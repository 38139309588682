import React, { useState } from "react";
import Popup from "../../../util/popup/Popup";
import WarningData from "./WarningData";
// import CommonDropDown from "../dropDown";
import { CreateBroadCast } from "../../../lib/Api/BroadCast";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from "moment";
// import LoaderSpinner from "../../../util/LoaderSpinner";

const schema = yup.object({
  send_to: yup.string().required("Send to is required"),
  send_on: yup.string().required("Send on is required"),
  send_via: yup.string().required("Send via is required"),
  title: yup.string().required("Title is required"),
  description: yup.string().required("description is required"),
});

const BroadCastMessage = ({ setUpdateLoading }) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [sendTime, setSendTime] = useState(null);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: "onChange", resolver: yupResolver(schema) });


  const handleCheckTime = (value) => {
    // Get the current time in Ivory Coast timezone
    const currentTime = new Date().toLocaleString("en-US", {
      timeZone: "Africa/Abidjan",
    });

    // Convert the current time string to a Date object
    const currentTimeObj = new Date(currentTime);

    let newSendTime;
    switch (value) {
      case "Now":
        // newSendTime = currentTimeObj;
        newSendTime = 0;
        break;
      case "In 30 minutes":
        // newSendTime = new Date(currentTimeObj.getTime() + 30 * 60000);
        newSendTime = 30;
        break;
      case "In 1 hour":
        // newSendTime = new Date(currentTimeObj.getTime() + 60 * 60000);
        newSendTime = 60;
        break;
      case "In 6 hours":
        // newSendTime = new Date(currentTimeObj.getTime() + 6 * 60 * 60000);
        newSendTime = 360;
        break;
      default:
        newSendTime = null;
        break;
    }

    return newSendTime;
  };


  const submitBoradcast = async () => {
    let { send_on, send_to, send_via, title, description } = formData;
    let combine = handleCheckTime(send_on)
    
    // const timedd = moment(combine).format('HH:mm:ss');
    // let dd = moment().format('DD/MM/YYYY')
    // let combineTime = `${dd} ${timedd}`

    try {
      setLoading(true);

      let payload = {
        send_to: send_to,
        interval : combine,
        send_via: send_via,
        title: title,
        message: description,
      };

      let { res } = await CreateBroadCast(payload);
      if (res) {
        setLoading(false);
        reset();
        setUpdateLoading(false);
        setShow(false);
      } else {
        setLoading(false);
      }
    } catch (err) { }
  };

  const onSubmit = (data) => {
    setFormData(data);
    setShow(true);
    setUpdateLoading(true);
  };

  return (
    <>
      {show && (
        <div className="max-w-[400px] m-auto">
          <Popup
            modalOpen={show}
            onClose={() => setShow(false)}
            data={
              <WarningData
                setShow={(val) => setShow(val)}
                submitBoradcast={submitBoradcast}
                loading={loading}
              />
            }
          />
        </div>
      )}

      <div className="flex justify-between">
        <div>
          <h2 className="text-[#FF8C00] font-semibold text-[20px]">
            Broadcast
          </h2>
          <p className="pt-4 text-[14px] text-[#898989]">
            Here You can send Broadcast message to every one
          </p>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="pt-5">
          <h2 className="text-[#444444] font-semibold">Broadcast Message</h2>
          <div className="grid lg:grid-cols-3 md:grid-cols-3 pt-5 gap-[34px]">
            <div className="flex flex-col">
              <label className="pb-3 text-[#606060] text-[14px]">
                Represents
              </label>

              <select
                {...register("send_to")}
                className={`border border-[#CDD2E1] h-[42px] placeholder:text-[14px] text-[14px] rounded-md text-[#ACACAC] focus:outline-none px-2.5 ${errors.send_to && "border-red-500"
                  }`}
              >
                <option value="">Please Select</option>
                <option value="renters">Renter</option>
                <option value="owners">Vehicle Owner</option>
                <option value="all">All</option>
              </select>
              {errors.send_to && (
                <p className="text-red-500 text-sm text-start pt-1 ">
                  {errors.send_to.message}
                </p>
              )}
            </div>
            <div className="flex flex-col">
              <label className="pb-3 text-[#606060] text-[14px]">
                Schedule Delivery
              </label>
              <select
                {...register("send_on")}
                className={`border border-[#CDD2E1] h-[42px] placeholder:text-[14px] text-[14px] rounded-md text-[#ACACAC] focus:outline-none px-2.5 ${errors.send_to && "border-red-500"
                  }`}
              >
                <option value="">Please Select</option>
                <option value="Now">Now</option>
                <option value="In 30 minutes"> In 30 minutes</option>
                <option value="In 1 hour">In 1 hour</option>
                <option value="In 6 hours">In 6 hours</option>
              </select>
              {errors.send_on && (
                <p className="text-red-500 text-sm text-start pt-1 ">
                  {errors.send_on.message}
                </p>
              )}
            </div>
            <div className="flex flex-col">
              <label className="pb-3 text-[#606060] text-[14px]">
                Send Message via
              </label>
              <select
                {...register("send_via")}
                className={`border border-[#CDD2E1] h-[42px] placeholder:text-[14px] text-[14px] rounded-md text-[#ACACAC] focus:outline-none px-2.5 ${errors.send_via && "border-red-500"
                  }`}
              >
                <option value="">Please Select</option>
                <option value="email">Email</option>
                <option value="notification">App Notification</option>
              </select>
              {/* <CommonDropDown
                {...register('send_via')}
                list={[
                  { label: "Email", value: "Email" },
                  { label: "App Notification", value: "App Notification" },
                ]}
              /> */}
              {errors.send_via && (
                <p className="text-red-500 text-sm text-start pt-1 ">
                  {errors.send_via.message}
                </p>
              )}
            </div>
          </div>
          <div className="flex flex-col pt-4">
            <label className="pb-3 text-[#606060] text-[14px]">
              Title
            </label>
            <input
              type="text"
              placeholder="Title"
              {...register("title")}
              className={`border ticket border-[#CDD2E1] h-[42px] placeholder:text-[14px] text-[14px] rounded-md text-[#ACACAC] focus:outline-none px-2.5 ${errors.title && "border-red-500"
                }`}
            />
            {errors.title && (
              <p className="text-red-500 text-sm text-start pt-1 ">
                {errors.title.message}
              </p>
            )}
          </div>
          <div className="pt-4 flex flex-col">
            <label className="pb-3 text-[#606060] text-[14px]">Message</label>
            <textarea
              placeholder="description"
              {...register("description")}
              // className=" resize-none border border-[#CDD2E1] w-full placeholder:text-[14px] py-2 text-[#ACACAC] focus:outline-none px-2 rounded-md  "
              className={`border border-[#CDD2E1] py-3 placeholder:text-[14px] text-[14px] rounded-md text-[#ACACAC] focus:outline-none px-2.5 ${errors.description && "border-red-500"
                }`}
              rows={6}
            ></textarea>
            {errors.description && (
              <p className="text-red-500 text-sm text-start pt-1 ">
                {errors.description.message}
              </p>
            )}
          </div>
          <div className="flex justify-end pt-5">
            <button className="bg-[#FF8C00] text-white text-[14px] font-semibold h-[40px] px-[52px] rounded-md">
              Send
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default BroadCastMessage;
