import React, { useState } from 'react';

const TruncateText = ({ text, maxLength }) => {
  const [isTruncated, setIsTruncated] = useState(true);

  const toggleTruncate = () => {
    setIsTruncated(!isTruncated);
  };

  return (
    <div>
      <p className=''>
        {isTruncated ? `${text.slice(0, maxLength)}...` : text}
      </p>
      <button onClick={toggleTruncate} className='text-[#FF8C00]'>
        {isTruncated ? 'Show More' : 'Show Less'}
      </button>
    </div>
  );
};

export default TruncateText;
