import React, { useState } from "react";
import delIcon from "../../../assests/newDesign/pass.svg";
import EditIcon from "../../../assests/newDesign/edit.svg";
import QuestionIcon from "../../../assests/newDesign/question.svg";
import { MdClose } from "react-icons/md";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import LoaderSpinner from "../../../util/LoaderSpinner";
import { UpdatePrimeConfig, VeifyPassword } from "../../../lib/Api/Marketing";
import { useSelector } from 'react-redux'


const FeesActions = ({
  setShow,
  singleCoupon,
  handleClose,
  type,

}) => {

  const schema = yup.object({
    password: yup.string().required("password is required"),
  });
  const schema2 = yup.object({
    rate: yup.string().required("Rate is required"),
  });

  const [active, setActive] = useState(0)
  const [loading, setLoading] = useState(false)
  const [loadingUpdate, setLoadingUpdate] = useState(false)
  const [rate, setRate] = useState("")

  const user = useSelector((state) => state.userAuth?.userInfo)

  let check = active === 0 ? ' Password Verification' : active === 1 && singleCoupon.key === "fee_for_renters" ? "Renter Service Fees" : active === 1 && singleCoupon.key === "fee_for_owners"? "Owner Commission Rate" : "Are you sure you want to modify this ?"

  let check_2 = active === 0 ? 'Enter this password before getting to any edit options' : active === 1 && singleCoupon.key === "fee_for_renters" ? "Enter the updated service fees " : active === 1 && singleCoupon.key === "fee_for_owners" ? "Enter the updated commission rate" : "It is suggested to apply these modification in off-peak hours. It will effect the overall mobile platforms."


  // React hook form initilization
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: "onChange", resolver: yupResolver(active === 0 ? schema : schema2) });

  // Submit Form 
  const onSubmit = async (data) => {
    try {
      setLoading(true);
      if (active === 0) {
        let paylaod = {
          email: user?.email,
          password: data.password
        }
        let { res } = await VeifyPassword(paylaod);
        if (res !== undefined) {
          setLoading(false);
          let payload = { rate: singleCoupon?.value * 100 }
          reset(payload);
          setActive(1)
        } else {
          setLoading(false);
        }
      }
      else {
        setRate(data.rate)
        setActive(2)

      }

    } catch (err) {
      setLoading(false);
    }
  };

  const handleSubmitData = async () => {
    try {
      setLoadingUpdate(true);
      let rateValue = rate / 100
      let paylaod = {
        key: singleCoupon?.key,
        value: rateValue
      }
      let { res } = await UpdatePrimeConfig(paylaod);
      if (res !== undefined) {
        setLoadingUpdate(false);
        reset();
        handleClose()
      } else {
        setLoadingUpdate(false);
      }
    }
    catch (err) {
      setLoadingUpdate(false);
    }

  }



  return (
    <>
      <div className="flex justify-end items-end cursor-pointer" onClick={() => setShow(false)}>
        <MdClose />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="text-center px-5">
          <div className="flex justify-center items-center">
            {active === 0 ?
              <img src={delIcon} alt="delicon" className="object-cover" />
              : active === 1 ?
                <img src={EditIcon} alt="delicon" className="object-cover" />
                : active === 2 &&
                <img src={QuestionIcon} alt="delicon" className="object-cover" />
            }

          </div>
          <div className="pt-3">
            <h2 className="text-[#292929] font-semibold text-[20px] font-sans">{check}</h2>
            <p className="text-[16px] font-normal text-[#666666] pt-1 font-sans max-w-[400px]">{check_2}</p>
            {active === 2 ? '' :

              active === 0 ?
                <input type={"password"} {...register('password')} placeholder="***Enter Password" className="border w-full mt-4 h-[40px] px-2 rounded-md focus:outline-none" />

                : active === 1 &&
                <input type={"text"} {...register('rate')} placeholder="15%" className="border w-full mt-4 h-[40px] px-2 rounded-md focus:outline-none" />

            }
            {active === 0 ?
              errors.password && (
                <p className="text-red-500 text-sm text-start pt-1 ">
                  {errors.password.message}
                </p>
              )
              :
              errors.rate && (
                <p className="text-red-500 text-sm text-start pt-1 ">
                  {errors.rate.message}
                </p>
              )}
            {
              active !== 2 &&

              <div className="flex justify-center gap-3 items-center pt-5">
                <button onClick={() => setShow(false)} className='w-[138px] py-2 mx-5 rounded-md bg-transparent border-[#ACACAC] border text-[#ACACAC]'>Cancel</button>
                <button className='bg-[#FF8C00] text-white h-[40px] px-8   rounded-md'>
                  {loading ? <LoaderSpinner /> : "Confirm"}
                </button>
              </div>
            }

          </div>
        </div>
      </form>
      {
        active === 2 &&
        <div className="flex gap-3 justify-center items-center pt-5">
          <button onClick={() => setShow(false)} className='w-[138px] py-2 mx-5 rounded-md bg-transparent border-[#ACACAC] border text-[#ACACAC]'>Cancel</button>
          <button onClick={() => handleSubmitData()} className='bg-[#FF8C00] text-white h-[40px] px-8   rounded-md'>
            {loadingUpdate ? <LoaderSpinner /> : "Confirm"}
          </button>
        </div>
      }


    </>
  );
};

export default FeesActions;
