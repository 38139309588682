import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { useLocation, useParams } from "react-router-dom";

ChartJS.register(ArcElement, Tooltip, Legend);



const PieChart = ({ statis }) => {
  const location = useLocation().pathname;
  const { id } = useParams()


  let renterLable = [ "Spend Statistics",  "Paid Services ", "Refund Statistics", "Total Paid"]
  let OwnerLable = [ "Cancelled " ,"Approved ",  "Extended ",  "Received " ,"Rejected" ]
  let renterDataSet = [
    {
      data: [statis?.refund_over_time, statis?.spending_over_time, statis?.total_paid_services, statis?.expired_requests],
      // backgroundColor: ["#58D764", "#EF7BE3", "#FBE947", "#52ACFF"],
      backgroundColor: ["#58D764","#EF7BE3", "#FF903E", "#FBE947"],
      borderColor: ["#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
      borderWidth: 1,
    },
  ]

  // "#52ACFF" pink
  //"#EF7BE3", blue

  let ownerDataSet = [
    {
      data: [statis?.canceled_requests, statis?.approved_requests, statis?.extended_requests, statis?.received_requests, statis?.rejected_requests],
      backgroundColor: ['#FF903E', '#FBE947', '#EF7BE3'  , '#52ACFF','#58D764'  ],
      borderColor: ["#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
      borderWidth: 1,
    },
  ]

  let CustomerOwnerSet = [
    {
      data:[statis?.canceled , statis?.approved , statis?.extended, statis?.recevied , statis?.rejected ],
      // data: [ statis?.rejected , statis?.extended, statis?.approved, statis?.recevied , statis?.canceled ],
      backgroundColor: ["#FF903E", "#FBE947","#EF7BE3","#52ACFF","#58D764"],
      borderColor: ["#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
      borderWidth: 1,
    },
  ]
  
  

  const data = {
    labels: location === `/dinss/tech/dashboard/vehical-detail/${id}/all` || location === `/dinss/tech/vehicalOwner/detail/${id}/all` || location === "/dinss/tech/customerOwner"  ? OwnerLable  : renterLable,
    datasets: location === `/dinss/tech/dashboard/vehical-detail/${id}/all` || location === `/dinss/tech/vehicalOwner/detail/${id}/all` ? ownerDataSet : location === "/dinss/tech/customerOwner" ? CustomerOwnerSet : renterDataSet
  };
  return (
    <>
      <div className="flex lg:flex-nowrap lg:flex-col xl:flex-row justify-center   xl:justify-between items-center flex-wrap  ">
        <div className="flex pt-[2rem] ">
          {location === "/dinss/tech/customerRenter" ? (
            <ul>
              <li className="justify-between flex items-center">
                <div className="flex items-center ">
                  <div className="border w-[13px] h-[13px] bg-[#58D764]" />
                  <h2 className="pl-1 text-[15px] text-[#444444]">
                    Spend Statistics Over Time
                  </h2>
                </div>
                <h2 className="pl-5 text-[15px] text-[#444444]">{statis?.spending_over_time}</h2>
              </li>
              <li className="justify-between flex items-center py-2">
                <div className="flex items-center ">
                  <div className="border w-[13px] h-[13px] bg-[#FF903E]" />
                  <h2 className="pl-1 text-[15px] text-[#444444]">
                    Refund Statistics Over time
                  </h2>
                </div>
                <h2 className="pl-5 text-[15px] text-[#444444]">{statis?.refund_over_time}</h2>
              </li>
              <li className="justify-between flex items-center">
                <div className="flex items-center ">
                  <div className="border w-[13px] h-[13px] bg-[#EF7BE3] " />
                  <h2 className="pl-1 text-[15px] text-[#444444]">
                    Paid Services statictics
                  </h2>
                </div>
                <h2 className="pl-5 text-[15px] text-[#444444]">{statis?.expired_requests}</h2>
              </li>
              <li className="justify-between flex items-center py-2">
                <div className="flex items-center ">
                  <div className="border w-[13px] h-[13px] bg-[#FBE947]" />
                  <h2 className="pl-1 text-[15px] text-[#444444]">
                    Total Paid Services
                  </h2>
                </div>
                <h2 className="pl-5 text-[15px] text-[#444444]">{statis?.total_paid_services}</h2>
              </li>
            </ul>
          ) : (
            <ul>
              <li className="justify-between flex items-center">
                <div className="flex items-center ">
                  <div className="border w-[13px] h-[13px] bg-[#58D764]" />
                  <h2 className="pl-1 text-[15px] text-[#444444]">
                    Rejected Requests
                  </h2>
                </div>
                <h2 className="pl-5 text-[15px] text-[#444444]">{statis?.rejected_requests || statis?.rejected}</h2>
              </li>
              <li className="justify-between flex items-center py-2">
                <div className="flex items-center ">
                  <div className="border w-[13px] h-[13px] bg-[#FF903E]" />
                  <h2 className="pl-1 text-[15px] text-[#444444]">
                    Cancelled Requests
                  </h2>
                </div>
                <h2 className="pl-5 text-[15px] text-[#444444]">{statis?.canceled_requests || statis?.canceled}</h2>
              </li>
              <li className="justify-between flex items-center">
                <div className="flex items-center ">
                  <div className="border w-[13px] h-[13px] bg-[#FBE947]" />
                  <h2 className="pl-1 text-[15px] text-[#444444]">
                    Approved Requests
                  </h2>
                </div>
                <h2 className="pl-5 text-[15px] text-[#444444]">{statis?.approved_requests || statis?.approved}</h2>
              </li>
              <li className="justify-between flex items-center py-2">
                <div className="flex items-center ">
                  <div className="border w-[13px] h-[13px] bg-[#EF7BE3] " />
                  <h2 className="pl-1 text-[15px] text-[#444444]">
                    Extended Requests
                  </h2>
                </div>
                <h2 className="pl-5 text-[15px] text-[#444444]">{statis?.extended_requests || statis?.extended
                }</h2>
              </li>
              <li className="justify-between flex items-center">
                <div className="flex items-center ">
                  <div className="border w-[13px] h-[13px] bg-[#52ACFF]" />
                  <h2 className="pl-1 text-[15px] text-[#444444]">
                    Received Requests{" "}
                  </h2>
                </div>
                <h2 className="pl-5 text-[15px] text-[#444444]">{statis?.received_requests || statis?.recevied}</h2>
              </li>
            </ul>
          )}
        </div>
        <div className="max-w-[200px] lg:py-0 py-3">
          <Pie
            data={data}
            className="w-auto h-auto"
            options={{
              responsive: true,
              plugins: {
                legend: {
                  display: false,
                  position: "left",
                },
              },
            }}
          />
        </div>
      </div>
    </>
  );
};
export default PieChart;
