import React, { useState, useEffect } from "react";
import BlogLayout from "../../components/layouts/blogLayout/BlogLayout";
import BlogArticleCard from "../../components/BlogComponents/BlogArticleCard";
import googlePlay from "../../assests/blog/google-play.webp";
import appleStore from "../../assests/blog/apple-store.webp";
import heroImage from "../../assests/blog/article-head-section.webp";
import { useNavigate } from "react-router-dom";
import article1 from "../../assests/blog/articles/thumbnails/article-1.webp";
import article2 from "../../assests/blog/articles/thumbnails/article-2.webp";
import article3 from "../../assests/blog/articles/thumbnails/article-3.webp";
import article4 from "../../assests/blog/articles/thumbnails/article-4.webp";
import { Helmet } from "react-helmet";

const Blog = () => {
	const navigate = useNavigate();

	let blogs = [
		{
			uid: 1,
			thumbnail: article1,
			tagText: "Vacances",
			time: "5 min",
			title: "Trouvez la meilleure location de voiture pour vos vacances en Côte d’Ivoire.",
			date: "21 octobre 2023",
			btnText: "Lire l'article",
			slug: "trouvez-la-meilleure-location-de-voiture-pour-vos-vacances-en-cote-divoire",
		},
		{
			uid: 2,
			thumbnail: article2,
			tagText: "Professionnels",
			time: "3 min",
			title: "Louer une voiture pour votre entreprise ou en acheter en Côte d’Ivoire ?",
			date: "23 octobre 2023",
			btnText: "Lire l'article",
			slug: "louer-une-voiture-pour-votre-entreprise-ou-en-acheter-en-cote-divoire",
		},
		{
			uid: 3,
			thumbnail: article3,
			tagText: "Guides et conseils",
			time: "4 min",
			title: "Ce qu’il faut savoir sur la location de véhicule utilitaire.",
			date: "24 octobre 2023",
			btnText: "Lire l'article",
			slug: "ce-quil-faut-savoir-sur-la-location-de-vehicule-utilitaire",
		},
		{
			uid: 4,
			thumbnail: article4,
			tagText: "Guides et conseils",
			time: "4 min",
			title: "Nos conseils pour faire des annonces avec de belles photos sur Primecar",
			date: "25 octobre 2023",
			btnText: "Lire l'article",
			slug: "nos-conseils-pour-faire-des-annonces-avec-de-belles-photos-sur-primecar",
		},
	];

	const [blogsList, setBlogsList] = useState([]);

	useEffect(() => {
		const columnCount = 2;
		const tempArray = [];
		while (blogs.length) tempArray.push(blogs.splice(0, columnCount));
		setBlogsList(tempArray);
	}, []);

	return (
		<BlogLayout>
			<Helmet>
				<title>
					Le blog Primecar : Nos conseils voyages et actualités
				</title>
				<meta
					name="description"
					content="Nos conseils voyages et actualités "
				/>
				<link rel="canonical" href="https://primecarapp.com/blog" />
			</Helmet>
			<div className="blog-page-head-section">
				<div className="blog-page-heading-section-main">
					<div className="blog-page-heading-section">
						<hr className="blog-header-heading-top-line" />
						<p className="blog-header-heading">Le blog</p>
						<p className="blog-page-head-section-heading">
							Location de voitures à Abidjan
						</p>
						<p className="blog-page-head-section-sub-heading">
							Vous préparez un voyage en Côte d’Ivoire pour vos
							vacances ? Faites votre location de voiture en toute
							sérénité. Ce guide vous aidera à faire les bons
							choix !
						</p>
						<div
							className="blog-page-head-button"
							onClick={() => {
								navigate(
									"/blog/trouvez-la-meilleure-location-de-voiture-pour-vos-vacances-en-cote-divoire"
								);
							}}
						>
							Lire la suite ...
						</div>
					</div>
					<div className="blog-page-head-apps-buttons-section">
						<p className="blog-page-head-apps-buttons-heading-text">
							Téléchargez l’application Primecar
						</p>
						<div className="mt-2 flex">
							<a href="https://play.google.com/store/apps/details?id=com.dinsstech.primecar">
								<img
									className="blog-page-head-apps-button mr-2"
									src={googlePlay}
									alt="google-play"
								/>
							</a>
							<a href="https://apps.apple.com/fr/app/primecar/id6446251526">
								<img
									className="blog-page-head-apps-button"
									src={appleStore}
									alt="apple-store"
								/>
							</a>
						</div>
					</div>
				</div>
				<img src={heroImage} alt="header-section" />
				<div className="blog-page-heading-section-main-mobile">
					<div className="blog-page-heading-section">
						<h1 className="blog-page-head-section-heading">
							Location de voitures à Abidjan
						</h1>
						<p className="blog-page-head-section-sub-heading">
							Vous préparez un voyage en Côte d’Ivoire pour vos
							vacances ? Faites votre location de voiture en toute
							sérénité. Ce guide vous aidera à faire les bons
							choix !
						</p>
						<div className="flex justify-center">
							<span className="blog-page-head-button">
								Lire la suite ...
							</span>
						</div>
					</div>
				</div>
			</div>
			<div className="blog-page-main">
				<h3 className="blog-heading">
					Découvrez nos derniers articles
				</h3>
				<p className="blog-sub-heading">
					Louez une voiture, un utilitaire ou un engin roulant en
					quelque clics
				</p>
				<div className="blog-artice-main">
					{blogsList.map((blogs, key1) => {
						return (
							<div
								className="blog-article-grid gap-8"
								key={blogs.length + "blog-article-grid" + key1}
							>
								{blogs.map((blog, key2) => {
									return (
										<div
											className=""
											key={
												blog.uid +
												"-blog-article-card" +
												key1 +
												"-" +
												key2
											}
										>
											<BlogArticleCard
												imageSrc={blog.thumbnail}
												tagText={blog.tagText}
												time={blog.time}
												cardText={blog.title}
												date={blog.date}
												buttonText={blog.btnText}
												slug={blog.slug}
											/>
										</div>
									);
								})}
							</div>
						);
					})}
				</div>
				<div className="blog-artice-bottom-button-main">
					<button className="blog-artice-bottom-button">
						<span className="blog-artice-bottom-button-text">
							Voir plus d’articles
						</span>
					</button>
				</div>
			</div>
		</BlogLayout>
	);
};

export default Blog;