import React, { useState, useEffect } from "react";
import { GetAfiliateUser } from "../../lib/Api/Marketing";
import CreateAfiliate from "../../components/AdminComponents/afiliate/CreateAfiliate";
import AfiliateTable from "../../components/AdminComponents/afiliate/AfiliateTable";
import { MdSearch } from "react-icons/md";

const Afiliate = () => {

  // React hooks

  const [vehicalList, setVehicalList] = useState([])
  const [loading, setLoading] = useState(false)
  const [reload, setReload] = useState("")
  const [search, setSearch] = useState("")
  const [offsetPage , setOffsetPage] = useState(1)
  const [totalPage , setTotalPage] = useState(0)
  useEffect(() => {
    try {
      let fetchData = async () => {
        setLoading(true)
        let { res } = await GetAfiliateUser(offsetPage)
        if (res) {
          setLoading(false)
          setVehicalList(res?.data)
          setTotalPage(res?.total)
        }
        else {
          setLoading(false)
        }
      }
      if (reload === "") {
        fetchData()
      }
    } catch (err) { }

  }, [reload])

  

  return (
    <>
      {/* Promo component */}
      <div className="px-5">
        <CreateAfiliate setReload={setReload} />
      </div>
      {/* table component */}
      <div className="pt-10">
        <div className="flex  flex-wrap justify-between items-center">
          <h2 className="text-[20px] font-semibold leading-[20.16px]  text-[#444444]">
            Afiliate Users
          </h2>
          <div className="flex lg:py-0 py-2 ">
            <div className="bg-white shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] rounded-full border-0 flex items-center px-4 lg:w-[380px] w-full py-3 h-[45px]">
              <MdSearch className="text-[25px] text-[#FF8C00]" />
              <input
                onChange={(e) => setSearch(e.target?.value)}
                type="text"
                className="focus:outline-none mx-2 w-full font-medium placeholder:text-[#ACACAC] placeholder:text-[16px]"
                value={search}
                placeholder="Search..."
              />
            </div>
          </div>
        </div>
        <AfiliateTable setReload={setReload} searchData={search} vehicalList={vehicalList} setVehicalList={setVehicalList} loading={loading} setLoading={setLoading} setOffsetPage={setOffsetPage}  totalPage={totalPage} />
      </div>
    </>
  );
};

export default Afiliate;
