import { toast } from "react-toastify";
import { callPrivateApi } from "../Apiendpoint";

// ------ Management Api's ----- //

// Get Vehical Management
export const GetVehicalMang = async () => {
  try {
    let response = await callPrivateApi("admin/coupons", "get");
    if (response.success === true) {
      let obj = Object.assign({}, ...response.data);
      return {
        res: obj.coupons,
      };
    } else {
      toast.error(response.message);
    }
  } catch (err) {
    toast.error(err);
  }
};
// Get Reviews Management
export const GetReviewsMang = async () => {
  try {
    let response = await callPrivateApi("admin/vehicle/all-reviews", "get");
    if (response.success === true) {
      // let obj = Object.assign({}, ...response.data);
      return {
        res: response.data
      };
    } else {
      toast.error(response.message);
    }
  } catch (err) {
    toast.error(err);
  }
};

// Get Booking Management
export const GetBookingMang = async (page_no) => {
  try {
    let response = await callPrivateApi(
      `admin/rent/list-rents?page=${page_no}&per_page=10`,
      "post"
    );
    if (response.success === true) {
      let obj = Object.assign({}, ...response.data);
      return {
        res: obj.rents,
        page: obj.rents?.total,
      };
    } else {
      toast.error(response.message);
    }
  } catch (err) {
    toast.error(err);
  }
};

// Get Pendding Transfer
export const GetTransfer = async () => {
  try {
    let response = await callPrivateApi(
      `admin/rent/transfer-payments`,
      "get"
    );
    if (response.success === true) {
      let obj = Object.assign({}, ...response.data);
      return {
        res: obj.rents,
        page: obj.rents?.total,
      };
    } else {
      toast.error(response.message);
    }
  } catch (err) {
    toast.error(err);
  }
};

// Get Pendding Transfer
export const GetTransferHistory = async () => {
  try {
    let response = await callPrivateApi(
      `admin/rent/transfer-payments/histroy`,
      "get"
    );
    if (response.success === true) {
      let obj = Object.assign({}, ...response.data);
      return obj.rents
      // return {
      //   res: 
      //   page: obj.rents?.total,
      // };
    } else {
      toast.error(response.message);
    }
  } catch (err) {
    toast.error(err);
  }
};

// update Transfer
export const UpdateTransfer = async (data) => {
  try {
    let response = await callPrivateApi(
      `admin/rent/update-transfer-status`,
      "post",
      data
    );
    if (response.success === true) {
      // let obj = Object.assign({}, ...response.data);
      return response?.data
    } else {
      toast.error(response.message);
    }
  } catch (err) {
    toast.error(err);
  }
}

// Get Canceled Booking Management
export const GetCanceledBookingMang = async (page_no) => {
  try {
    let response = await callPrivateApi(
      `admin/rent/list-rents/canceled?page=${page_no}&per_page=10`,
      "post"
    );
    if (response.success === true) {
      let obj = Object.assign({}, ...response.data);
      return {
        res: obj.rents,
        page: obj.rents?.total,
      };
    } else {
      toast.error(response.message);
    }
  } catch (err) {
    toast.error(err);
  }
};

// Get Booking Management Page Number
export const GetBookingMangPageNo = async (pageNo, search) => {
  try {
    let check = search
      ? `admin/rent/list-rents?page=${pageNo}&per_page=10?search=${search}`
      : `admin/rent/list-rents?page=${pageNo}`;
    let response = await callPrivateApi(check, "post");
    if (response.success === true) {
      let obj = Object.assign({}, ...response.data);
      return {
        res: obj.rents,
      };
    } else {
      toast.error(response.message);
    }
  } catch (err) {
    toast.error(err);
  }
};

// Get Booking Management
export const GetBookingMangDetail = async (id) => {
  try {
    let response = await callPrivateApi(`admin/rent/rent-details/${id}`, "get");
    if (response.success === true) {
      let obj = Object.assign({}, ...response.data);
      return {
        res: obj.rent,
      };
    } else {
      toast.error(response.message);
    }
  } catch (err) {
    toast.error(err);
  }
};


// Cancel Booking Management
export const CancelBookingMang = async (data) => {
  try {
    let response = await callPrivateApi(`admin/rent/update-status`, "post", data);
    if (response.success === true) {
      toast.success(response.message);
      return {
        res: response.data,
      };
    } else {
      toast.error(response.message);
    }
  } catch (err) {
    toast.error(err);
  }
};

// Get Booking Management
export const GetPaymentMang = async () => {
  try {
    let response = await callPrivateApi(
      "admin/payment-methods",
      "get"
    );
    if (response.success === true) {
      let obj = Object.assign({}, ...response.data);
      return {
        res: obj.payment_methods,
      };
    } else {
      toast.error(response.message);
    }
  } catch (err) {
    toast.error(err);
  }
};


export const UpdatePaymentStatus = async (data) => {
  try {
    let response = await callPrivateApi(
      "admin/payment-methods",
      "post", data

    );
    if (response.success === true) {
      return {
        res: response.success
      };
    } else {
      toast.error(response.message);
    }
  } catch (err) {
    toast.error(err);
  }
}

// ------------------------ Register User Apis------------------//

// Get Register Users
export const GetRegisterUsers = async (page_no ) => {
  try {
    let response = await callPrivateApi(`admin/users?page=${page_no}&per_page=10`, "get");
    if (response.success === true) {
      let obj = Object.assign({}, ...response.data);
      return obj.vehicles
    } else {
      toast.error(response.message);
    }
  } catch (err) {
    toast.error(err);
  }
};

// Download Excel File
export const GetExcelFile = async () => {
  debugger
  try {
    let response = await callPrivateApi(`admin/excel-export`, "get");
    return response

  } catch (err) {
    toast.error(err);
  }
};


// Search Register Users
export const SearchRegisterUsers = async (page_no, search) => {
  try {
    let response = await callPrivateApi(`admin/users?page=${page_no}&per_page=10&search=${search}`, "get");
    if (response.success === true) {
      let obj = Object.assign({}, ...response.data);
      return { res: obj.vehicles }
    } else {
      toast.error(response.message);
    }
  } catch (err) {
    toast.error(err);
  }
};
// Delete Users
export const DelRegisterUsers = async (page_no) => {
  try {
    let response = await callPrivateApi(`admin/users?page=${page_no}&per_page=10`, "get");
    if (response.success === true) {
      let obj = Object.assign({}, ...response.data);
      return obj.vehicles
    } else {
      toast.error(response.message);
    }
  } catch (err) {
    toast.error(err);
  }
};

// User restrict 

// Get Register Users
export const GetUserResctrict = async (id) => {
  try {
    let response = await callPrivateApi(`admin/restrict-user/${id}`, "get");
    if (response.success === true) {
      toast.success(response.message);
      return response

    } else {
      toast.error(response.message);
    }
  } catch (err) {
    toast.error(err);
  }
};
