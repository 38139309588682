import React, { useState, useEffect } from "react";
import Promo from "../../components/AdminComponents/marketing/Promo";
// import { MdSearch } from "react-icons/md";
import PromoTable from "../../components/AdminComponents/marketing/PromoTable";
import { GetCoupon } from "../../lib/Api/Marketing";
// import SearchBar from "../../components/GenericComponents/SearchBar";
import { MdSearch } from "react-icons/md";

const Marketing = () => {

  // React hooks

  const [vehicalList, setVehicalList] = useState([])
  const [loading, setLoading] = useState(false)
  const [reload, setReload] = useState("")
  const [search, setSearch] = useState("")

  useEffect(() => {
    try {
      let fetchData = async () => {
        setLoading(true)
        let { res } = await GetCoupon()
        if (res) {
          setLoading(false)
          setVehicalList(res?.data)
        }
        else {
          setLoading(false)
        }
      }
      if (reload === "") {
        fetchData()
      }
    } catch (err) { }

  }, [reload])



  return (
    <>
      {/* Promo component */}
      <div className="px-5">
        <Promo setReload={setReload} />
      </div>
      {/* table component */}
      <div className="pt-10">
        <div className="flex  flex-wrap justify-between items-center">
          <h2 className="text-[20px] font-semibold leading-[20.16px]  text-[#444444]">
            Promo Codes Raw Data
          </h2>
          <div className="flex lg:py-0 py-2 ">
            <div className="bg-white shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] rounded-full border-0 flex items-center px-4 lg:w-[380px] w-full py-3 h-[45px]">
              <MdSearch className="text-[25px] text-[#FF8C00]" />
              <input
                onChange={(e) => setSearch(e.target?.value)}
                type="text"
                className="focus:outline-none mx-2 w-full font-medium placeholder:text-[#ACACAC] placeholder:text-[16px]"
                value={search}
                placeholder="Search..."
              />
            </div>
          </div>
        </div>
        <PromoTable setReload={setReload} searchData={search} vehicalList={vehicalList} setVehicalList={setVehicalList} loading={loading} setLoading={setLoading} />
      </div>
    </>
  );
};

export default Marketing;
