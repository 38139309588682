import { BsStarFill, BsStarHalf } from "react-icons/bs";

const Rating = ({ value }) => {
    return (
        <div className='flex items-center'>
            <span className="mr-1">
                    <div >
                    {value >= 1
                        ? <BsStarFill className={ value >= 1 ? 'text-[#FF8C00]' : 'text-gray-200'}/>
                        : value >= 0.5
                            ? <BsStarHalf className={ value >= 0.5 ? 'text-[#FF8C00]' : 'text-gray-200'} />
                            : <BsStarFill className={ value >= 1 ? 'text-[#FF8C00]' : 'text-gray-200'}/>
                    }
                </div>
            </span>
            <span className="mr-1">
                    <div >
                    {value >= 2
                        ? <BsStarFill className={ value >= 2 ? 'text-[#FF8C00]' : 'text-gray-200'}/>
                        : value >= 1.5
                            ? <BsStarHalf className={ value >= 1.5 ? 'text-[#FF8C00]' : 'text-gray-200'} />
                            : <BsStarFill className={ value >= 2 ? 'text-[#FF8C00]' : 'text-gray-200'}/>
                    }
                </div>
            </span>
            <span className="mr-1">
                    <div >
                    {value >= 3
                        ? <BsStarFill className={ value >= 3 ? 'text-[#FF8C00]' : 'text-gray-200'}/>
                        : value >= 2.5
                            ? <BsStarHalf  className={ value >= 2.5 ? 'text-[#FF8C00]' : 'text-gray-200'} />
                            : <BsStarFill  className={ value >= 3 ? 'text-[#FF8C00]' : 'text-gray-200'}/>
                    }
                </div>
            </span>
            <span className="mr-1">
                    <div >
                    {value >= 4
                        ? <BsStarFill className={ value >= 4 ? 'text-[#FF8C00]' : 'text-gray-200'}/>
                        : value >= 3.5
                            ? <BsStarHalf  className={ value >= 3.5 ? 'text-[#FF8C00]' : 'text-gray-200'} />
                            : <BsStarFill  className={ value >= 4 ? 'text-[#FF8C00]' : 'text-gray-200'}/>
                    }
                </div>
            </span>
            <span className="mr-1">
                    <div >
                    {value >= 5
                        ? <BsStarFill className={ value >= 5 ? 'text-[#FF8C00]' : 'text-gray-200'}/>
                        : value >= 4.5
                            ? <BsStarHalf  className={ value >= 4.5 ? 'text-[#FF8C00]' : 'text-gray-200'} />
                            : <BsStarFill  className={ value >= 5 ? 'text-[#FF8C00]' : 'text-gray-200'}/>
                    }
                </div>
            </span>
            {/* <span className="mr-1">{text && text}</span> */}
        </div>
    )
}

Rating.defaultProps = {
    color: '#FF8C00',
}



export default Rating;