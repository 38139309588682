import React, { useEffect, useState } from "react";
// import road from '../../../assests/line.png'
// import { BsSearch } from 'react-icons/bs';
import BreadCums from "../breadCums/BreadCums";
const Banner = ({ left, center, right, middleLink }) => {
	const [windowDimensions, setWindowDimensions] = useState(0);

	useEffect(() => {
		function handleResize() {
			const { innerWidth: width } = window;
			setWindowDimensions(width);
		}

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, [windowDimensions]);

	return (
		<div className="flex flex-col bg-support_img bg-cover bg-no-repeat bg-center px-6 py-14 md:py-20 items-center">
			<div className="flex flex-col w-full max-w-md md:max-w-2xl items-center">
				<BreadCums
					left={left}
					right={right}
					middle={center}
					middleLink={middleLink}
				/>
				<div className="flex flex-col w-full gap-3 md:gap-4 lg:gap-5 xl:gap-6">
					<h1 className="text-[#FF8C00] text-center text-xl md:text-2xl lg:text-2xl xl:text-3xl font-semibold xl:font-bold lg:leading-[64px]">
						Centre d’aide
					</h1>
					<div className="flex flex-col md:flex-row gap-3 lg:gap-4 xl:gap-5 items-center">
						<input
							type="text"
							className="w-full rounded-sm px-4 lg:px-5 py-2 lg:py-3 font-normal text-xs lg:text-sm text-[#ACACAC]"
							placeholder="Des réponses dans notre centre d’aide ..."
						/>
						<button className="bg-[#FF8C00] text-sm text-white px-7 lg:px-8 py-2 lg:py-3 rounded-md mt-1 md:mt-0">
							Rechercher
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Banner;
