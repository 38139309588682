import React, { useState, useEffect } from "react";
import car_orang from "../../../assests/newDesign/rent-orange-city-car.svg";
import GroupRentBg from "../../../assests/newDesign/group-rent-bg.png";
import {
	GetFilterBaseOnId,
	GetFilterCar,
	GetMetaData,
} from "../../../lib/Api/WebsiteApi";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import LoaderSpinner from "../../../util/LoaderSpinner";
import { Link } from "react-router-dom";

const schema = yup.object({
	make: yup.string().required("Marque du véhicule"),
	model: yup.string().required("Marque du véhicule"),
	year: yup.string().required("Année du véhicule"),
});

let initailValue = {
	make: 176,
	model: 1850,
	year: 2022,
};

const Banner = () => {
	const [carLoading, setCarLoading] = useState(false);
	const [priceLoading, setPriceLoading] = useState(false);
	const [loading, setLoading] = useState(false);
	const [yearDisable, setYearDisable] = useState(false);
	const [carModel, setCarModel] = useState([]);
	const [carMake, setCarMake] = useState([]);
	const [carYear, setCarYear] = useState([]);
	const [calculatedPrice, setCalulatedprice] = useState(0);
	const [year, setYear] = useState('');

	const {
		register,
		handleSubmit,
		setValue,
		reset,
		formState: { errors },
	} = useForm({ mode: "onChange", resolver: yupResolver(schema) });


	const onSubmit = async (data) => {
		try {
			setLoading(true);
			let payload = {
				make_id: data?.make,
				model_id: data?.model,
				year: data.year,
			};
			let { res } = await GetFilterCar(payload);
			if (res !== undefined) {
				let cal = 21 * res?.price_out_abidjan;
				setCalulatedprice(cal);
				setLoading(false);
				// reset();
			} else {
				setLoading(false);
			}
		} catch (err) {
			setLoading(false);
		}
	};




	const handleChnageApi = async (e, type) => {
		let id = e.target.value;
		setCarLoading(true)
		let { res } = await GetFilterBaseOnId(id, type);
		if (type === "model") {
			let model = res ? Object.assign({}, ...res) : {};
			setCarModel(model?.models);
			setCarLoading(false)
			setYearDisable(true);
		} else {
			setValue("year", null)
			let model = res ? Object.assign({}, ...res) : {};
			setCarYear(model?.years);
			setCarLoading(false)
			setYearDisable(false);
		}
	};

	useEffect(() => {
		try {
			setCarLoading(true);
			let fetchData = async () => {
				const { resModel, resMake, resYear } = await GetMetaData();
				let model = resModel ? Object.assign({}, ...resModel) : {};
				setCarModel(model?.models);
				let make = Object.assign({}, ...resMake);
				setCarMake(make?.makes);
				let year = Object.assign({}, ...resYear);
				setCarYear(year?.years);
				setCarLoading(false);
				reset(initailValue);
				// setValue("make", initailValue?.make)
				// setValue("model", initailValue?.model)
				setValue("year", initailValue?.year)
				let payload = {
					make_id: 176,
					model_id: 1850,
					year: 2022,
				};
				setPriceLoading(true);
				let { res } = await GetFilterCar(payload);
				if (res !== null) {
					setPriceLoading(false);
					let cal = 21 * res?.price_out_abidjan;
					setCalulatedprice(cal);
				} else {
					setPriceLoading(false);
				}
			};
			fetchData();
		} catch (err) { }
	}, []);


	return (
		<div className=" bgImgBnr ">
			<div className="2xl:w-[1440px] gap-[13px] lg:gap-[70px] m-auto flex flex-col pt-10 lg:relative lg:py-0 h-fit px-5 md:px-20 2xl:px-0 lg:h-[540px] lg:flex-row">
				<div className="flex flex-col z-10   lg:justify-center">
					<form
						// onSubmit={}
						className="flex flex-col bg-[#F4F4F4] rounded-md p-6 gap-5 lg:w-80 xl:w-80 md:mt-[-50px]"
					>
						<div className="py-3 px-4 rounded-md border border-[#E0E0E0] bg-white">
							<select
								// onChange={(e) => {
								// 	setValue("make", e.target.value);
								// 	// handleSubmit((data) => console.log("data", data))();
								// }}
								{...register('make')}
								// value={initailValue?.make}
								className="w-full outline-none text-sm bg-transparent"
								onChange={(e) => handleChnageApi(e, "model")}
							>
								<option value="">
									{carLoading
										? "Chargement..."
										: "Veuillez sélectionner"}
								</option>
								{carMake?.map((item, i) => (
									<option key={i} value={item?.id_car_make}>
										{item?.name}
									</option>
								))}
							</select>
							{errors.make && (
								<p className="text-red-500 text-sm text-start ">
									{errors.make.message}
								</p>
							)}
						</div>
						<div className="py-3 px-4 rounded-md border border-[#E0E0E0] bg-white">
							<select
								{...register("model")}
								className="w-full outline-none text-sm bg-transparent"
								onChange={(e) => handleChnageApi(e, "year")}
							>
								<option value="">
									{carLoading
										? "Chargement..."
										: "Veuillez sélectionner"}
								</option>
								{carModel?.map((item, i) => (
									<option key={i} value={item?.id_car_model}>
										{item?.name}
									</option>
								))}
							</select>
							{errors.model && (
								<p className="text-red-500 text-sm text-start pt-1 ">
									{errors.model.message}
								</p>
							)}
						</div>
						<div className="py-3 px-4 rounded-md border border-[#E0E0E0] bg-white">

							<select
								{...register("year")}
								onChange={(e) => {
									setValue("year", e.target.value);
									handleSubmit((data) => onSubmit(data))();
								}}
								className="w-full outline-none text-sm bg-transparent"
							>
								<option value="">
									{carLoading
										? "Chargement..."
										: "Veuillez sélectionner"}
								</option>
								{carYear?.map((item, i) => (
									<option key={i} value={item?.year}>
										{item?.year}
									</option>
								))}
							</select>

							{errors.year && (
								<p className="text-red-500 text-sm text-start pt-1 ">
									{errors.year.message}
								</p>
							)}
						</div>
						{

							loading ? <div className="flex items-center justify-center"><LoaderSpinner type={"tableLoad"} /></div> :
								calculatedPrice > 0 ? (
									<div className="flex flex-col items-center gap-1">
										<p className="text-[#ACACAC] text-sm leading-5">
											Votre revenu est estimé à
										</p>
										<p className="text-[#FF8C00] text-2xl leading-8 font-semibold">
											{priceLoading ? (
												<LoaderSpinner />
											) : (
												calculatedPrice?.toLocaleString()
											)}{" "}
											FCFA
										</p>
										<p className="text-[#ACACAC] text-sm leading-5">
											par mois
										</p>
									</div>
								) : (
									<div className="text-center text-md">
										Enregistrement non trouvé
									</div>
								)}
						<Link to="/contact"
							type="submit"
							className="text-base leading-6 text-white bg-[#FF8C00] hover:bg-[#D16A00] w-fit px-8 py-2 rounded-md self-center"
						>
							"Ça m'intéresse !"
						</Link>
					</form>
				</div>

				<div className="flex flex-col z-10 lg:justify-center lg:w-[868px]">
					<div className="h-[213px] sm:h-[450px] lg:h-full relative mt-8 lg:mt-0">
						<img
							src={car_orang}
							alt="car"
							className="w-[95%] sm:w-[90%] md:w-[85%] lg:w-[700px] xl:w-[800px] 2xl:w-[868px]"
						/>
					</div>
				</div>
			</div >
		</div >
	);
};

export default Banner;
