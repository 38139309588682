import pic1 from "../../../assests/pic1.png";
import pic2 from "../../../assests/pic2.png";
export const newData = [
	{
		title: "Trouver un véhicule",
		questionsAnswers: [
			{
				slug: "Pourrais-je-louer-un-vehicule-sur-Primecar",
				question: "Pourrais je louer un véhicule sur Primecar?",
				answer: "<p>ll est tout à fait possible de louer un véhicule sur Primecar. Cependant, il vous faudra remplir les conditions suivantes</p><ul><li> Être âgé de plus de 18 ans;</li><li> Être inscrit sur la plateforme sous sa véritable identité et avoir fourni la véritable adresse de son domicile;</li> <li>Avoir communiqué un numéro de téléphone auquel le locataire est effectivement joignable ;</li><li> Ne pas avoir créé sur le site plusieurs comptes;  </li></ul> <p>Dans le cas d’une location sans chauffeur, il faudra être titulaire d’un permis de conduire valide dans le pays dans lequel le véhicule est loué.  Si vous êtes titulaire dun permis de conduire étranger, il faudra vous assurer de sa validité dans le pays hôte notamment par le biais d’accords bilatéraux existants.</p><p>Vous devriez fournir au propriétaire une copie de votre permis de conduire et/ou de votre pièce d’identité avant le début d’une location. Ces copies devront être conservées par le propriétaire au moins jusqu’à la fin de la location.</p>",
			},
			{
				slug: "Comment-est-fixe-le-prix-de-la-location-d-un-vehicule",
				question:
					"Comment est fixé le prix de la location d’un véhicule?",
				answer: "<p>Les propriétaires fixent le tarif journalier de la location. Celui-ci inclus, dans certains cas, les frais de prestations de leur chauffeur si le véhicule est proposé avec ce dernier. Vous aurez accès aux meilleurs prix sur notre plateforme. Le tarif total est calculé en fonction des dates et de la durée de votre location. </p><p> Primecar vous prélève une petite commission pour la location du véhicule. Elle varie entre 1000 FCFA et 8000 FCFA selon la catégorie du véhicule, pour toute la période de location.</p>",
			},
			{
				slug: "Quels-moyens-pourrais-je-utiliser-pour-payer-la-reservation-d-un-vehicule",
				question:
					"Quels moyens pourrais-je utiliser pour payer la réservation d’un véhicule?",
				answer: "<p>Nous vous offrons la possibilité d’effectuer le paiement de votre réservation soit par carte bancaire ou prépayé (VISA, MASTERCARD) ou par mobile money (ORANGE MONEY, MTN MONEY, MOOV AFRICA MONEY, WAVE), directement à partir de l’application.</p> <p>C’est simple, rapide et intuitif.</p>",
			},
			{
				slug: "Pourrais-je-contacter-le-proprietaire-du-vehicule",
				question: "Pourrais-je contacter le propriétaire du véhicule?",
				answer: "<p>Effectivement ! Pour cela vous devriez envoyer une demande de location à ce dernier. Une fois qu’il l’aura accepté, vous devriez vous acquitter du coût de la location.<br>Ainsi, un espace d’échange, accessible sur le profil du propriétaire est mis à votre disposition. Vous pourriez également le contacter directement au téléphone.</p>",
			},
			{
				slug: "Pourrais-je-louer-un-vehicule-avec-ou-sans-chauffeur",
				question:
					"Pourrais-je louer un véhicule avec ou sans chauffeur?",
				answer: "<p>Chaque propriétaire propose son véhicule à la location avec ou sans chauffeur. Ainsi, la plateforme Primecar intègre des véhicules disponibles suivant les conditions proposées par les propriétaires.</p><p>Dans le cas d’une location sans chauffeur, il faudra être titulaire d’un permis de conduire valide dans le pays dans lequel le véhicule est loué.</p><p>Si vous êtes titulaire dun permis de conduire étranger, il faudra vous assurer de sa validité dans le pays hôte notamment par le biais d’accords bilatéraux existants</p>",
			},
		],
	},
	{
		title: "Réservez votre véhicule",
		questionsAnswers: [
			{
				slug: "Comment-je-procede-pour-reserver-un-vehicule",
				question: "Comment je procède pour réserver un véhicule?",
				answer: "<p>Tout se fait à partir de l’application Primecar. Il faudra d’abord la télécharger. Ensuite vous vous y enregistrez en sorte de rechercher les véhicules que souhaiteriez louer. Un filtre avancer vous permettra d’indiquer exactement les caractéristiques des véhicules que vous cherchez.</p><p> Une fois que vous les aurez repérés, vous pourriez envoyer une demande de location, aux propriétaires, pour les modèles qui vous intéresse. N’hésitez surtout pas à multiplier vos demandes pour maximiser vos chances de réaliser rapidement une réservation.</p><p>Ainsi, dès lors que le propriétaire accepte votre demande de location, vous devriez la confirmer en procédant au paiement du coût total de la location sur notre application, via un espace sécurisé, soit par carte bancaire (VISA, MASTERCARD), soit par mobile money (ORANGE MONEY, MTN MONEY, MOOV AFRICA MONEY, WAVE).</p><p>Vous recevrez un mail et une notification par sms confirmant que l’opération de paiement s’est bien effectuée.</p><p> Et c’est parti ! vous pourriez directement écrire un message au propriétaire à partir de son profil sur notre plateforme ou l’appeler directement !</p>",
			},
			{
				slug: "Pourrais-je-modifier-ma-reservation",
				question: "Pourrais-je modifier ma réservation?",
				answer: "<p>Oops ☹, c’est malheureusement impossible de le faire.<br>Vous pourriez, par conséquent, annuler la réservation au plus tôt en sorte de soumettre une nouvelle demande.</p><p> Toutefois, il vous est possible de prolonger votre réservation !</p>",
			},
			{
				slug: "A-quel-moment-pourrais-je-annuler-ma-reservation",
				question: "À quel moment pourrais-je annuler ma réservation?",
				answer: `<p>Vous pourriez annuler votre réservation tant que le contrat n’est pas effectif. Ceci est valable tant pour vous que pour le propriétaire.</p><p> Ainsi, il faudra vous rentre sur l’interface d’historique des paiements, accessible à travers le menu en haut à de gauche, dans l’application. Vous y trouverez l’option d’annulation de votre réservation.</p><p>Prenez garde, il est possible que des pénalités s’applique selon le délai avant le début du contrat de location.<p><ul><li>Une annulation plus de quarante-huit (48h) heures avant le début de la location donne lieu au remboursement intégral du prix total de la location sans application d’une éventuelle pénalité ou d’une retenue. Le remboursement se fera sous trois (3) jours ouvrés à partir de la date où elle aura été initiée.</li><li>Une annulation moins de quarante-huit (48h) heures avant le début de la location fait valoir un remboursement partiel du prix total de la location après avoir retenue le coût de la première journée de la location et des frais de service. Le propriétaire se verra rétribué 60% du montant de la première journée de location en guise de dédommagement.<br><div><img src="${pic1}" alt="like" className="object-contain " /></div></li><li> Si votre contrat est déjà en cours et que vous souhaitez l’annuler, il vous faudra impérativement contacter le service client.</li></ul>`,
			},
			{
				slug: "Pourrais-je-prolonger-ma-reservation-si-j-ai-toujours-besoin-du-vehicule",
				question:
					"Pourrais-je prolonger ma réservation si j’ai toujours besoin du véhicule?",
				answer: `<p>Cela est tout à fait possible ! Il vous suffira de vous rendre dans l’application dans la section  Locations . Vous y trouverez le véhicule qui fait l’objet de votre location et tout au bas de la section, vous apercevrez le bouton  Prolonger .<p></p>Il vous suffira d’introduire une nouvelle demande auprès du propriétaire. Et si ce dernier l’accepte, vous n’aurez qu’a payer pour confirmer la prolongation de la réservation.</p><div><img src="${pic2}" alt="like" className="object-contain " /></div>`,
			},
		],
	},
	{
		title: "Récupérez-le et partez",
		questionsAnswers: [
			{
				slug: "A-quel-moment-je-recupere-le-vehicule",
				question: "À quel moment je récupère le véhicule ?",
				answer: "<p>Que votre location soit avec ou sans chauffeur, vous devrez confirmer le lieu et l’heure de rendez-vous au plutôt avec le propriétaire. Ce dernier fournira deux exemplaires du contrat de location que vous devriez vérifier et signer.<p></p>Le contrat de location est accompagné d’un état de lieux si la location est sans chauffeur. Il convient de l’exécuter minutieusement à mesure que ce document peut faire office de référence en cas de litiges.</p>",
			},
			{
				slug: "Ma-location-est-elle-protegee-par-un-contrat-de-location",
				question:
					"Ma location est-elle protégée par un contrat de location ?",
				answer: "<p>Absolument, toute location est précédée d’un contrat de location entre les parties. Le propriétaire fournira deux exemplaires du contrat de location que vous devriez vérifier et signer.</p><p>Voici les situations qui pourraient s’y prêter :</p><p>Dans le cas d’une location sans chauffeur, le propriétaire doit fournir les documents originaux de la carte grise, le certificat de visite technique ainsi que l’attestation de l’assurance du véhicule. De même, le locataire fournira au propriétaire une copie de sa pièce d’identité et de son permis de conduire. Un état des lieux du véhicule sera nécessaire, en plus du contrat de location, avant la remise des clés.</p><p>Dans le cas d’une location avec chauffeur, le propriétaire mettra à disposition un chauffeur pour les déplacements du locataire. Il sera tenu de fournir une photocopie de la carte grise, du certificat de visite technique et de l’attestation du véhicule.</p><p> Le locataire, quant à lui, ne fournira qu’une copie de sa pièce d’identité. L’état des lieux du véhicule n’est pas obligatoire à mesure que le locataire ne conduira pas le véhicule.Le propriétaire devra renseigner les informations du locataire et celles requises pour son véhicule dans le contrat de location.</p><p> Le locataire devra vérifier que les informations sont conformes. Et tous les deux procèderont à la signature du contrat de location. Le contrat de location devra être de nouveau présenté à la fin de la location pour procéder à l’état des lieux du retour. Ce document pourra être utiliser comme évidence en cas de litiges. Ainsi, il convient aux parties de l’exécuter de manière rigoureuse.</p>",
			},
			{
				slug: "La-voiture-louee-est-elle-en-bon-etat",
				question: "La voiture louée est-elle en bon état ?",
				answer: "<p>Les propriétaires volontaires pour mettre leur véhicule en location, chez Primecar, sont engagés à mettre à votre disposition un véhicule en parfait état.</p><p>Chaque véhicule dispose de contrôle technique à jour vérifiable au rendez-vous de récupération du véhicule.</p><p>Nous vous invitons à signaler tous véhicules présentant des disfonctionnements, au plutôt en sorte de nous permettre de les restreindre de la plateforme en sorte d’obtenir du propriétaire qu’il effectue le contrôles et réparations nécessaires.</p><p>De même, notre plateforme vous offre la possibilité de laisser un avis après chaque location !</p>",
			},
			{
				slug: "Pourrais-je-savoir-qui-contacter-en-cas-de-probleme",
				question:
					"Pourrais-je savoir qui contacter en cas de problème ?",
				answer: "<p>Le propriétaire est votre interface principale quelque soit le problème rencontré sur le véhicule. Nous vous suggérons de le contacter pour l’informer de la situation.</p><p>Toutefois, si le véhicule est immobilisé et nécessite d’être remorqué, nous vous prions de contacter notre service clientèle, avec l’accord du propriétaire, pour vous faire venir des équipes pour vous assister dans ce sens.</p>",
			},
			{
				slug: "Que-faire-en-cas-d’accident",
				question: "Que faire en cas d’accident ?",
				answer: "<p>Dans le cas où vous êtes sujet à un accrochage ou à d’autre soucis du même type, le propriétaire demeure votre premier interlocuteur : Suivant la gravité de l’accrochage, une solution amiable peut être trouvée ou un constat pourrait être fait.</p><p>En cas d’accident, nous vous offrons de contacter notre service client au plus vite.</p><p>Dans tous les cas, le propriétaire doit être informer de la situation.</p>",
			},
		],
	},
	{
		title: "Après la location",
		questionsAnswers: [
			{
				slug: "Pourrais-je-laisser-un-avis-apres-une-location",
				question: "Pourrais-je laisser un avis après une location?",
				answer: "<p>Absolument, à la fin de chaque location vous avez la possibilité de laisser un avis dans la section « Locations > Terminées ». Le bouton « Laisser un avis » est disponible à cet effet.</p>",
			},
			{
				slug: "Et-si-je-dois-être-rembourser",
				question: "Et si je dois être rembourser?",
				answer: "<p>Qu’il s’agisse du propriétaire ou du locataire, nous procédons au remboursement de votre dû sous trois jours ouvrés dès que celui-ci a été confirmé par nos équipes du service clients.</p>",
			},
		],
	},
];

export const newData2 = [
	{
		title: "Déposez votre véhicule",
		questionsAnswers: [
			{
				slug: "Pourrais-je-deposer-mon-vehicule-pour-la-location",
				question: "Pourrais-je déposer mon véhicule pour la location?",
				answer: "<p>Absolument, vous devrez vous assurez que votre véhicule est en très bon état et qu’il dispose d’un contrôle technique à jour (à l’exception des véhicules de moins de 4 ans) !</p> Ainsi, en tant que propriétaire, vous devrez remplir sur l’application via votre compte, une fiche Produit du véhicule (marque, modèle, etc.), comprenant ses caractéristiques. Vous devrez vous engager à signaler les détails essentiels de votre véhicule (par exemple, le volume, la hauteur, la longueur ou la charge utile lorsque celui-ci est un utilitaire, …) en sorte de fournir le plus d’informations possible au locataire.</p><p> Veuillez préparer les documents ci-après avant le dépôt sur la plateforme:</p><ul><li>Votre pièce d’identité (CNI, Passeport)</li><li> La carte grise du véhicule</li><li> Un certificat d’assurance valide du véhicule couvrant le locataire, ainsi que les passagers, qu’il soit chauffeur ou non.</li></ul><p>De plus, vous devriez communiquer un numéro de téléphone à partir duquel le locataire pourra vous joindre facilement.</p><p> Une fois le dépôt effectué, nous procèderons aux vérifications nécessaires et si tout est conforme, votre véhicule sera disponible à la location sur la plateforme dans un délais de vingt-quatre (24h).</p>",
			},
			{
				slug: "Pourrais-je-deposer-mon-vehicule-pour-la-location-avec-chauffeur",
				question:
					"Pourrais-je déposer mon véhicule pour la location avec chauffeur?",
				answer: "<p>Absolument, cela est tout à fait possible. Lors au dépôt de votre véhicule sur notre plateforme, cette option vous est proposée. Ainsi, les locataires sauront que votre véhicule est loué avec ou sans chauffeur.</p>",
			},
			{
				slug: "Je-loue-mon-vehicule-exclusivement-pour-la-zone-d-Abidjan-que-faire",
				question:
					"Je loue mon véhicule exclusivement pour la zone d’Abidjan, que faire?",
				answer: "<p>Absolument, cela est tout à fait possible. Lors au dépôt de votre véhicule sur notre plateforme, cette option vous est proposée. Ainsi, les locataires sauront que votre véhicule est louable pour les déplacements dans le « Grand-Abidjan » ou « Hors d’Abidjan », c’est-à-dire dans toute la Côte d’Ivoire.</p>",
			},
			{
				slug: "Le-depot-de-vehicule-est-il-valable-pour-les-professionnels",
				question:
					"Le dépôt de véhicule est-il valable pour les professionnels?",
				answer: "<p>Absolument, les professionnels peuvent bel et bien publier leurs véhicules disponibles à la location sur Primecar.</p><p>Nous vous prions de nous contacter pour remplir les formalités administratives après quoi nous vous donnerons accès à la plateforme.</p>",
			},
			{
				slug: "Pourrais-je-gerer-la-disponibilite-de-mon-vehicule",
				question: "Pourrais-je gérer la disponibilité de mon véhicule?",
				answer: "<p>Si pour une raison ou une autre votre véhicule n’est plus, temporairement, disponible à la location, vous pourriez le mettre en mode « veille » de sorte à ce qu’il ne soit pas visible par les locataires sur la plateforme.</p><p> Lorsque votre véhicule est en location sur Primecar, il est immédiatement rendu indisponible à la location pour toute la période où il est loué. Ainsi, si un locataire souhaite le louer en dehors de la période de location, il pourra le faire.</p>",
			},
			{
				slug: "Comment-etre-sur-de-l-etat-de-mon-vehicule",
				question: "Comment être sûr de l’état de mon véhicule?",
				answer: "<p>Nous vous recommandons de faire faire une révision pour votre véhicule auprès des technicien compétents en la matière dans le but de s’assurer du bon état des éléments suivant (liste non exhaustive):</p><ul><li> Le niveau des fluides notamment les liquides moteurs, de freins, de refroidissement et le lave-glace </li><li>Les éclairages et les éléments de signalisation (exemple : clignotants, témoins, signaux de détresse, etc.)</li><li> Les pneumatiques</li><li> Le pare-brise, les essuie-glaces et les rétroviseurs</li><li> Le système de freinage</li><li> Les équipements de sécurité (exemple : une roue de secours)</li></ul> <p>Aussi, nous vous invitons à conforter cette révision avec un contrôle technique favorable.</p>",
			},
			{
				slug: "Suis-je-libre-de-fixer-mon-tarif-journalier-pour-la-location-de-mon-vehicule",
				question:
					"Suis-je libre de fixer mon tarif journalier pour la location de mon véhicule?",
				answer: "<p>Il s’agit de votre véhicule ! Oui, c’est vous qui fixez le tarif journalier de location de votre véhicule. Nous vous proposons les prix observés sur le marché à titre indicatif.</p>",
			},
			{
				slug: "Quelle-commission-prelevez-vous-sur-mes-gains",
				question: "Quelle commission prélevez-vous sur mes gains?",
				answer: "<p>Pour le bon fonctionnement de notre plateforme, la rémunération des personnes qui la fait vivre ainsi que les autres charges et taxes encourus, nous vous prélevons :</p><ul><li>La somme de 5000 FCFA/Jour pour toutes vos locations en dessous de 25 000 FCFA/jour.</li><li>Au delà, nous prélevons 15% sur le coût total de la location. </li><li>Si vous recevez une offre de location de la part du locataire, lequel vous propose un budget en deçà de votre tarif journalier, notre commission est graduellement revue à la baisse en fonction du montant et peu aller jusqu'à 10% selon les cas. Vous verrez plus de détails dans l’offre de location que vous recevrez.</li></ul>",
			},
		],
	},
	{
		title: "Acceptez les demandes de location",
		questionsAnswers: [
			{
				slug: "J-ai-une-demande-de-location-que-faire",
				question: "J’ai une demande de location, que faire?",
				answer: "<p>C’est très simple ! Lorsqu’un locataire introduit une demande de location, il vous suffit de l’accepter en sorte de lui permettre de procéder à la confirmation par le biais du paiement du coût total de la location.</p><p> Une fois que le locataire aura fait le paiement, il pourra vous contacter directement sur le numéro de téléphone que vous aurez communiqué sur la plateforme ou sur la messagerie disponible à cet effet.</p><p> Il vous appartiendra de convenir le plus rapidement d’un lieu de rendez-vous pour la remise de la voiture après avoir signer et vérifier ensemble le contrat de location.</p>",
			},
			{
				slug: "Je-souhaite-annuler-la-reservation-en-cours",
				question: "Je souhaite annuler la réservation en cours?",
				answer: "<p>Il est tout à fait possible d’annuler la réservation en cours à partir de la plateforme si le contrat de location n’a pas encore débuté. Toutefois, en fonction des cas, certaines pénalités peuvent s’appliquer.</p><ul><li> Le propriétaire peut annuler la location quarante-huit (48h) heures avant le début de la location sans être sujet à pénalité.</li><li> Si le propriétaire souhaite annuler la location à moins de quarante-huit (48h) heures avant le début du contrat, il est automatiquement sujet à pénalité suivant les cas ci-après :</li> <ul><li> Pénalité de 4000 FCFA si l’annulation a lieu entre 36h et 48h avant le début de la location.</li><li> Pénalité de 7000 FCFA si l’annulation a lieu entre 6h et 36h avant le début de la location.</li><li> Pénalité de 10000 FCFA si l’annulation a lieu 6h avant le début de la location.</li></ul></ul> <p>Ce montant sera déduit du prochain avoir du propriétaire sur la plateforme.</p><p> Par conséquent, si le souhait d’annulation à lieu après le début de la location, le propriétaire devra contacter l’équipe en charge du service clients.</p>",
			},
			{
				slug: "Et-si-je-dois-etre-rembourser",
				question: "Et si je dois être rembourser?",
				answer: "<p>Qu’il s’agisse du propriétaire ou du locataire, nous procédons au remboursement de votre dû sous trois jours ouvrés dès que celui-ci a été confirmé par nos équipes du service clients.</p>",
			},
		],
	},
	{
		title: "Faites plus du revenu",
		questionsAnswers: [
			{
				slug: "Combien-je-gagne-pour-ma-location",
				question: "Combien je gagne pour ma location?",
				answer: "<p>Votre gain dépendra du type de véhicule, de son état, du nombre de jours que vous mettrez à disposition pour la location. Certains membres de la Primecar gagnent jusqu’à 500 000 FCFA/mois sur la base d’une estimation des tarifs que nous recommandons pour la location sur notre plateforme.</p><p> Néanmoins, nous vous suggérons de faire une étude des prix proposés sur la plateforme spécifiquement pour le type de véhicule que vous avez. Elle vous permettra de proposer des prix compétitifs.</p><p> Un véhicule en très bon état et au meilleur prix recevra de bons commentaires de la part des locataires : Il pourra booster vos réceptions de demandes de location.</p>",
			},
			{
				slug: "Quand-et-comment-pourrais-je-percevoir-mes-gains",
				question: "Quand et comment pourrais-je percevoir mes gains?",
				answer: "<p>Une fois la location terminée, nous vous reversons vos gains les mardi ou jeudi de la semaine qui suivent la fin de la location. Ce versement peut toutefois faire l’objet de retard pour des raisons tenant à des contraintes bancaires et/ou à des cas de force majeure en vertu de la loi en vigueur.</p><p>Dans le cas d’une location excède un mois, nous pouvons vous reverser votre gain mensuel à la demande : par exemple, pour une location de 45 jours, vous percevrez le montant des 30 premiers jours et le reste une fois la location terminée.</p><p>  Il en va de même dans le cas d’une prolongation, nous pouvons vous reverser votre gain à la demande à la fin de la première période de réservation.</p><p>Pour la rétribution de votre gain, nous vos offrons la possibilité de payer soit par virement bancaire (votre RIB ou Relevé d’Information Bancaire sera requis), soit par le biais d’une carte bancaire de débit ou par mobile money. Nous vous prions de nous contacter si vous avez d’autres questions.</p>",
			},
		],
	},
];
export const otherArticle = [
	{
		title: "Déposez votre véhicule",
		questionsAnswers: [
			{
				slug: "Pourrais-je-deposer-mon-vehicule-pour-la-location-avec-chauffeur",
				question:
					"Pourrais-je déposer mon véhicule pour la location avec chauffeur?",
				answer: "<p>Absolument, cela est tout à fait possible. Lors au dépôt de votre véhicule sur notre plateforme, cette option vous est proposée. Ainsi, les locataires sauront que votre véhicule est loué avec ou sans chauffeur.</p>",
			},
			{
				slug: "Pourrais-je-gerer-la-disponibilite-de-mon-vehicule",
				question: "Pourrais-je gérer la disponibilité de mon véhicule?",
				answer: "<p>Si pour une raison ou une autre votre véhicule n’est plus, temporairement, disponible à la location, vous pourriez le mettre en mode « veille » de sorte à ce qu’il ne soit pas visible par les locataires sur la plateforme.</p><p> Lorsque votre véhicule est en location sur Primecar, il est immédiatement rendu indisponible à la location pour toute la période où il est loué. Ainsi, si un locataire souhaite le louer en dehors de la période de location, il pourra le faire.</p>",
			},
			{
				slug: "Le-depot-de-vehicule-est-il-valable-pour-les-professionnels",
				question:
					"Le dépôt de véhicule est-il valable pour les professionnels?",
				answer: "<p>Absolument, les professionnels peuvent bel et bien publier leurs véhicules disponibles à la location sur Primecar.</p><p>Nous vous prions de nous contacter pour remplir les formalités administratives après quoi nous vous donnerons accès à la plateforme.</p>",
			},
			{
				slug: "Je-loue-mon-vehicule-exclusivement-pour-la-zone-dabidjan-que-faire",
				question:
					"Je loue mon véhicule exclusivement pour la zone d’Abidjan, que faire?",
				answer: "<p>Absolument, cela est tout à fait possible. Lors au dépôt de votre véhicule sur notre plateforme, cette option vous est proposée. Ainsi, les locataires sauront que votre véhicule est louable pour les déplacements dans le « Grand-Abidjan » ou « Hors d’Abidjan », c’est-à-dire dans toute la Côte d’Ivoire.</p>",
			},
		],
	},
];
