import React, { useState, useEffect } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { UpdateAfiliateUser } from '../../../lib/Api/Marketing'
import LoaderSpinner from '../../../util/LoaderSpinner';

// Validation Scema
const schema = yup.object({
    first_name: yup.string().required("first name is required"),
    last_name: yup.string().required("last name is required"),
    email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
    description: yup.string().required("Description is required"),
});

const EditData = ({ singleCoupon, handleClose, setShow }) => {

    const [loading, setLoading] = useState(false);

    // React hook form initilization
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({ mode: "onChange", resolver: yupResolver(schema) });

    // Submit Form 
    const onSubmit = async (data) => {
        try {
            setLoading(true);
            data.id = singleCoupon.id
            let { res } = await UpdateAfiliateUser(data);
            if (res !== undefined) {
                setLoading(false);
                handleClose()
                reset();
            } else {
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
        }
    };

   



    useEffect(() => {
        if (singleCoupon) {
            let payload = {
                first_name: singleCoupon?.firstname,
                last_name: singleCoupon?.lastname,
                email:singleCoupon?.email,
                description: singleCoupon.notes
            }
            reset(payload)
        }
    }, [])



    return (
        <>
            <form className='' onSubmit={handleSubmit(onSubmit)}>
                <h2 className='text-[#444444] font-semibold'>Edit Afiliate User</h2>
                <div className='flex flex-col'>
                    <label className='pb-2 text-[#606060] text-[14px]'>First Name</label>
                    <input
                        type="text"
                        {...register("first_name")}
                        placeholder="First Name"
                        className={`border border-[#CDD2E1] h-[42px] placeholder:text-[14px] text-[14px] rounded-md text-[#ACACAC] focus:outline-none px-2.5 ${errors.first_name && "border-red-500"
                            }`}
                    />
                    {errors.first_name && (
                        <p className="text-red-500 text-sm text-start pt-1 ">
                            {errors.first_name.message}
                        </p>
                    )}
                </div>
                <div className='flex flex-col'>
                    <label className='pb-2 text-[#606060] text-[14px]'>Last Name</label>
                    <input
                        type="text"
                        {...register("last_name")}
                        placeholder="Last Name"
                        className={`border border-[#CDD2E1] h-[42px] placeholder:text-[14px] text-[14px] rounded-md text-[#ACACAC] focus:outline-none px-2.5 ${errors.last_name && "border-red-500"
                            }`}
                    />
                    {errors.last_name && (
                        <p className="text-red-500 text-sm text-start pt-1 ">
                            {errors.last_name.message}
                        </p>
                    )}
                </div>
                <div className='flex flex-col'>
                    <label className='pb-2 text-[#606060] text-[14px]'>Email</label>

                    <input
                        type="email"
                        disabled
                        {...register("email")}
                        placeholder='Email'
                        className={`border border-[#CDD2E1] h-[42px] ticket placeholder:text-[14px] text-[14px] rounded-md text-[#ACACAC] focus:outline-none px-2 ${errors.email && "border-red-500"
                            }`}
                    />
                    {errors.email && (
                        <p className="text-red-500 text-sm text-start pt-1 ">
                            {errors.email.message}
                        </p>
                    )}
                </div>
                
                <div className='pt-5'>
                    <label className='X text-[#606060] text-[14px]'>Description</label>
                    <textarea
                        placeholder='description'
                        {...register("description")}
                        className={`resize-none border border-[#CDD2E1] w-full placeholder:text-[14px] text-[14px] py-2 text-[#ACACAC] focus:outline-none px-2 rounded-md ${errors.description && "border-red-500"
                            }`}
                    ></textarea>
                    {errors.description && (
                        <p className="text-red-500 text-sm text-start pt-1 ">
                            {errors.description.message}
                        </p>
                    )}
                </div>
                <div className='pt-5 flex justify-end items-end'>
                    <button onClick={() => setShow(false)} className='w-[138px] py-2 mx-5 rounded-md bg-transparent border-[#ACACAC] border text-[#ACACAC]'>Cancel</button>
                    <button className='bg-[#FF8C00] text-white h-[40px] px-8   rounded-md'>
                        {loading ? <LoaderSpinner /> : "Update"}
                    </button>
                </div>


            </form >
        </>
    )
}

export default EditData