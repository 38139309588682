import React, { useState } from 'react'
import copy from '../../../assests/copy.png'
import LoaderSpinner from '../../../util/LoaderSpinner'
import { SendAffiiateLink } from '../../../lib/Api/Marketing'
import { toast } from "react-toastify"

const ShareData = ({ singleCoupon }) => {
    const [loading, setLoading] = useState(false)
    // const [isCopied, setIsCopied] = useState(false);


    let resendLink = async () => {
        setLoading(true)
        let { res } = await SendAffiiateLink(singleCoupon?.id)
        if (res) {
            setLoading(false)
        }
        else {
            setLoading(false)

        }
    }



    const handleCopyClick = async () => {
        try {
            await navigator.clipboard.writeText(singleCoupon?.link);
            toast.success("link Copied")
            // setIsCopied(true);
        } catch (err) {
            console.error('Unable to copy to clipboard', err);
        }

        // Reset copied state after a short delay
        // setTimeout(() => {
        //     setIsCopied(false);
        // }, 2000);
    };

    return (
        <>
            <div className=''>
                <h2 className='text-[#444444] font-semibold'>Refer the Promocode</h2>
                <p className='text-[#606060] leading-[20px] text-[14px] pt-2 max-w-[520px]'>You Have created this affiliate user. Share this with him</p>
                <div className='flex flex-col pt-5'>
                    <label className='pb-3 text-[#606060] text-[14px]'> Affiliate Link </label>
                    <div className='flex justify-between items-center border px-2 rounded-md'>
                        <input type="text" readOnly  value={singleCoupon?.link} placeholder={singleCoupon?.link} className='w-full  h-[42px] ticket placeholder:text-[14px] rounded-md text-[#ACACAC] focus:outline-none px-2 ' />
                        <img onClick={()=> handleCopyClick()} src={copy} alt="copy" className='object-cover cursor-pointer' />
                    </div>
                </div>

                <div className='flex justify-end pt-5'>
                    <button onClick={() => resendLink()} className='bg-[#FF8C00] text-white h-[40px] w-full rounded-md'>
                        {
                            loading ?
                                <LoaderSpinner />
                                :
                                "Resend to affiliate user by email"
                        }

                    </button>
                </div>

            </div>
        </>
    )
}

export default ShareData