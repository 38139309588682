import React, { useEffect } from "react";
import Layout from "../../components/layouts/mainLayout/MainLayout";
import Banner from "../../components/MainLandingComp/landing/Banner";
import Services from "../../components/MainLandingComp/landing/Services";
import Occasions from "../../components/MainLandingComp/landing/Occasions";
// import Notre from '../../components/MainLandingComp/landing/Notre'
import Rentable from "../../components/MainLandingComp/landing/Rentable";
import Application from "../../components/MainLandingComp/landing/Application";
import VotreMobilite from "../../components/MainLandingComp/landing/VotreMobilite";
import NotreMobility from "../../components/MainLandingComp/landing/NotreMobility";
import LatestBlogArticleSection from "../../components/MainLandingComp/landing/LatestBlogArticleSection";
import { Helmet } from "react-helmet";

const Landing = () => {
	return (
		<Layout>
			<Helmet>
				<title>
					Primecar : Location voiture, utilitaire & engin lourd – Côte
					d’Ivoire
				</title>
				<meta
					name="description"
					content="Débloquez votre mobilité en Côte d’Ivoire avec Primecar. Des services de location de voitures à la location de camions à Abidjan, nous proposons des solutions de transport fiables."
				/>
				<link rel="canonical" href="https://primecarapp.com/" />
			</Helmet>

			<Banner />
			<Services />
			<Occasions />
			{/* <Notre /> */}
			<NotreMobility />
			<Rentable />
			<Application />
			<VotreMobilite />
			<LatestBlogArticleSection />
		</Layout>
	);
};

export default Landing;
