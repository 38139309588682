import React from "react";
import closer2 from "../../../assests/Group-1261153751-x1.webp";

const Habits = () => {
	return (
		<div className="flex bg-[#F8F8F8] flex-row items-center justify-center md:justify-start px-5 md:px-20">
			<div className=" bg-[#F8F8F8] py-10 md:py-11 lg:py-12 xl:py-16  flex flex-col items-center md:items-start 2xl:w-[1440px] md:m-auto ">
				<p className="text-[#444444]  text-center md:text-left font-semibold lg:font-bold text-2xl xl:text-3xl ">
					<span>Facilitez davantage vos habitudes et celles de</span>{" "}
					<br />
					<span className="text-[#FF8C00]">vos équipes</span>
				</p>

				<div className="flex flex-col md:flex-row mt-10 gap-6 md:gap-8 lg:gap-12 xl:gap-[80px] items-center xl:justify-between">
					<div className="flex flex-col gap-6 md:gap-8 lg:gap-10 xl:gap-12 max-w-full md:max-w-none xl:max-w-3xl md:w-1/2">
						<div className="flex flex-col gap-3">
							<div className="flex bg-white rounded-md overflow-hidden group shadow hover:shadow-lg">
								<div className="bg-[#FF8C00] p-0.5 group-hover:p-2.5 transition-all ease-in-out duration-150"></div>
								<div className="flex flex-col gap-1 p-3 lg:p-4 xl:p-6">
									<p className="text-[#FF8C00] text-sm lg:text-base xl:text-lg font-semibold">
										Moins de contraintes
									</p>
									<p className="text-[#667085] text-xs lg:text-sm">
										Plus nécessaire de se déplacer, gagnez
										du temps et faites tout à partir de vos
										bureaux ou chez vous.
									</p>
								</div>
							</div>

							<div className="flex bg-white rounded-md overflow-hidden group shadow hover:shadow-lg">
								<div className="bg-[#FF8C00] p-0.5 group-hover:p-2.5 transition-all ease-in-out duration-150"></div>
								<div className="flex flex-col gap-1 p-3 lg:p-4 xl:p-6">
									<p className="text-[#FF8C00] text-sm lg:text-base xl:text-lg font-semibold">
										Réservez au dernier moment
									</p>
									<p className="text-[#667085] text-xs lg:text-sm">
										Votre statut vous confère la possibilité
										de réserver un véhicule jusqu'à
										quatre(4) heures avant le début de la
										location.
									</p>
								</div>
							</div>

							<div className="flex bg-white rounded-md overflow-hidden group shadow hover:shadow-lg">
								<div className="bg-[#FF8C00] p-0.5 group-hover:p-2.5 transition-all ease-in-out duration-150"></div>
								<div className="flex flex-col gap-1 p-3 lg:p-4 xl:p-6">
									<p className="text-[#FF8C00] text-sm lg:text-base xl:text-lg font-semibold">
										Paiement en ligne
									</p>
									<p className="text-[#667085] text-xs lg:text-sm">
										Un unique moyen de paiement pour toutes
										vos équipes. Vos reçus sont
										immédiatement générés au nom de votre
										entreprise avec le détail de vos
										opérations.
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="md:w-1/2">
						<img
							src={closer2}
							alt="closer_img"
							className="object-contain w-full"
							// className='object-contain w-full h-full max-w-md md:max-w-none xl:max-w-3xl md:w-1/2 xl:object-contain xl:max-h-[500px]'
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Habits;
