import React from 'react'
import Messages from './chats/Messages'
import MediaList from './chats/MediaList'
import UsersList from './chats/UsersList';
const CustomerSuppport = ({ users, convId, setConvId, currentUser, setUpdateMessage, title }) => {

  return (
    <>
      {/* {users?.length > 0 ? ( */}
      <div className="grid lg:grid-cols-4 gap-5">
        <div>
          <UsersList
            users={users}
            convId={convId}
            setConvId={setConvId} />
        </div>
        <div className="lg:col-span-2">
          <Messages
            convId={convId}
            users={users}
            currentUser={currentUser}
            setUpdateMessage={setUpdateMessage}
            title={title} />
        </div>
        <div>
          <MediaList />
        </div>
      </div>
      {/* ) : (
        <div className="px-5 py-5">
          {loading ? <LoaderSpinner type="tableLoad" /> : "Record Not Found"}
        </div>
      )} */}
    </>
  )
}

export default CustomerSuppport