import React, { useState, useEffect } from "react";
import closer from "../../../assests/girl-image.webp";
import closer2 from "../../../assests/girl-image-small.webp";
const Closer = () => {
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const imageSource = windowWidth >= 768 ? closer : closer2;
	return (
		<div className="bg-[#F8F8F8]">
			<div className="px-5 md:px-20">
				<div className="flex flex-col md:flex-row py-10 md:py-12 lg:py-14   gap-6 md:gap-8 lg:gap-14  xl:gap-[70px] 2xl:w-[1440px]  m-auto  items-center  xl:items-end">
					<div className="flex flex-col gap-6 md:gap-8 lg:gap-10 xl:gap-12 md:w-1/2 xl:w-[700px]">
						<p className="font-bold text-xl lg:text-2xl xl:text-3xl text-center md:text-left">
							Nous sommes plus proches de vous que{" "}
							<span className="text-[#FF8C00]">
								vous ne l'imaginez
							</span>
						</p>

						<div className="flex flex-col gap-3 lg:gap-4 w-full">
							<div className="flex bg-white rounded-md overflow-hidden group hover:shadow-lg">
								<div className="bg-[#FF8C00] p-0.5 group-hover:p-2.5 transition-all duration-150 ease-in-out"></div>
								<div className="flex flex-col gap-1 p-3 lg:p-4 xl:p-6">
									<p className="text-[#FF8C00] text-sm lg:text-base xl:text-lg font-semibold">
										Un service client disponible
									</p>
									<p className="text-[#667085] text-xs lg:text-sm">
										Votre satisfaction est au centre de nos
										priorités. Notre équipe d'assistance
										clients est disponible 6j/7 de 08h00 à
										20h00
									</p>
								</div>
							</div>

							<div className="flex bg-white rounded-md overflow-hidden group hover:shadow-lg">
								<div className="bg-[#FF8C00] p-0.5 group-hover:p-2.5 transition-all duration-150 ease-in-out"></div>
								<div className="flex flex-col gap-1 p-3 lg:p-4 xl:p-6">
									<p className="text-[#FF8C00] text-sm lg:text-base xl:text-lg font-semibold">
										Une assistance 24h/24 en cas de panne ou
										d'accident
									</p>
									<p className="text-[#667085] text-xs lg:text-sm">
										ous vous offrons la possibilité de
										bénéficier d’un contrat d’assistance à
										tout moment si votre véhicule vient à
										être en panne et doit être remorqué.
									</p>
								</div>
							</div>

							<div className="flex bg-white rounded-md overflow-hidden group hover:shadow-lg">
								<div className="bg-[#FF8C00] p-0.5 group-hover:p-2.5 transition-all duration-150 ease-in-out"></div>
								<div className="flex flex-col gap-1 p-3 lg:p-4 xl:p-6">
									<p className="text-[#FF8C00] text-sm lg:text-base xl:text-lg font-semibold">
										Des conseils pour une assurance adaptée
										pour votre location
									</p>
									<p className="text-[#667085] text-xs lg:text-sm">
										Nous vous recommandons de prendre une
										assurance qui protège votre véhicule et
										votre locataire qu’il soit le chauffeur
										ou non.
									</p>
								</div>
							</div>
						</div>
					</div>

					{/* <img src={closer} alt="closer_img" className='object-cover w-full h-full max-w-md md:max-w-2xl md:w-1/2 xl:object-contain xl:max-h-[450px]' /> */}
					<div className=" w-[350px] h-[284.72px] md:w-[504px] md:h-[446px] relative flex flex-row items-center justify-center">
						<div className="absolute right-0 top-0 w-[137px] h-[94px] md:w-[198px] md:h-[148px] bg-[#EAD2BA] rounded-[8px] z-10"></div>

						<img
							src={closer}
							alt="closer_img"
							className="w-[318px] h-[258px] md:w-[458px] md:h-[405.3px] z-20 relative rounded-[8px] object-cover"
						/>
						<div className="absolute bottom-0 left-0 w-[137px] h-[94px] md:w-[198px] md:h-[148px] bg-[#EAD2BA] rounded-[8px] z-10"></div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Closer;
