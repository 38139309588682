import React from "react";

const BlogArticleCard = ({
	imageSrc,
	tagText,
	time,
	cardText,
	date,
	buttonText,
	slug,
	isButtonShow,
	titleClass = "blog-article-card-text",
}) => {
	return (
		<a href={"/blog/" + slug}>
			<div className="blog-article-card-main">
				<img
					src={imageSrc}
					alt="article1"
					className="blog-article-image"
				/>
				<div className="flex justify-between mt-6">
					<div className="blog-article-card-tag">
						<p className="pt-1 pl-4">{tagText}</p>
					</div>
					<p className="mr-1 blog-article-card-read-time">{time}</p>
				</div>

				<p className={["mt-5", titleClass].join(" ")}>{cardText}</p>
				<p className="blog-article-card-date mt-5">{date}</p>
				{isButtonShow != "false" ? (
					<div className="blog-article-card-button mt-3 pt-1 pl-6">
						{buttonText}
					</div>
				) : null}
			</div>
		</a>
	);
};

export default BlogArticleCard;
