import React, { useEffect, useState, useRef } from "react";
import { DayPicker } from "react-day-picker";

const DateTimeModal = ({
  selected,
  disabled,
  onSelect,
  locale,
  showDatePicker,
  showTimePicker,
  closeModal,
  times,
  handleTime,
  modalOpen,
  type,
  heading,
  isModal1,
}) => {
  const dateRef = useRef(null);
  const date2Ref = useRef(null);
  const timeRef = useRef(null);
  const time2Ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isModal1) {
        if (dateRef.current && !dateRef.current.contains(event.target)) {
          closeModal();
        } else if (timeRef.current && !timeRef.current.contains(event.target)) {
          closeModal();
        }
      } else {
        if (date2Ref.current && !date2Ref.current.contains(event.target)) {
          closeModal();
        } else if (
          time2Ref.current &&
          !time2Ref.current.contains(event.target)
        ) {
          closeModal();
        }
      }
    };

    // Attach the event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Detach the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
      {modalOpen && (
        <div className="modal fixed inset-0 z-50 items-center justify-center overflow-auto bg-black/40">
          <div
            id="modal"
            className="modal-content rounded-t-xl bg-white drop-shadow-xl max-w-3xl w-11/12 animation-slideUp fixed bottom-0 w-full"
          >
            <h2 className="font-medium text-gray-800 text-center text-[30px] mt-[20px]">
              {heading}
            </h2>
            {showDatePicker && (
              <div
                ref={type == "first" ? dateRef : date2Ref}
                className="bg-white  z-40 data_pick "
              >
                <DayPicker
                  mode="single"
                  selected={selected}
                  disabled={disabled}
                  onSelect={onSelect}
                  locale={locale}
                />
              </div>
            )}
            {showTimePicker && (
              <div
                ref={type == "first" ? timeRef : time2Ref}
                className="bg-white lg:w-auto w-full z-40 timPick  right-0 rounded px-5 py-4"
              >
                <div className="max-h-[250px] lg:w-[80px]  overflow-y-auto text-enter time_scroll">
                  {times?.map((time, index) => (
                    <p
                      key={index}
                      onClick={() => handleTime(time, type)}
                      className=" lg:max-w-[120px] text-center w-full text-[15px] font-sans hover:bg-gray-200 cursor-pointer font-normal"
                    >
                      {time?.time}
                    </p>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DateTimeModal;