import React,{useEffect} from "react";

const Download = () => {
         function getMobileOperatingSystem() {
                var userAgent = navigator.userAgent || navigator.vendor || window.opera;
                // Windows Phone must come first because its UA also contains "Android"
                if (/windows phone/i.test(userAgent)) {
                    // return alert("Unsupprted OS");
                }
               else if (/android/i.test(userAgent)) {
                    window.location.replace("https://play.google.com/store/apps/details?id=com.dinsstech.primecar")
                }
               else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                    window.location.replace("https://apps.apple.com/fr/app/primecar/id6446251526")
                }
                else {
                    window.location.replace("/ads/cta/locataires");
                }
            }

            useEffect(()=>{
                getMobileOperatingSystem()
            },[])
            
	return (
 
              	<div
				className="fixed top-0 left-0 w-full bg-white z-50 h-screen flex items-center justify-center"
				id="Loading-Spinner"
			>
				<div className="flex gap-3 items-center">
					<div className="rounded-full p-3 border-4 border-t-[#FF8C00] border-r-[#FF8C00]  animate-spin"></div>
					<p className="text-2xl animate-pulse text-[#FF8C00]">
						Chargement ...
					</p>
				</div>
			</div>
    
	);
};

export default Download;