import React, { useState } from "react";
import info from "../../../assests/PMNeew/botm_sub.svg";
import ProfCheck from "../../../assests/newDesign/prof-check.webp";
import ProfCross from "../../../assests/newDesign/prof-cross.webp";
import { StoreContactForm } from "../../../lib/Api/WebsiteApi";
import LoaderSpinner from "../../../util/LoaderSpinner";

const Information = () => {
	const [showMsg, setShowMsg] = useState(false);
	const [showError, setShowError] = useState(false);
	const [submitButtonLoader, setSubmitButtonLoader] = useState(false);
	const [firstname, setFirstname] = useState("");
	const [lastname, setLastname] = useState("");
	const [telephone, setTelephone] = useState("");
	const [email, setEmail] = useState("");
	const [company, setCompany] = useState("");

	const submitForm = async () => {
		const data = {
			first_name: firstname,
			last_name: lastname,
			phone: telephone,
			email: email,
			company_name: company,
		};
		if (firstname && lastname && telephone && email) {
			setSubmitButtonLoader(true);
			let res = await StoreContactForm(data);
			if (res != null) {
				setShowMsg(true);
				setShowError(false);
				setSubmitButtonLoader(false);
				setFirstname("");
				setLastname("");
				setTelephone("");
				setEmail("");
				setCompany("");
				setTimeout(() => {
					setShowMsg(false);
				}, 2500);
			} else {
				setSubmitButtonLoader(false);
			}
		} else {
			setSubmitButtonLoader(false);
			setShowError(true);
		}
	};

	return (
		<div className="py-10 md:py-11  lg:py-20 xl:py-32 px-5 md:px-20  ">
			<div
				id="prof-form"
				className="flex  flex-col items-center justify-center md:flex-row  lg:rounded-lg sm:px-5 lg:py-3 xl:py-5 gap-6 md:gap-8  lg:gap-12 xl:gap-[62px] md:shadow-xl 2xl:w-[1440px] m-auto"
			>
				<p className=" md:hidden max-w-md md:max-w-none text-center md:text-left text-[#444444] font-semibold lg:font-bold text-2xl xl:text-3xl">
					Renseignez vos informations, nous nous{" "}
					<span className="text-[#FF8C00]">occupons de vous</span>
				</p>

				<div className="h-40  w-full sm:w-full md:w-[411px] md:h-[500px] shadow-lg rounded-md md:rounded-lg overflow-hidden">
					<img
						src={info}
						alt="information_img"
						className="object-cover md:w-full w-full lg:w-full h-full hover:scale-110 transition-all duration-150 ease-in-out"
					/>
				</div>

				<div className="flex flex-col gap-6 md:gap-8 lg:gap-10 xl:gap-12  md:w-[637px] relative w-full">
					<p className="hidden md:block  max-w-md md:max-w-none text-[#444444] font-semibold lg:font-bold text-2xl lg:text-left lg:text-2xl xl:text-3xl ">
						Renseignez vos informations, nous nous{" "}
						<span className="text-[#FF8C00]">occupons de vous</span>
					</p>

					<div className="flex flex-col  gap-3 lg:gap-4 w-full">
						<div className="flex flex-row items-center justify-between gap-2 md:gap-3 w-full">
							<div className="flex flex-col gap-1 w-1/2">
								<label
									htmlFor="Prénom"
									className="text-xs lg:text-sm xl:text-base text-[#667085]"
								>
									Nom
								</label>
								<input
									type="text"
									id="Nom"
									value={firstname}
									className="text-sm lg:text-base xl:text-lg border border-[#ACACAC] rounded-md px-3 py-2 lg:px-5 lg:py-3 placeholder:text-[#ACACAC]"
									placeholder={"Nom"}
									onChange={(e) =>
										setFirstname(e.target.value)
									}
								/>
							</div>

							<div className="flex flex-col gap-1 w-1/2">
								<label
									htmlFor="Prénom"
									className="text-xs lg:text-sm xl:text-base text-[#667085]"
								>
									Prénom
								</label>
								<input
									type="text"
									id="Prénom"
									value={lastname}
									className="text-sm lg:text-base xl:text-lg border border-[#ACACAC] rounded-md px-3 py-2 lg:px-5 lg:py-3 placeholder:text-[#ACACAC]"
									placeholder={"Prénom"}
									onChange={(e) =>
										setLastname(e.target.value)
									}
								/>
							</div>
						</div>
						{showError && (!firstname || !lastname) ? (
							<div className="flex flex-row items-center justify-between gap-2 md:gap-3 w-full">
								<div className="w-full">
									{showError && !firstname ? (
										<p className="text-red-500 text-sm text-start">
											Veuillez renseigner votre Nom!
										</p>
									) : null}
								</div>
								<div className="w-full">
									{showError && !lastname ? (
										<p className="text-red-500 text-sm text-start">
											Veuillez renseigner votre Prénom!
										</p>
									) : null}
								</div>
							</div>
						) : null}
						<div className="flex flex-row items-center gap-2 md:gap-3 justify-between w-full">
							<div className="flex flex-col gap-1 w-1/2">
								<label
									htmlFor="Téléphone"
									className="text-xs lg:text-sm xl:text-base text-[#667085]"
								>
									Téléphone
								</label>
								<input
									type="number"
									id="Téléphone"
									value={telephone}
									className="text-sm lg:text-base xl:text-lg border border-[#ACACAC] rounded-md px-3 py-2 lg:px-5 lg:py-3 placeholder:text-[#ACACAC]"
									placeholder={"Téléphone"}
									onChange={(e) =>
										setTelephone(e.target.value)
									}
								/>
							</div>

							<div className="flex flex-col gap-1 w-1/2">
								<label
									htmlFor="Email"
									className="text-xs lg:text-sm xl:text-base text-[#667085]"
								>
									Email
								</label>
								<input
									type="email"
									id="Email"
									value={email}
									className="text-sm lg:text-base xl:text-lg border border-[#ACACAC] rounded-md px-3 py-2 lg:px-5 lg:py-3 placeholder:text-[#ACACAC]"
									placeholder={"Email"}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</div>
						</div>
						{showError && (!telephone || !email) ? (
							<div className="flex flex-row items-center justify-between gap-2 md:gap-3 w-full">
								<div className="w-full">
									{showError && !telephone ? (
										<p className="text-red-500 text-sm text-start">
											Veuillez renseigner votre numéro de
											téléphone!
										</p>
									) : null}
								</div>
								<div className="w-full">
									{showError && !email ? (
										<p className="text-red-500 text-sm text-start">
											Veuillez renseigner votre email!
										</p>
									) : null}
								</div>
							</div>
						) : null}

						<div className="flex flex-col gap-1 col-span-2">
							<label
								htmlFor="Société"
								className="text-xs lg:text-sm xl:text-base text-[#667085]"
							>
								Nom de votre société
							</label>
							<input
								type="text"
								id="Société"
								value={company}
								className="text-sm lg:text-base xl:text-lg border border-[#ACACAC] rounded-md px-3 py-2 lg:px-5 lg:py-3 placeholder:text-[#ACACAC]"
								placeholder={"Facultatif"}
								onChange={(e) => setCompany(e.target.value)}
							/>
						</div>
					</div>

					<button
						disabled={submitButtonLoader}
						className="bg-[#FF8C00] lg:font-semibold text-white px-8 py-2 lg:px-10 lg:py-3 text-sm  lg:text-base rounded-md w-fit hover:bg-[#D16A00]"
						onClick={submitForm}
					>
						{submitButtonLoader ? (
							<LoaderSpinner type="saved" />
						) : (
							"Ouvrir mon compte"
						)}
					</button>

					<div
						className={`flex bg-green-600 px-[10px] py-[14px] rounded-md absolute top-0 left-0 w-full justify-between items-start ${
							showMsg ? "" : "hidden"
						}`}
					>
						<div className="flex gap-3 items-start w-full">
							<img
								src={ProfCheck}
								alt="Check"
								className="object-contain w-6"
							/>
							<div className="flex flex-col text-white">
								<p className="text-base font-medium">
									Message envoyé !
								</p>
								<p className="text-[13px] font-normal">
									Nous vous contacterons au plus vite.
								</p>
							</div>
						</div>
						<img
							src={ProfCross}
							alt="Cross"
							className="object-contain w-[28px] cursor-pointer"
							onClick={() => setShowMsg(false)}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Information;
