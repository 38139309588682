import React, { useState } from "react";
// import rent from '../../../assests/rent.png'
import { BsThreeDotsVertical } from "react-icons/bs";
import ReactPaginate from "react-paginate";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
// import GenericDrodown from '../../../util/dropdowns/GenericDropdown';
// import { BiRefresh } from "react-icons/bi";
import Popup from "../../../../util/popup/Popup";
import DeleteData from "../../marketing/DeleteData";
import {
  // GetVihicalsPendding,
  GetVihicalsBaseOnPages,
  ChangeVehicalStatus,
} from "../../../../lib/Api/Vehical";
import { Truncate } from "../../../../util/TrucateString";
import LoaderSpinner from "../../../../util/LoaderSpinner";
import EditData from "../../manageUser/EditData";
import EyeIcon from "../../../../assests/EyeIcon";
import RefreshIcon from "../../../../assests/RefreshIcon";
import TrashIcon from "../../../../assests/TrashIcon";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { FormatePrice } from "../../../../Partials/FormatePriceSpace";
const DataList = ({
  setType,
  type,
  setIsStatus,
  statusCheck,
  loading,
  setLoading,
  vehicalList,
  setVehicalList,
  totalPage,
  setSearchData,
  setCurrentPageIndex,
  currentPageIndex,
  // setTotalPage,
  setOffsetPage,
}) => {
  const [show, setShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  // const [loading, setLoading] = useState(false);
  const [loadReason, setLoadReason] = useState(false);
  const [vehicalStatus, setVehicalStatus] = useState(null);
  const [singleVehical, setSingleVehical] = useState({});

  const navigate = useNavigate();
  // const items = [];
  const itemperPage = 10;
  // //console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const pageCount = Math.ceil(totalPage / itemperPage);
  const handlePageChange = async (selectedPage) => {
    let CurentSel = selectedPage.selected + 1;
    setOffsetPage(CurentSel);
    setCurrentPage(selectedPage.selected);
    setCurrentPageIndex(selectedPage.selected)
    setLoading(true);
    let { res } = await GetVihicalsBaseOnPages(CurentSel, statusCheck);
    let vehicals = Object.assign({}, ...res);
    setLoading(false);
    setVehicalList(vehicals?.vehicles?.data);
    // setTotalPage(vehicals?.vehicles?.total)
  };

  let Status = [
    { id: 0, Title: "Draft", color: "#4B3A8E", bgColor: "#F4F1FF" },
    { id: 3, Title: "Approved", color: "#6ABD8B", bgColor: "#ECFFEC" },
    { id: 4, Title: "Rejected", color: "#F04438", bgColor: "#FFE7E7" },
    { id: 2, Title: "Pending", color: "#4B3A8E", bgColor: "#F4F1FF" },
    { id: 1, Title: "Incomplete", color: "#4B3A8E", bgColor: "#F4F1FF" },
    { id: 5, Title: "Inactive", color: "#4B3A8E", bgColor: "#F4F1FF" },
  ];

  const handlePopup = (status, item) => {
    let checkSt = Status.find((f) => f.Title === status.Title);
    if (checkSt.Title === "Rejected") {
      setSearchData("edit");
      setVehicalStatus("rejected");
      setSingleVehical(item, "rejected");
      setShow(true);
    } else if (checkSt.Title === "Incomplete") {
      setSearchData("edit");
      setVehicalStatus("incomplete");
      setSingleVehical(item, "incomplete");
      setShow(true);
    } else {
      setSearchData("edit");
      setVehicalStatus(checkSt?.Title?.toLowerCase());
      setSingleVehical(item);
      setIsStatus(true);
      setTimeout(() => {
        handleTrigger(item, checkSt?.Title?.toLowerCase());
      }, 2000);
    }
  };

  const handleTrigger = async (reason, singleId) => {
    let payoad = {
      vehicle_id: `${reason?.id || singleVehical?.id}`,
      status: vehicalStatus || singleId,
      reason: reason?.length > 0 ? reason : "",
    };
    setLoadReason(true);
    let { res } = await ChangeVehicalStatus(payoad);
    if (res) {
      setIsStatus(false);
      setLoadReason(false);
      setSearchData("");
      setShow(false);
    }
  };

  const DeleteVehical = (list, type) => {
    setSearchData(type);
    setShow(true);
    setSingleVehical(list);
  };

  const handleClosePopup = () => {
    setShow(false);
    setSearchData('')
  };




  return (
    <>
      {show && (
        <Popup
          modalOpen={show}
          data={
            type === "delete" ? (
              <DeleteData
                singleCoupon={singleVehical?.id}
                setType={setType}
                callback={handleClosePopup}
                setShow={(val) => setShow(val)}
                type="vehical"
              />
            ) : (
              <EditData
                setShow={(val) => setShow(val)}
                vehicalStatus={vehicalStatus}
                callback={(val) => handleTrigger(val)}
                loadReason={loadReason}
              />
            )
          }
          onClose={() => setShow(false)}
        />
      )}
      {/* {show && <Popup modalOpen={show} data={<DeleteData setShow={(val) => setShow(val)} />} onClose={() => setShow(false)} />} */}
      <div>
        <div className="overflow-x-auto bg-white rounded-0 mt-6 w-full">
          {vehicalList?.length > 0 ? (
            <>
              <table className="table-auto w-full ">
                {/* Table header */}
                <thead className="text-xs font-[500] bg-[#FFF8EF] border-0">
                  <tr className="text-[#606060]">
                    <th className="px-4 py-4 pr-[25px] whitespace-nowrap w-px">
                      <div className="font-[600] text-[14px] text-left">
                        Vehicle Owner{" "}
                      </div>
                    </th>
                    <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                      <div className="font-[600] text-[14px] text-left">
                        Brand
                      </div>
                    </th>
                    <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                      <div className="font-[600] text-[14px] text-left">
                        Date
                      </div>
                    </th>
                    <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                      <div className="font-[600] text-[14px] text-left">
                        Vehicle Type
                      </div>
                    </th>
                    <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                      <div className="font-[600] text-[14px] text-left">
                        Grand Abidjan
                      </div>
                    </th>
                    <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                      <div className="font-[600] text-[14px] text-left">
                        Hors Abidjan
                      </div>
                    </th>
                    <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                      <div className="font-[600] text-[14px] text-left">
                        Driver{" "}
                      </div>
                    </th>
                    <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                      <div className="font-[600] text-[14px] text-left">
                        Status{" "}
                      </div>
                    </th>
                    <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                      <div className="font-[600] text-[14px] text-center">
                        Action{" "}
                      </div>
                    </th>
                  </tr>
                </thead>
                {/* Table body */}
                <tbody className="text-sm ">
                  {!loading ? (
                    vehicalList?.map((item, i) => (
                      <tr className=" cursor-pointer  " key={i}>
                        <td
                          onClick={() => {
                            return navigate(
                              `/dinss/tech/dashboard/vehical-detail/${item?.user_id}/all`
                            );
                            // setRentalUser(item?.user)
                          }}
                          className="px-2 first:pl-5 flex items-center last:pr-5 py-3 whitespace-nowrap"
                        >
                          <div className="object-cover w-8 h-8 rounded-full overflow-hidden flex items-center justify-center">
                            <img
                              src={item?.user?.image}
                              alt="rent_image"
                              className="w-auto"
                              style={{ backgroundColor: "#CCCCCC" }}
                            />
                          </div>
                          <div className="text-left  text-[14px] text-[#898989] px-1">
                            {item?.user && Truncate(item?.user?.first_name, 10)}{" "}
                          </div>
                        </td>
                        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                          <div className="text-left text-[14px] text-[#898989] px-1">
                            {item?.model}
                          </div>
                        </td>
                        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                          <div className="text-left text-[14px] text-[#898989] px-1">
                            {moment(item?.updated_at).format('DD-MM-YYYY HH:mm')}
                          </div>
                        </td>
                        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                          <div className="text-left text-[14px] text-[#898989] px-1">
                            {item?.vehicle_category}
                          </div>
                        </td>
                        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                          <div className="text-left text-[14px] text-[#898989] px-1">
                            <span className="text-[#FF8C00]">
                              {FormatePrice(item?.price_in_abidjan)}FCFA{" "}
                            </span>
                            /day
                          </div>
                        </td>
                        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                          <div className="text-left text-[14px] text-[#898989] px-1">
                            <span className="text-[#FF8C00]">
                              {FormatePrice(item?.price_out_abidjan)} FCFA
                            </span>
                            /day
                          </div>
                        </td>
                        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                          <div className="text-left text-[14px] text-[#898989] px-1">
                            With
                          </div>
                        </td>
                        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                          <div
                            style={{
                              color: `${item?.status_object?.text_color}`,
                              backgroundColor: `${item?.status_object?.background_color}`,
                            }}
                            className={`text-center py-[5px] w-fit px-2.5 text-[12px] font-medium leading-4 rounded-full `}
                          >
                            {item?.status_object?.text_en}
                          </div>
                        </td>
                        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                          <div className="text-center flex justify-center  px-1">
                            {/* <BsThreeDotsVertical className='text-[20px] text-[#898989]' /> */}
                            {/* <GenericDrodown /> */}

                            <div class="group inline-block">
                              <button class="outline-none focus:outline-none  px-3 py-1 bg-white rounded-sm flex items-center">
                                <BsThreeDotsVertical className="text-[20px] text-[#898989]" />
                              </button>
                              <ul
                                class="bg-white shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] border-0 rounded-md p-4 transform scale-0 group-hover:scale-100 absolute 
  transition duration-150 ease-in-out right-7 origin-top xl:w-[15%]  "
                              >
                                <li
                                  onClick={() => {
                                    return navigate(
                                      `/dinss/tech/dashboard/vehical-detail/${item?.user_id}/carlist`
                                    );
                                    // setRentalUser(item?.user)
                                  }}
                                  class="rounded-sm actions flex items-center px-2 py-2 mb-2 hover:bg-gray-100"
                                >
                                  <EyeIcon className=" text-[#98A2B3]" />
                                  <h2 className="ml-2">View details</h2>
                                </li>

                                <li class="rounded-sm actions relative px-2 py-2 mb-2 hover:bg-gray-100">
                                  <button class="w-full flex items-center outline-none focus:outline-none">
                                    <RefreshIcon className=" ml-auto text-[#98A2B3]" />
                                    <span class="pr-1 flex-1 text-left ml-2">
                                      Change Status
                                    </span>
                                    <span class="mr-auto">
                                      <svg
                                        class="fill-current h-4 w-4
            transition duration-150 ease-in-out"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                      >
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                      </svg>
                                    </span>
                                  </button>
                                  <ul
                                    class="bg-white border-0 p-2 flex justify-center flex-col items-start rounded-md absolute top-0 right-10 shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)]
  transition duration-150 ease-in-out origin-top-left
  min-w-32
  "
                                  >
                                    {Status.filter(
                                      (f) =>
                                        f.Title !== item?.status_object?.text_en
                                    ).map((st, i) => (
                                      <li
                                        onClick={() => handlePopup(st, item)}
                                        class="px-2 py-2 text-left mb-2"
                                        key={i}
                                      >
                                        <div
                                          style={{
                                            color: `${st.color}`,
                                            backgroundColor: `${st.bgColor}`,
                                          }}
                                          className={`text-center py-[3px] px-2.5 w-auto text-[12px] font-medium rounded-full`}
                                        >
                                          {st.Title}
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                </li>
                                <li
                                  onClick={() => DeleteVehical(item, "delete")}
                                  class="rounded-sm flex items-center px-2 py-2 hover:bg-gray-100"
                                >
                                  <TrashIcon className=" text-[#98A2B3]" />
                                  <h2 className="ml-2">Delete</h2>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <div className="pt-4">
                      <LoaderSpinner type="tableLoad" />
                    </div>
                  )}
                </tbody>
              </table>
            </>
          ) : (
            <div className="p-5 ">
              {loading ? (
                <LoaderSpinner type="tableLoad" />
              ) : (
                <h2>Record not found</h2>
              )}
            </div>
          )}
        </div>
        {vehicalList?.length > 0 && (
          <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap justify-between items-center pt-6 ">
           
            <div className="px-8 xxtra-small:px-0 mt-6 mb-2 w-full flex justify-end">
              <ReactPaginate
                breakLabel="..."
                breakClassName=" bg-[#F3F3F3]"
                nextLabel={<MdOutlineKeyboardArrowRight />}
                onPageChange={handlePageChange}
                activeClassName="bg-[#FF8C00] text-white rounded"
                nextClassName="px-3  bg-[#F3F3F3] text-[20px] pt-[5px] rounded"
                previousClassName="px-3  bg-[#F3F3F3] text-[20px] pt-[5px] rounded"
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel={<MdOutlineKeyboardArrowLeft />}
                className=" flex justify-end xtra-small:justify-start xxtra-small:w-full xxtra-small:justify-start"
                pageClassName=" w-[30px] h-[30px]  text-center mx-2 pt-[3px] rounded bg-[#F3F3F3] "
                forcePage={currentPageIndex}
                
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DataList;
