import React from 'react'

const PreviewLicence = ({pic}) => {
    return (
        <>
            <h2>Preview Licence</h2>
            <div className='pt-5'>
                <img src={pic} alt="preview_img" className='w-[25%] h-[25%]' />
            </div>
        </>
    )
}

export default PreviewLicence