export const FormatePrice = (number) => {
    if (typeof number === 'number') {
        let numStr = number.toString();
        let formatted = '';

        // Check the length of the number and insert space accordingly
        if (numStr.length > 3 && numStr.length <= 6) {
            formatted = numStr.slice(0, -3) + ' ' + numStr.slice(-3);
        } else if (numStr.length > 6) {
            formatted = numStr.slice(0, -6) + ' ' + numStr.slice(-6, -3) + ' ' + numStr.slice(-3);
        } else {
            formatted = numStr;
        }

        return formatted
    }
}