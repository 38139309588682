import React from "react";
import primcar from "../../../assests/PMNeew/rent_left_big.svg";
import primcar2 from "../../../assests/PMNeew/rent_right_big.svg";
import android from "../../../assests/googleplay.webp";
import apple from "../../../assests/apple-store.webp";
const Primecar = () => {
	return (
		<div className="flex flex-col px-5 md:px-20  py-10 md:py-12 lg:py-14  gap-6 items-center w-full  mx-auto">
			<div className="flex flex-col items-center text-center max-w-[1001px]">
				<p className="text-xl lg:text-2xl xl:text-3xl font-bold text-[#444444] mb-2 text-center">
					Alors, vous faites déjà la location de véhicules, ou vous
					souhaitez en faire avec Primecar?
				</p>
				<p className="text-sm font-normal text-[#444444] mt-6">
					Téléchargez l'application
				</p>
				<div className="flex items-center justify-center gap-4 mt-2">
					<a
						className="w-full h-[51px]"
						href="https://apps.apple.com/fr/app/primecar/id6446251526"
					>
						<img
							src={apple}
							alt="social_login"
							className="w-full h-[51px]"
						/>
					</a>
					<a
						className="w-full h-[51px]"
						href="https://play.google.com/store/apps/details?id=com.dinsstech.primecar"
					>
						<img
							src={android}
							alt="social_login"
							className="w-full h-[51px]"
						/>
					</a>
				</div>
			</div>

			<div className="flex flex-col md:flex-row gap-6 xl:gap-12 md:items-center 2xl:w-[1043px] m-auto ">
				<div className="flex flex-col gap-6 md:gap-10 items-center text-center md:justify-between md:w-1/2 md:h-[389px]  rounded-md hover:shadow-lg p-5">
					<p className="text-lg text-[#444] font-semibold sm:h-8">
						Vous êtes un particulier ?
					</p>
					<p className="text-sm font-normal text-[#5A5A5A] sm:h-8">
						Et vous souhaitez vous lancer dans l'activité de
						location de véhicules?
					</p>
					<img
						src={primcar}
						alt="social_login"
						className="h-48 w-full object-cover md:h-52 xl:h-60 rounded-md"
					/>
				</div>

				<span className="border-r border-[#A7A7A7] border-opacity-80 hidden md:block h-96"></span>

				<div className="flex flex-col gap-6 md:gap-10 items-center text-center md:justify-between md:w-1/2 md:h-[389px]  rounded-md hover:shadow-lg p-5">
					<p className="text-lg text-[#444] font-semibold sm:h-8">
						Vous avez déjà une flotte de véhicules à disposition ?
					</p>
					<p className="text-sm font-normal text-[#5A5A5A] sm:h-8">
						Et vous souhaitez la rendre plus accessible au plus
						grand nombre?
					</p>
					<img
						src={primcar2}
						alt="social_login"
						className="h-48 w-full object-cover md:h-52 xl:h-60 rounded-md"
					/>
				</div>
			</div>
		</div>
	);
};

export default Primecar;
