import React, { useEffect, useState } from "react";
import { Firebaseapp } from "../../../../confiq/firebase";
import UsersList from "./UsersList";
import Messages from "./Messages";
import MediaList from "./MediaList";

// import { useLocation, useParams } from "react-router-dom";
// import LoaderSpinner from "../../../../util/LoaderSpinner";
// import { UpdateUserImages } from "../../../../lib/Api/AuthApi";

const Chat = ({ users, unRead, convId, setUnRead, setConvId, currentUser, setUpdateMessage, title }) => {


  return (
    <>
      {/* {users?.length > 0 ? ( */}
      <div className="grid lg:grid-cols-4 gap-5">
        <div>
          <UsersList users={users} unRead={unRead} convId={convId} setConvId={setConvId} />
        </div>
        <div className="lg:col-span-2">
          <Messages
            convId={convId}
            title={title}
            users={users}
            currentUser={currentUser}
            setUpdateMessage={setUpdateMessage} />
        </div>
        <div>
          <MediaList />
        </div>
      </div>
      {/* ) : (
        <div className="px-5 py-5">
          {loading ? <LoaderSpinner type="tableLoad" /> : "Record Not Found"}
        </div>
      )} */}
    </>
  );
};

export default Chat;
