import React, { useEffect, useState } from 'react'
import DataTable from '../../components/AdminComponents/GlobelReports/TransactionTable/Table'
import PieChart from '../../components/AdminComponents/dashboard/PieChart'
import WalletChart from '../../components/AdminComponents/GlobelReports/WalletChart'
import { useLocation } from 'react-router-dom'
import TotalSales from '../../components/AdminComponents/GlobelReports/TotalSales'
import WalletIncome from '../../components/AdminComponents/GlobelReports/WalletIncom'
import { GetGlobelGraph, GetOwnerGlobelGraph } from '../../lib/Api/RentalAppApi'
const GlobelReports = () => {
    const [overAll, setOverAll] = useState({})
    const [topWollet, setTopWollet] = useState([])
    const [g1, setg1] = useState([])
    const [g2, setg2] = useState([])
    const location = useLocation().pathname

    let fecthData = async () => {
        let { res } = await GetGlobelGraph()
        let obj = Object.assign({}, ...res)
        setOverAll(obj.graph)
        setTopWollet(obj.graph2)
    }

    let fecthDataOwner = async () => {
        let { res } = await GetOwnerGlobelGraph()
        let obj = Object.assign({}, ...res)
        setg1(obj.graph)
        setg2(obj.graph2)
    }

    useEffect(() => {
        location === "/dinss/tech/globelRenter" ?
            fecthData()
            :
            fecthDataOwner()

    }, [])
    return (

        <>
            <>
                <div className='grid lg:grid-cols-2 md:grid-cols-2 xxtra-small:grid-cols-1 xtra-small:grid-cols-1  gap-6 pt-[19px]'>
                    <div className='bg-white shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] w-full  h-auto  rounded-md p-5 '>
                        <div className='flex justify-between items-center'>
                            <h2 className='text-[#FF8C00] leading-[20.16px] text-[20px] font-semibold'>
                                {
                                    location === "/dinss/tech/globelOwner" ?
                                        "Overall Total Sales"
                                        :
                                        "Overall Wallet Trends"
                                }

                            </h2>
                        </div>
                        {location === "/dinss/tech/globelOwner" ?
                            <TotalSales  g2={g2}/>
                            :
                            <PieChart overAll={overAll} />
                        }
                    </div>
                    <div className='bg-white shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] w-full h-auto rounded-md p-5  '>
                        <h1 className='text-[#FF8C00] text-[20px] font-semibold pb-2'>

                            {
                                location === "/dinss/tech/globelOwner" ? "Top Wallet With Highest Income" : "Top Wallet"
                            }
                        </h1>
                        <div className='flex pb-2'>
                            <div className='flex items-center '>
                                <div className=' w-[13px] h-[13px] rounded-full bg-[#FF8C00]' />
                                <h2 className='pl-1 text-[15px] text-[#444444]'>
                                    {
                                        location === "/dinss/tech/globelOwner" ? "Highest Income" : "Car owners"
                                    }

                                </h2>
                            </div>
                            <div className='flex items-center ml-5 '>
                                <div className=' w-[13px] h-[13px] rounded-full bg-[#FFC278]' />
                                <h2 className='pl-1 text-[15px] text-[#444444]'>
                                    {
                                        location === "/dinss/tech/globelOwner" ? "Average Income" : "Renters"
                                    }
                                </h2>
                            </div>
                        </div>
                        {location === "/dinss/tech/globelOwner" ?
                            <WalletIncome g1={g1} />
                            :
                            <WalletChart topWollet={topWollet} />
                        }
                    </div>
                </div>


                <div className='bg-white shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] rounded-md h-full mt-5'>
                    <DataTable title="Top Highly Ranked Renters" />
                </div>

                <div className=' h-full mt-5'>
                    <DataTable title="Transaction" />
                </div>
            </>
        </>
    )
}

export default GlobelReports