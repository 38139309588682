export const  downloadFile = async (file) => {
     // Create a hidden link element
     const link = document.createElement('a');
     link.href = file;
     link.download = 'downloaded-file.pdf';
 
     // Append the link to the document
     document.body.appendChild(link);
 
     // Trigger a click on the link to start the download
     link.click();
 
     // Remove the link from the document
     document.body.removeChild(link);

  };