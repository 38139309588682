import { toast } from "react-toastify"
import { callPrivateApi } from "../Apiendpoint"

// Get Vehical Renter Statis
export const GetSeller = async (page) => {
    try {
        let response = await callPrivateApi(`admin/vehicle/sale?page=${page}&per_page=10`, 'get')
        if (response.success === true) {
            return response?.data
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}

export const SearchSeller = async (search, statusCheck , page_no) => {
  try {
    let check =
      statusCheck === "all" || statusCheck === ""
        ? `admin/vehicle/sale?page=${page_no}&per_page=10&search=${search}`
        : `admin/vehicle/sale/${statusCheck}?page=${page_no}&per_page=10&search=${search}`;
    let response = await callPrivateApi(check, "get");
    if (response.success === true) {
      let obj = Object.assign({}, ...response.data);
      return {
        res: obj?.vehicles,
      };
    } else {
      toast.error(response.message);
    }
  } catch (err) {
    toast.error(err);
  }
};


export const GetSellerByFilter = async (status, offsetPage, search) => {
  try {
    let apiRun = search
      ? `admin/vehicle/sale/${status}?page=${offsetPage}&per_page=10&search=${search}`
      : `admin/vehicle/sale/${status}?page=${offsetPage}&per_page=10`;
    let response = await callPrivateApi(apiRun, "get");
    if (response.success === true) {
      return {
        res: response.data,
      };
    } else {
      toast.error(response.message);
    }
  } catch (err) {
    toast.error(err);
  }
};


// Get Vehical rent base on page
export const GetSellerBaseOnPages = async (pageNo, statusCheck) => {
  try {
    let check =
      statusCheck === "" || statusCheck === "all"
        ? `admin/vehicle/sale?page=${pageNo}`
        : `admin/vehicle/sale/${statusCheck}?page=${pageNo}`;
    let response = await callPrivateApi(check, "get");
    if (response.success === true) {
      return {
        res: response.data,
      };
    } else {
      toast.error(response.message);
    }
  } catch (err) {
    toast.error(err);
  }
};


// Change  rent vehical  Status
export const ChangeSellerStatus = async (data) => {
    try {
      let response = await callPrivateApi(
        `admin/vehicle/sale/update`,
        "post",
        data
      );
      if (response.success === true) {
        toast.success(response.message);
  
        return {
          res: response?.data,
        };
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      toast.error(err);
    }
  };


  // Get Vehical Owner Statis
export const GetSellerVehicalByUser = async (id) => {
  try {
      let response = await callPrivateApi(`admin/vehicle/sale/by-user/${id}`, 'get')
      if (response.success === true) {
          return {
              res: response.data
          }
      }
      else {
          toast.error(response.message)
      }
  }
  catch (err) {
      toast.error(err)

  }
}

// Get Vehicals detail
export const GetSellerDetail = async (id) => {
  try {
    let response = await callPrivateApi(
      `admin/vehicle/sale/vehicle-details/${id}`,
      "get"
    );
    if (response.success === true) {
      return {
        res: response?.data,
      };
    } else {
      toast.error(response.message);
    }
  } catch (err) {
    toast.error(err);
  }
};


// Change  Vehical Status
export const ChangeSalesStatus = async (data) => {
  try {
    let response = await callPrivateApi(
      `admin/vehicle/sale/update`,
      "post",
      data
    );
    if (response.success === true) {
      toast.success(response.message);

      return {
        res: response?.data,
      };
    } else {
      toast.error(response.message);
    }
  } catch (err) {
    toast.error(err);
  }
};



// Change  Vehical Status
export const getGraphStatic = async () => {
  try {
    let response = await callPrivateApi(
      `admin/vehicle/sale/owner/stats`,
      "get",
    );
    if (response.success === true) {
      return {
        res: response?.data,
      };
    } else {
      toast.error(response.message);
    }
  } catch (err) {
    toast.error(err);
  }
};



// Change  Vehical Status
export const UpdateWaranty = async (data) => {
  try {
    let response = await callPrivateApi(
      `admin/vehicle/sale/apply-warranty`,
      "post",
      data
    );
    if (response.success === true) {
      toast.success(response.message);
      return {
        res: response?.data,
      };
    } else {
      toast.error(response.message);
    }
  } catch (err) {
    toast.error(err);
  }
};


