import React from 'react'
import { MdClose } from 'react-icons/md'
import { useEffect } from 'react'
import { GetRentDetail } from '../../../lib/Api/RentalAppApi'
import { useState } from 'react'
import LoaderSpinner from '../../../util/LoaderSpinner'
import moment from 'moment'
import { FormatePrice } from '../../../Partials/FormatePriceSpace'
const RentalDetail = ({ setShow, singleVehical }) => {
    const [detail, setDetail] = useState({})
    const [loading, setLoading] = useState(false)
    let { status_object, vehicle, start_date,start_time,  original_end_time, original_end_date, children, rent_status_logs, at_price, amount, total_days, referral_discount } = detail


    useEffect(() => {
        let fetch = async () => {
            setLoading(true)
            let { res } = await GetRentDetail(singleVehical?.id)
            if (res) {
                setDetail(res)
                setLoading(false)
            }
        }
        fetch()
    }, [])



    return (
        <>
            <div className='w-full'>
                <div className="flex items-center">
                    <div className='w-full flex justify-center items-center'>
                        <h2 className="text-[#444444] text-[20px] font-sans font-bold">Rental Detail </h2>
                    </div>
                    {
                        !loading &&
                        <div className='flex justify-end items-end w-[5%]'>
                            <div className='bg-gray-200 rounded-md p-2 cursor-pointer'>
                                <MdClose onClick={() => setShow(false)} className="cursor-pointer" />
                            </div>
                        </div>
                    }
                </div>
                {
                    loading ? <LoaderSpinner type="tableLoad" />
                        :
                        <>

                            <div className='border rounded-xl border-[#FF8C00] p-2 mt-5'>
                                <div className='grid grid-cols-2 gap-10'>
                                    <div className='pt-5'>
                                        <h2 className='text-[#444444] text-[18px] font-sans font-bold'>Vehicle Details</h2>
                                        <div className='flex pt-[31px] '>
                                            <div>
                                                <img src={vehicle?.display_image} className='object-cover rounded-full w-[134px] h-[134px] ' />
                                            </div>
                                            <div className='pl-10 pt-4'>
                                                <div className='flex justify-between'>
                                                    <h2 className='text-[#444444] text-[16px] font-sans font-normal'>Brand</h2>
                                                    <p className='pl-6 text-[#444444] text-[16px] font-sans font-medium'>{vehicle?.make}</p>
                                                </div>
                                                <div className='flex justify-between py-2'>
                                                    <h2 className='text-[#444444] text-[16px] font-sans font-normal'>Model</h2>
                                                    <p className='pl-6 text-[#444444] text-[16px] font-sans font-medium'>{vehicle?.model}</p>
                                                </div>
                                                <div className='flex justify-between'>
                                                    <h2 className='text-[#444444] text-[16px] font-sans font-normal'>Year</h2>
                                                    <p className='pl-6 text-[#444444] text-[16px] font-sans font-medium'>{vehicle?.year}</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='pt-5'>
                                        <h2 className='text-[#444444] text-[18px] font-sans font-bold'>Owner Details</h2>
                                        <div className='pt-12'>
                                            <div className='flex '>
                                                <h2 className='text-[#444444] text-[16px] font-sans font-normal'>First Name</h2>
                                                <p className='pl-6 text-[#444444] text-[16px] font-sans font-medium'>{vehicle?.user?.first_name}</p>
                                            </div>
                                            <div className='flex mt-2'>
                                                <h2 className='text-[#444444] text-[16px] font-sans font-normal'>Last Name</h2>
                                                <p className='pl-6 text-[#444444] text-[16px] font-sans font-medium'>{vehicle?.user?.last_name}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='border rounded-xl border-[#FF8C00] p-2 mt-5'>
                                <h2 className="text-[#444444] text-[20px] font-sans font-bold">Rental</h2>
                                <div className='pt-3'>
                                    <div className='flex pt-2 '>
                                        <h2 className='text-[#98A2B3] text-[16px] w-[120px] font-sans font-normal'>Start Date</h2>
                                        <p className='pl-6 text-[#444444] text-[16px] font-sans font-medium'>{moment(start_date).format('DD/MM/YYYY')} {start_time} </p>
                                    </div>
                                    <div className='flex pt-2 '>
                                        <h2 className='text-[#98A2B3] text-[16px] w-[120px] font-sans font-normal'>End Date</h2>
                                        <p className='pl-6 text-[#444444] text-[16px] font-sans font-medium'>{moment(original_end_date).format('DD/MM/YYYY')} {original_end_time}</p>
                                    </div>
                                    <div className='flex pt-2 '>
                                        <h2 className='text-[#98A2B3] text-[16px] w-[120px] font-sans font-normal'>Extended</h2>
                                        {
                                            children?.length > 0 ?
                                           
                                                <p className='pl-6 text-[#444444] text-[16px] font-sans font-medium'>
                                                
                                                </p>
                                                : <p className='pl-6 text-[#444444] text-[16px] font-sans font-medium'>
                                                -
                                                </p>
                                        }
                                    </div>
                                    <div className='flex pt-2 '>
                                        <h2 className='text-[#98A2B3] text-[16px] w-[120px] font-sans font-normal'>Trip Duration</h2>
                                        <p className='pl-6 text-[#444444] text-[16px] font-sans font-medium'>{total_days} </p>
                                    </div>
                                    <div className='flex pt-2 '>
                                        <h2 className='text-[#98A2B3] text-[16px] w-[120px] font-sans font-normal'>Location</h2>
                                        <p className='pl-6 text-[#444444] text-[16px] font-sans font-medium'>{vehicle?.locations[0].address} </p>
                                    </div>
                                    <div className='flex pt-2 '>
                                        <h2 className='text-[#98A2B3] text-[16px] w-[120px] font-sans font-normal'>Daily Price</h2>
                                        <p className='pl-6 text-[#444444] text-[16px] font-sans font-medium'>{FormatePrice(at_price)}</p>
                                    </div>
                                    <div className='flex pt-2 '>
                                        <h2 className='text-[#98A2B3] text-[16px] w-[120px] font-sans font-normal'>Discount</h2>
                                        <p className='pl-6 text-[#444444] text-[16px] font-sans font-medium'>{referral_discount}</p>
                                    </div>
                                    <div className='flex pt-2 '>
                                        <h2 className='text-[#98A2B3] text-[16px] w-[120px] font-sans font-normal'>Total Price</h2>
                                        <p className='pl-6 text-[#444444] text-[16px] font-sans font-medium'>{FormatePrice(amount)}</p>
                                    </div>

                                </div>
                            </div>
                            <div className='border rounded-xl border-[#FF8C00] p-2 mt-5'>
                                <div className='flex items-center'>
                                    <div className='w-[80%]'>
                                        <h2 className="text-[#444444] text-[20px] font-sans font-bold">Current Status</h2>
                                    </div>
                                    <div className='w-[20%]'>
                                        <div
                                            style={{
                                                color: `${status_object?.text_color}`,
                                                backgroundColor: `${status_object?.background_color}`,
                                            }}
                                            className={`text-center text-[#363636] bg-[#DCDCDC] py-[8px] px-2.5  text-[12px] rounded-full`}
                                        >
                                            {status_object?.text_en}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='border rounded-xl border-[#FF8C00] p-2 mt-5'>
                                <h2 className="text-[#444444] text-[20px] font-sans font-bold">History</h2>
                                <div className='pt-3 '>
                                    {
                                        rent_status_logs?.reverse().map((st, i) => (
                                            <div className='flex flex-col pt-2 ' key={i}>
                                                <div
                                                    className="flex items-center w-full"
                                                >
                                                    <p className=' text-[#444444] text-[16px] font-sans font-medium'>{moment(st?.updated_at).format('DD/MM/YYYY HH:mm:ss A')}</p>
                                                    <h2 className='pl-6 text-[#98A2B3] text-[16px]  font-sans font-normal'>{st?.status_object?.admin_panel_text}</h2>
                                                </div>
                                            </div>

                                        ))
                                    }


                                </div>
                            </div>
                        </>
                }
            </div>
        </>
    )
}

export default RentalDetail