import React, { useState } from "react";
// import { MdSearch } from "react-icons/md";
import DataList from "./DataList";
import PaymentDetail from "./PaymentDetail";
import FilterDropdown from "../../../../../util/dropdowns/FilterDropdown";
import SearchBar from "../../../../GenericComponents/SearchBar";
import { useEffect } from "react";
import { GetPaymentMang } from "../../../../../lib/Api/Management";

const DataTable = () => {
  // const [active, setActive] = useState(0)
  const [detail, setDetail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [totalPages, setTotalPages] = useState();
  const [transaction, setTransaction] = useState([]);


  useEffect(() => {
    let fetch = async () => {
      setLoading(true)
      let { res } = await GetPaymentMang()
      if (res) {
        setTransaction(res)
        // setTotalPages(res?.total)
        setLoading(false)

      }
      
    }
    if(status === ""){
    fetch()
    }
  }, [status])



  return (
    <>
      {detail === false ? (
        <>
          <div className="flex  xl:flex-nowrap flex-wrap  justify-between items-center">
            <h2 className="text-[20px] leading-[20.16px] font-semibold text-[#444444] mb-2">
              Gateway Payment Management
            </h2>
            <div className="flex tablet:justify-between tablet:w-full lg:py-0 py-2  xtra-small:block xxtra-small:block xtra-small:w-full xxtra-small:w-full">
              <SearchBar />
              <div className="ml-4 xtra-small:ml-0 xxtra-small:ml-0 xxtra-small:mt-2 xtra-small:mt-2">
                <div className="text-center w-full flex justify-end xtra-small:justify-start xxtra-small:justify-start ">
                  <div className="dropdown xxtra-small:w-full xtra-small:w-full relative">
                    <FilterDropdown />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <DataList setDetail={setDetail} status={status} loading={loading} setStatus={setStatus} totalPages={totalPages} transaction={transaction} />
          </div>
        </>
      ) : (
        <PaymentDetail />
      )}
    </>
  );
};

export default DataTable;
