import React, { useEffect, useState } from "react";
// import review from '../../../../assests/review.png'
// import star from "../../../../assests/svg/star.svg";
// import like from '../../../../assests/svg/lik.svg'
// import message from '../../../../assests/svg/mes.svg'
// import share from '../../../../assests/svg/shar.svg'
// import send from '../../../../assests/svg/send-gray.svg'
import { GetReviewsMang } from "../../../../lib/Api/Management";
import moment from "moment";
import LoaderSpinner from "../../../../util/LoaderSpinner";
import Rating from "../../../../Partials/StarRating";
const Reviews = () => {
  const [loading, setLoading] = useState(false);
  const [review, setReviews] = useState([]);


  useEffect(() => {
    let fetch = async () => {
      setLoading(true)
      let { res } = await GetReviewsMang();
      let Obj = Object.assign({}, ...res)
      if (res) {
        setReviews(Obj?.reviews);
        setLoading(false);
      }
    };
    fetch();
  }, []);

  return (
    <>
      {
        review?.length > 0 ?
          review?.map((rev) => {
            return (
              <div className="bg-white rounded-md shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] h-full border-0 p-5 mt-5">
                <div className="flex items-center pb-5 ">
                  <div className="h-[131px]  rounded-full overflow-hidden">
                    <img
                      src={rev?.display_image}
                      alt="review_img"
                      className="object-cover rounded-full w-[134px] h-[134px] "
                    />
                  </div>
                  <div className="px-5">
                    <h1 className="lg:text-[21px] text-[21px] text-[#444444] font-semibold">
                      {rev?.make}
                      {rev?.model}
                    </h1>
                    <div className="flex items-center pt-0">
                      <Rating value={rev?.reviews_avg_rating} />
                      <span className="text-[#B8B8B8] text-[14px] px-2 leading-[22px]">
                        ({rev?.reviews_count})
                      </span>
                    </div>
                  </div>
                </div>
                <hr className="border-[#E0E0E0]" />
                {rev?.reviews?.map((list, i) => (
                  <div className="py-4 px-2 " key={i}>
                    <div className="flex justify-between items-center">
                      <div className="">
                        <h1 className="lg:text-[20px] text-[20px] text-[#444444] leading-[30px] font-semibold">
                          {list?.user?.first_name} {list?.user?.last_name}
                        </h1>
                        <div className="flex items-center pt-2.5">
                          <Rating value={list?.rating} />
                          {/* {Array(list?.rating)
                          .fill(list?.rating)
                          .map((_, i) => (
                            <div key={i}>
                              <img src={star} alt="star" className="object-cover" />
                            </div>
                          ))} */}

                        </div>
                      </div>
                      <div>
                        <h1 className="text-[#444444] font-semibold text-[14px]">
                          {moment(list?.created_at).format('ll')}
                        </h1>
                      </div>
                    </div>
                    <div className="py-3">
                      <p className="text-[#898989] text-[16px] ">
                        {list?.comments}
                      </p>
                    </div>

                  </div>
                ))}
              </div>
            )
          })
          :
          loading ? <LoaderSpinner type={"tableLoad"} /> : <div className="px-5 py-5" >
            Record Not Found
          </div>

      }



    </>
  );
};

export default Reviews;
