import moment from 'moment'
import React, { useState } from 'react'
import LoaderSpinner from '../../../util/LoaderSpinner'
import { UpdateOrderStatus } from '../../../lib/Api/Orders'
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md'
import ReactPaginate from 'react-paginate'
import { FormatePrice } from '../../../Partials/FormatePriceSpace'
const OrderTable = ({ broadCast, loading, setLoading }) => {
    const [loadingOrder, setLoadingOrder] = useState(false)
    const [order, setOrder] = useState({})


    // const items = [];
    // const [itemOffset, setItemOffset] = useState(0);
    const [itemperPage] = useState(10);

    // const endOffset = itemOffset + itemperPage;
    // const currentItems = Array(vehicalList?.total).slice(itemOffset, endOffset);
    const pageCount = Math.ceil(broadCast?.length / itemperPage);

    const handlePageClick = async (event) => {
        // const newOffset = (event.selected * itemperPage) % Array(vehicalList?.total).length;
        // setItemOffset(newOffset);
        //  let pageNo = event.selected === 0  ? 1  : event.selected
        // setLoading(true);
        // let { res } = await GetVihicalsBaseOnPages(
        //   event.selected + 1,
        //   "approved",
        //   searchData
        // );
        // let vehicals = Object.assign({}, ...res);
        // setLoading(false);
        // setVehicalList(vehicals?.vehicles?.data);
        // setOffsetPage(event.selected + 1);
        // setCurrentPageIndex(event.selected)

    };


    const updateStatus = async (list) => {
        setOrder(list)
        setLoadingOrder(true)
        let res = await UpdateOrderStatus(list)
        if (res) {
            setLoading(false)
            setLoadingOrder(false)
        }
        else {
            setLoadingOrder(false)
        }
    }

    return (
        <>
            <h2 className="text-[20px] font-semibold leading-[20.16px]  text-[#444444]">
                All Orders
            </h2>

            <div className="overflow-x-auto bg-white mt-6  rounded-md shadow-md">
                {
                    !loading &&
                        broadCast?.length > 0 ?
                        <>
                            <table className="table-auto w-full ">
                                {/* Table header */}
                                <thead className="text-xs font-[500] bg-[#FFF8EF] border-0">
                                    <tr className='text-[#606060]'>

                                        <th className="px-5 py-4 pr-[25px] whitespace-nowrap ">
                                            <div className="font-[500] text-[14px] text-left">Fullname</div>
                                        </th>
                                        <th className="px-5 py-4 pr-[25px] whitespace-nowrap ">
                                            <div className="font-[500] text-[14px] text-left">Email Address</div>
                                        </th>
                                        <th className="px-5 py-4 pr-[25px] whitespace-nowrap w-[40%]">
                                            <div className="font-[500] text-[14px] text-left">Package</div>
                                        </th>
                                        <th className="px-5 py-4 pr-[25px] whitespace-nowrap ">
                                            <div className="font-[500] text-[14px] text-left">Date</div>
                                        </th>
                                        <th className="px-5 py-4 pr-[25px] whitespace-nowrap ">
                                            <div className="font-[500] text-[14px] text-left">Total Price</div>
                                        </th>
                                        <th className="px-5 py-4 pr-[25px] whitespace-nowrap ">
                                            <div className="font-[500] text-[14px] text-left">Status</div>
                                        </th>
                                        {
                                            broadCast && broadCast.some(item => item?.status_object.text_en !== "Delivered") && (
                                                <th className={`px-5 py-4 pr-[25px] whitespace-nowrap`}>
                                                    <div className="font-[500] text-[14px] text-left">Action</div>
                                                </th>
                                            )
                                        }
                                    </tr>
                                </thead>
                                {/* Table body */}
                                <tbody className="text-sm ">
                                    {
                                        broadCast?.map((item, i) => {
                                            return (
                                                <tr className=' cursor-pointer  ' key={i}>
                                                    <td className="px-5 py-4 pr-[25px] whitespace-nowrap w-[30%]">
                                                        <div className="text-left text-[14px] text-[#898989]">{`${item?.user?.first_name} ${item?.user?.last_name}`}</div>
                                                    </td>
                                                    <td className="px-5 py-4 pr-[25px] whitespace-nowrap w-[30%]">
                                                        <div className="text-left text-[14px] text-[#898989]">{`${item?.user?.email} `}</div>
                                                    </td>
                                                    <td className="px-5 py-4 pr-[25px] whitespace-nowrap w-[20%]">
                                                        <div className="text-left text-[14px] text-[#898989]">{item?.product?.name}</div>
                                                    </td>
                                                    <td className="px-5 py-4 pr-[25px] whitespace-nowrap">
                                                        <div className="text-left text-[14px] text-[#898989]">{moment(item?.send_on).format('ll HH:mm')}</div>
                                                    </td>
                                                    <td className="px-5 py-4 pr-[25px] whitespace-nowrap">
                                                        <div className="text-left text-[14px] text-[#898989]">{ FormatePrice(item?.product?.price)}</div>
                                                    </td>
                                                    <td className="px-5 py-4 pr-[25px] whitespace-nowrap">
                                                        <div
                                                            style={{
                                                                color: `${item?.status_object.text_color}`,
                                                                backgroundColor: `${item?.status_object.background_color}`,
                                                            }}
                                                            className={`text-center py-[3px] px-2.5 w-auto text-[12px] font-medium rounded-full`}
                                                        >
                                                            {item?.status_object.text_en}
                                                        </div>
                                                    </td>
                                                    <td className={` ${item?.status_object.text_en === "Delivered" ? 'hidden' : 'block'} px-5 py-4 pr-[25px] whitespace-nowrap`}>
                                                        <button onClick={() => updateStatus(item)} className='bg-[#FF8C00] text-white rounded-md px-4 py-2'>
                                                            {
                                                                order?.id === item.id && loadingOrder ? <LoaderSpinner /> :
                                                                    "Delivered"}
                                                        </button>
                                                    </td>



                                                </tr>
                                            )
                                        }
                                        )
                                    }

                                </tbody>

                            </table>


                        </>
                        :
                        <div className='p-5 '>
                            {loading ? (
                                <LoaderSpinner type="tableLoad" />
                            ) : (
                                <h2>Record not found</h2>
                            )}
                        </div>
                }



            </div>

            {

                <div className='px-8 my-6 '>
                    <ReactPaginate
                        breakLabel="..."
                        breakClassName=" bg-[#F3F3F3]"
                        nextLabel={<MdOutlineKeyboardArrowRight />}
                        onPageChange={handlePageClick}
                        activeClassName="bg-[#FF8C00] text-white rounded"
                        nextClassName="px-3  bg-[#F3F3F3] text-[20px] pt-[5px] rounded"
                        previousClassName="px-3  bg-[#F3F3F3] text-[20px] pt-[5px] rounded"
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel={<MdOutlineKeyboardArrowLeft />}
                        className=" flex justify-end"
                        pageClassName=" w-[30px] h-[30px]  text-center mx-2 pt-[3px] rounded bg-[#F3F3F3] "
                        renderOnZeroPageCount={null}
                    />


                </div>

            }

        </>
    )
}

export default OrderTable