import React from "react";

function EditIcon(props) {
  return (
    <svg
      {...props}
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8667 4.94967L8.03333 2.14967L8.96667 1.21634C9.22222 0.960786 9.53622 0.833008 9.90867 0.833008C10.2807 0.833008 10.5944 0.960786 10.85 1.21634L11.7833 2.14967C12.0389 2.40523 12.1722 2.71367 12.1833 3.07501C12.1944 3.4359 12.0722 3.74412 11.8167 3.99967L10.8667 4.94967ZM9.9 5.93301L2.83333 12.9997H0V10.1663L7.06667 3.09967L9.9 5.93301Z"
        fill="#98A2B3"
      />
    </svg>
  );
}

export default EditIcon;
