import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { CreateAfiliateUser } from '../../../lib/Api/Marketing';
import LoaderSpinner from '../../../util/LoaderSpinner';

// Validation Scema
const schema = yup.object({
    first_name: yup.string().required("first name is required"),
    last_name: yup.string().required("last name is required"),
    email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
    description: yup.string().required("Description is required"),
});
const CreateAfiliate = ({ setReload }) => {
    // React hooks
    const [loading, setLoading] = useState(false);

    // React hook form initilization
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({ mode: "onChange", resolver: yupResolver(schema) });

    // Submit Form 
    const onSubmit = async (data) => {
        try {
            setLoading(true);
            let { res } = await CreateAfiliateUser(data);
            if (res !== undefined) {
                setLoading(false);
                reset();
                setReload("create")
                setTimeout(() => {
                    setReload("")
                }, 1000);
            } else {
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
        }
    };

    return (
        <>
            <div className='flex md:py-5 py-5 justify-between'>
                <div>
                    <h2 className='text-[#FF8C00] font-semibold text-[20px]'>Afiliate Users</h2>
                    <p className='pt-4 text-[#898989] text-[14px]'>Here you can Create and edit Afiliate User</p>
                </div>
                {/* <button className='bg-[#FF8C00] text-white w-[160px] min-w-max h-[40px] text-[14px] leading-[16px] font-semibold rounded-md'>Invite a Friend</button> */}
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='pt-5'>
                    <h2 className='text-[#444444] font-semibold text-[20px]'>Create New Afiliate User</h2>
                    <div className='grid lg:grid-cols-3 md:grid-cols-3 pt-5 gap-[32px]'>
                        <div className='flex flex-col'>
                            <label className='pb-2 text-[#606060] text-[14px]'>First Name</label>
                            <input
                                type="text"
                                {...register("first_name")}
                                placeholder="First Name"
                                className={`border border-[#CDD2E1] h-[42px] placeholder:text-[14px] text-[14px] rounded-md text-[#ACACAC] focus:outline-none px-2.5 ${errors.first_name && "border-red-500"
                                    }`}
                            />
                            {errors.first_name && (
                                <p className="text-red-500 text-sm text-start pt-1 ">
                                    {errors.first_name.message}
                                </p>
                            )}
                        </div>
                        <div className='flex flex-col'>
                            <label className='pb-2 text-[#606060] text-[14px]'>Last Name</label>
                            <input
                                type="text"
                                {...register("last_name")}
                                placeholder="Last Name"
                                className={`border border-[#CDD2E1] h-[42px] placeholder:text-[14px] text-[14px] rounded-md text-[#ACACAC] focus:outline-none px-2.5 ${errors.last_name && "border-red-500"
                                    }`}
                            />
                            {errors.last_name && (
                                <p className="text-red-500 text-sm text-start pt-1 ">
                                    {errors.last_name.message}
                                </p>
                            )}
                        </div>
                        <div className='flex flex-col'>
                            <label className='pb-2 text-[#606060] text-[14px]'>Email</label>

                            <input
                                type="email"
                                {...register("email")}
                                placeholder='Email'
                                className={`border border-[#CDD2E1] h-[42px] ticket placeholder:text-[14px] text-[14px] rounded-md text-[#ACACAC] focus:outline-none px-2 ${errors.email && "border-red-500"
                                    }`}
                            />
                            {errors.email && (
                                <p className="text-red-500 text-sm text-start pt-1 ">
                                    {errors.email.message}
                                </p>
                            )}
                        </div>
                       
                    </div>
                    <div className='pt-10'>
                        <textarea
                            cols={4}
                            placeholder='Note'
                            {...register("description")}
                            className={`resize-none border border-[#CDD2E1] w-full placeholder:text-[14px] text-[14px] py-2 text-[#ACACAC] focus:outline-none px-2 rounded-md ${errors.description && "border-red-500"
                                }`}
                        ></textarea>
                        {errors.description && (
                            <p className="text-red-500 text-sm text-start pt-1 ">
                                {errors.description.message}
                            </p>
                        )}
                        {/* <textarea placeholder='description' className=' resize-none border border-[#CDD2E1] w-full placeholder:text-[14px] text-[14px] py-2 text-[#ACACAC] focus:outline-none px-2 rounded-md  ' rows={5}></textarea> */}
                    </div>
                    <div className='flex justify-end pt-5'>
                        <button className='bg-[#FF8C00] text-white h-[40px] px-[46px] text-[14px] font-semibold rounded-md'>
                            {loading ? <LoaderSpinner /> : "Create"}
                        </button>
                    </div>

                </div>
            </form>
        </>
    )
}

export default CreateAfiliate