import React, { useState } from "react";
import Car from "../../../assests/PMNeew/car-07.svg";
import gas from "../../../assests/newDesign/Clip.svg";
import cricle from "../../../assests/newDesign/specification.svg";
import user from "../../../assests/newDesign/seat.svg";
import { IoLocationSharp } from "react-icons/io5";
// import { Link } from 'react-router-dom'
import { MdKeyboardArrowRight } from "react-icons/md";
import Rating from "../../../Partials/StarRating";
import { GetFilterByPage } from "../../../lib/Api/WebsiteApi";
import { useSelector } from "react-redux";
import LoaderSpinner from "../../../util/LoaderSpinner";
import emptySearch from "../../../assests/svg/EmptySearch.svg";
import moment from "moment";
const Right = ({
  setDetailPage,
  cars,
  pagination,
  setPagination,
  setCars,
  priceType,
}) => {
  const [page, setPage] = useState(1);
  const [loadingCars, setLoadingCars] = useState(false);

  const searchInfo = useSelector((state) => state.userAuth?.searchInfo);

  const convertTo24HourFormat = (value, type) => {
    let [hours, minutes] = value?.time?.split(":") || value.split(":");
    if (value.zone === "PM" && hours !== "12") {
      hours = String(Number(hours) + 12);
    }
    if (value.zone === "AM" && hours === "12") {
      hours = "00";
    }
    return `${hours}:${minutes}`;
  };
  const NextPage = async () => {
    let inc = page + 1;
    // let perpage = pageTotal *2
    setPage(inc);
    let startTime = convertTo24HourFormat(searchInfo?.start_time, "first");
    let endTime = convertTo24HourFormat(searchInfo?.end_time, "second");
    let payload = {
      longitude: searchInfo?.latitude,
      latitude: searchInfo?.latitude,
      start_date: searchInfo.start_date,
      end_time: searchInfo?.end_time.time ? endTime : searchInfo?.defaultTime,
      start_time: searchInfo?.start_time.time
        ? startTime
        : searchInfo?.defaultTime,
      end_date: searchInfo?.end_date,
      user_id: "",
      location: "in_abidjan",
    };
    if (pagination !== null) {
      setLoadingCars(true);
      const urlParts = pagination.split("/");
      const lastPart = urlParts[urlParts.length - 1];
      let { res } = await GetFilterByPage(payload, lastPart);
      if (res) {
        setCars([...cars, ...res.vehicles]);
        setPagination(res?.next_page);
      }
      setLoadingCars(false);
    }
  };

  const renderPriceTypeItem = (item, i, priceType) => {
    return (
      <div
        onClick={() => setDetailPage(item)}
        key={i}
        className={`  border cursor-pointer border-gray-50 shadow-md bg-white rounded-md  flex-col flex justify-center sm:max-w-full mx-auto sm:mx-0`}
      >
        <div className="flex justify-center items-center">
          <img
            src={item?.display_image || Car}
            alt="car"
            className=" object-cover h-[150px] w-full searchPage_image_style"
          />
        </div>

        <div className="flex flex-col p-[8px] xl:p-[16px]">
          <div className="flex justify-between items-center pt-2">
            <h2 className="lg:text-[17px] lg:leading-[21px] md:text-[12px] text-[15px] font-sans font-bold text-[#444444]">
              {item.make} {item?.model}
            </h2>
            {/* <div className='flex justify-between items-center'>
								  <IoLocationSharp className='text-[#FF8C00]' />
								  <h2 className='pl-1 text-[#ACACAC] text-[11.57px]'>Abidjan</h2>
							  </div> */}
          </div>

          <div className="flex gap-[2.4px] lg:gap-[4px] lg:mt-[6px] ">
            <Rating value={item?.reviews_avg_rating} />
            {item?.reviews_count > 0 && (
              <span className="lg:text-[12px] lg:leading-[16px] text-[7px] font-[400] leading-[9px] lg:ml-[12px] ml-[4px]">
                ({item?.reviews_count})
              </span>
            )}
          </div>

          <div className="flex justify-between my-[9.47px] lg:my-[16px]">
            <div className="flex items-center gap-[2px]">
              <img
                src={gas}
                alt="detail"
                className="object-contain w-[15px] h-[15px] md:h-[10px] md:w-[10px]"
              />
              <h2 className="md:text-[11.57px] text-[15px] leading-[10px] font-sans text-[#ACACAC]">
                {item?.fuel_type?.fuel_type}
              </h2>
            </div>
            <div className="flex items-center gap-[2px]">
              <img
                src={cricle}
                alt="detail"
                className="object-contain w-[15px] h-[15px] md:h-[10px] md:w-[10px]"
              />
              <h2 className="md:text-[11.57px] text-[15px] leading-[10px] font-sans text-[#ACACAC]">
                {" "}
                {item?.gearbox}
              </h2>
            </div>
            <div className="flex items-center gap-[2px]">
              <img
                src={user}
                alt="detail"
                className="object-contain w-[15px] h-[15px] md:h-[10px] md:w-[10px]"
              />
              <h2 className="md:text-[11.57px] text-[15px] leading-[10px] font-sans text-[#ACACAC]">
                {item?.no_of_places} Places
              </h2>
            </div>
          </div>

          <div className="">
            <div className="flex ">
              <IoLocationSharp
                className={`text-[#FF8C00] ${
                  item?.nearest_location?.address.length > 50
                    ? "text-[30px]"
                    : "text-[20px] "
                }`}
              />
              <h2 className="font-sans text-[#ACACAC] pl-2 text-[15px] md:text-[12px] font-[400] h-[40px]">
                {item?.nearest_location?.address}
              </h2>
            </div>
          </div>

          <div className="mt-[9.47px] lg:mt-[16px]">
            <div className="bg-[#F4F4F4] rounded-md py-[3.55px] lg:py-[6px]">
              <h2 className="text-[#FF8C00] text-center font-sans  md:text-[14.43px] md:p-[3px] lg:leading-[19.5px] h-[26px] p-[6px] text-[16px] leading-[11.25px]">
                {priceType == "Grand Abidjan" ? (
                  <>{item?.price_in_abidjan.toLocaleString()} FCFA </>
                ) : (
                  <>{item?.price_out_abidjan.toLocaleString()} FCFA </>
                )}

                <span className="md:text-[12.22px] text-[16px] font-[400] text-[#ACACAC] ">
                  / jour
                </span>
              </h2>
            </div>
          </div>

          {/* <div className="mt-[6px] lg:mt-[18px]">
						<button
							onClick={() => setDetailPage(item)}
							className="bg-[#FF8C00] font-sans rounded-md text-center text-white lg:leading-[21.35px] text-[14px] px-8 py-[2px] lg:py-[9px] w-full lg:font-medium"
						>
							Voir{" "}
						</button>
					</div> */}
        </div>
      </div>
    );
  };

  return (
    <>
      {cars?.length > 0 ? (
        <div className="grid lg:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5 search_car sm:grid-cols-2 grid-cols-1 gap-[16px] md:pl-[24px]  justify-center">
          {cars?.map((item, i) =>
            priceType == "Grand Abidjan" && item?.price_in_abidjan
              ? renderPriceTypeItem(item, i, priceType)
              : priceType == "Hors d’Abidjan" &&
                item?.price_out_abidjan &&
                item?.available_outside_abidjan == 1
              ? renderPriceTypeItem(item, i, priceType)
              : null
          )}
        </div>
      ) : (
        <div className="flex justify-center pt-[50px] md:pt-[150px]">
          <div className="text-center font-bold items-start empty_search_main">
            <div className="flex justify-center pb-[10px]">
              <img src={emptySearch} alt="delicon" className="object-cover" />
            </div>
            <h2 className="text-[#444444] text-[20px] py-[10px] font-semibold">
              Aucun résultat
            </h2>
            <p className="text-[#444444] text-[13px] font-normal">
              Nous vous prions de revoir vos critères de recherches pour vous
              donner des chances d’obtenir des résultats.
            </p>
          </div>
        </div>
      )}

      {loadingCars ? (
        <div className="py-20 flex items-center justify-center">
          {" "}
          <LoaderSpinner type="tableLoad" />
        </div>
      ) : (
        <div className="flex justify-between items-center md:pt-[47px] md:pb-[57px] pt-[24px] pb-[40px] md:pl-[24px]">
          {cars.length > 1 && (
            <h2 className="text-[#ACACAC] md:text-[14px] text-[12px] leading-[24px] font-sans">
              {/* Page {page} sur {12} */}
            </h2>
          )}
          {pagination === null ? (
            ""
          ) : (
            <div
              className="flex cursor-pointer items-center"
              onClick={() => NextPage()}
            >
              <h2 className="font-bold text-[#FF8C00] font-sans text-[14px] leading-[19px] pl-[14px]">
                Suivant
              </h2>
              <MdKeyboardArrowRight className="text-[16px] text-[#FF8C00] " />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Right;