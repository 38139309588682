import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
// import { useLocation } from "react-router-dom";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export const options = {
  
  responsive: true,
  // maintainAspectRatio: true,
  devicePixelRatio: 4,
  tooltips: {
    enabled: false,
  },
  scales: {
    x: {
      ticks: {
        color: "#98A2B3",
        font: {
          size: 8.64,
        },
      },
      grid: {
        display: false,
      },
    },
    y: {
      ticks: {
        color: "#98A2B3",
        font: {
          size: 8.64,
        },
      },
    },
  },
  plugins: {
    legend: {
      display: false,
      position: "top",
    },
  },
};

const DashboardChart = ({ graphData }) => {
  // const location = useLocation().pathname;
  let labelArr = []
  let dataArr = []

  const handleFormate = (value) => {
    // Remove commas from the input value
    // const strippedNumber = value.replace(/,/g, '');
    // // Remove decimals
    // return  strippedNumber.split('.')[0];

   
  };




  let grData =  graphData?.data
  for (let index = 0; index < grData?.length; index++) {
    const element = grData[index];
    labelArr.push(element?.day)
   let dataFormated =  handleFormate(element?.value)
    // dataArr.push(dataFormated)
  }



  const label2 = [
    "11th",
    "12th",
    "13th",
    "14th",
    "15th",
    "16th",
    "17th",
    "18th",
  ];
  const valdashboard = [0, 1000, 1500, 2000, 2100, 2200, 2300, 2400];

  const data = {
    labels: grData?.length > 0 ? labelArr : label2,
    datasets: [
      {
        fill: true,
        data: grData?.length > 0 ? dataArr : valdashboard,
        borderColor: "#FF8C00",
        backgroundColor: "rgba(247, 246, 247, 0.5)",
      },
    ],
  };

  return (
    <>
      <div className="flex items-center w-full  ">
        <h1 className="[writing-mode:vertical-rl] rotate-180 text-[12px] text-[#98A2B3]">
          FCFA
        </h1>
        <Line
          className=" !w-full lg:!h-[240px] xl:!h-[210px] md:!h-[260px] sm:!h-[280px] pr-7 sidebar-expanded:!h-[260px]"
          options={options}
          data={data}
          redraw={true}
        />
      </div>
      <h1 className=" text-[12px] text-[#98A2B3] text-center ">Month</h1>
    </>
  );
};

export default DashboardChart;
