import React, { useState } from "react";
import Autocomplete from "react-google-autocomplete";
const PlaceAutocomplete = ({
	// setLocate,
	setLatitude,
	setLongitude,
	// setPlace,
	setDefaultAdress,
	defaultAddress,
}) => {
	const autocompleteOptions = {
		types: ["address"],
		componentRestrictions: { country: "ci" },
	};

	return (
		<>
			<Autocomplete
				style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}
				apiKey={"AIzaSyCniyNSjW0XAd32jDYoZ5qgzRXaANaDNj0"}
				options={autocompleteOptions}
				defaultValue={defaultAddress}
				onPlaceSelected={async (place) => {
					// setLocate(place)
					// setPlace(place)
					setDefaultAdress(place?.formatted_address);
					setLatitude(place.geometry?.location?.lat());
					setLongitude(place.geometry?.location?.lng());
					// const response = await axios.get(
					//     `https://maps.googleapis.com/maps/api/place/details/json?placeid=${place?.place_id}&fields=address_component,geometry&key=AIzaSyBD-xaHdgobMyufM5tW3whuusr8l5J57X0`
					// );
					// const result = response.data.result;
					// const addressComponents = result.address_components;
					// const geometry = result.geometry;

					// Extract required address details
					// const city = addressComponents.find((component) =>
					//     component.types.includes('locality')
					// ).long_name;
					// const state = addressComponents.find((component) =>
					//     component.types.includes('administrative_area_level_1')
					// ).long_name;
					// const zipCode = addressComponents.find((component) =>
					//     component.types.includes('postal_code')
					// ).long_name;
					// const lat = geometry.location.lat;
					// const lng = geometry.location.lng;
				}}
				className="w-full border-transparent  focus:outline-none font-family-open-sans font-weight-400 text-[#444444]"
				placeholder="Abidjan"
			/>
		</>
	);
};

export default PlaceAutocomplete;
