import React, { useState, useEffect } from 'react'
import copy from '../../../assests/copy.png'
import { MdSearch } from 'react-icons/md'
import { GetSingleAfiliateUser } from '../../../lib/Api/Marketing'
// import { Truncate } from '../../../util/TrucateString'
import LoaderSpinner from '../../../util/LoaderSpinner'
import { toast } from "react-toastify"
import moment from 'moment'

const Detail = ({ singleCoupon, setShow }) => {
    const [search, setSearch] = useState("")
    const [loading, setLoading] = useState(false)
    const [singleUser, setSingleUser] = useState([])


    const handleCopyClick = async () => {
        try {
            await navigator.clipboard.writeText(singleCoupon?.link);
            toast.success("link Copied")
            // setIsCopied(true);
        } catch (err) {
            console.error('Unable to copy to clipboard', err);
        }

        // Reset copied state after a short delay
        // setTimeout(() => {
        //     setIsCopied(false);
        // }, 2000);
    };



    let fetchDetail = async () => {
        setLoading(true)
        let { res } = await GetSingleAfiliateUser(singleCoupon)
        if (res) {
            setLoading(false)
            setSingleUser(res?.partners)
        }
    }

    useEffect(() => {
        fetchDetail()
    }, [])



    const filteredData = singleUser.filter((item) =>
        item?.first_name?.toLowerCase().includes(search?.toLowerCase())
        || item?.last_name?.toLowerCase().includes(search?.toLowerCase())
        || item?.email?.toLowerCase().includes(search?.toLowerCase()))

    return (
        <>
            <div className='xl:w-[900px] lg:w-[900px]'>
                <div className="flex  flex-wrap justify-between items-center pb-3 ">
                    <h2 className="text-[20px] font-semibold leading-[20.16px]  text-[#444444]">
                        Afiliate Users
                    </h2>
                    <div className="flex lg:py-0 py-2 ">
                        <div className="bg-white shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] rounded-full border-0 flex items-center px-4 lg:w-[380px] w-full py-3 h-[45px]">
                            <MdSearch className="text-[25px] text-[#FF8C00]" />
                            <input
                                onChange={(e) => setSearch(e.target?.value)}
                                type="text"
                                className="focus:outline-none mx-2 w-full font-medium placeholder:text-[#ACACAC] placeholder:text-[16px]"
                                value={search}
                                placeholder="Search..."
                            />
                        </div>
                    </div>
                </div>
                {singleUser?.length > 0 ? (
                    <>
                        <table className="table-auto w-full border border-gray-100 rounded-md">
                            {/* Table header */}
                            <thead className="text-xs font-[500] bg-[#FFF8EF] border-0 rounded">
                                <tr className="text-[#626973]">
                                    <th className="px-5 py-4 whitespace-nowrap w-px">
                                        <div className="font-[500] text-[14px] text-left">First Name</div>
                                    </th>
                                    <th className="px-5 py-4 whitespace-nowrap">
                                        <div className="font-[500] text-[14px] text-left">
                                            Last Name
                                        </div>
                                    </th>
                                    <th className="px-5 w-[50%] py-4 whitespace-nowrap">
                                        <div className="font-[500] text-[14px] text-left">
                                            Email
                                        </div>
                                    </th>
                                    <th className="px-5 py-4 whitespace-nowrap">
                                        <div className="font-[500] text-[14px] text-left">
                                            Date
                                        </div>
                                    </th>
                                    <th className="px-5 py-4 whitespace-nowrap">
                                        <div className="font-[500] text-[14px] text-left">
                                            Last Rent Status
                                        </div>
                                    </th>



                                </tr>
                            </thead>
                            {/* Table body */}
                            <tbody className="text-sm ">
                                {!loading ? (
                                    filteredData?.map((item, i) => (
                                        <tr tr className=" cursor-pointer" key={i} >
                                            <td className="px-5 py-4 whitespace-nowrap">
                                                <div className="text-left text-[14px] text-[#898989]">
                                                    {item?.first_name}

                                                </div>
                                            </td>
                                            <td className="px-5 py-4 whitespace-nowrap">
                                                <div className="text-left text-[14px] text-[#898989]">
                                                    {item?.last_name}

                                                </div>
                                            </td>
                                            <td className="px-5 py-4 whitespace-nowrap">
                                                <div className="text-left text-[14px] text-[#898989]">
                                                    {item?.email}

                                                </div>
                                            </td>
                                            <td className="px-5 py-4 whitespace-nowrap">
                                                <div className="text-left text-[14px] text-[#898989]">
                                                    {moment(item?.created_at).format('DD-MM-YYYY HH:mm')}

                                                </div>
                                            </td>
                                            <td className="px-5 py-4 whitespace-nowrap">
                                                {item?.first_rent === null ?
                                                    <div className="text-center text-[14px] text-[#898989]">
                                                        ----
                                                    </div>
                                                    :
                                                    <div className='flex justify-center items-center'>
                                                        <div
                                                            style={{
                                                                color: `${item?.first_rent?.status_object?.text_color}`,
                                                                backgroundColor: `${item?.first_rent?.status_object?.background_color}`,
                                                            }}
                                                            className={`text-center  py-[3px] w-fit px-2.5 text-[12px] leading-4 rounded-full `}
                                                        >
                                                            {item?.first_rent?.status_object?.text_en}
                                                        </div>
                                                    </div>
                                                }
                                            </td>

                                        </tr>
                                    ))
                                ) : (
                                    <div className="flex items-center p-5">
                                        <LoaderSpinner type="tableLoad" />
                                    </div>
                                )}


                            </tbody>
                        </table>
                    </>
                ) : (
                    <div className="p-5 flex items-center px-3 py-3">
                        {loading ? (
                            <div className='flex items-center'>
                                <LoaderSpinner type="tableLoad" />
                            </div>
                        ) : (
                            <h2>Record not found</h2>
                        )}
                    </div>
                )}
                <div className='flex justify-between items-end w-full'>
                    {singleCoupon?.link &&
                        <div className='flex flex-col pt-5'>
                            <label className='pb-3 text-[#FF5B59] text-[14px]'> Affiliator URL - To be shared with affiliator on demand</label>
                            <div className='flex justify-between items-center border px-2 rounded-md'>
                                <input type="text" value={singleCoupon?.link} readOnly placeholder={singleCoupon?.link} className='w-full  h-[42px] ticket placeholder:text-[14px] rounded-md text-[#ACACAC] focus:outline-none px-2 ' />
                                <img src={copy} onClick={() => handleCopyClick()} alt="copy" className='object-cover cursor-pointer' />
                            </div>
                        </div>
                    }

                    <div onClick={() => setShow(false)} className='flex justify-end items-end pt-10'>
                        <button className='bg-[#FF8C00] text-white h-[40px] w-[100%] px-6 rounded-md'>Close</button>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Detail