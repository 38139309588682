// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore";
import { getAuth } from "firebase/auth";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBcmr0y1THW4qeszTJYRB06gqJTHkMBa9s",
  authDomain: "primecar-72fde.firebaseapp.com",
  projectId: "primecar-72fde",
  storageBucket: "primecar-72fde.appspot.com",
  messagingSenderId: "949328771235",
  appId: "1:949328771235:web:f4d1d5d11ae66a9dd36716",
  measurementId: "G-7GXNL73VY1"
};


// Initialize Firebase
const Firebaseapp = initializeApp(firebaseConfig);
const auth = getAuth(Firebaseapp);
// const firestore = Firebaseapp.firestore();
const db = getFirestore(Firebaseapp);
export { Firebaseapp , db , auth }
