import React from "react";
import Layout from "../../components/layouts/mainLayout/MainLayout";
import Banner from "../../components/MainLandingComp/helpSupport/Banner";
import Main from "../../components/MainLandingComp/helpSupport/Main";
import { Helmet } from "react-helmet";

const Helpsupport = () => {
	return (
		<Layout>
			<Helmet>
				<title>
					Primecar : La plateforme N°1 de location de voiture en Côte
					d’Ivoire
				</title>
				<meta
					name="description"
					content="Comment pouvons-nous vous aider ?"
				/>
				<link
					rel="canonical"
					href="https://primecarapp.com/help-center"
				/>
			</Helmet>
			<Banner left="Centre d’aide" center="" right="Je suis Locataire" />
			<Main />
		</Layout>
	);
};

export default Helpsupport;
