import React, { useEffect, useState } from 'react'
import Chat from '../chats/Chat'
import { useLocation, useParams } from 'react-router-dom'
import { Firebaseapp } from '../../../../confiq/firebase'
import { collection, getDocs, getFirestore, orderBy, query, where } from 'firebase/firestore'

const ChatWithOwner = ({ allUsers , title }) => {
  const [users, setUsers] = useState([]);
  const [convId, setConvId] = useState();
  const [updateMessage, setUpdateMessage] = useState("")

  const id = useParams().id
  let location = useLocation().pathname

  let check = location === `/dinss/tech/vehicalOwner/detail/${id}/detail` ? "owner" : "renter"

  useEffect(() => {
    let fetch = async () => {
      const currentDateMinus24Hours = new Date();
      currentDateMinus24Hours.setHours(currentDateMinus24Hours.getHours() - 24);
      const db = getFirestore(Firebaseapp);
      const Conv = query(collection(db, `Users/${id}/Conversation`),
        where('endDate', '>', currentDateMinus24Hours),
        orderBy('endDate'),
        orderBy("lastMessageTime", "desc")) // Add additional sorting if needed
      const querySnapshot = await getDocs(Conv);
      const UsersConv = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      let filterData = UsersConv.filter((f) => {
        if (check === "owner") {
          return f?.otherUserId !== "0" && f?.renterId === id
        }
        else {
          return f?.otherUserId !== "0" && f?.ownerId === id

        }
      })




      let arr = [];
      for (let index = 0; index < allUsers?.length; index++) {
        const element = allUsers[index];
        for (let j = 0; j < filterData?.length; j++) {
          const element2 = filterData[j];
          if (element.id === element2.otherUserId) {
            let obj = {
              ...element,
              ...element2,
            };
            arr.push(obj);
          }
        }
      }

      // setLoading(false)
      setUsers(arr);
      setConvId(arr[0])

    }
    fetch()
  }, [updateMessage])

  let checkUser = allUsers.find((f) => f.userId === id)


  return (
    <>
      <Chat
        users={users}
        convId={convId}
        currentUser={checkUser}
        setConvId={setConvId}
        setUpdateMessage={setUpdateMessage}
        title={title}
      />
    </>
  )
}

export default ChatWithOwner