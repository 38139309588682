import React, { useEffect, useState } from "react";
import message from "../../../../assests/message.png";
import call from "../../../../assests/call.png";
// import car from '../../../../assests/unsplash_eTuO9BNtcPA.png'
// import car2 from '../../../../assests/caar2.png'
// import car3 from '../../../../assests/car3.png'
// import car4 from '../../../../assests/car4.png'
import pump from "../../../../assests/pump.png";
import boite from "../../../../assests/Group (2).png";
import seet from "../../../../assests/Group (3).png";
// import setting from "../../../../assests/setting.png";
import usb from "../../../../assests/usb.png";
import upload from "../../../../assests/PMNeew/uplad.svg";
import bluthoth from "../../../../assests/blue.png";
import { IoLocation } from "react-icons/io5";
import file from "../../../../assests/file.png";
import { MdDelete } from "react-icons/md";
import { downloadFile } from '../../../../Partials/DownloadDoc'
// import PieChart from '../PieChart'
import { Link, useLocation, useParams } from "react-router-dom";
import {
  DeletelicenceDocuments,
  GetVihicalsDetail,
  UplaodlicenceDocuments,
} from "../../../../lib/Api/Vehical";
import moment from "moment";
import CarListPieChart from "../CarDetailPieChart";
import LoaderSpinner from "../../../../util/LoaderSpinner";
import Popup from "../../../../util/popup/Popup";
import PreviewLicence from "./PreviewLicence";
import { BsFillCloudDownloadFill } from "react-icons/bs";
import { AiFillEye } from "react-icons/ai";
import { UpdateRentOption } from "../../../../lib/Api/RantelServiceApi";
import AddQuestion from "./AddQuestion";
import { FormatePrice } from "../../../../Partials/FormatePriceSpace";
const CarlistDetail = ({ setUser, user }) => {
  const [isEdit] = useState(true);
  const [loading, setLoading] = useState(false);
  const [fileUplading, setFileUplaoding] = useState(false);
  const [activeImg, setActiveImg] = useState();
  const [list, setList] = useState({});
  const [chart, setChart] = useState({});
  const [insuranceFront, setInsuranceFront] = useState(null);
  const [insuranceBack, setInsuranceBack] = useState(null);
  const [show, setShow] = useState(false);
  const [preview, setPreview] = useState("");
  const [fileType, setFileType] = useState("");
  const [title, setTitle] = useState("");
  const [answers, setAnswers] = useState({});
  const [type, setType] = useState("");
  const [instant_car, setinstant_Car] = useState();
  const Images = list?.images;

  let { id } = useParams();
  let location = useLocation().pathname;

 

  const handlePreview = (pic) => {
    setType("preview")
    setPreview(pic);
    setShow(true);
  };

  const handleAddQuestion = (text, list) => {
    setTitle(text);
    setAnswers(list)
    setShow(true);
  };

  //console.log("VehicalId", VehicalId)

  const handleUplaodDoc = async (e, type) => {
    let val = e.target.files[0];
    let checkFile =
      type === "front" ? "document_pdf_front" : "document_pdf_back";
    if (val) {
      setFileType(type);
      try {
        let formdata = new FormData();
        formdata.append("vehicle_id", list?.id);
        formdata.append(`${checkFile}`, val);
        setFileUplaoding(true);
        let { res } = await UplaodlicenceDocuments(formdata);
        if (res) {
          if (type === "front") {
            setInsuranceFront((prev) => ({
              ...prev,
              document_pdf_front: res?.document_pdf_front
            }))
          }
          else {
            setInsuranceBack((prev) => ({
              ...prev,
              document_pdf_back: res?.document_pdf_back
            }))
          }

          setFileUplaoding(false);
        }
      } catch (err) { }
    }
  };

  const handleDelete = async (type) => {
    setFileType(type);
    try {
      let formdata = new FormData();
      formdata.append("vehicle_id", list?.id);
      formdata.append(`document_to_delete`, type);
      setFileUplaoding(true);
      let { res } = await DeletelicenceDocuments(formdata);
      if (res) {
        if (type === "front") {
          setInsuranceFront(null)
        }
        else {
          setInsuranceBack(null)

        }
        setFileUplaoding(false);
      }
    } catch (err) { }
  };

  const updateRentOption = async (list) => {
    let { res } = await UpdateRentOption(list)
    if (res) {
      let obj = Object.assign({}, ...res);
      setList(obj?.vehicle);
    }
  }


  useEffect(() => {
    let fecth = async () => {
      setLoading(true);
      let { res } = await GetVihicalsDetail(id);
      if (res) {
        let obj = Object.assign({}, ...res);
        setList(obj?.vehicle_details);
        setinstant_Car(obj?.vehicle_details?.available_instant)
        setChart(obj);
        setActiveImg(obj?.vehicle_details?.images[0]);
        if (obj) {
          setUser(obj?.vehicle_details?.user);
        }
        setLoading(false);
      }
    };
    fecth();
  }, []);

  // console.log("itme", list)
  return (
    <>
      {show && (
        <Popup
          modalOpen={show}
          data={
            type === "preview" ?
              <PreviewLicence pic={preview} />
              :
              <AddQuestion title={title} answers={answers} onClose={() => setShow(false)} />
          }
          onClose={() => setShow(false)}
        />
      )}
      {loading ? (
        <LoaderSpinner type="tableLoad" />
      ) : (
        <>
          <div className="text-[#ACACAC] text-[16px] pb-4">
            <Link
              to={
                location === `/dinss/tech/vehicalOwner/detail/${id}/detail`
                  ? `/dinss/tech/vehicalOwner/detail/${user?.id}/carlist`
                  : location === `/dinss/tech/dashboard/vehical-detail/${id}/detail`
                    ? `/dinss/tech/dashboard/vehical-detail/${user?.id}/carlist`
                    : `/dinss/tech/dashboard/vehical-detail/${user?.id}/all`
              }
              className="cursor-pointer"
            >
              {" "}
              {`Car list > `}
            </Link>
            <span className="text-[#FF8C00] px-2 font-semibold">Details</span>
          </div>

          {/* section 1  */}
          <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-3">
            <div className="bg-white shadow-md border rounded-md border-gray-100 p-5">
              <div className="flex items-center">
                <div className=" w-[110px] h-[87px] rounded-[13.647px] border border-gray-100">
                  <img
                    src={list?.user?.image}
                    alt="user"
                    className="w-[110px] h-[87px] rounded-[13.647px] object-cover"
                  />
                </div>
                <div className="w-full pl-8 ">
                  <div className="flex items-center">
                    <h2 className="text-[#444444] text-[18px]">
                      {list?.user?.first_name}
                    </h2>
                    <span className="text-[#ACACAC] px-3">
                      {list?.with_driver === 1
                        ? "( With Driver )"
                        : "( Without Driver )"}
                    </span>
                  </div>
                  <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap justify-between pt-2">
                    <div className="">
                      <h2 className="text-[#ACACAC] text-[16px]">
                        Proprétaire
                      </h2>
                      <div
                        style={{
                          color: `${list?.status_object?.text_color}`,
                          backgroundColor: `${list?.status_object?.background_color}`,
                        }}
                        className={` mt-3 text-[14px]  rounded-full text-center  py-2 w-full px-2 ml-auto`}
                      >
                        <h2>{list?.status_object?.text_en}</h2>
                      </div>
                    </div>
                    <div className="flex items-center lg:py-0 md:py-0 py-2">
                      <img
                        src={message}
                        alt="message"
                        className="object-cover"
                      />
                      <img
                        src={call}
                        alt="message"
                        className="object-cover ml-2"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="pt-4">
                <p className="text-[#ACACAC] text-[16px]">
                  {list?.description}{" "}
                </p>
              </div>
            </div>
            <div className="">
              <img
                src={activeImg?.image}
                alt="car"
                className="object-cover rounded-[11.904px] w-full h-[400px]"
              />
            </div>
          </div>

          {/*  section 2 */}
          <div className="grid lg:grid-cols-2 md:grid-cols-2  gap-3 pt-6">
            <div className="bg-white shadow-md border lg:order-0 md:order-0 order-1 rounded-md border-gray-100 p-5">
              <h1 className="text-[#FF8C00] font-semibold text-[18px]">
                Caracteristiques
              </h1>
              <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-2 lg:gap-0 md:gap-0 gap-5 pt-8">
                <div className="">
                  <img src={pump} alt="car" className="object-cover" />
                  <h2 className="text-[#444444] font-medium text-[18px] pt-3">
                    Carburant
                  </h2>
                  <p className="text-[#ACACAC] text-[16px]">{list?.fuel_type?.fuel_type}</p>
                </div>
                <div className="">
                  <img src={boite} alt="car" className="object-cover" />
                  <h2 className="text-[#444444] font-medium text-[18px] pt-3">
                    Boite
                  </h2>
                  <p className="text-[#ACACAC] text-[16px]">{list?.gearbox}</p>
                </div>

                <div className="">
                  <img src={seet} alt="car" className="object-cover" />
                  <h2 className="text-[#444444] font-medium text-[18px] pt-3">
                    Places, Portes
                  </h2>
                  <p className="text-[#ACACAC] text-[16px]">
                    {list?.no_of_places},{list?.no_of_doors}
                  </p>
                </div>
              </div>
              <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-2 lg:gap-0 md:gap-0 gap-5 pt-8">
                {/* <div className="">
                  <img src={setting} alt="car" className="object-cover" />
                  <h2 className="text-[#444444] font-medium text-[18px] pt-3">
                    AC
                  </h2>
                  <p className="text-[#ACACAC] text-[16px]">convertisseur</p>
                </div> */}
                {list?.accessories &&
                  list?.accessories.find(
                    (f) => f.accessory?.accessory === "AC"
                  ) ? (
                  <div className="">
                    <img src={usb} alt="car" className="object-cover" />
                    <h2 className="text-[#444444] font-medium text-[18px] pt-3">
                      {list?.accessories &&
                        list?.accessories.find(
                          (f) => f.accessory?.accessory === "AC"
                        )
                        ? "AC"
                        : ""}
                    </h2>
                    <p className="text-[#ACACAC] text-[16px]">convertisseur</p>
                  </div>
                ) : (
                  ""
                )}
                {list?.accessories &&
                  list?.accessories.find(
                    (f) => f.accessory?.accessory === "Chargeur USB"
                  ) ? (
                  <div className="">
                    <img src={usb} alt="car" className="object-cover" />
                    <h2 className="text-[#444444] font-medium text-[18px] pt-3">
                      {list?.accessories &&
                        list?.accessories.find(
                          (f) => f.accessory?.accessory === "Chargeur USB"
                        )
                        ? "USB"
                        : ""}
                    </h2>
                    <p className="text-[#ACACAC] text-[16px]">chargeur</p>
                  </div>
                ) : (
                  ""
                )}
                {list?.accessories &&
                  list?.accessories.find(
                    (f) => f.accessory?.accessory === "Bluetooth"
                  ) ? (
                  <div className="">
                    <img src={bluthoth} alt="car" className="object-cover" />
                    <h2 className="text-[#444444] font-medium text-[18px] pt-3">
                      {list?.accessories &&
                        list?.accessories.find(
                          (f) => f.accessory?.accessory === "Bluetooth"
                        )
                        ? "Bluetooth"
                        : ""}
                    </h2>
                    <p className="text-[#ACACAC] text-[16px]">connexion</p>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="lg:order-1 md:order-1 order-0">
              <div className="grid grid-cols-5 gap-4 pl-1">
                {/* <img src={car2} alt="car"  />
                        <img src={car2} alt="car" className='object-cover rounded-md' />
                        <img src={car3} alt="car" className='object-cover rounded-md' />
                        <img src={car4} alt="car" className='object-cover rounded-md' />
                        <img src={car2} alt="car" className='object-cover rounded-md' /> */}
                {Images?.map((item, i) => (
                  <img
                    onClick={() => setActiveImg(item)}
                    key={i}
                    src={item?.image}
                    alt="car"
                    className={`object-cover w-[95px] h-[80px]  rounded-md border-[3px] ${item?.id === activeImg?.id ? "border-[#FF8C00]" : ""
                      }`}
                  />
                ))}
              </div>
              <div className="bg-white shadow-md border rounded-md  border-gray-100 p-5 mt-4">
                <h1 className="text-[#FF8C00] text-[18px] font-semibold">
                  Total Requests
                </h1>
                <div>
                  <CarListPieChart statis={chart} />
                </div>
              </div>
            </div>
          </div>

          {/* secrtion 3  */}
          <section>
            <div className="grid lg:grid-cols-2 md:grid-cols-2  gap-3 pt-6">
              <div className="bg-white shadow-md border rounded-md border-gray-100 p-5">
                <h1 className="text-[#FF8C00] font-semibold text-[18px]">
                  Location
                </h1>
                <div className="flex justify-between items-center pt-5">
                  <h2 className="text-[#898989] text-[14px] ">
                    Within Abidjan
                  </h2>
                  <p className="text-[#FF8C00]  text-[14px]">
                    {FormatePrice(list?.price_in_abidjan)} FCFA
                    <span className="text-[#898989] "> /day</span>
                  </p>
                </div>
                <div className="flex justify-between items-center pt-5">
                  <h2 className="text-[#898989] text-[14px] ">
                    Outside Abidjan
                  </h2>
                  <p className="text-[#FF8C00]  text-[14px]">
                    {FormatePrice(list?.price_out_abidjan)} FCFA
                    <span className="text-[#898989] "> /day</span>
                  </p>
                </div>
                {list?.locations?.map((loc, i) => (
                  <div className="flex items-center pt-5 " key={i}>
                    <IoLocation className="text-[#FF8C00]" />
                    <h2 className="ml-1 text-[#444444]">{loc?.address}</h2>
                  </div>
                ))}
              </div>
              <div className="bg-white shadow-md border rounded-md border-gray-100 p-5">
                <h1 className="text-[#FF8C00] font-semibold text-[18px]">
                  Rental Options
                </h1>
                <div className="flex justify-between items-center">
                  <div className="flex flex-col pt-6">
                    <h2 className="text-[#444444] text-[14px] ">Availability</h2>
                    <p className="text-[#444444] pt-3 text-[14px]">{list?.available_outside_abidjan === 0 ? ' Within Abidjan' : "Outside Abidjan"}</p>
                  </div>

                  <div className="flex flex-col pt-6">
                    <h2 className="text-[#444444] text-[14px] ">Smokers</h2>
                    <p className="text-[#898989]  text-[14px] pt-3"> {list?.is_smoker_allowed === 0
                      ? "Not Accepting"
                      : "Accepting"}</p>
                  </div>
                  <div className="flex flex-col pt-6">
                    <h2 className="text-[#444444] text-[14px] ">Instant Booking</h2>
                    <p onClick={() => updateRentOption(list)} className={`${list?.available_instant === 0 ? 'text-red-600' : 'text-green-600'} cursor-pointer pt-3 text-[14px]`}>{list?.available_instant === 0 ? 'No' : "Yes"}</p>
                  </div>
                  <div className="flex flex-col pt-6">
                    <h2 className="text-[#444444] text-[14px] ">Minimul Rental Duration</h2>
                    <p onClick={() => updateRentOption(list)} className={`cursor-pointer pt-3 text-[14px]`}>{list?.minimumRentalDuration}</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* section 4  */}

          <div className="bg-white shadow-md border rounded-md border-gray-100 p-5 mt-5">
            <h1 className="text-[#FF8C00] font-semibold text-[18px]">
              Vehicle Details
            </h1>
            <div className="grid grid-cols-6 gap-19 pt-5">
              <div className="">
                <h2 className="text-[#444444] text-[14px] ">Number Plate</h2>
                <p className="text-[#444444]  text-[14px]">
                  {list?.insurance?.plate_number || "N/A"}
                </p>
              </div>
              <div className="">
                <h2 className="text-[#898989] text-[14px] ">Brand</h2>
                <p className="text-[#898989]  text-[14px]">
                  {list?.make || "N/A"}
                </p>
              </div>
              <div className="">
                <h2 className="text-[#898989] text-[14px] ">Model</h2>
                <p className="text-[#898989]  text-[14px]">
                  {list?.model || "N/A"}
                </p>
              </div>
              <div className="">
                <h2 className="text-[#898989] text-[14px] ">Year</h2>
                <p className="text-[#898989]  text-[14px]">
                  {list?.year || "N/A"}
                </p>
              </div>
              <div className="">
                <h2 className="text-[#898989] text-[14px] ">Mileage</h2>
                <p className="text-[#898989]  text-[14px]">
                  {list?.mileage || "N/A"}
                </p>
              </div>
              <div className="pl-10">
                <h2 className="text-[#898989] text-[14px] ">Vehicle type</h2>
                <p className="text-[#898989]  text-[14px]">
                  {list?.vehicle_category || "N/A"}
                </p>
              </div>
            </div>
          </div>

          {/* section 5  */}
          <section>
            <div className="grid lg:grid-cols-5 md:grid-cols-2 grid-cols-2  gap-3 pt-6">
              <div className="bg-white shadow-md border rounded-md border-gray-100 p-5  col-span-2">
                <h1 className="text-[#FF8C00] font-semibold text-[18px]">
                  Discount Option
                </h1>
                <div className="flex justify-between items-center pt-5">
                  <h2 className="text-[#898989] text-[14px] ">
                    Rental [7-29 days] (%)
                  </h2>
                  <h2 className="text-[#898989] text-[14px] ">
                    {`Rental <30 days)`}(%)
                  </h2>
                </div>
                <div className="flex justify-between items-left pt-5">
                  <h2 className="text-[#898989] text-[14px] w-full ">
                    {list?.discount_for_7_to_29_days}%
                  </h2>
                  <h2 className="text-[#898989] text-[14px] text-left  lg:w-[40%] w-[26%] ">
                    {list?.discount_for_30_days}%
                  </h2>
                </div>

              </div>
              <div className="bg-white shadow-md border rounded-md border-gray-100 p-5 col-span-3">
                <h1 className="text-[#FF8C00] font-semibold text-[18px]">
                  Rental Targets (Step 8/11)
                </h1>
                <div className="flex justify-between items-center">
                  <div className="flex flex-col pt-6">
                    <h2 className="text-[#444444] text-[14px] ">Q1, Financial target</h2>
                    <p onClick={() => handleAddQuestion("Q1, Financial target" ,list?.goals?.financial_goal?.text )} className="text-[#FF8C00] cursor-pointer pt-3 text-[14px]">Click to see</p>
                  </div>

                  <div className="flex flex-col pt-6">
                    <h2 className="text-[#444444] text-[14px] ">Q2, Vehicle use frequency</h2>
                    <p onClick={() => handleAddQuestion("Q2, Vehicle use frequency" , list?.goals?.usage_goal?.text )} className="text-[#FF8C00] cursor-pointer pt-3 text-[14px]">Click to see</p>
                  </div>
                  <div className="flex flex-col pt-6">
                    <h2 className="text-[#444444] text-[14px] ">Q3, Vehicle rental frequency</h2>
                    <p onClick={() => handleAddQuestion("Q3, Vehicle rental frequency" , list?.goals?.renting_goal?.text)} className="text-[#FF8C00] cursor-pointer pt-3 text-[14px]">Click to see</p>
                  </div>
                </div>
              </div>
            </div>
          </section>


          {/* section 6  */}
          <section>
            <div className="bg-white shadow-md border rounded-md border-gray-100 p-5 mt-5">
              <h1 className="text-[#FF8C00] text-[18px] font-semibold">
                Insurance Details
              </h1>
              <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap justify-between items-center pt-5">
                <div className="">
                  <h2 className="text-[#444444] text-[14px]">Insurance ID</h2>
                  <p className="text-[14px] pt-4 text-[#898989] leading-[22px]">
                    {list?.insurance?.grey_card_number || "N/A"}
                  </p>
                </div>
                <div className="">
                  <h2 className="text-[#444444] text-[14px]">
                    Registration ID
                  </h2>
                  <p className="text-[14px] pt-4 text-[#898989] leading-[22px]">
                    {list?.insurance?.grey_card_number || "N/A"}
                  </p>
                </div>
                <div className="">
                  <h2 className="text-[#444444] text-[14px]">
                    Insurance Validity
                  </h2>
                  <p className="text-[14px] pt-4 text-[#898989] leading-[22px]">
                    {moment(list?.valid_from).format("ll")}
                  </p>
                </div>
                <div className="">
                  <h2 className="text-[#444444] text-[14px] text-center">
                    Status
                  </h2>
                  <p className="text-[14px] mt-4 text-[#2BA676] bg-[#E0F3EC] px-6 py-2 rounded-full leading-[22px]">
                    valid
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-5">
                <div className="pt-5">
                  <h2 className="text-[#444444] text-[14px] font-medium pb-5">
                    Upload Front Doccument
                  </h2>
                  <label htmlFor="front" className="cursor-pointer">
                    <div className="border py-[20px] border-dashed rounded-md text-center">
                      {fileUplading && fileType === "front" ? (
                        <LoaderSpinner type="saved" />
                      ) : (
                        <>
                          <div className="flex justify-center items-center">
                            <img src={upload} alt="upload" />
                          </div>
                          <h2 className="text-[14px] font-normal text-[#8C979A] font-sans">
                            Drop your files here, or browse
                          </h2>
                          <p className="text-[14px] text-[#8C979A] font-normal font-sans">
                            MP4, MKV, PDF, JPEG, Max size: 20MB
                          </p>
                        </>
                      )}
                    </div>
                  </label>
                  <input
                    type="file"
                    className="hidden"
                    onChange={(e) => handleUplaodDoc(e, "front")}
                    id="front"
                  />
                </div>
                <div className="pt-5">
                  <h2 className="text-[#444444] text-[14px] font-medium pb-5">
                    Upload Back Doccument
                  </h2>
                  <label htmlFor="back" className="cursor-pointer">
                    <div className="border py-[20px] border-dashed rounded-md text-center">
                      {fileUplading && fileType === "back" ? (
                        <LoaderSpinner type="saved" />
                      ) : (
                        <>
                          <div className="flex justify-center items-center">
                            <img src={upload} alt="upload" />
                          </div>
                          <h2 className="text-[14px] font-normal text-[#8C979A] font-sans">
                            Drop your files here, or browse
                          </h2>
                          <p className="text-[14px] text-[#8C979A] font-normal font-sans">
                            MP4, MKV, PDF, JPEG, Max size: 20MB
                          </p>
                        </>
                      )}
                    </div>
                  </label>
                  <input
                    type="file"
                    onChange={(e) => handleUplaodDoc(e, "back")}
                    className="hidden"
                    id="back"
                  />
                </div>
              </div>

              <div className="pt-5">
                <div className="grid lg:grid-cols-2 md:grid-cols-2  gap-5">
                  <div>
                    {list?.insurance || insuranceFront !== null ?
                      <div className="border border-[#ACACAC] rounded-md mt-4 px-2">
                        <div className="flex justify-between items-center">
                          <div className="flex items-center">
                            <img
                              src={file}
                              alt="file_image"
                              className="object-cover"
                            />
                            <div className="px-2 py-2">
                              <p className="text-[14px] text-[#444444]">
                                {
                                  list?.insurance !== null ?
                                    list?.insurance?.document_pdf_front
                                      ?.file_name
                                    : insuranceFront?.document_pdf_front
                                      ?.file_name
                                }
                              </p>
                              <span className="text-[12px] text-[#898989]">
                                {moment(
                                  list?.insurance !== null ? list?.insurance?.front_file_created :
                                    insuranceFront?.front_file_created
                                ).format("ll")}
                                , Size:
                                {list?.insurance ? list?.insurance?.front_file_size : insuranceFront?.front_file_size}
                              </span>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <h2
                              onClick={() =>
                                handlePreview(
                                  list?.insurance !== null ? list?.insurance?.document_pdf_front?.url : insuranceFront?.document_pdf_front
                                )
                              }
                              className="underline mx-1 text-[#397DED] text-[14px]"
                            >
                              <AiFillEye className="text-[20px]" />
                            </h2>
                            {isEdit && (
                              <>
                                <MdDelete
                                  className="text-[#F04438] mx-1 "
                                  onClick={() => handleDelete("front")}
                                />
                                <BsFillCloudDownloadFill onClick={() => downloadFile(list?.insurance !== null ? list?.insurance?.document_pdf_front?.url : insuranceFront?.document_pdf_front)} />
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      : "Document Not Found"
                    }
                  </div>
                  <div>
                    {
                      list?.insurance !== null || insuranceBack !== null ? (
                        <div className="border border-[#ACACAC] rounded-md mt-4 px-2">
                          <div className="flex justify-between items-center">
                            <div className="flex items-center">
                              <img
                                src={file}
                                alt="file_image"
                                className="object-cover"
                              />
                              <div className="px-2 py-2">
                                <p className="text-[14px] text-[#444444]">
                                  {list?.insurance !== null ? list?.insurance?.document_pdf_back?.file_name : insuranceBack?.document_pdf_back?.file_name}
                                </p>
                                <span className="text-[12px] text-[#898989]">
                                  {moment(
                                    list?.insurance !== null ? list?.insurance?.back_file_created : insuranceBack?.back_file_created
                                  ).format("ll")}
                                  , Size:
                                  {list?.insurance !== null ? list?.insurance?.back_file_size : insuranceBack?.back_file_size}
                                </span>
                              </div>
                            </div>
                            <div className="flex items-center">
                              <h2
                                onClick={() =>
                                  handlePreview(
                                    list?.insurance !== null ? list?.insurance?.document_pdf_back?.url : insuranceBack?.document_pdf_back
                                  )
                                }
                                className="underline mx-1 text-[#397DED] text-[14px]"
                              >
                                <AiFillEye className="text-[20px]" />
                              </h2>
                              {isEdit && (
                                <>
                                  <MdDelete
                                    className="text-[#F04438] mx-1 "
                                    onClick={() => handleDelete("back")}
                                  />
                                  <BsFillCloudDownloadFill onClick={() => downloadFile(list?.insurance ? list?.insurance?.document_pdf_back?.url : insuranceBack)} />
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        "Document Not Found"
                      )}
                  </div>
                </div>

              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default CarlistDetail;
