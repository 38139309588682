import React from "react";

function RefreshIcon(props) {
  return (
    <svg
      {...props}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5613 0.438477V5.20581H6.79395V3.6167H9.15278C8.78033 3.1201 8.31684 2.7311 7.76231 2.4497C7.20778 2.16829 6.62014 2.02759 5.99939 2.02759C5.51107 2.02759 5.05172 2.11035 4.62133 2.27589C4.19095 2.44142 3.79781 2.66903 3.44191 2.95871C3.08602 3.24839 2.79634 3.60015 2.57287 4.01398C2.3494 4.42781 2.19214 4.87061 2.1011 5.34237L0.524405 5.06925C0.640277 4.39884 0.863746 3.78223 1.19481 3.21942C1.52588 2.65661 1.93143 2.16829 2.41147 1.75446C2.89152 1.34063 3.44191 1.01784 4.06266 0.786095C4.68341 0.554349 5.32898 0.438477 5.99939 0.438477C6.75256 0.438477 7.47263 0.583317 8.15959 0.872999C8.84655 1.16268 9.45074 1.58479 9.97217 2.13932V0.438477H11.5613ZM5.99939 9.97314C6.47943 9.97314 6.93879 9.89038 7.37745 9.72485C7.81611 9.55931 8.20925 9.33171 8.55687 9.04202C8.90448 8.75234 9.19417 8.40059 9.42591 7.98676C9.65766 7.57292 9.81491 7.13013 9.89768 6.65836L11.4744 6.93149C11.3585 7.60189 11.135 8.2185 10.804 8.78131C10.4729 9.34412 10.0673 9.83244 9.58731 10.2463C9.10726 10.6601 8.55687 10.9829 7.93612 11.2146C7.31537 11.4464 6.6698 11.5623 5.99939 11.5623C5.24622 11.5623 4.52615 11.4174 3.83919 11.1277C3.15223 10.8381 2.54804 10.4159 2.02661 9.86141V11.5623H0.4375V6.79492H5.20483V8.38403H2.846C3.21844 8.88063 3.68194 9.26963 4.23647 9.55104C4.791 9.83244 5.37864 9.97314 5.99939 9.97314Z"
        fill="#98A2B3"
      />
    </svg>
  );
}

export default RefreshIcon;
