import React from "react";
import Layout from "../../components/layouts/mainLayout/MainLayout";
import Banner from "../../components/MainLandingComp/helpSupport/Banner";
const Test = () => {
  return (
    <Layout>
      <Banner
        left="Centre d’aide"
        right="Conditions Générales d'Utilisation"
        center=""
      />

      <div
        onCopy={(e) => {
          e.preventDefault();
        }}
        className="flex flex-col px-5 py-10 md:px-8 md:py-12 lg:px-16 lg:py-14 xl:py-16 xl:px-28 gap-5 md:gap-7 lg:gap-9 xl:gap-12 items-start  xl:justify-center 2xl:w-[1440px] mx-auto"
      >
        <div className="flex flex-col gap-3">
          <h2 className="text-lg md:text-xl lg:text-2xl lg:font-semibold text-[#444444] mb-2">
            Conditions Générales d'Utilisation
          </h2>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Version publiée le 17 octobre 2023 et entrée en vigueur le 20
            octobre 2023.
          </p>
          <span className="text-base font-bold text-[#444444] mb-[5px]">
            Mentions légales :
          </span>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            La plateforme{" "}
            <a
              href="https://primecarapp.com/"
              style={{ color: "blue", borderBottom: "1px solid blue" }}
            >
              www.primecarapp.com
            </a>{" "}
            est la propriété de la société dénommée Digital Innovation Services
            & Solutions en abrégé{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS,
            </span>{" "}
            une SARL au capital de 1000,000 FCFA,immatriculée au Registre du
            Commerce de Grand-Bassam, sous le numéro CI-GRDBSM-2021-B-9497, dont
            le siège est sis à Grand-Bassam route expresse Bassam-Bonoua,Section
            AX, Parcelle 62, Lot N°1690, ILOT 172.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Le présent site web et l’application mobile sont développés par{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>
            .
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Merci d’utiliser{" "}
            <span className="text-base font-semibold text-[#444444]">
              Primecar,
            </span>{" "}
            nous sommes heureux de vous compter parmi les membres de notre
            communauté qui contribue à faciliter l’accès aux services de
            mobilité et de logistique dans la région ivoirienne.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base mb-[10px]">
            Nous vous prions de porter une attention particulière aux présentes
            conditions générales d’utilisation et de service (les « CGU »).
            Elles contiennent des informations importantes sur les droits, les
            obligations des Utilisateurs, ainsi que les limitations et
            exclusions applicables.
          </p>
          <h2 className="text-lg md:text-xl lg:text-2xl text-[#444444] heading">
            Introduction
          </h2>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <span className="text-base font-semibold text-[#444444]">
              Primecar
            </span>{" "}
            met à disposition une plateforme de mise en relation entre des
            Utilisateurs recherchant un véhicule à louer et des utilisateurs
            souhaitant louer leur véhicule. Se faisant,{" "}
            <span className="text-base font-semibold text-[#444444]">
              Primecar
            </span>{" "}
            se positionne comme un acteur de l’économie collaborative. La
            plateforme est un intermédiaire qui n'est pas partie à l'opération
            de location qui est faite entre les propriétaires des véhicules et
            les locataires.{" "}
            <span className="text-base font-semibold text-[#444444]">
              Primecar
            </span>{" "}
            ne procède à aucune location de véhicule, que ce soit à travers
            notre site internet, l’application ou les services proposés, et n'a
            pas d'autre activité que celle de mettre en relation des
            utilisateurs pour la location d'un véhicule.
            <br />
            La Plateforme permet ainsi le dépôt d’annonces de location, la
            recherche et la location de véhicules avec ou sans chauffeur sur une
            durée bien déterminée. Elle agit en tiers de confiance pour réaliser
            les transactions liées à ces locations.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Les Locations sont régies par les présentes CGU et sont complétées
            par les Contrats de Location, tels qu'ils sont décrits dans les CGU.
            Les utilisateurs ne peuvent bénéficier des services proposés par{" "}
            <span className="text-base font-semibold text-[#444444]">
              Primecar
            </span>{" "}
            que s'ils respectent les CGU ainsi que les termes du contrat de
            location.
          </p>
          <h2 className="text-lg md:text-xl lg:text-2xl text-[#444444] sub_heading">
            1. Définitions
          </h2>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Les termes définis ci-dessous auront entre autres la signification
            suivante :
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            «{" "}
            <span className="text-base font-semibold text-[#444444]">
              Annonce de location
            </span>{" "}
            » désigne l’annonce d’un ou plusieurs véhicules proposés à la
            location à destination d’un loueur par le biais de la plateforme,
            c’est à dire, le site internet ou l’application ;
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            «{" "}
            <span className="text-base font-semibold text-[#444444]">
              Application{" "}
            </span>{" "}
            »: désigne l’application mobile intitulée{" "}
            <span className="text-base font-semibold text-[#444444]">
              Primecar,
            </span>{" "}
            développée par la société DINSS sous Android et iOS afin d’utiliser
            les services ;
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            «{" "}
            <span className="text-base font-semibold text-[#444444]">
              Assurance et Assistance
            </span>{" "}
            du locataire et du propriétaire » : correspondent à l’assurance et
            l’assistance dont bénéficient les utilisateurs au cours d’une
            location. L’assurance est proposée par une compagnie d’assurance aux
            frais du propriétaire ;
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            «{" "}
            <span className="text-base font-semibold text-[#444444]">
              Assistance routière
            </span>{" "}
            » désigne les services d'assistance accordés au locataire lorsque le
            véhicule a subi un dommage ou une défaillance technique pendant la
            location rendant le véhicule inopérable ;
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            «{" "}
            <span className="text-base font-semibold text-[#444444]">
              Autorisation de prélèvement
            </span>{" "}
            » : désigne l’autorisation expresse que donne le locataire à{" "}
            <span className="text-base font-semibold text-[#444444]">
              Primecar
            </span>{" "}
            de réaliser des prélèvements sur son compte bancaire, avant ou à
            l’issue de la location exceptionnellement pour les sommes dues au
            titre des frais complémentaires occasionnés durant la location ;
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            «{" "}
            <span className="text-base font-semibold text-[#444444]">
              Compte
            </span>{" "}
            » : désigne la section sur le site ou l’application réservé à chaque
            utilisateur après l'acceptation des CGU. Il est accessible par
            identifiant et par mot de passe. Tous les utilisateurs doivent y
            communiquer certaines données à caractère personnel pour bénéficier
            des services offerts par la plateforme, à savoir, la publication
            d'une annonce ou la location d'un véhicule ;
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            «{" "}
            <span className="text-base font-semibold text-[#444444]">CGU</span>{" "}
            » : désigne les présentes Conditions Générales d’Utilisation du site{" "}
            <span className="text-base font-semibold text-[#444444]">
              Primecar
            </span>{" "}
            exprimant l’intégralité de l’accord des Utilisateurs sur le Site,
            les Services, l’Application ;
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            «{" "}
            <span className="text-base font-semibold text-[#444444]">
              Commission d’intermédiation
            </span>{" "}
            » : désigne les frais dont le propriétaire et/ou le locataire
            s’acquitte pour être mis en relation à travers la Plateforme. Le
            propriétaire s’acquitte des « frais de prestation », quant au
            locataire, il sursoit aux « frais de service ». Ces termes font
            référence à la commission d’intermédiation ;
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            «{" "}
            <span className="text-base font-semibold text-[#444444]">
              Contrat de location
            </span>{" "}
            » : désigne le Contrat de location mis à la disposition des
            utilisateurs par{" "}
            <span className="text-base font-semibold text-[#444444]">
              Primecar
            </span>
            . Il est soit format dématérialisé via l’Application ou soit sous
            format papier. Ce contrat lie le locataire et le propriétaire. Pour
            le propriétaire, ce contrat est généré lorsque le véhicule qu’il met
            sur la plateforme est approuvé et rempli les CGU de la plateforme.
            Pour le locataire, Il est généré au moment où la location est
            confirmée, c’est-à-dire payée par le locataire. Ce dernier le reçoit
            par mail. Le contrat de location concerne, en exclusivité, le
            locataire et le propriétaire ;
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            «{" "}
            <span className="text-base font-semibold text-[#444444]">
              Demande de location
            </span>{" "}
            » : désigne la demande de location ou de réservation faite par un
            locataire auprès d’un propriétaire. Ce dernier peut accepter ou
            rejeter la demande. À ce stade, il s’agit d’une demande qui n’a pas
            encore été payée par le locataire ;
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            «{" "}
            <span className="text-base font-semibold text-[#444444]">
              Frais post-location ou Franchise
            </span>{" "}
            » : désigne tous les frais inhérents à la location, dus par le
            locataire au propriétaire et pouvant survenir après la location. Par
            exemple, sans que cette liste soit exhaustive, nous avons les frais
            de réparation pour un sinistre occasionné pendant une location non
            pris en charge par l’assureur, ou d’autres frais non susmentionnés ;
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            «{" "}
            <span className="text-base font-semibold text-[#444444]">
              Frais de services
            </span>{" "}
            » : désigne les frais de fonctionnement de la plateforme
            (maintenance et développement du site et de l’application,
            accompagnement des utilisateurs, sécurité des opérations en ligne).
            Ils sont tributaires du prix de location et viennent s’ajouter au
            prix de la location ;
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            «{" "}
            <span className="text-base font-semibold text-[#444444]">
              Horaires de la location
            </span>{" "}
            » : désigne les horaires qui caractérisent la période de la
            location. Ils sont contenus dans le contrat de location
            dématérialisé ou papier, lequel reprend les horaires réels de remise
            des clés à l’aller et au retour du véhicule ;
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Les retenues et pénalités applicables aux Utilisateurs se
            déterminent sur la base des horaires de location définis, en accord
            entre les utilisateurs ;
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            «{" "}
            <span className="text-base font-semibold text-[#444444]">
              Locataire
            </span>{" "}
            » : désigne l’utilisateur dénommé au Contrat de location :
            <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
              <ul>
                <li className="list_items list-disc">
                  <div className="list_text_margin">
                    <span className="text-base font-semibold text-[#444444] m-0">
                      Cas d’une location avec chauffeur
                    </span>
                    : Personne physique ou morale, qui souhaite louer un
                    véhicule sur une durée déterminée.
                  </div>
                </li>
                <li className="list_items list-disc">
                  <div className="list_text_margin">
                    <span className="text-base font-semibold text-[#444444]">
                      Cas d’une location sans chauffeur
                    </span>
                    : Personne physique, titulaire d’un permis de conduire de la
                    catégorie du véhicule loué et en cours de validité avec un
                    solde de points supérieur à zéro, si applicable, et conforme
                    à la réglementation ivoirienne, souhaitant louer un véhicule
                    sans chauffeur pour en être le conducteur principal.
                  </div>
                </li>
              </ul>
            </p>
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            «{" "}
            <span className="text-base font-semibold text-[#444444]">
              Location
            </span>{" "}
            » : désigne la réservation qui a commencé à s’exécuter et qui a pris
            effet le jour et l’heure fixé pour le démarrage du contrat de
            location. À partir de ce moment, la réservation devient et se
            dénomme location ;
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            «{" "}
            <span className="text-base font-semibold text-[#444444]">
              Plateforme
            </span>{" "}
            » : désigne l’infrastructure technique mise en place par{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>
            , regroupant le site internet, les applications et les services ;
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            «{" "}
            <span className="text-base font-semibold text-[#444444]">
              Plateforme d’intermédiation
            </span>{" "}
            » : désigne{" "}
            <span className="text-base font-semibold text-[#444444]">
              Primecar
            </span>{" "}
            et se définit comme un intermédiaire de mise en relation entre
            plusieurs parties à travers une plateforme en ligne proposant, à but
            lucratif, un service en ligne en vue de la conclusion d’un contrat
            portant sur la location d’un véhicule ;
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            «{" "}
            <span className="text-base font-semibold text-[#444444]">
              Prix de la location
            </span>{" "}
            » : désigne le prix de la location par jour déterminé librement par
            le propriétaire ;
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Pour éviter les excès et les prix trop bas,{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            propose un prix recommandé relatif au tarif journalier. Selon la
            durée de la location,{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            pourrait appliquer également une dégressivité sur le tarif
            journalier ;
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            «{" "}
            <span className="text-base font-semibold text-[#444444]">
              Prix Recommandé
            </span>{" "}
            » : désigne un tarif journalier proposé sur la plateforme afin
            d’éviter les excès et les prix abusivement bas. Il est proposé à
            titre indicatif (non obligatoire) et le propriétaire peut à tout
            moment le faire varier ;
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            «{" "}
            <span className="text-base font-semibold text-[#444444]">
              Prix Total
            </span>{" "}
            » : désigne le prix final et total payé par le Locataire. Il
            correspond à l’addition des éléments suivants :
            <ul>
              <li className="list_items list-disc">
                <span className="list_text_margin">Prix de la Location</span>
              </li>
              <li className="list_items list-disc">
                <span className="list_text_margin">Frais de services</span>
              </li>
              <li className="list_items list-disc">
                <span className="list_text_margin">
                  Réduction (Coupon de réduction)
                </span>
              </li>
            </ul>
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            À l’issue de la location, des frais post-location ou une franchise
            peuvent être facturés au locataire ;
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            «{" "}
            <span className="text-base font-semibold text-[#444444]">
              Propriétaire
            </span>{" "}
            » : désigne l’utilisateur dénommé au contrat de location, personne
            physique ou morale, propriétaire d’un Véhicule et/ou autorisée à le
            louer, mettant en ligne des annonces de location, avec ou sans
            chauffeur ;
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            «{" "}
            <span className="text-base font-semibold text-[#444444]">
              Réservation
            </span>{" "}
            » : désigne une demande de location qui a été acceptée par le
            propriétaire et payée par le locataire ;
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            «{" "}
            <span className="text-base font-semibold text-[#444444]">
              Service
            </span>{" "}
            » : désigne le service de mise en relation entre un locataire et un
            propriétaire pour la location des véhicules via sur la plateforme ;
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            «{" "}
            <span className="text-base font-semibold text-[#444444]">Site</span>{" "}
            » : désigne les sites web permettant d’accéder aux services sur
            car.yuweens.com ;
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            «{" "}
            <span className="text-base font-semibold text-[#444444]">
              Connexion via une plateforme sociale
            </span>{" "}
            » : désigne la possibilité dont dispose un visiteur de créer un
            compte, de s'identifier sur la plateforme{" "}
            <span className="text-base font-semibold text-[#444444]">
              Primecar
            </span>
            , en utilisant un de ses comptes sociaux préexistant tels que
            Facebook, LinkedIn, Google au lieu de créer un nouveau compte de
            connexion spécifiquement pour{" "}
            <span className="text-base font-semibold text-[#444444]">
              Primecar
            </span>
            .
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            «{" "}
            <span className="text-base font-semibold text-[#444444]">
              Utilisateur(s)
            </span>{" "}
            » : désigne toute personne physique âgée de plus de 18 ans ou
            personne morale, titulaire d’un compte sur la plateforme{" "}
            <span className="text-base font-semibold text-[#444444]">
              Primecar
            </span>
            , susceptible d’en faire usage après acceptation des CGU.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            «{" "}
            <span className="text-base font-semibold text-[#444444]">
              Véhicule(s)
            </span>{" "}
            » : désigne le véhicule terrestre à moteur d’au moins quatre roues,
            soumis à l’obligation d’assurance, immatriculé dans le pays,
            appartenant au propriétaire et mis à disposition du locataire via la
            plateforme.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            «{" "}
            <span className="text-base font-semibold text-[#444444]">
              Visiteur(s)
            </span>{" "}
            » : désigne toute personne visitant la plateforme mais n’ayant pas
            le statut d’Utilisateur.
          </p>
          <h2 className="text-lg md:text-xl lg:text-2xl lg:font-semibold text-[#444444] heading">
            2. Conditions d’accès et d’éligibilité des utilisateurs
          </h2>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Les CGU aident à définir les relations entre les utilisateurs
            (propriétaire ou locataire) et la plateforme{" "}
            <span className="text-base font-semibold text-[#444444]">
              Primecar
            </span>
            . Elles mettent en avant les conditions d’accès et d’utilisation de
            la plateforme et des services proposés en ligne.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Pour accéder et utiliser la plateforme ou pour créer un compte,
            l'utilisateur doit être une personne physique ou morale, une
            organisation ou autre entité juridique dûment organisée, ayant une
            existence valide et en règle au regard des lois du pays de son siège
            et capable de conclure des contrats juridiquement contraignants.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Pour toute inscription sur la plateforme, les utilisateurs accèdent
            à leur espace membre à l'aide de leur prénom et nom de famille,
            choisissent un identifiant de connexion (une adresse e-mail) et un
            mot de passe associé. Dès lors, l’utilisateur devient garant et
            responsable de la création et de l’utilisation de son compte, qui
            lui est strictement personnel et ne peut donc être partagé avec des
            tiers (même un membre de sa famille).
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            L’utilisateur est responsable de toute utilisation des services via
            son compte, y compris par un tiers (à son insu ou en violation des
            dispositions qui précèdent).
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Le service est accessible et ne peut être utilisé que par les
            utilisateurs inscrits sur le site ou sur l'application, à condition
            que les utilisateurs (le locataire ou le propriétaire) acceptent les
            présentes CGU.
          </p>
          <h3 className="text-lg md:text-xl lg:text-1xl lg:font-semibold text-[#444444] sub_heading">
            2.1. Conditions applicables aux propriétaires
          </h3>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Les conditions applicables aux propriétaires de véhicules sont
            mentionnées comme ci- après :
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <ul>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Être âgé de plus de 18 ans ;
                </div>
              </li>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Être inscrit sur la plateforme sous sa véritable identité et
                  avoir fourni la véritable adresse de son domicile ;
                </div>
              </li>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Avoir communiqué un numéro de téléphone sur lequel le
                  propriétaire peut être joignable ;
                </div>
              </li>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  S’il est enregistré sur la plateforme comme étant un
                  professionnel (car il est inscrit sur un registre du Commerce
                  et des Sociétés en tant que société de location de véhicules,
                  ci-après le « Loueur Professionnel »), il doit avoir rempli
                  sur le site ou sur l'application toutes les informations
                  relatives à son entreprise (dénomination sociale, numéro du
                  registre de commerce, adresse, téléphone, etc.) ;
                </div>
              </li>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Ne pas avoir créé sur la plateforme plusieurs annonces pour le
                  même véhicule ;
                </div>
              </li>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Ne proposer à la location que des Véhicules dont il est et
                  demeure le propriétaire pendant toute la durée de la Location.
                </div>
              </li>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Le Loueur qui propose à la location un véhicule dont il
                  dispose en vertu d'un contrat de location doit justifier d'une
                  autorisation de sa société de location pour louer ce véhicule.
                  Ainsi,{" "}
                  <span className="text-base font-semibold text-[#444444]">
                    Primecar
                  </span>{" "}
                  pourra le considérer comme le propriétaire de ce véhicule ;
                </div>
              </li>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Ne proposer à la location que des véhicules en conformité avec
                  ce qui est requis par la réglementation, dont l’entretien et
                  la révision préconisés par le constructeur ont été effectués
                  et la présence de tous les équipements de sécurité
                  obligatoires dans le pays dans lequel le véhicule est loué.
                  Tous les équipements de sécurité doivent être, à sa
                  connaissance, en parfait état, notamment les pneumatiques, les
                  freins, les phares et feux, la direction, les ceintures de
                  sécurité, ainsi que le retrait du dispositif de télépéage
                  lorsque le véhicule est loué sur{" "}
                  <span className="text-base font-semibold text-[#444444]">
                    Primecar
                  </span>
                  .
                </div>
              </li>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Ne proposer à la location que des véhicules à jour du contrôle
                  technique et dont la validité de l’assurance couvre la période
                  de location. Les Véhicules en attente d’une contre-visite,
                  même s’ils sont autorisés à circuler, ne sont pas considérés
                  comme à jour de leur contrôle technique pour les besoins du
                  Service et ne peuvent être loués ;
                </div>
              </li>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Ne proposer à la location que des véhicules ne présentant
                  aucun dysfonctionnement pouvant affecter la jouissance
                  attendue et/ou normale de la location (air conditionné non
                  fonctionnel, vitre bloquée, etc.) ;
                </div>
              </li>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Ne proposer à la location que des véhicules automobiles
                  assurés à l’année au minimum au tiers et par toute assurance
                  requise par les lois du pays) ;
                </div>
              </li>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Ne proposer à la location que des véhicules dont le loueur
                  possède au moins un jeu de clés permettant d’ouvrir et de
                  démarrer le véhicule ;
                </div>
              </li>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Renseigner dans son annonce des informations vraies, exactes,
                  complètes et actuelles ;
                </div>
              </li>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Ne pas avoir fait l’objet de réclamations sérieuses et/ou
                  répétées de la part d’autres utilisateurs ou de{" "}
                  <span className="text-base font-semibold text-[#444444]">
                    DINSS
                  </span>
                  .
                </div>
              </li>
            </ul>
          </p>
          <h3 className="text-lg md:text-xl lg:text-1xl lg:font-semibold text-[#444444] sub_heading">
            2.2. Conditions applicables aux locataires
          </h3>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Les conditions applicables aux locataires de véhicules sont
            mentionnées comme ci- après :
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <ul>
              <li className="list_items  list-disc">
                <div className="list_text_margin">
                  Être âgé de plus de 18 ans ;
                </div>{" "}
              </li>
              <li className="list_items  list-disc">
                <div className="list_text_margin">
                  Être inscrit sur la plateforme sous sa véritable identité et
                  avoir fourni la véritable adresse de son domicile ;
                </div>{" "}
              </li>
              <li className="list_items  list-disc">
                <div className="list_text_margin">
                  Avoir communiqué un numéro de téléphone auquel le locataire
                  est effectivement joignable ;
                </div>{" "}
              </li>
              <li className="list_items  list-disc">
                <div className="list_text_margin">
                  Ne pas avoir créé sur le site plusieurs comptes ;
                </div>{" "}
              </li>
              <li className="list_items  list-disc">
                <div className="list_text_margin">
                  Dans le cas d’une location sans chauffeur :
                  <ul>
                    <li className="ml-5 list_items  list-circle">
                      <div className="list_text_margin">
                        Ne pas être en incapacité médicale à conduire.
                      </div>{" "}
                    </li>
                    <li className="ml-5 list_items  list-circle">
                      <div className="list_text_margin">
                        Être et demeurer le conducteur principal du Véhicule.
                      </div>{" "}
                    </li>
                    <li className="ml-5 list_items  list-circle">
                      <div className="list_text_margin">
                        Le locataire doit être titulaire d’un permis de conduire
                        valide dans le pays dans lequel le véhicule est loué.
                      </div>{" "}
                    </li>
                    <li className="ml-5 list_items  list-circle">
                      <div className="list_text_margin">
                        Ce dernier ne doit pas avoir commis, au moyen d’un
                        véhicule loué via la plateforme, d’infractions au code
                        de la route ayant entraîné l’application de plus de deux
                        peines contraventionnelles ou une peine délictuelle ;
                      </div>{" "}
                    </li>
                    <li className="ml-5 list_items  list-circle">
                      <div className="list_text_margin">
                        Dans le cas où le Locataire est titulaire d'un permis de
                        conduire étranger, il doit s’assurer de sa validité dans
                        le pays hôte notamment par le biais d’accords bilatéraux
                        existants.
                      </div>{" "}
                    </li>
                  </ul>
                </div>{" "}
              </li>
              <li className="list_items  list-disc">
                <div className="list_text_margin">
                  Être titulaire d’un moyen de paiement électronique en cours de
                  validité au moment de la réservation et jusqu’à la fin de
                  celle-ci ;
                </div>{" "}
              </li>
              <li className="list_items  list-disc">
                <div className="list_text_margin">
                  Ne pas avoir été en défaut de paiement vis-à-vis de ses
                  obligations dans le cadre des CGU applicables à une location
                  antérieure ;
                </div>{" "}
              </li>
              <li className="list_items  list-disc">
                <div className="list_text_margin">
                  Ne pas avoir été reconnu civilement ou pénalement responsable
                  d’un accident de la route, ayant entraîné plus de deux amendes
                  ou délit ;
                </div>{" "}
              </li>
              <li className="list_items  list-disc">
                <div className="list_text_margin">
                  Ne pas avoir fait l’objet de réclamations de la part d’autres
                  utilisateurs ou de{" "}
                  <span className="text-base font-semibold text-[#444444]">
                    DINSS{" "}
                  </span>{" "}
                  concernant le comportement de l'Utilisateur. Celles-ci
                  pourraient être notifiées dans les commentaires publiés sur la
                  plateforme, ou dans les messages privés envoyés entre les
                  utilisateurs au cours d’un exercice de location, ou en cas de
                  réclamations envoyées par un utilisateur ou de
                  <span className="text-base font-semibold text-[#444444]">
                    DINSS{" "}
                  </span>{" "}
                  ;
                </div>{" "}
              </li>
            </ul>
          </p>
          <h3 className="text-lg md:text-xl lg:text-1xl lg:font-semibold text-[#444444] sub_heading">
            2.3. Cas d’exclusion des utilisateurs des services de Primecar
          </h3>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Se verront refuser l’accès aux services les utilisateurs :
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <ul>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Ne respectant pas une condition essentielle des CGU et/ou à
                  plusieurs reprises et/ou d’une manière impactant l’exécution
                  normale du Service ;
                </div>
              </li>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Communiquant des informations fausses sur leur Compte. Dans ce
                  cas, DINSS pourrait immédiatement, sans préavis ni indemnité,
                  suspendre et/ou résilier l’accès à la plateforme.
                  L’utilisateur s’engage à fournir tous les justificatifs
                  nécessaires et s'engage à mettre régulièrement à jour ses
                  informations ;
                </div>
              </li>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Ayant un comportement inadéquat ou irrespectueux vis-à-vis
                  d’autres utilisateurs ou du personnel de{" "}
                  <span className="text-base font-semibold text-[#444444]">
                    DINSS
                  </span>{" "}
                  ;
                </div>
              </li>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Ayant un comportement délictueux sur la plateforme ou dans
                  leur usage des Services ;
                </div>
              </li>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Contournant le site (c'est-à-dire qui tentent d'effectuer une
                  location auprès du propriétaire ou du locataire sans passer
                  par la plateforme) ;
                </div>
              </li>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Étant débiteurs de{" "}
                  <span className="text-base font-semibold text-[#444444]">
                    DINSS
                  </span>
                  .
                </div>
              </li>
            </ul>
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            se réserve le droit d’accepter ou de refuser, sans motif et à sa
            seule discrétion, toute inscription sur sa plateforme et de
            suspendre et/ou supprimer le Compte d’un utilisateur qui ne
            respecterait pas les présentes CGU.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            se réserve le droit de refuser, de retirer, ou de restreindre
            l'accès à un propriétaire si ce dernier annule les demandes de
            location de façon excessive.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Enfin,{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            se réserve le droit d’exclure de la plateforme un locataire qui
            serait responsable de dommages à un véhicule. Tout manquement à ces
            obligations peut emmener le non-paiement des sommes à venir prévues
            au contrat de Location, au titre de dédommagement.
          </p>
          <h3 className="text-lg md:text-xl lg:text-1xl lg:font-semibold text-[#444444] sub_heading">
            2.4. Conditions d’accès à la plateforme
          </h3>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <span className="text-base font-semibold text-[#444444]">
              Primecar
            </span>{" "}
            est accessible 24h/24 et 7j/7. L’accès ou l’inscription à la
            Plateforme est gratuit.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Cependant,{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            se réserve le droit, sans préavis, ni indemnité, de fermer
            temporairement ou définitivement la plateforme ou l’accès aux
            services à distance notamment pour effectuer une mise à jour, des
            opérations de maintenance, des modifications ou changements sur les
            méthodes opérationnelles, les serveurs et les heures
            d’accessibilité, et en cas d’anomalie, de tout mettre en œuvre afin
            de rendre la plateforme accessible et opérationnelle. L’utilisateur
            sera informé par les moyens disponibles et dans les meilleurs
            délais.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            ne sera pas responsable de la non-réalisation d’une location et des
            dommages de toute nature qui peuvent résulter de ces opérations à
            mesure que les utilisateurs auraient été informés dans les meilleurs
            délais avant tout changement et/ou indisponibilité temporaire ou
            encore de la fermeture définitive de tout ou partie de la plateforme
            ou des services qui y sont associés.
          </p>{" "}
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            se réserve le droit de compléter ou de modifier, à tout moment, la
            plateforme et les services qui y sont disponibles en fonction de
            l’évolution de la technologie et des nouvelles réglementations
            législatives.
          </p>{" "}
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Il appartient par ailleurs au visiteur ou à l’utilisateur de
            s’assurer que son matériel électronique est en bon état de
            fonctionnement et à jour afin de bénéficier des fonctionnalités de
            la plateforme, et de faire face à leurs évolutions.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            En cas d’interruption ou d’impossibilité d’utiliser la plateforme,
            l’utilisateur peut s’adresser au service client de{" "}
            <span className="text-base font-semibold text-[#444444]">
              Primecar
            </span>{" "}
            dont les coordonnées se trouvent dans la section Centre d’Aide de sa
            plateforme.
          </p>
          <h2 className="text-lg md:text-xl lg:text-2xl lg:font-semibold text-[#444444] heading">
            3. Documents contractuels{" "}
          </h2>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Les documents contractuels ci-après s’imposent aux utilisateurs :
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <ul>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Les présentes CGU (incluant notre Politique de
                  Confidentialité) ;
                </div>{" "}
              </li>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Le modèle du contrat de location et ses annexes proposé par{" "}
                  <span className="text-base font-semibold text-[#444444]">
                    DINSS
                  </span>
                  .
                </div>{" "}
              </li>
            </ul>
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            En cas de contradiction entre des documents de nature différentes ou
            de rang différent, les dispositions contenues dans le document de
            rang supérieur prévaudront pour les obligations se trouvant en
            conflit d’interprétation. En cas de contradiction entre les termes
            des documents de même ordre, les derniers documents contractuels en
            date prévaudront sur les autres.{" "}
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Les présentes CGU et le contrat de location forment un tout
            indivisible.{" "}
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Dès lors que les documents contractuels ont été acceptés et/ou
            signés par l’utilisateur, ils lui sont opposables.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Les documents contractuels figurant en ligne sur les plateformes et
            acceptés lors de la location par l’utilisateur prévalent sur toutes
            les versions papier ou électronique de date antérieure.
          </p>
          <h2 className="text-lg md:text-xl lg:text-2xl lg:font-semibold text-[#444444] heading">
            4. Modification des CGU
          </h2>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            se réserve le droit, à sa seule discrétion, à tout moment et sans
            préavis, d'adapter ou de modifier les présentes CGU et contrat de
            location, les conditions financières du service ou tout autre
            document afférent à la plateforme. Les modifications des présentes
            CGU seront notifiées aux utilisateurs par le biais d’une fenêtre «
            pop in ».
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            La nouvelle version des CGU sera publiée sur le site internet et via
            l’Application.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            notifiera l’utilisateur de toute modification des présentes CGU au
            plus tôt 10 (dix) jours ouvrés avant leur entrée en vigueur.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            L’utilisateur pourra émettre des observations à la nouvelle version
            des CGU dans les 20 (vingt) jours suivant la réception de la
            notification des modifications des CGU. Passé ce délai, les
            nouvelles CGU seront réputées avoir été acceptées par l’Utilisateur.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Lorsqu’un utilisateur n’accepte pas les nouvelles CGU, il devra
            immédiatement arrêter d’utiliser le site, l’application et les
            services fournis par{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>
            .
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            En tout état de cause, à défaut d’observations, les nouvelles CGU
            seront réputées acceptées par l’utilisateur qui, au moment de la
            prochaine location, acceptera expressément les CGU par le biais
            d’une case à cocher présente sur le contrat de location conclu entre
            le propriétaire et le locataire.
          </p>
          <h2 className="text-lg md:text-xl lg:text-2xl lg:font-semibold text-[#444444] heading">
            5. Détails sur la location{" "}
          </h2>
          <h3 className="text-lg md:text-xl lg:text-1xl lg:font-semibold text-[#444444] sub_heading">
            5.1. Conditions de la location{" "}
          </h3>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Il est précisé que la location envisagée n’est possible que pour
            faire des déplacements privés et professionnels. De même, la
            location de véhicules commerciales (utilitaires et engins lourds)
            est possible. Etant entendu que ces activités se font hors
            sous-location ou en prêt à titre onéreux ou gratuit.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Il est entendu que le locataire et le propriétaire sont des
            personnes différentes. Ainsi, un propriétaire ne peut se louer à
            soi-même son véhicule, ni à tout autre utilisateur ayant un lien de
            parenté ou faisant partie du même foyer.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Toute violation de ces dispositions pourra entraîner l’annulation de
            la location et la fermeture du compte des utilisateurs concernés de
            la plateforme.
          </p>
          <h4 className="text-lg md:text-xl lg:text-xl lg:font-semibold text-[#444444] inner_sub_heading">
            5.1.1. Les types de location
          </h4>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            DINSS met à disposition{" "}
            <span className="text-base font-semibold text-[#444444]">
              Primecar
            </span>{" "}
            au travers de laquelle la location de véhicules à des fins privées
            ou professionnels est possible. Plusieurs catégories de véhicule
            peuvent servir à la location sur la plateforme{" "}
            <span className="text-base font-semibold text-[#444444]">
              Primecar
            </span>
            .
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <ul>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Les véhicules de type personnel : Berline, SUV, Familiale,
                  etc.{" "}
                </div>
              </li>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Les utilitaires : Les fourgons, camion, poids lourds, Bus,
                  etc.{" "}
                </div>
              </li>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Les machines lourdes pour les grands travaux : Pelles,
                  Chargeuses, tracteurs, etc.{" "}
                </div>
              </li>
            </ul>
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Les utilisateurs peuvent proposer ces véhicules ou en faire la
            demande pour la location.
          </p>
          <h3 className="text-lg md:text-xl lg:text-1xl lg:font-semibold text-[#444444] sub_heading">
            5.2. Coût du Service{" "}
          </h3>
          <h4 className="text-lg md:text-xl lg:text-xl lg:font-semibold text-[#444444] inner_sub_heading">
            5.2.1. Le coût total du service payé par le locataire{" "}
          </h4>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Il comprend les éléments suivants :
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <ul>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  <span className="text-base font-semibold text-[#444444]">
                    Le coût de la location déterminé par le propriétaire{" "}
                  </span>
                </div>{" "}
              </li>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Les{" "}
                  <span className="text-base font-semibold text-[#444444]">
                    frais de service{" "}
                  </span>
                </div>{" "}
              </li>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  {" "}
                  <span className="text-base font-semibold text-[#444444]">
                    La réduction
                  </span>
                </div>{" "}
              </li>
            </ul>
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Dans le cas d’une location sans chauffeur, une fois la location
            terminée, des frais post- location peuvent être facturés au
            locataire.{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            peut se charger de les collecter et de reverser au propriétaire.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            collecte le coût total du service au moment où la location est
            approuvée et payée par le locataire. À ce titre, le propriétaire
            annonce donner expressément à{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            un mandat d’encaissement du prix de la location (et mêmement du
            montant de l’autorisation de prélèvement, des frais complémentaires
            et autres pénalités) pour le compte du propriétaire.
          </p>
          <h4 className="text-lg md:text-xl lg:text-xl lg:font-semibold text-[#444444] inner_sub_heading">
            5.2.2. Les gains du propriétaire
          </h4>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Les gains du propriétaire sont fonction du coût de location, soit du
            prix (x) jours qui ont été déterminés par lui. Les gains du
            propriétaire représentent 85% du coût de la location quelque soit le
            tarif journalier proposé pour le véhicule. Ainsi, le solde (soit 15%
            du coût de la location) représente :
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <li className="list-inside list-disc">
              Les frais de services de la plateforme{" "}
            </li>
            <li className="list-inside list-disc">
              La commission d’intermédiation due par le propriétaire à{" "}
              <span className="text-base font-semibold text-[#444444]">
                DINSS
              </span>
            </li>
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Une fois la location terminée,{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            reverse ses revenus au propriétaire les mardi ou jeudi de la semaine
            qui suivent la fin de la location. Ce versement peut toutefois faire
            l’objet de retard pour des raisons tenant à des contraintes
            bancaires et/ou à des cas de force majeure en vertu de la loi en
            vigueur.{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            peut en outre suspendre le versement au propriétaire en cas de
            vérifications administratives ou juridiques.{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            travaillera à réduire les réclamations et à tenir informé les
            utilisateurs sur les éventuels délais de traitement.
          </p>
          <h3 className="text-lg md:text-xl lg:text-xl lg:font-semibold text-[#444444] sub_heading">
            5.3. Le fonctionnement du service de location{" "}
          </h3>
          <h4 className="text-lg md:text-xl lg:text-xl lg:font-semibold text-[#444444] inner_sub_heading">
            5.3.1. Le propriétaire dépose une annonce
          </h4>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Le propriétaire dépose une (ou plusieurs) annonce(s) de location de
            son (ses) véhicule(s) sur la plateforme, en précisant le lieu de la
            location et le prix de la location souhaité. Une annonce de location
            de véhicule correspond à un seul véhicule. En d’autres termes, le
            propriétaire n’est pas autorisé à publier plusieurs véhicules pour
            la même annonce. Ainsi, chaque véhicule destiné à la location a
            droit à une et une seule annonce par un seul utilisateur.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <span className="text-base font-semibold text-[#444444]">
              Dans le cas où le propriétaire agirait en tant que professionnel,
            </span>{" "}
            il est tenu d’informer les utilisateurs, et de communiquer les
            données relatives à son identité professionnelle (N° Registre de
            commerce, coordonnées postales) et à indiquer dans son annonce les
            horaires d’ouverture et de fermeture de son agence afin que le
            locataire soit informé, à défaut le propriétaire devra rembourser au
            locataire le montant de la location.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Le propriétaire, qui modifie le véhicule d’une réservation initiale
            par un autre véhicule, s’engage à le remplacer par un de ses
            véhicules disponibles de même catégorie afin de ne pas annuler la
            réservation faite par le locataire. En tout état de cause,{" "}
            <span className="text-base font-semibold text-[#444444]">
              le coût initial de la location correspondant au véhicule initial
              demeure inchangé
            </span>
            .
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Le Locataire a toujours s’il le souhaite la possibilité d’annuler sa
            réservation, après le changement de véhicule par le propriétaire,
            conformément au respect des conditions d’annulation mentionnées dans
            le présent CGU.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Le propriétaire remplit via son compte, sur la plateforme, une fiche
            Produit par véhicule qu’il propose à la location et comprenant les
            caractéristiques du véhicule. Il s’engage à signaler les détails
            essentiels de son véhicule (par exemple, le volume, la hauteur, la
            longueur ou la charge utile lorsque celui-ci est un utilitaire, …)
            et à ce que le contrôle technique soit à jour.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Le propriétaire s’engage à ne pas faire apparaître ses coordonnées
            personnelles ou information de contact en dehors des champs prévus à
            cet effet dans son espace membre sur la plateforme. Il est donc
            strictement interdit de faire apparaître un numéro de téléphone ou
            une adresse email en surimpression sur la photographie du véhicule,
            ou en indiquant un numéro de téléphone en toutes lettres dans
            l’annonce. A défaut,{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            se réserve le droit d’annuler la demande de réservation, de
            supprimer ou modérer l’annonce ou l’espace membre du propriétaire et
            de demander des dommages et intérêts au propriétaire malveillant.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            L’annonce est rendue publique sur la plateforme dans un délai de 48
            (quarante-huit) heures après un contrôle et une approbation de la
            part des administrateurs de{" "}
            <span className="text-base font-semibold text-[#444444]">
              Primecar
            </span>
            .
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            L’utilisateur reconnaît que{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            n’intervient nullement sur le contenu des annonces et que la
            responsabilité de cette dernière ne pourra pas être retenue à ce
            titre. Le propriétaire garantit à{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            qu’il ne viole aucune convention conclue avec des tiers.
          </p>
          <h4 className="text-lg md:text-xl lg:text-xl lg:font-semibold text-[#444444] inner_sub_heading">
            5.3.2. Le choix d’un véhicule par le locataire{" "}
          </h4>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Le visiteur voulant louer un véhicule procède à une recherche
            portant sur le lieu, la période de location et la région du
            déplacement souhaités.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Ce dernier prend connaissance des détails sur le véhicule qui
            précisent notamment la marque et l’année du véhicule, le Prix par
            jour, une description du véhicule, les conditions d’utilisation et
            les évaluations du véhicule et/ou du propriétaire par les précédents
            locataires le cas échéant.
          </p>
          <h4 className="text-lg md:text-xl lg:text-xl lg:font-semibold text-[#444444] inner_sub_heading">
            5.3.3. Demande de location introduite par le locataire{" "}
          </h4>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Pour effectuer une demande de location, le locataire doit se
            connecter à son compte sur la plateforme{" "}
            <span className="text-base font-semibold text-[#444444]">
              Primecar
            </span>{" "}
            pour réserver le véhicule de son choix. Il précise la période de
            location souhaitée et introduit, par conséquent, ses informations
            personnelles, sa pièce d’identité (cas d’une demande de location
            avec chauffeur) ou son permis de conduire (cas d’une demande de
            location sans chauffeur. Plusieurs demandes de location peuvent être
            effectuées par le locataire.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Le locataire sera tenu de faire vérifier ses informations d’identité
            par le propriétaire, au lieu de remise du véhicule, lorsque sa
            demande de location est acceptée et payée.
          </p>
          <h4 className="text-lg md:text-xl lg:text-xl lg:font-semibold text-[#444444] inner_sub_heading">
            5.3.4. Acceptation par le propriétaire de la demande de location{" "}
          </h4>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Selon les paramètres définis, le Propriétaire reçoit un SMS, une
            notification et/ou un courrier électronique lui notifiant la demande
            de location émise par le locataire.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Il appartiendra au propriétaire de valider la demande de location
            comme suit, à défaut la demande de Location expirera :
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <ul>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Pour les demandes entre 6 (six) heures du matin et 18
                  (dix-huit) heures, le propriétaire disposera d’un délai de 6
                  (six) heures pour apporter une réponse.{" "}
                </div>
              </li>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Pour les demandes en soirée, à savoir entre 18 (dix-huit)
                  heures et 6 (six) heures du matin le propriétaire pourra
                  apporter une réponse avant 9 (neuf) heures le lendemain.
                </div>
              </li>
            </ul>
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Pour les demandes de location formulées à la dernière minute, le
            propriétaire devra répondre avant le début de la location.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Le locataire est informé que{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            n’est aucunement tenue pour responsable des carences du propriétaire
            et notamment, mais non exclusivement :
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <ul>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  En cas d’absence de validation de la demande de location ;{" "}
                </div>
              </li>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  En cas de non-respect du délai pour valider la demande de
                  location ;{" "}
                </div>
              </li>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  En cas d’échec de la demande de location ;{" "}
                </div>
              </li>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  En cas d’absence de réponse aux messages du locataire.{" "}
                </div>
              </li>
            </ul>
          </p>
          <h4 className="text-lg md:text-xl lg:text-xl lg:font-semibold text-[#444444] inner_sub_heading">
            5.3.5. La réservation instantanée
          </h4>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Le propriétaire a la possibilité de spécifier dans son annonce que
            le véhicule mis en location bénéficie d’un système de réservation
            instantanée. Cette possibilité permet aux locataires d’obtenir
            l’acceptation immédiate de leur demande de location d’un véhicule
            sans l’approbation préalable du propriétaire.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Le propriétaire est tenu de mettre le véhicule à la disposition du
            locataire. Il est par conséquent responsable des obligations prévues
            dans les CGU.
          </p>
          <h4 className="text-lg md:text-xl lg:text-xl lg:font-semibold text-[#444444] inner_sub_heading">
            5.3.6. Paiement en ligne de la réservation{" "}
          </h4>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Le futur locataire a 24 (vingt-quatre) heures pour procéder au
            paiement du coût total du service auquel cas se demande sera
            annulée. Le propriétaire et le locataire recevront une notification
            dans ce sens.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Le paiement est exclusivement effectué en ligne sur la plateforme de{" "}
            <span className="text-base font-semibold text-[#444444]">
              Primecar :
            </span>
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <ul>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Au moyen{" "}
                  <span className="text-base font-semibold text-[#444444]">
                    d’une carte de paiement
                  </span>{" "}
                  valide aux nom et prénom du locataire. La plateforme propose
                  une interface hautement sécurisée pour la saisie des données
                  de paiement : numéro de carte, date d'expiration et le
                  cryptogramme visuel.
                  <br />
                  <span className="text-base font-semibold text-[#444444]">
                    ATTENTION
                  </span>{" "}
                  : Il doit être noté qu’aucun contrôle ne sera réalisé par{" "}
                  <span className="text-base font-semibold text-[#444444]">
                    DINSS
                  </span>{" "}
                  pour vérifier la correspondance :{" "}
                  <span className="text-base font-semibold text-[#444444]">
                    les coordonnées bancaires communiquées seront réputées être
                    celles du titulaire du Compte.
                  </span>{" "}
                  Le Locataire sera responsable des informations saisies sur son
                  compte et du prélèvement sur ce moyen de paiement de toutes
                  les sommes associées.
                </div>
              </li>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Par le bais d’options de paiement mobile money réputée être la
                  plus utilisée sur l’échiquier ivoirien. Le locataire devra
                  renseigner le numéro de téléphone qui sera utiliser pour
                  l’opération de prélèvement dont les procédures sont
                  exclusivement propres aux fournisseurs de passerelles de
                  paiement.
                </div>
              </li>
            </ul>
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Dès qu’une demande de location est acceptée par le propriétaire et
            payée par le locataire, elle devient{" "}
            <span className="text-base font-semibold text-[#444444]">
              une réservation. Primecar{" "}
            </span>
            fournit au locataire les coordonnées complètes du propriétaire afin
            qu’il puisse obtenir de plus amples informations auprès de lui. Le
            locataire reçoit un courrier électronique accusant réception de
            l’émission de sa demande de location en ligne et récapitulant les
            détails concernant la location du véhicule.
          </p>{" "}
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            procède cependant à l’encaissement sur un compte du prix de la
            location versée par le locataire qui vaut accord par celui-ci de
            l’offre.
          </p>{" "}
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Tout paiement entre le propriétaire et le Locataire doit être
            réalisé par l’intermédiaire de{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            sur le compte ouvert à cet effet auprès de l’institution bancaire.
          </p>{" "}
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            procède à cet effet au versement, des sommes perçues du
            propriétaire, sur le compte bancaire ou mobile money fourni par
            celui-ci.
          </p>
          <h4 className="text-lg md:text-xl lg:text-xl lg:font-semibold text-[#444444] inner_sub_heading">
            5.3.7. Echanges sur la plateforme
          </h4>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Une fois le véhicule réservé sur la plateforme, le locataire et le
            propriétaire peuvent échanger directement à travers la boite de
            dialogue (messages et appels) mis à leur disposition dans le menu «
            Location » afférent. Celle-ci est disponible pendant toute la durée
            de la location.
          </p>
          <h4 className="text-lg md:text-xl lg:text-xl lg:font-semibold text-[#444444] inner_sub_heading">
            5.3.8. Annulation de la réservation{" "}
          </h4>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Le locataire et le propriétaire peuvent annuler à tout moment une
            réservation réalisée sur le site. Toutefois, cette opération est
            régie sous des conditions bien spécifiques. Le locataire et le
            propriétaire sont invités à s’informer par écrit l’un l’autre d’une
            annulation le plus tôt possible au travers de la Plateforme{" "}
            <span className="text-base font-semibold text-[#444444]">
              Primecar
            </span>
            .
          </p>
          <h5 className="text-lg md:text-xl lg:text-xl lg:font-semibold text-[#444444] inner_sub_heading">
            5.3.8.1. Annulation de la réservation par le locataire
          </h5>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Le locataire peut initier une demande d’annulation de la réservation
            de manière gratuite jusqu’à 48h avant le début de l’opération et
            dans les cas ci-après :
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <ul>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Une annulation plus de quarante-huit (48h) heures avant le
                  début de la location donne lieu au remboursement intégral du
                  prix total de la location sans application d’une éventuelle
                  pénalité ou d’une retenue. Le remboursement se fera sous trois
                  (3) jours ouvrés à partir de la date où elle aura été initiée.
                </div>
              </li>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Une annulation moins de quarante-huit (48h) heures avant le
                  début de la location fait valoir un remboursement partiel du
                  prix total de la location après avoir retenue le coût de la
                  première journée de la location et des frais de service. Le
                  propriétaire se verra rétribué 85% du montant de la première
                  journée de location en guise de dédommagement.
                </div>
              </li>
            </ul>
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <span className="text-base font-semibold text-[#444444]">
              En cas de désaccord, le propriétaire et le locataire sont invités
              à trouver une solution amiable. DINSS
            </span>{" "}
            ne pourra les assister dans la résolution de leur litige que dans
            l’hypothèse où ces derniers ont fait part à{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            de leur accord sur une solution amiable (preuve d’un accord écrit
            entre le Propriétaire et le Locataire par sms, par email, par
            lettre).{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            ne peut agir sur demande unilatérale (contestation unilatérale) de
            l’un des utilisateurs.
          </p>
          <h5 className="text-lg md:text-xl lg:text-xl lg:font-semibold text-[#444444] inner_sub_heading">
            5.3.8.2. Annulation de la réservation par le propriétaire
          </h5>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Le propriétaire souhaitant annuler une réservation à moins de
            quarante-huit (48h) heures avant le début de la location pourra le
            faire. Autrement, le véhicule du propriétaire concerné par la
            location sera modéré par{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>
            , c’est-à-dire qu’il ne sera plus accessible pour une durée de 15
            jours. Le véhicule du propriétaire sera de nouveau disponible sur la
            plateforme le 16ème jour. <br />
            Le propriétaire est autorisé à contester cette décision dans un
            délais de sept (7) jours à partir de la date d’annulation, en
            fournissant les justificatifs qu’il juge pertinent auprès de nos
            équipes d’assistance clients.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Le justificatif fourni par le propriétaire devra permettre
            d'attester que le véhicule est immobilisé à la date concernée et ne
            peut donc pas circuler (facture de réparation acquittée, faisant
            figurer la date de réparation/d'immobilisation du véhicule).
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <div className="table-custom-styling">
              <div className="" style={{ display: "flex" }}>
                <div
                  className="col-6 font-semibold table-col-color"
                  style={{
                    width: "50%",
                    paddingBottom: "15px",
                    paddingLeft: "7px",
                    border: "solid 1px white",
                  }}
                >
                  Si l’annulation a lieu :
                </div>
                <div
                  className="col-6 font-semibold table-col-color"
                  style={{
                    width: "50%",
                    paddingBottom: "15px",
                    paddingLeft: "7px",
                    border: "solid 1px white",
                  }}
                >
                  Le propriétaire devra s’acquitter d’une pénalité (qui sera
                  déduite par compensation sur le versement à venir de ses
                  futurs revenus de location) de :
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div
                  className="col-6 font-semibold table-col-color"
                  style={{
                    width: "50%",
                    paddingBottom: "15px",
                    paddingLeft: "7px",
                    border: "solid 1px white",
                  }}
                >
                  Jusqu’à trente-six (36h) heures avant le début de la Location
                </div>
                <div
                  className="col-6 font-semibold table-col-color"
                  style={{
                    width: "50%",
                    paddingBottom: "15px",
                    paddingLeft: "7px",
                    border: "solid 1px white",
                    color: "#444444",
                    backgroundColor: "#f7caac",
                  }}
                >
                  4000 FCFA TTC
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div
                  className="col-6 font-semibold table-col-color"
                  style={{
                    width: "50%",
                    paddingBottom: "15px",
                    paddingLeft: "7px",
                    border: "solid 1px white",
                  }}
                >
                  Entre trente-six (36h) heures et six (6h) heures avant le
                  début de la location
                </div>
                <div
                  className="col-6 font-semibold table-col-color"
                  style={{
                    width: "50%",
                    paddingBottom: "15px",
                    paddingLeft: "7px",
                    border: "solid 1px white",
                    color: "#444444",
                    backgroundColor: "#fbe4d5",
                  }}
                >
                  6000 FCFA TTC
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div
                  className="col-6 font-semibold table-col-color"
                  style={{
                    width: "50%",
                    paddingBottom: "15px",
                    paddingLeft: "7px",
                    border: "solid 1px white",
                  }}
                >
                  Les six (6h) dernières heures avant le début de la location
                </div>

                <div
                  className="col-6 font-semibold table-col-color"
                  style={{
                    width: "50%",
                    paddingBottom: "15px",
                    paddingLeft: "7px",
                    border: "solid 1px white",
                    color: "#444444",
                    backgroundColor: "#f7caac",
                  }}
                >
                  10000 FCFA TTC
                </div>
              </div>
            </div>
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            remboursera au locataire le coût total du service.
          </p>
          <h5 className="text-lg md:text-xl lg:text-xl lg:font-semibold text-[#444444] inner_sub_heading">
            5.3.8.3. Annulation de la réservation ou de la location par DINSS{" "}
          </h5>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <span className="text-base font-semibold text-[#444444]">
              Cas d’annulation de la location par DINSS sur responsabilité
              avérée du locataire, annulation qui a lieu après la date de début
              de la location initiale :
            </span>
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Le locataire ne sera pas remboursé du prix total du service. Le
            propriétaire perçoit son dû après avoir déduit les frais ou
            commissions destinés à DINSS suivant la méthode de calcul annoncée
            dans la section « Coût du services »
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <span className="text-base font-semibold text-[#444444]">
              Cas d’annulation de la location par DINSS sur responsabilité
              avérée du propriétaire, annulation qui a lieu après la date de
              début de location initiale :
            </span>
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            En cas de toute violation par le propriétaire de l’une quelconque
            des dispositions des CGU ou contrat de location, le propriétaire se
            verra appliquer une pénalité de{" "}
            <span className="text-base font-semibold text-[#444444]">
              20 000 FCFA
            </span>
            , laquelle sera déduite du versement de ses prochains revenus.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Le Locataire est remboursé du coût total du service.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            En cas de récidive sous la responsabilité avérée du propriétaire, et
            sans justification avérée et valable,{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            supprimera le compte, et annulera les demandes de location et de
            réservations à venir, à compter de la date de la constatation des
            faits dans les conditions de l’article « Résiliation » des
            présentes.
          </p>
          <h4 className="text-lg md:text-xl lg:text-xl lg:font-semibold text-[#444444] inner_sub_heading">
            5.3.9. Mise à disposition du véhicule{" "}
          </h4>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Après avoir accepté la mise en relation à travers la plateforme
            <span className="text-base font-semibold text-[#444444]">
              Primecar
            </span>
            , les utilisateurs procèdent au jour convenu de début de la location
            à la mise à disposition du véhicule et aux vérifications
            nécessaires.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Au jour de la mise à disposition du véhicule, le locataire et le
            propriétaire vérifient et signent ensemble le contrat de location.
            Les obligations du contrat de location, ainsi que celles des
            utilisateurs, c’est-à-dire, le locataire et le propriétaire, sont
            exposées dans la présente CGU.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            En tant qu’entité d’intermédiation,{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            n’est pas partie au contrat de location qui régit le locataire et le
            propriétaire.
          </p>
          <h4 className="text-lg md:text-xl lg:text-xl lg:font-semibold text-[#444444] inner_sub_heading">
            5.3.10. Réclamation{" "}
          </h4>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Bien que le propriétaire ait déclaré que le véhicule loué est en bon
            état de marche, le locataire à la possibilité, pendant les neuf (9)
            premiers kilomètres à compter du début de la location, de s’assurer
            de l’état effectif du véhicule. Tout dépassement de cette distance
            vaudra pleinement acceptation par le locataire de l’état du véhicule
            dès lors considéré comme exempt de défaut apparent de
            fonctionnement.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Par contre, si le locataire estime que l’état du véhicule{" "}
            <span className="text-base font-semibold text-[#444444]">
              n’est pas conforme
            </span>{" "}
            à l’annonce au bout des neuf (9) premiers kilomètres, il lui
            appartiendra{" "}
            <span className="text-base font-semibold text-[#444444]">
              dans les vingt-quatre (24h) heures à compter du début de la
              location
            </span>
            , de produire des éléments suffisamment avérés attestant de la
            non-conformité de l’annonce (photos, diagnostic précis établi par un
            garage…), en contactant l’équipe en charge de l’assistance clients
            dont les coordonnées sont disponibles sur la plateforme (le site
            internet et l’application).
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Dans le cas d’un dysfonctionnement du véhicule survenu au cours de
            la location (voyant sur le tableau de bord du véhicule) et qui
            semble présenter un état alarmant, lequel nécessite l’arrêt du
            véhicule (alors qu’il peut toujours se déplacer), le locataire devra
            informer le propriétaire et{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            dans les vingt-quatre (24h) heure à compter de sa survenance et
            apporter la preuve de l’état de dangerosité. Le Locataire devra
            cesser d’utiliser le véhicule et impliquer le propriétaire en sorte
            qu’il fasse appel à une compagnie d’assistance.
          </p>
          <h4 className="text-lg md:text-xl lg:text-xl lg:font-semibold text-[#444444] inner_sub_heading">
            5.3.11. Prolongation
          </h4>
          <h4 className="text-lg md:text-xl lg:text-xl lg:font-bold text-[#444444] inner_sub_heading_simple">
            Pour une réservation normale :
          </h4>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Le locataire a la possibilité de prolonger la location lorsqu’elle a
            déjà débuté après avoir obtenu l’accord préalable du propriétaire.
            Ainsi, la demande de prolongation se fera depuis l’application sur
            le menu « Locations » spécifiquement dans la section de location du
            véhicule concerné.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            La procédure d’itération sera la même que celle d’une demande
            normale sur la plateforme :
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <ul>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Le locataire initie une prolongation de location
                </div>{" "}
              </li>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Le propriétaire approuve, ce dernier est alerté par le biais
                  des moyens de notification.
                </div>{" "}
              </li>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Le locataire procède au paiement
                </div>{" "}
              </li>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  La période de location du véhicule est immédiatement
                  prolongée.{" "}
                </div>{" "}
              </li>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Les contrats de location mis à jour sont envoyés par mail aux
                  deux parties.{" "}
                </div>{" "}
              </li>
            </ul>
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Dans le cas où le propriétaire n’accepte pas la demande de
            prolongation faite en ligne par le locataire avant la fin du contrat
            de location initial, le véhicule est remis au propriétaire dès
            l’expiration du contrat de location en cours.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Toute prolongation de location ne respectant pas cette procédure ne
            serait pas considérée comme valable par{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            et en conséquence, le locataire et le propriétaire assumeront seuls
            la pleine responsabilité de cette opération.
          </p>
          <h4 className="text-lg md:text-xl lg:text-xl lg:font-bold text-[#444444] inner_sub_heading_simple">
            Pour une réservation instantanée :{" "}
          </h4>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Le locataire a la possibilité de prolonger la location lorsqu’elle a
            déjà débuté après avoir obtenu l’accord préalable du propriétaire.
            Ainsi, la demande de prolongation se fera depuis l’application sur
            le menu « Locations » spécifiquement dans la section de location du
            véhicule concerné.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            La procédure d’itération sera la même que celle d’une demande de
            réservation instantanée.
          </p>
          <h4 className="text-lg md:text-xl lg:text-xl text-[#444444] inner_sub_heading">
            5.3.12. Véhicule non restitué suite à une prolongation{" "}
          </h4>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Cette mention s’applique exclusivement dans le cas d’une location
            sans chauffeur. Dans le cadre d’une prolongation, les utilisateurs
            concernés c’est-à-dire le locataire et le propriétaire conviennent
            de proroger le contrat de location existant conformément aux
            présentes CGU.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Au cas où le locataire ne restitue pas le véhicule eu égard le
            contrat de location, cet acte sera considéré comme un acte de vol et
            pourra engager sa responsabilité devant les tribunaux compétents.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Dans le cas d’une location avec chauffeur, le locataire ne pourra
            être rendu responsable de cet acte, sauf cas échéant, seul le
            chauffeur devra rendre compte à son responsable (le propriétaire).
          </p>
          <h4 className="text-lg md:text-xl lg:text-xl  text-[#444444] inner_sub_heading">
            5.3.13. Á la fin de la location du véhicule{" "}
          </h4>
          <h4 className="text-lg md:text-xl lg:text-xl lg:font-bold text-[#444444] inner_sub_heading_simple">
            Pour une location sans chauffeur :{" "}
          </h4>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Le Locataire et le propriétaire vérifient l’état du véhicule
            notamment au niveau du carburant et de la propreté, ils s’assurent
            que le véhicule n’est pas sujet à dommages qui pourraient être
            survenus à tous les niveaux : par exemple, au niveau de la
            carrosserie, à l’intérieur du véhicule, etc.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            En somme, l’état des lieux du véhicule est effectué et un compte
            rendu de fin de location est exécuté conformément au contrat de
            location. Des pénalités pourront s’appliquer contre locataire en cas
            de dommages encourus.
            <br />
            Dans le cas où le locataire aurait mis un trop plein de carburant,
            ce dernier ne pourra revendiquer une quelconque compensation auprès
            du propriétaire, sauf avis contraire de celui-ci.
          </p>
          <h4 className="text-lg md:text-xl lg:text-xl lg:font-bold text-[#444444] inner_sub_heading_simple">
            Pour une location avec chauffeur :{" "}
          </h4>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Le chauffeur est le mandant du propriétaire, il est le seul
            responsable de l’état du véhicule, pendant et à la fin de la
            location, à mesure qu’il est celui qui a été en charge de la
            conduite du véhicule. Le locataire ne pourrait être tenu responsable
            d’éventuels dommages observés sur le véhicule.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Dans le cas où le chauffeur a passé le véhicule à une tierce
            personne au cours de la période de location,{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            ne reconnaitra pas cette dernière comme faisant partie de l’équation
            et en attribuera la responsabilité d’éventuels dommages à ce
            dernier, et par ricochet, au propriétaire.
          </p>
          <h4 className="text-lg md:text-xl lg:text-xl lg:font-semibold text-[#444444] inner_sub_heading">
            5.3.14. Après la fin de la location{" "}
          </h4>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Le locataire pourrait laisser un avis sur le véhicule de location
            ayant fait l’objet de la location, et sur la prestation du
            propriétaire. Ceci permettra aux autres utilisateurs de se faire une
            idée de leur expérience avec le véhicule et son propriétaire.
          </p>
          <h4 className="text-lg md:text-xl lg:text-xl lg:font-semibold text-[#444444] inner_sub_heading">
            5.3.15. Contraventions pendant une location
          </h4>
          <h4 className="text-lg md:text-xl lg:text-xl lg:font-bold text-[#444444] inner_sub_heading_simple">
            Pour une location sans chauffeur :{" "}
          </h4>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Le locataire étant sujet d’une contravention est entièrement
            responsable des frais encourus et est tenu d’informer, en
            conséquence, le propriétaire et DINSS. En conséquence, il lui a
            appartient de s’en acquitter.
          </p>
          <h4 className="text-lg md:text-xl lg:text-xl lg:font-bold text-[#444444] inner_sub_heading_simple">
            Pour une location avec chauffeur :{" "}
          </h4>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Dans le cadre d’une location avec chauffeur, le propriétaire est le
            responsable de toutes contraventions encourus. L’interaction entre
            le chauffeur et le propriétaire est laissée à leur discrétion
          </p>
          <h4 className="text-lg md:text-xl lg:text-xl  text-[#444444] inner_sub_heading">
            5.3.16. Le locataire abandonne le véhicule
          </h4>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Cette mention s’applique exclusivement dans le cas d’une location
            sans chauffeur. L’abandon du véhicule est caractérisé par la
            non-remise des clés et l’absence d’état des lieux après la location.
            Dans ce cas-ci, considérons la mise en fourrière du véhicule par les
            autorités compétentes, et non assumée par le locataire, ou encore,
            le véhicule laissé au garage sans que le propriétaire ait été
            informé conformément au contrat de location.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Il sera exigé au locataire de rembourser l’intégralité des frais
            encourus incluant les déplacements engagés par le propriétaire sauf
            accord amiable. En foi de quoi, le locataire engage sa
            responsabilité devant les tribunaux compétents.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Dans le cas d’une location avec chauffeur, le locataire ne pourra
            être rendu responsable de cet acte, sauf cas échéant, seul le
            chauffeur devra rendre compte à son responsable (le propriétaire).
          </p>
          <h4 className="text-lg md:text-xl lg:text-xl text-[#444444] inner_sub_heading">
            5.3.17. Véhicule non restitué en cas de force majeure
          </h4>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Cette mention s’applique exclusivement dans le cas d’une location
            sans chauffeur.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Un cas de force majeure se définit comme une situation inhabituelle
            et quasi impossible à prévenir. Par exemple, le véhicule
            réquisitionné par la police ou par d’autres autorités compétentes.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Si le véhicule n’est pas restitué par le locataire et qu’il a été
            prouvé qu’il s’agit d’un cas de force majeure, le locataire et le
            propriétaire s’engagent à mettre tout en œuvre pour trouver une
            solution amiable. Il leur sera recommandé de venir à bout de ce
            souci tout en engageant toutes leurs responsabilités.
          </p>
          <h3 className="text-lg md:text-xl lg:text-xl lg:font-semibold text-[#444444] sub_heading">
            5.4. Les obligations des utilisateurs{" "}
          </h3>
          <h4 className="text-lg md:text-xl lg:text-xl text-[#444444] inner_sub_heading">
            5.4.1. Les obligations de conformité aux CGU
          </h4>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Les utilisateurs de la plateforme{" "}
            <span className="text-base font-semibold text-[#444444]">
              Primecar
            </span>{" "}
            s’engagent à l’utiliser uniquement dans les conditions prévues par
            les présentes CGU, de façon loyale, dans le respect des lois et
            règlements applicables, notamment les lois relatives à la propriété
            intellectuelle et industrielle, à l’informatique, aux fichiers et à
            la vie privée.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Les utilisateurs garantissent à{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            le bon respect de toutes réglementations qui leurs seraient
            applicables selon leur statut juridique.
          </p>
          <h4 className="text-lg md:text-xl lg:text-xl  text-[#444444] inner_sub_heading">
            5.4.2. Les obligations liées au système d’évaluation
          </h4>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <span className="text-base font-semibold text-[#444444]">
              Primecar
            </span>{" "}
            met à la disposition de ses utilisateurs, un système permettant
            l’évaluation et de commentaire relatif au véhicule loué, à son
            propriétaire et au locataire à l’issue d’une location.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            n’intervient pas sur le contenu des commentaires publiés par les
            utilisateurs et se réserve le droit d’y mener des opérations de
            contrôle. L’entreprise autorise par conséquent tout utilisateur
            ayant des doutes sur l’authenticité des commentaires de les
            signaler.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Les utilisateurs se doivent de tenir des propos mesurés, respectueux
            envers les autres, en toutes circonstances, et notamment lors de
            l’utilisation du système permettant l’évaluation et les commentaires
            sur des véhicules et/ou des autres utilisateurs.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Le non-respect de ces principes entraînera la suppression du
            commentaire et/ou de l’évaluation concerné.
          </p>
          <h4 className="text-lg md:text-xl lg:text-xl lg:font-semibold text-[#444444] inner_sub_heading">
            5.4.3. Les obligations liées à la location d’un véhicule
          </h4>
          <h4 className="text-lg md:text-xl lg:text-xl lg:font-semibold text-[#444444] inner_sub_heading_simple">
            Pour la location sans chauffeur :
          </h4>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Toutes locations prévues sur la plateforme{" "}
            <span className="text-base font-semibold text-[#444444]">
              Primecar
            </span>{" "}
            est précédé d’une opération de formalisation par la signature d’un
            contrat de location entre le locataire et le propriétaire. Il peut
            s’agir d’un contrat papier ou dématérialisé.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Le contrat de location inclut :
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <ul>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Les termes de références aux CGU
                </div>{" "}
              </li>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  L’état des lieux du véhicule avant le début de la location et
                  en présence des deux parties (le locataire et le
                  propriétaire). Un ensemble de photos couvrant tous les angles
                  du véhicule est pris.{" "}
                </div>{" "}
              </li>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  L’état des lieux du véhicule après la location et en présence
                  des deux parties (le locataire et le propriétaire). Un
                  ensemble de photos couvrant tous les angles du véhicule est
                  pris.{" "}
                </div>{" "}
              </li>
            </ul>
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Le Locataire est tenu de fournir les documents ci-après au
            propriétaire du véhicule :{" "}
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <ul>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Une copie de la pièce d’identité et du permis de conduire du
                  locataire.
                </div>
              </li>{" "}
            </ul>
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            De même, le propriétaire doit mettre à la disposition du locataire
            les documents ci- après :{" "}
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <ul>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Une copie de la carte grise du véhicule loué ;
                </div>
              </li>{" "}
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Une copie de l'attestation d'assurance annuelle du véhicule ;
                </div>
              </li>{" "}
            </ul>
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Le propriétaire et le locataire sont invités à vérifier les
            originaux des documents fournis par chaque partie pour s’assurer de
            leurs conformités avec les copies partagées.{" "}
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <span className="text-base font-semibold text-[#444444]">Note</span>{" "}
            : Il est obligatoire de fournir un véhicule parfaitement nettoyé
            avant toute location. Toutes les informations renseignées dans
            l’état des lieux avant le début de location et après la location
            doivent être précises, parfaitement lisibles et sans équivoque.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Les utilisateurs s’engagent à compléter l’ensemble des informations
            attendues dans les états des lieux et à prendre douze (12) photos
            puis de les conserver sur un support fiable, à titre de preuve. Il
            est conseillé de les envoyer à l’autre partie par email en sorte
            d’être en phase.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Les douze (12) photos du véhicule loué prises par le Locataire ou le
            Propriétaire devront répondre aux exigences suivantes :
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <ul>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Photos correspondantes au véhicule publié sur l’application{" "}
                  <span className="text-base font-semibold text-[#444444]">
                    Primecar
                  </span>{" "}
                </div>{" "}
              </li>{" "}
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Photos de qualité et parfaitement lisibles,
                </div>{" "}
              </li>{" "}
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Photos du véhicule intégralement visible et respectant les
                  huit (8) angles différents indiqués dans le contrat de
                  location et les encadrés de l’application à savoir « Face
                  avant, Angle avant droit, Aile droite, Angle arrière droit,
                  Face arrière, Angle arrière gauche, Aile gauche et Angle avant
                  gauche »,
                </div>{" "}
              </li>{" "}
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Quatre (4) autres photos du véhicule intégralement visible
                  incluant : Le contenu du capot, l’intérieur du côté avant du
                  véhicule, l’intérieur du côté arrière du véhicule et le
                  contenu du coffre arrière.
                </div>{" "}
              </li>{" "}
            </ul>
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Lors de la signature du contrat de location, le propriétaire et le
            locataire procèdent à un état des lieux du Véhicule. Le Locataire
            est tenu de déclarer tous les dommages dans l'état des lieux avant
            la prise du véhicule. Tout dommage non indiqué dans l’état des lieux
            sera présumé être du fait direct du Locataire.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Lors de l’état des lieux retour du véhicule, il est fortement
            recommandé aux utilisateurs de prendre douze (12) photos du véhicule
            qui devront répondre aux mêmes exigences que celles de l’état des
            lieux avant le début de la location : Ces photos devront être
            partagées entre le propriétaire et le locataire par mail et
            conservées pendant un délai minimum de 28 (vingt-huit) jours, après
            la fin de location, afin d’éviter toute contestation en cas de
            dommages au véhicule.
          </p>
          <h4 className="text-lg md:text-xl lg:text-xl lg:font-bold text-[#444444] inner_sub_heading_simple">
            Pour la location avec chauffeur :{" "}
          </h4>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Le propriétaire est tenu de conserver une copie de la pièce
            d’identité du locataire.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Le locataire s’assure de la conformité du véhicule telle qu’il a été
            annoncé sur la plateforme{" "}
            <span className="text-base font-semibold text-[#444444]">
              Primecar
            </span>{" "}
            avant de procéder à la signature du contrat.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            L’état des lieux du véhicule est laissé à la discrétion du locataire
            et du propriétaire. Le chauffeur étant perçu comme le mandant du
            propriétaire, il assume la responsabilité entière de l’état du
            véhicule pendant toute la durée de la location.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Que la location soit avec ou sans chauffeur, le contrat de location
            signé et ses annexes font foi auprès de l’assureur en cas de
            sinistre. Il est entendu que le contrat de location prend fin
            lorsque le véhicule est accidenté ou en panne et est pris en charge
            par un dépanneur.
          </p>
          <h4 className="text-lg md:text-xl lg:text-xl lg:font-semibold text-[#444444] inner_sub_heading_simple">
            Cas d’une réservation instantanée :{" "}
          </h4>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Le propriétaire s’engage à tenir son calendrier de location à jour
            et à répondre à ses obligations s’il ne souhaite pas s’exposer à des
            pénalités.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            La réservation instantanée autorise le locataire à louer le véhicule
            sans l’approbation préalable du propriétaire. Ce dernier est tenu de
            mettre le véhicule à disposition dans le respect des créneaux
            horaires au début de la location. A son tour, le locataire est tenu
            de restituer le véhicule dans le créneau horaire de fin de location.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Les conditionnalités du contrat de location restent les mêmes que
            dans la procédure de réservation normale selon que la location se
            fait avec ou sans chauffeur.
          </p>
          <h4 className="text-lg md:text-xl lg:text-xl lg:font-semibold text-[#444444] inner_sub_heading">
            5.4.4. Les autres obligations
          </h4>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            L’utilisateur reconnait et accepte de répondre à toute demande de
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            ou de toute autorité administrative ou judiciaire compétente dans le
            cadre de la prévention ou lutte contre le blanchiment. Ainsi,
            l’utilisateur accepte de fournir, sur simple demande, tout
            justificatif d’adresse et/ou d’identité utile. En l’absence de
            réponse de sa part à ces demandes,{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            se réserve la possibilité de supprimer l’accès au compte.
          </p>
          <h2 className="text-lg md:text-xl lg:text-2xl lg:font-semibold text-[#444444] heading">
            6. Vérification de l’identité des utilisateurs
          </h2>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            est tenu par la vérification de l’identité des utilisateurs que ce
            soit les locataires ou les propriétaires. Il est à noter que{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            ne se porte garant d’aucun utilisateur.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Il est de la responsabilité des utilisateurs de fournir de manière
            exacte les informations sur leur identité.{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            ne fait aucune confirmation ou approbation concernant les
            utilisateurs, leur identité ou leur antécédent.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Tous actes ou omissions des utilisateurs ou de tiers vis-à-vis de
            leur identité auprès d’autres utilisateurs ne peut faire l’objet que
            de réclamation à l’encontre de ceux-ci auprès de{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>
            . Les utilisateurs acceptent et reconnaissent que la responsabilité
            de
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            ne peut être engagée pour de tels actes ou omissions.
          </p>
          <h3 className="text-lg md:text-xl lg:text-1xl lg:font-semibold text-[#444444] sub_heading">
            6.1. L’identité des locataires
          </h3>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <span className="text-base font-semibold text-[#444444]">Seul</span>{" "}
            le propriétaire est tenu de vérifier l’identité du locataire en
            prenant les photos des documents d’identité de ce dernier (exemple :
            CNI, Passeport, Permis de conduire) dans le cas d’une location.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <span className="text-base font-semibold text-[#444444]">
              Le propriétaire
            </span>{" "}
            vérifie systématiquement l’identité des locataires dès lors que ces
            derniers initient leur demande de location.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <ul>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  S’il s’agit d’une location{" "}
                  <span className="text-base font-semibold text-[#444444]">
                    sans
                  </span>{" "}
                  chauffeur,{" "}
                  <span className="text-base font-semibold text-[#444444]">
                    DINSS
                  </span>{" "}
                  collecte la photo d’identité et le permis de conduire de
                  celui-ci sur sa plateforme.
                </div>
              </li>{" "}
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Dans le cas d’une location{" "}
                  <span className="text-base font-semibold text-[#444444]">
                    avec
                  </span>{" "}
                  chauffeur,{" "}
                  <span className="text-base font-semibold text-[#444444]">
                    DINSS
                  </span>{" "}
                  récupère cette fois une pièce d’identité en plus de la photo
                  sur sa plateforme.
                </div>
              </li>{" "}
            </ul>
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            et le propriétaire sont tenus de garder une copie des documents
            d’identité du locataire pendant toute la durée de la location et ne
            sauraient les utiliser à d’autres fins que celle prévus par les CGU
            et la Politique de Confidentialité.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Au cas où il est prouvé la non-conformité de l’identité du
            locataire, le compte de celui-ci est supprimé comme cela est prévu
            dans les CGU : « Conditions d’exclusion de la plateforme ».
          </p>
          <h3 className="text-lg md:text-xl lg:text-1xl lg:font-semibold text-[#444444] sub_heading">
            6.2. L’identité des propriétaires
          </h3>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Le propriétaire communique à{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            les informations ci-après pendant le dépôt du véhicule :
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <ul>
              <li className="list_items list-disc">
                <div className="list_text_margin">Une photo d’identité</div>
              </li>{" "}
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Une copie des papiers d’identité (Attestation, CNI, Passeport)
                </div>
              </li>{" "}
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Une copie de la carte grise du/des véhicule(s)
                </div>
              </li>{" "}
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Le(s) documents d’assurance du/des véhicules
                </div>
              </li>{" "}
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Un justificatif de domicile datant de moins de trois (3) mois.
                </div>
              </li>{" "}
            </ul>
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Pour les entreprises de location, il leur sera demander les
            informations :
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <ul>
              <li className="list_items list-disc">
                <div className="list_text_margin">Un registre de commerce </div>
              </li>{" "}
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Une copie des papiers d’identité (Attestation, CNI, Passeport)
                  du représentant principal de l’entreprise
                </div>
              </li>{" "}
            </ul>
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Le propriétaire communique à{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            les informations ci-après pendant le dépôt du véhicule :
          </p>
          <h2 className="text-lg md:text-xl lg:text-2xl lg:font-semibold text-[#444444] heading">
            7. Détails sur l’assurance
          </h2>
          <h3 className="text-lg md:text-xl lg:text-1xl lg:font-semibold text-[#444444] sub_heading">
            7.1. Conditions générales de l’assurance
          </h3>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            met à disposition une plateforme d’intermédiation permettant aux
            locataires de bénéficier des services de location de véhicule
            proposées par les propriétaires.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Tous les propriétaires annonçant leur(s) véhicule(s) sur la
            plateforme sont tenus de les faire accompagner d’une police
            d’assurance couvrant tous les passagers et prenant en compte tous
            les dommages causés à leur(s) véhicule(s).
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Cette assurance doit couvrir au moins :
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <ul>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Les dommages causés à la carrosserie du véhicule, les
                  incendies ou les vols quel que soit leur auteur.
                </div>{" "}
              </li>{" "}
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Les dommages causés aux tiers et à leur véhicule.
                </div>{" "}
              </li>{" "}
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Les dommages physiques (ex. blessure corporelle) causés au
                  locataire.
                </div>{" "}
              </li>{" "}
            </ul>
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            n’assure en aucun cas les locations conclues sur sa plateforme{" "}
            <span className="text-base font-semibold text-[#444444]">
              Primecar
            </span>
            .
          </p>
          <h3 className="text-lg md:text-xl lg:text-1xl lg:font-semibold text-[#444444] sub_heading">
            7.2. L’option d’une couverture d’assurance complémentaire
          </h3>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            ne propose pas pour l’instant une police d’assurance couvrant toute
            la période de location.
          </p>
          <h2 className="text-lg md:text-xl lg:text-2xl lg:font-semibold text-[#444444] heading">
            8. Impôt et taxes
          </h2>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Les propriétaires sont informés que les revenus qu’ils tirent de
            leur activité de location de véhicule pourront faire l’objet d’une
            imposition ou d’une taxation. Il leur appartient donc de vérifier
            leurs obligations fiscales et d’effectuer toutes déclarations
            requises aux autorités compétentes.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            n’est pas partie de cette démarche et sa responsabilité ne pourrait
            aucunement être engagée.
          </p>
          <h2 className="text-lg md:text-xl lg:text-2xl lg:font-semibold text-[#444444] heading">
            9. Propriété Intellectuelle
          </h2>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            est titulaire de l’ensemble des droits de propriété intellectuelle
            relatifs aux éléments textuels, graphiques, sonores,
            vidéographiques, logiciels ou de toute autre nature composant le
            Site ou l’Application, notamment la marque{" "}
            <span className="text-base font-semibold text-[#444444]">
              Primecar
            </span>
            , à l’exception des informations renseignées par les utilisateurs.
            Le Site ou l’Application constitue une œuvre sur laquelle seule{" "}
            <span className="text-base font-semibold text-[#444444]">
              Primecar
            </span>{" "}
            est titulaire des droits de propriété intellectuelle.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Toute reproduction ou représentation, totale ou partielle d’un de
            ces droits, sans l’autorisation de{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>
            , est interdite et constituerait une contrefaçon sanctionnée par le
            Code de la Propriété Intellectuelle.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Ainsi, les utilisateurs s'interdisent tout agissement et tout acte
            susceptible de porter atteinte directement ou non aux droits de
            propriété intellectuelle de{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>
            .
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Les utilisateurs ne peuvent en aucun cas utiliser, imprimer ou
            reformater le contenu de la plateforme{" "}
            <span className="text-base font-semibold text-[#444444]">
              Primecar
            </span>{" "}
            à des fins autres que privées ou familiales. Ils s’engagent à ne pas
            télécharger, reproduire, transmettre, vendre ou distribuer, etc le
            contenu de la Plateforme.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Chacun reconnaît que les informations et les bases de données
            accessibles sur la Plateforme sont la propriété exclusive de{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>
            .
          </p>
          <h2 className="text-lg md:text-xl lg:text-2xl lg:font-semibold text-[#444444] heading">
            10. Responsabilité
          </h2>
          <h3 className="text-lg md:text-xl lg:text-1xl lg:font-semibold text-[#444444] sub_heading">
            10.1. Les utilisateurs
          </h3>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <span className="text-base font-semibold text-[#444444]">
              Primecar
            </span>{" "}
            est accessible à tous les utilisateurs qui souhaiteraient bénéficier
            des services qu’elle offre. Ces derniers s’engagent à les utiliser
            dans les seules conditions définies par{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            et dans une certaine mesure, dans le strict respect des règles
            sociales et sociétales.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Il est à noter que tout utilisateur est tenu responsable des
            annonces qu’il publie sur la plateforme. Il en est de même pour les
            possibles préjudices causés en raison de ces annonces. En foi de
            quoi, il reconnait et accepte qu’il peut faire l’objet de
            signalements par d’autres utilisateurs en cas de dérives. Dans ce
            cas,{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            se réserve le droit de contrôle de ces remontés et des décisions qui
            pourraient en découler.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Cette responsabilité est aussi valable dans le cadre des évaluations
            et/ou des commentaires effectués par les utilisateurs de{" "}
            <span className="text-base font-semibold text-[#444444]">
              Primecar
            </span>
            , dans le strict respect des CGU, des règles et législations
            applicables.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            L’utilisateur s’engage à :
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <ul>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Utiliser uniquement le compte membre qu’il a créé et dont il a
                  l’entière responsabilité sur la plateforme{" "}
                  <span className="text-base font-semibold text-[#444444]">
                    Primecar
                  </span>
                  .
                </div>
              </li>{" "}
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Notifier immédiatement à{" "}
                  <span className="text-base font-semibold text-[#444444]">
                    DINSS
                  </span>{" "}
                  tout changement de données fournies sur la plateforme, et à
                  défaut reconnait être le seul responsable de quelque nature
                  que ce soit, qui pourraient en résulter.
                </div>
              </li>{" "}
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Ne pas collecter et utiliser les données des autres
                  utilisateurs de{" "}
                  <span className="text-base font-semibold text-[#444444]">
                    Primecar
                  </span>
                  .
                </div>
              </li>{" "}
            </ul>
          </p>
          <h3 className="text-lg md:text-xl lg:text-1xl lg:font-semibold text-[#444444] sub_heading">
            10.2. L’entreprise DINSS
          </h3>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            met à disposition{" "}
            <span className="text-base font-semibold text-[#444444]">
              Primecar
            </span>
            , une plateforme digitale dont le rôle porte essentiellement sur la
            mise en relation entre propriétaires de véhicules et locataires.{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            n’est en aucun cas partie aux opérations de locations et
            n’intervient à aucun moment en qualité de propriétaire de véhicules.{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            est un tiers à la relation contractuelle entre les utilisateurs pour
            chaque location sur{" "}
            <span className="text-base font-semibold text-[#444444]">
              Primecar
            </span>
            .
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            ne saurait être tenu pour responsable de tout dommage subi ou causé
            par le locataire ou le propriétaire au moyen d’un véhicule loué via{" "}
            <span className="text-base font-semibold text-[#444444]">
              Primecar
            </span>
            .{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            ne garantit pas le bon état et le bon fonctionnement du véhicule
            loué.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            ne garantit aucun revenu de location quel qu'il soit.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            ne garantit en aucun cas la solvabilité des utilisateurs. D’autre
            part, l’utilisateur reconnait et accepte que{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            n’est pas dans l’obligation de vérifier les antécédents de ces
            utilisateurs. Toutefois{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            se réserve le droit de procéder à ces vérifications à sa seule
            discrétion, dans les limites des prérogatives autorisées par les
            législations en vigueur.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            L’utilisateur reconnait que{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            ne saurait être tenu responsable pour avoir retiré ou restreint
            l’accès à un contenu publié par un utilisateur en raison de son
            caractère illicite ou abusif.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            La responsabilité de{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            ne saurait être recherchée en cas d’usage frauduleux ou abusif ou dû
            à une divulgation volontaire ou involontaire à quiconque des codes
            d’accès confiés à l’utilisateur.
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            ne pourra être tenue responsable de toute perte ou dommage survenus
            en raison du manquement aux obligations de confidentialité incombant
            à l’utilisateur.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            ne saurait être responsable de la violation des présentes CGU.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            ne garantit en aucun cas la bonne exécution des contrats de location
            conclus entre les membres de la plateforme{" "}
            <span className="text-base font-semibold text-[#444444]">
              Primecar
            </span>
            . Chaque membre est strictementresponsable de la bonne exécution de
            ses obligations contractuelles sans que la responsabilité de{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            ne puisse être recherchée de ce fait.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            ne saurait être responsable de l’atteinte aux droits des
            utilisateurs de manière générale.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            ne pourra être tenu responsable de la véracité des annonces de
            Location publiées sur la Plateforme.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Globalement,{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            ne saurait être tenu pour responsable de tout dommage indirect ou
            accessoire engendré par les utilisateurs dans l'exécution des
            présentes CGU.
          </p>
          <h2 className="text-lg md:text-xl lg:text-2xl lg:font-semibold text-[#444444] heading">
            11. La disponibilité de la plateforme Primecar
          </h2>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            La plateforme{" "}
            <span className="text-base font-semibold text-[#444444]">
              Primecar
            </span>{" "}
            est accessible tous les jours, 24h/24 sauf :
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <ul>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  En cas de force majeure occasionné par des évènements
                  inattendus ou inespérés ayant échappé au contrôle de{" "}
                  <span className="text-base font-semibold text-[#444444]">
                    DINSS
                  </span>
                  .
                </div>{" "}
              </li>{" "}
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  En cas d’activités planifiées (Maintenance, mis-à-jour, etc.)
                  nécessaire au bon fonctionnement de la plateforme et des
                  services fournis.
                </div>{" "}
              </li>{" "}
            </ul>
          </p>{" "}
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            L’utilisateur est informé que la plateforme pourrait être suspendue
            dans le cadre des réalisations de travaux de maintenance. En foi de
            quoi,{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            a la responsabilité de notifier les utilisateurs dans les meilleurs
            délais.
          </p>{" "}
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            L’utilisateur reconnait que constituent un cas de force majeure, les
            pannes et les problèmes d’ordre matériel concernant les ressources
            destinées au bon fonctionnement de la plateforme, mais ne se
            limitant pas aux interruptions, suspension ou fermeture du service.
          </p>{" "}
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            DINSS met à disposition un centre d’assistance pour les utilisateurs
            de la plateforme{" "}
            <span className="text-base font-semibold text-[#444444]">
              Primecar
            </span>{" "}
            accessible sur son site internet ou à partir de l’application
            mobile.
          </p>
          <h2 className="text-lg md:text-xl lg:text-2xl lg:font-semibold text-[#444444] heading">
            12. Droit de rétractation
          </h2>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            En acceptant les présentes CGU, l’utilisateur accepte expressément
            que les conditions des CGU entre{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            et les utilisateurs consistant en la mise en relation avec un
            autremembre soient exécutées avant l’expiration du délai de
            rétractation et dès la pleine confirmation de réservation de la
            demande location, en conséquence de quoi l’utilisateur renonce
            expressément à son droit de rétraction.
          </p>
          <h2 className="text-lg md:text-xl lg:text-2xl lg:font-semibold text-[#444444] heading">
            13. Données à caractère personnel
          </h2>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            est responsable du traitement des données à caractère personnel de
            l’utilisateur. Ces données sont collectées au cours de l’utilisation
            des services offerts par la plateforme{" "}
            <span className="text-base font-semibold text-[#444444]">
              Primecar
            </span>
            .
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            En s’inscrivant sur la plateforme{" "}
            <span className="text-base font-semibold text-[#444444]">
              Primecar
            </span>
            , l’utilisateur consent à ce traitement et garantie l’exactitude des
            données fournies par ses soins.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            donne plus de détails sur la collecte, l’utilisation et la sécurité
            des données de l’utilisateur dans sa Politique de Confidentialité
            laquelle est non seulement, conforme aux règles de protection de
            données prévus par la législation mais aussi, fait partie des
            présentes CGU.
          </p>
          <h2 className="text-lg md:text-xl lg:text-2xl lg:font-semibold text-[#444444] heading">
            14. Conditions d’exclusion de la plateforme
          </h2>
          <h3 className="text-lg md:text-xl lg:text-1xl lg:font-semibold text-[#444444] sub_heading">
            14.1. Les utilisateurs
          </h3>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Les utilisateurs inscrits sur la plateforme{" "}
            <span className="text-base font-semibold text-[#444444]">
              Primecar
            </span>{" "}
            bénéficient des services qui y sont offerts dans le respect des CGU
            et des autres dispositions législatives et réglementaires.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Les utilisateurs peuvent à tout moment et sans motif procéder à la
            fermeture de leur compte. Dès lors, ils ne sont plus tenus par les
            présentes CGU.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            La fermeture d’un compte ne peut en aucun cas mettre fin à une
            procédure judiciaire entre les parties sur des questions de
            recouvrement de quelque nature que ce soit, etc.
          </p>
          <h3 className="text-lg md:text-xl lg:text-1xl lg:font-semibold text-[#444444] sub_heading">
            14.2. L’entreprise DINSS
          </h3>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            se réserve le droit de retirer les l’accès aux services offerts dans
            l’hypothèse d’éventuelles soupçons de violations des règles édictées
            par les présentes CGU.
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            En cas de manquements, de violations des droits qui lui sont
            accordés par les CGU, ou d’utilisation subversive des services par
            l’utilisateur,{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>{" "}
            peut suspendre à tout moment et sans préavis l’accès à tout ou
            partie des services. Et ce, de plein droit, sans préavis, sans
            indemnité et sans préjudice des actions qui pourraient être menées
            contre{" "}
            <span className="text-base font-semibold text-[#444444]">
              DINSS
            </span>
            .
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            Les manquements aux dispositions des CGU sont résumés ci-après
            (liste non exhaustive) :
          </p>
          <p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
            <ul>
              <li className="list_items list-disc">
                <div className="list_text_margin">Impayés</div>
              </li>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Coût de location proposé anormalement bas
                </div>
              </li>
              <li className="list_items list-disc">
                <div className="list_text_margin">Vol de véhicule</div>
              </li>
              <li className="list_items list-disc">
                <div className="list_text_margin">Usurpation d’identité</div>
              </li>
              <li className="list_items list-disc">
                <div className="list_text_margin">Infraction pénale</div>
              </li>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Menaces, insultes ou offenses envers les utilisateurs, le
                  personnel de{" "}
                  <span className="text-base font-semibold text-[#444444]">
                    DINSS
                  </span>{" "}
                  ou les partenaires
                </div>
              </li>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Paiement en dehors de la plateforme
                </div>
              </li>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Litige avec un utilisateur
                </div>
              </li>
              <li className="list_items list-disc">
                <div className="list_text_margin">Fraude à l’assurance</div>
              </li>
              <li className="list_items list-disc">
                <div className="list_text_margin">
                  Atteinte à l’intégrité de la plateforme
                </div>
              </li>
            </ul>
          </p>
        </div>
      </div>
    </Layout>
  );
};
export default Test;
