import React, { useEffect } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import AdminLayout from '../components/layouts/adminlayout/AdminLayout';
import { useSelector } from 'react-redux';

const AuthProvider = () => {
    const navigate = useNavigate()
    const role = useSelector((state) => state?.userAuth?.userInfo?.userRole)
    const RequireAuth = ({ children }) => {
        const token = useSelector((state) => state.userAuth.loginInfo.token);
        let location = useLocation();
        if (!token) {
            return <Navigate to="/dinss/tech/signin" state={{ from: location }} replace />;
        }
        return children;
    };

    useEffect(() => {
        if (role === "Moderator") {
          navigate('/dinss/tech/afiliate')
        }
    }, [])
    return (
        <RequireAuth>
            <AdminLayout />
        </RequireAuth>
    )
}

export default AuthProvider