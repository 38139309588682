import React, { useEffect, useState } from "react";
import message from "../../../../../assests/message.svg";
import call from "../../../../../assests/call.svg";
import car from "../../../../../assests/unsplash_eTuO9BNtcPA.png";
import pump from "../../../../../assests/svg/carburant-icon.svg";
import boite from "../../../../../assests/svg/boite-icon.svg";
import seet from "../../../../../assests/svg/places-portes-icon.svg";
import setting from "../../../../../assests/svg/AC-icon.svg";
import usb from "../../../../../assests/svg/USB-icon.svg";
import upload from "../../../../../assests/PMNeew/uplad.svg";
import bluthoth from "../../../../../assests/svg/bluetooth-icon.svg";
import location from "../../../../../assests/svg/location-icon.svg";
// import { IoLocation } from 'react-icons/io5'
import file from "../../../../../assests/svg/file-icon.svg";
import { MdDelete, MdOutlineKeyboardArrowRight } from "react-icons/md";
// import PieChart from "../../PieChart";
import { Link, useParams } from "react-router-dom";
import {
  DeletelicenceDocuments,
  GetVihicalsDetail,
  UplaodlicenceDocuments,
} from "../../../../../lib/Api/Vehical";
import CarListPieChart from "../../../manageUser/CarDetailPieChart";
import { IoLocation } from "react-icons/io5";
import moment from "moment";
import Popup from "../../../../../util/popup/Popup";
import PreviewLicence from "../../../manageUser/carlistDetail/PreviewLicence";
import LoaderSpinner from "../../../../../util/LoaderSpinner";
import { Truncate } from "../../../../../util/TrucateString";
import { BsFillCloudDownloadFill } from "react-icons/bs";
import { AiFillEye } from "react-icons/ai";
import { downloadFile } from "../../../../../Partials/DownloadDoc";

const CarlistDetail = (props) => {
  const { name = "" } = props;
  const [isEdit] = useState(true);
  const [detail, setDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [activeImg, setActiveImg] = useState(car);
  const [chart, setChart] = useState({});
  const [show, setShow] = useState(false);
  const [preview, setPreview] = useState("");
  const [fileUplading, setFileUplaoding] = useState(false);
  const [fileType, setFileType] = useState("");
  const [insuranceFront, setInsuranceFront] = useState(null);
  const [insuranceBack, setInsuranceBack] = useState(null);
  let id = useParams().id;

  // Function to add a space after every hundred value in a number
  function addSpaceAfterHundred(number) {
    // Convert the number to a string
    let numStr = number?.toString();

    // Use a regular expression to insert a space after every group of three digits from the end
    numStr = numStr?.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    return numStr;
  }

  const handlePreview = (pic) => {
    setPreview(pic);
    setShow(true);
  };

  const handleUplaodDoc = async (e, type) => {
    let val = e.target.files[0];
    let checkFile =
      type === "front" ? "document_pdf_front" : "document_pdf_back";
    if (val) {
      setFileType(type);
      try {
        let formdata = new FormData();
        formdata.append("vehicle_id", detail?.id);
        formdata.append(`${checkFile}`, val);
        setFileUplaoding(true);
        let { res } = await UplaodlicenceDocuments(formdata);
        if (res) {
          if (type === "front") {
            setInsuranceFront((prev) => ({
              ...prev,
              document_pdf_front: res?.document_pdf_front
            }))
          }
          else {
            setInsuranceBack((prev) => ({
              ...prev,
              document_pdf_back: res?.document_pdf_back
            }))
          }

          setFileUplaoding(false);
        }
      } catch (err) { }
    }
  };

  const handleDelete = async (type) => {
    setFileType(type);
    try {
      let formdata = new FormData();
      formdata.append("vehicle_id", detail?.id);
      formdata.append(`document_to_delete`, type);
      setFileUplaoding(true);
      let { res } = await DeletelicenceDocuments(formdata);
      if (res) {
        if (type === "front") {
          setInsuranceFront(null)
        }
        else {
          setInsuranceBack(null)

        }
        setFileUplaoding(false);
      }
    } catch (err) { }
  };

  useEffect(() => {
    let fecth = async () => {
      setLoading(true);
      let { res } = await GetVihicalsDetail(id);
      let obj = Object.assign({}, ...res);
      setDetail(obj?.vehicle_details);
      setInsuranceFront((prev) => ({
        ...prev,
        document_pdf_front: obj?.vehicle_details.insurance?.document_pdf_front
      }))
      setInsuranceBack((prev) => ({
        ...prev,
        document_pdf_back: obj?.vehicle_details.insurance?.document_pdf_back
      }))
      setChart(obj);
      setActiveImg(obj?.vehicle_details?.images[0]);
      setLoading(false);
    };
    fecth();
  }, []);

  return (
    <>
      {show && (
        <Popup
          modalOpen={show}
          data={<PreviewLicence pic={preview} />}
          onClose={() => setShow(false)}
        />
      )}
      <div className="flex items-center">
        <Link to="/dinss/tech/management">
          <h2 className="text-[#ACACAC] text-[16px]">
            {name ? name : "Vehicle management"}
          </h2>
        </Link>
        <MdOutlineKeyboardArrowRight className="text-[#ACACAC] text-[20px]" />
        <h2 className="text-[#FF8C00] text-[16px]">Detail</h2>
      </div>

      {/* section 1  */}

      {loading ? (
        <div className="py-10">
          <LoaderSpinner type="tableLoad" />
        </div>
      ) : (
        <>
          <section>
            <div className="bg-white shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] border-0 rounded-md border-gray-100 p-5 my-5 pt-[29px]">
              <h1 className="text-[#FF8C00] text-[20px] font-semibold">
                {" "}
                Details
              </h1>
              <div className="grid lg:gap-40 lg:grid-cols-4 md:grid-cols-4 grid-cols-2 pt-5">
                <div className="">
                  <h2 className="text-[#444444] text-[14px]">Number Plate</h2>
                  <p className="text-[14px] pt-5 xxtra-small:pt-2 text-[#898989] leading-[22px]">
                    {detail?.insurance?.plate_number}
                  </p>
                </div>
                <div className="">
                  <h2 className="text-[#444444] text-[14px]">Brand</h2>
                  <p className="text-[14px] pt-5 xxtra-small:pt-2 text-[#898989] leading-[22px]">
                    {detail?.make}
                  </p>
                </div>
                <div className="lg:my-0 md:my-0 my-3">
                  <h2 className="text-[#444444] text-[14px]">Model</h2>
                  <p className="text-[14px] pt-5 xxtra-small:pt-2 text-[#898989] leading-[22px]">
                    {detail?.model}
                  </p>
                </div>
                <div className="lg:my-0 md:my-0 my-3">
                  <h2 className="text-[#444444] text-[14px] text-left">
                    Mileage
                  </h2>
                  <p className="text-[14px] pt-5 xxtra-small:pt-2 text-[#898989] leading-[22px]">
                    {detail?.mileage}
                  </p>
                </div>
              </div>
              <div className="grid lg:gap-40 lg:grid-cols-4 grid-cols-2 pt-10 xxtra-small:pt-3">
                <div className="">
                  <h2 className="text-[#444444] text-[14px]">Vehicle type</h2>
                  <p className="text-[14px] pt-5 xxtra-small:pt-2 text-[#898989] leading-[22px]">
                    {detail?.vehicle_category}
                  </p>
                </div>
                <div className="">
                  <h2 className="text-[#444444] text-[14px]"> Email</h2>
                  <p className="text-[14px] pt-5 xxtra-small:pt-2 text-[#898989] leading-[22px]">
                    {detail?.user?.email}
                  </p>
                </div>
                <div className="lg:my-0 md:my-0 my-3">
                  <h2 className="text-[#444444] text-[14px]">Phone</h2>
                  <p className="text-[14px] pt-5 xxtra-small:pt-2 text-[#898989] leading-[22px]">
                    {detail?.user?.phone_number}
                  </p>
                </div>
                <div className="lg:my-0 md:my-0 my-3">
                  <h2 className="text-[#444444] text-[14px] text-left">
                    Vehicle ID
                  </h2>
                  <p className="text-[14px] pt-5 xxtra-small:pt-2 text-[#898989] leading-[22px]">
                    {detail?.id}
                  </p>
                </div>
              </div>
            </div>
          </section>

          {/* Section 2 */}
          <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-5">
            <div className="bg-white shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] border-0 rounded-md border-gray-100 p-5">
              <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center">
                <div className=" max-w-[90px] w-full h-[87px] rounded-lg ">
                  <img
                    src={detail?.user?.image}
                    alt="user"
                    className="w-[110px] h-[80px]"
                  />
                </div>
                <div className="w-full lg:pl-9 md:pl-8 ">
                  <div className="flex items-center">
                    <h2 className="text-[#444444] text-[18px]">
                      {detail?.user?.first_name}
                    </h2>
                    <span className="text-[#ACACAC] px-3">
                      {detail?.with_driver === 1
                        ? "( With Driver )"
                        : "( Without Driver )"}
                    </span>
                  </div>
                  <div className="flex justify-between items-start pt-1.5">
                    <div className="">
                      <h2 className="text-[#ACACAC] text-[16px] leading[27px]">
                        Proprétaire
                      </h2>
                      <div
                        style={{
                          color: `${detail?.status_object?.text_color}`,
                          backgroundColor: `${detail?.status_object?.background_color}`,
                        }}
                        className={` mt-3 text-[14px]  rounded-full text-center  py-2 w-full px-2 ml-auto`}
                      >
                        <h2>{detail?.status_object?.text_en}</h2>
                      </div>
                    </div>
                    <div className="flex items-center">
                      <img
                        src={message}
                        alt="message"
                        className="object-cover"
                      />
                      <img
                        src={call}
                        alt="message"
                        className="object-cover ml-2.5"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="pt-5">
                <p className="text-[#ACACAC] text-[16px]">
                  {Truncate(detail?.description, 180)}{" "}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-center h-[309px] xxtra-small:h-[206px] rounded-xl overflow-hidden">
              <img src={activeImg?.image || car} alt="car" className="w-full" />
            </div>
          </div>

          {/*  section 2 */}
          <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-5 pt-6">
            <div className="bg-white lg:order-0 md:order-0 order-1 shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] border-0 rounded-md border-gray-100 p-5">
              <h1 className="text-[#FF8C00] font-semibold text-[20px] leading-[30px]">
                Caracteristiques
              </h1>
              {detail?.accessories?.length > 0 ? (
                <>
                  <div className="grid lg:grid-cols-3 md:grid-cols-3 xxtra-small:grid-cols-3 grid-cols-2 pt-[30px]">
                    <div className="">
                      <img src={pump} alt="car" className="object-cover" />
                      <h2 className="text-[#444444] font-medium text-[18px] xxtra-small:text-[14px] pt-6 xxtra-small:pt-3">
                        Carburant
                      </h2>
                      <p className="text-[#ACACAC] text-[17px] xxtra-small:text-[12px] pt-1.5 xxtra-small:pt-1">
                        Hybride
                      </p>
                    </div>
                    <div className="">
                      <img src={boite} alt="car" className="object-cover" />
                      <h2 className="text-[#444444] font-medium text-[18px] xxtra-small:text-[14px] pt-6 xxtra-small:pt-3">
                        {detail?.gearbox}
                      </h2>
                      <p className="text-[#ACACAC] text-[17px] xxtra-small:text-[12px] pt-1.5 xxtra-small:pt-1">
                        Manuelle
                      </p>
                    </div>
                    <div className="lg:my-0 md:my-0 my-0">
                      <img src={seet} alt="car" className="object-cover" />
                      <h2 className="text-[#444444] font-medium text-[18px] xxtra-small:text-[14px] pt-6 xxtra-small:pt-3">
                        Places, Portes
                      </h2>
                      <p className="text-[#ACACAC] text-[17px] xxtra-small:text-[12px] pt-1.5 xxtra-small:pt-1">
                        {detail?.no_of_places},{detail?.no_of_doors}
                      </p>
                    </div>
                  </div>
                  <div className="grid lg:grid-cols-3 md:grid-cols-3 xxtra-small:grid-cols-3 grid-cols-2 pt-11 xxtra-small:pt-6">
                    <div className="">
                      <img src={setting} alt="car" className="object-cover" />
                      <h2 className="text-[#444444] font-medium text-[18px] xxtra-small:text-[14px] pt-6 xxtra-small:pt-3">
                        AC
                      </h2>
                      <p className="text-[#ACACAC] text-[17px] xxtra-small:text-[12px] pt-1.5 xxtra-small:pt-1">
                        convertisseur
                      </p>
                    </div>
                    <div className="lg:my-0 md:my-0 my-0">
                      <img src={usb} alt="car" className="object-cover" />
                      <h2 className="text-[#444444] font-medium text-[18px] xxtra-small:text-[14px] pt-6 xxtra-small:pt-3">
                        {detail?.accessories &&
                          detail?.accessories.find(
                            (f) => f.accessory?.accessory === "Chargeur USB"
                          )
                          ? "USB"
                          : ""}
                      </h2>
                      <p className="text-[#ACACAC] text-[17px] xxtra-small:text-[12px] pt-1.5 xxtra-small:pt-1">
                        chargeur
                      </p>
                    </div>
                    <div className="">
                      <img src={bluthoth} alt="car" className="object-cover" />
                      <h2 className="text-[#444444] font-medium text-[18px] xxtra-small:text-[14px] pt-6 xxtra-small:pt-3">
                        {detail?.accessories &&
                          detail?.accessories.find(
                            (f) => f.accessory?.accessory === "Bluetooth"
                          )
                          ? "Bluetooth"
                          : ""}
                      </h2>
                      <p className="text-[#ACACAC] text-[17px] xxtra-small:text-[12px] pt-1.5 xxtra-small:pt-1">
                        connexion
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                <div className="pt-5">No Caracteristiques Found </div>
              )}
            </div>

            <div className="lg:order-1 md:order-1 order-0">
              <div className="grid grid-cols-5 gap-5 pl-1">
                {detail?.images?.map((item, i) => (
                  <img
                    onClick={() => setActiveImg(item)}
                    key={i}
                    src={item?.image}
                    alt="car"
                    className={`object-cover w-[80px] h-[80px]  rounded-md border-[3px] ${item?.id === activeImg?.id ? "border-[#FF8C00]" : ""
                      }`}
                  />
                ))}
              </div>
              <div className="bg-white shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] border-0 rounded-md p-5 mt-4">
                <h1 className="text-[#FF8C00] text-[20px] font-semibold">
                  Total Requests
                </h1>
                <div>
                  <CarListPieChart statis={chart} />
                </div>
              </div>
            </div>
          </div>

          {/* secrtion 3  */}
          <section>
            <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-5 pt-6">
              <div className="bg-white shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] border-0 rounded-md border-gray-100 p-5">
                <h1 className="text-[#FF8C00] font-semibold text-[20px]">
                  Location
                </h1>

                <div className="flex justify-between items-center pt-5">
                  <h2 className="text-[#898989] text-[14px] ">
                    Within Abidjan
                  </h2>
                  <p className="text-[#FF8C00]  text-[14px]">
                    {addSpaceAfterHundred(detail?.price_in_abidjan)} FCFA
                    <span className="text-[#898989] "> /day</span>
                  </p>
                </div>
                <div className="flex justify-between items-center pt-5">
                  <h2 className="text-[#898989] text-[14px] ">
                    Outside Abidjan
                  </h2>
                  <p className="text-[#FF8C00]  text-[14px]">
                    {addSpaceAfterHundred(detail?.price_out_abidjan)} FCFA
                    <span className="text-[#898989] "> /day</span>
                  </p>
                </div>
                <div className="flex items-center pt-5">
                  {/* <img src={location} alt="" /> */}
                  {/* <IoLocation className='text-[#FF8C00]' /> */}
                  {detail?.locations?.map((loc, i) => (
                    <div className="flex items-center pt-5 " key={i}>
                      <IoLocation className="text-[#FF8C00]" />
                      <h2 className="ml-1 text-[#444444]">{loc?.address}</h2>
                    </div>
                  ))}
                </div>
              </div>
              <div className="bg-white shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] border-0 rounded-md border-gray-100 p-5">
                <h1 className="text-[#FF8C00] font-semibold text-[20px] leading-[30px]">
                  Rental Options
                </h1>

                <div className="flex justify-between items-center pt-5">
                  <h2 className="text-[#444444] text-[14px] ">Smokers</h2>
                  <p className="text-[#444444]  text-[14px]">Pets</p>
                </div>
                <div className="flex justify-between items-center pt-5">
                  <h2 className="text-[#898989] text-[14px] ">
                    {detail?.is_smoker_allowed === 0
                      ? "Not Accepting"
                      : "Accepting"}
                  </h2>
                  <p className="text-[#898989]  text-[14px]">Not Accepting</p>
                </div>
              </div>
            </div>
          </section>

          {/* section 5  */}
          <section>
            <div className="bg-white shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] border-0 rounded-md border-gray-100 p-5 mt-5">
              <h1 className="text-[#FF8C00] text-[20px] font-semibold">
                Insurance Details
              </h1>
              {detail.insurance !== null ? (
                <>
                  <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap justify-between items-center pt-5">
                    <div className="">
                      <h2 className="text-[#444444] text-[14px]">
                        Insurance ID
                      </h2>
                      <p className="text-[14px] pt-4 text-[#898989] leading-[22px]">
                        {detail.insurance?.grey_card_number}
                      </p>
                    </div>
                    <div className="">
                      <h2 className="text-[#444444] text-[14px]">
                        Registration ID
                      </h2>
                      <p className="text-[14px] pt-4 text-[#898989] leading-[22px]">
                        {detail.insurance?.grey_card_number}
                      </p>
                    </div>
                    <div className="">
                      <h2 className="text-[#444444] text-[14px]">
                        Insurance Validity
                      </h2>
                      <p className="text-[14px] pt-4 text-[#898989] leading-[22px]">
                        {moment(detail.valid_from).format("ll")}
                      </p>
                    </div>
                    <div className="">
                      <h2 className="text-[#444444] text-[14px] text-center">
                        Status
                      </h2>
                      <p className="text-[14px] mt-4 text-[#2BA676] bg-[#E0F3EC] px-6 py-2 rounded-full leading-[22px]">
                        valid
                      </p>
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-5">
                    <div className="pt-5">
                      <h2 className="text-[#444444] text-[14px] font-medium pb-5">
                        Upload Front Doccument
                      </h2>
                      <label htmlFor="front" className="cursor-pointer">
                        <div className="border py-[20px] border-dashed rounded-md text-center">
                          {fileUplading && fileType === "front" ? (
                            <LoaderSpinner type="saved" />
                          ) : (
                            <>
                              <div className="flex justify-center items-center">
                                <img src={upload} alt="upload" />
                              </div>
                              <h2 className="text-[14px] font-normal text-[#8C979A] font-sans">
                                Drop your files here, or browse
                              </h2>
                              <p className="text-[14px] text-[#8C979A] font-normal font-sans">
                                MP4, MKV, PDF, JPEG, Max size: 20MB
                              </p>
                            </>
                          )}
                        </div>
                      </label>
                      <input
                        type="file"
                        className="hidden"
                        onChange={(e) => handleUplaodDoc(e, "front")}
                        id="front"
                      />
                    </div>
                    <div className="pt-5">
                      <h2 className="text-[#444444] text-[14px] font-medium pb-5">
                        Upload Back Doccument
                      </h2>
                      <label htmlFor="back" className="cursor-pointer">
                        <div className="border py-[20px] border-dashed rounded-md text-center">
                          {fileUplading && fileType === "back" ? (
                            <LoaderSpinner type="saved" />
                          ) : (
                            <>
                              <div className="flex justify-center items-center">
                                <img src={upload} alt="upload" />
                              </div>
                              <h2 className="text-[14px] font-normal text-[#8C979A] font-sans">
                                Drop your files here, or browse
                              </h2>
                              <p className="text-[14px] text-[#8C979A] font-normal font-sans">
                                MP4, MKV, PDF, JPEG, Max size: 20MB
                              </p>
                            </>
                          )}
                        </div>
                      </label>
                      <input
                        type="file"
                        onChange={(e) => handleUplaodDoc(e, "back")}
                        className="hidden"
                        id="back"
                      />
                    </div>
                  </div>
                  <div className="pt-5">
                    <div className="grid lg:grid-cols-2 md:grid-cols-2  gap-5">
                      <div>
                        {insuranceFront?.document_pdf_front
                          ?.file_name !== null ?
                          <div className="border border-[#ACACAC] rounded-md mt-4 px-2">
                            <div className="flex justify-between items-center">
                              <div className="flex items-center">
                                <img
                                  src={file}
                                  alt="file_image"
                                  className="object-cover"
                                />
                                <div className="px-2 py-2">
                                  <p className="text-[14px] text-[#444444]">
                                    {
                                      insuranceFront?.document_pdf_front
                                        ?.file_name
                                    }
                                  </p>
                                  <span className="text-[12px] text-[#898989]">
                                    {moment(

                                      insuranceFront?.front_file_created
                                    ).format("ll")}
                                    , Size:
                                    {insuranceFront?.front_file_size}
                                  </span>
                                </div>
                              </div>
                              <div className="flex items-center">
                                <h2
                                  onClick={() =>
                                    handlePreview(
                                      insuranceFront?.document_pdf_front
                                    )
                                  }
                                  className="underline mx-1 text-[#397DED] text-[14px]"
                                >
                                  <AiFillEye className="text-[20px]" />
                                </h2>
                                {isEdit && (
                                  <>
                                    <MdDelete
                                      className="text-[#F04438] mx-1 "
                                      onClick={() => handleDelete("front")}
                                    />
                                    <BsFillCloudDownloadFill onClick={() => downloadFile( insuranceFront?.document_pdf_front)} />
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                          : "Document Not Found"
                        }
                      </div>
                      <div>
                        {
                          insuranceBack?.document_pdf_back
                          ?.file_name !== null ? (
                            <div className="border border-[#ACACAC] rounded-md mt-4 px-2">
                              <div className="flex justify-between items-center">
                                <div className="flex items-center">
                                  <img
                                    src={file}
                                    alt="file_image"
                                    className="object-cover"
                                  />
                                  <div className="px-2 py-2">
                                    <p className="text-[14px] text-[#444444]">
                                      {insuranceBack?.document_pdf_back?.file_name}
                                    </p>
                                    <span className="text-[12px] text-[#898989]">
                                      {moment(
                                        insuranceBack?.back_file_created
                                      ).format("ll")}
                                      , Size:
                                      {insuranceBack?.back_file_size}
                                    </span>
                                  </div>
                                </div>
                                <div className="flex items-center">
                                  <h2
                                    onClick={() =>
                                      handlePreview(
                                        insuranceBack?.document_pdf_back
                                      )
                                    }
                                    className="underline mx-1 text-[#397DED] text-[14px]"
                                  >
                                    <AiFillEye className="text-[20px]" />
                                  </h2>
                                  {isEdit && (
                                    <>
                                      <MdDelete
                                        className="text-[#F04438] mx-1 "
                                        onClick={() => handleDelete("back")}
                                      />
                                      <BsFillCloudDownloadFill onClick={() => downloadFile(insuranceBack)} />
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          ) : (
                            "Document Not Found"
                          )}
                      </div>
                    </div>

                  </div>
                </>
              ) : (
                <div> No Insurance Data </div>
              )}
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default CarlistDetail;
