import React, { useState } from "react";
import BlogArticleCardMainPage from "../../BlogComponents/BlogArticleCardMainPage";
import article2 from "../../../assests/blog/articles/thumbnails/article-2.webp";
import article3 from "../../../assests/blog/articles/thumbnails/article-3.webp";
import article4 from "../../../assests/blog/articles/thumbnails/article-4.webp";
const LatestBlogArticleSection = () => {
	let blogsList = [
		{
			uid: 4,
			thumbnail: article4,
			tagText: "Guides et conseils",
			time: "4 min",
			title: "Nos conseils pour faire des annonces avec de belles photos sur Primecar",
			date: "25 octobre 2023",
			btnText: "Lire l'article",
			slug: "nos-conseils-pour-faire-des-annonces-avec-de-belles-photos-sur-primecar",
		},
		{
			uid: 3,
			thumbnail: article3,
			tagText: "Guides et conseils",
			time: "4 min",
			title: "Ce qu’il faut savoir sur la location de véhicule utilitaire.",
			date: "24 octobre 2023",
			btnText: "Lire l'article",
			slug: "ce-quil-faut-savoir-sur-la-location-de-vehicule-utilitaire",
		},
		{
			uid: 2,
			thumbnail: article2,
			tagText: "Professionnels",
			time: "3 min",
			title: "Louer une voiture pour votre entreprise ou en acheter en Côte d’Ivoire ?",
			date: "23 octobre 2023",
			btnText: "Lire l'article",
			slug: "louer-une-voiture-pour-votre-entreprise-ou-en-acheter-en-cote-divoire",
		},
	];

	return (
		<>
			<div className="md:px-20      px-5">
				<div className="flex-col md:flex-row lg:gap-10 gap-5 md:gap-[40px] items-center   py-5  md:py-9  lg:py-[60px] 2xl:w-[1440px] justify-between m-auto">
					<h2 className="latest-blog-section-heading">
						Découvrez nos conseils voyages et actualités
					</h2>
					<p className="latest-blog-section-sub-heading">
						Pour vos déplacements privés ou professionnels, optez
						pour la location de voiture haut de gamme ou à petit
						prix chez Primecar.
					</p>
					<div className="flex latest-blog-section-articles  columns-4 gap-8">
						{blogsList.map((blog, key) => {
							return (
								<div
									className=""
									key={blog.uid + "-blog-article-card-" + key}
								>
									<BlogArticleCardMainPage
										imageSrc={blog.thumbnail}
										tagText={blog.tagText}
										time={blog.time}
										cardText={blog.title}
										date={blog.date}
										buttonText={blog.btnText}
										slug={blog.slug}
										isButtonShow="false"
										titleClass="blog-article-card-text-slider-main-page"
									/>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</>
	);
};

export default LatestBlogArticleSection;
