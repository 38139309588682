import React from "react";
import Layout from "../../components/layouts/mainLayout/MainLayout";
import Banner from "../../components/MainLandingComp/helpSupport/Banner";
const Privacy = () => {
	const data = [
		{
			title: "Mentions légales",
			description:
				"La plateforme www.primecarapp.com est la propriété de la société dénommée Digital Innovation Services & Solutions en abrégé DINSS, une SARL au capital de 1000,000 FCFA, immatriculée au Registre du Commerce de Grand-Bassam, sous le numéro CI-GRDBSM-2021-B-9497, dont le siège est sis à Grand-Bassam route expresse Bassam-Bonoua, Section AX, Parcelle 62, Lot N°1690, ILOT 172. Le présent site web et l’application mobile Primecar sont développés par DINSS. Merci d’utiliser Primecar, nous sommes heureux de vous compter parmi les membres de notre communauté qui contribue à faciliter l’accès aux services de mobilité et de logistique dans la région ivoirienne. Nous sommes pleinement conscients de l’importance de protéger votre vie privée et nous appliquons rigoureusement des règles visant à préserver la confidentialité de vos données. Notre objectif principal est de vous offrir une expérience en ligne agréable et sécurisée lorsque vous utilisez notre Plateforme. Nous vous prions de porter une attention particulière à la présente politique de confidentialité. Elle vous donne des informations sur la manière dont nous collectons, conservons et traitons vos Données Personnelles.",
		},
		{
			title: "Définitions",
			description:
				"Les termes définis dans la présente Politique de Confidentialité viennent compléter ceux déjà définis dans les Conditions Générales d’Utilisation :",
			list: [
				{
					title: "Données à caractère personnel / Données personnelles",
					description:
						"désigne toute information se rapportant à une personne physique identifiée ou identifiable ;",
				},
				{
					title: "Traitement de données à caractère personnel",
					description:
						"désigne une opération ou tout ensemble d’opérations portant sur les données personnelles : collecte, enregistrement, organisation, conservation, adaptation, modification, extraction, consultation, utilisation ou toute autre forme de mise à disposition, rapprochement ;",
				},
				{
					title: "Destinataire(s)",
					description:
						"Il s’agit des personnes physiques ou morales qui reçoivent des données à caractère personnel de la part de DINSS ;",
				},
				{
					title: "Prestataire / Sous-traitant",
					description:
						"Il s’agit des personnes physiques ou morales qui traite des données à caractère personnel pour le compte de DINSS ;",
				},
				{
					title: "Responsable de traitement",
					description:
						"désigne la société Responsable du traitement des données personnelles relatives aux utilisateurs qui sont collectées dans le cadre de l’accès à la Plateforme et pour l’utilisation des Services ;",
				},
				{
					title: "Finalité(s)",
					description:
						"désigne les objectifs principaux assignés au traitement et aux fonctions substantielles mises en œuvre ;",
				},
				{
					title: "Personne concernée",
					description:
						"désigne les utilisateurs dont les données à caractère personnel sont collectées et intégrées dans le Traitement de données personnelles ;",
				},
				{
					title: "Violation de données à caractère personnel",
					description:
						"désigne une violation de la sécurité donnant lieu, de manière accidentelle ou illicite, à la destruction, la perte, l'altération, la divulgation non autorisée de données à caractère personnel transmises, conservées ou traitées d'une autre manière, ou l'accès non autorisé à de telles données.",
				},
				{
					title: "DINSS est désigné par la suite en tant que DINSS / nous / notre",
					description:
						"L’utilisateur est désigné par la suite en tant que « l’utilisateur » ou « vous » ou « votre ».",
				},
			],
		},
		{
			title: "Quels types d’informations recueillons nous et comment ?",
			description:
				"Nous collectons vos données à caractère personnel notamment toutes les informations utiles, de quelque nature que soit, se rapportant à une personne physique ou morale et permettant de l’identifier directement ou indirectement. Les données personnelles de l’utilisateur sont collectées et traitées par DINSS dans les cas suivants :",
			list: [
				{
					title: "Création d’un compte sur la plateforme",
					description:
						"Pour accéder aux services de Primecar, l’utilisateur peut créer un compte directement sur la plateforme ou à travers un compte Google ou Apple. Ainsi, les informations ci-après lui sont demandés pour terminer l’inscription. Certaines données sont recueillies automatiquement lorsque l’utilisateur utilise Google ou Apple pour se connecter sur la plateforme Primecar. Il s’agit, entre autres, de son nom, son prénom et de sa photo. Nécessaire pour terminer l’inscription :",
					points: [
						"Civilité",
						"Nom, Prénom",
						"Numéro de téléphone",
						"Adresse électronique",
						"Adresse ou coordonnées postale",
						"Mot de passe",
						"Photo",
					],
				},
				{
					title: "Mise en location d’un véhicule",
					description:
						"Lorsqu’un propriétaire d’un (ou de plusieurs) véhicule(s), qu’il soit une personne physique ou morale, souhaite mettre son (ses) biens en location sur la plateforme Primecar, des informations complémentaires sont requises en plus de celles fournies pendant l’inscription. Informations sur le véhicule, Informations relatives au paiement du propriétaire , Informations si le propriétaire est un professionnel:",
					points: [
						"Catégorie du véhicule",
						"Type et marque du véhicule",
						"Les informations détaillées du véhicule (type de moteur, carburant, la boite, nombre de sièges et de portes, le kilométrage, les accessoires)",
						"Le pays et la date de mise en circulation",
						"Le numéro de la carte grise",
						"Le document d’assurance du véhicule ainsi que sa validité",
						"L’immatriculation du véhicule",
						"L’adresse ou le lieu de stationnement du véhicule",
						"Coordonnées bancaire (Relevé d’Identité Bancaire) ou Compte mobile money valide (MTN, Moov, Orange, Wave)",
						"Une copie valide de la pièce d’identité",
						"Une copie du certificat d’immatriculation du véhicule",
						"Un justificatif de domicile de trois (3) mois",
						"Une copie du registre de commerce indiquant la dénomination sociale de l’entreprise",
						"Le nom et le prénom du représentant légal de l’entreprise concernée",
					],
				},
				{
					title: "Location d’un véhicule",
					description:
						"Tout utilisateur souhaitant louer un véhicule sur Primecar devra fournir les informations ci-après en plus de celles déjà partagées au cours de l’inscription. DINSS collecte les données d’identité du locataire lorsque celui-ci émet une demande de location. Ces données sont disponibles électroniquement sur la plateforme Primecar et accessibles par le Propriétaire. A noter que quand bien même ces données sont disponibles sur la plateforme, seul le propriétaire est tenu de vérifier l’identité du locataire en prenant une copie des photos des documents d’identité de ce dernier (exemple : CNI, Passeport, Permis de conduire) dans le cas d’une location. Dans le cadre de la vérification de l’identité du locataire, DINSS pourrait être emmené à demander à ce dernier de fournir des informations supplémentaires dépendamment de la raison pour laquelle son identité n’a pas pu être vérifiée. Par exemple, un selfie du locataire avec sa pièce d’identité ou son permis de conduire, une autre copie de sa carte nationale d’identité ou de son passeport, un justificatif de domicile. Conformément à nos Conditions générales d’utilisation et à notre Politique de Confidentialité, les propriétaires s’engagent à supprimer ces documents d’identité dans un délai de 30 jours à compter de la fin de la location et à ne pas divulguer les Données Personnelles du locataire à des tiers qui n’ont pas qualité pour les recevoir. Dans le cas contraire, des sanctions pénales pourront être appliquées aux dispositions législatives prévues. De même, les documents d’identité de tous les locataires accessibles sur Primecar, par les propriétaires, seront supprimés de la plateforme, par DINSS, dans un délai de 30 jours à compter de la fin de la location.",
					points: [
						"Pour réserver un véhicule - Les données ou informations de paiement du locataire qui sont immédiatement envoyées à notre fournisseur de passerelle de paiement une fois collectées.",
						"Cas d’un paiement bancaire - Le nom et le prénom du titulaire du compte, Données présentes sur la carte de paiement (Numéro de la carte, date d’expiration de la carte, cryptogramme visuel), DINSS ne conservera pas les données figurant sur la carte de paiement à l’exception des quatre (4) derniers chiffres",
						"Cas d’un paiement mobile money - Le nom et le prénom du titulaire du compte mobile money, Le numéro de téléphone, Le code de validation de la transaction, DINSS conservera toutes ces données à l’exception du code de validation. Un numéro de transaction est généré et stocké par DINSS à l’issue du chaque paiement d’une location.",
						"Pour vérifier l’identité du locataire",
						"Cas d’une location avec Chauffeur - Une photo d’identité, Une copie recto/verso de la pièce d’identité (Attestation, CNI, Passeport)",
						"Cas d’une location sans Chauffeur - Une photo d’identité, Une copie recto/verso du permis de conduire valide sur le territoire de location",
					],
				},
				{
					title: "Visite de la plateforme",
					description:
						"DINSS peut collecter des données personnelles lorsque les utilisateurs naviguent sur la plateforme.",
					points: [
						"DINSS peut collecter des données personnelles lorsque les utilisateurs naviguent sur la plateforme. Les données relatives aux évènements provenant des appareils utilisés. Par exemple : les incidents, la date et l’heure des requêtes, les URLs.",
						"Données de l’application - Identifiant de l’utilisateur, Identifiant de l’appareil sous Android ou IOS, Code pays, Nom et version des systèmes d’exploitation, Adresse IP",
					],
				},
				{
					title: "Newsletter et courriels",
					description:
						"DINSS octroie la possibilité aux utilisateurs de s’inscrire ou non à la newsletter de sa plateforme. A cet effet, une case à cocher est disponible après la création d’un compte membre. En cochant celle-ci, l’utilisateur consent avoir accepté de partager son adresse e-mail en ce sens qu’il est d’accord pour recevoir nos newsletters ainsi que les offres de nos partenaires. Il reconnait avoir pris connaissance de notre Politique de Confidentialité. L’utilisateur à la capacité de se désinscrire de la newsletter à partir de n’importe qu’elle newsletter envoyé par les soins de DINSS et qu’il a reçu. Il peut se faire aider par notre centre d’assistance dans ce sens.",
				},
				{
					title: "Réponse à une offre d’emploi",
					description:
						"DINSS collecte les données personnelles des candidats postulant de façon spontanée ou à un poste ouvert. Informations Personnelles:",
					points: [
						"Nom et prénom",
						"Adresse postale",
						"Adresse électronique",
						"Curriculum vitae",
						"Formation professionnelle ou universitaire",
						"Expérience professionnelle",
						"Tout autres informations fournies",
					],
				},
			],
		},
		{
			title: "Pourquoi et sur quelles bases légales utilisons nous vos informations ?",
			description:
				"L’utilisation des données personnelles a pour finalités et bases légales l’utilisation efficiente de notre plateforme de mise en relation entre les propriétaires et les locataires.",
			list: [
				{
					title: "Les intérêts légitimes",
					description:
						"Les utilisateurs sont informés des mouvements opérés sur la plateforme Primecar. Il pourra s’agir de :",
					points: [
						"La nécessité de communiquer sur les modifications apportées dans les présentes CGU ou politique de confidentialité.",
						"Garantir la sécurité et la fiabilité des opérations effectuées sur la plateforme ",
						"Prévenir tous possibles dysfonctionnements et erreurs susceptibles d’affecter la bonne marche des services.",
						"L’application des conditions d’exclusion de la plateforme comme déjà mentionné dans les CGU.",
					],
				},
				{
					title: "Les obligations légales",
					description:
						"DINSS à des obligations légales en vertu des dispositions dont elle est sujette. Ainsi, l’entreprise devra :",
					points: [
						"Satisfaire les demandes officielles des autorités publiques et judiciaires",
						"Satisfaire les exigences liées aux réglementations financières",
						"Être alignée sur les prescriptions légales",
					],
				},
				{
					title: "L’engagement avec les utilisateurs",
					description:
						"DINSS porte un intérêt particulier sur la relation qu’elle entretien avec ses utilisateurs. Dans le but de leur offrir les meilleurs services qui soient, l’entreprise utilise les données à caractère personnel pour faciliter tous les traitements en accord avec sa politique de mise en relation.",
					points: [
						"L’inscription sur la plateforme",
						"La vérification de l’identité des utilisateurs prévue dans les CGU.",
						"La publication d’une annonce de location de véhicule sur Primecar",
						"La gestion des opérations de location de véhicule",
						"L’échange entre les utilisateurs",
						"La gestion des impayés",
						"La gestion des pénalités et des frais",
						"La gestion des sinistres",
						"La communication entre les utilisateurs et DINSS",
					],
				},
				{
					title: "La politique commerciale",
					description:
						"L’utilisation des données personnelles des utilisateurs se fait aussi dans le cadre de :",
					points: [
						"L’application de notre stratégie marketing et communication",
						"La gestion de offres promotionnelles et des codes de parrainage ",
						"Le suivi client",
						"L’amélioration continue de nos services avec notre enquête de satisfaction",
						"L’analyse de données stochastiques ou statistiques",
					],
				},
			],
		},
		{
			title: "Pour combien de temps vos informations sont-elles conservées ?",
			description:
				"Conformément à la réglementation applicable, DINSS conserve les données à caractère personnels de ses utilisateurs pendant une durée de sept (7) ans à compter de la dernière utilisation du compte sauf cas d’exception. Néanmoins, DINSS souhaite mettre en lumière le fait qu’une conservation plus longue de ces données est possible dans certains cas et sous réserve . Qu’il existe une obligation légale à la charge de DINSS de conserver vos données à caractère personnel pendant une durée fixe. Qu’il existe un intérêt administratif comme dans le cas d’un contentieux nécessitant la conservation de vos données personnelles en vertus des prescriptions applicables jusqu’à sa résolution. Qu’il est mis en œuvre à des fins statistiques.",
		},
		{
			title: "Quels sont les droits des utilisateurs et quelles possibilités ont-ils pour l’exercer ?",
			description:
				"Les utilisateurs disposent de plusieurs droits conformément aux dispositions législatives et aux règles applicables, en particulier les articles 28, 29, 30, 31, 32, 33 entre autres de la loi n° 2013-450 du 19 juin 2013 portant une attention particulière à la protection des données à caractère personnel. Tout utilisateur désireux d’exercer l’un des droits susmentionnés pourra nous contacter à l’adresse suivante hello@primecarapp.com en justifiant son identité pendant l’introduction de la demande.",
			list: [
				{
					title: "Le droit à l’information :",
					description:
						"Pour savoir ce que l’on fait avec vos données personnelles à notre disposition ;",
				},
				{
					title: "Le droit d’opposition :",
					description:
						"pour demander la limitation de l’utilisation de vos données personnelles ;",
				},
				{
					title: "Le droit à l’oubli numérique et à l’effacement :",
					description:
						"pour demander la suppression de certaines ou plusieurs de vos données Personnelles ;",
				},
				{
					title: "Le droit d’interrogation :",
					description:
						"pour s’opposer expressément à l’utilisation de vos données personnelles, pour des motifs relatif à votre situation ;",
				},
				{
					title: "Le droit d’accès à l’information :",
					description:
						"Pour accéder aux données personnelles que nous détenons sur vous ;",
				},
				{
					title: "Le droit de rectification :",
					description:
						"pour modifier des données inexactes ou incomplètes vous concernant.",
				},
			],
		},
		{
			title: "Quels sont les sont les mesures de sécurité mis en œuvre pour assurer la protection des données personnelles ?",
			description:
				"L’utilisateur accède à son compte sur la plateforme Primecar au moyen d’un accès direct avec son adresse mail et son mot de passe qu’il a lui-même configuré. Il dispose aussi d’un processus de connexion automatique grâce à un compte Google ou Apple. Quand bien même l’utilisateur utilise ce moyen de connexion, il a toujours la possibilité de changer de mot de passe à souhait. Chaque mot de passe renseigner par l’utilisateur est crypté à l’aide d’algorithme ultra robuste et de pointe. DINSS met en œuvre des mesures éprouvées et appropriées pour protéger les services qu’elle offre contre les malversations externes susceptibles d’occasionner la perte, la modification non autorisée, la destruction ou la diffusion des données personnelles de ses utilisateurs, sans leur consentement, par des personnes qui n’en ont pas l’autorité. Le transfert de données entre la plateforme et le serveur est sécurisé avec le protocole SSL. Les données de paiement du locataire et du propriétaire seront traitées et conservées par les fournisseurs de passerelle de paiement (Paydunya, Cinetpay) dans le stricte respect des mesures de sécurité applicables à l’industrie du commerce électronique. Ainsi les transactions s’effectueront de manière sécurisée et une parties des informations bancaires, laquelle apparaît sur l’écran, sera masquée. Cette sécurité s’appliquera aussi pour les paiements mobiles intégrant des mesures de sécurité intégrées et éprouvée par les opérateurs mobiles money.",
		},
		{
			title: "Comment se fait le transfert des données personnelles ?",
			description:
				"DINSS prend toutes les mesures à sa disposition pour assurer que les données personnelles de ses utilisateurs sont correctement protégées, en vertu des réglementations et des législations relatives à la protection de données. Cette précaution est une obligation pour nous avant tout transfert de données. Nous portons les mêmes exigences de respect de notre Politique de Confidentialité auprès de nos prestataires de services qui interviennent en tant que sous-traitant. L’utilisateur reconnait avoir été informé et accepte que ses données personnelles soient communiquées à des prestataires dans le strict respect des règles et législations en aaplicables. Il est à noter que toutes les données de vérifications sont stockées dans les serveurs situés en Europe et gérés par la compagnie Amazon Web Services. L’utilisateur faisant usage des données personnelles à des finalités autres que celles prévues par les présentes règles est passible d’être exclu de la plateforme Primecar de plein droit par DINSS, sans préjudice de droit qui pourrait lui être imputable et à l’exclusion d’actions intentées en justice à son encontre en vertu des réparations de préjudices supposés subis. Tout utilisateur désireux d’exercer une opposition au traitement de ses données, pourra nous contacter à l’adresse suivante hello@primecarapp.com en justifiant son identité pendant l’introduction de la demande. ",
			list: [
				{
					title: "Conditions d'engagement pour les utilisateurs:",
					description:
						"L’utilisateur s’engage à utiliser exclusivement les données à caractère personnel, disponibles sur Primecar, dans le cadre de nos conditions générales d’utilisation et de notre politique de confidentialité. Ces données peuvent être entre autres :",
					points: [
						"L’identité du locataire et/ou du propriétaire, ",
						"La photo de profil de l’utilisateur",
						"Les pièces d’identité (CNI, Passeport, Permis de conduire, etc.)",
						"Etc.",
					],
				},
			],
		},
		{
			title: "Modification de la Politique de Confidentialité",
			description:
				"DINSS se réserve la possibilité de modifier la présente Politique de Confidentialité en fonction de l'évolution des règles que nous appliquons à la collecte et à la divulgation des données. Sauf indication contraire, toute modification de la présente politique de confidentialité prendra effet dès sa publication. L’utilisateur est tenu de consulter régulièrement cette page pour prendre connaissance des éventuelles modifications ou mises à jour apportées à notre Politique de Confidentialité.",
		},
	];

	return (
		<Layout>
			<Banner
				left="Centre d’aide"
				right="Conditions Générales d'Utilisation"
				center="Confidentialités"
			/>

			<div className="flex flex-col px-5 py-10 md:px-8 md:py-12 lg:px-16 lg:py-14 xl:py-16 xl:px-28 gap-5 md:gap-7 lg:gap-9 xl:gap-12 items-start  xl:justify-center 2xl:w-[1440px] mx-auto">
				{data.map((each, index) => (
					<div key={index} className="flex flex-col gap-3">
						<h2 className="text-lg md:text-xl lg:text-2xl lg:font-semibold text-[#444444] mb-2">
							{each.title}:
						</h2>
						<p className="font-normal text-[#5A5A5A] text-sm lg:text-base">
							{each?.description}
						</p>
						{each?.list?.map((eachL, index) => (
							<div
								key={"list" + index}
								className="flex flex-col gap-1 text-sm"
							>
								<p className="">
									<span className="text-base font-semibold text-[#444444]">
										{eachL.title}:
									</span>{" "}
									{eachL.description}
								</p>
								<div className="flex flex-col gap-1 ml-6">
									{eachL.points?.map((eachP, index) => (
										<li
											key={"point" + index}
											className="list-inside list-disc"
										>
											{eachP}
										</li>
									))}
								</div>
							</div>
						))}
					</div>
				))}
			</div>
		</Layout>
	);
};

export default Privacy;
