import LoaderSpinner from "../../../util/LoaderSpinner"

const UpdateStatusLoader = () => {
    return (
      <div className="p-10">
        <LoaderSpinner type="tableLoad" />
      </div>
    )
  }

  export default UpdateStatusLoader