import React, { useEffect, useState } from "react";
import { MdSearch } from "react-icons/md";
import { useLocation } from "react-router-dom";
import FilterDropdown from "../../../../util/dropdowns/FilterDropdown";
import TransactionList from "./TransactionList";
import { GetCustomeOwnerTrans, GetTopRated, GetTopRatedOwner } from "../../../../lib/Api/RentalAppApi";
import SearchBar from '../../../GenericComponents/SearchBar'
const TransactionTable = ({ title }) => {
  const location = useLocation().pathname;
  const [loading, setLoading] = useState(false)
  const [totalPage, setTotalPage] = useState()
  const [topRated, setTopRated] = useState([])
  const [transaction, setTransaction] = useState([])


  let fetchTop = async () => {
    setLoading(true)

    let { res } = await GetTopRated()
    if (res) {
      setLoading(false)
      setTopRated(res)
    }
  }


  let fetchTopOwner = async () => {
    setLoading(true)
    
    let { res } = await GetTopRatedOwner()
    if (res) {
      setLoading(false)
      setTopRated(res)
    }
  }


  let fetchTrans = async () => {
    setLoading(true)
    let { res , page } = await GetCustomeOwnerTrans()
    if (res) {
      setLoading(false)
      setTotalPage(page)
      setTransaction(res.data)
    }
  }


  useEffect(() => {
    if (location === "/dinss/tech/globelOwner") {
      fetchTopOwner()
    }
    else {
      fetchTop()
    }

    fetchTrans()
  }, [location])




  // let checkType = location === "/dinss/tech/ticket" ? " Support Tickets" : "Reports"

  return (
    <>
      <div className="">
        {title === "Transaction" ? (
          <div className="flex lg:flex-nowrap flex-wrap justify-between items-center pb-5">
            <h2 className="text-[20px] font-semibold leading-[20.16px]  text-[#444444]">
              Transaction
            </h2>
            <div className="flex tablet:justify-between tablet:w-full lg:py-0 py-2  xtra-small:block xxtra-small:block xtra-small:w-full xxtra-small:w-full">
              <SearchBar/>
              <div className="ml-4 xtra-small:ml-0 xxtra-small:ml-0 xxtra-small:mt-2 xtra-small:mt-2">
                <div className="text-center w-full flex justify-end xtra-small:justify-start xxtra-small:justify-start ">
                  <div className="dropdown xxtra-small:w-full xtra-small:w-full relative">
                    <FilterDropdown />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <h2 className="text-[20px] font-semibold leading-[20.16px] p-5 text-[#FF8C00]">
            {location === "/dinss/tech/globelRenter"
              ? "Top Highly Ranked  Renters"
              : "Top Highly Ranked Owners"}
          </h2>
        )}
        <div>
          <TransactionList title={title} loading={loading} topRated={topRated} totalPage={totalPage} transaction={transaction} />
        </div>
      </div>
    </>
  );
};

export default TransactionTable;
