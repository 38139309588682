import React, { useState, useRef, useEffect } from "react";
import Layout from "../../components/layouts/mainLayout/MainLayout";
import BreadCums from "../../components/MainLandingComp/breadCums/BreadCums";
import Left from "../../components/MainLandingComp/search/Left";
import Right from "../../components/MainLandingComp/search/Right";
import { FiFilter } from "react-icons/fi";
import Sidebar from "../../components/MainLandingComp/search/Sidebar";
import { AiFillCalendar } from "react-icons/ai";
import { MdLocationOn } from "react-icons/md";
import SearchLoader from "../../components/MainLandingComp/search/SearchLoader";
import DetailPopup from "../../util/popup/Popup";
import Detail from "./Detail";
import PlaceAutocomplete from "../../Partials/PlaceAutocomplete";
import moment from "moment";
import { DayPicker } from "react-day-picker";
import { fr } from "date-fns/locale";
import { times } from "../../Partials/TimeSlotsHalfHourInterval";
import { useSelector } from "react-redux";
import { GetFilter } from "../../lib/Api/WebsiteApi";
import LoaderSpinner from "../../util/LoaderSpinner";
import DateTimeModal from "../../components/MainLandingComp/search/DateTimeModal";
const Search = () => {
  const searchInfo = useSelector((state) => state.userAuth?.searchInfo);

  const datePickerRef = useRef(null);
  const datePicker2Ref = useRef(null);
  const timePickerRef = useRef(null);
  const timePicker2Ref = useRef(null);

  // const myDate = `${new Date().getMonth()}/${new Date().getDay()}/${new Date().getFullYear()}`;
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);
  // const myTime = `${new Date().getHours()}:${new Date().getMinutes()}`;
  const currentTime = moment();
  // Add 10 minutes to the current time
  const newTime = currentTime.add(30, "minutes");
  // Round to the nearest 30 minutes
  const roundedTime = moment(newTime)
    .startOf("hour")
    .add(Math.round(newTime.minute() / 30) * 30, "minutes");
  // Format the rounded time
  const formattedTime = roundedTime.format("hh:mm");
  const [selected, setSelected] = useState(today);
  const [selected2, setSelected2] = useState(tomorrow);
  const [dateOne, setDateOne] = useState("");
  const [dateOriginalOne, setDateOriginalOne] = useState("");
  const [dateOriginalTwo, setDateOriginalTwo] = useState("");
  const [dateTwo, setDateTwo] = useState("");
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showDatePicker2, setShowDatePicker2] = useState(false);
  const [showTimePicker, setShowTimePicker] = useState(false);
  const [showTimePicker2, setShowTimePicker2] = useState(false);
  const [timeValue, setTimeValue] = useState(formattedTime);
  const [timeValue2, setTimeValue2] = useState(formattedTime);
  // const [locate, setLocate] = useState("")
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  // const [place, setPlace] = useState(null);
  const [defaultAddress, setDefaultAdress] = useState("");
  const [filter, setFilter] = useState(false);
  const [loading, setLoading] = useState(true);
  const [cars, setCars] = useState([]);
  const [convertedTime1, setConvertedTime1] = useState(searchInfo?.start_time);
  const [convertedTime2, setConvertedTime2] = useState(searchInfo?.end_time);
  const [pagination, setPagination] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [detailpage, setDetailPage] = useState(false);
  const [carDetail, setCarDetail] = useState({});
  const [priceType, setPriceType] = useState("Grand Abidjan");
  const [loadingCars, setLoadingCars] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [showMobileModalsInputs, setShowMobileModalsInputs] = useState(false);
  const [payload, setPayload] = useState();

  const [selectedDateOne, setSelectedDateOne] = useState("");
  const [selectedDateTwo, setSelectedDateTwo] = useState("");


  const convertTo24HourFormat2 = (value, type) => {
    // Parse the input time using moment
    const originalTime = moment(value.time, `hh:mm`);
    let minutesToAdd = 30;
    // Add 30 minutes to the original time
    const newTime = originalTime.add(minutesToAdd, "minutes");
    const convertedTime1 = newTime.format("hh:mm");
    if (type === "first") {
      setTimeValue(convertedTime1);
      setConvertedTime1(convertedTime1);
    } else {
      setTimeValue2(convertedTime1);
      setConvertedTime2(convertedTime1);
      FilterCar();
    }
  };

  useEffect(() => {
    const updateDateFormats = () => {
      if (window.innerWidth < 768) {
        setShowMobileModalsInputs(true);
      } else {
        setShowMobileModalsInputs(false);
      }
      moment.locale("fr");
      const formattedDate = moment(new Date(selectedDateOne)).format(
        window.innerWidth < 768 ? "D MMM" : "ddd D MMM"
      );
      const formattedDate2 = moment(new Date(selectedDateTwo)).format(
        window.innerWidth < 768 ? "D MMM" : "ddd D MMM"
      );
      setDateOriginalOne(formattedDate);
      setDateOriginalTwo(formattedDate2);
    };

    // Call the function on mount and window resize
    updateDateFormats();

    const handleResize = () => {
      updateDateFormats();
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [selectedDateOne, selectedDateTwo]);

  let pyaload = {
    longitude: searchInfo?.longitude || longitude,
    latitude: searchInfo?.latitude || latitude,
    start_date: dateOne,
    start_time: searchInfo?.start_time
      ? convertedTime1
      : searchInfo?.defaultTime,
    end_date: dateTwo,
    end_time: searchInfo?.end_time ? convertedTime2 : searchInfo?.defaultTime,
    location: "in_abidjan",
  };

  const toggleDatePicker = () => {
    if (showMobileModalsInputs) {
      setModalOpen(true);
    }
    setShowDatePicker(true);
    setShowDatePicker2(false);
    setShowTimePicker(false);
    setShowTimePicker2(false);
  };

  const handleDaySelect = (date) => {
    const formattedDate = moment(date).format("ddd D MMM");
    const parsedDate = moment(date, "DD/M/YYYY");
    const formattedDate2 = parsedDate.format(
      "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
    );
    const todayLatest = new Date(formattedDate2);
    setSelected(todayLatest);
    setSelectedDateOne(parsedDate);
    setDateOriginalOne(formattedDate);
    setDateOne(moment(date).format("DD/MM/YYYY"));
    setShowDatePicker(false);
    setShowTimePicker(true);
  };

  const handleTime = (value, type) => {
    if (type === "first") {
      convertTo24HourFormat2(value, "first");
      if (!showMobileModalsInputs) {
        setShowTimePicker(false);
        setShowDatePicker(false);
        toggleDatePicker2();
      } else {
        const modal = document.getElementById("modal");
        if (modal) {
          modal.classList.add("animation-slideDown");
          setTimeout(() => {
            toggleDatePicker2();
            setModalOpen(false);
            modal.classList.remove("animation-slideDown");
          }, 300); // Use the same duration as your animation
        }
      }
    } else {
      if (!showMobileModalsInputs) {
        setShowTimePicker2(false);
      } else {
        closeModal();
      }
      convertTo24HourFormat2(value, "second");
    }
  };

  const toggleDatePicker2 = () => {
    if (showMobileModalsInputs) {
      setModalOpen2(true);
    }
    setShowDatePicker(false);
    setShowDatePicker2(true);
    setShowTimePicker2(false);
    setShowTimePicker(false);
  };

  const handleDaySelect2 = (date) => {
    // moment.locale("fr");
    const formattedDate = moment(date).format("ddd D MMM");
    const parsedDate = moment(date, "DD/M/YYYY");
    const formattedDate2 = parsedDate.format(
      "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
    );
    const todayLatest = new Date(formattedDate2);
    setSelected2(todayLatest);
    setSelectedDateTwo(parsedDate);
    setDateOriginalTwo(formattedDate);
    setDateTwo(moment(date).format("DD/MM/YYYY"));
    setShowDatePicker2(false);
    setShowTimePicker2(true);
  };

  const handleOutsideClick = () => {
    if (showDatePicker === true) {
      setShowDatePicker(false);
    } else if (showTimePicker === true) {
      setShowTimePicker(false);
    } else {
      setShowTimePicker2(false);
    }
  };

  const isDateDisabled = (date) => {
    moment.locale("en");
    const parsedDate = moment(selected, "DD/M/YYYY");
    const formattedDate = parsedDate.format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
    const today = new Date(formattedDate);
    return date < today;
  };

  const isDateDisabledTomorrow = (date) => {
    moment.locale("en");
    const parsedDate = moment(selected2, "DD/M/YYYY");
    const formattedDate = parsedDate.format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
    const today = new Date(formattedDate);
    let dd = searchInfo ? today : tomorrow;
    return date < dd;
  };

  const handleDetail = (item) => {
    setDetailPage(true);
    setCarDetail(item);
  };

  const handleDate = (date) => {
    const parsedDate = moment(date, "DD/MM/YYYY");
    const formattedDate = parsedDate.format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
    const dateTwo = new Date(formattedDate);
    return dateTwo;
  };

  useEffect(() => {
    moment.locale("en");
    const parsedDate1 = moment(searchInfo?.start_date, "DD/MM/YYYY");
    const formattedDate1 = parsedDate1.format("ddd D MMM");
    const parsedDate2 = moment(searchInfo?.end_date, "DD/MM/YYYY");
    const formattedDate2 = parsedDate2.format("ddd D MMM");
    setTimeValue(searchInfo?.start_time.time || searchInfo?.start_time);
    setDateOriginalOne(formattedDate1);
    setSelectedDateOne(parsedDate1);
    setSelectedDateTwo(parsedDate2);
    setDateOriginalTwo(formattedDate2);
    setDateOne(searchInfo?.start_date);
    setDateTwo(searchInfo?.end_date);
    let Startvalue = handleDate(searchInfo?.start_date);
    let Endvalue = handleDate(searchInfo?.end_date);
    setSelected(Startvalue);
    setSelected2(Endvalue);
    setTimeValue2(searchInfo?.end_time.time || searchInfo?.end_time);
    setDefaultAdress(searchInfo?.address);
    let payload = {
      longitude: searchInfo?.longitude,
      latitude: searchInfo?.latitude,
      start_date: searchInfo?.start_date,
      start_time: searchInfo?.start_time
        ? searchInfo?.start_time
        : searchInfo?.defaultTime,
      end_date: searchInfo?.end_date,
      end_time: searchInfo?.end_time
        ? searchInfo?.end_time
        : searchInfo?.defaultTime,
      user_id: "",
      location: "in_abidjan",
    };
    setPayload(payload);

    let fetch = async () => {
      let { res } = await GetFilter(payload);
      if (res) {
        setLoading(false);
        setCars(res.vehicles);
        setPagination(res?.next_page);
        setTotalPage(res?.total);
      }
    };
    fetch();
  }, []);

  const FilterCar = async () => {
    let payload = {
      longitude: longitude ?? searchInfo?.longitude,
      latitude: latitude ?? searchInfo?.latitude,
      start_date: dateOne,
      start_time: timeValue,
      end_date: dateTwo,
      end_time: timeValue2,
      user_id: "",
      location: "in_abidjan",
    };
    setLoadingCars(true);
    let { res } = await GetFilter(payload);
    if (res) {
      setLoadingCars(false);
      setCars(res.vehicles);
      setPagination(res?.next_page);
    }
  };

  const closeModal = () => {
    const modal = document.getElementById("modal");
    if (modal) {
      modal.classList.add("animation-slideDown");
      setTimeout(() => {
        setModalOpen(false);
        setModalOpen2(false);
        setShowDatePicker(false);
        setShowTimePicker(false);
        setShowDatePicker2(false);
        setShowTimePicker2(false);
        modal.classList.remove("animation-slideDown");
      }, 300); // Use the same duration as your animation
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setShowDatePicker(false);
      } else if (
        datePicker2Ref.current &&
        !datePicker2Ref.current.contains(event.target)
      ) {
        setShowDatePicker2(false);
      } else if (
        timePickerRef.current &&
        !timePickerRef.current.contains(event.target)
      ) {
        setShowTimePicker(false);
      } else if (
        timePicker2Ref.current &&
        !timePicker2Ref.current.contains(event.target)
      ) {
        setShowTimePicker2(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if ((longitude, latitude)) {
      FilterCar();
    }
  }, [longitude, latitude]);

  return (
    <>
      {detailpage && (
        <div id="search_page">
          {" "}
          <DetailPopup
            type="detail"
            modalOpen={detailpage}
            data={
              <Detail
                priceType={priceType}
                carDetail={carDetail}
                onClose={() => setDetailPage(false)}
              />
            }
            onClose={() => setDetailPage(false)}
          />{" "}
        </div>
      )}
      <Layout>
        {loading ? (
          <SearchLoader />
        ) : (
          <>
            {filter && (
              <Sidebar
                payload={pyaload}
                setCars={setCars}
                setPagination={setPagination}
                sidebarOpen={filter}
                setSidebarOpen={setFilter}
                priceType={priceType}
                setPriceType={setPriceType}
                setLoadingCars={setLoadingCars}
              />
            )}
            <div className="px-5 sm:px-10 md:px-20 lg:pt-[24px] 2xl:px-0 pt-[16px] search_page_main_section">
              <div className="flex">
                <div className="flex justify-between  ">
                  <BreadCums
                    left="Accueil"
                    middle=""
                    right="Recherche"
                    leftLink="/"
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 rounded-lg gap-[16px] sticky top-0">
                <div className="flex gap-[16px] ">
                  <div className="border flex items-center w-full bg-white rounded-md px-[12px] py-[10px]">
                    <MdLocationOn className="text-[#FF8C00] lg:text-[30px] text-[22px]" />
                    <PlaceAutocomplete
                      setLatitude={setLatitude}
                      setLongitude={setLongitude}
                      defaultAddress={defaultAddress}
                      setDefaultAdress={setDefaultAdress}
                    />
                  </div>

                  <div className="md:hidden ">
                    <div className="rounded-md bg-[#FF8C00] p-[12px] flex justify-center items-center">
                      <div onClick={() => setFilter(!filter)} className="">
                        <FiFilter className="text-[19px] text-white" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-[16px]  ">
                  <div className="w-full py-[10px] border flex items-center  bg-white rounded-md xl:px-2 ">
                    <AiFillCalendar className="text-[#FF8C00] lg:text-[30px] text-[22px] mx-2" />
                    <div className="flex justify-center relative items-center">
                      <div
                        onClick={toggleDatePicker}
                        className="w-full placeholder:text-[#606060] font-family-open-sans font-weight-400 text-[#444444] text-[16px] font-semibold cursor-pointer  xl:bg-transparent bg-[#F4F4F4]  xl:px-0 px-2  h-full focus:outline-none dateTime_show_input"
                      >
                        {dateOriginalOne
                          ? `${dateOriginalOne} ${timeValue}`
                          : "Tue 15 Feb, 09:00"}
                      </div>

                      {showMobileModalsInputs && (
                        <div
                          ref={showDatePicker ? datePickerRef : timePickerRef}
                        >
                          <DateTimeModal
                            selected={selected}
                            disabled={isDateDisabled}
                            onSelect={handleDaySelect}
                            locale={fr}
                            showDatePicker={showDatePicker}
                            closeModal={closeModal}
                            showTimePicker={showTimePicker}
                            times={times}
                            handleTime={handleTime}
                            modalOpen={modalOpen}
                            type={modalOpen ? "first" : null}
                            heading="Début"
                            isModal1={true}
                          />
                        </div>
                      )}

                      {showDatePicker && !showMobileModalsInputs && (
                        <div
                          ref={datePickerRef}
                          className="bg-white shadow-md absolute z-40 cstmPosTop data_pick "
                        >
                          <DayPicker
                            mode="single"
                            selected={selected}
                            disabled={isDateDisabled}
                            onSelect={handleDaySelect}
                            locale={fr}
                          />
                        </div>
                      )}
                      {showTimePicker && !showMobileModalsInputs && (
                        <div
                          ref={timePickerRef}
                          className="bg-white shadow-md lg:w-auto w-full absolute z-40 timPick cstmPosTop right-0 rounded px-5 py-4"
                        >
                          <div className="max-h-[250px] lg:w-[80px]  overflow-y-auto text-enter time_scroll">
                            {times?.map((time, index) => (
                              <p
                                key={index}
                                onClick={() => handleTime(time, "first")}
                                className=" lg:max-w-[120px] text-center w-full text-[15px] font-sans hover:bg-gray-200 cursor-pointer font-normal"
                              >
                                {time?.time}
                              </p>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="w-full py-[10px] border flex items-center bg-white rounded-md xl:px-2 ">
                    <AiFillCalendar className="text-[#FF8C00] lg:text-[30px] text-[22px] mx-2" />
                    <div className="flex justify-center relative items-center">
                      <div
                        onClick={toggleDatePicker2}
                        className="w-full  placeholder:text-[#606060] font-family-open-sans font-weight-400 text-[#444444] text-[16px] font-semibold cursor-pointer  xl:bg-transparent bg-[#F4F4F4]  xl:px-0 px-2  h-full focus:outline-none dateTime_show_input"
                      >
                        {dateOriginalTwo
                          ? `${dateOriginalTwo} ${timeValue2}`
                          : "Tue 15 Feb, 09:00"}
                      </div>
                      {showMobileModalsInputs && (
                        <div
                          ref={
                            showTimePicker2 ? datePicker2Ref : timePicker2Ref
                          }
                        >
                          <DateTimeModal
                            selected={selected2}
                            disabled={isDateDisabledTomorrow}
                            onSelect={handleDaySelect2}
                            locale={fr}
                            showDatePicker={showDatePicker2}
                            closeModal={closeModal}
                            showTimePicker={showTimePicker2}
                            times={times}
                            handleTime={handleTime}
                            modalOpen={modalOpen2}
                            type={modalOpen2 ? "second" : null}
                            heading="Fin"
                          />
                        </div>
                      )}

                      {showDatePicker2 && !showMobileModalsInputs && (
                        <div
                          ref={datePicker2Ref}
                          className="bg-white shadow-md absolute z-40 cstmPosTop  data_pick2 text-[#667085]"
                        >
                          <DayPicker
                            mode="single"
                            selected={selected2}
                            disabled={isDateDisabledTomorrow}
                            onSelect={handleDaySelect2}
                            locale={fr}
                          />
                        </div>
                      )}
                      {showTimePicker2 && !showMobileModalsInputs && (
                        <div
                          ref={timePicker2Ref}
                          className="bg-white shadow-md absolute z-40 lg:w-auto w-full timPick cstmPosTop right-0 rounded px-5 py-4"
                        >
                          <div className="max-h-[250px] lg:w-[80px] w-full  overflow-y-auto text-enter time_scroll">
                            {times?.map((time) => (
                              <p
                                onClick={() => handleTime(time, "second")}
                                className=" lg:max-w-[120px] w-full text-[15px] text-[#667085] font-sans hover:bg-gray-200 cursor-pointer font-normal"
                              >
                                {time?.time}
                              </p>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap h-full mt-[24px]">
                <div
                  className={` ${
                    filter
                      ? "md:hidden tablet:hidden xtra-small:hidden hidden"
                      : "lg:block md:block hidden"
                  } ${
                    cars.length === 1 && "border-r"
                  }  lg:w-[30%] md:w-[30%] w-full h-full`}
                >
                  <Left
                    payload={pyaload}
                    setPagination={setPagination}
                    setCars={setCars}
                    priceType={priceType}
                    setPriceType={setPriceType}
                    setLoadingCars={setLoadingCars}
                  />
                </div>
                <div className={`${cars.length > 1} w-full`}>
                  {loadingCars ? (
                    <div className="py-20 flex items-center justify-center">
                      <LoaderSpinner type="tableLoad" />
                    </div>
                  ) : (
                    <Right
                      setPagination={setPagination}
                      setDetailPage={handleDetail}
                      total={totalPage}
                      pagination={pagination}
                      setCars={setCars}
                      cars={cars}
                      priceType={priceType}
                    />
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </Layout>
    </>
  );
};

export default Search;
