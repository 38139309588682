import React, { useEffect, useState } from "react";
import close from "../../../../../assests/svg/cancel-icon.svg";
import Popup from "../../../../../util/popup/Popup";
import CancelBooking from "./CancelBooking";
import { useParams } from "react-router-dom";
import { GetBookingMangDetail } from "../../../../../lib/Api/Management";
import moment from "moment";
import LoaderSpinner from "../../../../../util/LoaderSpinner";
import { BsThreeDotsVertical } from "react-icons/bs";
const BookingDetail = () => {
  const [active, setActive] = useState(0);
  const [show, setShow] = useState(false);
  const [searchData, setSearchData] = useState("");
  const [loading, setLoading] = useState(false);
  const [singleRent, setSingleRent] = useState({});

  const id = useParams().id;

  let list = [
    { heading: "Booking ID", title: singleRent?.id },
    { heading: "Transaction ID", title: singleRent?.payment_token },
    { heading: "Booking Status ", status: singleRent?.status_object },
    { heading: "Refund Status", title: "N/A" },
    {
      heading: "Vehicle Name",
      title: `${singleRent?.vehicle?.make}-${singleRent?.vehicle?.model}`,
    },
    { heading: "Trip Duration", title: singleRent?.total_days },
    {
      heading: "Starting Date",
      title: `${moment(singleRent?.start_date).format("ll")} ${singleRent?.start_time}`,
    },
    {
      heading: "Ending Date",
      title: `${moment(singleRent?.end_date).format("ll")} ${singleRent?.end_time}`,
    },

    { heading: "Renter Name", title: singleRent?.user?.first_name },
    { heading: "Renter Email ", title: singleRent?.user?.email },
    { heading: "Service Fee", title: active === 0 ? singleRent?.commission_from_owner : singleRent?.primecar_commission },
    { heading: "Penalties ", title: singleRent?.penalty },
  ];

  const handleShow = () => {
    setShow(true)
    setSearchData("cancel")
  }

  useEffect(() => {
    let fetch = async () => {
      setLoading(true);
      let { res } = await GetBookingMangDetail(id);
      if (res) {
        setLoading(false);
        setSingleRent(res);
      }
    };
    if (searchData === "") {
      fetch();
    }
  }, [id, searchData]);

  return (
    <>
      {show && (
        <Popup
          modalOpen={show}
          data={<CancelBooking rentId={id} setSearchData={setSearchData} setShow={(val) => setShow(val)} />}
          onClose={() => setShow(false)}
        />
      )}
      <div className="bg-white px-5 pb-5 shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] border-0 rounded-md">
        <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center py-5">
          <div
            onClick={() => setActive(0)}
            className={`${active === 0
              ? "bg-[#FF8C00] text-white"
              : "bg-[#EAEAEA] text-[#8E8E8E]"
              } py-3 px-5 cursor-pointer text-[14px] leading-[16px]`}
          >
            <h2>Vehicle Owner</h2>
          </div>
          <div
            onClick={() => setActive(1)}
            className={`${active === 1
              ? "bg-[#FF8C00] text-white"
              : "bg-[#EAEAEA] text-[#8E8E8E]"
              } py-3 px-5 cursor-pointer text-[14px] leading-[16px]`}
          >
            <h2>Vehicle Renter</h2>
          </div>
        </div>
        {loading ? (
          <LoaderSpinner type="tableLoad" />
        ) : (
          <>
            <div className="flex justify-between items-center">
              <h2 className="text-[16px] font-semibold">Booking Details</h2>
              {singleRent?.status_object?.text_en !== "Canceled" &&
                <div class="group inline-block">
                  <button class="outline-none focus:outline-none  px-3 py-1 bg-white rounded-sm flex items-center">
                    <BsThreeDotsVertical className="text-[20px] text-[#898989]" />
                  </button>
                  <ul
                    class="bg-white shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] rounded-md border-0  transform p-4 scale-0 group-hover:scale-100 absolute 
  transition duration-150 ease-in-out right-7 origin-top lg:w-[14%]  "
                  >
                    <li
                      onClick={() => handleShow()}
                      className="rounded-sm cursor-pointer actions flex items-center px-2 py-2 hover:bg-gray-100"
                    >
                      <div className="flex justify-center items-center">
                        <img src={close} alt="close" className="" />
                      </div>
                      <h2 className="ml-2.5 text-[14px]">Cancel Booking</h2>
                    </li>
                  </ul>
                </div>
              }
            </div>
            <div className="grid lg:grid-cols-4 grid-cols-2 gap-5 pt-6">
              {list.map((item, i) => (
                <div className="flex flex-col" key={i}>
                  <h2 className="text-[14px] font-medium text-[#444444] pb-5 ">
                    {item?.heading}
                  </h2>
                  {item?.status && (
                    <div className="text-center inline-block py-[3px] w-fit px-2.5 text-[12px] leading-4  rounded-full "
                      style={{
                        color: `${item?.status.text_color}`,
                        backgroundColor: `${item?.status.background_color}`,
                      }}
                    >
                      {item?.status.text_en}
                    </div>
                    // <p className="text-[12px] bg-[#FFECD6] py-[2px] text-[#FF8C00] rounded-full w-fit px-2.5">
                    //   {item?.status}
                    // </p>
                  )}

                  <p className="text-[14px] text-[#898989] ">{item?.title}</p>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default BookingDetail;
