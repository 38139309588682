import React, { useState } from "react";
// import { MdSearch } from "react-icons/md";
import DataList from "./DataList";
import BookingDetail from "./BookingDetail";
import SearchBar from "../../../../GenericComponents/SearchBar";
import { useEffect } from "react";
import { GetBookingMang, GetCanceledBookingMang } from "../../../../../lib/Api/Management";

const DataTable = ({ title }) => {
  const [active, setActive] = useState(0);
  const [detail, setDetail] = useState(false);
  const [rents, setRents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPage, setTotalPage] = useState();
  const [offsetPage, setOffsetPage] = useState(1);
  const [searchData, setSearchData] = useState("");
  const [types, setTypes] = useState(false);
  const [statusCheck, setStatusCheck] = useState("");
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  let fetch = async () => {
    setLoading(true);
    let check = active === 0 ? GetBookingMang(offsetPage) : GetCanceledBookingMang(offsetPage);
    let { res, page } = await check
    if (res) {
      setLoading(false);
      setRents(res.data);
      setTotalPage(page);
    }
  };

  useEffect(() => {
    if (searchData === "") {
      fetch();
    }
  }, [searchData, active]);

  return (
    <>
      {detail === false ? (
        <>
          <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center py-5">
            <div
              onClick={() => setActive(0)}
              className={`${active === 0
                ? "bg-[#FF8C00] text-white"
                : "bg-[#EAEAEA] text-[#8E8E8E]"
                } py-3 px-[34px] cursor-pointer text-[14px] leading-[16px]`}
            >
              <h2>Booking Management</h2>
            </div>
            <div
              onClick={() => setActive(1)}
              className={`${active === 1
                ? "bg-[#FF8C00] text-white"
                : "bg-[#EAEAEA] text-[#8E8E8E]"
                } text-[14px] lg:my-0 md:my-0 my-2 py-3 px-[34px] leading-[16px] cursor-pointer`}
            >
              <h2>Booking Management Cancelled</h2>
            </div>
          </div>

          <div className="flex lg:flex-nowrap flex-wrap justify-between items-center">
            <h2 className="text-[20px] leading-[20.16px] font-semibold text-[#444444]">
              Booking Management
            </h2>
            <div className="flex lg:py-0 py-2">
              <SearchBar
                offsetPage={offsetPage}
                setSearchData={setSearchData}
                setVehicalList={setRents}
                setLoading={setLoading}
                setTypes={setTypes}
                setTotalPages={setTotalPage}
                title={title}
                setStatusCheck={setStatusCheck}
                statusCheck={statusCheck}
              />
            </div>
          </div>
          <div>
            <DataList
              rents={rents}
              active={active}
              setDetail={setDetail}
              setRents={setRents}
              setSearchData={setSearchData}
              setOffsetPage={setOffsetPage}
              setCurrentPageIndex={setCurrentPageIndex}
              currentPageIndex={currentPageIndex}
              searchData={searchData}
              totalPage={totalPage}
              loading={loading}
              setLoading={setLoading}
            />
          </div>
        </>
      ) : (
        <BookingDetail />
      )}
    </>
  );
};

export default DataTable;
