export const times = [
  { time: "00:30", zone: "AM" },
  { time: "01:00", zone: "AM" },
  { time: "01:30", zone: "AM" },
  { time: "02:00", zone: "AM" },
  { time: "02:30", zone: "AM" },
  { time: "03:00", zone: "AM" },
  { time: "03:30", zone: "AM" },
  { time: "04:00", zone: "AM" },
  { time: "04:30", zone: "AM" },
  { time: "05:00", zone: "AM" },
  { time: "05:30", zone: "AM" },
  { time: "06:00", zone: "AM" },
  { time: "06:30", zone: "AM" },
  { time: "07:00", zone: "AM" },
  { time: "07:30", zone: "AM" },
  { time: "08:00", zone: "AM" },
  { time: "08:30", zone: "AM" },
  { time: "09:00", zone: "AM" },
  { time: "09:30", zone: "AM" },
  { time: "10:00", zone: "AM" },
  { time: "10:30", zone: "AM" },
  { time: "11:00", zone: "AM" },
  { time: "11:30", zone: "AM" },
  { time: "12:00", zone: "PM" },
  { time: "12:30", zone: "PM" },
  { time: "13:00", zone: "PM" },
  { time: "13:30", zone: "PM" },
  { time: "14:00", zone: "PM" },
  { time: "14:30", zone: "PM" },
  { time: "15:00", zone: "PM" },
  { time: "15:30", zone: "PM" },
  { time: "16:00", zone: "PM" },
  { time: "16:30", zone: "PM" },
  { time: "17:00", zone: "PM" },
  { time: "17:30", zone: "PM" },
  { time: "18:00", zone: "PM" },
  { time: "18:30", zone: "PM" },
  { time: "19:00", zone: "PM" },
  { time: "19:30", zone: "PM" },
  { time: "20:00", zone: "PM" },
  { time: "20:30", zone: "PM" },
  { time: "21:00", zone: "PM" },
  { time: "21:30", zone: "PM" },
  { time: "22:00", zone: "PM" },
  { time: "22:30", zone: "PM" },
  { time: "23:00", zone: "PM" },
  { time: "23:30", zone: "PM" },
];
