import React, { useState } from "react";
// import rent from '../../../assests/rent.png'
import { BsThreeDotsVertical } from "react-icons/bs";
import ReactPaginate from "react-paginate";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
// import GenericDrodown from '../../../util/dropdowns/GenericDropdown';
import Popup from "../../../../util/popup/Popup";
import DeleteData from "../../marketing/DeleteData";
import {
  // ChangeRentStatus,
  ChangeVehicalStatus,
  // ChangeVehicalStatus,
  GetVihicalsBaseOnPages,
} from "../../../../lib/Api/Vehical";
import { Truncate } from "../../../../util/TrucateString";
import LoaderSpinner from "../../../../util/LoaderSpinner";
import { useLocation, useNavigate } from "react-router-dom";
import EyeIcon from "../../../../assests/EyeIcon";
import RefreshIcon from "../../../../assests/RefreshIcon";
import TrashIcon from "../../../../assests/TrashIcon";
// import EditData from "../../manageUser/EditData";
// import moment from "moment";
import UpdateStatusLoader from "../../statusLoaderSpinner/StatusLoader";
import { FormatePrice } from "../../../../Partials/FormatePriceSpace";
// import RentalDetail from "../../manageUser/RentalDetail";
const DataList = ({
  // setDetail,
  vehicalList,
  setVehicalList,
  loading,
  setLoading,
  title,
  searchData,
  setOffsetPage,
  setSearchData,
  setTypes,
  currentPageIndex,
  setCurrentPageIndex,
  totalPages,
  // totalPages2,
}) => {
  const [show, setShow] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [loadReason, setLoadReason] = useState(false);
  const [type, setType] = useState("");
  const [vehicalStatus, setVehicalStatus] = useState(null);
  const [singleVehical, setSingleVehical] = useState({});
  // const [vehicalList, setVehicalList] = useState([])
  const location = useLocation().pathname;
  const navigate = useNavigate();

  // const items = [];
  // const [itemOffset, setItemOffset] = useState(0);
  const [itemperPage] = useState(10);

  // const endOffset = itemOffset + itemperPage;
  // const currentItems = Array(vehicalList?.total).slice(itemOffset, endOffset);
  const pageCount = Math.ceil(totalPages / itemperPage);

  const handlePageClick = async (event) => {
    // const newOffset = (event.selected * itemperPage) % Array(vehicalList?.total).length;
    // setItemOffset(newOffset);
    //  let pageNo = event.selected === 0  ? 1  : event.selected
    setLoading(true);
    let { res } = await GetVihicalsBaseOnPages(
      event.selected + 1,
      "approved",
      searchData
    );
    let vehicals = Object.assign({}, ...res);
    setLoading(false);
    setVehicalList(vehicals?.vehicles?.data);
    setOffsetPage(event.selected + 1);
    setCurrentPageIndex(event.selected)
    
  };

  const handlePageClick2 = async (event) => {
    // const newOffset = (event.selected * itemperPage) % Array(vehicalList?.total).length;
    // setItemOffset(newOffset);
    //  let pageNo = event.selected === 0  ? 1  : event.selected
    setLoading(true);
    let { res } = await GetVihicalsBaseOnPages(
      event.selected + 1,
      "restricted",
      searchData
    );
    let vehicals = Object.assign({}, ...res);
    setLoading(false);
    setVehicalList(vehicals?.vehicles?.data);
    setCurrentPageIndex(event.selected)

  };

  let Status = [
    { id: 1, Title: "Restricted", color: "#4B3A8E", bgColor: "#F4F1FF" },
    // { id: 2, Title: "Pending", color: "#9A534E", bgColor: "#F0DFD6" },
    // { id: 4, Title: "Approved", color: "#6ABD8B", bgColor: "#ECFFEC" },
    // { id: 4, Title: "Paid", color: "#008E28", bgColor: "#B4DAC8" },
    // { id: 1, Title: "Expired", color: "#4B3A8E", bgColor: "#F4F1FF" },
    // { id: 3, Title: "Cancel", color: "#898989", bgColor: "#EBEBEB" },
  ];
  let statusOwner = [
    { id: 1, Title: "UnRestricted", color: "#4B3A8E", bgColor: "#F4F1FF" },
    // { id: 2, Title: "Pending", color: "#9A534E", bgColor: "#F0DFD6" },
    // { id: 4, Title: "Approved", color: "#6ABD8B", bgColor: "#ECFFEC" },
    // { id: 5, Title: "Rejected", color: "#F04438", bgColor: "#FFE7E7" },
  ];

  const handlePopup = (status, item) => {
    // let checkSt = Status.find((f) => f.Title === status.Title);
    setType("edit");
    setShow(true);
    setVehicalStatus(status?.Title.toLowerCase());
    setSingleVehical(item);
    setTimeout(() => {
      let check = status?.Title?.toLowerCase() === "unrestricted"
      handleTrigger(item, check ? "approved" : status?.Title?.toLowerCase());
    }, 2000);
  };

  const handleTrigger = async (reason, updateStatus) => {
    let payoad = {
      vehicle_id: `${reason?.id || singleVehical?.id}`,
      status: updateStatus,
      reason: reason?.length > 0 ? reason : "",
    };
    setLoadReason(true);
    setSearchData("change Status");
    let { res } = await ChangeVehicalStatus(payoad);
    if (res) {
      setLoadReason(false);
      setSearchData("");
      setType("");
      setShow(false);
      setSingleVehical({})
    }
  };

  const DeleteVehical = (list, type) => {
    setType(type);
    setShow(true);
    setSearchData("delete")
    setSingleVehical(list);
  };

  const handleClosePopup = () => {
    setShow(false);
    setSearchData("")
    setType('')
  };

  return (
    <>
      {show && (
        <Popup
          modalOpen={show}
          data={
            type === "delete" ? (
              <DeleteData
                singleCoupon={singleVehical?.id}
                // setType={setType}
                // setTypes={setTypes}
                callback={handleClosePopup}
                setShow={(val) => setShow(val)}
                type={"vehical"}
              />
            ) : (
              <UpdateStatusLoader />
            )
          }
          onClose={() => setShow(false)}
        />
      )}
      <div>
        {title === "Vehicle Fleet Management" ? (
          <>
            <div className="overflow-x-auto bg-white border border-gray-100 shadow-md rounded-md mt-6 w-full">
              <>
                {vehicalList?.length > 0 ? (
                  <table className="table-auto w-full ">
                    {/* Table header */}

                    <thead className="text-xs font-[500] bg-[#FFF8EF] border-0">
                      <tr className="text-[#626973]">
                        <th className="px-4 py-4 pr-[25px] whitespace-nowrap w-px">
                          <div className="font-[600] text-[14px] text-left">
                            ID
                          </div>
                        </th>
                        <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                          <div className="font-[600] text-[14px] text-left">
                            Name
                          </div>
                        </th>
                        <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                          <div className="font-[600] text-[14px] text-left">
                            Vehicle Type
                          </div>
                        </th>
                        <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                          <div className="font-[600] text-[14px] text-left">
                            City
                          </div>
                        </th>
                        <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                          <div className="font-[600] text-[14px] text-left">
                            Owner Email
                          </div>
                        </th>
                        <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                          <div className="font-[600] text-[14px] text-left">
                            Status
                          </div>
                        </th>
                        <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                          <div className="font-[600] text-[14px] text-left">
                            Daily Rent Cost
                          </div>
                        </th>
                        <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                          <div className="font-[600] text-[14px] text-center">
                            Action{" "}
                          </div>
                        </th>
                      </tr>
                    </thead>

                    {/* Table body */}

                    <tbody className="text-sm ">
                      {!loading ? (
                        vehicalList?.map((item, i) => (
                          <tr className=" cursor-pointer  " key={i}>
                            <td
                              onClick={() => {
                                return navigate(`/dinss/tech/management/detail/${item?.id}`);
                                // setRentalUser(item?.user)
                              }}
                              className="px-2 first:pl-5 flex items-center last:pr-5 py-3 whitespace-nowrap"
                            >
                              <div className="text-left  text-[14px] text-[#898989] px-1">
                                {item?.id}
                              </div>
                            </td>
                            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                              <div className="text-left text-[14px] text-[#898989] px-1">
                                {item?.model}
                              </div>
                            </td>
                            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                              <div className="text-left text-[14px] text-[#898989] px-1">
                                {item?.vehicle_category}
                              </div>
                            </td>
                            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                              <div className="text-left text-[14px] text-[#898989] px-1">
                                {item?.locations[0]?.city}
                              </div>
                            </td>
                            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                              <div className="text-left text-[14px] text-[#898989] px-1">
                                {item?.user?.email}
                              </div>
                            </td>

                            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                              <div
                                style={{
                                  color: `${item?.status_object?.text_color}`,
                                  backgroundColor: `${item?.status_object?.background_color}`,
                                }}
                                className={`text-center py-[3px] w-fit px-2.5 text-[12px] leading-4 rounded-full `}
                              >
                                {item?.status_object?.text_en}
                              </div>
                            </td>
                            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                              <div className="text-left text-[14px] text-[#898989] px-1">
                                {FormatePrice(item?.price_in_abidjan)} FCFA
                              </div>
                            </td>
                            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                              <div className="text-center flex justify-center  px-1">
                                {/* <BsThreeDotsVertical className='text-[20px] text-[#898989]' /> */}
                                {/* <GenericDrodown /> */}

                                <div className="group inline-block">
                                  <button className="outline-none focus:outline-none  px-3 py-1 bg-white rounded-sm flex items-center">
                                    <BsThreeDotsVertical className="text-[20px] text-[#898989]" />
                                  </button>
                                  <ul
                                    className="bg-white shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] border-0 rounded-md p-4 transform scale-0 group-hover:scale-100 absolute 
  transition duration-150 ease-in-out right-7 origin-top xl:w-[15%]  "
                                  >
                                    <li
                                      onClick={() => {
                                        return navigate(
                                          `/dinss/tech/management/detail/${item?.id}`
                                        );
                                        // setRentalUser(item?.user)
                                      }}
                                      className="rounded-sm actions flex items-center px-2 py-2 mb-2 hover:bg-gray-100"
                                    >
                                      <EyeIcon className="text-[14px] text-[#98A2B3]" />
                                      <h2 className="ml-2">View details</h2>
                                    </li>

                                    <li className="rounded-sm actions relative px-2 py-2 mb-2 hover:bg-gray-100">
                                      <button className="w-full flex items-center outline-none focus:outline-none">
                                        <RefreshIcon className=" ml-auto text-[#98A2B3]" />
                                        <span className="pr-1 flex-1 text-left ml-2">
                                          Change Status
                                        </span>
                                        <span className="mr-auto">
                                          <svg
                                            className="fill-current h-4 w-4
            transition duration-150 ease-in-out"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                          >
                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                          </svg>
                                        </span>
                                      </button>
                                      <ul
                                        className="bg-white border border-gray-100 p-2 flex justify-center flex-col items-center rounded-md absolute top-0 right-10 shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)]
  transition duration-150 ease-in-out origin-top-left
  min-w-32
  "
                                      >
                                        {Status.filter(
                                          (f) =>
                                            f.Title !==
                                            item?.status_object?.text_en
                                        ).map((st, i) => (
                                          <li
                                            onClick={() => handlePopup(st, item)}
                                            className="px-2 py-2 text-left mb-2"
                                            key={i}
                                          >
                                            <div
                                              style={{
                                                color: `${st.color}`,
                                                backgroundColor: `${st.bgColor}`,
                                              }}
                                              className={`text-center py-[3px] px-2.5 w-auto text-[12px] rounded-full`}
                                            >
                                              {st.Title}
                                            </div>
                                          </li>
                                        ))}
                                      </ul>
                                    </li>
                                    <li
                                      onClick={() =>
                                        DeleteVehical(item, "delete")
                                      }
                                      className="rounded-sm flex items-center px-2 py-2 hover:bg-gray-100"
                                    >
                                      <TrashIcon className="text-[14px] text-[#98A2B3]" />
                                      <h2 className="ml-2">Delete</h2>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </td>
                          </tr>
                        )))
                        :
                        <div className="pt-4 flex items-center px-3 py-3">
                          <LoaderSpinner type="tableLoad" />
                        </div>
                      }
                    </tbody>
                  </table>
                ) : (
                  <div className=" py-5 px-5">
                    {loading ? (
                      <LoaderSpinner type="tableLoad" />
                    ) : (
                      <div className="flex w-full  font-sans font-semibold">
                        Vehical Data Not Found
                      </div>
                    )}
                  </div>
                )}
              </>
            </div>
            {vehicalList?.length > 0 && (
              <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap justify-between items-center pt-6 ">
                <div className="px-8 xxtra-small:px-0 mt-4 mb-1 w-full flex justify-end xxtra-small:justify-start">
                  <ReactPaginate
                    breakLabel="..."
                    breakClassName=" bg-[#F3F3F3]"
                    nextLabel={<MdOutlineKeyboardArrowRight />}
                    onPageChange={handlePageClick}
                    activeClassName="bg-[#FF8C00] text-white rounded"
                    nextClassName="px-3  bg-[#F3F3F3] text-[20px] pt-[5px] rounded xxtra-small:mb-4"
                    previousClassName="px-3  bg-[#F3F3F3] text-[20px] pt-[5px] rounded xxtra-small:mb-4"
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel={<MdOutlineKeyboardArrowLeft />}
                    className=" flex justify-end  xxtra-small:w-full xxtra-small:justify-start xtra-small:justify-start xxtra-small:flex-wrap xtra-small:flex-wrap"
                    pageClassName=" w-[30px] h-[30px]  text-center mx-2 pt-[3px] rounded bg-[#F3F3F3] xxtra-small:mb-4 xtra-small:mb-4"
                    // renderOnZeroPageCount={null}
                    forcePage={currentPageIndex}
                  />
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            <div className="overflow-x-auto bg-white  border border-gray-100 shadow-md rounded-md mt-6  w-full">
              {vehicalList?.length > 0 ? (
                <table className="table-auto w-full ">
                  {/* Table header */}
                  <thead className="text-xs font-[500] bg-[#FFF8EF] border-0">
                    <tr className="text-[#626973]">
                      <th className="px-4 py-4 pr-[25px] whitespace-nowrap w-px">
                        <div className="font-[600] text-[14px] text-left">
                          ID
                        </div>
                      </th>
                      <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                        <div className="font-[600] text-[14px] text-left">
                          Name
                        </div>
                      </th>
                      <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                        <div className="font-[600] text-[14px] text-left">
                          Vehicle Type
                        </div>
                      </th>
                      <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                        <div className="font-[600] text-[14px] text-left">
                          City
                        </div>
                      </th>
                      <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                        <div className="font-[600] text-[14px] text-left">
                          Owner Email
                        </div>
                      </th>
                      <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                        <div className="font-[600] text-[14px] text-left">
                          Posting Status
                        </div>
                      </th>
                      <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                        <div className="font-[600] text-[14px] text-left">
                          Status
                        </div>
                      </th>

                      <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                        <div className="font-[600] text-[14px] text-center">
                          Action{" "}
                        </div>
                      </th>
                    </tr>
                  </thead>

                  <tbody className="text-sm ">
                    {
                      !loading ?
                        vehicalList?.map((item, i) => (
                          <tr className=" cursor-pointer  " key={i}>
                            <td
                              onClick={() => {
                                return navigate(`/dinss/tech/management/detail/${item?.id}`);
                                // setRentalUser(item?.user)
                              }}
                              className="px-2 first:pl-5 flex items-center last:pr-5 py-3 whitespace-nowrap"
                            >
                              <div className="text-left  text-[14px] text-[#898989] px-1">
                                {i + 1}
                              </div>
                            </td>
                            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                              <div className="text-left text-[14px] text-[#898989] px-1">
                                {item?.model}
                              </div>
                            </td>
                            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                              <div className="text-left text-[14px] text-[#898989] px-1">
                                {item?.vehicle_category}
                              </div>
                            </td>
                            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                              <div className="text-left text-[14px] text-[#898989] px-1">
                                {item?.locations[0]?.city}
                              </div>
                            </td>
                            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                              <div className="text-left text-[14px] text-[#898989] px-1">
                                {item?.user?.email}
                              </div>
                            </td>

                            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                              <div className="text-left text-[14px] text-[#898989] px-1">
                                status posting
                              </div>
                            </td>

                            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                              <div
                                style={{
                                  color: `${item?.status_object?.text_color}`,
                                  backgroundColor: `${item?.status_object?.background_color}`,
                                }}
                                className={`text-center py-[3px] w-fit px-2.5 text-[12px] leading-4 rounded-full `}
                              >
                                {item?.status_object?.text_en}
                              </div>
                            </td>

                            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                              <div className="text-center flex justify-center  px-1">
                                {/* <BsThreeDotsVertical className='text-[20px] text-[#898989]' /> */}
                                {/* <GenericDrodown /> */}

                                <div className="group inline-block">
                                  <button className="outline-none focus:outline-none  px-3 py-1 bg-white rounded-sm flex items-center">
                                    <BsThreeDotsVertical className="text-[20px] text-[#898989]" />
                                  </button>
                                  <ul
                                    className="bg-white shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] border-0 rounded-md p-4 transform scale-0 group-hover:scale-100 absolute 
  transition duration-150 ease-in-out right-7 origin-top xl:w-[15%]  "
                                  >
                                    <li
                                      onClick={() => {
                                        return navigate(
                                          `/dinss/tech/management/detail/${item?.id}`
                                        );
                                        // setRentalUser(item?.user)
                                      }}
                                      className="rounded-sm actions flex items-center px-2 py-2 mb-2 hover:bg-gray-100"
                                    >
                                      <EyeIcon className="text-[14px] text-[#98A2B3]" />
                                      <h2 className="ml-2">View details</h2>
                                    </li>

                                    <li className="rounded-sm actions relative px-2 py-2 mb-2 hover:bg-gray-100">
                                      <button className="w-full flex items-center outline-none focus:outline-none">
                                        <RefreshIcon className=" ml-auto text-[#98A2B3]" />
                                        <span className="pr-1 flex-1 text-left ml-2">
                                          Change Status
                                        </span>
                                        <span className="mr-auto">
                                          <svg
                                            className="fill-current h-4 w-4
            transition duration-150 ease-in-out"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                          >
                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                          </svg>
                                        </span>
                                      </button>
                                      <ul
                                        className="bg-white border border-gray-100 p-2 flex justify-center flex-col items-center rounded-md absolute top-0 right-10 shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)]
  transition duration-150 ease-in-out origin-top-left
  min-w-32
  "
                                      >
                                        {statusOwner
                                          .filter(
                                            (f) =>
                                              f.Title !==
                                              item?.status_object?.text_en
                                          )
                                          .map((st, i) => (
                                            <li
                                              onClick={() => handlePopup(st, item)}
                                              className="px-2 py-2 text-left mb-2"
                                              key={i}
                                            >
                                              <div
                                                style={{
                                                  color: `${st.color}`,
                                                  backgroundColor: `${st.bgColor}`,
                                                }}
                                                className={`text-center py-[3px] px-2.5 w-auto text-[12px] rounded-full`}
                                              >
                                                {st.Title}
                                              </div>
                                            </li>
                                          ))}
                                      </ul>
                                    </li>
                                    <li
                                      onClick={() => DeleteVehical(item, "delete")}
                                      className="rounded-sm flex items-center px-2 py-2 hover:bg-gray-100"
                                    >
                                      <TrashIcon className="text-[14px] text-[#98A2B3]" />
                                      <h2 className="ml-2">Delete</h2>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))
                        :
                        <div className="pt-4 flex items-center px-3 py-3">
                          <LoaderSpinner type="tableLoad" />
                        </div>
                    }
                  </tbody>
                </table>
              ) : (
                <div className=" py-5 px-5">
                  {loading ? (
                    <LoaderSpinner type="tableLoad" />
                  ) : (
                    <div className="flex w-full  font-sans font-semibold">
                      Vehical Data Not Found
                    </div>
                  )}
                </div>
              )}
            </div>
            {vehicalList?.length > 0 && (
              <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap justify-between items-center pt-6 ">
                <div className="px-8 xxtra-small:px-0 mt-4 mb-1 w-full flex justify-end xxtra-small:justify-start">
                  <ReactPaginate
                    breakLabel="..."
                    breakClassName=" bg-[#F3F3F3]"
                    nextLabel={<MdOutlineKeyboardArrowRight />}
                    onPageChange={handlePageClick2}
                    activeClassName="bg-[#FF8C00] text-white rounded"
                    nextClassName="px-3  bg-[#F3F3F3] text-[20px] pt-[5px] rounded xxtra-small:mb-4"
                    previousClassName="px-3  bg-[#F3F3F3] text-[20px] pt-[5px] rounded xxtra-small:mb-4"
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel={<MdOutlineKeyboardArrowLeft />}
                    className=" flex justify-end  xxtra-small:w-full xxtra-small:justify-start xtra-small:justify-start xxtra-small:flex-wrap xtra-small:flex-wrap"
                    pageClassName=" w-[30px] h-[30px]  text-center mx-2 pt-[3px] rounded bg-[#F3F3F3] xxtra-small:mb-4 xtra-small:mb-4"
                    // renderOnZeroPageCount={null}
                    forcePage={currentPageIndex}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default DataList;
