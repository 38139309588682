import { toast } from "react-toastify"
import { callPrivateApi } from "../Apiendpoint"

// ------ vehical Renter ----- //

// Get Vehical Renter Statis
export const GetRnterStatis = async () => {
    try {
        let response = await callPrivateApi('admin/rent/vehicle-renter-stats', 'get')
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}

// Get Vihical renter Graph State
export const GetRenterGraph = async () => {
    try {
        let response = await callPrivateApi('admin/rent/vehicle-renter-graph', 'get')
        // let response2 = await callPrivateApi('admin/rent/vehicle-owner-graph', 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data);
            // //console.log("response2",response2)
            // let obj2 = Object.assign({}, ...response2.data);
            return {
                resGraph: obj,
                // resGraph2: obj2.data
            }
        }
        else {
            toast.error(response.message)
        }

    }
    catch (err) {
        toast.error(err)

    }
}

// Get Vehical Renter Detail
export const GetVehicalRnterDetail = async (user_id) => {
    try {
        let response = await callPrivateApi(`admin/rent/vehicle-renter-detail-stats/${user_id}`, 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data);
            return {
                res: obj,
            }
        }
        else {
            toast.error(response.message)
        }

    }
    catch (err) {
        toast.error(err)

    }
}

// Get  Renter Detail
export const GetRentDetail = async (id) => {
    try {
        let response = await callPrivateApi(`admin/rent/rent-details/${id}`, 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data);
            return {
                res: obj?.rent
            }
        }
        else {
            toast.error(response.message)
        }

    }
    catch (err) {
        toast.error(err)

    }
}

// Get Vehical Renter Reviews
export const GetVehicalRnterReviews = async (user_id) => {
    try {
        let response = await callPrivateApi(`admin/rent/vehicle-renter/reviews/${user_id}`, 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data);
            return {
                resReview: obj.reviews,
            }
        }
        else {
            toast.error(response.message)
        }

    }
    catch (err) {
        toast.error(err)

    }
}

// Get Vehical Renter Reviews
export const GetVehicalRnterRequest = async (user_id) => {
    try {
        let response = await callPrivateApi(`admin/rent/vehicle-renter/requests/${user_id}?page=1&per_page=10`, 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data);
            return {
                resRequest: obj.rental_request,
            }
        }
        else {
            toast.error(response.message)
        }

    }
    catch (err) {
        toast.error(err)

    }
}

// Get Vehical Renter Reviews
export const GetVehicalRnterTrans = async (user_id) => {
    try {
        let response = await callPrivateApi(`admin/user/transactions/owners/${user_id}`, 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data);
            return {
                res: obj?.transactions,
            }
        }
        else {
            toast.error(response.message)
        }

    }
    catch (err) {
        toast.error(err)

    }
}

// Edit Profile
export const EditRentalUserProfile = async (data) => {
    //console.log("Daya", data)
    let { id, business_registration_id, id_number, license_number, password, email, phone_number, address, image } = data



    try {

        let checkImg = image.startsWith('http://') || image.startsWith('https://')

        let payload = {
            "business_registration_id": business_registration_id || "N/A",
            "id_number": id_number || "N/A",
            "license_number": license_number || "N/A",
            "email": email,
            "phone_number": phone_number,
            "address": address,
            "image": checkImg ? "" : image,
            "password": password,
        }

        let response = await callPrivateApi(`admin/edit-profile/${id}`, 'post', payload)
        if (response.success === true) {
            toast.success(response.message)
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
            return {
                error: response.error
            }
        }
    }
    catch (err) {
        toast.error(err)

    }
}


// ----- Vehical Owner ----- //

// Get Vehical Owner Statis
export const GetOwnerStatis = async () => {
    try {
        let response = await callPrivateApi('admin/vehicle/vehicle-owner-stats', 'get')
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}

// Get Vihical renter Graph State
export const GetOwnerGraph = async () => {
    try {
        let response = await callPrivateApi('admin/rent/vehicle-owner-graph', 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data);

            return {
                resGraph: obj.data,
            }
        }
        else {
            toast.error(response.message)
        }

    }
    catch (err) {
        toast.error(err)

    }
}

// Get Vehical Owner Statis
export const GetOwnerVehicalByUser = async (id) => {
    try {
        let response = await callPrivateApi(`admin/vehicle/by-user/${id}`, 'get')
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}

// Get Vehical Renter Detail
export const GetVehicalOwnerDetail = async (user_id) => {
    try {
        let response = await callPrivateApi(`admin/rent/vehicle-owner-detail-stats/${user_id}`, 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data);
            return {
                res: obj,
            }
        }
        else {
            toast.error(response.message)
        }

    }
    catch (err) {
        toast.error(err)

    }
}

// Get Vehical Renter Reviews
export const GetVehicalOwnerReviews = async (user_id) => {
    try {
        let response = await callPrivateApi(`admin/rent/vehicle-owner/reviews/${user_id}`, 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data);
            return {
                resReview: obj.reviews,
            }
        }
        else {
            toast.error(response.message)
        }

    }
    catch (err) {
        toast.error(err)

    }
}

// Get Vehical Renter Reviews
export const GetVehicalOwnerRequest = async (user_id) => {
    try {
        let response = await callPrivateApi(`admin/rent/vehicle-renter/requests/${user_id}`, 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data);
            return {
                resRequest: obj.request,
            }
        }
        else {
            toast.error(response.message)
        }

    }
    catch (err) {
        toast.error(err)

    }
}


// Get Vehical Renter Rquest per page 
export const GetVehicalRequestperPage = async (user_id, page_No) => {
    try {
        let response = await callPrivateApi(`admin/rent/vehicle-renter/requests/${user_id}?page=${page_No}`, 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data);
            return {
                resRequest: obj.rental_request,
            }
        }
        else {
            toast.error(response.message)
        }

    }
    catch (err) {
        toast.error(err)

    }
}


// Get Vehical Renter Reviews
export const GetVehicalOwnerTrans = async (user_id) => {
    try {
        let response = await callPrivateApi(`admin/user/transactions/${user_id}`, 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data);
            return {
                res: obj?.transactions,
            }
        }
        else {
            toast.error(response.message)
        }

    }
    catch (err) {
        toast.error(err)

    }
}



// Customer Reposts Api's

// Get Customer renter reposts Statis 
export const GetCustomerRenterStatis = async () => {
    try {
        let response = await callPrivateApi(`admin/customer-report/renters/stats`, 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data);
            return {
                res: obj
            }
        }
        else {
            toast.error(response.message)
        }

    }
    catch (err) {
        toast.error(err)

    }
}
// Get Vehical Renter Reviews
export const GetCustomerRenterTrans = async () => {
    try {
        let response = await callPrivateApi(`admin/user/transactions?page=1&per_page=10`, 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data);
            return {
                resTrans: obj.trasaction,
                page: obj?.transactions?.total
            }
        }
        else {
            toast.error(response.message)
        }

    }
    catch (err) {
        toast.error(err)

    }
}

// Get Vehical Renter Reviews
export const GetCustomerRenterbyPage = async (pageNo) => {
    try {
        let response = await callPrivateApi(`admin/user/transactions?page=${pageNo}`, 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data);
            return {
                res: obj.trasaction,
            }
        }
        else {
            toast.error(response.message)
        }

    }
    catch (err) {
        toast.error(err)

    }
}

// Customer Reports Owner
export const GetCustomerOwnerStatis = async () => {
    try {
        let response = await callPrivateApi(`admin/customer-report/owners/stats`, 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data);
            return {
                res: obj
            }
        }
        else {
            toast.error(response.message)
        }

    }
    catch (err) {
        toast.error(err)

    }
}
// Get Vehical Renter Reviews
export const GetCustomeOwnerTrans = async () => {
    try {
        let response = await callPrivateApi(`admin/user/transactions?page=1&per_page=10`, 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data);
            return {
                res: obj.transactions,
                page: obj?.transactions?.total
            }
        }
        else {
            toast.error(response.message)
        }

    }
    catch (err) {
        toast.error(err)

    }
}


//---------------- Globel Reports Api's ---------------------//

// Get Graph State
export const GetGlobelGraph = async () => {
    try {
        let response = await callPrivateApi('admin/global-report/renters/stats', 'get')
        if (response.success === true) {
            return {
                res: response?.data,
            }
        }
        else {
            toast.error(response.message)
        }

    }
    catch (err) {
        toast.error(err)

    }
}

// Get Top Rated 
export const GetTopRated = async () => {
    try {
        let response = await callPrivateApi('admin/global-report/renters/top-renters', 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data)
            return {
                res: obj.renters,
            }
        }
        else {
            toast.error(response.message)
        }

    }
    catch (err) {
        toast.error(err)

    }
}
// Get Top Rated Owner
export const GetTopRatedOwner = async () => {
    try {
        let response = await callPrivateApi('admin/global-report/owners/top-owners', 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data)
            return {
                res: obj.renters,
            }
        }
        else {
            toast.error(response.message)
        }

    }
    catch (err) {
        toast.error(err)

    }
}
// Get Graph Owner State
export const GetOwnerGlobelGraph = async () => {
    try {
        let response = await callPrivateApi('admin/global-report/owners/stats', 'get')
        if (response.success === true) {
            return {
                res: response?.data,
            }
        }
        else {
            toast.error(response.message)
        }

    }
    catch (err) {
        toast.error(err)

    }
}
// Get Kpi matrics hourly
export const GetkpiHourly = async (key , date ) => {
    try {
        let payload = {
            "date": date,
            "action_key": key
        }
        let response = await callPrivateApi('admin/global-report/kpis/hourly', 'post', payload)
        if (response.success === true) {
            return response?.data
        }
        else {
            toast.error(response.message)
        }

    }
    catch (err) {
        toast.error(err)

    }
}

// Get Kpi matrics Daily
export const GetkpiDaily = async (key, date) => {
    try {
        let payload = {
            "date": date,
            "action_key": key
        }
        let response = await callPrivateApi('admin/global-report/kpis/monthly', 'post', payload)
        if (response.success === true) {
            return {
                res: response?.data,
            }
        }
        else {
            toast.error(response.message)
        }

    }
    catch (err) {
        toast.error(err)

    }
}