import React, { useEffect, useState } from 'react'
import KpiSingleLineChart from '../SingleLineKpiChart';
import { GetkpiDaily, GetkpiHourly } from '../../../../lib/Api/RentalAppApi';
import KpiSingleLineMonthly from '../SingleLineMonthly';
import moment from 'moment';

function Chart9({ heading, sideHeading, keys }) {
    const [hourly, setHourly] = useState([])
    const [monthly, setMonthly] = useState([])

    const currentDate = new Date();
    const [defaultMonth, setDefaultMonth] = useState(currentDate.getMonth());
    const today = moment().format("YYYY-MM-DD"); // Format: yyyy-mm-dd

    const [defaultDate, setDefaultDate] = useState(today);

    // Month is 0-based, so add 1
    const currentYear = currentDate.getFullYear();
    const months = [];
    for (let month = 0; month < 12; month++) {
        const monthName = new Date(currentYear, month, 1).toLocaleString('default', { month: 'long' });
        months.push(monthName);
    }

  

    let fetchChartData = async () => {
        // let date = `0${defaultMonth + 1}`
        // let completeDate = `${date}/${currentYear}`
        let incVal = Number(defaultMonth) + 1
        let addZeroWithMonth = incVal <= 9 ? `0${incVal}/${currentYear}` : `${incVal}/${currentYear}`
        let completeHour = moment(defaultDate).format('DD/MM/YYYY')
        let { res } = await GetkpiDaily(keys, addZeroWithMonth)
        if (res) {
            const obj = Object.assign({}, ...res)
            setMonthly(obj.graph_data)
        }
        let res2 = await GetkpiHourly(keys, completeHour)
        if (res2) {
            const obj = Object.assign({}, ...res2)
            setHourly(obj.graph_data)
        }
    }


    let handleDateChange = async (e) => {
        let value = e.target.value
        setDefaultDate(value)
        let completeHour = moment(value).format('DD/MM/YYYY')
        let res2  = await GetkpiHourly(keys, completeHour)
        if (res2) {
            const obj = Object.assign({}, ...res2)
            setHourly(obj.graph_data)
        }
    }
  
    const handleChangeMonth = async (e) => {
        let val = e.target.value
        setDefaultMonth(val)
        let incVal = Number(val) + 1
        let addZeroWithMonth = incVal <= 9 ? `0${incVal}/${currentYear}` : `${incVal}/${currentYear}`
        let { res } = await GetkpiDaily(keys, addZeroWithMonth)
        if (res) {
            const obj = Object.assign({}, ...res)
            setMonthly(obj.graph_data)
        }
      }




    useEffect(() => {
        fetchChartData()
    }, [])


    return (
        <>
            <div className='flex lg:flex-row flex-col justify-between items-center gap-5 mb-5'>
                <div className="bg-white shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] pb-3 border border-gray-100 rounded-md h-full px-3 pt-3 col-span-6 md:col-span-4  w-full ">
                    <div className="flex justify-between items-center pb-3">
                        <h2 className="text-[#FF8C00] leading-[20.16px] text-[14.4px] font-medium">
                            {heading}
                        </h2>
                        <input
                            type="date"
                            value={defaultDate}
                            pattern="\d{2}/\d{2}/\d{4}"
                            onChange={(e) => handleDateChange(e)}
                            className='border px-3 py-1 rounded-full focus:outline-none' />
                    </div>
                    <KpiSingleLineMonthly sideHeading={sideHeading} graphData={hourly} condition={"Hourly"} />
                </div>
                <div className="bg-white shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] w-full pb-3 border border-gray-100 rounded-md h-full px-3 pt-3 col-span-6 md:col-span-4  ">
                    <div className="flex justify-between items-center pb-3">
                        <h2 className="text-[#FF8C00] leading-[20.16px] text-[14.4px] font-medium">
                            {heading}
                        </h2>
                        <select value={defaultMonth} onChange={(e) => handleChangeMonth(e)} className="border rounded-2xl outline-none px-4 py-1">
                            <option disabled>Month</option>
                            {
                                months?.map((item, i) => (
                                    <option value={i} key={i}>{item}</option>
                                ))
                            }
                        </select>
                    </div>
                    <KpiSingleLineChart sideHeading={sideHeading} graphData={monthly} condition={"Monthly"} />
                </div>
            </div>
        </>
    )
}

export default Chart9