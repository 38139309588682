import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
const BreadCums = ({ left, right, middle, leftLink, middleLink }) => {
	// const location = useLocation().pathname
	const [state, setState] = useState("");
	const isActive = useSelector((state) => state.userAuth.isActive);
	const location = useLocation();
	// //console.log('I am right',right)
	const navigate = useNavigate();
	const navigateUser = () => {
		let newData = "";
		if (isActive) {
			newData = "";
		} else {
			newData = "changed";
		}
		navigate("/help-center", { state: { data: newData } });
	};
	return (
		<>
			<div className="mb-[16px]">
				<ul className="inline-flex flex-wrap text-sm font-medium">
					<li className=" flex items-center md:text-[15px] text-[12px] md:font-medium text-[#ACACAC]">
						{left}
						<svg
							className="md:h-4 h-[12px] md:w-4 w-[12px] fill-current text-[#ACACAC] lg:mx-[24px] mx-[10px]"
							viewBox="0 0 16 16"
						>
							<path d="M6.6 13.4L5.2 12l4-4-4-4 1.4-1.4L12 8z" />
						</svg>
					</li>
					{location.pathname === "/privacy" && (
						<li
							className={`flex items-center  md:text-sm text-[12px] md:font-medium  text-[#FF8C00]  cursor-pointer`}
						>
							Confidentialités
						</li>
					)}
					{location.pathname === "/gcu" ? (
						<div></div>
					) : (
						<li
							className={`flex items-center  md:text-sm text-[12px] md:font-medium ${
								location.pathname === "/privacy" && "hidden"
							} ${
								location.pathname === "/help-center"
									? "text-[#FF8C00]"
									: "text-[#ACACAC]"
							}  cursor-pointer`}
							onClick={navigateUser}
						>
							{isActive
								? "Je suis Locataire "
								: "Je suis Propriétaire "}
						</li>
					)}
					{}
					<li
						className={`flex items-center md:text-sm text-[12px] md:font-medium text-[#ACACAC] cursor-pointer ${
							location.pathname === "/gcu" || "/privacy"
								? "hidden"
								: "flex"
						} `}
						onClick={navigateUser}
					>
						{isActive
							? "Je suis Locataire"
							: "Je suis Propriétaire"}

						<svg
							className={`md:h-4 h-[12px]  md:w-4 w-[12px]  fill-current text-[#ACACAC] lg:mx-[24px] mx-[10px] ${
								location.pathname === "/help-center"
									? "hidden"
									: "block"
							} `}
							viewBox="0 0 16 16"
						>
							<path d="M6.6 13.4L5.2 12l4-4-4-4 1.4-1.4L12 8z" />
						</svg>
					</li>
					{location.pathname === "/gcu" ||
					location.pathname === "/privacy" ? (
						<div></div>
					) : (
						<svg
							className={`md:h-4 h-[12px]  md:w-4 w-[12px]   fill-current text-[#ACACAC] lg:mx-[24px] mx-[10px] ${
								location.pathname === "/help-center"
									? "hidden"
									: "block"
							} `}
							viewBox="0 0 16 16"
						>
							<path d="M6.6 13.4L5.2 12l4-4-4-4 1.4-1.4L12 8z" />
						</svg>
					)}
					{}{" "}
					<li className="flex items-center mainIrfan">
						<div
							className={` ${
								location.pathname === "/help-center" ||
								location.pathname === "/privacy"
									? "hidden"
									: ""
							} md:text-sm text-[12px] text-[#FF8C00] md:font-bold irfan`}
							href="#0"
						>
							{location.pathname === "/help-center"
								? "Déposez votre véhicule"
								: right
								? right
								: "Déposez votre véhicule"}
						</div>
					</li>
				</ul>
			</div>
		</>
	);
};

export default BreadCums;
