import React, { useEffect, useState } from "react";

import DataTable from "../managementData/DataTable";
import CarlistDetail from "./carlistDetail/CarlistDetail";
import { GetVihicalsPendding } from "../../../../lib/Api/Vehical";

const VihicalManagement = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [UnResloading, setUnResLoading] = useState(false);
  const [totalPage, setTotalPages] = useState();
  const [totalUnPage, setTotalUnPages] = useState();
  const [vehicalList, setVehicalList] = useState([]);
  const [vehicalListRes, setVehicalListRes] = useState([]);
  const [offsetPage, setOffsetPage] = useState(1);
  const [searchDataFleet, setSearchDataFleet] = useState("");
  const [searchData, setSearchData] = useState("");
  const [types2, setTypes2] = useState(false);
  const [statusCheck, setStatusCheck] = useState("approved");
  const [statusResCheck, setStatusResCheck] = useState("restricted");
  const [currentPageIndexFleet, setCurrentPageIndexFleet] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);


  let restrit = () => {
    (async () => {
      setLoading(true);
      let { res } = await GetVihicalsPendding(statusCheck, 1);
      setLoading(false);
      let vehicals = Object.assign({}, ...res);
      setVehicalList(vehicals?.vehicles?.data);
      setTotalPages(vehicals?.vehicles?.total);
    })();
  };

  let Unrestrit = () => {
    (async () => {
      setUnResLoading(true);
      let { res } = await GetVihicalsPendding(statusResCheck, 1);
      setUnResLoading(false);
      let vehicals = Object.assign({}, ...res);
      setVehicalListRes(vehicals?.vehicles?.data);
      setTotalUnPages(vehicals?.vehicles?.total);
    })();
  };
  useEffect(() => {
    if (searchDataFleet === "") {
      restrit();
    }
  }, [searchDataFleet]);
  useEffect(() => {
    if (searchData === "") {
      Unrestrit();
    }
  }, [searchData]);
  useEffect(() => {
    if(searchDataFleet === "change Status" ){
      setSearchData("update")
      setTimeout(() =>{
        setSearchData("")
      },4000)
    }
    else if(searchData === "change Status"){
      setSearchDataFleet("update")
      setTimeout(() =>{
        setSearchDataFleet("")
      },4000)
    }
  },[searchDataFleet])

  return (
    <>
      {isEdit === false ? (
        <>
          <div className="py-5">
            <DataTable
              setIsEdit={setIsEdit}
              offsetPage={offsetPage}
              setSearchData={setSearchDataFleet}
              searchData={searchDataFleet}
              setOffsetPage={setOffsetPage}
              vehicalList={vehicalList}
              setVehicalList={setVehicalList}
              loading={loading}
              setTotalPages={setTotalPages}
              setStatusCheck={setStatusCheck}
              statusCheck={statusCheck}
              setTypes={setTypes2}
              totalPages={totalPage}
              setLoading={setLoading}
              currentPageIndex={currentPageIndexFleet}
              setCurrentPageIndex={setCurrentPageIndexFleet}
              title={"Vehicle Fleet Management"}
            />
            <div className="pt-5">
              <DataTable
                setIsEdit={setIsEdit}
                vehicalList={vehicalListRes}
                setVehicalList={setVehicalListRes}
                setSearchData={setSearchData}
                loading={UnResloading}
                searchData={searchData}
                totalPages={totalUnPage}
                offsetPage={offsetPage}
                setOffsetPage={setOffsetPage}
                setTotalPages={setTotalUnPages}
                setStatusCheck={setStatusResCheck}
                statusCheck={statusResCheck}
                setTypes={setTypes2}
                setLoading={setUnResLoading}
                title={"Vehicle Fleet Restrictions "}
                setCurrentPageIndex={setCurrentPageIndex}
                currentPageIndex={currentPageIndex}

              />
            </div>
          </div>
        </>
      ) : (
        <CarlistDetail />
      )}
    </>
  );
};

export default VihicalManagement;
