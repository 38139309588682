import React from "react";
import Table from "../../components/AdminComponents/CustomerSupports/DataTable/Table";

const Tickets = () => {
  return (
    <>
      <Table />
    </>
  );
};

export default Tickets;
