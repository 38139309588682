import React, { useEffect, useRef, useState } from 'react'
import QRCode from 'qrcode'
import { IoClose } from "react-icons/io5";
import { useReactToPrint } from 'react-to-print';
import appIcon from '../../../assests/app-Icon-.png'
import car from '../../../assests/svg/fa-solid_car.svg'
import key from '../../../assests/svg/game-icons_car-key.svg'
import PrimeCarIcon from '../../../assests/svg/Primecar Icon.svg'
import * as htmlToImage from 'html-to-image';
import LoaderSpinner from '../../../util/LoaderSpinner';

function ViewQrCode({ list, close }) {
    const [qrCodeDowload, setQrCodeDownload] = useState("")
    const [isActive, setIsActive] = useState(0)
    const [loading, setLoading] = useState(false)
    const componentRef = useRef();
    const componentPostRef = useRef();
    let currentYear = new Date().getFullYear()
    const downloadQRCode = async () => {
        try {
            let res = await QRCode.toDataURL(list?.link)
            setQrCodeDownload(res)
        }
        catch (err) { }
    };
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })
    const handlePrintPoster = useReactToPrint({
        content: () => componentPostRef.current,
    })



    const handleDownload = () => {
        setLoading(true)
        htmlToImage.toPng(componentPostRef.current)
            .then(function (dataUrl) {
                // download(dataUrl, 'component.png');
                setLoading(false)
                const link = document.createElement('a');
                link.href = dataUrl;
                link.download = 'component.png';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch(function (error) {
                console.error('Error while downloading the image:', error);
            });
    };

    useEffect(() => {
        downloadQRCode()
    }, [])

    return (
        <>
            <div className='flex justify-end items-end'>
                <IoClose onClick={() => close()} className='cursor-pointer' />
            </div>
            <div className="flex justify-between items-center pt-4 gap-3">
                <div onClick={() => setIsActive(0)} className={`border cursor-pointer w-full text-center p-2 rounded-md ${isActive === 0 ? 'bg-[#FF8C00]' : 'bg-transparent'}`}>
                    <span className={`${isActive === 0 ? 'text-white' : 'text-black'}`}>Qr Code</span>
                </div>
                <div onClick={() => setIsActive(1)} className={`border cursor-pointer w-full text-center p-2 rounded-md ${isActive === 1 ? 'bg-[#FF8C00]' : 'bg-transparent'}`}>
                    <span className={`${isActive === 1 ? 'text-white' : 'text-black'}`}>Poster Code</span>
                </div>
            </div>
            {isActive === 0 ?
                <div>
                    <div className='flex justify-center items-center' ref={componentRef} >
                        <img src={qrCodeDowload} className='w-[300px] h-[300px] object-cover' />
                    </div>
                    <div>
                        <div className='flex items-center gap-5'>
                            <button onClick={() => handlePrint()} className="bg-[#FF8C00] text-white text-center px-3 py-2 rounded-md">Print Qr Code </button>
                            <a href={qrCodeDowload} download className="bg-[#FF8C00] text-white text-center px-3 py-2 rounded-md">Download Code</a>
                        </div>
                    </div>
                </div>
                :
                <div className=''>
                    <div className='bg-[#FFEAD5] w-[420px] relative overflow-hidden mt-3 ' ref={componentPostRef}>
                        <div className='flex flex-col justify-center items-center py-[28px]'>
                            <img src={appIcon} alt="icon" className='w-[90px] h-[90px] object-cover' />
                            <h2 className='text-center font-bold text-[36px] pt-[5px]'>Primecar</h2>
                            <p className='text-[#444444] text-[12px] pt-[4px] font-semibold '>Louez votre véhicule en quelques clics</p>

                            <div className='flex gap-4 items-center mt-2 border rounded-md border-white px-2'>
                                <img src={car} alt="car" />
                                <img src={key} alt="car" />
                            </div>
                            <div className='pt-2'>
                                <span className='text-[12px] font-medium'>Scannez pour télécharger l’application !</span>
                            </div>
                            <div className='flex justify-center items-center z-50'  >
                                <img src={qrCodeDowload} className='w-[250px] rounded-lg h-[250px] object-cover' />
                            </div>
                            <div className='mt-3  border-t border-black'>
                                <span className='text-[12px] font-medium'>© {currentYear} DINSS SARL. All rights reserved.</span>
                            </div>
                            <div className='absolute -top-5 -right-7'>
                                <img src={PrimeCarIcon} alt="primeca" />
                            </div>
                            <div className='absolute -bottom-10 -left-10'>
                                <img src={PrimeCarIcon} alt="primeca" />
                            </div>
                            <div className='absolute top-[70px] left-[90px]'>
                                <img src={PrimeCarIcon} alt="primeca" />
                            </div>
                            <div className='absolute z-20 top-[300px] left-[30px]'>
                                <img src={PrimeCarIcon} alt="primeca" />
                            </div>
                            <div className='absolute z-20 top-[390px] right-[20px]'>
                                <img src={PrimeCarIcon} alt="primeca" />
                            </div>
                        </div>

                    </div>
                    <div>
                        <div className='flex items-center justify-center mt-5 gap-5'>
                            <button onClick={() => handlePrintPoster()} className="bg-[#FF8C00] text-white text-center px-3 py-2 rounded-md">Print Poster </button>
                            <button onClick={() => handleDownload()} className="bg-[#FF8C00] text-white text-center px-3 py-2 rounded-md">
                                {loading ? <LoaderSpinner/> : "Download Poster"}
                            </button>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default ViewQrCode