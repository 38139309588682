import React from "react";
import { Link, useLocation } from "react-router-dom";
import rantable from "../../../assests/PMNeew/rentalable.svg";
const Rentable = () => {
	return (
		<>
			<div className=" lg:py-[60px] py-[40px] md:px-20 px-5">
				<div className="flex flex-col md:flex-row lg:gap-20 gap-5 md:gap-7 2xl:w-[1440px]  justify-between m-auto">
					<div className="w-full lg:order-1 order-0 md:order-1 max-w-2xl xl:items-end">
						<img
							src={rantable}
							alt="rentable"
							className="object-contain  w-full h-full  "
						/>
					</div>
					<div className=" w-full lg:order-0 md:order-0 order-1 lg:my-0 xl:max-w-2xl">
						<h2 className="lg:text-[31px] font-sans max-w-[500px] text-[#444444] text-[22px] md:leading-[50px] leading-[30px] font-bold">
							Rentabilisez votre{" "}
							<span className="text-[#FF8C00]">
								véhicule maintenant !
							</span>
						</h2>

						<div className="pt-[24px] max-w-[400px]">
							<div className="flex ">
								<div>
									<span className="text-[#FF8C00] px-1 md:text-[18px] text-[14px] leading-[28px] font-semibold font-sans">
										1.
									</span>
								</div>
								<div className="flex flex-col pl-2">
									<h2 className="md:text-[18px] text-[14px]  font-sans  font-semibold text-[#444444] ">
										{" "}
										Déposez votre véhicule
									</h2>
									<p className="text-[14px] md:pt-[8px] pt-[6px] font-sans font-normal text-[#5A5A5A]">
										Téléchargez l’application faites-y
										gratuitement le dépôt de votre véhicule
										et à vous les locations !
									</p>
								</div>
							</div>
						</div>

						<div className="pt-[24px] max-w-[400px]">
							<div className="flex ">
								<div>
									<span className="text-[#FF8C00] px-1 md:text-[18px] text-[14px] leading-[28px]  font-semibold font-sans">
										2.
									</span>
								</div>
								<div className="flex flex-col pl-2">
									<h2 className="md:text-[18px] text-[14px]  font-sans  font-semibold text-[#444444] ">
										Acceptez les demandes de location
									</h2>
									<p className="text-[14px]  font-sans  font-normal text-[#5A5A5A] md:pt-[8px] pt-[6px]">
										Donnez votre accord aux parties qui
										souhaitent louer votre bien.
									</p>
								</div>
							</div>
						</div>

						<div className="pt-[24px] max-w-[400px]">
							<div className="flex ">
								<div>
									<span className="text-[#FF8C00] px-1 md:text-[18px] text-[14px] leading-[28px]  font-semibold font-sans">
										3.
									</span>
								</div>
								<div className="flex flex-col pl-2">
									<h2 className="md:text-[18px] text-[14px]  font-sans  font-semibold text-[#444444] ">
										Faites plus du revenu
									</h2>
									<p className="text-[14px]  font-sans  font-normal text-[#5A5A5A] md:pt-[8px] pt-[6px]">
										Gagnez de l’argent grâce à la mise en
										location de votre véhicule, et
										rentabilisez votre business !
									</p>
								</div>
							</div>
						</div>

						<div className="pt-[32px]">
							<Link to="/louer-mon-vehicule">
								<button className="px-[30px] md:py-[10px] py-[8px] md:text-[18px] text-[16px] font-sans  rounded-md bg-[#FF8C00] hover:bg-[#D16A00] text-white">
									Louer mon véhicule
								</button>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Rentable;
