import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useParams } from 'react-router-dom';
import { onCreateConversationAndSendMessage } from './CreateConversationMessage';
import LoaderSpinner from '../../../../util/LoaderSpinner';
const schema = yup.object({
    subject: yup.string().required("Subject to is required"),
    message: yup.string().required("Message on is required"),

});
function IntitiateChat({ close, }) {
    const [loading, setLoading] = useState(false)

    const id = useParams().id

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({ mode: "onChange", resolver: yupResolver(schema) });

    const onSubmit = async (data) => {
        setLoading(true)
        let res = onCreateConversationAndSendMessage(id, data)
        if (res) {
            setTimeout(() => {
                setLoading(false)
                reset()
                close()
            }, 200);

        }
    }


    // const createConversation = async (userId , data ) => {
    //     try {
    //         // Reference to the user's conversation collection
    //         const conversationRef = collection(db, `Users/${userId}/Conversation`);
    //         // Query to get the last conversation document
    //         const querySnapshot = await getDocs(conversationRef);
    //         let lastId = 0;
    //         // Check if there are existing documents
    //         if (!querySnapshot.empty) {
    //             // Get the last document and extract its ID
    //             querySnapshot.forEach((doc) => {
    //                 const id = parseInt(doc.id);
    //                 if (id > lastId) {
    //                     lastId = id;
    //                 }
    //             });
    //         }
    //         // Generate a new conversation ID by incrementing the last ID
    //         const newConversationId = lastId + 1;

    //         // Create the new conversation document
    //         const newConversationRef = doc(conversationRef, `${newConversationId}`);
    //         const senderChat = {
    //             conversationId: newConversationId,

    //         };
    //         await setDoc(newConversationRef, senderChat);
    //         console.log("Conversation created successfully with ID:", newConversationId);
    //         return newConversationId;
    //     } catch (error) {
    //         console.error("Error creating conversation:", error);
    //         return null;
    //     }
    // };


    // const createConversationAndSendMessage = async (data) => {
    //     const conversationRef = collection(
    //         db,
    //         `Users/${0}/Conversation`
    //     );

    //     const conversationRef2 = collection(
    //         db,
    //         `Users/${id}/Conversation`
    //     );

    //     const utcTime = moment.utc().format('YYYY-MM-DD HH:mm:ss');

    //     // Check if conversation already exists
    //     const conversationSnapshot = await getDocs(conversationRef);
    //     if (conversationSnapshot.empty) {
    //         const newConversationRef = doc(conversationRef, id); // Explicitly set the document ID to "0"
    //         const senderChat = {
    //             conversationId: id,
    //             lastMessage: data?.message,
    //             lastMessageTime: utcTime,
    //             // lastMessageId: lastMesId,
    //             otherUserId: id,
    //             conversationType: 'single',
    //             renterId: 0,
    //             ownerId: id,
    //             startDate: utcTime,
    //             // endDate: new Date(endDate)
    //         };
    //         try {
    //             await setDoc(newConversationRef, senderChat);
    //         } catch (error) {
    //             console.error("Error creating conversation:", error);
    //             return null;
    //         }
    //     }
    //     else {
    //         const newConversationRef = doc(conversationRef, id);
    //         const senderChat = {
    //             conversationId: id,
    //             lastMessage: data?.message,
    //             lastMessageTime: utcTime,
    //             // lastMessageId: lastMesId,
    //             otherUserId: 0,
    //             conversationType: 'single',
    //             renterId: id,
    //             ownerId: 0,
    //             startDate: utcTime,
    //             // endDate: new Date(endDate)
    //         };
    //         try {
    //             await setDoc(newConversationRef, senderChat);
    //         } catch (error) {
    //             console.error("Error creating conversation:", error);
    //             return null;
    //         }
    //     }

    //     // Check if Reciver Conversation already exists

    //     const conversationSnapshot2 = await getDocs(conversationRef2);
    //     if (conversationSnapshot2.empty) {
    //         const newConversationRef = doc(conversationRef2, "0"); // Explicitly set the document ID to "0"
    //         const senderChat = {
    //             conversationId: 0,
    //             lastMessage: data?.message,
    //             lastMessageTime: utcTime,
    //             // lastMessageId: lastMesId,
    //             otherUserId: id,
    //             conversationType: 'single',
    //             renterId: 0,
    //             ownerId: id,
    //             startDate: utcTime,
    //         };
    //         try {
    //             await setDoc(newConversationRef, senderChat);
    //         } catch (error) {
    //             console.error("Error creating conversation:", error);
    //             return null;
    //         }
    //     }
    //     else {
    //         const newConversationRef = doc(conversationRef2, "0");
    //         const senderChat = {
    //             conversationId: 0,
    //             lastMessage: data?.message,
    //             lastMessageTime: utcTime,
    //             // lastMessageId: lastMesId,
    //             conversationType: 'single',
    //             otherUserId: 0,
    //             renterId: 0,
    //             ownerId: id,
    //             startDate: utcTime,
    //             // endDate: new Date(endDate)
    //         };
    //         try {
    //             await setDoc(newConversationRef, senderChat);
    //         } catch (error) {
    //             console.error("Error creating conversation:", error);
    //             return null;
    //         }
    //     }
    // }



    return (
        <>
            <div className='lg:w-[400px]'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='flex flex-col '>
                        <label htmlFor="">Subject</label>
                        <input type="text" {...register('subject')} className='border px-2 rounded-md focus:outline-none border-gray-200 px-2 h-[40px]' />
                    </div>
                    {errors.subject && (
                        <p className="text-red-500 text-sm text-start pt-1 ">
                            {errors.subject.message}
                        </p>
                    )}
                    <div className='flex flex-col pt-6'>
                        <label htmlFor="">Messsage</label>
                        <textarea rows={10} {...register('message')} className='border py-2 px-2 rounded-md focus:outline-none border-gray-200 px-2 ' />
                    </div>
                    {errors.message && (
                        <p className="text-red-500 text-sm text-start pt-1 ">
                            {errors.message.message}
                        </p>
                    )}
                    <div className='pt-3'>
                        <button type='submit' className='bg-[#FF8C00] text-white px-3 py-2 rounded-md w-full'>
                            {loading ? <LoaderSpinner /> : "Send"}
                        </button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default IntitiateChat