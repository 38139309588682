import React, { useState,useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import BlogSidebar from "./BlogSidebar";
import HamburgerMenuBar from "../../../assests/hamburger-menu.svg";
import AppLogo from "../../../assests/app-Icon-.webp";
import close from "../../../assests/close.png";

const BlogHeader = () => {
	const [sideNavShow, setSideNavShow] = useState(false);
	const [isMobile, setIsMobile] = useState(false);
  const [isAndroid, setIsAndroid] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);

  useEffect(() => {
    let resizeTimer;
	let prevWidth = window.innerWidth;
	
    const checkDeviceInfo = () => {
      const userAgent = navigator.userAgent;
      const pattern_mobile = /Mobile|Android|iPhone/i;
      const ipad_check = /iPad/i;

      if (!ipad_check.test(userAgent)) {
        if (pattern_mobile.test(userAgent)) {
          setIsMobile(true);
          setShowDownloadModal(true);
          const pattern_ios = /\((iPhone).*?like Mac OS X\)/;
          const match_ios = userAgent.match(pattern_ios);

          if (!match_ios) {
            setIsAndroid(true);
          } else {
            setIsAndroid(false);
          }
        } else {
          setIsMobile(false);
        }
      }else{
        setShowDownloadModal(false)
      }
    };

	const handleResize = () => {
		clearTimeout(resizeTimer);
		resizeTimer = setTimeout(() => {
		  const currentWidth = window.innerWidth;
		  if (prevWidth !== currentWidth) {
			prevWidth = currentWidth;
			checkDeviceInfo();
		  }
		}, 200);
	  };

    window.addEventListener("resize", handleResize);
    checkDeviceInfo();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

	const location = useLocation().pathname;
	return (
		<>
			<BlogSidebar
				sidebarOpen={sideNavShow}
				setSidebarOpen={setSideNavShow}
			/>
			 {isMobile && showDownloadModal ? (
        <div className="flex justify-between bg-white py-[10px] md:px-20 fixed w-full top-0  text-center border-b-2 border-[#F2F2F2]">
          <div className="flex">
         <div className="my-auto" onClick={()=> setShowDownloadModal(false)}>
				<img
                                      src={close}
                                      alt="close"
                                      className="object-cover px-3"
                                    />
				</div>
            <div className="flex  flex-row items-center gap-[4px]">
              <img
                height={32}
                width={32}
                src={AppLogo}
                alt="logo"
                className="object-contain w-[48px] h-[48px] cursor-pointer"
              />
              <div className="text-left ml-2">
                <p className="font-bold text-[18px] leading-[22px] text-[#444444]">
                  Primecar
                </p>
                <p className="font-[500] text-[10px] leading-[14px] text-[#444444]">
                  N°1 location de véhicule en Côte d’Ivoire
                </p>
                <p className="font-[500] text-[10px] leading-[14px] text-[#EC670A]">
                  +1000 Téléchargements
                </p>
              </div>
            </div>
            </div>
          <a
            className="my-auto pr-5"
            href={
              isAndroid
                ? "https://play.google.com/store/apps/details?id=com.dinsstech.primecar"
                : "https://apps.apple.com/fr/app/primecar/id6446251526"
            }
          >
            <button className="bg-[#FF8C00] hover:bg-[#D16A00] py-[5px] px-[5px] w-[72px] text-[10px] font-medium leading-[20px] rounded-md text-white">
            Télécharger
            </button>
          </a>
        </div>
      ) : null}
			<div className={showDownloadModal ? "md:px-20 px-5 mt-[82px]" : "md:px-20 px-5 mt-0"}>
				<div className="2xl:w-[1440px] m-auto flex justify-between items-center  py-[20px]  tablet:pr-10 ">
					<div className="">
						<Link to="/">
							<div className="flex flex-row items-center gap-[4px]">
								<img
									src={AppLogo}
									alt="logo"
									className="object-contain lg:w-[31.87px] lg:h-[31.84px] md:w-[25.89px] md:h-[25.87px] tablet:w-[25.89px] tablet:h-[25.87px]  w-[25.89px] h-[25.87px] cursor-pointer"
								/>
								<p className="font-bold text-[16px] sm:text-[20px]  leading-[24px] text-[#444444]">
									Primecar
								</p>
							</div>
						</Link>
					</div>
					<div className="lg:hidden md:hidden header-tablet:block cursor-pointer">
						<img
							src={HamburgerMenuBar}
							alt="Hamburger-Menu-Bar"
							onClick={() => setSideNavShow(true)}
						/>
					</div>
					<div className="hidden md:block header-tablet:hidden lg:block w-full">
						<ul className="flex items-center justify-end gap-[50px]">
							<li className="">
								<Link
									to="/404"
									className={`${
										location ===
										"/deplacement-professionnel"
											? "text-[#FF8C00]"
											: "text-[#444444]"
									} text-[15px] font-medium p-[10px] tablet:text-[14px] m leading-[24px]  text-center w-full hover:text-[#FF8C00]`}
								>
									Actualités
								</Link>
							</li>
							<li className="">
								<Link
									to="/404"
									className={`${
										location === "/help-center" ||
										location === "/helpsupport_detail"
											? " pb-[10px] text-[#FF8C00] font-medium"
											: "text-[#444444] font-medium"
									} text-[15px] tablet:text-[14px] font-medium leading-[24px] text-center w-full hover:text-[#FF8C00]`}
								>
									Les bon plans
								</Link>
							</li>
							<li className="ml-[10px]">
								<Link
									to="/404"
									className={`${
										location === "/help-center" ||
										location === "/helpsupport_detail"
											? " pb-[10px] text-[#FF8C00] font-medium"
											: "text-[#444444] font-medium"
									} text-[15px] tablet:text-[14px] font-medium leading-[24px] text-center w-full hover:text-[#FF8C00]`}
								>
									Nouveautés
								</Link>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<p className={!showDownloadModal ? "blog-header-heading-mobile" : "blog-header-heading-mobile_with_banner"}>Le blog</p>
		</>
	);
};

export default BlogHeader;