import React, { useState } from "react";
// import { MdSearch } from "react-icons/md";
import ManageRole from "../../components/AdminComponents/role/ManageRole";
import ManageRoleTable from "../../components/AdminComponents/role/ManageRoeTable";
import { MdSearch } from "react-icons/md";
// import SearchBar from "../../components/GenericComponents/SearchBar";
// import { GetInvitation } from "../../lib/Api/AdminApi";

const ManageRoles = () => {
  // React hooks
  //  const [updateloading, setUpdateLoading] = useState(false)
  // const [updated, setUpdated] = useState(false);
  const [searchData, setSearchData] = useState("");


  return (
    <>
      {/* Promo component */}
      <ManageRole setSearchData={setSearchData} />
      {/* table component */}
      <div className="mt-5 p-5 bg-white rounded-md shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)]">
        <div className="flex  flex-wrap justify-between items-center">
          <h2 className="text-[20px] font-semibold leading-[20.16px]  text-[#FF8C00]">
            Roles List{" "}
          </h2>
          <div className="flex lg:py-0 py-2 ">
            <div className="flex lg:flex-nowrap xxtra-small:w-full xtra-small:w-full xtra-small:flex-nowrap md:flex-nowrap flex-wrap lg:my-0 my-3">
              <div className="bg-white shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] rounded-full border-0 flex items-center px-4 lg:w-[380px] w-full py-3 h-[45px]">
                <MdSearch className="text-[25px] text-[#FF8C00]" />
                <input
                  onChange={(e) => setSearchData(e.target.value)}
                  type="text"
                  className="focus:outline-none mx-2 w-full font-medium placeholder:text-[#ACACAC] placeholder:text-[16px]"
                  value={searchData}
                  placeholder="Search..."
                />
              </div>
            </div>
          </div>
        </div>
        <ManageRoleTable searchData={searchData}  setSearchData={setSearchData} />
      </div>
    </>
  );
};

export default ManageRoles;
