import React, { useState } from 'react'
import warning from '../../../../../assests/warning.png'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { CancelBookingMang } from '../../../../../lib/Api/Management'
import LoaderSpinner from "../../../../../util/LoaderSpinner";

// Validation Scema
const schema = yup.object({
    penalty: yup.string().required("Penalty is required"),
});
const CancelBooking = ({ setShow, rentId, setSearchData }) => {

    const [active, setActive] = useState(0)
    const [loading, setLoading] = useState(false)

    // React hook form initilization
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ mode: "onChange", resolver: yupResolver(schema) });

    const onSubmit = async (data) => {
        try {
            setLoading(true);
            let paylaod = {
                "rent_id": rentId,
                "status": "cancel",
                "penalty": data?.penalty
            }
            let { res } = await CancelBookingMang(paylaod);
            if (res !== undefined) {
                setLoading(false);
                setShow(false)
                setSearchData("")
            } else {
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
        }
    };


    return (
        <>
            <div className='text-center p-5'>
                <div className='flex justify-center items-center'>
                    <img src={warning} alt="delicon" className='object-cover' />
                </div>
                {active === 0 &&
                    <div className='py-5'>
                        <h1 className='text-[#292929] text-[20px] font-semibold'>Are you sure you want to cancel this booking</h1>
                        <p className='text-[#666666] leading-[20px] text-[16px]'>You won’t be able to revert back later on.</p>
                        <div className='pt-5'>
                            <button type="button" onClick={() => setShow(false)} className='w-[138px] py-2 rounded-md bg-transparent border-[#ACACAC] border text-[#ACACAC]'>Cancel</button>
                            <button type="button" onClick={() => setActive(1)} className='w-[138px] py-2 ml-3 rounded-md bg-[#FF8C00] text-white'>Yes</button>
                        </div>
                    </div>
                }
                {
                    active === 1 &&
                    <div className='py-5 '>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <h1 className='text-[#292929] text-[20px] font-semibold'>How  much is the penality to the user ?</h1>
                            <div className="flex items-center gap-4 pt-4 px-5">
                                <input type="number" {...register('penalty')} className="border placeholder:text-[#FF8C00] text-[#FF8C00] focus:outline-none rounded h-[40px] px-2" />
                                <span className="text-[20px] font-medium">FCFA</span>
                            </div>
                            {errors.penalty && (
                                <p className="text-red-500 text-sm text-start pt-1 px-5  ">
                                    {errors.penalty.message}
                                </p>
                            )}
                            <div className='pt-5'>
                                <button type="button" onClick={() => setShow(false)} className='w-[30%] py-3 rounded-md bg-transparent border-[#ACACAC] border text-[#ACACAC]'>Cancel</button>
                                <button type="submit" className=' px-6 py-3 ml-3 rounded-md bg-[#FF8C00] text-white'>
                                    {
                                        loading ? <LoaderSpinner  /> : "Send to user & finance"
                                    }
                                </button>
                            </div>
                        </form>
                    </div>
                }
            </div>
        </>
    )
}

export default CancelBooking