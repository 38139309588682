import React from 'react'
import DataTable from './bookingTables/DataTable'
const BookingManagement = ({title}) => {
  return (
    <>
          <DataTable title={title}/>
    </>
  )
}

export default BookingManagement