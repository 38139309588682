import React, { useEffect, useState } from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import user from "../../../../assests/Ellipse 1490.png";
import moment from "moment";
import { Truncate } from "../../../../util/TrucateString";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../../confiq/firebase";

const UsersList = ({ users, convId, setConvId }) => {
  const [search, setSearch] = useState("");
  const [totalUnRead, setTotalUnRead] = useState(0);
  const filteredData = users.filter((item) =>
    item?.userName?.toLowerCase().includes(search?.toLowerCase())
  );


  const getUnreadMessageCount = async (userId, conversationId) => {
    const q = query(
      collection(db, `Users/${userId}/Conversation/${conversationId}/Messages`),
      where('isRead', '==', false)
    );

    const querySnapshot = await getDocs(q);
    return querySnapshot.size;
  };

  let getCounts = async () => {
    let totalUnreadMessages = 0;
    for (const usersId of users) {
      const unreadMessageCount = await getUnreadMessageCount(usersId?.userId, usersId?.conversationId);
      totalUnreadMessages += unreadMessageCount;
    }
    setTotalUnRead(totalUnreadMessages)
  }

  useEffect(() => {
    getCounts()
  }, [users])


  return (
    <>
      <div className="h-full">
        <div className="bg-[white] rounded shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] border-0 h-full">
          <div className="border-b px-6 py-[26px] flex items-center">
            <h1 className="text-[#444444] text-[16px] leading-[24px]">
              Message
            </h1>
            <MdOutlineKeyboardArrowDown className="text-[20px] mx-2" />
            <div className="bg-[#FF8C00] rounded-full ">
              <h2 className="text-white text-[16px] leading-[24px] px-2 py-[2px] font-semibold">
                {totalUnRead}
              </h2>
            </div>
          </div>
          <div className="p-1">
            <input
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search messages"
              className="focus:outline-none border border-[#A7AFB2] text-[16px] leading-[18px] rounded-lg px-3 py-3.5 w-full"
            />
            <div className="h-[400px] overflow-y-auto overflow-x-hidden mt-3 user_list">
              {filteredData?.map((item, i) => (
                <div
                  className={`py-2 px-2 cursor-pointer ${convId?.id === item?.id ? "bg-gray-100 rounded-md" : ""
                    }`}
                  key={i}
                  onClick={() => setConvId(item)}
                >
                  <div className="flex justify-between px-1">
                    <div className="flex items-center">
                      <div className="h-[44px] w-[44px] rounded-full border overflow-hidden">
                        <img
                          src={item?.userImage || user}
                          alt="user"
                          className="object-cover  w-full h-full  rounded-full"
                        />
                      </div>
                      <div className="px-1">
                        <h2 className="text-[12px] leading-[18px] font-semibold text-[#444444] truncate  lg:sidebar-expanded:w-[50px] xl:sidebar-expanded:w-full">
                          {Truncate(item?.userName, 15)}
                        </h2>
                        <p className="text-[12px] text-[#898989] leading-[18px] truncate lg:sidebar-expanded:w-[50px] xl:sidebar-expanded:w-full ">
                          {Truncate(item?.lastMessage, 15)}
                        </p>
                      </div>
                    </div>
                    <div className="w-[30%]">
                      <h2 className="text-[#98A2B3] text-[10px] flex items-center">
                        {moment.utc(item?.lastMessageTime)
                          .fromNow()}
                      </h2>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UsersList;
