import React, { useEffect, useState } from "react";
import Layout from "../../../components/layouts/mainLayout/MainLayout";

import { Link } from "react-router-dom";
import Banner from "../../MainLandingComp/helpSupport/Banner";
import {  useNavigate } from "react-router-dom";
import hitlike from "../../../assests/hit-like.png";
import hitDislike from "../../../assests/hit-dislike.png";

import { db } from "../../../confiq/firebase";
import { doc, setDoc, getDoc } from "firebase/firestore";

// import piucj from '../../../assests/pic1.png'
import Parser from "html-react-parser";
import { otherArticle } from "./data";
import { useDispatch, useSelector } from "react-redux";
import { setHelpCenter } from "../../../Redux/UserAuthSlice/UserAuthSlice";
const HelpSupportDetail = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const  help_center  = useSelector((state) => state?.userAuth); 
  const handleClick = (item, item2) => {
    let payload = {
			itemData: item,
			item2Data: item2,
		}
    dispatch(setHelpCenter(payload))
    navigate(`/help-center/locataire/Autre-Articles/${item.slug}`);
    // window.location.reload();
    // navigate(`/helpsupport_detail/${encodeURIComponent(item.question)}`, {
    // 	state: {
    // 		itemData: item,
    // 		item2Data: item2,
    // 	},
    // });
  };
  const item = help_center?.help_center?.itemData;
  const item2 = help_center?.help_center?.item2Data;

  const [articleId, setArticleId] = useState("");
  const [isArticleIdExistInFirebase, setIsArticleIdExistInFirebase] =
    useState(false);

  const [likeDislikeData, setLikeDislikeData] = useState({
    likes: 0,
    disLikes: 0,
    ratedBy: "",
  });

  useEffect(() => {
    
    let pathName = window.location.pathname;
    let splitedPath = pathName.split("/");
    setArticleId(splitedPath[splitedPath.length - 1]);
  }, []);

  useEffect(() => {
    
    let pathName = window.location.pathname;
    let splitedPath = pathName.split("/");
    let tempArticleId = splitedPath[splitedPath.length - 1];
    setArticleId(tempArticleId);
    async function fetchData() {
      const collectionRef = doc(db, "Article", tempArticleId);
      const docSnap = await getDoc(collectionRef);
      if (docSnap.exists()) {
        let data = {
          likes: docSnap.data().likes,
          disLikes: docSnap.data().disLikes,
          ratedBy: docSnap.data().ratedBy,
        };
        setIsArticleIdExistInFirebase(true);
        setLikeDislikeData(data);
      }
    }
    fetchData();
  }, []);

  function generateRandomAlphanumeric(length) {
    const alphanumericCharacters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(
        Math.random() * alphanumericCharacters.length
      );
      result += alphanumericCharacters.charAt(randomIndex);
    }
    return result;
  }

  const handleLikeButton = () => {
    const ratedBy = localStorage.getItem(articleId);
    if (!ratedBy) {
      const randomAlphanumeric = generateRandomAlphanumeric(16);
      let tempObj = {
        likes: likeDislikeData.likes + 1,
        disLikes: likeDislikeData.disLikes,
        ratedBy: randomAlphanumeric,
      };
      setLikeDislikeData(tempObj);
      setDoc(doc(db, "Article", articleId), tempObj);
      localStorage.setItem(articleId, randomAlphanumeric);
    } else if (
      !isArticleIdExistInFirebase &&
      likeDislikeData.ratedBy != localStorage.getItem(articleId)
    ) {
      const randomAlphanumeric = generateRandomAlphanumeric(16);
      let tempObj = {
        likes: likeDislikeData.likes + 1,
        disLikes: likeDislikeData.disLikes,
        ratedBy: randomAlphanumeric,
      };
      setLikeDislikeData(tempObj);
      setDoc(doc(db, "Article", articleId), tempObj);
      localStorage.setItem(articleId, randomAlphanumeric);
    }
  };

  const handleDisLikeButton = () => {
    const ratedBy = localStorage.getItem(articleId);
    if (!ratedBy) {
      const randomAlphanumeric = generateRandomAlphanumeric(16);
      let tempObj = {
        likes: likeDislikeData.likes,
        disLikes: likeDislikeData.disLikes + 1,
        ratedBy: randomAlphanumeric,
      };
      setLikeDislikeData(tempObj);
      setDoc(doc(db, "Article", articleId), tempObj);
      localStorage.setItem(articleId, randomAlphanumeric);
    } else if (
      !isArticleIdExistInFirebase &&
      likeDislikeData.ratedBy != localStorage.getItem(articleId)
    ) {
      const randomAlphanumeric = generateRandomAlphanumeric(16);
      let tempObj = {
        likes: likeDislikeData.likes + 1,
        disLikes: likeDislikeData.disLikes,
        ratedBy: randomAlphanumeric,
      };
      setLikeDislikeData(tempObj);
      setDoc(doc(db, "Article", articleId), tempObj);
      localStorage.setItem(articleId, randomAlphanumeric);
    }
  };

  return (
    <Layout>
      {/* <div className=' lg:px-[165px] md:px-[50px] px-5 py-10'> */}
      <Banner left="Centre d’aide" center="Locataire" right={item2} />
      <div className="px-5 md:px-20">
        <div className="flex flex-col  lg:flex-row  py-10  md:py-12  lg:py-14 gap-5 md:gap-7 lg:gap-9 xl:gap-12 items-start  justify-center 2xl:w-[1440px] m-auto">
          <div className="flex flex-col justify-center  gap-3 lg:w-2/3 max-w-[800px]">
            <h1 className="font-semibold text-2xl leading-[32.68px] ">
              {item?.question}
            </h1>
            <div className="font-normal text-[#5A5A5A] text-[14px] mt-[18px] detailDesc">
              {Parser(item?.answer)}
            </div>
            <div className="flex-col gap-3 lg:gap-6 lg:flex hidden items-start mt-16">
              <div className="border rounded-sm p-3 lg:p-6 items-center flex flex-wrap gap-x-7 gap-y-3">
                <div className="flex flex-col gap-1">
                  <p className="font-normal text-[4E5565] text-sm lg:text-base">
                    Cet article vous a-t-il été utile?
                  </p>
                  <p className="text-[#979797] text-xs lg:text-sm font-normal">
                    Utilisateurs l’ayant trouvé utile: {" "}
                    {likeDislikeData.likes ? likeDislikeData.likes : 0} sur{" "}
                    {likeDislikeData.disLikes + likeDislikeData.likes}
                  </p>
                </div>
                <div className="flex gap-3 cursor-pointer">
                  <div
                    onClick={() => handleLikeButton()}
                    className="w-[40px] h-[40px] border-[1px] border-[#ACACAC] rounded-[4px] flex flex-row items-center justify-center"
                  >
                    <img
                      src={hitlike}
                      alt="like"
                      className="object-contain w-[19px] h-[18px]"
                    />
                  </div>

                  <div
                    onClick={() => handleDisLikeButton()}
                    className="w-[40px] h-[40px] border-[1px] border-[#ACACAC] flex flex-row items-center justify-center rounded-[4px]"
                  >
                    <img
                      src={hitDislike}
                      alt="like"
                      className="object-contain  w-[19px] h-[18px]"
                    />
                  </div>
                </div>
              </div>

              <p className="font-normal text-[#5A5A5A] text-sm  lg:text-base">
                Vous avez d’autres questions ?{" "}
                <span className="text-[#FF8C00] font-semibold">
                  <Link to="/contact"> Contactez-nous</Link>
                </span>
              </p>
            </div>
          </div>

          {/* Hide On Large 1024 */}
          <div className="flex flex-col gap-3 lg:hidden">
            <div className="border rounded-sm p-3 items-center flex flex-wrap gap-x-7 gap-y-3">
              <div className="flex flex-col gap-1">
                <p className="font-normal text-[4E5565] text-sm lg:text-base">
                  Cet article vous a-t-il été utile?
                </p>
                <p className="text-[#979797] text-xs lg:text-sm font-normal">
                  Utilisateurs l’ayant trouvé utile:
                  {likeDislikeData.likes ? likeDislikeData.likes : 0} sur{" "}
                  {likeDislikeData.disLikes + likeDislikeData.likes}
                </p>
              </div>
              <div className="flex gap-3">
                <div
                  onClick={() => handleLikeButton()}
                  className="w-[30px] h-[30px] border-[1px] border-[#ACACAC] rounded-[4px] flex flex-row items-center justify-center"
                >
                  <img
                    src={hitlike}
                    alt="like"
                    className="object-contain w-[14.85px] h-[13.5px]"
                  />
                </div>

                <div
                  onClick={() => handleDisLikeButton()}
                  className="w-[30px] h-[30px] border-[1px] border-[#ACACAC] flex flex-row items-center justify-center rounded-[4px]"
                >
                  <img
                    src={hitDislike}
                    alt="like"
                    className="object-contain  w-[14.85px] h-[13.5px]"
                  />
                </div>
              </div>
            </div>

            <p className="font-normal text-[#5A5A5A] text-sm  lg:text-base">
              Vous avez d’autres questions ?{" "}
              <span className="text-[#FF8C00] font-semibold">
                <Link to="/contact"> Contactez-nous</Link>
              </span>
            </p>
          </div>

          <div className="flex flex-col gap-3 lg:gap-5 lg:w-1/3 max-2w-xl">
            <p className="text-lg lg:text-xl lg:font-semibold text-[#FF8C00] mb-2">
              Autre Articles
            </p>
            <div className="flex flex-col gap-3">
              {otherArticle.map((each, index) => {
                return (
                  <div key={each.title} className="flex flex-col gap-3">
                    {each.questionsAnswers.map((eachQ, indexQ) => {
                      return (
                        <p
                          className="font-normal text-[#5A5A5A] text-[14px] hover:text-[#FF8C00] text-sm cursor-pointer"
                          onClick={() => handleClick(eachQ, each.title)}
                        >
                          {eachQ.question}
                        </p>
                      );
                    })}
                  </div>
                );
              })}
              {/* <p className="font-normal text-[#5A5A5A] text-[14px]">
                                Pourrais-je déposer mon véhicule pour la location avec chauffeur ?
                            </p>
                            <p className="font-normal text-[#5A5A5A] text-[14px]">
                                Pourrais-je gérer la disponibilité de mon véhicule ?
                            </p>
                            <p className="font-normal text-[#5A5A5A] text-[14px]">
                                Le dépôt de véhicule est-il valable pour les professionnels ?
                            </p>
                            <p className="font-normal text-[#5A5A5A] text-[14px]">
                                Je loue mon véhicule exclusivement pour la zone d’Abidjan, que faire ?
                            </p> */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default HelpSupportDetail;