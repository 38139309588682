
import React, { useState } from 'react'
import loginImg from '../../assests/login.png'
import EmailFrom from '../../components/AuthComponents/ForgotPassword/EmailForm';
import OtpForm from '../../components/AuthComponents/ForgotPassword/OtpForm';
import Resetpassword from '../../components/AuthComponents/ForgotPassword/ResetPassword';

const Forgot = () => {
    const [active, setActive] = useState(0)
    const [email, setEmail] = useState('')
    const [emailOtp, setemailOtp] = useState('')
    return (
        <>
            <div className='flex lg:flex-row-reverse flex-col gap-10 justify-center lg:h-screen lg:items-center lg:px-10 '>

                <div className={`flex justify-end lg:w-[60%] max-w-2xl`}>
                    <img src={loginImg} className='object-cover w-full h-72 md:h-96 lg:h-full' alt="login_img" />
                </div>

                <div className='w-full text-enter max-w-2xl'>

                    {active === 0 && <EmailFrom handleNext={setActive} setEmail={setEmail}/>}
                    {active === 1 && <OtpForm handleNext={setActive} email={email} setemailOtp={setemailOtp} />}
                    {active === 2 && <Resetpassword  email={email} emailOtp={emailOtp}/>}
                </div>
            </div>


        </>
    )
}

export default Forgot