import React, { useEffect, useRef, useState } from "react";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";
import { GetFilter, GetFilterData } from "../../../lib/Api/WebsiteApi";
import LoaderSpinner from "../../../util/LoaderSpinner";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

const Left = ({
  payload,
  setCars,
  setPagination,
  setPriceType,
  priceType,
  setLoadingCars,
}) => {
  const [loading, setLoading] = useState(false);
  const [Cartypes, setCarTypes] = useState([]);
  const [LocationTypes, setLocationTypes] = useState([]);
  
  const [CarFeule, setCarFeuel] = useState([]);
 
 // const [CarModel, setCarModel] = useState([]);
  const [CarMake, setCarMake] = useState([]);
  const [CarPort, setCarPort] = useState([]);

  const [FormMake, setFormMake] = useState([]);
  const [FormType, setFormType] = useState([]);
  const [FormFuel, setFormFuel] = useState([]);
  const [FormDoor, setFormDoor] = useState();

  const [PriceRangeMin, setPriceRangeMin] = useState(0);
  const [PriceRangeMax, setPriceRangeMax] = useState(600000);

  const [openCarType, setOpenCarType] = useState(true);
  const [openPriceType, setOpenPriceType] = useState(true);
  const [openCarMake, setOpenCarMake] = useState(true);
  const [range, setRange] = useState(50000);

  const [isFirstSearch, setIsFirstSearch] = useState(false);
  const [priceRange, setPriceRange] = useState([0, 600000]);

  const handlePriceRangeFilter = (value) => {
    setPriceRange(value);
    FilterCar(value);
    setIsFirstSearch(true);
  };

  const handleChange = (id, type) => {
    setIsFirstSearch(true);

    if (type === "make") {
      const isSelected = FormMake.includes(id);
      if (isSelected) {
        setFormMake(FormMake.filter((selectedId) => selectedId !== id));
      } else {
        setFormMake(() => [...FormMake, id]);
      }
    } else if (type === "type") {
      const isSelected = FormType.includes(id);
      if (isSelected) {
        setFormType(FormType.filter((selectedId) => selectedId !== id));
      } else {
        setFormType(() => [...FormType, id]);
      }
    } else if (type === "door") {
      const isSelected = FormDoor === id;
      if (isSelected) {
        setFormDoor(null);
      } else {
        setFormDoor(id);
      }
    } else {
      const isSelected = FormFuel.includes(id);
      if (isSelected) {
        setFormFuel(FormFuel.filter((selectedId) => selectedId !== id));
      } else {
        setFormFuel(() => [...FormFuel, id]);
      }
    }
  };

  const FilterCar = async (price) => {
    let values = {
      ...payload,
      price_min: price ? price[0] : priceRange[0],
      price_max: price ? price[1] : priceRange[1],
      FormType,
      FormMake,
      FormFuel,
      FormDoor,
      locationPriceType:priceType
    };

    setLoadingCars(true);
    fetchFilterDataVehiclesCount(values);
    let { res } = await GetFilter(values);
    if (res) {
      setLoadingCars(false);
      setCars(res.vehicles);
      setPagination(res?.next_page);
    }
  };

  const fetchFilterDataVehiclesCount = async (dataset) => {

    var tempMakeArray =[];
      dataset.FormMake.forEach(element => {
        let temp = CarMake.filter(function (el) {
        return element == el.id_car_make
      })
      tempMakeArray.push(temp[0]);
      });

      let tempQueryMakeType = "";
      tempMakeArray.forEach((element,index) => {
        tempQueryMakeType = tempQueryMakeType+"makes[]="+element.name;
        if(index < tempMakeArray.length-1){
        tempQueryMakeType = tempQueryMakeType+"&";  
        }
      });

      let tempQueryFuelType = "";
      dataset.FormFuel.forEach((element,index) => {
        tempQueryFuelType = tempQueryFuelType+"fuel_type_id[]="+element
        if(index < dataset.FormFuel.length-1){
        tempQueryFuelType = tempQueryFuelType+"&";  
        }
      });

      let tempQueryType = "";
      dataset.FormType.forEach((element,index) => {
        tempQueryType = tempQueryType+"series_id[]="+element;
        // console.log(index)
        if(index < dataset.FormType.length-1){
        tempQueryType = tempQueryType+"&";  
        }
      });


    let tempQueryLocation = "";
       if(dataset.locationPriceType == "Grand Abidjan"){
        tempQueryLocation = "location=in_abidjan"
       } 
       if(dataset.locationPriceType == "Hors d’Abidjan"){
        tempQueryLocation = "location=out_abidjan"
       }


      let tempQueryDoor = "";
      if(FormDoor != null){
        tempQueryDoor = "no_of_doors[]="+FormDoor;
      }

      let queryParam = "";

      if(tempQueryLocation != ""){
        queryParam = queryParam+tempQueryLocation
      }

      if(tempQueryDoor != ""){
        queryParam =  queryParam+"&"+tempQueryDoor
      }

      if(tempQueryType != ""){
        queryParam =  queryParam+"&"+tempQueryType
      }
      
      if(tempQueryFuelType != ""){
        queryParam = queryParam+"&"+tempQueryFuelType
      }

      if(tempQueryMakeType != ""){
        queryParam = queryParam+"&"+tempQueryMakeType
      }

      const data = await GetFilterData(queryParam);
      setLocationTypes(data?.types_by_locaation);
      setCarTypes(data?.series);
      setCarMake(data?.makes);
      setCarFeuel(data?.fuel_types);
      setCarPort(data?.doors);
  };




  const fetchData = async () => {
    try {
      setLoading(true);
      let queryParam = "location="+payload.location;
      const data  = await GetFilterData(queryParam);
      setLocationTypes(data?.types_by_locaation);
      setCarTypes(data?.series);
      setCarMake(data?.makes);
      setCarFeuel(data?.fuel_types);
      setCarPort(data?.doors);      
      setLoading(false);
    } catch (err) {}

  };

  const handlePriceTypeChange = (value) => {
    setLoadingCars(true);
    setTimeout(() => {
      setLoadingCars(false);
    }, 2000); // Use the same duration as your animation
    setPriceType(value);
    fetchFilterDataVehiclesCount( {
      ...payload,
      price_min: priceRange[0],
      price_max: priceRange[1],
      FormType,
      FormMake,
      FormFuel,
      FormDoor,
      locationPriceType:value
    })
  };

  const DefaultCarData = async (e) => {
    let values = {
      ...payload,
    };
    setLoadingCars(true);
    let { res } = await GetFilter(values);
    if (res) {
      setLoadingCars(false);
      setCars(res.vehicles);
      setPagination(res?.next_page);
    }
  };



  const getDoorCountFromStringValue = (stringValue) => {
    return parseInt(stringValue)
  };






  useEffect(() => {
    if (isFirstSearch) {
      if (
        FormMake?.length == 0 &&
        FormType?.length == 0 &&
        FormFuel?.length == 0 &&
        (FormDoor == null || FormDoor == undefined) 
      ) {
        DefaultCarData();
      } else {
        FilterCar();
      }
    }
  }, [FormMake, FormType, FormDoor, FormFuel]);

  //console.log("FormDoor", FormDoor);

  // //console.log("model", FormMake)
  // //console.log("FormType", FormType)
  // //console.log("FormFuel", FormFuel)
  // //console.log("range", range)
  // //console.log("models", CarFeule)

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="flex flex-col gap-6 pt-6">
        <div className="flex flex-col gap-2">
          <div
            className="flex justify-between cursor-pointer"
            onClick={() => setOpenPriceType(!openPriceType)}
          >
            <p className="text-[#98A2B3] font-semibold text-xs">Déplacement</p>
            <MdOutlineKeyboardArrowUp
              className={`text-[#8D8D8D] text-[25px] ${
                openPriceType && "rotate-180 ease-in-out delay-100"
              }`}
            />
          </div>

          <div
            className={`flex flex-col gap-2 w-[96%] scrollbar-style transition-all ease-in-out duration-200 ${
              openPriceType ? "h-16 overflow-auto" : "h-0 overflow-hidden"
            }`}
          >
             {!loading ? (
              LocationTypes.map((each, index) => (
                <div key={`location-type-${each.type}`} className="flex gap-2">
                  <input
                    type="checkbox"
                    name="car-price-radio"
                    id={each.type+"-"+index}
                    checked={priceType == each.type}
                    className="custom-checkbox"
                    onChange={() => handlePriceTypeChange(each.type)}
                  />

                  <div className="flex gap-1 text-sm">
                    <label
                      className="text-[#444444] font-semibold"
                      htmlFor={each.type+"-"+index}
                    >
                      {each.type}
                                            <span className="text-[#ACACAC]">
                        {"  "}
                        {`(${each.vehicles_count})`}
                      </span>
                    </label>
                  </div>
                </div>
              ))
            ) : (
              <div className="ml-5">
                <LoaderSpinner type={"weight"} />
              </div>
            )}



          </div>

          <div
            className="flex justify-between cursor-pointer"
            onClick={() => setOpenCarType(!openCarType)}
          >
            <p className="text-[#98A2B3] font-semibold text-xs">
              Type de véhicule
            </p>
            <MdOutlineKeyboardArrowUp
              className={`text-[#8D8D8D] text-[25px] ${
                openCarType && "rotate-180 ease-in-out delay-100"
              }`}
            />
          </div>
          <div
            className={`flex flex-col gap-2 w-[96%] scrollbar-style transition-all ease-in-out duration-200 ${
              openCarType ? "h-40 overflow-auto" : "h-0 overflow-hidden"
            }`}
          >
            {!loading ? (
              Cartypes.map((each, index) => (
                <div key={`cartype-${each.id}`} className="flex gap-2">
                  <input
                    type="checkbox"
                    id={`cartype-${each.id}`}
                    className="custom-checkbox"
                    onChange={() => handleChange(each.id, "type")}
                  />

                  <div className="flex gap-1 text-sm">
                    <label
                      className="text-[#444444] font-semibold"
                      htmlFor={`cartype-${each.id}`}
                    >
                      {each.series_for_renters}
                      <span className="text-[#ACACAC]">
                        {"  "}
                        {`(${each.vehicles_count})`}
                      </span>
                    </label>
                  </div>
                </div>
              ))
            ) : (
              <div className="ml-5">
                <LoaderSpinner type={"weight"} />
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <div
            className="flex justify-between cursor-pointer"
            onClick={() => setOpenCarMake(!openCarMake)}
          >
            <p className="text-[#98A2B3] font-semibold text-xs">
              Marques populaires
            </p>
            <MdOutlineKeyboardArrowUp
              className={`text-[#8D8D8D] text-[25px] ${
                openCarMake && "rotate-180 ease-in-out delay-100"
              }`}
            />
          </div>

          <div
            className={`flex flex-col gap-2 w-[96%] scrollbar-style transition-all ease-in-out duration-200 ${
              openCarMake ? "h-40 overflow-auto" : "h-0 overflow-hidden"
            }`}
          >
            {!loading ? (
              CarMake.map((each, index) => (
                <div
                  key={`carmodel-${each?.id_car_make}`}
                  className="flex gap-2"
                >
                  <input
                    type="checkbox"
                    id={`carmodel-${each?.id_car_make}`}
                    className="custom-checkbox"
                    onChange={() => handleChange(each?.id_car_make, "make")}
                  />

                  <div className="flex gap-1 text-sm">
                    <label
                      className="text-[#444444] font-semibold"
                      htmlFor={`carmodel-${each?.id_car_make}`}
                    >
                      {each.name}
                      <span className="text-[#ACACAC]">
                        {"  "}
                        {`(${each.vehicles_count})`}
                      </span>
                    </label>
                  </div>
                </div>
              ))
            ) : (
              <div className="ml-5">
                <LoaderSpinner type={"weight"} />
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <p className="text-[#98A2B3] font-semibold text-xs">Portes</p>

          <div className="flex flex-col gap-2">
            {!loading ? (CarPort.map((each, index) => (
              <div key={`carport-${getDoorCountFromStringValue(each.value)}`} className="flex gap-2">
                <input
                  type="checkbox"
                  id={`carport-${getDoorCountFromStringValue(each.value)}`}
                  className="custom-checkbox"
                  checked={getDoorCountFromStringValue(each.value) === FormDoor}
                  onChange={() => handleChange(getDoorCountFromStringValue(each.value), "door")}
                />

                <div className="flex gap-1 text-sm">
                  <label
                    className="text-[#444444] font-semibold"
                    htmlFor={`carport-${getDoorCountFromStringValue(each.value)}`}
                  >
                    {each.value}
                    <span className="text-[#ACACAC]">
                      {"  "}
                      {`(${each.vehicles_count})`}
                    </span>
                  </label>
                </div>
              </div>
            ))  ) : (
              <div className="ml-5">
                <LoaderSpinner type={"weight"} />
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <p className="text-[#98A2B3] font-semibold text-xs">Moteur</p>

          <div className="flex flex-col gap-2">
            {!loading ? (
              CarFeule.map((each, index) => (
                <div key={`carfuel-${each.id}`} className="flex gap-2">
                  <input
                    type="checkbox"
                    id={`carfuel-${each.id}`}
                    className="custom-checkbox"
                    onChange={() => handleChange(each.id, "")}
                  />

                  <div className="flex gap-1 text-sm">
                    <label
                      className="text-[#444444] font-semibold"
                      htmlFor={`carfuel-${each.id}`}
                    >
                      {each.fuel_type}
                      <span className="text-[#ACACAC]">
                        {"  "}
                        {`(${each.vehicles_count})`}
                      </span>
                    </label>
                  </div>
                </div>
              ))
            ) : (
              <div className="ml-5">
                <LoaderSpinner type={"weight"} />
              </div>
            )}
          </div>
        </div>

        <div
          className="flex flex-col gap-2 mb-[50px]"
          style={{ maxWidth: "207px" }}
        >
          <p className="text-[#98A2B3] font-semibold text-xs">Prix</p>

          <Slider
            defaultValue={[0, 600000]}
            min={PriceRangeMin}
            max={PriceRangeMax}
            range
            step={10000}
            allowCross={false}
            onAfterChange={(value) => handlePriceRangeFilter(value)}
            trackStyle={[
              { backgroundColor: "#FF8C00" },
              { backgroundColor: "#FF8C00" },
            ]}
            handleStyle={[
              { backgroundColor: "#FF8C00" },
              { backgroundColor: "#FF8C00" },
            ]}
            railStyle={{ backgroundColor: "#ACACAC" }}
            className="rounded-2"
          />

          {/* <input
						className="appearance-none w-full"
						type="range"
						min="50000"
						max="600000"
						step="10000"
						defaultValue={range}
						onChange={(e) => setRange(e.target.value)}
					/> */}
          <p
            className="flex gap-1 font-semibold mt-[15px]"
            style={{ alignItems: "center" }}
          >
            <span className="text-[#FF8C00] ">
              {`${priceRange[0]} - ${priceRange[1]}`} FCFA
            </span>
            <span className="text-[#ACACAC] text-base"> /jour</span>
          </p>
        </div>
      </div>
    </>
  );
};

export default Left;