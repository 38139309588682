import React from 'react'
import fixez from '../../../assests/PMNeew/r1.svg'
import car from '../../../assests/PMNeew/r2.svg'
import mobile from '../../../assests/PMNeew/r3.svg'
const Management = () => {
  return (
    <div className='py-10 px-5 md:py-12 md:px-20 xl:px-16 lg:py-14 flex flex-col gap-6 md:gap-12 items-center'>

      <div className="flex flex-col gap-4 max-w-md md:max-w-7xl">
        <p className="font-bold text-2xl xl:text-3xl text-center">
          La gestion de votre véhicule est sous
          <span className="text-[#FF8c00]"> votre entière responsabilité</span>
        </p>
        <p className="text-sm xl:text-base text-[#667085] text-center">
          En tant que propriétaire, gardez le contrôle sur votre véhicule et mettez-le à disposition quand vous le jugez nécessaire.
        </p>
      </div>

      <div className="flex flex-col md:px-5 gap-6 md:flex-row xxxtra-small:px-0 md:justify-between max-w-md md:max-w-7xl">

        <div className="flex flex-col items-center gap-4 p-4 cursor-pointer rounded-md hover:shadow-lg">
          <img src={fixez} alt="fixez_img" className='w-20 h-20 md:w-24 md:h-24 lg:w-28 lg:h-28 xl:w-36 xl:h-36' />
          <p className="font-semibold text-base xl:text-xl text-[#444444] text-center">
            Fixez le prix journalier de la location
          </p>
          <p className="text-sm xl:text-base text-[#667085] text-center">
            En tant que propriétaire, gardez le contrôle sur votre véhicule et mettez-le à disposition quand vous le jugez nécessaire.
          </p>
        </div>

        <div className="flex flex-col items-center gap-4  p-4 cursor-pointer rounded-md hover:shadow-lg">
          <img src={car} alt="car" className='w-20 h-20 md:w-24 md:h-24 lg:w-28 lg:h-28 xl:w-36 xl:h-36' />
          <p className="font-semibold text-base xl:text-xl text-[#444444] text-center">
            Gérez la disponibilité de votre véhicule
          </p>
          <p className="text-sm xl:text-base text-[#667085] text-center">
            Un mode veille existe pour mettre en hibernation votre véhicule sur la plateforme quand il n’est pas disponible à la location.
          </p>
        </div>

        <div className="flex flex-col items-center gap-4  p-4 cursor-pointer rounded-md hover:shadow-lg">
          <img src={mobile} alt="mobile" className='w-20 h-20 md:w-24 md:h-24 lg:w-28 lg:h-28 xl:w-36 xl:h-36' />
          <p className="font-semibold text-base xl:text-xl text-[#444444] text-center">
            Le tout à partir de votre smartphone
          </p>
          <p className="text-sm xl:text-base text-[#667085] text-center">
            Notre application mobile est accessible à tout moment à partir de votre smartphone. Gérez-y toutes vos opérations de location.
          </p>
        </div>
      </div>

    </div>
  )
}

export default Management