import { toast } from "react-toastify"
import { callPrivateApi } from "../Apiendpoint"

// Get BroadCast 
export const GetOrders = async () => {
    try {
        let response = await callPrivateApi(`admin/orders`, 'get')
        if (response.success === true) {
            return response.data
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}


// update Order Status  
export const UpdateOrderStatus = async (data) => {
    try {
        let payload = {
            "order_id": data?.id,
            "status": "delivered"
        }
        let response = await callPrivateApi(`admin/orders/update`, 'post', payload)
        if (response.success === true) {
            return response.data
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}



// Allowed User link
export const AlowedUserLink = async (data , check ) => {
    try {
        let payload = {
            "selected_user_id": data?.id,
            "allowed_as": check ? "vehicle_owner" : "vehicle_renter"
        }
        let response = await callPrivateApi(`admin/user/allowed/links/store
        `, 'post', payload)
        if (response.success === true) {
            return response.data
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}
