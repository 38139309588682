import React, { useState } from "react";
// import { MdSearch } from "react-icons/md";
import DataList from "./DataList";
import SearchBar from "../../GenericComponents/SearchBar";
import { useEffect } from "react";
import { GetTransfer, GetTransferHistory } from "../../../lib/Api/Management";


const DataTable = ({ title }) => {
  const [active, setActive] = useState(0);
  const [detail, setDetail] = useState(false);
  const [rents, setRents] = useState([]);
  const [rentsHistroy, setRentsHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPage, setTotalPage] = useState();
  const [offsetPage, setOffsetPage] = useState(1);
  const [searchData, setSearchData] = useState("");
  const [types, setTypes] = useState(false);
  const [statusCheck, setStatusCheck] = useState("");
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  let fetch = async () => {
    setLoading(true);
    let { res, page } = await GetTransfer(offsetPage)
    let resHis = await GetTransferHistory(offsetPage)
    if (res) {
      setLoading(false);
      setRents(res);
      setRentsHistory(resHis)
      setTotalPage(page);
    }
  };

  useEffect(() => {
    if (searchData === "") {
      fetch();
    }
  }, [searchData, active]);

  return (
    <>

      <>
        <div className="flex lg:flex-nowrap flex-wrap justify-between items-center">
          <h2 className="text-[20px] leading-[20.16px] font-semibold text-[#444444]">
            Transfers Management
          </h2>
          <div className="flex lg:py-0 py-2">
            <SearchBar
              offsetPage={offsetPage}
              setSearchData={setSearchData}
              setVehicalList={setRents}
              setLoading={setLoading}
              setTypes={setTypes}
              setTotalPages={setTotalPage}
              title={title}
              setCurrentPageIndex={setCurrentPageIndex}
              setStatusCheck={setStatusCheck}
              statusCheck={statusCheck}
            />
          </div>
        </div>
        <div>
          <DataList
            type="Inactive"
            rents={rents}
            active={active}
            setDetail={setDetail}
            setRents={setRents}
            setSearchData={setSearchData}
            setOffsetPage={setOffsetPage}
            setCurrentPageIndex={setCurrentPageIndex}
            currentPageIndex={currentPageIndex}
            searchData={searchData}
            totalPage={totalPage}
            loading={loading}
            setLoading={setLoading}
          />
        </div>
      </>
      <>
        <div className="flex lg:flex-nowrap flex-wrap justify-between items-center mt-10">
          <h2 className="text-[20px] leading-[20.16px] font-semibold text-[#444444]">
            Transfer History
          </h2>
          <div className="flex lg:py-0 py-2">
            <SearchBar
              offsetPage={offsetPage}
              setSearchData={setSearchData}
              setVehicalList={setRents}
              setLoading={setLoading}
              setTypes={setTypes}
              setTotalPages={setTotalPage}
              title={title}
              setStatusCheck={setStatusCheck}
              statusCheck={statusCheck}
            />
          </div>
        </div>
        <div>
          <DataList
            type="Active"
            rents={rentsHistroy}
            active={active}
            setDetail={setDetail}
            setRents={setRents}
            setSearchData={setSearchData}
            setOffsetPage={setOffsetPage}
            setCurrentPageIndex={setCurrentPageIndex}
            currentPageIndex={currentPageIndex}
            searchData={searchData}
            totalPage={totalPage}
            loading={loading}
            setLoading={setLoading}
          />
        </div>
      </>
    </>
  );
};

export default DataTable;
