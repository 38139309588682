import { toast } from "react-toastify"
import { callPrivateApi, callPublicApi } from "../Apiendpoint"
import moment from "moment"

// ------ Coupon Api's ----- //

// Create Coupon
export const CreateCoupon = async (data) => {
    let { code, discount, valid_from, valid_to, description, min_days, max_attempts } = data
    let start_date = moment(valid_from).format('MM/DD/YYYY')
    let end_date = moment(valid_to).format('MM/DD/YYYY')
    try {
        let payload = {
            "code": code,
            "discount": discount,
            "valid_from": start_date,
            "valid_to": end_date,
            "description": description,
            "min_days": min_days,
            "max_attempts": max_attempts
        }

        let response = await callPrivateApi(`admin/coupons/store`, 'post', payload)
        if (response.success === true) {
            toast.success(response.message)
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}
// Get Coupon 
export const GetCoupon = async () => {
    try {
        let response = await callPrivateApi('admin/coupons', 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data)
            return {
                res: obj.coupons
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}

// Get Vehical base on page
export const GetCouponBaseOnPages = async (pageNo) => {
    try {
        let response = await callPrivateApi(`admin/coupons?page=${pageNo}`, 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data)
            return {
                res: obj.coupons
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}

// Update Coupon
export const UpdateCoupon = async (data) => {
    let { id, code, discount, valid_from, valid_to, description } = data
    try {

        // Convert date formate 
        // let s_date = valid_from.split("-");
        // let reversedSDate = s_date[1] + "/" + s_date[2] + "/" + s_date[0];
        // let e_date = valid_to.split("-");
        // let reversedEDate = e_date[1] + "/" + e_date[2] + "/" + e_date[0];

        let start_date = moment(valid_from).format('MM/DD/YYYY')
        let end_date = moment(valid_to).format('MM/DD/YYYY')

        let payload = {
            "coupon_id": id,
            "code": code,
            "discount": discount,
            "valid_from": start_date,
            "valid_to": end_date,
            "description": description
        }

        let response = await callPrivateApi(`admin/coupons/update`, 'post', payload)
        if (response.success === true) {
            toast.success(response.message)
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}

// Delete Coupon 
export const DeleteCoupon = async (id) => {
    try {
        let response = await callPrivateApi(`admin/coupons/delete/${id}`, 'get')
        if (response.success === true) {
            toast.success(response.message)
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}

// Create Afiliate user
export const CreateAfiliateUser = async (data) => {
    let { first_name, last_name, email, description } = data

    try {
        let payload = {
            "firstname": first_name,
            "lastname": last_name,
            "email": email,
            "notes": description
        }

        let response = await callPrivateApi(`admin/affiliate/user/store`, 'post', payload)
        if (response.success === true) {
            toast.success(response.message)
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}

// Create Afiliate user
export const SendAffiiateLink = async (id) => {
    try {
        let response = await callPrivateApi(`admin/affiliate/user/resend/${id}`, 'get')
        if (response.success === true) {
            toast.success(response.message)
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}

// Get Coupon 
export const GetAfiliateUser = async (offsetPage) => {
    try {
        let response = await callPrivateApi(`admin/affiliate/user?page=${offsetPage}&per_page=10`, 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data)
            return {
                res: obj.affiliate
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}

// Get Afliate Single 
export const GetSingleAfiliateUser = async (id) => {
    try {
        let response = await callPrivateApi(`admin/affiliate/user/${id}`, 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data)
            return {
                res: obj.affiliate
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}

// Update Coupon
export const UpdateAfiliateUser = async (data) => {
    let { first_name, last_name, email, description, id } = data

    try {
        let payload = {
            "affiliate_id": id,
            "firstname": first_name,
            "lastname": last_name,
            "email": email,
            "notes": description
        }

        let response = await callPrivateApi(`admin/affiliate/user/update`, 'post', payload)
        if (response.success === true) {
            toast.success(response.message)
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}

// Delete Coupon 
export const DeleteAfiliate = async (id) => {
    try {
        let response = await callPrivateApi(`admin/affiliate/user/delete/${id}`, 'get')
        if (response.success === true) {
            toast.success(response.message)
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}

// -----------------Service Fees ------------------------------ ///


// / Get Config
export const GetPrimeConf = async () => {
    try {
        let response = await callPrivateApi('admin/config', 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data)
            return {
                res: obj
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}

// Config verify password
export const VeifyPassword = async (data) => {
    try {
        let response = await callPublicApi("admin/login", 'post', data)
        if (response.success === true) {
            return { res: response.data }
        }
        else {
            toast.error(response.message)
            return { error: response.message }
        }
    }
    catch (err) {
        toast.error(err)

    }
}

// Update Coupon
export const UpdatePrimeConfig = async (data) => {

    try {
        let response = await callPrivateApi(`admin/config/store`, 'post', data)
        if (response.success === true) {
            toast.success(response.message)
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}