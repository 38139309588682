import React, { useEffect, useState } from 'react'
import { GetBroadCast } from '../../lib/Api/BroadCast'
import OrderTable from '../../components/AdminComponents/orders/OrderTable'
import { GetOrders } from '../../lib/Api/Orders'

const Orders = () => {
    // React hooks
    const [broadCast, setBroadCast] = useState([])
    const [loading, setLoading] = useState(false)



    useEffect(() => {
        try {
            let fetchData = async () => {
                setLoading(true)
                let res = await GetOrders()
                if (res) {
                    let obj = Object.assign({},...res)
                    setLoading(false)
                    setBroadCast(obj?.orders?.data)
                }
                else {
                    setLoading(false)
                }
            }
            
                fetchData()
        }
        catch (err) { }
    }, [])



    return (
        <>
            <OrderTable broadCast={broadCast} loading={loading} setLoading={setLoading} />
        </>
    )
}

export default Orders