import React, { useEffect, useState } from "react";
import Profile from "./RentalDetail/Profile";
import Drivinglicence from "./RentalDetail/Drivinglicence";
import PaymentInfo from "./RentalDetail/PaymentInfo";
import {
  GetVehicalOwnerDetail,
  GetVehicalRnterDetail,
} from "../../../../lib/Api/RentalAppApi";
import { useLocation, useParams } from "react-router-dom";
import LoaderSpinner from "../../../../util/LoaderSpinner";

const RenterDetail = ({ userId }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEditImg, setIsEditImg] = useState(false);
  const [rentalDetail, setRentalDetail] = useState({});

  let id = useParams().id;
  let location = useLocation().pathname;

  // let check = location === `/dashboard/vehical-detail/${id}/carlist`
  // let sendId = check ? userId : id

  // Get Renter Request
  let fetchRenterDetail = async () => {
    try {
      setLoading(true);
      let { res } = await GetVehicalRnterDetail(id);
      if (res) {
        setLoading(false);
        setRentalDetail(res);
      } else {
        setLoading(false);
      }
    } catch (err) {
      //console.log(err);
    }
  };
  // Get Owner Request
  let fetchOwnerDetail = async () => {
    try {
      setLoading(true);
      let { res } = await GetVehicalOwnerDetail(userId || id);
      if (res) {
        setLoading(false);
        setRentalDetail(res);
      } else {
        setLoading(false);
      }
    } catch (err) {
      //console.log(err);
    }
  };

  useEffect(() => {
    if (isEdit === false || isEditImg === true) {
      if (location === `/dinss/tech/vehicalSaller/detail/${id}/all`) {
        fetchRenterDetail();
        setIsEditImg(false);
      } else {
        fetchOwnerDetail();
        setIsEditImg(false);
      }
    }
  }, [id, isEdit, isEditImg]);
  return (
    <>
      {(!loading && rentalDetail.renter_details != null) ||
      rentalDetail.owner_details != null ? (
        <div>
          {isEdit && (
            <div className="text-[#ACACAC] text-[16px] pb-4">
              <span className="cursor-pointer" onClick={() => setIsEdit(false)}>
                {isEdit && location === "/dinss/tech/dashboard/vehical-detail/"
                  ? `Rental Detail > `
                  : `Owner Detail > `}
              </span>
              <span className="text-[#FF8C00] px-2">Edit</span>
            </div>
          )}
          {/* User profile  */}
          <Profile
            setIsEdit={setIsEdit}
            isEdit={isEdit}
            setIsEditImg={setIsEditImg}
            rentalUser={
              rentalDetail?.renter_details || rentalDetail?.owner_details
            }
          />
          <div className="grid lg:grid-cols-2  gap-5">
            <div>
              <Drivinglicence
                isEdit={isEdit}
                loading={loading}
                rentalUser={
                  rentalDetail?.renter_details || rentalDetail?.owner_details
                }
              />
            </div>
            <div>
              <PaymentInfo rentalUser={rentalDetail} />
            </div>
          </div>
        </div>
      ) : (
        <div>
          {loading ? <LoaderSpinner type="tableLoad" /> : "Record Not Found"}
        </div>
      )}
    </>
  );
};

export default RenterDetail;
