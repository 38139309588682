import React, { useState } from "react";
import rent from "../../../../assests/rent.png";
// import { BsEye, BsThreeDotsVertical, BsTrash } from 'react-icons/bs'
import ReactPaginate from "react-paginate";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { useLocation } from "react-router-dom";
import LoaderSpinner from "../../../../util/LoaderSpinner";
import moment from "moment";
import { FormatePrice } from "../../../../Partials/FormatePriceSpace";
// import { BiRefresh } from 'react-icons/bi';
const TransactionList = ({
  title,
  topRated,
  transaction,
  loading,
  totalPage,
}) => {

  const [itemperPage] = useState(10);

  // const endOffset = itemOffset + itemperPage;
  // const currentItems = Array(vehicalList?.total).slice(itemOffset, endOffset);
  const pageCount = Math.ceil(totalPage / itemperPage);

  const handlePageClick = async (event) => {
    // const newOffset = (event.selected * itemperPage) % Array(vehicalList?.total).length;
    // setItemOffset(newOffset);
    //  let pageNo = event.selected === 0  ? 1  : event.selected
    // setLoading(true);
    // let { res } = await GetVihicalsBaseOnPages(event.selected + 1);
    // let vehicals = Object.assign({}, ...res);
    // setLoading(false);
    // setTrans(vehicals?.vehicles);
  };

  const location = useLocation().pathname;

  return (
    <>
      <div
        className={`bg-white rounded-md ${
          title === "Transaction" && "shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)]"
        }`}
      >
        {title !== "Transaction" ? (
          <div className={` overflow-x-auto`}>
            { !loading && topRated.length > 0 ? (
              <>
                <table className="table-auto w-full ">
                  {/* Table header */}
                  <thead className="text-xs font-[500] bg-[#FFF8EF] border-0">
                    <tr className="text-[#626973]">
                      <th className="px-4 py-4 pr-[25px] whitespace-nowrap w-px">
                        <div className="font-[500] text-[14px] text-left">
                          Ranking{" "}
                        </div>
                      </th>
                      <th className="px-4 py-4 pr-[25px] whitespace-nowrap w-px">
                        <div className="font-[500] text-[14px] text-left">
                          {location === "/dinss/tech/globelOwner" ? "Owner" : "Renter"}
                        </div>
                      </th>
                      <th className="px-4 py-4 pr-[25px] whitespace-nowrap w-px">
                        <div className="font-[500] text-[14px] text-left">
                          {location === "/dinss/tech/globelRenter"
                            ? " Total Rented Vehicles"
                            : "Total Accepted requests"}{" "}
                        </div>
                      </th>
                      
                      <th className="px-4 py-4 pr-[25px] whitespace-nowrap w-px">
                        <div className="font-[500] text-[14px] text-left">
                          {location === "/dinss/tech/globelRenter"
                            ? "Total Amount Paid"
                            : "Total Amount Earned"}
                        </div>
                      </th>
                    </tr>
                  </thead>

                  {/* Table body */}
                  <tbody className="text-sm ">
                    {topRated.map((item, i) => (
                      <tr className=" cursor-pointer  " key={i}>
                        <td className="px-4 pr-[25px] py-3 whitespace-nowrap">
                          <div className="text-left text-[14px] text-[#898989]">
                            {i < 10 ? `0${i + 1}` : i + 1}
                          </div>
                        </td>
                        <td className="px-4 pr-[25px] flex items-center py-3 whitespace-nowrap">
                          <div className="object-cover w-8 h-8 rounded-full overflow-hidden flex items-center justify-center">
                            <img
                              src={item?.image || rent}
                              alt="rent_image"
                              className="w-auto"
                            />
                          </div>
                          <div className="text-left text-[14px] text-[#898989] px-1">
                            {item?.first_name}
                          </div>
                        </td>

                        <td className="px-4 pr-[25px] py-3 whitespace-nowrap">
                          <div className="text-left text-[14px] text-[#898989]">
                            {FormatePrice(item?.rents_count) || FormatePrice(item?.rented_cars_count)}
                          </div>
                        </td>
                        
                        <td className="px-4 pr-[25px] py-3 whitespace-nowrap">
                          <div className="text-left text-[14px] text-[#898989]">
                            <span className="text-[#FF8C00]">
                              {FormatePrice(item?.rents_sum_amount) ||
                                FormatePrice(item?.rented_cars_sum_amount)}{" "}
                              FCFA{" "}
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              <div className="p-5 ">
                {loading ? (
                  <LoaderSpinner type="tableLoad" />
                ) : (
                  <h2>Record not found</h2>
                )}
              </div>
            )}
          </div>
        ) : (
          <div className={` overflow-x-auto`}>
            {!loading && transaction?.length > 0 ? (
              <>
                <table className="table-auto w-full ">
                  {/* Table header */}
                  <thead className="text-xs font-[500]  bg-[#FFF8EF] border-0">
                    <tr className="text-[#626973]">
                      {/* <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                                                <div className="font-[500]  text-[14px] text-left">ID </div>
                                            </th> */}
                      <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                        <div className="font-[500]  text-[14px] text-left">
                          Renter{" "}
                        </div>
                      </th>
                      <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                        <div className="font-[500]  text-[14px] text-left">
                          Vehical
                        </div>
                      </th>
                      <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                        <div className="font-[500]  text-[14px] text-left">
                          Date
                        </div>
                      </th>
                      <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                        <div className="font-[500]  text-[14px] text-left">
                          Location
                        </div>
                      </th>

                      <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                        <div className="font-[500]  text-[14px] text-left">
                          Status{" "}
                        </div>
                      </th>
                      <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                        <div className="font-[500]  text-[14px] text-center">
                          Amount{" "}
                        </div>
                      </th>
                    </tr>
                  </thead>
                  {/* Table body */}
                  <tbody className="text-sm ">
                    {transaction?.map((item, i) => (
                      <tr className=" cursor-pointer  " key={i}>
                        {/* <td className="px-4 first:pl-4 last:pr-4 py-3 whitespace-nowrap pr-5">
                                                        <div className="text-left text-[14px] text-[#898989]">1347</div>
                                                    </td> */}
                        <td className="px-4 first:pl-4 flex items-center last:pr-5 py-3 whitespace-nowrap">
                          <div className="object-cover w-8 h-8 rounded-full overflow-hidden flex items-center justify-center">
                            <img
                              src={item?.rent?.user?.image || rent}
                              alt="rent_image"
                              className="w-auto"
                            />
                          </div>
                          <div className="text-left text-[14px] text-[#898989] px-1">
                            {item?.rent?.user?.first_name}
                          </div>
                        </td>
                        <td className="px-4 first:pl-3 last:pr-4 py-3 whitespace-nowrap">
                          <div className="text-left text-[14px] text-[#898989]">
                            {item?.rent?.vehicle?.make}
                          </div>
                        </td>
                        <td className="px-4 first:pl-4 last:pr-5 py-3 whitespace-nowrap">
                          <div className="text-left text-[14px] text-[#898989]">
                            {moment(item?.created_at).format("ll HH:mm")}
                          </div>
                        </td>
                        <td className="px-4 first:pl-4 last:pr-5 py-3 whitespace-nowrap">
                          <div className="text-left text-[14px] text-[#898989]">
                            Abidjan
                          </div>
                        </td>

                        <td className="px-4 first:pl-4 last:pr-5 py-3 whitespace-nowrap">
                          <div
                            className={`text-center py-[3px] w-fit px-2.5 text-[12px] leading-4 rounded-full`}
                            style={{
                              color: `${item?.rent?.status_object?.text_color}`,
                              backgroundColor: `${item?.rent?.status_object?.background_color}`,
                            }}
                          >
                            {item?.rent?.status_object?.text_en}
                          </div>
                        </td>
                        <td className="px-4 first:pl-4 last:pr-5 py-3 whitespace-nowrap">
                          <div className="text-center text-[14px] text-[#898989]">
                            <span className="text-[#FF8C00]">
                              {FormatePrice(item?.amount)} FCFA{" "}
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              <div className="p-5 ">
                {loading ? (
                  <LoaderSpinner type="tableLoad" />
                ) : (
                  <h2>Record not found</h2>
                )}
              </div>
            )}
          </div>
        )}

        {title === "Transaction" && (
          <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap justify-end items-end pt-6 ">
            {/* <div className="flex items-center">
              <h2 className="text-[#333333] text-[12px]">Show</h2>
              <select
                value={itemperPage}
                onChange={(e) => setItemPerPage(e.target.value)}
                className="border focus:outline-none rounded-md px-3 py-2 mx-2 text-[12px] text-[#333333]"
              >
                <option value="6">6</option>
                <option value="12">12</option>
                <option value="25">25</option>
                <option value="30">30</option>
              </select>
              <h2 className="text-[#333333] text-[12px]">
                entries of {items?.length}
              </h2>
            </div> */}
            <div className="lg:px-8  my-6 ">
              <ReactPaginate
                breakLabel="..."
                breakClassName=" bg-[#F3F3F3]"
                nextLabel={<MdOutlineKeyboardArrowRight />}
                onPageChange={handlePageClick}
                activeClassName="bg-[#FF8C00] text-white rounded"
                nextClassName="px-3  bg-[#F3F3F3] text-[20px] pt-[5px] rounded"
                previousClassName="px-3  bg-[#F3F3F3] text-[20px] pt-[5px] rounded"
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel={<MdOutlineKeyboardArrowLeft />}
                className=" flex justify-end"
                pageClassName=" w-[30px] h-[30px]  text-center mx-2 pt-[3px] rounded bg-[#F3F3F3] "
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TransactionList;
