import React, { useState, useEffect, useRef, useCallback } from "react";
import DataList from "./DataList";
import SearchBar from "../../../GenericComponents/SearchBar";
import Popup from "../../../../util/popup/Popup";
import UpdateStatusLoader from "../../statusLoaderSpinner/StatusLoader";
import { GetRegisterUsers } from "../../../../lib/Api/Management";
import LoaderSpinner from "../../../../util/LoaderSpinner";
import { callPrivateApi } from "../../../../lib/Apiendpoint";
const DataTable = ({ setDetail }) => {
  const [loading, setLoading] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [types, setTypes] = useState(true);
  const [totalPages, setTotalPage] = useState();
  const [vehicalList, setVehicalList] = useState([]);
  const [checkStatus, setStatusCheck] = useState("all");
  const [searchData, setSearchData] = useState("");
  const [isStatus, setIsStatus] = useState(false);
  const [offsetPage, setOffSetPage] = useState(1);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const tableRef = useRef(null);


  let getRegisterUsers = async () => {
    setLoading(true);
    let res = await GetRegisterUsers(offsetPage);
    setLoading(false);
    setVehicalList(res?.data);
    setTotalPage(res?.total);
  };




  useEffect(() => {
    if (searchData === "") {
      getRegisterUsers()
    }
    // ExcelData()
  }, [searchData]);


  const handleButtonClick = (link) => {
    // Create an anchor element
    const anchor = document.createElement('a');
    // Set the href attribute to the file path
    anchor.href = link; // Replace 'path/to/your/file.pdf' with the path to your file
    // Set the target attribute to '_blank' to open in a new tab
    anchor.target = '_blank';
    // Programmatically click the anchor element to open the file in a new tab
    anchor.click();
  };
  const handleDownload = async () => {
    try {
      setLoadingDownload(true)
      const response = await callPrivateApi('admin/excel-export-link', 'get')
      if (response) {
        setLoadingDownload(false)
        handleButtonClick(response?.data[0].link)
      }
    } catch (error) {
      console.error('Download error', error);
    }
  }



  return (
    <>
      {isStatus && <Popup data={<UpdateStatusLoader />} modalOpen={isStatus} onClose={() => setIsStatus(false)} />}
      <div className="flex justify-end items-end pb-2">
        {vehicalList &&
          <button onClick={() => handleDownload()} className="border rounded-md bg-[#FF8C00] px-3 py-2 text-white"> {loadingDownload ? <LoaderSpinner /> : "Export excel"} </button>
        }
      </div>
      <div className="flex lg:flex-nowarp  flex-wrap justify-between items-center">
        <h2 className="text-[20px] leading-[20.16px] font-semibold text-[#444444]">
          Registered Users
        </h2>

        <div className="flex tablet:justify-between tablet:w-full lg:py-0 py-2  xtra-small:block xxtra-small:block xtra-small:w-full xxtra-small:w-full">

          <SearchBar
            setVehicalList={setVehicalList}
            setLoading={setLoading}
            setSearchData={setSearchData}
            statusCheck={checkStatus}
            setTotalPages={setTotalPage}
            offsetPage={offsetPage}
            setStatusCheck={setStatusCheck}
          />
          {/* <div className="ml-4 xtra-small:ml-0 xxtra-small:ml-0 xxtra-small:mt-2 xtra-small:mt-2">
            <div className="text-center w-full flex justify-end xtra-small:justify-start xxtra-small:justify-start ">
              <div className="dropdown xxtra-small:w-full xtra-small:w-full relative">
                <FilterDropdown
                  setVehicalList={(val) => setVehicalList(val)}
                  setTypes={setTypes}
                  setSearchData={setSearchData}
                  searchData={searchData}
                  offsetPage={offsetPage}
                  setCurrentPageIndex={setCurrentPageIndex}
                  setTotalPage={setTotalPage}
                  setStatusCheck={setStatusCheck}
                  setLoading={(val) => setLoading(val)}
                />
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div>
        <DataList
          tableRef={tableRef}
          setIsStatus={setIsStatus}
          setDetail={setDetail}
          vehicalList={vehicalList}
          setVehicalList={setVehicalList}
          loading={loading}
          checkStatus={checkStatus}
          setSearchData={setSearchData}
          setLoading={(val) => setLoading(val)}
          setTypes={setTypes}
          setOffSetPage={setOffSetPage}
          totalPages={totalPages}
          setCurrentPageIndex={setCurrentPageIndex}
          currentPageIndex={currentPageIndex}
        />
      </div>
    </>
  );
};

export default DataTable;
