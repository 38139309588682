import React, { useState } from "react";
// import CommonDropDown from "../dropDown";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import LoaderSpinner from '../../../util/LoaderSpinner'
import { CreateSendCode } from "../../../lib/Api/BroadCast";
import Select from 'react-select';
const schema = yup.object({
  vehicle_owner_id: yup.object().shape({
    value: yup.string().required('Vehicle owner is required'),
    label: yup.string().required('Vehicle owner is required'),
  }),
  code: yup.string().required("Code is required"),
});

const SendCodeForm = ({ users , loading }) => {
  const [loadingSend, setLoadingSend] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({ mode: "onChange", resolver: yupResolver(schema) });



  const onSubmit = async (data) => {
    try {
      let payload = {
        code: data?.code,
        vehicle_owner_id: data?.vehicle_owner_id?.value
      }
      setLoadingSend(true);
      let res = await CreateSendCode(payload)
      if (res) {
        reset()
        setLoadingSend(false)
      }
      else {
        setLoadingSend(false)
      }

    } catch (err) {
      setLoadingSend(false)

    }
  };

console.log("errors", errors)

  return (
    <>

      <div className="flex justify-between">
        <div>
          <h2 className="text-[#FF8C00] font-semibold text-[20px]">
            Send Code
          </h2>
          <p className="pt-4 text-[14px] text-[#898989]">
            Here You can send code to users
          </p>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="pt-5">
          <h2 className="text-[#444444] font-semibold">Send Code </h2>
          <div className="grid lg:grid-cols-2 md:grid-cols-2 pt-5 gap-[34px]">
            <div className="flex flex-col">
              <label className="pb-3 text-[#606060] text-[14px]">
                Users
              </label>
              <Controller
                name="vehicle_owner_id"
                control={control}
                className={`border border-[#CDD2E1] h-[42px] placeholder:text-[14px] text-[14px] rounded-md text-[#ACACAC] focus:outline-none px-2.5 ${errors.vehicle_owner_id && "border-red-500"
                  }`}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={users}
                    isSearchable={loading ? false : true}
                    placeholder={loading ? 'loading....': "Please Select"}
                    onChange={(value) => field.onChange(value)}
                    value={field.value}
                  />
                )}
              />
              {errors.vehicle_owner_id && (
                <p className="text-red-500 text-sm text-start pt-1 ">
                  {errors.vehicle_owner_id?.label?.message}
                </p>
              )}
            </div>
            <div className="flex flex-col">
              <label className="pb-3 text-[#606060] text-[14px]">
                Code
              </label>
              <input
                type="text"
                placeholder="code"
                {...register("code")}
                className={`border ticket border-[#CDD2E1] h-[42px] placeholder:text-[14px] text-[14px] rounded-md text-[#ACACAC] focus:outline-none px-2.5 ${errors.code && "border-red-500"
                  }`}
              />
              {errors.code && (
                <p className="text-red-500 text-sm text-start pt-1 ">
                  {errors.code.message}
                </p>
              )}
            </div>
          </div>


          <div className="flex justify-end pt-5">
            <button type="submit" className="bg-[#FF8C00] text-white text-[14px] font-semibold h-[40px] px-[52px] rounded-md">
              {loadingSend ? <LoaderSpinner /> : "Send"}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default SendCodeForm;
