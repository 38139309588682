import React from 'react'
import car from '../../../assests/newDesign/notre_img.svg'
const NotreMobility = () => {
    return (
        <>
            <div className='bg-notreMobiity bg-center bg-cover bg-no-repeat flex items-center justify-center'>
                <h2 className='md:text-[31px] font-sans text-center text-white text-[22px] font-bold md:py-[100px] py-[40px] px-[20px]'>Notre métier, c’est faciliter <span className='text-[#FF8C00]'>votre mobilité</span></h2>
            </div>
        </>
    )
}

export default NotreMobility