import { toast } from "react-toastify";
import {
	callPrivateApi,
	callPublicApi,
	callPublicApiWithoutToken,
} from "../Apiendpoint";

// Get car model , year , make
export const GetMetaData = async () => {
	try {
		let responseModel = await callPrivateApi(
			"website/vehicle/models/176",
			"get"
		);
		let responseMake = await callPrivateApi(
			"website/vehicle/popular-makes/1",
			"get"
		);
		let responseYears = await callPrivateApi(
			"website/vehicle/years/1810",
			"get"
		);

		if (responseModel.success === true) {
			return {
				resModel: responseModel.data,
				resMake: responseMake.data,
				resYear: responseYears.data,
			};
		} else {
			toast.error(responseModel.message);
		}
	} catch (err) {
		toast.error(err);
	}
};

// Get car model , year , make
export const GetFilterBaseOnId = async (id, type) => {
	try {
		let check =
			type === "model"
				? `website/vehicle/models/${id}`
				: `website/vehicle/years/${id}`;
		let response = await callPrivateApi(check, "get");

		if (response.success === true) {
			return { res: response.data };
		} else {
			toast.error(response.message);
		}
	} catch (err) {
		toast.error(err);
	}
};

// Get car model , type  , fuel, door
export const GetFilterData = async (queryParam = "") => {
	

	let filtersUrl = "website/vehicle/filter-metadata/1"


	// let seriesUrl = "website/vehicle/series";
	// let makesUrl = "website/vehicle/popular-makes/1";
	// let fuelUrl = "website/vehicle/fuel-types";
	// let doorsUrl = "website/vehicle/doors";
	
	if(queryParam != ""){
		filtersUrl = filtersUrl+"?"+queryParam;
		// seriesUrl=seriesUrl+"?"+queryParam;
		// makesUrl=makesUrl+"?"+queryParam;
		// fuelUrl=fuelUrl+"?"+queryParam;
		// doorsUrl=doorsUrl+"?"+queryParam;
	}
	
	try {

		let responseData = await callPrivateApi(filtersUrl,"get")


		// let responseTypes = await callPrivateApi(
		// 	seriesUrl,
		// 	"get"
		// );
		// // let responseModel = await callPrivateApi(
		// // 	"website/vehicle/models/176",
		// // 	"get"
		// // );
		// let responseMakes = await callPrivateApi(
		// 	makesUrl,
		// 	"get"
		// );
		// let responseFuel = await callPrivateApi(
		// 	fuelUrl,
		// 	"get"
		// );
		
		// let responseDoor = await callPrivateApi(
		// 	doorsUrl,
		// 	"get"
		// );
		// if (
		// 	responseMakes.success === true &&
		// 	responseTypes.success === true &&
		// 	responseFuel.success === true &&
		// 	responseDoor.success === true
		// ) {
		// 	return {
		// 		resMakes: responseMakes.data,
		// 		resType: responseTypes.data,
		// 		resFuel: responseFuel.data,
		// 		resDoor: responseDoor.data,
		// 	};
		// } else {
		// 	toast.error(responseMakes.message);
		// }

		if (
			responseData.success === true
		) {
			return responseData.data[0];
			// return {
			// 	resLocations: responseData.data.types_by_locaation,
			// 	resMakes: responseData.data.series,
			// 	resType: responseData.data.makes,
			// 	resFuel: responseData.data.doors,
			// 	resDoor: responseData.data.fuel_types,
			// };
		} else {
			toast.error(responseData.message);
		}


	} catch (err) {
		toast.error(err);
	}
};


// Get car model , type  , feule
export const GetFilterCar = async (payload) => {
	try {
		let response = await callPrivateApi(
			"website/vehicle/price-suggestion",
			"post",
			payload
		);

		if (response.success === true) {
			let obj = Object.assign({}, ...response?.data);
			return { res: obj?.suggested_price };
		} else {
			toast.error(response.message);
		}
	} catch (err) {
		toast.error(err);
	}
};

// Get car From filter
export const GetFilter = async (data) => {
	const {
		longitude,
		latitude,
		start_date,
		FormType,
		FormMake,
		FormFuel,
		FormDoor,
		start_time,
		end_date,
		end_time,
		price_min,
		location,
		price_max,
	} = data;

	


	try {
		let payload = {
			longitude: longitude,
			vehicle_category: "non-commercial",
			location: location,
			start_date: start_date,
			start_time: start_time,
			available_instant: false,
			end_time: end_time,
			end_date: end_date,
			latitude: latitude,
			makes: FormMake,
			price_min: price_min,
			price_max: price_max,
			vehicle_series: FormType,
			fuel_type_id: FormFuel,
			// "gearbox": [],
			// "no_of_places": 5,
			// "vehicle_age": 0,
		};
		if(FormDoor){
			payload.no_of_doors=FormDoor 
		}
		let response = await callPublicApi(
			"website/vehicle/filter?per_page=12",
			"post",
			payload
		);

		if (response.success === true) {
			let obj = Object.assign({}, ...response?.data);
			return { res: obj };
		} else {
			toast.error(response.message);
		}
	} catch (err) {
		toast.error(err);
	}
};

export const GetFilterByPage = async (data, pageNo) => {
	try {
		const {
			longitude,
			latitude,
			start_date,
			FormType,
			FormModel,
			FormFuel,
			FormDoor,
			start_time,
			end_date,
			end_time,
			price_min,
			location,
			price_max,
		} = data;
		let payload = {
			longitude: longitude,
			vehicle_category: "non-commercial",
			location: location,
			start_date: start_date,
			start_time: start_time,
			available_instant: false,
			end_time: end_time,
			end_date: end_date,
			latitude: latitude,
			// "vehicle_type": FormModel,
			price_min: price_min,
			price_max: price_max,
			vehicle_series: FormType,
			fuel_type_id: FormFuel,
			no_of_doors: FormDoor,
			// "gearbox": [],
			// "no_of_places": 5,
			// "vehicle_age": 0,
		};
		let response = await callPublicApi(
			`website/vehicle/${pageNo}&per_page=12`,
			"post",
			payload
		);
		if (response.success === true) {
			let obj = Object.assign({}, ...response?.data);
			return { res: obj };
		} else {
			toast.error(response.message);
		}
	} catch (err) {
		toast.error(err);
	}
};

// Professional Page Store Form
export const StoreContactForm = async (payload) => {
	try {
		let response = await callPrivateApi(
			"website/contact-us-professional",
			"post",
			payload
		);

		if (response.success === true) {
			return { res: response.data };
		} else {
			toast.error(response.message);
		}
	} catch (err) {
		toast.error(err);
	}
};

// Contact Page Store Form
export const ContactPageStoreForm = async (payload) => {
	try {
		let response = await callPrivateApi(
			"website/contact-us",
			"post",
			payload
		);

		if (response.success === true) {
			return { res: response.data };
		} else {
			toast.error(response.message);
		}
	} catch (err) {
		toast.error(err);
	}
};

// Main Page Subscribe Form
export const SubscribeForm = async (payload) => {
	try {
		let response = await callPrivateApi(
			"website/newsletter",
			"post",
			payload
		);

		if (response.success === true) {
			return { res: response.data };
		} else {
			toast.error(response.message);
		}
	} catch (err) {
		toast.error(err);
	}
};

// Professional Page Store Form
export const GetVehicalReviews = async (id) => {
	try {
		let response = await callPublicApi(
			`website/vehicle/reviews/${id}`,
			"get"
		);

		if (response.success === true) {
			let obj = Object.assign({}, ...response.data);
			return { res: obj.reviews };
		} else {
			toast.error(response.message);
		}
	} catch (err) {
		toast.error(err);
	}
};

export const GetVehicleDetails = async (id) => {
	try {
		let response = await callPublicApi(
			`website/vehicle/details/${id}`,
			"get"
		);
		if (response.success === true) {
			let obj = Object.assign({}, ...response.data);
			return { res: obj.vehicle_details };
		} else {
			toast.error(response.message);
		}
	} catch (err) {
		toast.error(err);
	}
};