import { toast } from "react-toastify"
import { callPrivateApi } from "../Apiendpoint"


// Get Roles
export const GetRoles = async () => {
    try {
        let response = await callPrivateApi('admin/roles', 'get')
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}

// InviteMember
export const InviteMember = async (data) => {
    let { name, email, role } = data
    try {
        let payload = {
            "name": name,
            "email": email,
            "role_id": role
        }

        let response = await callPrivateApi('admin/invite-new-admin', 'post', payload)
        if (response.success === true) {
            toast.success(response.message)
            return {
                res: response
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}

// Get Invititions
export const GetInvitation = async () => {
    try {
        let response = await callPrivateApi('admin/admins', 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data)
            return {
                res: obj?.roles
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}

// Update  InviteMember
export const EditInviteNember = async (data) => {
    try {
        
        let response = await callPrivateApi('admin/edit-admin', 'post', data)
        if (response.success === true) {
            toast.success(response.message)
            return {
                res: response
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}


// del Invititions
export const DelInvitation = async (id) => {
    try {
        let response = await callPrivateApi(`admin/delete-admin/${id}`, 'get')
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}



// Edit Profile
export const EditAdminProfile = async (data) => {
    try {
        let response = await callPrivateApi(`admin/edit-profile/${data?.id}`, 'post', data)
        if (response.success === true) {
            toast.success(response.message)
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}
// Edit Profile After Invite
export const EditProfileAfterInvite = async (data) => {
    let { first_name, last_name, email, phone_number, user_id, password } = data
    try {
        let payload = {
            "user_id": user_id,
            "first_name": first_name,
            "last_name": last_name,
            "email": email,
            "password": password,
            "phone_number": phone_number
        }
        let response = await callPrivateApi('admin/register', 'post', payload)
        if (response.success === true) {
            toast.success(response.message)
            return {
                data: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}
// Get Dashboard Graph State
export const GetGrapState = async (month) => {
    try {
        let payload = {
            "month": month
        }

        let response = await callPrivateApi('admin/earnings-graph', 'post', payload)
        if (response.success === true) {
            // toast.success(response.message)
            let obj = Object.assign({}, ...response?.data)
            return {
                resGraph: obj
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}

// export const DeleteLeased= async (id) => {
//     try {

//         let response = await callPrivateApi(`/servicer/lease-builder/destroy/${id}`, 'post')
//         if (response.success === true) {
//             toast.success(response.message)
//             return {
//                 res: response.data
//             }
//         }
//         else {
//             toast.error(response.message)
//         }
//     }
//     catch (err) {
//         toast.error(err)

//     }
// }