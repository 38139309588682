import React, { useState, useEffect } from "react";
import DataList from "./DataList";
import FilterDropdown from "../../../../util/dropdowns/FilterDropdown";
import { useLocation } from "react-router-dom";
import SearchBar from "../../../GenericComponents/SearchBar";
import Popup from "../../../../util/popup/Popup";
import UpdateStatusLoader from "../../statusLoaderSpinner/StatusLoader";
import { GetSeller, SearchSeller } from "../../../../lib/Api/SellerApi";
const DataTable = ({ setDetail }) => {
  const [loading, setLoading] = useState(false);
  const [types, setTypes] = useState(true);
  const [totalPages, setTotalPage] = useState();
  const location = useLocation().pathname;
  const [vehicalList, setVehicalList] = useState([]);
  const [checkStatus, setStatusCheck] = useState("all");
  const [searchData, setSearchData] = useState("");
  const [isStatus, setIsStatus] = useState(false);
  const [offsetPage, setOffSetPage] = useState(1);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);


  let fetch = async () => {
    setLoading(true);
    let {res} = await SearchSeller("", checkStatus, 1);
    setLoading(false);
    setVehicalList(res.data);
    setTotalPage(res.total);
  };


  useEffect(() => {
    if (searchData === "") {
      fetch();
    }
  }, [searchData, location]);


  return (
    <>
      {isStatus && <Popup data={<UpdateStatusLoader />} modalOpen={isStatus} onClose={() => setIsStatus(false)} />}

      <div className="flex lg:flex-nowarp  flex-wrap justify-between items-center">
        <h2 className="text-[14.4px] leading-[20.16px] font-semibold text-[#444444]">
          Vehicle Sales List
        </h2>

        <div className="flex tablet:justify-between tablet:w-full lg:py-0 py-2  xtra-small:block xxtra-small:block xtra-small:w-full xxtra-small:w-full">

          <SearchBar
            setVehicalList={setVehicalList}
            setLoading={setLoading}
            setSearchData={setSearchData}
            statusCheck={checkStatus}
            setTotalPages={setTotalPage}
            offsetPage={offsetPage}
            setCurrentPageIndex={setCurrentPageIndex}
            setStatusCheck={setStatusCheck}
          />
          <div className="ml-4 xtra-small:ml-0 xxtra-small:ml-0 xxtra-small:mt-2 xtra-small:mt-2">
            <div className="text-center w-full flex justify-end xtra-small:justify-start xxtra-small:justify-start ">
              <div className="dropdown xxtra-small:w-full xtra-small:w-full relative">
                <FilterDropdown
                  setVehicalList={(val) => setVehicalList(val)}
                  setTypes={setTypes}
                  setSearchData={setSearchData}
                  searchData={searchData}
                  offsetPage={offsetPage}
                  setCurrentPageIndex={setCurrentPageIndex}
                  setTotalPage={setTotalPage}
                  setOffSetPage={setOffSetPage}
                  setStatusCheck={setStatusCheck}
                  setLoading={(val) => setLoading(val)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <DataList
          setIsStatus={setIsStatus}
          setDetail={setDetail}
          vehicalList={vehicalList}
          setVehicalList={setVehicalList}
          loading={loading}
          checkStatus={checkStatus}
          setSearchData={setSearchData}
          setLoading={(val) => setLoading(val)}
          setTypes={setTypes}
          setOffSetPage={setOffSetPage}
          totalPages={totalPages}
          setCurrentPageIndex={setCurrentPageIndex}
          currentPageIndex={currentPageIndex}
        />
      </div>
    </>
  );
};

export default DataTable;
