import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);



const CarListPieChart = ({statis}) => {

  let renterLable =  ["rejected_requests", "canceled_requests", "approved_requests", "extended_requests ","received_requests" ]
  // "rejected_requests": 0,
  // "canceled_requests": 0,
  // "approved_requests": 0,
  // "extended_requests": 0,
  // "received_requests": 0
  let renterDataSet = [
    {
      data: [statis.rejected_requests ,statis.canceled_requests, statis.approved_requests, statis.extended_requests, statis.received_requests],
      backgroundColor: ["#58D764","#FF903E","#FBE947","#EF7BE3","#52ACFF"],
      borderColor: ["#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
      borderWidth: 1,
    },
  ]

 

   const data = {
    labels:  renterLable,
    datasets: renterDataSet
  };
  return (
    <>
      <div className="flex lg:flex-nowrap lg:flex-col xl:flex-row justify-center   xl:justify-between items-center flex-wrap  ">
        <div className="flex pt-[2rem] ">
            <ul>
              <li className="justify-between flex items-center">
                <div className="flex items-center ">
                  <div className="border w-[13px] h-[13px] bg-[#58D764]" />
                  <h2 className="pl-1 text-[15px] text-[#444444]">
                    Rejected Requests
                  </h2>
                </div>
                <h2 className="pl-5 text-[15px] text-[#444444]">{statis?.rejected_requests}</h2>
              </li>
              <li className="justify-between flex items-center py-2">
                <div className="flex items-center ">
                  <div className="border w-[13px] h-[13px] bg-[#FF903E]" />
                  <h2 className="pl-1 text-[15px] text-[#444444]">
                    Cancelled Requests
                  </h2>
                </div>
                <h2 className="pl-5 text-[15px] text-[#444444]">{statis?.canceled_requests}</h2>
              </li>
              <li className="justify-between flex items-center">
                <div className="flex items-center ">
                  <div className="border w-[13px] h-[13px] bg-[#FBE947]" />
                  <h2 className="pl-1 text-[15px] text-[#444444]">
                    Approved Requests
                  </h2>
                </div>
                <h2 className="pl-5 text-[15px] text-[#444444]">{statis?.approved_requests}</h2>
              </li>
              <li className="justify-between flex items-center py-2">
                <div className="flex items-center ">
                  <div className="border w-[13px] h-[13px] bg-[#EF7BE3] " />
                  <h2 className="pl-1 text-[15px] text-[#444444]">
                    Extended Requests
                  </h2>
                </div>
                <h2 className="pl-5 text-[15px] text-[#444444]">{statis?.extended_requests}</h2>
              </li>
              <li className="justify-between flex items-center">
                <div className="flex items-center ">
                  <div className="border w-[13px] h-[13px] bg-[#52ACFF]" />
                  <h2 className="pl-1 text-[15px] text-[#444444]">
                    Received Requests{" "}
                  </h2>
                </div>
                <h2 className="pl-5 text-[15px] text-[#444444]">{statis?.received_requests}</h2>
              </li>
            </ul>
        </div>
        <div className="max-w-[{statis?.received_requests}0px] lg:py-0 py-3">
          <Pie
            data={data}
            className="w-auto h-auto"
            options={{
              responsive: true,
              plugins: {
                legend: {
                  display: false,
                  position: "left",
                },
              },
            }}
          />
        </div>
      </div>
    </>
  );
};
export default CarListPieChart;
