import React from "react";
import delIcon from "../../../assests/delIcon.png";
import { DeleteCoupon } from "../../../lib/Api/Marketing";
import { useState } from "react";
import LoaderSpinner from "../../../util/LoaderSpinner";
import { DeleteRent, DeleteVehical } from "../../../lib/Api/Vehical";
import { useLocation } from "react-router-dom";
import { DelInvitation } from "../../../lib/Api/AdminApi";
const DeleteData = ({
  setShow,
  singleCoupon,
  handleClose,
  type,
  callback,
  // setType,
  // setTypes,
}) => {
  // React hooks
  const [loading, setLoading] = useState(false);

  const location = useLocation().pathname;

  let delUser = async () => {
    try {
      setLoading(true)
      let { res } = await DelInvitation(singleCoupon);
      if (res) {
        setLoading(false);
        callback();
      } else {
        setLoading(false);
      }
    }
    catch (err) { }
  }


  let deleteCop = async () => {
    try {
      setLoading(true);
      let { res } = await DeleteCoupon(singleCoupon);
      if (res) {
        setLoading(false);
        callback();
      } else {
        setLoading(false);
      }
    } catch (err) { }
  };

  let deleteVeh = async () => {
    try {
      setLoading(true);
      // let apiCall =
      let check =
        location === "/dinss/tech/dashboard" ||
          location === "/dinss/tech/customerOwner" ||
          location === "/dinss/tech/vehicalOwner" ||
          location === "/globalOwner" ||
          location === "/dinss/tech/management"
          ? DeleteVehical(singleCoupon)
          : DeleteRent(singleCoupon);
      let { res } = await check;
      if (res) {
        setLoading(false);
        // setTypes(false);
        // setType("");
        callback()
      } else {
        setLoading(false);
      }
    } catch (err) { }
  };

  // handleDelete
  const handleDelete = async () => {
    if (type === "vehical" || type === "rent") {
      deleteVeh();
    }
    else if (type === "User") {
      delUser()
    }
    else {
      deleteCop();
    }
  };

  return (
    <>
      <div className="text-center p-5">
        <div className="flex justify-center items-center">
          <img src={delIcon} alt="delicon" className="object-cover" />
        </div>
        <div className="py-5">
          <h1 className="text-[#292929] text-[20px] font-semibold">
            Do you want to delete this{" "}
            {type ? type : "Promo code"}
          </h1>
          <p className="text-[#666666] leading-[20px] text-[16px] !mt-3 ">
            Are you sure you want to delete this{" "}
            {type ? type : " code"}
          </p>
          <div className="pt-5">
            <button
              onClick={() => setShow(false)}
              className="w-[138px] py-2 rounded-md bg-transparent border-[#ACACAC] border text-[#ACACAC]"
            >
              Cancel
            </button>
            <button
              onClick={() => handleDelete()}
              className="w-[138px] lg:my-0 md:my-0 my-2 py-2 lg:ml-3 rounded-md bg-[#F04438] text-white"
            >
              {loading ? <LoaderSpinner /> : "Delete"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteData;
