import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { useLocation, useParams } from "react-router-dom";

ChartJS.register(ArcElement, Tooltip, Legend);



const CarListPieChart = ({statis}) => {

  const location = useLocation().pathname
  const id = useParams().id
  let checkLoc = location === `/dinss/tech/vehicalSaller/detail/${id}/carlist` 

  let renterLable =  ["rejected_requests", "canceled_requests", "approved_requests", "extended_requests ","received_requests" ]
  let salesLable =  ["appointment_requests", "requests",]
  let renterDataSet = [
    {
      data: [statis.rejected_requests ,statis.canceled_requests, statis.approved_requests, statis.extended_requests, statis.received_requests],
      backgroundColor: ["#58D764","#FF903E","#FBE947","#EF7BE3","#52ACFF"],
      borderColor: ["#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
      borderWidth: 1,
    },
  ]
  let salesDataSet = [
    {
      data: [statis.rejected_requests ,statis.canceled_requests],
      backgroundColor: ["#58D764","#FF903E",],
      borderColor: ["#FFFFFF", "#FFFFFF"],
      borderWidth: 1,
    },
  ]

 

   const data = {
    labels: checkLoc ? salesLable :   renterLable,
    datasets: checkLoc ? salesDataSet : renterDataSet 
  };
  return (
    <>
      <div className="flex lg:flex-nowrap lg:flex-col xl:flex-row justify-center   xl:justify-between items-center flex-wrap  ">
        <div className="flex pt-[2rem] ">
            <ul>
              <li className="justify-between flex items-center">
                <div className="flex items-center ">
                  <div className="border w-[13px] h-[13px] bg-[#58D764]" />
                  <h2 className="pl-1 text-[15px] text-[#444444]">
                  Appointment Requests
                  </h2>
                </div>
                <h2 className="pl-5 text-[15px] text-[#444444]">{statis?.rejected_requests}</h2>
              </li>
              <li className="justify-between flex items-center py-2">
                <div className="flex items-center ">
                  <div className="border w-[13px] h-[13px] bg-[#FF903E]" />
                  <h2 className="pl-1 text-[15px] text-[#444444]">
                  Requests
                  </h2>
                </div>
                <h2 className="pl-5 text-[15px] text-[#444444]">{statis?.canceled_requests}</h2>
              </li>
            </ul>
        </div>
        <div className="max-w-[{statis?.received_requests}0px] lg:py-0 py-3">
          <Pie
            data={data}
            className="w-auto h-auto"
            options={{
              responsive: true,
              plugins: {
                legend: {
                  display: false,
                  position: "left",
                },
              },
            }}
          />
        </div>
      </div>
    </>
  );
};
export default CarListPieChart;
