import { useEffect, useState } from "react";
// import user from "../../../../assests/Ellipse 1490.png";
import file from "../../../../assests/filesend.svg";
import send from "../../../../assests/send.svg";
import ffile from "../../../../assests/ffile.png";
import del from "../../../../assests/del.png";
import friend from "../../../../assests/Ellipse 1490 (1).png";
import { BsThreeDotsVertical } from "react-icons/bs";
// import { useLocation } from 'react-router-dom'
import { Firebaseapp } from "../../../../confiq/firebase";
import { getFirestore, collection, query, addDoc, updateDoc, orderBy, doc, onSnapshot } from "firebase/firestore";
import { useLocation, useParams } from "react-router-dom";
import LoaderSpinner from "../../../../util/LoaderSpinner";
import moment from "moment";
import { useRef } from "react";
import { useSelector } from "react-redux";
const Messages = ({ type, convId, ticketDetail, currentUser, title, users, setUpdateMessage }) => {
  const [Messages, setMessages] = useState({});
  const [loading, setLoading] = useState(false)
  const [UserMessage, setMessage] = useState("");
  const [unsubscribe, setUnsubscribe] = useState(null);
  const user = useSelector((state) => state?.userAuth?.userInfo)

  let { id } = useParams();
  let location = useLocation().pathname
  const fetchMessages = async () => {
    try {
      const db = getFirestore(Firebaseapp);
      setLoading(true)
      const q = query(
        collection(db, `Users/${convId?.renterId}/Conversation/${convId?.conversationId}/Messages`),
        orderBy('messageTime', 'desc')
      );

      const newUnsubscribe = onSnapshot(q, (querySnapshot) => {
        const newMessages = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        newMessages.sort((a, b) => new Date(a.messageTime) - new Date(b.messageTime));

        const groupedMessages = {};
        newMessages.forEach((message) => {
          const date = new Date(message.messageTime).toLocaleDateString();
          if (!groupedMessages[date]) {
            groupedMessages[date] = [];
          }
          groupedMessages[date].push(message);
        });
        setMessages(groupedMessages);
        setLoading(false);
      });

      setUnsubscribe(() => newUnsubscribe);
    } catch (error) {
      console.error('Error fetching messages:', error);
      setLoading(false);
    }
  };
  const utcTime = moment.utc().format('YYYY-MM-DD HH:mm:ss');


  let sender = async () => {
    const db = getFirestore(Firebaseapp);
    const q = query(
      collection(
        db,
        `Users/${convId?.userInfo === "ticketDetail" ? convId?.otherUserId : id}/Conversation/${convId?.conversationId}/Messages`
      )
    );
    let check2 = title === "Support" ? "0" : convId?.userInfo === "ticketDetail" ? '0' : `${id}`
    let res = await addDoc(q, {
      conversationId: `${convId?.conversationId}`,
      senderId: check2,
      messageText: UserMessage,
      messageImage: "",
      messageId: "",
      isRead: false,
      messageTime: utcTime,
      messageType: "text",
    });
    if (res) {
      setMessage('')
    }
    const updateMessage = query(
      doc(
        db,
        `Users/${convId?.userInfo === "ticketDetail" ? convId?.otherUserId : id}/Conversation/${convId?.conversationId}/Messages/${res?.id}`
      )
    );

    try {
      await updateDoc(updateMessage, {
        messageId: res?.id
      });
    } catch (error) {
      console.error('Error updating message:', error);
    }
    const conversationDocRef = doc(
      db,
      `Users/${convId?.userInfo === "ticketDetail" ? convId?.otherUserId : id}/Conversation/${convId?.conversationId}`,
    );
    setUpdateMessage("message send")
    try {
      await updateDoc(conversationDocRef, {
        lastMessage: UserMessage,
        lastMessageTime: utcTime,
        lastMessageId: res?.id
        // otherUserId: title === "Support" ? "0" : convId?.userInfo === "ticketDetail" ? `0` : `${id}`,
      });
      // console.log('Last message in conversation updated successfully!');
    } catch (error) {
      console.error('Error updating last message in conversation:', error);
    }

  }

  let Reciever = async () => {
    let check = title === "Owner" ? convId?.otherUserId : convId?.ownerId
    const db = getFirestore(Firebaseapp);
    const q = query(
      collection(
        db,
        `Users/${check}/Conversation/${convId?.conversationId}/Messages`
      )
    );
    let check2 = convId?.userInfo === "ticketDetail" ? convId?.otherUserId : `${id}`
    let res = await addDoc(q, {
      conversationId: `${convId?.conversationId}`,
      senderId: check2,
      messageText: UserMessage,
      messageImage: "",
      messageId: "",
      isRead: false,
      messageTime: utcTime,
      messageType: "text",
    });
    const messageUpdate = query(
      doc(
        db,
        `Users/${check}/Conversation/${convId?.conversationId}/Messages/${res?.id}`
      )
    );

    try {
      await updateDoc(messageUpdate, {
        messageId: res?.id
      });
      // console.log('message updated successfully!');
    } catch (error) {
      console.error('Error updating message:', error);
    }


    const conversationDocRef = doc(
      db,
      `Users/${check}/Conversation/${convId?.conversationId}`,
    );

    try {
      await updateDoc(conversationDocRef, {
        lastMessage: UserMessage,
        lastMessageTime: utcTime,
        lastMessageId: res?.id
        // otherUserId:  `${id}`,
      });
    } catch (error) {
      console.error('Error updating last message in conversation:', error);
    }

  }

  const sendMessage = async () => {
    sender()
    Reciever()
    setUpdateMessage('')

  }

  const handleSubmit = (e) => {
    e.preventDefault()
    sendMessage()
  }


  const ref = useRef(null);
  useEffect(() => {
    // Scroll down when messages change
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }, [Messages]);



  useEffect(() => {
    fetchMessages();
    // Cleanup function
    return () => {
      if (unsubscribe) {
        unsubscribe(); // Ensure that unsubscribe is a function before calling
      }
    };
  }, [convId]);


  let userInfo = convId?.userInfo === "ticketDetail"


  return (
    <>
      <div className="flex flex-col flex-auto h-full ">
        <div
          className={`flex flex-col flex-auto flex-shrink-0 relative   ${type === "ticket"
            ? "bg-white shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] rounded-md"
            : "bg-gray-100 rounded-md"
            } h-full relative p-4`}
        >
          {!loading && userInfo && Object.keys(Messages).length > 0 || users?.length > 0 ? (
            <>
              <div className='flex justify-between items-center pb-4'>
                <div className='flex items-center'>
                  <div className="h-[60px] w-[60px] rounded-full overflow-hidden">
                    <img
                      src={convId?.userImage || ticketDetail?.user?.image}
                      alt="user"
                      className="object-cover  h-[60px] w-[60px]"
                    />
                  </div>
                  <div className='px-3'>
                    <h2 className="lg:text-[26px] lg:leading-[39px] text-[18px] font-semibold text-[#444444]">
                      {convId?.userName || ticketDetail?.user?.first_name}
                    </h2>
                    {convId?.isOnline && (
                      <p className="text-[12px] text-[#898989] flex items-center leading-[18px]">
                        <p className="w-[10px] mr-2 h-[10px] bg-[#06A551] rounded-full border-0" />
                        Online
                      </p>
                    )}
                  </div>
                </div>
                <div className='bg-white rounded-md relative flex z-10 items-center justify-center h-[40px] w-[36px]'>
                  <div class="group inline-block  ">
                    <button
                      class="outline-none focus:outline-none  px-3 py-1  flex items-center"
                    >
                      <BsThreeDotsVertical className='text-[20px] text-[#898989]' />

                    </button>
                    <ul
                      class={`bg-white shadow-md border broder-gray-100  rounded-md transform scale-0 group-hover:scale-100 absolute 
                    transition duration-150 ease-in-out top-full w-[150px] -right-12  `}
                    >
                      <li class="rounded-sm cursor-pointer actions flex items-center px-3 py-3 hover:bg-gray-100">
                        <img src={ffile} alt="file_img" className='object-cover' />
                        <h2 className='ml-2 text-[14px]' >View details</h2>
                      </li>

                      <li class="rounded-sm cursor-pointer flex items-center px-3 py-3 hover:bg-gray-100">
                        <img src={del} alt="file_img" className='object-cover' />
                        <h2 className='ml-2 text-[14px]'>Delete</h2>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {type === "ticket" ? "" :
                <p className='border border-gray-200' />}
              <div className={` ${location === `/dinss/tech/ticket-detail/${id}` ? 'max-h-[600px] xl:pb-auto lg:pb-auto md:pb-auto pb-20' : 'max-h-[400px] xl:pb-auto lg:pb-auto md:pb-auto pb-20'} overflow-y-auto  user_list`} ref={ref}>
                {Object.keys(Messages).map((date) => (
                  <>
                    <div className={`${type === "ticket" ? 'pt-0' : 'pt-5'}  flex justify-between items-center`}>
                      <p className='border border-[#AEB2C0] opacity-[0.5] w-full' />
                      <p className='text-[12px] text-[#444444] w-[50%] text-center'>{date}</p>
                      <p className='border border-[#AEB2C0] opacity-[0.5] w-full' />
                    </div>
                    {Messages[date].map((message) => {
                      return (
                        <div className="flex flex-col  overflow-x-auto  ">
                          <div className="flex flex-col ">
                            {location === `/dinss/tech/ticket-detail/${id}` ?
                              <div className="grid grid-cols-12  ">
                                {message?.senderId === convId?.otherUserId ?
                                  <div className={"col-start-1 lg:col-end-8 md:col-end-8 col-end-13 p-3 rounded-lg"} >
                                    <div className="flex flex-row ">
                                      <div
                                        className=""
                                      >
                                        <img src={ticketDetail?.user?.image || convId?.userImage} alt="user" className='object-cover lg:w-[40px] lg:h-[40px] md:w-[40%] h-[40px] w-[70px] rounded-full' />
                                      </div>
                                      <div className=''>
                                        <div
                                          className="relative ml-3 text-sm bg-white py-2 px-4 lg:w-auto w-full shadow rounded-xl"
                                        >
                                          <div className='text-[#444444] text-[14px]'>{message?.messageText}</div>
                                        </div>
                                        <div className='flex justify-between items-center pt-2 pl-4'>
                                          <p className='text-[#9F9F9F] text-[12px] '>{moment(message?.messageTime).format(
                                            "LT"
                                          )}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  :
                                  <div className="lg:col-start-6 md:col-start-6 col-start-1 col-end-13 p-3 rounded-lg">
                                    <div className="flex  justify-start flex-row-reverse">
                                      <div
                                        className=""
                                      >
                                        <img src={currentUser?.userImage || friend} alt="user" className={`${message?.messageText?.length > 10 ? 'lg:w-[85px] w-[70px]' : 'lg:w-[40px] w-[40px]'} object-cover  lg:h-[40px] md:w-[40%] h-[40px] w-[70px] rounded-full`} />
                                      </div>
                                      <div className=' mr-3'>
                                        <div
                                          className="relative text-sm bg-white py-2 px-4 shadow rounded-xl"
                                        >
                                          <div className='text-[#444444] text-[14px]'> {message?.messageText}</div>
                                        </div>
                                        <div className='flex justify-between items-center pt-2 pl-4'>
                                          <p className='text-[#9F9F9F] text-[12px] '> {moment(message?.messageTime).format(
                                            "LT"
                                          )}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                }

                              </div>
                              :
                              title === "Owner" ?
                                <div className="grid grid-cols-12  ">
                                  {message?.senderId !== id ?
                                    <div className={"col-start-1 lg:col-end-8 md:col-end-8 col-end-13 p-3 rounded-lg"} >
                                      <div className="flex flex-row ">
                                        <div
                                          className=""
                                        >
                                          <img src={currentUser?.userImage || friend} alt="user" className='object-cover lg:w-[40px] lg:h-[40px] md:w-[40%] h-[40px] w-[70px] rounded-full' />
                                        </div>
                                        <div className=''>
                                          <div
                                            className="relative ml-3 text-sm bg-white py-2 px-4 lg:w-auto w-full shadow rounded-xl"
                                          >
                                            <div className='text-[#444444] text-[14px]'>{message?.messageText}</div>
                                          </div>
                                          <div className='flex justify-between items-center pt-2 pl-4'>
                                            <p className='text-[#9F9F9F] text-[12px] '>{moment(message?.messageTime).format(
                                              "LT"
                                            )}</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    :
                                    <div className="lg:col-start-6 md:col-start-6 col-start-1 col-end-13 p-3 rounded-lg">
                                      <div className="flex  justify-start flex-row-reverse">
                                        <div
                                          className=""
                                        >
                                          <img src={user?.userimg || friend} alt="user" className='object-cover lg:w-[80px] lg:h-[40px] md:w-[40%] w-[70px] rounded-full' />
                                        </div>
                                        <div className=' mr-3'>
                                          <div
                                            className="relative text-sm bg-white py-2 px-4 shadow rounded-xl"
                                          >
                                            <div className='text-[#444444] text-[14px]'> {message?.messageText}</div>
                                          </div>
                                          <div className='flex justify-between items-center pt-2 pl-4'>
                                            <p className='text-[#9F9F9F] text-[12px] '> {moment(message?.messageTime).format(
                                              "LT"
                                            )}</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  }

                                </div>
                                :
                                <div className="grid grid-cols-12  ">
                                  {message?.senderId === id ?
                                    <div className={"col-start-1 lg:col-end-8 md:col-end-8 col-end-13 p-3 rounded-lg"} >
                                      <div className="flex flex-row ">
                                        <div
                                          className=""
                                        >
                                          <img src={currentUser?.userImage || friend} alt="user" className='object-cover lg:w-[40px] lg:h-[40px] md:w-[40%] h-[40px] w-[70px] rounded-full' />
                                        </div>
                                        <div className=''>
                                          <div
                                            className="relative ml-3 text-sm bg-white py-2 px-4 lg:w-auto w-full shadow rounded-xl"
                                          >
                                            <div className='text-[#444444] text-[14px]'>{message?.messageText}</div>
                                          </div>
                                          <div className='flex justify-between items-center pt-2 pl-4'>
                                            <p className='text-[#9F9F9F] text-[12px] '>{moment(message?.messageTime).format(
                                              "LT"
                                            )}</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    :
                                    <div className="lg:col-start-6 md:col-start-6 col-start-1 col-end-13 p-3 rounded-lg">
                                      <div className="flex  justify-start flex-row-reverse">
                                        <div
                                          className=""
                                        >
                                          <img src={convId?.userImage || friend} alt="user" className={`${message?.messageText?.length > 10 ? 'lg:w-[85px] w-[70px]' : 'lg:w-[40px] w-[40px]'} object-cover  lg:h-[40px] md:w-[40%] h-[40px]  rounded-full`} />
                                        </div>
                                        <div className=' mr-3'>
                                          <div
                                            className="relative text-sm bg-white py-2 px-4 shadow rounded-xl"
                                          >
                                            <div className='text-[#444444] text-[14px]'> {message?.messageText}</div>
                                          </div>
                                          <div className='flex justify-between items-center pt-2 pl-4'>
                                            <p className='text-[#9F9F9F] text-[12px] '> {moment(message?.messageTime).format(
                                              "LT"
                                            )}</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  }

                                </div>
                            }
                          </div>
                        </div >
                      )
                    })}
                  </>
                ))}
              </div>
              {/* {
                location === `/dinss/tech/ticket-detail/${id}` && */}
              <div className='flex items-center absolute xl:left-auto lg:left-auto md:left-auto left-1 bottom-2 w-[96%]'>
                <div className="mr-4">
                  <img src={file} alt="send" className='object-cover' />
                </div>
                <form onSubmit={handleSubmit} className="w-full mt-2">
                  <div
                    className={`flex flex-row items-center  rounded-xl ${type === "ticket" ? "bg-[#F5F5F5] h-full" : "bg-white h-[48px]"
                      } w-full px-2`}
                  >
                    <div className="flex-grow ml-2">
                      <div className="relative w-full">
                        {ticketDetail ?
                          <textarea
                            rows="2"
                            name="UserMessage"
                            placeholder="Type a message"
                            value={UserMessage}
                            onChange={(e) => setMessage(e.target.value)}
                            className="flex w-full bg-transparent pt-3  focus:outline-none focus:border-indigo-300 h-full"
                          >

                          </textarea>
                          :
                          <input
                            type="text"
                            name="UserMessage"
                            placeholder="Type a message"
                            value={UserMessage}
                            onChange={(e) => setMessage(e.target.value)}
                            className="flex w-full bg-transparent  focus:outline-none focus:border-indigo-300 h-full"
                          />
                        }
                      </div>
                    </div>
                    <div className="ml-4" onClick={(e) => handleSubmit(e)}>
                      <img src={send} alt="send" className="object-cover cursor-pointer" />
                    </div>
                  </div>
                </form>
              </div>
              {/* } */}
            </>
          ) : loading ? <div className="flex items-center justify-center pt-40"><LoaderSpinner type="chatLoad" color={"#FF8C00"} /></div> : <div className="text-center font-bold pt-20">No Conversation</div>}
        </div>
      </div >

    </>
  );
};

export default Messages;
