import React, { useEffect, useState } from "react";
import { MdSearch } from "react-icons/md";
import DataList from "./DataList";
import FilterDropdown from "../../../../util/dropdowns/FilterDropdown";
import { GetVihicalsPendding } from "../../../../lib/Api/Vehical";
import SearchBar from "../../../GenericComponents/SearchBar";

const DataTable = ({ setRentalUser, setIsStatus }) => {
  const [loading, setLoading] = useState(false);
  const [totalPage, setTotalPage] = useState();
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [statusCheck, setStatusCheck] = useState("pending");
  // const [loadedData, setLoadedData] = useState([])
  const [type, setType] = useState("");
  const [types, setTypes] = useState(false);
  const [vehicalList, setVehicalList] = useState([]);
  const [offsetPage, setOffsetPage] = useState(1);
  const [searchData, setSearchData] = useState("");

  let fetchVehical = async () => {
    setLoading(true);
    // let Incr = currentPageIndex + 1
    let { res } = await GetVihicalsPendding(statusCheck , 1);
    setLoading(false);
    let vehicals = Object.assign({}, ...res);
    setVehicalList(vehicals?.vehicles?.data);
    setTotalPage(vehicals?.vehicles?.total);
    setType('')
  };

  useEffect(() => {
    if (searchData === "") {
      fetchVehical()
    }
  }, [searchData ]);
 
  return (
    <>
      <div className="flex  flex-wrap justify-between items-center  space-y-2 sm:space-y-0 ">
        <h2 className="text-[14.4px] mb-2 leading-[20.16px] font-semibold text-[#444444]">
          New Vehicle Deposit Requests
        </h2>

        <div className="flex tablet:justify-between tablet:w-full lg:py-0 py-2  xtra-small:block xxtra-small:block xtra-small:w-full xxtra-small:w-full">
          <SearchBar
            setSearchData={setSearchData}
            offsetPage={offsetPage}
            setStatusCheck={setStatusCheck}
            setTypes={setTypes}
            setTotalPages={setTotalPage}
            statusCheck={statusCheck}
            setCurrentPageIndex={setCurrentPageIndex}
            setVehicalList={setVehicalList}
            setLoading={setLoading}
          />
          <div className="ml-4 xtra-small:ml-0 xxtra-small:ml-0 xxtra-small:mt-2 xtra-small:mt-2">
            <div className="text-center w-full flex justify-end xtra-small:justify-start xxtra-small:justify-start ">
              <div className="dropdown xxtra-small:w-full xtra-small:w-full relative">
                <FilterDropdown
                  searchData={searchData}
                  offsetPage={offsetPage}
                  setSearchData={setSearchData}
                  setTotalPage={setTotalPage}
                  setCurrentPageIndex={setCurrentPageIndex}
                  setStatusCheck={setStatusCheck}
                  setVehicalList={setVehicalList}
                  setOffsetPage={setOffsetPage}
                  setLoading={setLoading}
                  setTypes={setTypes}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <DataList
          setOffsetPage={setOffsetPage}
          setIsStatus={setIsStatus}
          setRentalUser={setRentalUser}
          statusCheck={statusCheck}
          type={type}
          setSearchData={setSearchData}
          setType={setType}
          setVehicalList={setVehicalList}
          vehicalList={vehicalList}
          loading={loading}
          setCurrentPageIndex={setCurrentPageIndex}
          currentPageIndex={currentPageIndex}
          totalPage={totalPage}
          setLoading={setLoading}
          offsetPage={offsetPage}
          setTotalPage={setTotalPage}
        />
      </div>
    </>
  );
};

export default DataTable;
