import React, { useState, useEffect } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { UpdateCoupon } from '../../../lib/Api/Marketing'
import LoaderSpinner from '../../../util/LoaderSpinner';

// Validation Scema
const schema = yup.object({
    code: yup.string().required("Code is required"),
    discount: yup.string().required("Discount is required"),
    valid_from: yup.string().required("Start Date is required"),
    valid_to: yup.string().required("End Date is required"),
    description: yup.string().required("Description is required"),
});

const EditData = ({ singleCoupon, handleClose, setShow }) => {

    const [loading, setLoading] = useState(false);

    // React hook form initilization
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({ mode: "onChange", resolver: yupResolver(schema) });

    // Submit Form 
    const onSubmit = async (data) => {
        try {
            setLoading(true);
            data.id = singleCoupon.id
            let { res } = await UpdateCoupon(data);
            if (res !== undefined) {
                setLoading(false);
                handleClose()
                reset();
            } else {
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
        }
    };

    const RemoveTime = (date) => {
        // Convert the date string to a Date object
        const dateObject = new Date(date);
        // Get the date portion (YYYY-MM-DD)
        const dateWithoutTime = dateObject.toISOString().split('T')[0];
        return dateWithoutTime

    }



    useEffect(() => {
        if (singleCoupon) {
            // let parts = singleCoupon?.valid_from?.split("/");
            // let reversedDateStr = parts[2] + "-" + parts[0] + "-" + parts[1];
            // let parts2 = singleCoupon?.valid_to?.split("/");
            // let reversedDateStr2 = parts2[2] + "-" + parts2[0] + "-" + parts2[1];
            // //console.log("dat1", singleCoupon?.valid_from)
            let s_date = RemoveTime(singleCoupon?.valid_from)
            let e_date = RemoveTime(singleCoupon?.valid_to)
            let payload = {
                code: singleCoupon?.code,
                discount: singleCoupon?.discount,
                valid_from: s_date,
                valid_to: e_date,
                description: singleCoupon.description
            }
            reset(payload)
        }
    }, [])



    return (
        <>
            <form className='' onSubmit={handleSubmit(onSubmit)}>
                <h2 className='text-[#444444] font-semibold'>Edit Promo Code</h2>
                <div className='flex flex-col pt-5'>
                    <label className='pb-3 text-[#606060] text-[14px]'>Code</label>
                    <input
                        type="text"
                        {...register("code")}
                        placeholder="Code"
                        className={`border border-[#CDD2E1] h-[42px] placeholder:text-[14px] text-[14px] rounded-md text-[#ACACAC] focus:outline-none px-2.5 ${errors.code && "border-red-500"
                            }`}
                    />
                    {errors.code && (
                        <p className="text-red-500 text-sm text-start pt-1 ">
                            {errors.code.message}
                        </p>
                    )}
                </div>
                <div className='flex flex-col pt-2'>
                    <label className='pb-3 text-[#606060] text-[14px]'>Discount</label>
                    <input
                        type="text"
                        {...register("discount")}
                        placeholder="discount"
                        className={`border border-[#CDD2E1] h-[42px] placeholder:text-[14px] text-[14px] rounded-md text-[#ACACAC] focus:outline-none px-2.5 ${errors.discount && "border-red-500"
                            }`}
                    />
                    {errors.discount && (
                        <p className="text-red-500 text-sm text-start pt-1 ">
                            {errors.discount.message}
                        </p>
                    )}
                </div>
                <div className='grid lg:grid-cols-2 md:grid-cols-2 pt-5 gap-5'>
                    <div className='flex flex-col'>
                        <label className='pb-3 text-[#606060] text-[14px]'>Starting Date</label>
                        <input
                            type="date"
                            {...register("valid_from")}
                            pattern="\d{1,2}/\d{1,2}/\d{4}"
                            className={`border border-[#CDD2E1] h-[42px] ticket placeholder:text-[14px] text-[14px] rounded-md text-[#ACACAC] focus:outline-none px-2 ${errors.valid_from && "border-red-500"
                                }`}
                        />
                        {errors.valid_from && (
                            <p className="text-red-500 text-sm text-start pt-1 ">
                                {errors.valid_from.message}
                            </p>
                        )}
                    </div>
                    <div className='flex flex-col'>
                        <label className='pb-3 text-[#606060] text-[14px]'>Ending Date</label>
                        <input
                            type="date"
                            {...register("valid_to")}
                            pattern="\d{1,2}/\d{1,2}/\d{4}"
                            className={`border border-[#CDD2E1] h-[42px] ticket placeholder:text-[14px] text-[14px] rounded-md text-[#ACACAC] focus:outline-none px-2 ${errors.valid_to && "border-red-500"
                                }`}
                        />
                        {errors.valid_to && (
                            <p className="text-red-500 text-sm text-start pt-1 ">
                                {errors.valid_to.message}
                            </p>
                        )}
                    </div>
                </div>
                <div className='pt-5'>
                    <label className='X text-[#606060] text-[14px]'>Description</label>
                    <textarea
                        placeholder='description'
                        {...register("description")}
                        className={`resize-none border border-[#CDD2E1] w-full placeholder:text-[14px] text-[14px] py-2 text-[#ACACAC] focus:outline-none px-2 rounded-md ${errors.description && "border-red-500"
                            }`}
                    ></textarea>
                    {errors.description && (
                        <p className="text-red-500 text-sm text-start pt-1 ">
                            {errors.description.message}
                        </p>
                    )}
                </div>
                <div className='pt-5 flex justify-end items-end'>
                    <button onClick={() => setShow(false)} className='w-[138px] py-2 mx-5 rounded-md bg-transparent border-[#ACACAC] border text-[#ACACAC]'>Cancel</button>
                    <button className='bg-[#FF8C00] text-white h-[40px] px-8   rounded-md'>
                        {loading ? <LoaderSpinner /> : "Update"}
                    </button>
                </div>


            </form>
        </>
    )
}

export default EditData