import React, { useRef, useEffect } from "react";
import {
	FacebookShareButton,
	LinkedinShareButton,
	TwitterShareButton,
} from "react-share";
import Sticky from "sticky-js";

const BlogArticleShareButtons = () => {
	const stickyRef = useRef(null);
	useEffect(() => {
		const sticky = new Sticky(".sticky-element", {
			topSpacing: 250,
			zIndex: 1000,
		});
		return () => sticky.destroy();
	}, []);

	const currentUrl = window.location.href;
	return (
		<div>
			<div className="article-share-buttons">
				<div className="sticky-element" ref={stickyRef}>
					<div className="blog-article-share-buttons-main">
						<div style={{ height: "40px" }}>
							<TwitterShareButton url={currentUrl}>
								<button className="blog-article-share-button">
									<svg
										width="15"
										height="15"
										viewBox="0 0 18 15"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M17.5197 1.81235C16.8977 2.10286 16.2272 2.29376 15.5325 2.38506C16.2434 1.94516 16.7927 1.24795 17.0512 0.409645C16.3807 0.824648 15.6375 1.11515 14.8539 1.28115C14.2157 0.567347 13.319 0.152344 12.3011 0.152344C10.4027 0.152344 8.85169 1.74595 8.85169 3.71307C8.85169 3.99527 8.884 4.26917 8.94055 4.52647C6.06467 4.37707 3.50384 2.95776 1.79931 0.808048C1.50041 1.33095 1.33077 1.94516 1.33077 2.59256C1.33077 3.82927 1.93664 4.92488 2.87373 5.54738C2.30017 5.54738 1.767 5.38138 1.29845 5.13238V5.15728C1.29845 6.88369 2.49405 8.3279 4.0774 8.6516C3.56905 8.79453 3.03537 8.81442 2.51828 8.7097C2.73769 9.41726 3.1674 10.0364 3.74701 10.48C4.32661 10.9237 5.02696 11.1696 5.74961 11.1831C4.52464 12.1795 3.00621 12.7181 1.44386 12.7103C1.1692 12.7103 0.894536 12.6937 0.619873 12.6605C2.15476 13.6731 3.98046 14.2624 5.93541 14.2624C12.3011 14.2624 15.7991 8.8342 15.7991 4.12807C15.7991 3.97037 15.7991 3.82097 15.791 3.66327C16.4696 3.16526 17.0512 2.53446 17.5197 1.81235Z"
											fill="white"
										/>
									</svg>
								</button>
							</TwitterShareButton>
						</div>
						<div style={{ height: "40px" }}>
							<LinkedinShareButton url={currentUrl}>
								<button className="blog-article-share-button">
									<svg
										width="15"
										height="15"
										viewBox="0 0 15 15"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M3.40969 14.8982H0.332764V5.41406H3.40969V14.8982Z"
											fill="white"
										/>
										<path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M1.86208 3.30573H1.8446C0.926377 3.30573 0.332764 2.60286 0.332764 1.72446C0.332764 0.827481 0.944456 0.144287 1.8801 0.144287C2.81575 0.144287 3.39194 0.827481 3.40969 1.72446C3.40969 2.60286 2.81575 3.30573 1.86208 3.30573Z"
											fill="white"
										/>
										<path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M14.6911 14.8979H11.6676V9.94047C11.6676 8.69526 11.23 7.84557 10.1355 7.84557C9.30012 7.84557 8.80256 8.41839 8.58403 8.97165C8.50408 9.16993 8.48446 9.44619 8.48446 9.72313V14.8982H5.46045C5.46045 14.8982 5.5003 6.5014 5.46045 5.6319H8.48446V6.94439C8.88577 6.31345 9.60451 5.41406 11.2099 5.41406C13.1996 5.41406 14.6912 6.73873 14.6912 9.58494L14.6911 14.8979Z"
											fill="white"
										/>
									</svg>
								</button>
							</LinkedinShareButton>
						</div>
						<div style={{ height: "40px" }}>
							<FacebookShareButton url={currentUrl}>
								<button className="blog-article-share-button">
									<svg
										width="15"
										height="15"
										viewBox="0 0 8 17"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M2.00187 16.3239V8.41973H0.411377V5.69582H2.00187V4.0604C2.00187 1.83824 2.89996 0.516846 5.45153 0.516846H7.5758V3.24107H6.24798C5.25472 3.24107 5.18901 3.62173 5.18901 4.33217L5.1854 5.69552H7.59083L7.30936 8.41943H5.1854V16.3239H2.00187Z"
											fill="white"
										/>
									</svg>
								</button>
							</FacebookShareButton>
						</div>
					</div>
				</div>
			</div>
			<div className="share-button-mobile-section">
				<div className="mr-2">
					<TwitterShareButton url={currentUrl}>
						<button className="blog-article-share-button">
							<svg
								width="15"
								height="15"
								viewBox="0 0 18 15"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M17.5197 1.81235C16.8977 2.10286 16.2272 2.29376 15.5325 2.38506C16.2434 1.94516 16.7927 1.24795 17.0512 0.409645C16.3807 0.824648 15.6375 1.11515 14.8539 1.28115C14.2157 0.567347 13.319 0.152344 12.3011 0.152344C10.4027 0.152344 8.85169 1.74595 8.85169 3.71307C8.85169 3.99527 8.884 4.26917 8.94055 4.52647C6.06467 4.37707 3.50384 2.95776 1.79931 0.808048C1.50041 1.33095 1.33077 1.94516 1.33077 2.59256C1.33077 3.82927 1.93664 4.92488 2.87373 5.54738C2.30017 5.54738 1.767 5.38138 1.29845 5.13238V5.15728C1.29845 6.88369 2.49405 8.3279 4.0774 8.6516C3.56905 8.79453 3.03537 8.81442 2.51828 8.7097C2.73769 9.41726 3.1674 10.0364 3.74701 10.48C4.32661 10.9237 5.02696 11.1696 5.74961 11.1831C4.52464 12.1795 3.00621 12.7181 1.44386 12.7103C1.1692 12.7103 0.894536 12.6937 0.619873 12.6605C2.15476 13.6731 3.98046 14.2624 5.93541 14.2624C12.3011 14.2624 15.7991 8.8342 15.7991 4.12807C15.7991 3.97037 15.7991 3.82097 15.791 3.66327C16.4696 3.16526 17.0512 2.53446 17.5197 1.81235Z"
									fill="white"
								/>
							</svg>
						</button>
					</TwitterShareButton>
				</div>
				<div className="mr-2">
					<LinkedinShareButton url={currentUrl}>
						<button className="blog-article-share-button">
							<svg
								width="15"
								height="15"
								viewBox="0 0 15 15"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M3.40969 14.8982H0.332764V5.41406H3.40969V14.8982Z"
									fill="white"
								/>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M1.86208 3.30573H1.8446C0.926377 3.30573 0.332764 2.60286 0.332764 1.72446C0.332764 0.827481 0.944456 0.144287 1.8801 0.144287C2.81575 0.144287 3.39194 0.827481 3.40969 1.72446C3.40969 2.60286 2.81575 3.30573 1.86208 3.30573Z"
									fill="white"
								/>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M14.6911 14.8979H11.6676V9.94047C11.6676 8.69526 11.23 7.84557 10.1355 7.84557C9.30012 7.84557 8.80256 8.41839 8.58403 8.97165C8.50408 9.16993 8.48446 9.44619 8.48446 9.72313V14.8982H5.46045C5.46045 14.8982 5.5003 6.5014 5.46045 5.6319H8.48446V6.94439C8.88577 6.31345 9.60451 5.41406 11.2099 5.41406C13.1996 5.41406 14.6912 6.73873 14.6912 9.58494L14.6911 14.8979Z"
									fill="white"
								/>
							</svg>
						</button>
					</LinkedinShareButton>
				</div>
				<FacebookShareButton url={currentUrl}>
					<button className="blog-article-share-button">
						<svg
							width="15"
							height="15"
							viewBox="0 0 8 17"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M2.00187 16.3239V8.41973H0.411377V5.69582H2.00187V4.0604C2.00187 1.83824 2.89996 0.516846 5.45153 0.516846H7.5758V3.24107H6.24798C5.25472 3.24107 5.18901 3.62173 5.18901 4.33217L5.1854 5.69552H7.59083L7.30936 8.41943H5.1854V16.3239H2.00187Z"
								fill="white"
							/>
						</svg>
					</button>
				</FacebookShareButton>
			</div>
		</div>
	);
};

export default BlogArticleShareButtons;
