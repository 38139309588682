import React, { useState } from "react";
import { BsEye, BsThreeDotsVertical } from "react-icons/bs";
// import ReactPaginate from "react-paginate";
// import close from "../../../../../assests/svg/cancel-icon.svg";
// import {
//   MdOutlineKeyboardArrowLeft,
//   MdOutlineKeyboardArrowRight,
// } from "react-icons/md";
// import EyeIcon from "../../../../../assests/EyeIcon";
// import { useNavigate } from "react-router-dom";
import LoaderSpinner from "../../../../../util/LoaderSpinner";
import UpdateStatusLoader from "../../../statusLoaderSpinner/StatusLoader";
import Popup from "../../../../../util/popup/Popup";
import { UpdatePaymentStatus } from "../../../../../lib/Api/Management";
import RefreshIcon from "../../../../../assests/RefreshIcon";
// import CancelBooking from '../';
const DataList = ({
  // setDetail,
  loading,
  // totalPages,
  status,
  setStatus,
  transaction }) => {
  const [show, setShow] = useState(false)

  // const itemperPage = 10

  // const pageCount = Math.ceil(totalPages / itemperPage);

  // const handlePageClick = (event) => {
  //   const newOffset = (event.selected * itemperPage) % totalPages;
  // };
  // const navigate = useNavigate();


  let Status = [
    { id: 0, Title: "Active", subTitle:"Active" ,  color: "#6ABD8B", bgColor: "#ECFFEC" },
    { id: 1, Title: "InActive", subTitle:"In-active" ,color: "#F04438", bgColor: "#FFE7E7" },
   
  ];

  const handleUpadateStatus = async (id, status) => {
    setStatus(status)
    setShow(true)
    let formData = new FormData()
    formData.append("payment_method_id", id)
    formData.append("status", status === "InActive" ? 0 : 1)
    let { res } = await UpdatePaymentStatus(formData)
    if (res) {
      setShow(false)
      setStatus("")
    }

  }


  return (
    <>
      <Popup modalOpen={show} data={<UpdateStatusLoader />} onClose={() => setShow(false)}
      />
      <div className="overflow-x-auto bg-white rounded-md shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] mt-6">
        {
          !loading && transaction?.length > 0 ?
            <>
              <table className="table-auto w-full ">
                {/* Table header */}
                <thead className="text-xs font-[500] bg-[#FFF8EF] border-0">
                  <tr className="text-[#626973]">
                    <th className="px-4 py-4 pr-[25px] whitespace-nowrap w-px">
                      <div className="font-[500] text-[14px] text-left">
                        Payment ID{" "}
                      </div>
                    </th>
                    <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                      <div className="font-[500] text-[14px] text-center">
                        Payment Category
                      </div>
                    </th>
                    <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                      <div className="font-[500] text-[14px] text-left">
                        Payment Name
                      </div>
                    </th>
                    <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                      <div className="font-[500] text-[14px] text-left">
                        Payment Status
                      </div>
                    </th>
                    <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                      <div className="font-[500] text-[14px] text-center">
                        Action{" "}
                      </div>
                    </th>
                  </tr>
                </thead>
                {/* Table body */}
                <tbody className="text-sm ">
                  {transaction.map((item, i) => (
                    <tr className=" cursor-pointer  " key={i}>
                      <td className="px-4 py-4 pr-[25px] whitespace-nowrap">
                        <div className="text-left text-[14px] text-[#898989]">
                          {item?.id}
                        </div>
                      </td>
                      <td className="px-4 py-4 pr-[25px] whitespace-nowrap">
                        <div className="text-center text-[14px] text-[#898989]">
                          {item?.company_name}
                        </div>
                      </td>
                      <td className="px-4 py-4 pr-[25px] whitespace-nowrap">
                        <div className="text-left text-[14px] text-[#898989]">
                          {item?.name}
                        </div>
                      </td>
                      <td className="px-4 py-4 pr-[25px] whitespace-nowrap">
                        <div style={{ backgroundColor: item?.status_object?.background_color }} className={`text-center w-fit py-[3px] px-2.5 text-[12px] leading-4 text-[${item?.status_object?.text_color}]  rounded-full `}>
                          {item?.status_object?.text}
                        </div>
                      </td>

                      <td className="px-4 py-4 pr-[25px] whitespace-nowrap">
                        <div className="text-center flex justify-center">


                        <div class="group inline-block">
                              <button class="outline-none focus:outline-none  px-3 py-1 bg-white rounded-sm flex items-center">
                                <BsThreeDotsVertical className="text-[20px] text-[#898989]" />
                              </button>
                              <ul
                                class="bg-white shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] border-0 rounded-md p-4 transform scale-0 group-hover:scale-100 absolute 
  transition duration-150 ease-in-out right-7 origin-top xl:w-[15%]  "
                              >
                                

                                <li class="rounded-sm actions relative px-2 py-2 mb-2 hover:bg-gray-100">
                                  <button class="w-full flex items-center outline-none focus:outline-none">
                                    <RefreshIcon className=" ml-auto text-[#98A2B3]" />
                                    <span class="pr-1 flex-1 text-left ml-2">
                                      Change Status
                                    </span>
                                    <span class="mr-auto">
                                      <svg
                                        class="fill-current h-4 w-4
            transition duration-150 ease-in-out"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                      >
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                      </svg>
                                    </span>
                                  </button>
                                  <ul
                                    class="bg-white border-0 p-2 flex justify-center flex-col items-start rounded-md absolute top-0 right-10 shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)]
  transition duration-150 ease-in-out origin-top-left
  min-w-32
  "
                                  >
                                    {Status.filter(
                                      (f) =>
                                        f.subTitle !== item?.status_object?.text
                                    ).map((st, i) => (
                                      <li
                                        onClick={() => handleUpadateStatus(item?.id, st?.Title)}
                                        class="px-2 py-2 text-left mb-2"
                                        key={i}
                                      >
                                        <div
                                          style={{
                                            color: `${st.color}`,
                                            backgroundColor: `${st.bgColor}`,
                                          }}
                                          className={`text-center py-[3px] px-2.5 w-auto text-[12px] font-medium rounded-full`}
                                        >
                                          {st.Title}
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                </li>
                               
                              </ul>
                            </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* <div className="flex justify-end items-end pt-6 px-5">
              
              <div className="px-8 my-6 ">
                <ReactPaginate
                  breakLabel="..."
                  breakClassName=" bg-[#F3F3F3]"
                  nextLabel={<MdOutlineKeyboardArrowRight />}
                  onPageChange={handlePageClick}
                  activeClassName="bg-[#FF8C00] text-white rounded"
                  nextClassName="px-3  bg-[#F3F3F3] text-[20px] pt-[5px] rounded"
                  previousClassName="px-3  bg-[#F3F3F3] text-[20px] pt-[5px] rounded"
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel={<MdOutlineKeyboardArrowLeft />}
                  className=" flex justify-end"
                  pageClassName=" w-[30px] h-[30px]  text-center mx-2 pt-[3px] rounded bg-[#F3F3F3] "
                  renderOnZeroPageCount={null}
                />
              </div>
            </div> */}
            </>
            :
            <div className="p-5 ">
              {loading ? (
                <LoaderSpinner type="tableLoad" />
              ) : (
                <h2>Record not found</h2>
              )}
            </div>
        }
      </div>
    </>
  );
};

export default DataList;
