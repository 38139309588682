import moment from 'moment'
import React, { useState } from 'react'
import LoaderSpinner from '../../../util/LoaderSpinner'
import deleteIcon from "../../../assests/svg/delete-icon.svg";
import { Truncate } from '../../../util/TrucateString'
import { BsThreeDotsVertical } from 'react-icons/bs'
import Popup from '../../../util/popup/Popup'
import DeleteBroadCast from './DeleteBroadCast'
import TruncateText from '../../../util/TruncateShow';
// import { BsEye, BsPencil, BsThreeDotsVertical, BsTrash } from 'react-icons/bs'
// import ReactPaginate from 'react-paginate';
// import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md';
const BradCastTable = ({ broadCast, loading, setUpdateLoading }) => {
    const [show, setShow] = useState(false)
    const [list, setList] = useState({})
    const openPopup = (e, item) => {
        setUpdateLoading(true)
        e.stopPropagation();
        setShow(true)
        setList(item)
    }

    const handleClose = () => {
        setShow(false);
        setUpdateLoading(false)

    }
    return (
        <>

            {show && (
                <Popup
                    id="job-modal"
                    modalOpen={show}
                    data={
                        <DeleteBroadCast handleClose={handleClose} list={list} onClose={() => setShow(false)} />
                    }
                    onClose={() => setShow(false)}
                />
            )}
            <div className="overflow-x-auto bg-white mt-6">
                {
                    !loading &&
                        broadCast?.length > 0 ?
                        <>
                            <table className="table-auto w-full ">
                                {/* Table header */}
                                <thead className="text-xs font-[500] bg-[#FFF8EF] border-0">
                                    <tr className='text-[#606060]'>
                                        <th className="px-5 py-4 pr-[25px] whitespace-nowrap ">
                                            <div className="font-[500] text-[14px] text-left">Scheduled Date</div>
                                        </th>
                                        <th className="px-5 py-4 pr-[25px] whitespace-nowrap ">
                                            <div className="font-[500] text-[14px] text-left">Destination</div>
                                        </th>
                                        <th className="px-5 py-4 pr-[25px] whitespace-nowrap  w-[30%] ">
                                            <div className="font-[500] text-[14px] text-left">Title</div>
                                        </th>
                                        <th className="px-5 py-4 pr-[25px] whitespace-nowrap  w-[40%] ">
                                            <div className="font-[500] text-[14px] text-left">Message</div>
                                        </th>
                                        <th className="px-5 py-4 pr-[25px] whitespace-nowrap ">
                                            <div className="font-[500] text-[14px] text-left">Channel</div>
                                        </th>
                                        <th className="px-5 py-4 pr-[25px] whitespace-nowrap">
                                            <div className="font-[500] text-[14px] text-left">Click Status</div>
                                        </th>
                                        <th className="px-5 py-4 pr-[25px] whitespace-nowrap">
                                            <div className="font-[500] text-[14px] text-left">Action</div>
                                        </th>

                                    </tr>
                                </thead>
                                {/* Table body */}
                                <tbody className="text-sm ">
                                    {
                                        broadCast?.map((item, i) =>
                                            <tr className=' cursor-pointer  ' key={i}>
                                                <td className="px-5 py-4 pr-[25px] whitespace-nowrap">
                                                    <div className="text-left text-[14px] text-[#898989]">
                                                        {moment(item?.send_on).format('ll HH:mm')}
                                                    </div>
                                                </td>
                                                <td className="px-5 py-4 pr-[25px] whitespace-nowrap">
                                                    <div className="text-left text-[14px] text-[#898989]">
                                                        {item?.send_to}
                                                    </div>
                                                </td>
                                                <td className="px-5 py-4 pr-[25px] whitespace-nowrap">
                                                    <div className="text-left text-[14px] text-[#898989]">
                                                        {item?.title || '--'}
                                                    </div>
                                                </td>
                                                <td className="px-5 py-4 pr-[25px]  ">
                                                    <div className="text-left  text-[14px] text-[#898989]">
                                                        {/* {Truncate(item?.message, 40)} */}
                                                        <TruncateText text={item?.message} maxLength={40} />
                                                    </div>
                                                </td>
                                                <td className="px-5 py-4 pr-[25px] whitespace-nowrap">
                                                    <div className="text-left text-[14px] text-[#898989]">{item?.send_via}</div>
                                                </td>
                                                <td className="px-5 py-4 pr-[25px] whitespace-nowrap">
                                                    <div className="text-left text-[14px] text-[#898989]">
                                                        {item?.clicks_count}
                                                    </div>
                                                </td>
                                                <td className="px-5 py-4 pr-[25px] whitespace-nowrap">
                                                    <div className="text-center flex justify-center">
                                                        {/* <BsThreeDotsVertical className='text-[20px] text-[#898989]' /> */}
                                                        {/* <GenericDrodown /> */}

                                                        <div class="group inline-block">
                                                            <button class="outline-none focus:outline-none  px-3 py-1 bg-white rounded-sm flex items-center">
                                                                <BsThreeDotsVertical className="text-[20px] text-[#898989]" />
                                                            </button>
                                                            <ul
                                                                class="bg-white shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] border-0 p-4 rounded-md transform scale-0 group-hover:scale-100 absolute 
  transition duration-150 ease-in-out right-7 origin-top w-[12%]  "
                                                            >
                                                                <li
                                                                    onClick={(e) => openPopup(e, item)}
                                                                    class="rounded-sm flex items-center px-2 py-2 hover:bg-gray-100"
                                                                >
                                                                    <img
                                                                        src={deleteIcon}
                                                                        className="text-sm "
                                                                        alt="Delete"
                                                                    />
                                                                    <h2 className="ml-2">Delete</h2>
                                                                </li>

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </td>

                                            </tr>
                                        )
                                    }

                                </tbody>

                            </table>
                            {/* <div className='flex justify-between items-center pt-6'>
                            <div className='flex items-center'>
                                <h2 className='text-[#333333] text-[14px]'>Show</h2>
                                <select value={itemperPage} onChange={(e) => setItemPerPage(e.target.value)} className='border focus:outline-none rounded-md px-5 py-2 mx-3'>
                                    <option value="6">6</option>
                                    <option value="12">12</option>
                                    <option value="25">25</option>
                                    <option value="30">30</option>
                                </select>
                                <h2 className='text-[#333333] text-[14px]'>entries of {items?.length}</h2>
                            </div>
                            <div className='px-8 my-6 '>
                                <ReactPaginate
                                    breakLabel="..."
                                    breakClassName=" bg-[#F3F3F3]"
                                    nextLabel={<MdOutlineKeyboardArrowRight />}
                                    onPageChange={handlePageClick}
                                    activeClassName="bg-[#FF8C00] text-white rounded"
                                    nextClassName="px-3  bg-[#F3F3F3] text-[20px] pt-[5px] rounded"
                                    previousClassName="px-3  bg-[#F3F3F3] text-[20px] pt-[5px] rounded"
                                    pageRangeDisplayed={5}
                                    pageCount={pageCount}
                                    previousLabel={<MdOutlineKeyboardArrowLeft />}
                                    className=" flex justify-end"
                                    pageClassName=" w-[30px] h-[30px]  text-center mx-2 pt-[3px] rounded bg-[#F3F3F3] "
                                    renderOnZeroPageCount={null}
                                />


                            </div>

                        </div> */}


                        </>
                        :
                        <div className='p-5 '>
                            {loading ? (
                                <LoaderSpinner type="tableLoad" />
                            ) : (
                                <h2>Record not found</h2>
                            )}
                        </div>
                }

            </div>
        </>
    )
}

export default BradCastTable