import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  GetVehicalRequestperPage,
  GetVehicalRnterRequest,
} from "../../../../lib/Api/RentalAppApi";
import LoaderSpinner from "../../../../util/LoaderSpinner";
import moment from "moment";
import { BsThreeDotsVertical } from "react-icons/bs";
import EyeIcon from "../../../../assests/EyeIcon";
// import { useNavigate } from 'react-router-dom';
import Popup from "../../../../util/popup/Popup";
import RentalDetail from "../RentalDetail";
import ReactPaginate from "react-paginate";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { FormatePrice } from "../../../../Partials/FormatePriceSpace";
const CarRequest = ({ setUser }) => {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [totalPages, setTotalPages] = useState();
  const [request, setRequest] = useState([]);
  const [singleVehical, setSingleVehical] = useState({});

  let id = useParams().id;
  // let location = useLocation().pathname

  // let navigate = useNavigate()

  const [itemperPage] = useState(10);
  const pageCount = Math.ceil(totalPages / itemperPage);

  let handlePageClick = async (event) => {
    setLoading(true);
    let { resRequest } = await GetVehicalRequestperPage(id, event.selected + 1);
    setLoading(false);
    setRequest(resRequest.data);
    setTotalPages(resRequest?.total);
  };

  // Get Renter Request
  let fetchRenterRequest = async () => {
    try {
      setLoading(true);
      let { resRequest } = await GetVehicalRnterRequest(id);
      if (resRequest) {
        setLoading(false);
        setRequest(resRequest.data);
        setTotalPages(resRequest?.total);
        setUser(request?.data?.user);
      } else {
        setLoading(false);
      }
    } catch (err) {
      //console.log(err);
    }
  };
  // Get Owner Request

  const handleDetail = (item) => {
    setSingleVehical(item);
    setShow(true);
  };

  // Function to add a space after every hundred value in a number
  // function addSpaceAfterHundred(number) {
  //   // Convert the number to a string
  //   let numStr = number?.toString();

  //   // Use a regular expression to insert a space after every group of three digits from the end
  //   numStr = numStr?.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  //   return numStr;
  // }

  function formatDate(date1, date2) {
    const dateData1 = new Date(date1);
    const dateData2 = new Date(date2);
    const month = dateData1.toLocaleString("default", { month: "short" });
    const startDay = dateData1.getDate();
    const startDay2 = dateData2.getDate();
    // const year = dateData1.getFullYear();
    return `${startDay} - ${startDay2} ${month}`;
  }

  useEffect(() => {
    fetchRenterRequest();
    // if (location === `/dinss/tech/vehicalRenters/detail/${id}`) {
    // }
    // else {
    //   fetchOwnerRequest()
    // }
  }, [id]);

  return (
    <>
      {show && (
        <Popup
          modalOpen={show}
          data={
            <RentalDetail
              singleVehical={singleVehical}
              setShow={(val) => setShow(val)}
            />
          }
          onClose={(val) => setShow(val)}
        />
      )}
      {!loading && request.length > 0 ? (
        <div>
          {request.map((item, i) => {
            let check =
              item?.status_object?.text_en === "Approved" ||
              item?.status_object?.text_en === "Paid";
            let rejCheck = item?.status_object?.text_en === "Rejected";
            return (
              <div
                className="bg-white rounded-md shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] h-full border-0 border-gray-100 px-5 py-5 mt-5"
                key={i}
              >
                <div className="flex lg:flex-nowrap flex-wrap  justify-between ">
                  <div className="flex items-center">
                    <div className="h-[90px] w-[90px] rounded-full overflow-hidden">
                      <img
                        src={item?.vehicle?.display_image}
                        alt="review_img"
                        className="object-cover w-[90px] h-[90px]"
                      />
                    </div>
                    <div className="px-2.5">
                      <h1 className="text-[16px] leding-[24px] text-[#444444] font-bold">
                        {`${item?.vehicle?.make} ${item?.vehicle?.model} `}
                      </h1>
                      <p className="text-[#98A2B3] text-[15px] pt-1.5">
                        Période
                      </p>
                      <p className="text-[#ACACAC] text-[16px] leading-[16px] pt-2">
                        {formatDate(item?.start_date, item?.end_date)}
                      </p>
                      {check && (
                        <p className="text-[#444444] text-[16px] leading-[16px] pt-1.5">
                          Time left: {item?.remaining_time}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex lg:flex-col md:flex-col lg:justify-center md:justify-start justify-between flex-row lg:w-auto md:w-auto w-full xxtra-small:mt-5">
                    <div className="flex justify-end items-end ">
                      <div className="group inline-block">
                        <button className="outline-none focus:outline-none  px-3 py-1 bg-white rounded-sm flex items-center">
                          <BsThreeDotsVertical className="text-[20px] text-[#898989]" />
                        </button>
                        <ul
                          className="bg-white shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] border-0 rounded-md p-4 transform scale-0 group-hover:scale-100 absolute 
  transition duration-150 ease-in-out right-7 origin-top xl:w-[15%]  "
                        >
                          <li
                            onClick={() => handleDetail(item)}
                            className="rounded-sm actions cursor-pointer flex items-center px-2 py-2 mb-2 hover:bg-gray-100"
                          >
                            <EyeIcon className="text-[14px] text-[#98A2B3]" />
                            <h2 className="ml-2">View details</h2>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <h2 className="text-[#FF8C00] text-[25px] leading-[35px] font-semibold">
                      {FormatePrice(item?.amount)} FCFA
                    </h2>
                    <div className="w-full flex justify-end items-end pt-3">
                      <div
                        style={{
                          color: `${item?.status_object?.text_color}`,
                          backgroundColor: `${item?.status_object?.background_color}`,
                        }}
                        className={`text-center py-[4px] w-[105px] h-[40px] px-2.5  text-[12px] rounded-full`}
                      >
                        <h2 className="pt-2">{item?.status_object?.text_en}</h2>
                      </div>
                    </div>
                  </div>
                </div>
                {item?.reason !== "" > 0 && rejCheck && (
                  <div className="pt-6">
                    <h1 className="text-[#444444] text-[20px] leading-[24px]">
                      Reason:
                    </h1>
                    <p className="text-[#898989] text-[16px] leading-[24px] pt-2.5">
                      {item?.reject_reason}
                    </p>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <div>
          {loading ? <LoaderSpinner type="tableLoad" /> : "Record Not Found"}
        </div>
      )}

      {request?.length > 0 && (
        <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap justify-between items-center pt-6 ">
          {/* <div className='flex items-center w-full'>
                            <h2 className='text-[#333333] text-[14px]'>Show</h2>
                            <select className='border focus:outline-none rounded-md px-5 py-2 mx-3'>
                                <option value="6">6</option>
                                <option value="12">12</option>
                                <option value="25">25</option>
                                <option value="30">30</option>
                            </select>
                            <h2 className='text-[#333333] text-[14px]'>entries of </h2>
                        </div> */}
          <div className="px-8 xxtra-small:px-0 mt-4 mb-1 w-full flex justify-end xxtra-small:justify-start">
            {/* <div className='flex items-center'>
                            {
                                vehicalList?.links?.map((pag, i) => (

                                    <div key={i} className='border text-center px-2 rounded-md bg-[#F3F3F3]'>
                                        {pag?.label === '&laquo; Previous' && <MdOutlineKeyboardArrowLeft /> && pag.label === "Next &raquo;" ? <MdOutlineKeyboardArrowRight /> : pag?.label}
                                    </div>

                                ))
                            }
                        </div> */}

            <ReactPaginate
              breakLabel="..."
              breakClassName=" bg-[#F3F3F3]"
              nextLabel={<MdOutlineKeyboardArrowRight />}
              onPageChange={handlePageClick}
              activeClassName="bg-[#FF8C00] text-white rounded"
              nextClassName="px-3  bg-[#F3F3F3] text-[20px] pt-[5px] rounded xxtra-small:mb-4"
              previousClassName="px-3  bg-[#F3F3F3] text-[20px] pt-[5px] rounded xxtra-small:mb-4"
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel={<MdOutlineKeyboardArrowLeft />}
              className=" flex justify-end  xxtra-small:w-full xxtra-small:justify-start xtra-small:justify-start xxtra-small:flex-wrap xtra-small:flex-wrap"
              pageClassName=" w-[30px] h-[30px]  text-center mx-2 pt-[3px] rounded bg-[#F3F3F3] xxtra-small:mb-4 xtra-small:mb-4"
              renderOnZeroPageCount={null}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default CarRequest;
