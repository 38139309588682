import React, { useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import ReactPaginate from "react-paginate";
import close from "../../../../../assests/clos.png";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import Popup from "../../../../../util/popup/Popup";
import CancelBooking from "./CancelBooking";
import EyeIcon from "../../../../../assests/EyeIcon";
import { useNavigate } from "react-router-dom";
import { GetBookingMangPageNo } from "../../../../../lib/Api/Management";
import LoaderSpinner from "../../../../../util/LoaderSpinner";
import { FormatePrice } from "../../../../../Partials/FormatePriceSpace";
const DataList = ({ rents,
  searchData,
  setRents,
  loading,
  setSearchData,
  setLoading,
  active,
  totalPage,
  setCurrentPageIndex,
  currentPageIndex,
  setOffsetPage }) => {
  const [show, setShow] = useState(false);
  const [rentId, setRentId] = useState(null);

  const [itemperPage] = useState(10);
  const navigate = useNavigate();


  const openPopup = (e , item ) => {
    e.stopPropagation();
    setRentId(item?.id)
    setSearchData("cancel")
    setShow(true);
  };


  // const endOffset = itemOffset + itemperPage;
  // const currentItems = Array(vehicalList?.total).slice(itemOffset, endOffset);
  const pageCount = Math.ceil(totalPage / itemperPage);

  const handlePageClick = async (event) => {
    setLoading(true);
    let { res } = await GetBookingMangPageNo(event.selected + 1, searchData);
    setLoading(false);
    setRents(res.data);
    setOffsetPage(event.selected + 1)
    setCurrentPageIndex(event.selected)


  };

  return (
    <>
      {show && <Popup
        id="job-modal"
        modalOpen={show}
        data={<CancelBooking   setSearchData={setSearchData} rentId={rentId} setShow={(val) => setShow(val)} />}
        onClose={() => setShow(false)}
      />
      }
      <div className="bg-white rounded-md shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)]">
        <div className="overflow-x-auto  mt-6">
          {
            !loading &&
              rents?.length > 0 ?
              <>
                <table className="table-auto w-full ">
                  {/* Table header */}
                  <thead className="text-xs font-semibold bg-[#FFF8EF] border-0">
                    <tr className="text-[#626973]">
                      <th className="px-4 py-4 pr-[25px] whitespace-nowrap w-px">
                        <div className="font-semibold text-[14px] text-left">
                          ID{" "}
                        </div>
                      </th>
                      <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                        <div className="font-semibold text-[14px] text-left">
                          Vehicle Name
                        </div>
                      </th>
                      <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                        <div className="font-semibold text-[14px] text-center">
                          Booking Status
                        </div>
                      </th>
                      <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                        <div className="font-semibold text-[14px] text-left">
                          City
                        </div>
                      </th>
                      <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                        <div className="font-semibold text-[14px] text-left">
                          Owner Email
                        </div>
                      </th>
                      <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                        <div className="font-semibold text-[14px] text-left">
                          Payout{" "}
                        </div>
                      </th>
                      <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                        <div className="font-semibold text-[14px] text-left">
                          Total Amount{" "}
                        </div>
                      </th>
                      <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                        <div className="font-semibold text-[14px] text-center">
                          Action{" "}
                        </div>
                      </th>
                    </tr>
                  </thead>
                  {/* Table body */}
                  <tbody className="text-sm ">
                    {rents?.map((item, i) => (
                      <tr className=" cursor-pointer  " key={i}>
                        <td className="px-4 py-4 pr-[25px] whitespace-nowrap">
                          <div className="text-left text-[14px] text-[#898989]">
                            {item?.id}
                          </div>
                        </td>
                        <td className="px-4 py-4 pr-[25px] whitespace-nowrap">
                          <div className="text-left text-[14px] text-[#898989]">
                            {item?.vehicle?.make}<span className="px-2">{item?.vehicle?.model}</span>
                          </div>
                        </td>
                        <td className="px-4 py-4 pr-[25px] whitespace-nowrap text-center">
                          <div className="text-center inline-block py-[3px] w-fit px-2.5 text-[12px] leading-4  rounded-full "
                            style={{
                              color: `${item?.status_object?.text_color}`,
                              backgroundColor: `${item?.status_object?.background_color}`,
                            }}
                          >
                            {item?.status_object?.text_en}
                          </div>
                        </td>
                        <td className="px-4 py-4 pr-[25px] whitespace-nowrap">
                          <div className="text-left text-[14px] text-[#898989]">
                            {item?.vehicle && item?.vehicle?.locations[0]?.city}
                          </div>
                        </td>
                        <td className="px-4 py-4 pr-[25px] whitespace-nowrap">
                          <div className="text-left text-[14px] text-[#898989]">
                            {item?.vehicle?.user?.email}
                          </div>
                        </td>
                        <td className="px-4 py-4 pr-[25px] whitespace-nowrap">
                          <div className="text-left text-[14px] text-[#898989]">
                            Done
                          </div>
                        </td>

                        <td className="px-4 py-4 pr-[25px] whitespace-nowrap">
                          <div className="text-left text-[14px] text-[#898989]">
                            {FormatePrice(item?.amount)}FCFA
                          </div>
                        </td>
                        <td className="px-4 py-4 pr-[25px] whitespace-nowrap">
                          <div className="text-center flex justify-center">
                            {/* <BsThreeDotsVertical className='text-[20px] text-[#898989]' /> */}
                            {/* <GenericDrodown /> */}

                            <div className="group inline-block">
                              <button className="outline-none focus:outline-none  px-3 py-1 bg-white rounded-sm flex items-center">
                                <BsThreeDotsVertical className="text-[20px] text-[#898989]" />
                              </button>
                              <ul
                                className="bg-white shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] border-0 rounded-md p-4 transform scale-0 group-hover:scale-100 absolute 
  transition duration-150 ease-in-out right-7 origin-top xl:w-[15%]  "
                              >
                                <li
                                  onClick={() => {
                                    navigate(`/dinss/tech/management/booking-detail/${item?.id}`);
                                  }}
                                  className="rounded-sm actions flex items-center px-2 py-2 mb-2 hover:bg-gray-100"
                                >
                                  <EyeIcon className="text-[14px] text-[#98A2B3]" />
                                  <h2 className="ml-2">View details</h2>
                                </li>
                                {item?.status_object?.text_en !== "Canceled" &&
                                  <li
                                    onClick={(e) => openPopup(e , item)}
                                    className="rounded-sm actions flex items-center px-2 py-2 mb-2 hover:bg-gray-100"
                                  >
                                    <img
                                      src={close}
                                      alt="close"
                                      className="object-cover"
                                    />
                                    <h2 className="ml-2">Cancel Booking</h2>
                                  </li>
                                }
                              </ul>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
              :
              <div className="p-5 ">
                {loading ? (
                  <LoaderSpinner type="tableLoad" />
                ) : (
                  <h2>Record not found</h2>
                )}
              </div>
          }
        </div>

        {
          rents?.length > 0 ?

            <div className="flex flex-wrap justify-end items-end pt-6 px-5">

              <div className="lg:px-8 my-6 ">
                <ReactPaginate
                  breakLabel="..."
                  breakClassName=" bg-[#F3F3F3]"
                  nextLabel={<MdOutlineKeyboardArrowRight />}
                  onPageChange={handlePageClick}
                  activeClassName="bg-[#FF8C00] text-white rounded"
                  nextClassName="px-3  bg-[#F3F3F3] text-[20px] pt-[5px] rounded"
                  previousClassName="px-3  bg-[#F3F3F3] text-[20px] pt-[5px] rounded"
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel={<MdOutlineKeyboardArrowLeft />}
                  className=" flex justify-end"
                  pageClassName=" w-[30px] h-[30px]  text-center mx-2 pt-[3px] rounded bg-[#F3F3F3] "
                  // renderOnZeroPageCount={null}
                  forcePage={currentPageIndex}
                />
              </div>
            </div>
            : null}
      </div>
    </>
  );
};

export default DataList;
