import { addDoc, collection, doc, getDocs, setDoc , Timestamp } from "firebase/firestore";
import { db } from "../../../../confiq/firebase";
import moment from "moment";

const utcTime = moment.utc().format('YYYY-MM-DD HH:mm:ss');

// Set the desired date and time in UTC
const startDate = moment.utc().utcOffset('+05');
// Format the date as required
const formattedStartDate = startDate.format('MMMM D, YYYY [at] h:mm:ss A [UTC]Z');



export const senderConversation = async (userId, data) => {
    try {
        // Reference to the user's conversation collection
        const conversationRef = collection(db, `Users/${0}/Conversation`);

        // Query to get the last conversation document
        const querySnapshot = await getDocs(conversationRef);
        let lastId = 0;

        // Check if there are existing documents
        if (!querySnapshot.empty) {
            // Get the last document and extract its ID
            querySnapshot.forEach((doc) => {
                const id = parseInt(doc.id);
                if (id > lastId) {
                    lastId = id;
                }
            });
        }

        // Check if conversation already exists
        const existingConversation = querySnapshot.docs.find(doc => parseInt(doc.id) === lastId);
        if (existingConversation) {
            const existingConversationData = existingConversation.data();
            // Check if renterId is the same as userId
            if (existingConversationData.renterId === userId) {
                return existingConversation.id;
            }
        }

        // Generate a new conversation ID by incrementing the last ID
        const newConversationId = lastId + 1;

        // Create the new conversation document
        const newConversationRef = doc(conversationRef, `${newConversationId}`);
        const senderChat = {
            conversationId: newConversationId,
            lastMessage: data?.message,
            lastMessageTime: utcTime,
            otherUserId: userId,
            conversationType: 'single',
            endDate: formattedStartDate,
            renterId: 0,
            ownerId: userId,
            startDate: formattedStartDate,
            
        };
        await setDoc(newConversationRef, senderChat);

        // console.log("Conversation created successfully with ID:", newConversationId);
        return newConversationId;
    } catch (error) {
        console.error("Error creating conversation:", error);
        return null;
    }
};
export const reciverConversation = async (userId,conversationId,  data) => {
    try {
        // Reference to the user's conversation collection
        const conversationRef = collection(db, `Users/${userId}/Conversation`);
        // Create the new conversation document
        const newConversationRef = doc(conversationRef, `${conversationId}`);
        const senderChat = {
            conversationId: conversationId,
            lastMessage: data?.message,
            lastMessageTime: utcTime,
            otherUserId: 0,
            conversationType: 'single',
            endDate: formattedStartDate,
            renterId: userId,
            ownerId: 0,
            startDate: formattedStartDate,
        };
        await setDoc(newConversationRef, senderChat);

        // console.log("Conversation created successfully with ID:", conversationId);
        return conversationId;
    } catch (error) {
        console.error("Error creating conversation:", error);
        return null;
    }
};

export const sendMessage = async (userId, conversationId, messageData) => {
    try {
        const messageRef = collection(db, `Users/${userId}/Conversation/${conversationId}/Messages`);
        const newMessageRef = await addDoc(messageRef, messageData);
        return newMessageRef.id;
    } catch (error) {
        console.error("Error sending message:", error);
        return null;
    }
};

// Example of how to call this function:
export const onCreateConversationAndSendMessage = async (userId, data) => {
    const conversationId = await senderConversation(userId, data);
    await reciverConversation(userId,conversationId , data);
    if (conversationId) {
        const messageData = {
            conversationId: `${conversationId}`,
            senderId: userId,
            messageText: data?.message,
            messageImage: "",
            messageId: "",
            isRead: false,
            messageTime: utcTime,
            messageType: "text",
        };
        await sendMessage(0, conversationId, messageData);
    }
    const messageData2 = {
        conversationId: `${conversationId}`,
        senderId: 0,
        messageText: data?.message,
        messageImage: "",
        messageId: "",
        isRead: false,
        messageTime: utcTime,
        messageType: "text",
    };
    await sendMessage(userId, conversationId, messageData2);
    return conversationId

};
