import React , { useState , useEffect} from 'react';
import { useLocation } from "react-router-dom";
import Weights from '../../components/AdminComponents/customerList/Weights'
import DataTable from '../../components/AdminComponents/customerReports/RenterListTable/Table'
import PieChart from '../../components/AdminComponents/manageUser/PieChart'
import TransactionTable from '../../components/AdminComponents/customerReports/TransactionTable/Table'
import OwnerWeights from '../../components/AdminComponents/customerList/OwnerWeights'
import { GetCustomerOwnerStatis, GetCustomerRenterStatis } from '../../lib/Api/RentalAppApi';
import Popup from '../../util/popup/Popup';
import UpdateStatusLoader from '../../components/AdminComponents/statusLoaderSpinner/StatusLoader';
const CustomerReports = () => {
    const [statis , setStatic] = useState({})
    const [isStatus, setIsStatus] = useState(false);

    const location = useLocation().pathname;


    let fetchRenterStatis = async () => {
      let { res } = await GetCustomerRenterStatis();
      setStatic(res);
     
    };

  
    let fetchOWnerStatis = async () => {
      let { res } = await GetCustomerOwnerStatis();
      setStatic(res);
     
  
    };
  
    useEffect(() => {
      try {
        if (location === "/dinss/tech/customerRenter") {
          fetchRenterStatis();
        }
        else {
          fetchOWnerStatis()
        }
      } catch (err) { }
    }, [ location]);


    return (
        <>
      {isStatus && <Popup data={<UpdateStatusLoader />} modalOpen={isStatus} />}

            <>
                <div className='grid lg:grid-cols-2 md:grid-cols-2 gap-6 pt-[19px]'>
                    <div className='bg-white shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] rounded-md h-full p-5 '>
                        <div className='flex justify-between items-center'>
                            <h2 className='text-[#FF8C00] leading-[20.16px] text-[20px] font-semibold'>Statistics </h2>
                        </div>
                        <PieChart statis={statis?.graph} />
                    </div>
                    <div>
                        { location === "/dinss/tech/customerRenter" ? <Weights Static={statis?.other_stats} /> : <OwnerWeights Static={statis?.other_stats}/> }
                    </div>
                </div>


                <div className='bg-white shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] rounded-md h-full p-5 mt-5'>
                    <DataTable title={ location === "/dinss/tech/customerRenter" ? "Vehicle Renters List" : "Vehicle Owner's List"} />
                </div>
                
                <div className='bg-white rounded-md h-full mt-5'>
                    <TransactionTable title={"Transaction"} />
                </div>
            </>
        </>
    )
}

export default CustomerReports