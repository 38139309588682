import React from 'react'
import { MdClose } from 'react-icons/md'

const AddQuestion = ({ title, onClose , answers }) => {
    // console.log("answers", answers)
    return (
        <>
            <div className='flex justify-between'>
                <div className='px-0'>
                    <h2 className="text-[#444444] text-[14px] ">{title}</h2>
                    <input type='text' readOnly value={answers} placeholder='Write here...' className='border focus:outline-none mt-4 w-[350px] px-5 py-2 rounded' />
                </div>
                <div className='ml-10'>
                    <MdClose className='cursor-pointer' onClick={() => onClose()} />
                </div>
            </div>
        </>
    )
}

export default AddQuestion