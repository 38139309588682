import React, { useEffect, useRef, useState } from "react";
// import bannerImge from "../../../assests/PMNeew/image 1.svg";
import bannerImge from "../../../assests/bannerImg.webp";
import { Link, useNavigate } from "react-router-dom";
// import { MdLocationOn } from 'react-icons/md'
// import { AiFillCalendar } from "react-icons/ai";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { fr } from "date-fns/locale";
import { times } from "../../../Partials/TimeSlotsHalfHourInterval";
import moment from "moment";
import "moment/locale/fr";
import PlaceAutocomplete from "../../../Partials/PlaceAutocomplete";
// import { GetFilter } from "../../../lib/Api/WebsiteApi";
import { useDispatch } from "react-redux";
import { searchInfoSet } from "../../../Redux/UserAuthSlice/UserAuthSlice";
import DateTimeModal from "../search/DateTimeModal";
const Banner = () => {
  // const myDate = `${new Date().getMonth()}/${new Date().getDay()}/${new Date().getFullYear()}`;
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);
  // const myTime = `${new Date().getHours()}:${new Date().getMinutes()}`;

  const currentTime = moment();
  // Add 30 minutes to the current time
  const newTime = currentTime.add(30, "minutes");
  // Round to the nearest 30 minutes
  const roundedTime = moment(newTime)
    .startOf("hour")
    .add(Math.round(newTime.minute() / 30) * 30, "minutes");
  // Format the rounded time
  const formattedTime = roundedTime.format("hh:mm");

  const parsedDate = moment(today, "DD/M/YYYY");
  const parsedDateTwo = moment(tomorrow, "DD/M/YYYY");
  const formattedDate = parsedDate.format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
  const todayLatest = new Date(formattedDate);

  const [selected, setSelected] = useState(todayLatest);
  const [selected2, setSelected2] = useState(tomorrow);
  const [dateOne, setDateOne] = useState(moment(today).format("DD/MM/YYYY"));
  const [dateTwo, setDateTwo] = useState(moment(tomorrow).format("DD/MM/YYYY"));
  const [showInputs, setShowInputs] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showDatePicker2, setShowDatePicker2] = useState(false);
  const [showTimePicker, setShowTimePicker] = useState(false);
  const [showTimePicker2, setShowTimePicker2] = useState(false);
  const [timeValue, setTimeValue] = useState(formattedTime);
  const [timeValue2, setTimeValue2] = useState(formattedTime);
  const [dateOriginalOne, setDateOriginalOne] = useState(parsedDate);
  const [dateOriginalTwo, setDateOriginalTwo] = useState(parsedDateTwo);
  // const [locate, setLocate] = useState("")
  const [latitude, setLatitude] = useState(5.261192);
  const [longitude, setLongitude] = useState(5.261192);
  const [convertedTime1, setConvertedTime1] = useState("");
  const [convertedTime2, setConvertedTime2] = useState("");
  const [defaultTimeIn24, setDefaultIn24] = useState();
  const [defaultAddress, setDefaultAdress] = useState(
    " Aéroport d'Abidjan-Félix Houphouët Boigny, 07 BP 30 Abidjan 07, Côte d’Ivoire"
  );
  const [error, setError] = useState("");

  const datePickerRef = useRef(null);
  const datePicker2Ref = useRef(null);
  const timePickerRef = useRef(null);
  const timePicker2Ref = useRef(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [showMobileModalsInputs, setShowMobileModalsInputs] = useState(false);
  const [selectedDateOne, setSelectedDateOne] = useState(parsedDate);
  const [selectedDateTwo, setSelectedDateTwo] = useState(parsedDateTwo);

  const convertTo24HourFormat2 = () => {
    const inputDate = new Date(today);
    const convertedTimeString = inputDate.toLocaleTimeString("en-US", {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
    });
    setDefaultIn24(convertedTimeString);
  };

  const convertTo24HourFormat = (value, type) => {
    const originalTime = moment(value.time, `hh:mm`);
    let minutesToAdd = 30;
    const newTime = originalTime.add(minutesToAdd, "minutes");
    const convertedTime1 = newTime.format("hh:mm");
    let payload = convertedTime1;
    if (type === "first") {
      setTimeValue(convertedTime1);
      setConvertedTime1(payload);
    } else {
      setTimeValue2(convertedTime1);
      setConvertedTime2(payload);
    }
  };

  useEffect(() => {
    const updateDateFormats = () => {
      if (window.innerWidth < 768) {
        setShowMobileModalsInputs(true);
      } else {
        setShowMobileModalsInputs(false);
      }
      moment.locale("fr");
      const formattedDate = moment(new Date(selectedDateOne)).format(
        window.innerWidth < 768 ? "D MMM" : "ddd D MMM"
      );
      const formattedDate2 = moment(new Date(selectedDateTwo)).format(
        window.innerWidth < 768 ? "D MMM" : "ddd D MMM"
      );
      setDateOriginalOne(formattedDate);
      setDateOriginalTwo(formattedDate2);
    };

    // Call the function on mount and window resize
    updateDateFormats();

    const handleResize = () => {
      updateDateFormats();
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dateOriginalOne, dateOriginalTwo]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const triggerInputs = () => {
    if (window.innerWidth < 768) {
      setShowInputs(true);
    }
  };

  const toggleDatePicker = () => {
    if (showMobileModalsInputs) {
      setModalOpen(true);
    }
    setShowDatePicker(true);
    setShowDatePicker2(false);
    setShowTimePicker(false);
    setShowTimePicker2(false);
  };

  const handleDaySelect = (date) => {
    // moment.locale("fr");
    const formattedDate = moment(date).format("ddd D MMM");
    const parsedDate = moment(date, "DD/M/YYYY");
    const formattedDate2 = parsedDate.format(
      "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
    );
    const todayLatest = new Date(formattedDate2);
    setSelected(todayLatest);
    setSelectedDateOne(parsedDate);
    setDateOriginalOne(formattedDate);
    setDateOne(moment(date).format("DD/MM/YYYY"));
    setShowDatePicker(false);
    setShowTimePicker(true);
  };

  const handleTime = (value, type) => {
    if (type === "first") {
      convertTo24HourFormat(value, "first");
      if (!showMobileModalsInputs) {
        setShowTimePicker(false);
        setShowDatePicker(false);
        toggleDatePicker2();
      } else {
        const modal = document.getElementById("modal");
        if (modal) {
          modal.classList.add("animation-slideDown");
          setTimeout(() => {
            toggleDatePicker2();
            setModalOpen(false);
            modal.classList.remove("animation-slideDown");
          }, 300); // Use the same duration as your animation
        }
      }
    } else {
      if (!showMobileModalsInputs) {
        setShowTimePicker2(false);
      } else {
        closeModal();
      }
      convertTo24HourFormat2(value, "second");
    }
  };

  const toggleDatePicker2 = () => {
    if (showMobileModalsInputs) {
      setModalOpen2(true);
    }
    setShowDatePicker(false);
    setShowDatePicker2(true);
    setShowTimePicker2(false);
    setShowTimePicker(false);
  };

  const handleDaySelect2 = (date) => {
    // moment.locale("fr");
    const formattedDate = moment(date).format("ddd D MMM");
    const parsedDate = moment(date, "DD/M/YYYY");
    const formattedDate2 = parsedDate.format(
      "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
    );
    const todayLatest = new Date(formattedDate2);
    setSelected2(todayLatest);
    setSelectedDateTwo(parsedDate);
    setDateOriginalTwo(formattedDate);
    setDateTwo(moment(date).format("DD/MM/YYYY"));
    setShowDatePicker2(false);
    setShowTimePicker2(true);
  };
  const handleFilter = async () => {
    moment.locale("en");
    let pyaload = {
      longitude,
      latitude,
      start_date: dateOne || dateOriginalOne,
      start_time: convertedTime1 || timeValue,
      end_date: dateTwo || dateOriginalTwo,
      end_time: convertedTime2 || timeValue2,
      address: defaultAddress,
      defaultTime: defaultTimeIn24,
      location: "in_abidjan",
    };

    if (defaultAddress !== "") {
      dispatch(searchInfoSet(pyaload));
      navigate("/search");
    } else {
      setError("Please enter Location");
      // let { res } = await GetFilter(pyaload)
    }
  };

  const isDateDisabled = (date) => {
    moment.locale("en");
    const parsedDate = moment(selected, "DD/M/YYYY");
    const formattedDate = parsedDate.format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
    const today = new Date(formattedDate);
    return date < today;
  };

  const isDateDisabledTomorrow = (date) => {
    moment.locale("en");
    const parsedDate = moment(selected2, "DD/M/YYYY");
    const formattedDate = parsedDate.format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
    const today = new Date(formattedDate);
    return date < today;
  };

  const handleOutsideClick = () => {
    // if (showDatePicker === true) {
    //     setShowDatePicker(false)
    // }
    // else if (showTimePicker === true) {
    //     setShowTimePicker(false)
    // }
    // else {
    //     setShowTimePicker2(false)
    // }
  };

  const closeModal = () => {
    const modal = document.getElementById("modal");
    if (modal) {
      modal.classList.add("animation-slideDown");
      setTimeout(() => {
        setModalOpen(false);
        setModalOpen2(false);
        setShowDatePicker(false);
        setShowTimePicker(false);
        setShowDatePicker2(false);
        setShowTimePicker2(false);
        modal.classList.remove("animation-slideDown");
      }, 300); // Use the same duration as your animation
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Close the date picker if the click is outside the date picker
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setShowDatePicker(false);
      } else if (
        datePicker2Ref.current &&
        !datePicker2Ref.current.contains(event.target)
      ) {
        setShowDatePicker2(false);
      } else if (
        timePickerRef.current &&
        !timePickerRef.current.contains(event.target)
      ) {
        setShowTimePicker(false);
      } else if (
        timePicker2Ref.current &&
        !timePicker2Ref.current.contains(event.target)
      ) {
        setShowTimePicker2(false);
      }
    };

    // Attach the event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Detach the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (defaultAddress !== "") {
      setError("");
    }
    convertTo24HourFormat2();
  }, [defaultAddress]);

  return (
    <>
      <div className="landing_bg_shade py-[50px] xl:justify-center">
        <div className="mx-auto xl:px-[110px] md:px-20 px-[20px] max-w-7xl">
          <div className="h-full">
            <div className="text-center max-w-[730px] w-full mx-auto">
              <h1 className="lg:text-[48px] text-[28px] pb-0 text-[#444444] lg:leading-[65px] md:leading-[56px] leading-[38px] font-semibold ">
                Louez votre véhicule pour{" "}
                <span className="text-[#FF8C00]">satisfaire vos objectifs</span>
              </h1>
              <span className="lg:text-[16px] text-[13px] text-[#444444] mt-[8px]">
                C’est simple et en quelques clics
              </span>
            </div>
            <div className="flex justify-center max-w-[850px] mx-auto ">
              <img
                src={bannerImge}
                alt="banner_img"
                className="lg:object-contain  md:w-[980px] md:h-[220px] w-full py-[24px] banner_img"
              />
            </div>
          </div>

          <div className="lg:-mt-[30px]">
            {/* large display   */}
            <div className="bg-white shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] px-[28px] py-[8px] rounded-lg md:flex-row flex-col flex lg:flex-nowrap md:flex-nowrap tablet:flex-nowrap w-full  flex-wrap md:justify-between max-w-[1150px] border-t lg:mt-4">
              <div
                className="flex flex-col justify-between"
                onFocus={triggerInputs}
              >
                <label className="md:text-[#ACACAC] text-[#667085] lg:text-[16px] text-[12px] leading-[16px] ">
                  Où recherchez vous votre véhicule ?
                </label>
                <div className=" flex items-center py-[10px] font-semibold">
                  <PlaceAutocomplete
                    // setLocate={setLocate}
                    setLatitude={setLatitude}
                    setLongitude={setLongitude}
                    // setPlace={setPlace}
                    defaultAddress={defaultAddress}
                    setDefaultAdress={setDefaultAdress}
                  />
                </div>
                {error ? (
                  <div className="text-red-500 text-md">{error}</div>
                ) : (
                  ""
                )}
              </div>

              <span className="border border-[#EAEAEA] my-[16px] md:my-0 md:block hidden"></span>

              <div
                className={`${
                  showInputs ? "" : "hidden md:block"
                } lg:flex flex-col justify-between`}
              >
                <label className="md:text-[#ACACAC] text-[#667085] lg:text-[16px] text-[12px] leading-[16px]">
                  Début
                </label>
                <div className=" flex items-center py-[10px] font-semibold relative">
                  <div className="flex justify-center items-center">
                    <div
                      onClick={toggleDatePicker}
                      className="w-full  placeholder:text-[#606060] font-family-open-sans font-weight-400 text-[#444444] text-[16px] font-semibold cursor-pointer  xl:bg-transparent bg-[#F4F4F4]  xl:px-0 px-2  h-full focus:outline-none"
                    >
                      {dateOriginalOne
                        ? `${dateOriginalOne} ${timeValue}`
                        : "Tue 15 Feb, 09:00"}
                    </div>

                    {showMobileModalsInputs && (
                      <div ref={showDatePicker ? datePickerRef : timePickerRef}>
                        <DateTimeModal
                          selected={selected}
                          disabled={isDateDisabled}
                          onSelect={handleDaySelect}
                          locale={fr}
                          showDatePicker={showDatePicker}
                          closeModal={closeModal}
                          showTimePicker={showTimePicker}
                          times={times}
                          handleTime={handleTime}
                          modalOpen={modalOpen}
                          type={modalOpen ? "first" : null}
                          heading="Début"
                          isModal1={true}
                        />
                      </div>
                    )}

                    {showDatePicker && !showMobileModalsInputs && (
                      <div
                        ref={datePickerRef}
                        className="bg-white shadow-md absolute z-40 cstmPosTop data_pick "
                      >
                        <DayPicker
                          mode="single"
                          selected={selected}
                          disabled={isDateDisabled}
                          onSelect={handleDaySelect}
                          locale={fr}
                        />
                      </div>
                    )}
                    {showTimePicker && !showMobileModalsInputs && (
                      <div
                        ref={timePickerRef}
                        className="bg-white shadow-md lg:w-auto w-full absolute z-40 timPick cstmPosTop right-0 rounded px-5 py-4"
                      >
                        <div className="max-h-[250px] lg:w-[80px]  overflow-y-auto text-enter time_scroll">
                          {times?.map((time, index) => (
                            <p
                              key={index}
                              onClick={() => handleTime(time, "first")}
                              className=" lg:max-w-[120px] text-center w-full text-[15px] font-sans hover:bg-gray-200 cursor-pointer font-normal"
                            >
                              {time?.time}
                            </p>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <span className="border border-[#EAEAEA] my-[16px] md:my-0 md:block hidden"></span>

              <div
                className={`${
                  showInputs ? "" : "hidden md:block"
                } lg:flex flex-col justify-between text-[16px] leading-[21.79px] text-[#667085]`}
              >
                <label className="md:text-[#ACACAC] text-[#667085] lg:text-[16px] text-[12px] leading-[16px]">
                  Fin
                </label>
                <div className=" flex items-center py-[10px] font-semibold relative">
                  <div className="flex justify-center items-center">
                    <div
                      onClick={toggleDatePicker2}
                      className="w-full placeholder:text-[#606060] font-family-open-sans font-weight-400 text-[#444444] cursor-pointer xl:bg-transparent bg-[#F4F4F4]  xl:px-0 px-2  h-full focus:outline-none"
                    >
                      {dateOriginalTwo
                        ? `${dateOriginalTwo} ${timeValue2}`
                        : "Tue 15 Feb, 09:00"}
                    </div>
                    {showMobileModalsInputs && (
                      <div
                        ref={showTimePicker2 ? datePicker2Ref : timePicker2Ref}
                      >
                        <DateTimeModal
                          selected={selected2}
                          disabled={isDateDisabledTomorrow}
                          onSelect={handleDaySelect2}
                          locale={fr}
                          showDatePicker={showDatePicker2}
                          closeModal={closeModal}
                          showTimePicker={showTimePicker2}
                          times={times}
                          handleTime={handleTime}
                          modalOpen={modalOpen2}
                          type={modalOpen2 ? "second" : null}
                          heading="Fin"
                        />
                      </div>
                    )}

                    {showDatePicker2 && !showMobileModalsInputs && (
                      <div
                        ref={datePicker2Ref}
                        className="bg-white shadow-md absolute z-40 cstmPosTop  data_pick2 text-[#667085]"
                      >
                        <DayPicker
                          mode="single"
                          selected={selected2}
                          disabled={isDateDisabledTomorrow}
                          onSelect={handleDaySelect2}
                          locale={fr}
                        />
                      </div>
                    )}
                    {showTimePicker2 && !showMobileModalsInputs && (
                      <div
                        ref={timePicker2Ref}
                        className="bg-white shadow-md absolute z-40 lg:w-auto w-full timPick cstmPosTop right-0 rounded px-5 py-4"
                      >
                        <div className="max-h-[250px] lg:w-[80px] w-full  overflow-y-auto text-enter time_scroll">
                          {times?.map((time) => (
                            <p
                              onClick={() => handleTime(time, "second")}
                              className=" lg:max-w-[120px] w-full text-[15px] text-[#667085] font-sans hover:bg-gray-200 cursor-pointer font-normal"
                            >
                              {time?.time}
                            </p>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="pt-0 md:w-auto md:mt-0 mt-[22px] w-full 2xl:w-auto md:self-center">
                {/* <Link to="/dinss/tech/search" className=""> */}
                <button
                  onClick={() => handleFilter()}
                  className={`bg-[#FF8C00] text-white w-full py-[10px] px-[30px] rounded-md text-[16px]`}
                >
                  Rechercher
                </button>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
