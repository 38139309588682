import React from 'react'
import Messages from '../../manageUser/chats/Messages'
// import { BsCheck } from 'react-icons/bs'
import { MdKeyboardArrowRight } from 'react-icons/md'
// import user from '../../../../assests/ticketuser.png'
import { Link, useLocation, useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { Firebaseapp } from "../../../../confiq/firebase";

import {
    getFirestore,
    collection,
    query,
    getDocs,
} from "firebase/firestore";
import { AssignTicket, GetSalesDetails, GetTicketDetails, MarkAsResolve } from '../../../../lib/Api/CustomerSupport'
import LoaderSpinner from '../../../../util/LoaderSpinner'
import moment from 'moment'
import { GetRoles } from '../../../../lib/Api/AdminApi'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
// import CommonDropDown from "../dropDown";

const schema = yup.object({
    role: yup.string().required("Role is required"),
    dueDate: yup.string().required("Due Date is required"),
});
const TicketDetail = () => {
    const [unRead, setUnRead] = useState()
    const [currentUser, setCurrentUser] = useState({});
    const location = useLocation().pathname
    const [users, setUsers] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [role, setRole] = useState([]);
    const [loading, setLoading] = useState(false);
    const [markLoading, setMarkLoading] = useState(false);
    const [loadingRole, setLoadingRole] = useState(false);
    const [assignLoading, setAssignLoading] = useState(false);
    const [ticketDetail, setTicketDetail] = useState({});
    const [UpdateMessage, setUpdateMessage] = useState({});
    
    const { id } = useParams()

    let checkType = location === `/dinss/tech/ticket-detail/${id}` ?  "Tickets" : location ===`/dinss/tech/vehicalBuyer/${id}` ? "Vehicle Buyer"  : "Reports"
    let changeHeading = location === "/dinss/tech/ticket" ? "Want to create an account in your system, please help!" : `${ticketDetail?.subject}`

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ mode: "onChange", resolver: yupResolver(schema) });


    const getDetail = async () => {
        setLoading(true)
        let { res } = await ( location === `/dinss/tech/vehicalBuyer/${id}` ? GetSalesDetails(id) : GetTicketDetails(id))
        if (res) {
            setLoading(false)
            setTicketDetail(res)
        }
    }

    const onSubmit = async (data) => {
        try {
            let dateFormate = moment(data.dueDate).format('DD/MM/YYYY')
            let paylaod = {
                "assignee_id": data.role,
                "due_date": dateFormate,
                "ticket_id": id
            }
            setAssignLoading(true)
            let { res } = await AssignTicket(paylaod)
            setAssignLoading(false)
        }
        catch (err) { }
    }

    const handleMarkAs = async () => {
        setMarkLoading(true)
        let { res } = await MarkAsResolve(id)
        setMarkLoading(false)
        getDetail()

    }

    const AdminRoles = async () => {
        let { res } = await GetRoles();
        let rols = Object.assign({}, ...res);
        if (rols) {
            setRole(rols.roles)
        }
    }



    useEffect(() => {
        let fetch = async () => {
            const db = getFirestore(Firebaseapp);
            const q = query(collection(db, `Users/`));
            const querySnapshots = await getDocs(q);
            const Users = querySnapshots.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setAllUsers(Users);
        };
        fetch();
        getDetail()
        AdminRoles()
    }, []);

    useEffect(() => {
        let fetch = async () => {
            const db = getFirestore(Firebaseapp);
            // setLoading(true);
            const Conv = query(collection(db, `Users/${0}/Conversation`));
            const querySnapshot = await getDocs(Conv);
            const UsersConv = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            let arr = [];
            for (let index = 0; index < allUsers?.length; index++) {
                const element = allUsers[index];
                for (let j = 0; j < UsersConv?.length; j++) {
                    const element2 = UsersConv[j];
                    if (element.id === element2?.renterId) {
                        let obj = {
                            ...element,
                            ...element2,
                        };
                        arr.push(obj);
                        if (obj?.id === id) {
                            setCurrentUser(obj)
                        }
                    }
                }
            }

            setLoading(false)
            setUsers(arr);
            // setConvId(arr[0])
        };
        if (allUsers?.length > 0) {
            fetch();
        }
    }, [allUsers]);


    let convId = {
        ownerId: 0,
        conversationId: currentUser?.id,
        otherUserId: currentUser?.otherUserId,
        renterId: currentUser?.otherUserId,
        img: currentUser?.userImage,
        name: currentUser?.userName,
        userInfo:"ticketDetail"
    }

    let check = ticketDetail.status === 0 || ticketDetail.status === 1

    let checkUser = allUsers.find((f) => f.userId === "0")

    return (
        <>
            {loading ? <LoaderSpinner type="tableLoad" /> :
                <>

                    <div className='flex items-center'>
                        <h1 className='text-[#ACACAC] text-[16px]'>
                            <Link to={location === `/dinss/tech/ticket-detail/${id}` ? "/dinss/tech/ticket" : location === `/dinss/tech/vehicalBuyer/${id}`? "/dinss/tech/vehicalBuyer":  "/dinss/tech/report"}>
                                {checkType}
                            </Link>
                        </h1>
                        <MdKeyboardArrowRight className='text-[#ACACAC] mx-2 text-[20px]' />
                        <h1 className='text-[#FF8C00] text-[16px]'> {
                            location === `/dinss/tech/ticket-detail/${id}` ? `Ticket #${id}` : location === `/dinss/tech/vehicalBuyer/${id}`? `Ticket #${id}` : `Reports ${id}`} </h1>

                    </div>

                    <div className='bg-white shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] rounded-md mt-10 mb-5 p-5'>
                        <div className='flex items-center'>
                            <p className='text-[#98A2B3] text-[14px] '>
                                {checkType}
                            </p>
                            <p className='text-[#444444] text-[14px] pl-2'>#{id}</p>
                        </div>
                        <div className='pt-5'>
                            <h1 className='text-[#444444] text-[24px] font-semibold'>{changeHeading}</h1>
                            <p className='text-[#444444] text-[14px] pt-4'>{ticketDetail?.message} </p>

                            {ticketDetail?.vehicle != null ? <a href={ticketDetail?.vehicle?.share_url}>View Vehicle Detail</a> : ""}
                        </div>
                    </div>
                    <div className="grid lg:grid-cols-4 gap-4">
                        <div className='lg:col-span-3'>
                            <Messages ticketDetail={ticketDetail} type="ticket" title={"Support"} currentUser={checkUser}  setUpdateMessage ={setUpdateMessage} convId={convId} setUnRead={setUnRead}  />
                        </div>
                        <div className='w-full shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] px-5 py-5 bg-white rounded '>
                            <button disabled={check ? false : true} onClick={() => handleMarkAs()} className='h-[40px] text-[14px] w-full bg-[#2BA676] flex justify-center items-center font-semibold text-white'>
                                {!markLoading && check &&
                                    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-2">
                                        <path d="M6.00016 11.1698L1.83016 6.99984L0.410156 8.40984L6.00016 13.9998L18.0002 1.99984L16.5902 0.589844L6.00016 11.1698Z" fill="white" />
                                    </svg>
                                }
                                {
                                    markLoading ? <LoaderSpinner /> : check ? "Mark as Resolved" : "Resolved"
                                }

                            </button>
                            <div className="pt-5 border-b border-[#ACACAC]">
                                <h1 className='text-[16px] font-semibold'>Ticket details</h1>
                                <ul className='pt-5'>
                                    <li className='flex justify-between pb-4'>
                                        <p className='text-[#ACACAC] text-[14px]'>Ticket ID</p>
                                        <p className='text-[16px] text-[#898989]'>#{id}</p>
                                    </li>
                                    <li className='flex justify-between pb-4'>
                                        <p className='text-[#ACACAC] text-[14px]'>Status</p>
                                        <p className='text-[12px] text-[#0041B2] px-1.5 font-medium py-[2px] bg-[#E9F3FF] rounded-full'>{check ? "Open" : "Close"}</p>
                                    </li>
                                    <li className='flex justify-between pb-4'>
                                        <p className='text-[#ACACAC] text-[14px]'>Req Date</p>
                                        <p className='text-[16px] text-[#898989]'>{moment(ticketDetail?.updated_at).format('DD/MM/YYYY')}</p>
                                    </li>
                                    <li className='flex justify-between pb-4'>
                                        <p className='text-[#ACACAC] text-[14px]'>Type</p>
                                        <p className='text-[16px] text-[#898989]'>General</p>
                                    </li>
                                </ul>
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="pt-5 ">
                                    <h1 className='text-[16px] font-semibold'>User detail</h1>
                                    <ul className='pt-5'>
                                        <li className='flex items-center  pb-4'>
                                            <img src={ticketDetail?.user?.image} alt="user" className='object-cover w-[36px] h-[36px] rounded-full' />
                                            <p className='text-[14px] text-[#444444] px-2'>{ticketDetail?.user?.first_name}   {ticketDetail?.user?.last_name}</p>
                                        </li>
                                        <li className='pb-4'>
                                            <p className='text-[#98A2B3] text-[14px] '>Email</p>
                                            <p className='text-[14px] pt-2 text-[#898989]'>{ticketDetail?.user?.email}</p>
                                        </li>
                                        <li className=' pb-5'>
                                            <p className='text-[#98A2B3] text-[14px]'>Phone</p>
                                            <p className='text-[14px] text-[#898989] pt-2'>{ticketDetail?.user?.phone_without_code} {ticketDetail?.user?.phone_number}</p>
                                        </li>
                                        <li className=' pb-5'>
                                            <p className='text-[#606060] text-[14px] py-2'>Assign to</p>
                                            <select {...register('role')} className="border rounded-md text-[#ACACAC] h-[40px] outline-none px-2 w-full">
                                                <option>{loadingRole ? 'loading...' : "Select Role"}</option>

                                                {
                                                    role.map((r, i) => (
                                                        <option key={i} value={r?.id}>{r?.role}</option>
                                                    ))
                                                }
                                            </select>
                                            {/* <CommonDropDown {...register("role")} list={role} /> */}
                                            {errors.role && (
                                                <p className="text-red-500 text-sm text-start pt-1 ">
                                                    {errors.role.message}
                                                </p>
                                            )}
                                        </li>
                                        <li className=' pb-5'>
                                            <p className='text-[#606060] text-[14px]'>Due date</p>
                                            <input type="date"
                                                pattern="\d{1,2}/\d{1,2}/\d{4}"
                                                {...register('dueDate')
                                                } className='w-full ticket text-[#ACACAC] text-[14px] mt-2 border border-[#E0E0E0] h-[38px] rounded-md focus:outline-none px-2' />
                                            {errors.dueDate && (
                                                <p className="text-red-500 text-sm text-start pt-1 ">
                                                    {errors.dueDate.message}
                                                </p>
                                            )}
                                        </li>
                                    </ul>
                                </div>

                                <div className='pt-8'>
                                    <button className='h-[40px] text-[14px] w-full bg-[#FF8C00] flex justify-center items-center font-semibold text-white rounded-md'>
                                        {
                                            assignLoading ? <LoaderSpinner /> :
                                                "Assign ticket"
                                        }
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default TicketDetail