import React, { useState } from "react";
import Layout from "../../components/layouts/mainLayout/MainLayout";
// import phone from '../../assests/newDesign/phone.svg'
// import mail from '../../assests/newDesign/inbox.svg'
// import locate from '../../assests/newDesign/locat.svg'
// import { FaPhoneAlt } from 'react-icons/fa'
// import { FiMail } from 'react-icons/fi'
// import { MdLocationOn } from 'react-icons/md'
import contact from "../../assests/PMNeew/Component 27.svg";
// import facebook from '../../assests/Facebook Icon2.png'
// import linkdin from '../../assests/Linkedin Icon2.png'
import ContactEmail from "../../assests/newDesign/contact-email.png";
import ContactTelephone from "../../assests/newDesign/contact-telephone.png";
import ContactOffice from "../../assests/newDesign/contact-office.png";
import ProfCheck from "../../assests/newDesign/prof-check.png";
import ProfCross from "../../assests/newDesign/prof-cross.png";
import { ContactPageStoreForm } from "../../lib/Api/WebsiteApi";
import { Helmet } from "react-helmet";
import LoaderSpinner from "../../util/LoaderSpinner";

const ContactUs = () => {
	const [showMsg, setShowMsg] = useState(false);
	const [showError, setShowError] = useState(false);
	const [submitButtonLoader, setSubmitButtonLoader] = useState(false);
	const [firstname, setFirstname] = useState("");
	const [lastname, setLastname] = useState("");
	const [telephone, setTelephone] = useState("");
	const [email, setEmail] = useState("");
	const [company, setCompany] = useState("");
	const [object, setObject] = useState("");
	const [message, setMessage] = useState("");

	const submitForm = async () => {
		const data = {
			first_name: firstname,
			last_name: lastname,
			phone: telephone,
			email: email,
			company_name: company,
			object: object,
			message: message,
		};

		if (firstname && lastname && telephone && email && object && message) {
			setSubmitButtonLoader(true);
			let res = await ContactPageStoreForm(data);
			if (res != null) {
				setShowMsg(true);
				setShowError(false);
				setSubmitButtonLoader(false);
				setFirstname("");
				setLastname("");
				setTelephone("");
				setEmail("");
				setCompany("");
				setObject("");
				setMessage("");
				setTimeout(() => {
					setShowMsg(false);
				}, 2500);
			} else {
				setSubmitButtonLoader(false);
			}
		} else {
			setSubmitButtonLoader(false);
			setShowError(true);
		}
	};

	return (
		<Layout>
			<Helmet>
				<title>Location de voiture sur Primecar : Nous contacter</title>
				<meta
					name="description"
					content="Le site N°1 de location de voiture en Côte d’Ivoire ?"
				/>
				<link
					rel="canonical"
					href="https://primecarapp.com/contactus"
				/>
			</Helmet>

			<div className="flex justify-center">
				<div className="flex flex-col w-[1440px] px-5 md:px-7 lg:py-20 xl:py-32 py-10 md:py-12 lg:px-16 xl:px-[120px] gap-6 md:gap-8 lg:gap-10">
					<div className="flex flex-col gap-3 md:gap-4 text-center">
						<p className="text-[#444444] font-bold text-xl lg:text-2xl xl:text-3xl">
							Contactez-nous
						</p>
						<p className="text-[#667085] font-normal text-xs lg:text-sm">
							Renseignez le formulaire, nos équipes vous
							reviendrons dans un délais de 24h.
						</p>
					</div>

					<div className="flex flex-col md:flex-row gap-2 lg:gap-5 xl:gap-6 w-full">
						<div className="flex w-full bg-white px-4 py-5 rounded-md gap-2 items-center hover:shadow-lg">
							<img
								src={ContactEmail}
								alt="contact-email"
								className="object-contain h-16 md:h-12 lg:h-16 xl:h-20"
							/>

							<div className="flex flex-col justify-between h-10 lg:h-14">
								<p className="text-[#1B2337] font-semibold text-xs lg:text-base xl:text-lg xl:font-bold">
									Email Address
								</p>
								<p className="text-[#BFBFBF] font-normal text-xs lg:text-sm">
									hello@primecarapp.com
								</p>
							</div>
						</div>

						<div className="flex w-full bg-white px-4 py-5 rounded-md gap-2 items-center hover:shadow-lg">
							<img
								src={ContactTelephone}
								alt="contact-email"
								className="object-contain h-16 md:h-12 lg:h-16 xl:h-20"
							/>

							<div className="flex flex-col justify-between h-10 lg:h-14">
								<p className="text-[#1B2337] font-semibold text-xs lg:text-base xl:text-lg xl:font-bold">
									Téléphone
								</p>
								<p className="text-[#BFBFBF] font-normal text-xs lg:text-sm">
									(+225) 2722291647
								</p>
								<p className="text-[#BFBFBF] font-normal text-xs lg:text-sm">
									(+225) 0797229746
								</p>
							</div>
						</div>

						<div className="flex w-full bg-white px-4 py-5 rounded-md gap-2 items-center hover:shadow-lg">
							<img
								src={ContactOffice}
								alt="contact-email"
								className="object-contain h-16 md:h-12 lg:h-16 xl:h-20"
							/>

							<div className="flex flex-col justify-between h-10 lg:h-14">
								<p className="text-[#1B2337] font-semibold text-xs lg:text-base xl:text-lg xl:font-bold">
									Office
								</p>
								<p className="text-[#BFBFBF] font-normal text-xs lg:text-sm">
									Cocody Riviera Faya, Abidjan Côte d'Ivoire
								</p>
							</div>
						</div>
					</div>

					<div className="flex flex-col lg:flex-row gap-4 md:gap-8 lg:gap-12 xl:gap-16 lg:shadow-md lg:rounded-md lg:px-11 lg:py-12 lg:items-center">
						<div className="h-40 md:h-80 rounded-lg lg:w-[320px] xl:w-[400px] lg:h-[520px] xl:h-[580px] overflow-hidden xl:hover:shadow-lg">
							<img
								src={contact}
								className="object-cover w-full h-full transition-all duration-150 ease-in-out xl:hover:scale-110"
								alt="contact_img"
							/>
						</div>

						<div className="grid grid-cols-2 gap-3 lg:gap-4 lg:w-1/2 relative">
							<div className="flex flex-col gap-1">
								<label
									htmlFor="Nom"
									className="text-xs lg:text-sm xl:text-base text-[#667085]"
								>
									Nom
								</label>
								<input
									type="text"
									id="Nom"
									placeholder={"Nom"}
									value={firstname}
									className="text-sm lg:text-base xl:text-lg border border-[#ACACAC] rounded-sm px-3 py-1.5 lg:px-5 lg:py-3"
									onChange={(e) =>
										setFirstname(e.target.value)
									}
								/>
								{showError && !firstname ? (
									<p className="text-red-500 text-sm text-start pt-1 ">
										Veuillez renseigner votre Nom!
									</p>
								) : null}
							</div>

							<div className="flex flex-col gap-1">
								<label
									htmlFor="Prénom"
									className="text-xs lg:text-sm xl:text-base text-[#667085]"
								>
									Prénom
								</label>
								<input
									type="text"
									id="Prénom"
									placeholder={"Prénom"}
									value={lastname}
									className="text-sm lg:text-base xl:text-lg border border-[#ACACAC] rounded-sm px-3 py-1.5 lg:px-5 lg:py-3"
									onChange={(e) =>
										setLastname(e.target.value)
									}
								/>
								{showError && !lastname ? (
									<p className="text-red-500 text-sm text-start pt-1 ">
										Veuillez renseigner votre Prénom!
									</p>
								) : null}
							</div>

							<div className="flex flex-col gap-1">
								<label
									htmlFor="Téléphone"
									className="text-xs lg:text-sm xl:text-base text-[#667085]"
								>
									Téléphone
								</label>
								<input
									type="number"
									id="Téléphone"
									value={telephone}
									placeholder={"Téléphone"}
									className="text-sm lg:text-base xl:text-lg border border-[#ACACAC] rounded-sm px-3 py-1.5 lg:px-5 lg:py-3"
									onChange={(e) =>
										setTelephone(e.target.value)
									}
								/>
								{showError && !telephone ? (
									<p className="text-red-500 text-sm text-start pt-1 ">
										Veuillez renseigner votre numéro de
										téléphone!
									</p>
								) : null}
							</div>

							<div className="flex flex-col gap-1">
								<label
									htmlFor="Email"
									className="text-xs lg:text-sm xl:text-base text-[#667085]"
								>
									Email
								</label>
								<input
									type="email"
									id="Email"
									value={email}
									placeholder={"Email"}
									className="text-sm lg:text-base xl:text-lg border border-[#ACACAC] rounded-sm px-3 py-1.5 lg:px-5 lg:py-3"
									onChange={(e) => setEmail(e.target.value)}
								/>
								{showError && !email ? (
									<p className="text-red-500 text-sm text-start pt-1 ">
										Veuillez renseigner votre email!
									</p>
								) : null}
							</div>

							<div className="flex flex-col gap-1 col-span-2 md:col-span-1">
								<label
									htmlFor="Société"
									className="text-xs lg:text-sm xl:text-base text-[#667085]"
								>
									Nom de votre société
								</label>
								<input
									type="text"
									id="Société"
									value={company}
									placeholder={"Facultatif"}
									className="text-sm lg:text-base xl:text-lg border border-[#ACACAC] rounded-sm px-3 py-1.5 lg:px-5 lg:py-3"
									onChange={(e) => setCompany(e.target.value)}
								/>
							</div>

							<div className="md:flex flex-col gap-1 hidden">
								<label
									htmlFor="Objet"
									className="text-xs lg:text-sm xl:text-base text-[#667085]"
								>
									Objet
								</label>
								<input
									type="text"
									id="Objet"
									value={object}
									placeholder={"Objet"}
									className="text-sm lg:text-base xl:text-lg border border-[#ACACAC] rounded-sm px-3 py-1.5 lg:px-5 lg:py-3"
									onChange={(e) => setObject(e.target.value)}
								/>
								{showError && !object ? (
									<p className="text-red-500 text-sm text-start pt-1 ">
										Veuillez préciser l'objet de votre
										email!
									</p>
								) : null}
							</div>

							<div className="md:flex flex-col gap-1 col-span-2 hidden">
								<label
									htmlFor="Message"
									className="text-xs lg:text-sm xl:text-base text-[#667085]"
								>
									Message
								</label>
								<textarea
									name=""
									id="Message"
									value={message}
									placeholder={"Message"}
									className="text-sm lg:text-base xl:text-lg border border-[#ACACAC] rounded-sm px-3 py-1.5 lg:px-5 lg:py-3 w-full lg:h-32"
									onChange={(e) => setMessage(e.target.value)}
								></textarea>
								{showError && !message ? (
									<p className="text-red-500 text-sm text-start pt-1 ">
										Quel message souhaiteriez-vous nous
										envoyer?!
									</p>
								) : null}
							</div>

							<button
								disabled={submitButtonLoader}
								onClick={submitForm}
								className="col-span-2 bg-[#FF8C00] hover:bg-[#D16A00] lg:font-semibold text-white px-8 py-2 lg:px-10 lg:py-3 text-sm  lg:text-base rounded-md w-fit"
							>
								{submitButtonLoader ? (
									<LoaderSpinner type="saved" />
								) : (
									"Envoyer"
								)}
							</button>
							<div
								className={`flex bg-green-600 px-[10px] py-[14px] rounded-md absolute top-0 left-0 w-full justify-between items-start ${
									showMsg ? "" : "hidden"
								}`}
							>
								<div className="flex gap-3 items-start w-full">
									<img
										src={ProfCheck}
										alt="Check"
										className="object-contain w-6"
									/>
									<div className="flex flex-col text-white">
										<p className="text-base font-medium">
											Message envoyé !
										</p>
										<p className="text-[13px] font-normal">
											Nous vous contacterons au plus vite.
										</p>
									</div>
								</div>
								<img
									src={ProfCross}
									alt="Cross"
									className="object-contain w-[28px] cursor-pointer"
									onClick={() => setShowMsg(false)}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default ContactUs;
