import React from 'react'
import car from '../../../assests/first.png'
import car2 from '../../../assests/newDesign/s_2.svg'
import car3 from '../../../assests/newDesign/s_3.svg'
// import vector from '../../../assests/newDesign/s_4.svg'
// import vector2 from '../../../assests/svg/Vector 3.svg'
// import one from '../../../assests/newDesign/srn1.svg'
import oneline from '../../../assests/newDesign/line1.svg'
// import twoline from '../../../assests/newDesign/line2.svg'
// import two from '../../../assests/newDesign/srn2.svg'
// import three from '../../../assests/newDesign/srn3.svg'
// import oneNumber from '../../../assests/newDesign/srn1.svg'
// import twoNumber from '../../../assests/newDesign/srn2.svg'
// import threeNumber from '../../../assests/newDesign/srn3.svg'

const Services = () => {

    const data = [
        {id: 1, comment: 'Trouvez un véhicule', desc: 'Téléchargez l\'application et faites votre recherche selon votre besoin.', img: car},
        {id: 2, comment: 'Réservez votre véhicule', desc: 'Confirmez votre réservation dès qu\'un propriétaire accepte votre demande de location.', img: car2},
        {id: 3, comment: 'Récupérez-le et partez', desc: 'Convenez d\'un point de rendez-vous avec le propriétaire, récupérez le véhicule et partez.', img: car3},
    ]


    return (
        <>
            <div className='bg-[#FFFBF7] pb-10'>
                <div className=' xl:px-[118px] lg:px-[50px]  md:px-0 px-3 py-[60px] flex flex-col items-center'>
                    <div className='text-center '>
                        <h2 className='lg:text-[31px] text-[22px] font-sans text-[#444444] font-bold'>Comment ça <span className='text-[#FF8C00]'>marche</span>?</h2>
                        <p className='lg:text-[14px] pt-[5px] font-sans font-normal text-[13px] text-[#444444]'>Facilitez votre mobilité, un véhicule près de chez vous est prêt pour le déplacement</p>
                    </div>

                    <div className='grid md:grid-cols-3 grid-cols-1 gap-5 mt-10 md:pt-32 items-center max-w-7xl'>

                        {data.map((each,index) => {
                            return (
                                <div key={index} className='flex justify-center flex-col max-w-lg mx-auto'>
                                    <div className='relative'>
                                        <div className='md:absolute relative -top-[110%] md:-top-[150%] md:left-[60%] left-[30%] font-goth z-10 p-[7px]'>
                                            <p id="number_setting">0{each.id}</p>
                                        </div>

                                        <img src={oneline} alt="line_one" className={`change-arrow absolute bottom-0 left-[50%] w-[248px] h-[193px] object-contain hidden ${each.id != 3 ? 'md:block' : ''}`} />

                                        <img src={each.img} alt="Car_img" className='object-contain lg:w-[120px] md:w-[90px] w-[60px] mx-auto' />
                                    </div>
                                    <div className='text-center lg:pt-[32px] pt-[24px] flex flex-col items-center'>
                                        <h2 className='lg:text-[18px] text-[16px] font-semibold'>{each.comment}</h2>
                                        <p className='w-[80%] xl:w-2/3 lg:text-[14px] text-[13px] text-[#5A5A5A] lg:leading-[28px] leading-[24px] lg:pt-4 pt-[8px]'>{each.desc}</p>
                                    </div>
                                </div>
                            )
                        })}

                    </div>

                </div>
            </div>
        </>
    )
}

export default Services