import React, { useEffect } from "react";

import Layout from "../../components/layouts/mainLayout/MainLayout";
import Banner from "../../components/MainLandingComp/rent/Banner";
import Management from "../../components/MainLandingComp/rent/Management";
import Closer from "../../components/MainLandingComp/rent/Closer";
import Primecar from "../../components/MainLandingComp/rent/Primecar";
import { Helmet } from "react-helmet";
const Rent = () => {
	return (
		<Layout>
			<Helmet>
				<title>
					Comment louer ma voiture sur Primecar et gagner de l’argent
				</title>
				<meta
					name="description"
					content="Louer ma voiture sur Primecar, le site N°1 en Côte d’Ivoire, et générer des revenus en toute sécurité."
				/>
				<link
					rel="canonical"
					href="https://primecarapp.com/louer-mon-vehicule"
				/>
			</Helmet>
			<Banner />
			<Management />
			<Closer />
			<Primecar />
		</Layout>
	);
};

export default Rent;
