import React, { useState } from "react";
// import Layout from '../../components/layouts/mainLayout/MainLayout'
// import BreadCums from '../../components/MainLandingComp/breadCums/BreadCums'
// import Left from '../../components/MainLandingComp/search/Left'
import Right from "../../components/MainLandingComp/detail/Right";
// import { FiFilter } from 'react-icons/fi'
// import Sidebar from '../../components/MainLandingComp/search/Sidebar'

const Detail = ({ onClose, carDetail, priceType }) => {
  // const [filter, setFilter] = useState(false)

  return (
    <>
      <Right onClose={onClose} carDetail={carDetail} priceType={priceType} />
    </>
  );
};

export default Detail;