import React, { useEffect, useRef, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import ReactPaginate from "react-paginate";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import Popup from "../../../../util/popup/Popup";
import DeleteData from "../../marketing/DeleteData";
import LoaderSpinner from "../../../../util/LoaderSpinner";
import TrashIcon from "../../../../assests/TrashIcon";
import moment from "moment";
import { GetRegisterUsers, GetUserResctrict } from "../../../../lib/Api/Management";
import {  useSelector } from "react-redux";

const DataList = ({
  // setDetail,
  vehicalList,
  setVehicalList,
  loading,
  setLoading,
  setIsStatus,
  checkStatus,
  setTypes,
  setSearchData,
  setCurrentPageIndex,
  setOffSetPage,
  totalPages,
  tableRef
}) => {
  const [show, setShow] = useState(false);
  const [type, setType] = useState("");
  const [singleVehical, setSingleVehical] = useState({});

  const [itemperPage] = useState(10);
  const pageCount = Math.ceil(totalPages / itemperPage);
  const { downloadExcel } = useSelector((state) => state?.userAuth)

  let RenterFilter = async (event) => {
    let CurentSel = event.selected + 1;
    setLoading(true);
    let res = await GetRegisterUsers(
      CurentSel,
      checkStatus
    );
    setLoading(false);
    setVehicalList(res.data);
    setOffSetPage(CurentSel);
    setCurrentPageIndex(event.selected)
  };

  const handlePageClick = async (event) => {
    RenterFilter(event)
  };

  const DeleteVehical = (list, type) => {
    setType(type);
    // setTypeSave(type)
    setShow(true);
    setSearchData("delete");
    setSingleVehical(list);
  };
  const handleClosePopup = () => {
    setShow(false);
    setSearchData("");
    setType('')
  };

  const handleRestrict = async (item) => {
    try {
      setIsStatus(true)
      setSearchData("restrict");
      let res = await GetUserResctrict(item?.id)
      if (res) {
        setIsStatus(false)
        setSearchData("");
      }

    } catch (err) {
      // console.log("err", err)
    }
  }


  // const tableRef = useRef(null);

  // const { onDownload } = useDownloadExcel({
  //   currentTableRef: tableRef.current,
  //   filename: 'Users table',
  //   sheet: 'Users'
  // })

  // useEffect(() => {
  //     if (tableRef.current) {
  //       dispatch(setDownload(false))
  //       onDownload()
  //   }
  // }, [downloadExcel])

  return (
    <>
      {show && (
        <Popup
          modalOpen={show}
          type={type}
          data={
            <DeleteData
              singleCoupon={singleVehical?.id}
              setType={setType}
              callback={handleClosePopup}
              setTypes={setTypes}
              setShow={(val) => setShow(val)}
              type={"User"}
            />
          }
          onClose={() => setShow(false)}
        />
      )}
      <div>
        <div className="overflow-x-auto bg-white rounded-md mt-6 w-full">
          {vehicalList?.length > 0 ? (
            <>
              <table className="table-auto w-full" ref={tableRef}>
                <thead className="text-xs font-[500] bg-[#FFF8EF] border-0">
                  <tr className="text-[#626973]">
                    <th className="px-4 py-4 pr-[25px] whitespace-nowrap w-px">
                      <div className="font-[600] text-[14px] text-left">
                        ID
                      </div>
                    </th>
                    <th className="px-4 py-4 pr-[25px] whitespace-nowrap w-[20%]">
                      <div className="font-[600] text-[14px] text-left">
                        Username
                      </div>
                    </th>
                    <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                      <div className="font-[600] text-[14px] text-left">
                        Email
                      </div>
                    </th>
                    <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                      <div className="font-[600] text-[14px] text-left">
                        Registration Date
                      </div>
                    </th>
                    <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                      <div className="font-[600] text-[14px] text-left">
                        User Type
                      </div>
                    </th>
                    <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                      <div className="font-[600] text-[14px] text-left">
                        Phone number
                      </div>
                    </th>
                    <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                      <div className="font-[600] text-[14px] text-left">
                        Status{" "}
                      </div>
                    </th>
                    {!downloadExcel &&
                      <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                        <div className="font-[600] text-[14px] text-center">
                          Action{" "}
                        </div>
                      </th>
                    }
                  </tr>
                </thead>
                {/* Table body */}
                <tbody className="text-sm ">
                  {!loading ? (
                    vehicalList?.map((item, i) =>
                      <tr className=" cursor-pointer  " key={i}>

                        <td className="px-2  last:pr-5 py-3 whitespace-nowrap">
                          <div className="text-left text-[14px] text-[#898989] px-1">
                            {item?.id}
                          </div>
                        </td>
                        <td
                          className="px-2 first:pl-5 flex items-center last:pr-5 py-3 whitespace-nowrap"
                        >
                          <div className="object-cover w-8 h-8 rounded-full overflow-hidden flex items-center justify-center">
                            <img
                              src={item?.image}
                              alt="rent_image"
                              className="w-auto"
                            />
                          </div>
                          <div className="text-left  text-[14px] text-[#898989] px-1">
                            {`${item?.first_name} ${item?.last_name}`}
                          </div>
                        </td>
                        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                          <div className="text-left text-[14px] text-[#898989] px-1">
                            {/* {moment(item?.created_at).format("ll HH:mm")} */}
                            {item?.email}
                          </div>
                        </td>
                        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                          <div className="text-left text-[14px] text-[#898989] px-1">
                            {moment(item?.created_at).format("ll HH:mm") || '------'}
                            {/* {item?.business_registration_id || '----'} */}
                          </div>
                        </td>
                        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                          <div className="text-left text-[14px] text-[#898989] px-1">
                            {item?.user_type || '----'}
                          </div>
                        </td>
                        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                          <div className="text-left text-[14px] text-[#FF8C00] px-1">
                            {item?.phone_without_code} {item?.phone_number}
                          </div>
                        </td>

                        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                          <div
                            style={{
                              color: `${item?.status?.text_color}`,
                              backgroundColor: `${item?.status?.background_color}`,
                            }}
                            className={`text-center py-[3px] w-fit px-2.5 text-[12px] leading-4 rounded-full `}
                          >
                            {item?.status?.text}
                          </div>
                        </td>
                          <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                            <div className="text-center flex justify-center  px-1">
                              {/* <BsThreeDotsVertical className='text-[20px] text-[#898989]' /> */}
                              {/* <GenericDrodown /> */}

                              <div className="group inline-block">
                                <button className="outline-none focus:outline-none  px-3 py-1 bg-white rounded-sm flex items-center">
                                  <BsThreeDotsVertical className="text-[20px] text-[#898989]" />
                                </button>
                                <ul
                                  className="bg-white shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] border-0 rounded-md p-4 transform scale-0 group-hover:scale-100 absolute 
  transition duration-150 ease-in-out right-7 origin-top xl:w-[15%]  "
                                >
                                  {item?.restricted_at === null &&
                                    <li
                                      onClick={() =>
                                        handleRestrict(item)
                                      }
                                      className="rounded-sm flex items-center px-2 py-2 hover:bg-gray-100"
                                    >
                                      {/* <TrashIcon className="text-[14px] text-[#98A2B3]" /> */}
                                      <h2 className="ml-2">Restrict</h2>
                                    </li>
                                  }

                                  {item?.restricted_at === null &&
                                    <li
                                      onClick={() =>
                                        handleRestrict(item)
                                      }
                                      className="rounded-sm flex items-center px-2 py-2 hover:bg-gray-100"
                                    >
                                      {/* <TrashIcon className="text-[14px] text-[#98A2B3]" /> */}
                                      <h2 className="ml-2">Restrict</h2>
                                    </li>
                                  }

                                  {item?.status?.text !== "Deleted" &&
                                    <li
                                      onClick={() =>
                                        DeleteVehical(item, "delete")
                                      }
                                      className="rounded-sm flex items-center px-2 py-2 hover:bg-gray-100"
                                    >
                                      <TrashIcon className="text-[14px] text-[#98A2B3]" />
                                      <h2 className="ml-2">Delete</h2>
                                    </li>
                                  }
                                </ul>
                              </div>
                            </div>
                          </td>
                      </tr>
                    )
                  ) : (
                    <div className="pt-4">
                      <LoaderSpinner type="tableLoad" />
                    </div>
                  )}
                </tbody>
              </table>
            </>
          ) : (
            <div className="p-5 ">
              {loading ? (
                <LoaderSpinner type="tableLoad" />
              ) : (
                <h2>Record not found</h2>
              )}
            </div>
          )}
        </div>
        {vehicalList?.length > 0 && (
          <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap justify-between items-center pt-6 ">

            <div className="px-8 xxtra-small:px-0 mt-4 mb-1 w-full flex justify-end xxtra-small:justify-start">
              <ReactPaginate
                breakLabel="..."
                breakClassName=" bg-[#F3F3F3]"
                nextLabel={<MdOutlineKeyboardArrowRight />}
                onPageChange={handlePageClick}
                activeClassName="bg-[#FF8C00] text-white rounded"
                nextClassName="px-3  bg-[#F3F3F3] text-[20px] pt-[5px] rounded xxtra-small:mb-4"
                previousClassName="px-3  bg-[#F3F3F3] text-[20px] pt-[5px] rounded xxtra-small:mb-4"
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel={<MdOutlineKeyboardArrowLeft />}
                className=" flex justify-end  xxtra-small:w-full xxtra-small:justify-start xtra-small:justify-start xxtra-small:flex-wrap xtra-small:flex-wrap"
                pageClassName=" w-[30px] h-[30px]  text-center mx-2 pt-[3px] rounded bg-[#F3F3F3] xxtra-small:mb-4 xtra-small:mb-4"

              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DataList;
