import React, { useEffect, useState } from 'react'
import { GetUserLists } from '../../lib/Api/BroadCast'
import SendCodeForm from '../../components/AdminComponents/sendCode/SendCodeForm'

const SendCode = () => {
    // React hooks
    const [users, setUsers] = useState([])
    const [loading, setLoading] = useState(false)
    const [updateloading, setUpdateLoading] = useState(false)



    useEffect(() => {
        try {
            let fetchData = async () => {
                setLoading(true)
                let { res } = await GetUserLists()
                if (res) {
                    setLoading(false)
                    const options = res?.users.map(user => ({
                        label: user?.first_name,
                        value: user?.id
                    }));
                    setUsers(options)
                }
                else {
                    setLoading(false)
                }
            }
            if (updateloading === false) {
                fetchData()
            }
        }
        catch (err) { }
    }, [updateloading])



    return (
        <>
            <div className="bg-white shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] border-0 border-gray-100 rounded-md h-full p-5 mt-5">
                <SendCodeForm users={users} loading={loading} />
            </div>

        </>
    )
}

export default SendCode