import React, { useEffect, useState } from "react";
import TicketsList from "./CustomerList";
import { MdSearch } from "react-icons/md";
import { GetCustomerSupport, GetSalesSupport, GetSupportSearch, GetSalesSearch } from "../../../../lib/Api/CustomerSupport";
import { useLocation } from "react-router-dom";

const Table = ({ setDetail }) => {
  const [supports, setSupports] = useState([])
  const [loading, setLoading] = useState(false)
  const [totalPage, setTotalPage] = useState()
  const [offsetPage, setoffsetPage] = useState(1)
  const [search, setSearch] = useState("")
  const [detail, setDetails] = useState({})

  const location = useLocation().pathname


  let fetch = async () => {
    setLoading(true)
    let { res } = await (location === "/dinss/tech/vehicalBuyer" ? GetSalesSupport() : GetCustomerSupport())
    if (res) {
      setLoading(false)
      setSupports(res?.data)
      setTotalPage(res.total)
    }
  }

  const handleChange = (e) => {
    setSearch(e.target.value)
  }


  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      if (search !== "") {
        setLoading(true);
        let { res } = await (location === "/dinss/tech/vehicalBuyer" ? GetSalesSearch(offsetPage, search) :  GetSupportSearch(offsetPage, search)  )
        if (res) {
          setLoading(false);
          setSupports(res?.data);
          setTotalPage(res?.total);
        }

        // setSearchData(search);
      }

    }

  };

  useEffect(() => {
    if (search === "")
      fetch()
  }, [search])

  return (
    <>
      <div className="flex  flex-wrap justify-between items-center">
        <h2 className="text-[20px] font-semibold leading-[20.16px]  text-[#444444]">
          {location === "/dinss/tech/vehicalBuyer" ? "Buyer Inquiry" : "Support Tickets"}
        </h2>
        <div className="flex lg:py-0 py-2 ">
          <div className="bg-white rounded-full border-0 flex items-center px-4 h-[45px] sm:w-[380px] w-[350px]  py-3 shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)]">
            <MdSearch className="text-[25px] text-[#FF8C00]" />
            <input
              type="text"
              onKeyDown={handleKeyDown}
              value={search}
              onChange={handleChange}
              className="focus:outline-none mx-2 w-full placeholder:text-[#ACACAC] placeholder:text-[16px]"
              placeholder="Search..."
            />
          </div>
        </div>
      </div>
      <div>
        <TicketsList setDetail={setDetail}
          supports={supports}
          setSupports={setSupports}
          loading={loading}
          setDetails={setDetails}
          totalPage={totalPage}
          setLoading={setLoading} />
      </div>
    </>
  );
};

export default Table;
