import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export const options = {
  responsive: true,
  tooltips: {
    enabled: false,
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
      position: "top",
    },
  },
};



const WalletIncome = ({g1}) => {

  let labelArr = []
  let Owners = []
  let Renters = []

  for (let index = 0; index < g1?.length; index++) {
    const element = g1[index];
    labelArr.push(element?.label)
    Owners.push(element?.this_year)
    Renters.push(element?.prev_year)

  }



  const labels = labelArr

 const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: "Requests Received",
        data: Owners,
        borderColor: "#FF8C00",
        backgroundColor: "rgba(247, 246, 247, 0.5)",
        borderWidth: 1,
        pointRadius: 0,
      },
      {
        fill: true,
        label: " Canceled Booking",
        data: Renters,
        borderColor: "#FFC278",
        backgroundColor: "rgba(247, 241, 236, 0.5)",
        borderWidth: 1,
        pointRadius: 0,
      },
    ],
  };
  return (
    <>
      <div className="h-auto w-full ">
        <Line className="w-full" options={options} data={data} redraw={true} />
      </div>
    </>
  );
};

export default WalletIncome;
