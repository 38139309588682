import React from "react";
import { useNavigate } from "react-router-dom";

const BlogArticleHeaderSection = () => {
	const navigate = useNavigate();

	return (
		<div>
			<div className="md:px-20 px-5 hide-on-mobile">
				<div className="2xl:w-[1440px] m-auto py-[20px]  tablet:pr-10 ">
					<hr className="blog-header-heading-top-line" />
					<p
						className="blog-header-heading"
						onClick={() => {
							navigate("/blog");
						}}
					>
						Le blog
					</p>
				</div>
			</div>
			<div className="">
				<p
					className="blog-article-header-heading-mobile"
					onClick={() => {
						navigate("/blog");
					}}
				>
					Le blog
				</p>
			</div>
		</div>
	);
};

export default BlogArticleHeaderSection;
