import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import LoaderSpinner from "../../../util/LoaderSpinner";
import rent from "../../../assests/rent.png";
import Refund from "./Refund";
import Popup from "../../../util/popup/Popup";
import moment from "moment";
const TransactionList = ({
  trans,
  loading,

}) => {
  const [show, setShow] = useState(false);

  // const location = useLocation().pathname;
  // const navigate = useNavigate();

  // const items = [];
  // const [itemOffset, setItemOffset] = useState(0);
  const [itemperPage] = useState(10);

  // const endOffset = itemOffset + itemperPage;
  // const currentItems = Array(trans?.total).slice(itemOffset, endOffset);
  const pageCount = Math.ceil(Array(trans?.total).length / itemperPage);

  const handlePageClick = async (event) => {
    // const newOffset = (event.selected * itemperPage) % Array(trans?.total).length;
    // setItemOffset(newOffset);
    //  let pageNo = event.selected === 0  ? 1  : event.selected
    // setLoading(true);
    // let { res } = await GetVihicalsBaseOnPages(event.selected + 1);
    // let vehicals = Object.assign({}, ...res);
    // setLoading(false);
    // settrans(vehicals?.vehicles);


  };

  const openPopup = (e, mode) => {
    e.stopPropagation();
    setShow(true);
  };




  return (
    <>
      <div>
        <Popup
          id="job-modal"
          modalOpen={show}
          data={<Refund setShow={(val) => setShow(val)} />}
          onClose={() => setShow(false)}
        />

        <div className="overflow-x-auto bg-white rounded-md mt-6 w-full">
          {trans?.length > 0 ? (
            <>
              <table className="table-auto w-full ">
                {/* Table header */}
                <thead className="text-xs font-semibold bg-[#FFF8EF] border-0">
                  <tr className="text-[#626973]">
                    <th className="px-5 py-4 pr-[25px] whitespace-nowrap w-px">
                      <div className="font-semibold text-[14px] text-left">
                        ID{" "}
                      </div>
                    </th>
                    <th className="px-5 py-4 pr-[25px] whitespace-nowrap">
                      <div className="font-semibold text-[14px] text-left">
                        Vehicle
                      </div>
                    </th>
                    <th className="px-5 py-4 pr-[25px] whitespace-nowrap">
                      <div className="font-semibold text-[14px] text-left">
                        Date
                      </div>
                    </th>
                    <th className="px-5 py-4 pr-[25px] whitespace-nowrap">
                      <div className="font-semibold text-[14px] text-left">
                        Status{" "}
                      </div>
                    </th>
                    <th className="px-5 py-4 pr-[25px] whitespace-nowrap">
                      <div className="font-semibold text-[14px] text-left">
                        Renter{" "}
                      </div>
                    </th>
                    <th className="px-5 py-4 pr-[25px] whitespace-nowrap">
                      <div className="font-semibold text-[14px] text-left">
                        Amount
                      </div>
                    </th>

                    <th className="px-5 py-4 pr-[25px] whitespace-nowrap">
                      <div className="font-semibold text-[14px] text-center">
                        Action{" "}
                      </div>
                    </th>
                  </tr>
                </thead>
                {/* Table body */}
                <tbody className="text-sm ">
                  {trans.map((item, i) => (
                    <tr className=" cursor-pointer  ">
                      <td className="px-5 py-4 pr-[25px] whitespace-nowrap">
                        <div className="text-left text-[14px] text-[#898989]">
                          {item?.id}
                        </div>
                      </td>
                      <td className="px-5 py-4 pr-[25px] whitespace-nowrap">
                        <div className="text-left text-[14px] text-[#898989]">
                          {item?.rent?.vehicle?.make} {item?.rent?.vehicle?.model}
                        </div>
                      </td>
                      <td className="px-5 py-4 pr-[25px] whitespace-nowrap">
                        <div className="text-left text-[14px] text-[#898989]">
                          {moment(item?.created_at).format('DD-MM-YYYY hh:mm')}
                        </div>
                      </td>
                      <td className="px-5 py-4 pr-[25px] whitespace-nowrap">
                        <div className="flex justify-start">
                          <div
                            style={{
                              color: `${item?.rent?.status_object?.text_color}`,
                              backgroundColor: `${item?.rent?.status_object?.background_color}`,
                            }}
                            className={`text-center px-2.5 py-[3px] text-[12px] leading-[16px] rounded-full`}
                          >
                            {item?.rent?.status_object?.text_en}
                          </div>
                          {/* <div className="text-center px-2.5 py-[3px] text-[12px] leading-[16px] text-[#8E3F3A]  rounded-full bg-[#F0DFD6]">
                            {item?.rent?.status_object?.text_en}
                          </div> */}
                        </div>
                      </td>
                      <td className="flex items-center px-4 py-4 pr-[25px] whitespace-nowrap">
                        <div className="h-[28px] w-[28px] rounded-full">
                          <img
                            src={item?.rent?.user?.image || rent}
                            alt="rent_image"
                            className="object-cover"
                          />
                        </div>
                        <div className="text-left text-[14px] text-[#898989] px-2.5">
                          {item?.rent?.user?.first_name}

                        </div>
                      </td>
                      <td className="px-5 py-4 pr-[25px] whitespace-nowrap">
                        <div className="text-left text-[14px] text-[#898989]">
                          <span className="text-[#FF8C00]">{item?.rent?.amount} FCFA </span>
                        </div>
                      </td>

                      <td className="px-5 py-4 pr-[25px] whitespace-nowrap">
                        <div className="flex justify-center">
                          <button
                            onClick={(e) => openPopup(e)}
                            className={` text-[14px] font-semibold  px-[22px] h-[30px] rounded-md ${i % 2 === 0
                              ? "bg-[#FF8C00] text-white"
                              : "bg-[#DCDCDC] text-[#C3C6CC] "
                              } `}
                          >
                            Refund
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <div className="p-5 ">
              {loading ? (
                <LoaderSpinner type="tableLoad" />
              ) : (
                <h2>Record not found</h2>
              )}
            </div>
          )}
        </div>
        {trans?.length > 0 && (
          <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap justify-between items-center pt-6 ">
            {/* <div className='flex items-center w-full'>
                            <h2 className='text-[#333333] text-[14px]'>Show</h2>
                            <select className='border focus:outline-none rounded-md px-5 py-2 mx-3'>
                                <option value="6">6</option>
                                <option value="12">12</option>
                                <option value="25">25</option>
                                <option value="30">30</option>
                            </select>
                            <h2 className='text-[#333333] text-[14px]'>entries of </h2>
                        </div> */}
            <div className="px-8 xxtra-small:px-0 mt-4 mb-1 w-full flex justify-end xxtra-small:justify-start">
              {/* <div className='flex items-center'>
                            {
                                trans?.links?.map((pag, i) => (

                                    <div key={i} className='border text-center px-2 rounded-md bg-[#F3F3F3]'>
                                        {pag?.label === '&laquo; Previous' && <MdOutlineKeyboardArrowLeft /> && pag.label === "Next &raquo;" ? <MdOutlineKeyboardArrowRight /> : pag?.label}
                                    </div>

                                ))
                            }
                        </div> */}

              <ReactPaginate
                breakLabel="..."
                breakClassName=" bg-[#F3F3F3]"
                nextLabel={<MdOutlineKeyboardArrowRight />}
                onPageChange={handlePageClick}
                activeClassName="bg-[#FF8C00] text-white rounded"
                nextClassName="px-3  bg-[#F3F3F3] text-[20px] pt-[5px] rounded xxtra-small:mb-4"
                previousClassName="px-3  bg-[#F3F3F3] text-[20px] pt-[5px] rounded xxtra-small:mb-4"
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel={<MdOutlineKeyboardArrowLeft />}
                className=" flex justify-end  xxtra-small:w-full xxtra-small:justify-start xtra-small:justify-start xxtra-small:flex-wrap xtra-small:flex-wrap"
                pageClassName=" w-[30px] h-[30px]  text-center mx-2 pt-[3px] rounded bg-[#F3F3F3] xxtra-small:mb-4 xtra-small:mb-4"
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TransactionList;
