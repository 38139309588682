import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { CreateCoupon } from '../../../lib/Api/Marketing';
import LoaderSpinner from '../../../util/LoaderSpinner';

// Validation Scema
const schema = yup.object({
    code: yup.string().required("Code is required"),
    discount: yup.string().required("Discount is required"),
    valid_from: yup.string().required("Start Date is required"),
    valid_to: yup.string().required("End Date is required"),
    description: yup.string().required("Description is required"),
    min_days: yup.string().required("Minimum Day is required"),
    max_attempts: yup.string().required("Maximum Attempts is required"),
});
const Promo = ({ setReload }) => {
    // React hooks
    const [loading, setLoading] = useState(false);

    // React hook form initilization
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({ mode: "onChange", resolver: yupResolver(schema) });

    // Submit Form 
    const onSubmit = async (data) => {
        try {
            setLoading(true);
            let { res } = await CreateCoupon(data);
            if (res !== undefined) {
                setLoading(false);
                reset();
                setReload("create")
                setTimeout(() => {
                    setReload("")
                }, 1000);
            } else {
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
        }
    };

    return (
        <>
            <div className='flex md:py-5 py-5 justify-between'>
                <div>
                    <h2 className='text-[#FF8C00] font-semibold text-[20px]'>Promo Code</h2>
                    <p className='pt-4 text-[#898989] text-[14px]'>Here you can Create and edit promo code</p>
                </div>
                <button className='bg-[#FF8C00] text-white w-[160px] min-w-max h-[40px] text-[14px] leading-[16px] font-semibold rounded-md'>Invite a Friend</button>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='pt-5'>
                    <h2 className='text-[#444444] font-semibold text-[20px]'>Create New Promo Code</h2>
                    <div className='grid lg:grid-cols-4 md:grid-cols-4 pt-5 gap-[32px]'>
                        <div className='flex flex-col'>
                            <label className='pb-2 text-[#606060] text-[14px]'>Code</label>
                            <input
                                type="text"
                                {...register("code")}
                                placeholder="Code"
                                className={`border border-[#CDD2E1] h-[42px] placeholder:text-[14px] text-[14px] rounded-md text-[#ACACAC] focus:outline-none px-2.5 ${errors.code && "border-red-500"
                                    }`}
                            />
                            {errors.code && (
                                <p className="text-red-500 text-sm text-start pt-1 ">
                                    {errors.code.message}
                                </p>
                            )}
                        </div>
                        <div className='flex flex-col'>
                            <label className='pb-2 text-[#606060] text-[14px]'>Discount</label>
                            <input
                                type="text"
                                {...register("discount")}
                                placeholder="discount"
                                className={`border border-[#CDD2E1] h-[42px] placeholder:text-[14px] text-[14px] rounded-md text-[#ACACAC] focus:outline-none px-2.5 ${errors.discount && "border-red-500"
                                    }`}
                            />
                            {errors.discount && (
                                <p className="text-red-500 text-sm text-start pt-1 ">
                                    {errors.discount.message}
                                </p>
                            )}
                        </div>
                        <div className='flex flex-col'>
                            <label className='pb-2 text-[#606060] text-[14px]'>Starting Date</label>

                            <input
                                type="date"
                                {...register("valid_from")}
                                pattern="\d{1,2}/\d{1,2}/\d{4}"
                                className={`border border-[#CDD2E1] h-[42px] ticket placeholder:text-[14px] text-[14px] rounded-md text-[#ACACAC] focus:outline-none px-2 ${errors.valid_from && "border-red-500"
                                    }`}
                            />
                            {errors.valid_from && (
                                <p className="text-red-500 text-sm text-start pt-1 ">
                                    {errors.valid_from.message}
                                </p>
                            )}
                        </div>
                        <div className='flex flex-col'>
                            <label className='pb-2 text-[#606060] text-[14px]'>Ending Date</label>

                            <input
                                type="date"
                                {...register("valid_to")}
                                pattern="\d{1,2}/\d{1,2}/\d{4}"
                                className={`border border-[#CDD2E1] h-[42px] ticket placeholder:text-[14px] text-[14px] rounded-md text-[#ACACAC] focus:outline-none px-2 ${errors.valid_to && "border-red-500"
                                    }`}
                            />
                            {errors.valid_to && (
                                <p className="text-red-500 text-sm text-start pt-1 ">
                                    {errors.valid_to.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className='grid grid-cols-2 gap-[32px] pt-10'>
                        <div className='grid grid-cols-2 gap-[32px] '>
                            <div className='flex flex-col pt-0'>
                                <label className='pb-2 text-[#606060] text-[14px]'>Minimum Days</label>

                                <input
                                    type="number"
                                    placeholder='e.g 4'
                                    {...register("min_days")}
                                    className={`border border-[#CDD2E1] h-[42px] ticket placeholder:text-[14px] text-[14px] rounded-md text-[#ACACAC] focus:outline-none px-2 ${errors.min_days && "border-red-500"
                                        }`}
                                />
                                {errors.min_days && (
                                    <p className="text-red-500 text-sm text-start pt-1 ">
                                        {errors.min_days.message}
                                    </p>
                                )}
                            </div>
                            <div className='flex flex-col'>
                                <label className='pb-2 text-[#606060] text-[14px]'>Maximum Attempts</label>
                                <input
                                    type="number"
                                    placeholder='e.g 4'
                                    {...register("max_attempts")}
                                    className={`border border-[#CDD2E1] h-[42px] ticket placeholder:text-[14px] text-[14px] rounded-md text-[#ACACAC] focus:outline-none px-2 ${errors.max_attempts && "border-red-500"
                                        }`}
                                />
                                {errors.max_attempts && (
                                    <p className="text-red-500 text-sm text-start pt-1 ">
                                        {errors.max_attempts.message}
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className='pt-2'>
                            <textarea
                                placeholder='description'
                                {...register("description")}
                                className={`resize-none border border-[#CDD2E1] w-full placeholder:text-[14px] text-[14px] py-2 text-[#ACACAC] focus:outline-none px-2 rounded-md ${errors.description && "border-red-500"
                                    }`}
                            ></textarea>
                            {errors.description && (
                                <p className="text-red-500 text-sm text-start pt-1 ">
                                    {errors.description.message}
                                </p>
                            )}
                            {/* <textarea placeholder='description' className=' resize-none border border-[#CDD2E1] w-full placeholder:text-[14px] text-[14px] py-2 text-[#ACACAC] focus:outline-none px-2 rounded-md  ' rows={5}></textarea> */}
                        </div>
                    </div>

                    <div className='flex justify-end pt-5'>
                        <button className='bg-[#FF8C00] text-white h-[40px] px-[46px] text-[14px] font-semibold rounded-md'>
                            {loading ? <LoaderSpinner /> : "Create"}
                        </button>
                    </div>

                </div>
            </form>
        </>
    )
}

export default Promo