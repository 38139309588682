import React, { useEffect, useState } from "react";
import rentcar from '../../assests/PMNeew/carRent.svg'
import DataTable from "../../components/AdminComponents/manageUser/DataTable/DataTable";
import RentalIndex from "../../components/AdminComponents/manageUser/vihicalRenterSteps/RentalIndex";
import { useLocation } from "react-router-dom";
import SingleLineChart from "../../components/AdminComponents/manageUser/SingleLineChart";
import MultiLineChart from "../../components/AdminComponents/dashboard/MultiLineChart";
import { GetOwnerGraph, GetOwnerStatis, GetRenterGraph, GetRnterStatis } from "../../lib/Api/RentalAppApi";
import LoaderSpinner from "../../util/LoaderSpinner";
const UserManagement = () => {
  const [detail, setDetail] = useState(false);
  const [edit] = useState(false);
  const [Static, setStatic] = useState({});
  const [graphData, setGraphData] = useState([]);
  const location = useLocation().pathname;

  let fetchRenterStatis = async () => {
    let { res } = await GetRnterStatis();
    let Statis = Object.assign({}, ...res);
    setStatic(Statis);
    let { resGraph } = await GetRenterGraph();
    setGraphData(resGraph)

  };

  let fetchOWnerStatis = async () => {
    let { res } = await GetOwnerStatis();
    let Statis = Object.assign({}, ...res);
    setStatic(Statis);
    let { resGraph } = await GetOwnerGraph();
    setGraphData(resGraph)

  };

  useEffect(() => {
    try {
      if (location === "/dinss/tech/vehicalRenters") {
        fetchRenterStatis();
      }
      else {
        fetchOWnerStatis()
      }
    } catch (err) { }
  }, [location]);



  return (
    <>
      {detail === false ? (
        <>
          <h2 className="text-[#444444] font-semibold text-[20px]">
            {location === "/dinss/tech/vehicalRenters"
              ? "Vehicle Renters Statistics"
              : "Vehicle Owner's Statistics"}
          </h2>
          <div className="grid lg:grid-cols-10 xxtra-small:grid-cols-6 xtra-small:grid-cols-6  md:grid-cols-2 gap-5 mt-6">
            {location === "/dinss/tech/vehicalRenters" ? (
              <div className="bg-white  lg:m-0 shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] h-[250px] pb-[60px] rounded-md p-5 col-span-6">
                <SingleLineChart graphData={graphData} />
              </div>
            ) : (
              <div className="bg-white  lg:m-0 shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] h-[250px] pb-[60px] rounded-md p-5 col-span-6">
                <MultiLineChart graphData={graphData} />
              </div>
            )}
            <div className="gap-5 col-span-6 lg:col-span-4 flex  ">
              {
                location === "/dinss/tech/vehicalRenters"
                  ?
                  <div className=" w-[40%] shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] bg-white rounded-md p-4">
                    <div className="border-l-4 px-3 h-[80px] ">
                      <h1 className="text-[#98A2B3] text-[14px]">Today requests</h1>
                      {Static?.today_requests >= 0 ? (
                        <h1 className="  text-[#FF8C00] text-[18px] pt-2 ">
                          {Static?.today_requests}
                        </h1>
                      ) : (
                        <LoaderSpinner type="weight" color="#FF8C00" />
                      )}
                    </div>
                    <hr className="mt-[25px] mb-[20px]" />
                    <div className="border-l-4 px-3  h-[80px]">
                      <h1 className="text-[#98A2B3] text-[14px]">
                        Approved requests
                      </h1>
                      {Static?.approved_requests >= 0 ? (
                        <h1 className="  text-[#FF8C00] text-[18px] pt-2 ">
                          {Static?.approved_requests}
                        </h1>
                      ) : (
                        <LoaderSpinner type="weight" color="#FF8C00" />
                      )}
                    </div>
                  </div>

                  :

                  <div className=" w-[40%] shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] bg-white rounded-md p-4">
                    <div className="border-l-4 px-3 h-[80px] ">
                      <h1 className="text-[#98A2B3] text-[14px]">Recieved requests</h1>
                      {Static?.recevied_request >= 0 ? (
                        <h1 className="  text-[#FF8C00] text-[18px] pt-2 ">
                          {Static?.recevied_request}
                        </h1>
                      ) : (
                        <LoaderSpinner type="weight" color="#FF8C00" />
                      )}
                    </div>
                    <hr className="mt-[25px] mb-[20px]" />
                    <div className="border-l-4 px-3  h-[80px]">
                      <h1 className="text-[#98A2B3] text-[14px]">
                        Approved requests
                      </h1>
                      {Static?.approved_requests >= 0 ? (
                        <h1 className="  text-[#FF8C00] text-[18px] pt-2 ">
                          {Static?.approved_requests}
                        </h1>
                      ) : (
                        <LoaderSpinner type="weight" color="#FF8C00" />
                      )}
                    </div>
                  </div>
              }
              <div className="bg-[#FFEFDC] rounded-md lg:h-[250px] h-full py-2 px-4 w-[60%] ">
                <h1 className=" text-[14px] leading-[24px]">
                  {location === "/dinss/tech/vehicalRenters" ? "Total car renter requests" : "Total car owner's requests"}
                </h1>
                <div className="flex justify-end mt-5">
                  <img src={rentcar} alt="rent-car" className="object-cover " />
                </div>
                {Static?.all_requests >= 0 ? (
                  <h1 className="  text-[#FF8C00] text-[24px] ">
                    {Static?.all_requests}
                  </h1>
                ) : (
                  <LoaderSpinner type="weight" color="#FF8C00" />
                )}
              </div>
            </div>
          </div>

          <div className="shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] bg-white rounded-md p-5 mt-5">
            <DataTable setDetail={setDetail} location={location} />
          </div>
        </>
      ) : (
        <RentalIndex />
      )}
    </>
  );
};

export default UserManagement;
