import Layout from "../../components/layouts/mainLayout/MainLayout";
import Vehicles from "../../components/MainLandingComp/professionnel/Vehicles";
import Proposals from "../../components/MainLandingComp/professionnel/Proposals";
import Habits from "../../components/MainLandingComp/professionnel/Habits";
import Information from "../../components/MainLandingComp/professionnel/Information";
import Banner from "../../components/MainLandingComp/professionnel/Banner";
import { Helmet } from "react-helmet";

const Professional = () => {
	return (
		<Layout>
			<Helmet>
				<title>
					Vos déplacements professionnels aux meilleurs prix !
				</title>
				<meta
					name="description"
					content="Des véhicules de tous types accessibles en un clin d’œil pour les besoins de votre entreprise.  Aucun frais d’inscription, ni de paperasse. Louez en quelques clics, 24h/24 et 7j/7."
				/>
				<link
					rel="canonical"
					href="https://primecarapp.com/deplacement-professionnel"
				/>
			</Helmet>
			<Banner />
			<Vehicles />
			<Proposals />
			<Habits />
			<Information />
		</Layout>
	);
};

export default Professional;
