import React from 'react'
import perposel from '../../../assests/newDesign/nose_img.svg'
const Proposals = () => {
  return (
    <div className='flex flex-row items-center justify-center md:justify-start  px-5 md:px-20'>
        <div className='2xl:w-[1440px] flex flex-col  md:m-auto md:items-center justify-end  md:flex-row-reverse md:justify-end py-10 md:py-11 lg:py-20 xl:py-32  md:px-0    gap-5 md:gap-10 lg:gap-20 xl:gap-32  '>
      <div className="flex flex-col gap-3 lg:gap-4 max-w-full md:max-w-none text-center  md:w-1/2">
        <p className="text-[#444444] font-semibold lg:font-bold text-2xl xl:text-3xl">
          Nos propositions, <span className="text-[#FF8C00]">vos exigences</span>
        </p>
        <p className="text-sm lg:text-base text-[#667085]">
          Nous prêtons attention à vos besoins et nous faisons ce qu'il faut pour vous.
        </p>
      </div>
      <div className='md:w-1/2'>
          <img src={perposel} alt="perposel" className='object-contain w-full' />
      </div>
      </div>
    </div>
  )
}

export default Proposals