import React from "react";

const BlogArticleCardMainPage = ({
  imageSrc,
  tagText,
  time,
  cardText,
  date,
  buttonText,
  slug,
  isButtonShow,
  titleClass = "blog-article-card-text-main-page",
}) => {
  return (
    <a href={"/blog/" + slug}>
      <div className="blog-article-card-main-page">
        <img src={imageSrc} alt="article1" className="blog-article-image" />
        <div className="flex justify-between mt-6">
          <div className="blog-article-card-tag">
            <h1 className="pt-1 pl-4">{tagText}</h1>
          </div>
          <h4 className="mr-1 blog-article-card-read-time">{time}</h4>
        </div>

        <h3 className={["mt-5", titleClass].join(" ")}>{cardText}</h3>
        <h2 className="blog-article-card-date-main-page">{date}</h2>
        {isButtonShow != "false" ? (
          <div className="blog-article-card-button mt-3 pt-1 pl-6">
            {buttonText}
          </div>
        ) : null}
      </div>
    </a>
  );
};

export default BlogArticleCardMainPage;
