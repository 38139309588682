import React from "react";
import { Link, useLocation } from "react-router-dom";
// import question from '../../../assests/svg/Customerservices.svg'
const Question = () => {
	return (
		<>
			<div className="lg:bg-footer_2_img bg-footer_2_img_smal bg-no-repeat bg-cover h-[300px] flex items-center justify-center px-5">
				<div className="flex justify-center flex-col items-center text-center">
					<h2 className="text-white lg:text-[31px] text-[20px] font-bold">
						Des questions?
					</h2>
					<p className="text-[14px] text-white lg:pt-[10px] md:pt-[10px] lg:w-full ">
						Notre service client est à votre disposition 6j/7
					</p>
					<Link
						to={"/help-center"}
						className="text-[text-16px] lg:mt-[32px] md:mt-[32px] mt-[10px] px-8 py-3 rounded-md font-medium bg-[#FF8C00] hover:bg-[#D16A00] text-white"
					>
						Besoin d’aide
					</Link>
				</div>
			</div>
		</>
	);
};

export default Question;
