import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export const options = {
  responsive: true,
  devicePixelRatio: 5,
  tooltips: {
    enabled: false,
  },
  
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        color: "#98A2B3",
        font: {
          size: 8.64,
        },
      },
    },
    y: {
      ticks: {
        color: "#98A2B3",
        font: {
          size: 8.64,
        },
        stepSize: 100,
      },
      min: 0,
      // max: 300,
    },
  },
  plugins: {
    legend: {
      display: false,
      position: "top",
    },
  },
};



const MultiLineChart = ({graphData}) => {


  let labelArr = []
  let reqRecArr = []
  let canceArr = []
  let extArr = []
  let reqAp = []
  
  for (let index = 0; index < graphData?.length; index++) {
    const element = graphData[index];
    labelArr.push(element?.label)
    reqRecArr.push(element?.req_received)
    canceArr.push(element?.req_canceled)
    extArr.push(element?.req_extended)
    reqAp.push(element?.req_approved)
  }


  // const labels = [
  //   "Jan",
  //   "Feb",
  //   "Mar",
  //   "Apr",
  //   "May",
  //   "June",
  //   "July",
  //   "Aug",
  //   "Sep",
  //   "Oct",
  //   "Nov",
  //   "Dec",
  // ];
  
   const data = {
    labels : labelArr,
    datasets: [
      {
        fill: true,
        label: "Requests Received",
        data: reqRecArr,
        borderColor: "#FF8C00",
        backgroundColor: "rgba(247, 246, 246, 0.5)",
        borderWidth: 1,
        pointRadius: 0,
      },
      {
        fill: true,
        label: " Canceled Booking",
        data: canceArr,
        borderColor: "#876842",
        backgroundColor: "rgba(247, 241, 236, 0.5)",
        borderWidth: 1,
        pointRadius: 0,
      },
      {
        fill: true,
        label: "Extended Booking",
        data: extArr,
        borderColor: "#FFC278",
        backgroundColor: "rgba(247, 231, 217, 0.2)",
        borderWidth: 1,
        pointRadius: 0,
      },
      {
        fill: true,
        label: " Requests Booking",
        data:reqAp ,
        borderColor: "#FFC278",
        backgroundColor: "rgba(247, 227, 208 , 1)",
        borderWidth: 1,
        pointRadius: 0,
      },
    ],
  };


  return (
    <>
      <div className="flex py-3 justify-between xl:flex-nowrap md:flex-nowrap flex-wrap">
        <div className="flex  items-center ">
          <div className=" w-[13px] h-[13px] rounded-full bg-[#FF8C00]" />
          <h2 className="pl-1 text-[12px] text-[#444444]">Requests Received</h2>
        </div>
        <div className="flex items-center ">
          <div className=" w-[13px] h-[13px] rounded-full bg-[#876842]" />
          <h2 className="pl-1 text-[12px] text-[#444444]">Canceled Booking</h2>
        </div>
        <div className="flex items-center ">
          <div className=" w-[13px] h-[13px] rounded-full bg-[#FFC278]" />
          <h2 className="pl-1 text-[12px] text-[#444444]">Extended Booking</h2>
        </div>
        <div className="flex items-center ">
          <div className=" w-[13px] h-[13px] rounded-full bg-[#CF7200]" />
          <h2 className="pl-1 text-[12px] text-[#444444]">Requests Approved</h2>
        </div>
      </div>
      <div className="!h-full">
        <Line
          className="!w-full !h-full"
          options={options}
          data={data}
          redraw={true}
        />
      </div>
    </>
  );
};

export default MultiLineChart;
