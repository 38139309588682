import React, { useEffect, useState } from "react";
import review from "../../../../assests/review.png";
import { AiFillStar } from "react-icons/ai";
import { useLocation, useParams } from "react-router-dom";
import {
  GetVehicalOwnerReviews,
  GetVehicalRnterReviews,
} from "../../../../lib/Api/RentalAppApi";
import LoaderSpinner from "../../../../util/LoaderSpinner";
import moment from "moment";
import Rating from "../../../../Partials/StarRating";
// import star from '../../../assests/star.png'

const Reviews = ({ user }) => {
  const [expanded, setexpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reviwes, setReviews] = useState([]);

  let id = useParams().id;
  let name = useParams().name;
  let location = useLocation().pathname;

  // let check = location === `/dashboard/vehical-detail/${id}/carlist`;
  // let sendId = check ? user?.id : id;

  // Get Renter Reviews
  let fetchRenterReviews = async () => {
    try {
      setLoading(true);
      let { resReview } = await GetVehicalRnterReviews(id);
      if (resReview) {
        setLoading(false);
        let obj = Object.assign({}, ...resReview);
        //console.log("obj", resReview )
        setReviews(resReview);
      } else {
        setLoading(false);
      }
    } catch (err) {
      //console.log(err);
    }
  };
  // Get Owner Reviews
  let fetchOwnerReviews = async () => {
    try {
      setLoading(true);
      let { resReview } = await GetVehicalOwnerReviews(user?.id || id);
      if (resReview) {
        setLoading(false);
        //console.log("resReview", resReview)
        // let obj = Object.assign({},...resReview)
        setReviews(resReview);
      } else {
        setLoading(false);
      }
    } catch (err) {
      //console.log(err);
    }
  };

  useEffect(() => {
    if (location === `/dinss/tech/vehicalRenters/detail/${id}/${name}`) {
      fetchRenterReviews();
    } else {
      fetchOwnerReviews();
    }
  }, [id]);

  //console.log("review", reviwes);
  return (
    <>
      {!loading && reviwes?.length > 0 ? (
        reviwes?.map((rev, i) => {
          let check = rev?.rent_reviews ? rev?.rent_reviews :  rev?.reviews
          return(
          <div className="bg-white rounded-md mt-5 shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] h-full border-0 p-5">
            <>
              <div className="flex items-center pb-5 " key={i}>
                <div className="h-[131px]  rounded-full overflow-hidden">
                  <img
                    src={rev?.display_image}
                    alt="review_img"
                    className="object-cover rounded-full w-[134px] h-[134px] "
                  />
                </div>
                <div className="px-5">
                  <h1 className="lg:text-[21px] text-[21px] text-[#444444] font-semibold">
                    {rev?.make} {rev?.model}
                  </h1>
                  <div className="flex items-center pt-0">
                        <Rating value={rev?.reviews_avg_rating}/>
                    <span className="text-[#B8B8B8] text-[14px] px-2 leading-[22px]">
                      ({rev?.reviews_count})
                    </span>
                  </div>
                </div>
              </div>
              <hr className="border-[#E0E0E0]" />
              {check?.map((list, i) => (
                <div className="py-4 px-2" key={i}>
                  <div className="flex justify-between items-center">
                    <div className="">
                      <h1 className="lg:text-[20px] text-[20px] text-[#444444] leading-[30px] font-semibold">
                        {list?.user?.first_name} {list?.user?.last_name}
                      </h1>
                      <div className="flex items-center pt-2.5">
                        <Rating value={list?.rating}/>
                       
                      </div>
                    </div>
                    <div>
                      <h1 className="text-[#444444] font-semibold text-[14px]">
                        {moment(list?.created_at).format("ll")}
                      </h1>
                    </div>
                  </div>
                  <div className="py-3">
                    <p
                      className={`text-[#898989] text-[16px] w-full  ${
                        expanded ? "line-clamp-none" : "line-clamp-2"
                      } `}
                    >
                      {list?.comments}
                    </p>
                  </div>
                  {/* <div className="flex justify-end ">
                    <h2
                      onClick={() => setexpanded(!expanded)}
                      className="text-[#0D7CFF] text-[16px] cursor-pointer hover:underline "
                    >
                      {expanded ? "See Less" : "See more"}
                    </h2>
                  </div> */}
                </div>
              ))}
            </>
          </div>
        )})
      ) : (
        <div>
          {loading ? <LoaderSpinner type="tableLoad" /> : "Record Not Found"}
        </div>
      )}
    </>
  );
};

export default Reviews;
