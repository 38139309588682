import React from 'react'

import ManagementIndex from '../../components/AdminComponents/management/vihicalRenterSteps/ManagemntIndex'
const Managements = () => {
    return (
        <>
            <ManagementIndex />
        </>

    )
}

export default Managements