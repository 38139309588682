import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";

const MainLayout = ({ children , BannerButtonText}) => {
	useEffect(() => {
		document.querySelector("#Top-Body").scrollIntoView();
		setTimeout(() => {
			document.querySelector("#Loading-Spinner").classList.add("hidden");
		}, 500);
	}, []);

	return (
		<div
			className="relative overflow-hidden overflow-x-hidden"
			id="Top-Body"
		>
			<Header BannerButtonText={BannerButtonText}/>
			<div className="overflow-hidden overflow-x-hidden ">{children}</div>
			<div className="">
				<Footer />
			</div>

			{/* Loader */}
			<div
				className="fixed top-0 left-0 w-full bg-white z-50 h-screen flex items-center justify-center"
				id="Loading-Spinner"
			>
				<div className="flex gap-3 items-center">
					<div className="rounded-full p-3 border-4 border-t-[#FF8C00] border-r-[#FF8C00]  animate-spin"></div>
					<p className="text-2xl animate-pulse text-[#FF8C00]">
						Chargement ...
					</p>
				</div>
			</div>
		</div>
	);
};

export default MainLayout;