import React, { useEffect, useState } from "react";
import profile from "../../../assests/Profill.png";
// import { IoLocationSharp } from 'react-icons/io5'
// import car from '../../../assests/svg/CarVehicledetail.svg'
// import car1 from '../../../assests/PMNeew/detail1.svg'
// import car2 from '../../../assests/PMNeew/detail2.svg'
import sp_1 from "../../../assests/newDesign/specification_1.svg";
import sp_2 from "../../../assests/newDesign/specification_2.svg";
import sp_3 from "../../../assests/newDesign/specification_3.svg";
import sp_4 from "../../../assests/newDesign/specification_4.svg";
import sp_5 from "../../../assests/newDesign/specification_5.svg";
import android from "../../../assests/svg/googleplay.svg";
import apple from "../../../assests/svg/apple.svg";
import spanImg from "../../../assests/svg/span.svg";
import location from "../../../assests/newDesign/loaction.svg";
import user_partic from "../../../assests/PMNeew/partic.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Navigation } from "swiper";
import { AiFillStar } from "react-icons/ai";
import { MdClose } from "react-icons/md";
import Rating from "../../../Partials/StarRating";
import { GetVehicalReviews } from "../../../lib/Api/WebsiteApi";
import moment from "moment";
const Right = ({ onClose, carDetail, priceType }) => {
  const [review, setReview] = useState([]);
  const specification = [
    {
      Pic: sp_1,
      heading: "Type Car",
      subheading: carDetail?.car_type?.name,
    },
    { Pic: sp_2, heading: "Portes", subheading: carDetail?.no_of_doors },
    { Pic: sp_3, heading: "Places", subheading: carDetail?.no_of_places },
    { Pic: sp_4, heading: "Boite", subheading: carDetail?.gearbox },
    {
      Pic: sp_5,
      heading: "Moteur",
      subheading: carDetail?.fuel_type?.fuel_type,
    },
  ];

  useEffect(() => {
    let fetch = async () => {
      let { res } = await GetVehicalReviews(carDetail?.id);
      if (res) {
        setReview(res);
      }
    };
    fetch();
  }, []);

  return (
    <>
      <div className="flex flex-col gap-[14px]">
        <div className="flex justify-end items-end pb-3">
          <div
            className="rounded-full bg-[#EEEEEE] p-2 cursor-pointer"
            onClick={() => onClose()}
          >
            <MdClose />
          </div>
        </div>

        <div className="md:border md:rounded-md md:p-3">
          <div className="">
            <Swiper
              navigation={true}
              slidesPerView={1}
              spaceBetween={24}
              breakpoints={{
                768: {
                  slidesPerView: 2,
                },
              }}
              modules={[Navigation]}
              className=""
            >
              {carDetail?.images?.map((item, i) => (
                <SwiperSlide key={i}>
                  <img
                    src={item?.image}
                    alt="car_img"
                    className="object-cover w-full  rounded-md"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          <div className="mt-[14px] flex flex-col lg:flex-row lg:justify-between gap-[14px]">
            <div className="flex justify-between gap-2 lg:flex-col lg:gap-[31px] flex-wrap">
              <div className="flex flex-col lg:gap-[6px]">
                <h1 className="lg:text-[25px] md:text-[20px] font-sans text-[18px] font-bold leading-[34px]">
                  {carDetail?.make} {carDetail?.model}
                </h1>

                <div className="flex items-center gap-[6.24px]">
                  <Rating value={carDetail?.reviews_avg_rating} />
                  {carDetail?.reviews_count > 0 && (
                    <h1 className="text-[#5A5A5A] font-sans lg:text-[12px] md:text-[10.44px] text-[7.8px] leading-[15.6px] md:font-normal font-[400]">
                      ({carDetail?.reviews_count})
                    </h1>
                  )}
                </div>
              </div>

              <p className="md:text-[20px] text-[#FF8C00] font-sans font-semibold text-base lg:leading-[26px]">
                {priceType == "Grand Abidjan" ? (
                  <>{carDetail?.price_in_abidjan.toLocaleString()} FCFA /</>
                ) : (
                  <>{carDetail?.price_out_abidjan.toLocaleString()} FCFA /</>
                )}

                <span className=" font-medium md:text-[15px] text-[13px]  text-[#ACACAC]">
                  {" "}
                  jour
                </span>
              </p>
            </div>

            <div className="flex flex-col gap-[14px] lg:justify-between lg:items-end">
              <div className="flex gap-[15.3px]">
                <a
                  className="object-cover w-[112px] h-[35px]"
                  href="https://apps.apple.com/fr/app/primecar/id6446251526"
                >
                  <img
                    src={apple}
                    alt="social_login"
                    className="object-cover w-[112px] h-[35px]"
                  />
                </a>
                <a
                  className="object-cover w-[140px] h-[35px]"
                  href="https://play.google.com/store/apps/details?id=com.dinsstech.primecar"
                >
                  <img
                    src={android}
                    alt="social_login"
                    className="object-cover w-[140px] h-[35px]"
                  />
                </a>
              </div>

              <div className="bg-[#FCE5E5] rounded-md">
                <p className="text-[#F04438] md:text-[14px] text-[10px] md:font-normal font-[400] font-sans leading-[13.62px] p-[5.5px] lg:p-2">
                  Note: Ce véhicule est à réserver uniquement à partir de
                  l’application mobile.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="border rounded-md px-[16px] py-[8px] lg:px-[20.5px] lg:py-[20.5px] gap-[14px] lg:gap-[20.54px] flex flex-col">
          <h2 className="text-[#98A2B3] md:text-[15px] text-[12px] font-semibold lg:leading-[19.6px]">
            Propriétaire
          </h2>

          <div className="flex items-center gap-[14px] lg:gap-[16.4px]">
            <img
              src={carDetail?.user?.image || user_partic}
              alt="user"
              className="w-[40px] h-[40px] lg:w-[52px] lg:h-[52px] object-cover rounded-full"
            />

            <div className="flex flex-col gap-[4px]">
              <h1 className="text-[16px] font-semibold font-sans text-[#5A5A5A] lg:leading-[24px]">
                {carDetail?.user?.first_name}
              </h1>

              <div className="flex items-center gap-[9.7px]">
                <AiFillStar className="text-[#FF8C00] md:text-[20px] text-[16px] " />
                <p className="text-[#414141] font-sans text-[14px] md:font-normal font-[400] leading-[16.31px]">
                  {carDetail?.user?.reviews_as_owner_avg_rating ?? "0"}{" "}
                  <span className="text-[#AFB0B2]">
                    ({carDetail?.user?.reviews_as_owner_count})
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="border rounded-md px-[16px] py-[8px]  lg:px-[20.5px] lg:py-[20.5px] gap-[14px] lg:gap-[20.54px] flex flex-col">
          <h2 className="text-[#98A2B3] md:text-[15px] text-[12px] font-semibold lg:leading-[19.6px] ">
            Adresse(s) de location
          </h2>

          <div className="flex flex-col gap-[17.5px] lg:flex-row flex-wrap ">
            {carDetail?.locations?.map((loc) => (
              <div className="flex items-center gap-[14px]">
                <img
                  src={location}
                  alt="location"
                  className="object-cover w-[40px] h-[40px] lg:w-[50px] lg:h-[50px]"
                />

                <div className="flex flex-col">
                  <h2 className="text-[#444444] font-semibold md:text-[16px] text-[14px] leading-[21px] lg:leading-[24px] font-sans">
                    Adresse principale
                  </h2>
                  <p className="text-[#667085] pt-[4px] font-medium md:text-[12.33px] text-[12px] leading-[18px] font-sans ">
                    {loc?.address}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="border rounded-md px-[16px] py-[8px]  lg:px-[20.5px] lg:py-[20.5px] gap-[14px] lg:gap-[20.54px] flex flex-col">
          <h2 className="text-[#98A2B3] md:text-[15px] text-[12px] font-semibold  lg:leading-[19.6px] ">
            Deacription
          </h2>
          <p className="text-[#5A5A5A] font-normal font-sans md:text-[14px] text-[12px] lg:leading-[28px]">
            {carDetail?.description}
          </p>
        </div>

        <div className=" border rounded-md px-[16px] py-[8px]  lg:px-[20.5px] lg:py-[20.5px] gap-[14px] lg:gap-[20.54px] flex flex-col">
          <h1 className="text-[#98A2B3] md:text-[15px] text-[12px] font-semibold  lg:leading-[19.6px] ">
            Caractéristiques
          </h1>

          <div className="grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 justify-between gap-1 lg:gap-2 Caractéristiques_style">
            {specification.map((item, i) => (
              <div className="flex items-center gap-[14px] pb-4" key={i}>
                <div
                  className="rounded-md md:w-[54px] w-[44px] md:h-[54px] h-[44px] p-2 flex justify-center items-center"
                  style={{
                    background: "rgba(255, 231, 202, 0.47)",
                  }}
                >
                  <img
                    src={item?.Pic}
                    alt="specification"
                    className="object-cover w-[24.4px] h-[19.3px] lg:w-[30.8px] lg:h-[30.8px]"
                  />
                </div>
                <div className="flex flex-col">
                  <h2 className="text-[#98A2B3] md:font-normal font-[400] font-sans md:text-[15px] text-[14px] leading-[21px] lg:text-[16px] lg:leading-[24px]">
                    {item?.heading}
                  </h2>
                  <p className="text-[#444444] font-sans font-semibold  md:text-[16px] text-[14px]">
                    {item?.subheading}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className=" border rounded-md px-[16px] py-[8px]  lg:px-[20.5px] lg:py-[20.5px] gap-[14px] lg:gap-[20.54px] flex flex-col">
          <h1 className="text-[#98A2B3] md:text-[15px] text-[12px] font-semibold  lg:leading-[19.6px] ">
            Equipements
          </h1>

          <div className=" gap-2 lg:gap-5 xl:gap-5 Equipements_style">
            {carDetail?.equipments?.map((item, i) => (
              <div
                key={i + 7}
                className="rounded-md  p-[7.7px] xl:p-[9px] flex items-center gap-[7px] lg:gap-[10px] border border-[#DEDEDE] lg:border-none lg:bg-[#FFE7CA] lg:bg-opacity-[30%]  Equipements_style_inner "
              >
                <img
                  src={spanImg}
                  alt="specification"
                  className="object-cover w-[15.5px] h-[15.5px] lg:w-[27px] lg:h-[27px]"
                />

                <h2 className="text-[#444444] font-semibold lg:text-[16px] md:text-[14px] text-[10px]">
                  {item?.equipment?.equipment}
                </h2>
              </div>
            ))}
          </div>
        </div>

        <div className=" border rounded-md px-[16px] py-[8px]  lg:px-[20.5px] lg:py-[20.5px] gap-[14px] lg:gap-[20.54px] flex flex-col">
          <h1 className="lg:text-[#444444] text-[#98A2B3] lg:text-[22.6px] md:text-[15px] text-[12px] font-semibold lg:leading-[29px] lg:mb-[10px]">
            Avis des locataires
          </h1>

          <div className="flex flex-col gap-[24px]">
            {review.map((item, i) => (
              <div
                key={i}
                className="last:border-b-0 border-b border-b-[#E7E7E7] flex flex-col gap-[12px] pb-[12px]"
              >
                <div className="flex gap-[16px]">
                  <img
                    src={item?.user?.image || profile}
                    alt="profile"
                    className="object-cover rounded-full w-[40px] h-[40px] lg:w-[58px] lg:h-[58px]"
                  />

                  <div className="flex flex-col">
                    <h2 className="text-[#444444] md:text-[19.52px] text-[14px] font-sans font-bold leading-[21px]">
                      {item?.user?.first_name} {item?.user?.last_name}
                    </h2>
                    <p className="md:text-[14.38px] font-semibold text-[12px] leading-[18px] font-sans text-[#667085]">
                      Locataire
                    </p>
                  </div>
                </div>

                <div className="flex items-center">
                  <Rating value={item?.rating} />
                  <h2 className="text-[12px] ml-[16px] font-normal font-sans text-[#667085] leading-[16px]">
                    {moment(item?.created_at).format("ll")}
                  </h2>
                </div>

                <p className="text-[#667085] md:text-[14.38px] text-[12px] font-sans leading-[24px]">
                  {item?.comments}{" "}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Right;