import React from "react";
// import votrieImg from '../../../assests/PMNeew/votre.svg'
import votrieImg from "../../../assests/Frame-65.webp";
const VotreMobilite = () => {
	return (
		<>
			<div className="md:px-20 px-5">
				<div className="flex flex-col md:flex-row lg:gap-10 md:gap-[40px] items-center   py-5  md:py-9  lg:py-[60px] 2xl:w-[1440px] justify-between m-auto votre-mobilite-section-main">
					<div className="lg:text-[31px] md:leading-[50px] font-sans text-[22px] text-[#444444] font-bold">
						<h2 className="main-page-votre-mobile-section-heading">
							<span className="text-[#FF8C00]">
								Votre mobilité{" "}
							</span>
							n’aurait pas été plus simple
						</h2>
						<p className="main-page-votre-mobile-section-sub-heading">
							Avec Primecar, vous gagnez du temps avec un accès à
							plusieurs opportunités de location aux meilleurs
							prix et en toute sécurité !
						</p>
					</div>
					<div className="lg:text-[31px] md:leading-[50px] font-sans text-[22px] text-[#444444] font-bold">
						<img
							src={votrieImg}
							alt="rentable"
							className="object-cover"
						/>
					</div>
				</div>
				<div className="blog-article-tags-section">
					<div className="flex">
						<div className="section-tags">
							<p className="section-tags-span">
								Location de voiture à Abidjan
							</p>
						</div>
						<div className="section-tags">
							<p className="section-tags-span ">
								Location de voiture à Cocody
							</p>
						</div>
						<div className="section-tags">
							<p className="section-tags-span ">
								Location de voiture à Marcory
							</p>
						</div>
						<div className="section-tags">
							<p className="section-tags-span">
								Location de voiture à Yopougon
							</p>
						</div>
						<div className="section-tags">
							<p className="section-tags-span ">
								Location de voiture au meilleur prix
							</p>
						</div>
						<div className="section-tags">
							<p className="section-tags-span ">
								Location camion
							</p>
						</div>
						<div className="section-tags">
							<p className="section-tags-span">
								Location de voiture à l’aéroport Félix Houphouet
								Boigny
							</p>
						</div>
						<div className="section-tags">
							<p className="section-tags-span ">
								Location de van et fourgon Abidjan
							</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default VotreMobilite;
