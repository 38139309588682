import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { updateIsActive } from "./path/to/UserAuthSlice";
import { setHelpCenter, updateIsActive } from "../../../Redux/UserAuthSlice/UserAuthSlice";
import { useNavigate } from "react-router-dom";
import { newData, newData2 } from "./data";

import { useLocation } from "react-router-dom";
const Main = () => {
	const isActive = useSelector((state) => state?.UserAuthSlice?.isActive);
	const  help_center  = useSelector((state) => state?.UserAuthSlice?.userAuth);


	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();

	const handleClick = (item, item2) => {
		let payload = {
			itemData: item,
			item2Data: item2,
		}
		if (item2 == "Récupérez-le et partez") {
			dispatch(setHelpCenter(payload))
			navigate(`/help-center/locataire/recuperez-le/${item.slug}`);

		} else if (item2 == "Réservez votre véhicule") {
			dispatch(setHelpCenter(payload))
			navigate(`/help-center/locataire/reservez/${item.slug}`);

		} else if (item2 == "Après la location") {
			dispatch(setHelpCenter(payload))
			navigate(`/help-center/locataire/Apres-la-location/${item.slug}`);

		} else if (item2 == "Déposez votre véhicule") {
			dispatch(setHelpCenter(payload))
			navigate(`/help-center/proprietaire/Depot/${item.slug}`);
		} else if (item2 == "Faites plus du revenu") {
			dispatch(setHelpCenter(payload))
			navigate(`/help-center/proprietaire/Faites-plus-du-revenu/${item.slug}`);

		} else if (item2 == "Acceptez les demandes de location") {
			dispatch(setHelpCenter(payload))
			navigate(`/help-center/proprietaire/Acceptez-les-demandes-de-location/${item.slug}`);
		} else {
			dispatch(setHelpCenter(payload))
			navigate(
				`/help-center/locataire/trouver-un-vehicule/${item.slug}`);
		}

		
	};
	const receivedData = location.state?.data || "";
	const toggleActive = (newState) => {
		dispatch(updateIsActive(newState));
	};

	const [active, setActive] = useState(true);
	const btnActClass =
		"text-[#5A5A5A] font-semibold border-[#FF8C00] lg:font-bold lg:text-xl";
	const btnNonActClass = "border-[#E0E0E0] lg:text-xl lg:font-medium";
	const btnSameClass = "w-1/2 py-1 lg:py-2 border-b max-w-xs";
	const animateSectionClass =
		"grid grid-cols-1 md:grid-cols-2 lg:gap-7 w-full";
	const animateClass = "transition-all ease-in-out delay-200";

	const toggleNonActFn = (index, status) => {
		let a = document.querySelector(`#non-active-${index}`);
		let nonActShowBtn = document.querySelector(
			`#non-active-show-btn-${index}`
		);
		let nonActHideBtn = document.querySelector(
			`#non-active-hide-btn-${index}`
		);
		if (status) {
			nonActShowBtn.classList.add("hidden");
			a.classList.remove("h-0");
			a.classList.add("h-full");
			nonActHideBtn.classList.remove("hidden");
		} else {
			nonActHideBtn.classList.add("hidden");
			a.classList.remove("h-full");
			a.classList.add("h-0");
			nonActShowBtn.classList.remove("hidden");
		}
	};

	// const toggleActFn = (index, status) => {
	// 	let a = document.querySelector(`#active-${index}`);
	// 	let actShowBtn = document.querySelector(`#active-show-btn-${index}`);
	// 	let actHideBtn = document.querySelector(`#active-hide-btn-${index}`);
	// 	if (status) {
	// 		actShowBtn.classList.add("hidden");
	// 		a.classList.remove("h-0");
	// 		a.classList.add("h-full");
	// 		actHideBtn.classList.remove("hidden");
	// 	} else {
	// 		actHideBtn.classList.add("hidden");
	// 		a.classList.remove("h-full");
	// 		a.classList.add("h-0");
	// 		actShowBtn.classList.remove("hidden");
	// 	}
	// };

	useEffect(() => {
		if (!isActive) {
			setActive(true);
			toggleActive(true);
		}
		if (help_center === null) {
			setActive(false);
			toggleActive(false);
		} else {
		}
	}, []);
	return (
		<div className="px-5 md:px-20">
			<div className="flex flex-col  py-10  md:py-12  lg:py-14 xl:py-16 gap-5 md:gap-7 lg:gap-9 xl:gap-12 2xl:w-[1440px] m-auto">
				<div className="flex w-full text-sm text-[#667085] font-normal justify-center ">
					<button
						className={`${active ? btnActClass : btnNonActClass
							} ${btnSameClass} ${animateClass}`}
						onClick={() => {
							setActive(true);
							toggleActive(true);
						}}
					>
						Je suis locataire
					</button>

					<button
						className={`${!active ? btnActClass : btnNonActClass
							} ${btnSameClass} ${animateClass}`}
						onClick={() => {
							setActive(false);
							toggleActive(false);
						}}
					>
						Je suis propriétaire
					</button>
				</div>

				<div className="flex flex-col w-full justify-center items-center">
					{/* Active: True */}
					<div
						className={`${animateSectionClass} ${animateClass} ${active ? "opacity-1 h-full z-50" : "opacity-0 h-0"
							}`}
					>
						{newData.map((each, index) => {
							return (
								<div
									key={each.title}
									className="flex flex-col gap-2 text-[#667085] text-xs lg:text-sm font-normal lg:font-medium"
								>
									<p className="text-[#FF8C00] text-base lg:text-lg font-semibold mb-2">
										{each.title}
									</p>
									<div className="flex flex-col gap-2">
										{each.questionsAnswers.map(
											(eachQ, indexQ) => {
												return (
													<div
														className="hover:text-[#FF8C00] text-sm cursor-pointer"
														onClick={() =>
															handleClick(
																eachQ,
																each.title
															)
														}
													>
														{" "}
														{eachQ.question}
													</div>
												);
											}
										)}
									</div>
								</div>
							);
						})}
					</div>

					<div
						className={`${animateSectionClass} ${animateClass} ${!active ? "opacity-1 h-full z-50" : "opacity-0 h-0"
							}`}
					>
						{newData2.map((each, index) => {
							return (
								<div
									key={each.title}
									className="flex flex-col gap-2 text-[#667085] text-xs lg:text-sm font-normal lg:font-medium"
								>
									<p className="text-[#FF8C00] text-base lg:text-lg font-semibold  mb-2">
										{each.title}
									</p>

									<div className="flex flex-col gap-2">
										{each.questionsAnswers.map(
											(eachB, indexB) => {
												return (
													<div
														className="hover:text-[#FF8C00] text-sm cursor-pointer"
														onClick={() =>
															handleClick(
																eachB,
																each.title
															)
														}
													>
														{" "}
														{eachB.question}
													</div>
												);
											}
										)}
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Main;
