import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
// import CommonDropDown from "../dropDown";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { EditInviteNember, GetRoles } from "../../../lib/Api/AdminApi";
import LoaderSpinner from "../../../util/LoaderSpinner";
// import LoaderSpinner from "../../../util/LoaderSpinner";
// import CommonDropDown from "../dropDown";

const schema = yup.object({
  name: yup.string().required(" Name is required"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
  role: yup.string().required("Role is required"),
});
const EditData = ({ handleClose, list, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [loadingRole, setLoadingRole] = useState(false);
  const [role, setRole] = useState([]);


  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: "onChange", resolver: yupResolver(schema) });

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      let payload = {
        admin_id: list?.id,
        name: data?.name,
        email: data?.email,
        role_id: data?.role,
      };
      let { res } = await EditInviteNember(payload);
      if (res !== undefined) {
        setLoading(false);
        handleClose();
        reset();
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    let fetchRols = async () => {
      setLoadingRole(true)
      let { res } = await GetRoles();
      let rols = Object.assign({}, ...res);
      if(rols){
      setRole(rols.roles);
      setLoadingRole(false)
      }
    };
    fetchRols();
  }, []);
  useEffect(() => {
    let payload = {
      name: list?.first_name,
      email: list?.email,
      role: list?.admin_role?.id,
    };
    if (role?.length > 0) {
      reset(payload);
    }
  }, [reset , role]);

  return (
    <>
      <div className="">
        <div className="flex justify-between items-center">
          <h2 className="text-[#444444] font-semibold text-xl">Edit Member</h2>
          <MdClose onClick={() => onClose()} className="cursor-pointer" />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col pt-5">
            <label className="pb-2 text-[#606060] text-sm">Name</label>
            <input
              type="text"
              {...register("name")}
              placeholder="Name"
              className={`border border-[#CDD2E1] h-[42px] placeholder:text-[14px] text-[14px] rounded-md  focus:outline-none px-2.5 ${errors.name && "border-red-500"
                }`}
            />
            {errors.name && (
              <p className="text-red-500 text-sm text-start pt-1 ">
                {errors.name.message}
              </p>
            )}
          </div>
          <div className="flex flex-col py-4">
            <label className="pb-2 text-[#606060] text-sm">Email</label>
            <input
              type="text"
              {...register("email")}
              placeholder="jhondie@gmail.com"
              className={`border border-[#CDD2E1] h-[42px] placeholder:text-[14px] text-[14px] rounded-md  focus:outline-none px-2.5 ${errors.email && "border-red-500"
                }`}
            />
            {errors.email && (
              <p className="text-red-500 text-sm text-start pt-1 ">
                {errors.email.message}
              </p>
            )}
          </div>
          <div className="flex flex-col">
            <label className="pb-2 text-[#606060] text-sm">Role</label>
            <select
              {...register("role")}
              className="border rounded-md h-[40px] outline-none px-2 text-[#ACACAC]"
            >
              <option value="">{ loadingRole ? "loading..." : "Select Role"}</option>

              {role.map((r, i) => (
                <option key={i} value={r?.id}>
                  {r?.role}
                </option>
              ))}
            </select>
            {/* <CommonDropDown {...register("role")} list={role} /> */}
            {errors.role && (
              <p className="text-red-500 text-sm text-start pt-1 ">
                {errors.role.message}
              </p>
            )}
          </div>
          <div className="pt-5 text-right">
            <button
              onClick={() => onClose()}
              className="w-[138px] h-[40px] text-sm leading-[16px] font-semibold py-3 rounded-md bg-transparent border-[#ACACAC] border text-[#ACACAC]"
            >
              Cancel
            </button>
            <button className="w-[138px] h-[40px] py-3 ml-3 rounded-md text-sm font-semibold bg-[#FF8C00] text-white leading-[16px]">
              {loading ? <LoaderSpinner /> : "Update"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditData;
