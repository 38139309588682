import React from "react";
// import c1 from "../../../assests/newDesign/c_1.svg";
// import c2 from "../../../assests/newDesign/c_2.svg";
// import c3 from "../../../assests/newDesign/c_3.svg";
// import c4 from "../../../assests/newDesign/c_4.svg";
// import c5 from "../../../assests/newDesign/c_5.svg";
import c1 from "../../../assests/vehicle1.webp";
import c2 from "../../../assests/vehicle2.webp";
import c3 from "../../../assests/vehicle3.webp";
import c4 from "../../../assests/vehicle4.webp";
import c5 from "../../../assests/vehicle5.webp";
const Occasions = () => {
	const cars = [c1, c2, c3, c4, c5];
	return (
		<>
			<div className="lg:px-[105px] lg:py-[60px] py-[40px] md:px-20 text-center flex flex-col xl:justify-center">
				<h2 className="lg:text-[31px] text-[#444444] font-sans text-[22px] font-bold ">
					Des véhicules pour toutes{" "}
					<span className="text-[#FF8C00]"> vos occasions </span>
				</h2>

				<p className="lg:text-[14px] md:text-[14px] font-sans xl:max-w-[830px] m-auto font-normal leading-[24px] pt-3 text-[13px] text-[#5A5A5A]">
					Primecar, c’est une grande variété de véhicules à choisir.
					Trouvez la voiture ou l’utilitaire parfait. Que vous
					planifiez un évènement, un voyage, un déplacement
					professionnel, des vacances ou de grands travaux, vous êtes
					au bon endroit. Louez l’esprit léger.
				</p>

				<div className="flex flex-wrap lg:flex-nowrap items-center justify-center md:mt-[60px] mt-[40px] md:gap-[20px] gap-[16px]">
					{cars.map((car, i) => (
						<img
							src={car}
							alt="fram_img"
							key={i}
							className="lg:object-contain object-cover cursor-pointer max-w-[98px] lg:max-w-[140px] xl:max-w-[220px] transition-all ease-in-out duration-150 hover:scale-90"
						/>
					))}
				</div>
			</div>
		</>
	);
};

export default Occasions;
