import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

function BlogSidebar({ sidebarOpen, setSidebarOpen }) {
	useEffect(() => {
		const keyHandler = ({ keyCode }) => {
			if (!sidebarOpen || keyCode !== 27) return;
			setSidebarOpen(false);
		};
		document.addEventListener("keydown", keyHandler);
		return () => document.removeEventListener("keydown", keyHandler);
	});

	useEffect(() => {
		if (sidebarOpen === true) {
			if (typeof window != "undefined" && window.document) {
				document.body.style.overflow = "hidden";
			}
		} else {
			document.body.style.overflow = "auto";
		}
	}, [sidebarOpen]);

	const location = useLocation().pathname;

	return (
		<div>
			{/* Sidebar backdrop (mobile only) */}
			<div
				className={`fixed inset-0 bg-slate-900 bg-opacity-30 md:hidden transition-opacity duration-200 ${
					sidebarOpen
						? "opacity-100 z-50"
						: "opacity-0 pointer-events-none"
				}`}
				aria-hidden="true"
				onClick={() => setSidebarOpen(false)}
			></div>

			{/* Sidebar */}

			<div
				className={` flex flex-col md:hidden w-[96%] ml-[2%] rounded-tr-[8px] rounded-tl-[8px] fixed l z-[999] bottom-0 left-0  ${
					sidebarOpen
						? "opacity-100"
						: "opacity-0 pointer-events-none"
				} transform ease-in-out border bg-white`}
			>
				{/* <div className='flex justify-start m-4 items-start'>
                    <div className='' onClick={() => setSidebarOpen(false)}>
                        <MdClose className='text-[30px] ' />
                    </div>
                </div> */}

				<div className="">
					<div className="h-full">
						<ul className="flex flex-col justify-center p-0 my-[40px] gap-[10px]">
							<li
								className={`flex justify-between text-[16px] py-[12px] px-[32px] font-medium leading-[24px] text-center w-full`}
							>
								<Link to="/deplacement-professionnel">
									Actualités
								</Link>
								<svg
									width="20"
									height="18"
									viewBox="0 0 20 18"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M15.6364 7.25641C15.6364 7.42982 15.5789 7.59613 15.4766 7.71875C15.3743 7.84137 15.2356 7.91026 15.0909 7.91026H7.81818C7.67352 7.91026 7.53478 7.84137 7.43249 7.71875C7.33019 7.59613 7.27273 7.42982 7.27273 7.25641C7.27273 7.083 7.33019 6.91669 7.43249 6.79407C7.53478 6.67145 7.67352 6.60256 7.81818 6.60256H15.0909C15.2356 6.60256 15.3743 6.67145 15.4766 6.79407C15.5789 6.91669 15.6364 7.083 15.6364 7.25641ZM15.0909 10.0897H7.81818C7.67352 10.0897 7.53478 10.1586 7.43249 10.2812C7.33019 10.4039 7.27273 10.5702 7.27273 10.7436C7.27273 10.917 7.33019 11.0833 7.43249 11.2059C7.53478 11.3285 7.67352 11.3974 7.81818 11.3974H15.0909C15.2356 11.3974 15.3743 11.3285 15.4766 11.2059C15.5789 11.0833 15.6364 10.917 15.6364 10.7436C15.6364 10.5702 15.5789 10.4039 15.4766 10.2812C15.3743 10.1586 15.2356 10.0897 15.0909 10.0897ZM20 2.02564V15.1026C20 15.7384 19.7893 16.3482 19.4142 16.7978C19.0391 17.2474 18.5304 17.5 18 17.5H2C1.47098 17.5 0.96351 17.2488 0.588671 16.8013C0.213833 16.3538 0.00216417 15.7465 0 15.1124V4.64103C0 4.46761 0.0574674 4.30131 0.15976 4.17869C0.262053 4.05607 0.400791 3.98718 0.545455 3.98718C0.690118 3.98718 0.828857 4.05607 0.931149 4.17869C1.03344 4.30131 1.09091 4.46761 1.09091 4.64103V15.1026C1.09091 15.3916 1.18669 15.6688 1.35718 15.8731C1.52766 16.0775 1.75889 16.1923 2 16.1923C2.24111 16.1923 2.47234 16.0775 2.64282 15.8731C2.81331 15.6688 2.90909 15.3916 2.90909 15.1026V2.02564C2.90909 1.62102 3.04318 1.23296 3.28186 0.94685C3.52055 0.660737 3.84427 0.5 4.18182 0.5H18.7273C19.0648 0.5 19.3885 0.660737 19.6272 0.94685C19.8659 1.23296 20 1.62102 20 2.02564ZM18.9091 2.02564C18.9091 1.96784 18.8899 1.9124 18.8558 1.87153C18.8217 1.83065 18.7755 1.80769 18.7273 1.80769H4.18182C4.1336 1.80769 4.08735 1.83065 4.05325 1.87153C4.01916 1.9124 4 1.96784 4 2.02564V15.1026C4.00039 15.4815 3.92528 15.8552 3.78091 16.1923H18C18.2411 16.1923 18.4723 16.0775 18.6428 15.8731C18.8133 15.6688 18.9091 15.3916 18.9091 15.1026V2.02564Z"
										fill="black"
									/>
								</svg>
							</li>
							<li
								className={`flex justify-between text-[16px] py-[12px] px-[32px] font-medium leading-[24px] text-center w-full`}
							>
								<Link to="/help-center">Les bons plans</Link>
								<svg
									width="19"
									height="19"
									viewBox="0 0 19 19"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M18.025 9.82508L8.85833 0.666748H0.666664V8.85841L9.825 18.0167L18.025 9.82508ZM3.58333 4.83341C2.89166 4.83341 2.33333 4.27508 2.33333 3.58341C2.33333 2.89175 2.89166 2.33341 3.58333 2.33341C4.275 2.33341 4.83333 2.89175 4.83333 3.58341C4.83333 4.27508 4.275 4.83341 3.58333 4.83341Z"
										fill="black"
									/>
								</svg>
							</li>
							<li
								className={`flex justify-between text-[16px] py-[12px] px-[32px] font-medium leading-[24px] text-center w-full`}
							>
								<Link to="/louer-mon-vehicule">Nouveautés</Link>
								<svg
									width="20"
									height="18"
									viewBox="0 0 20 18"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M7.16667 17.75L5.58334 15.0833L2.58334 14.4167L2.875 11.3333L0.833336 9L2.875 6.66667L2.58334 3.58333L5.58334 2.91667L7.16667 0.25L10 1.45833L12.8333 0.25L14.4167 2.91667L17.4167 3.58333L17.125 6.66667L19.1667 9L17.125 11.3333L17.4167 14.4167L14.4167 15.0833L12.8333 17.75L10 16.5417L7.16667 17.75ZM9.125 11.9583L13.8333 7.25L12.6667 6.04167L9.125 9.58333L7.33334 7.83333L6.16667 9L9.125 11.9583Z"
										fill="black"
									/>
								</svg>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}

export default BlogSidebar;
