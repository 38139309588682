import React from 'react'
import DataTable from '../../components/AdminComponents/transfer/DataTable'

const PendingTransfer = () => {
    return (
        <>
            <DataTable />
        </>
    )
}

export default PendingTransfer