import { toast } from "react-toastify";
import { callPrivateApi } from "../Apiendpoint";

// Get Dashboard Statis
export const GetDashStatis = async () => {
  try {
    let response = await callPrivateApi("admin/dashboard-stats", "get");
    if (response.success === true) {
      return {
        res: response.data,
      };
    } else {
      toast.error(response.message);
    }
  } catch (err) {
    toast.error(err);
  }
};

export const GetDashPendding = async () => {
  try {
    let response = await callPrivateApi(
      "admin/vehicle/pending?page=1&per_page=10",
      "get"
    );
    if (response.success === true) {
      return {
        res: response.data,
      };
    } else {
      toast.error(response.message);
    }
  } catch (err) {
    toast.error(err);
  }
};

// Get Vehicals
export const GetVihicals = async () => {
  try {
    let response = await callPrivateApi(
      "admin/rent/list-rents?page=1&per_page=10",
      "post"
    );
    if (response.success === true) {
      return {
        res: response.data,
      };
    } else {
      toast.error(response.message);
    }
  } catch (err) {
    toast.error(err);
  }
};

// Get Vehicals
export const GetVihicalsByFilter = async (status, offsetPage, search) => {
  try {
    let apiRun = search
      ? `admin/vehicle/${status}?page=${offsetPage}&per_page=10&search=${search}`
      : `admin/vehicle/${status}?page=${offsetPage}&per_page=10`;
    let response = await callPrivateApi(apiRun, "get");
    if (response.success === true) {
      return {
        res: response.data,
      };
    } else {
      toast.error(response.message);
    }
  } catch (err) {
    toast.error(err);
  }
};

// Get Rent Vehicals
export const GetRentsByFilter = async (status, offsetPage, search) => {
  try {
    let apiRun = search
      ? `admin/rent/list-rents/${status}?page=${offsetPage}&per_page=10&search=${search}`
      : `admin/rent/list-rents/${status}?page=${offsetPage}&per_page=10`;
    let response = await callPrivateApi(apiRun, "post");
    if (response.success === true) {
      return {
        res: response.data,
      };
    } else {
      toast.error(response.message);
    }
  } catch (err) {
    toast.error(err);
  }
};

// Get Pendding Vehicals
export const GetVihicalsPendding = async (status , page_no) => {
  try {
    let check =
      status === "all"
        ? `admin/vehicle?page=${page_no}&per_page=10`
        : `admin/vehicle/${status}?page=${page_no}&per_page=10`;
    let response = await callPrivateApi(check, "get");
    if (response.success === true) {
      return {
        res: response.data,
      };
    } else {
      toast.error(response.message);
    }
  } catch (err) {
    toast.error(err);
  }
};

// Get Vehical base on page
export const GetVihicalsBaseOnPages = async (pageNo, statusCheck, search) => {
  try {
    let check =
      statusCheck === "" || statusCheck === "all"
        ? `admin/vehicle?page=${pageNo}`
        : search
        ? `admin/vehicle/${statusCheck}?page=${pageNo}&per_page=10?search=${search}`
        : `admin/vehicle/${statusCheck}?page=${pageNo}`;
    let response = await callPrivateApi(check, "get");
    if (response.success === true) {
      return {
        res: response.data,
      };
    } else {
      toast.error(response.message);
    }
  } catch (err) {
    toast.error(err);
  }
};

// Get Vehical rent base on page
export const GetRentVehicalBaseOnPages = async (pageNo, statusCheck) => {
  try {
    let check =
      statusCheck === "" || statusCheck === "all"
        ? `admin/rent/list-rents?page=${pageNo}`
        : `admin/rent/list-rents/${statusCheck}?page=${pageNo}`;
    let response = await callPrivateApi(check, "post");
    if (response.success === true) {
      return {
        res: response.data,
      };
    } else {
      toast.error(response.message);
    }
  } catch (err) {
    toast.error(err);
  }
};

// Get Vehical base on page
export const SearchVehical = async (search, statusCheck, offsetPage) => {
  try {
    let check =
      statusCheck === "all"
        ? `admin/vehicle?page=${offsetPage}&per_page=10&search=${search}`
        : `admin/vehicle/${statusCheck}?page=${offsetPage}&per_page=10&search=${search}`;
    let response = await callPrivateApi(check, "get");
    if (response.success === true) {
      let obj = Object.assign({}, ...response.data);
      return {
        res: obj?.vehicles,
      };
    } else {
      toast.error(response.message);
    }
  } catch (err) {
    toast.error(err);
  }
};

// Search rent Vehical base on page
export const SearchRentVehical = async (search, statusCheck , page_no) => {
  try {
    let check =
      statusCheck === "all" || statusCheck === ""
        ? `admin/rent/list-rents?page=${page_no}&per_page=10&search=${search}`
        : `admin/rent/list-rents/${statusCheck}?page=${page_no}&per_page=10&search=${search}`;
    let response = await callPrivateApi(check, "post");
    if (response.success === true) {
      let obj = Object.assign({}, ...response.data);
      return {
        res: obj?.rents,
      };
    } else {
      toast.error(response.message);
    }
  } catch (err) {
    toast.error(err);
  }
};

// Change  Vehical Status
export const ChangeVehicalStatus = async (data) => {
  try {
    let response = await callPrivateApi(
      `admin/vehicle/update-status`,
      "post",
      data
    );
    if (response.success === true) {
      toast.success(response.message);

      return {
        res: response?.data,
      };
    } else {
      toast.error(response.message);
    }
  } catch (err) {
    toast.error(err);
  }
};

// Change  rent vehical  Status
export const ChangeRentStatus = async (data) => {
  try {
    let response = await callPrivateApi(
      `admin/rent/update-status`,
      "post",
      data
    );
    if (response.success === true) {
      toast.success(response.message);

      return {
        res: response?.data,
      };
    } else {
      toast.error(response.message);
    }
  } catch (err) {
    toast.error(err);
  }
};

// Get Vehicals detail
export const GetVihicalsDetail = async (id) => {
  try {
    let response = await callPrivateApi(
      `admin/vehicle/vehicle-details/${id}`,
      "get"
    );
    if (response.success === true) {
      return {
        res: response?.data,
      };
    } else {
      toast.error(response.message);
    }
  } catch (err) {
    toast.error(err);
  }
};

// Upload Vehical Licence Documents
export const UplaodlicenceDocuments = async (data) => {
  try {
    let response = await callPrivateApi(
      `admin/vehicle/insurance-documents/store`,
      "post",
      data
    );
    if (response.success === true) {
      toast.success(response.message);
      let obj = Object.assign({}, ...response?.data);
      return {
        res: obj?.insurance,
      };
    } else {
      toast.error(response.message);
    }
  } catch (err) {
    toast.error(err);
  }
};

// Delete Vehical Licence Documents
export const DeletelicenceDocuments = async (data) => {
  try {
    let response = await callPrivateApi(
      `admin/vehicle/insurance-documents/delete`,
      "post",
      data
    );
    if (response.success === true) {
      toast.success(response.message);
      let obj = Object.assign({}, ...response?.data);
      return {
        res: obj?.insurance,
      };
    } else {
      toast.error(response.message);
    }
  } catch (err) {
    toast.error(err);
  }
};

// Delete Vehical
export const DeleteVehical = async (id) => {
  try {
    let response = await callPrivateApi(`admin/vehicle/delete/${id}`, "get");
    if (response.success === true) {
      toast.success(response.message);
      return {
        res: response?.data,
      };
    } else {
      toast.error(response.message);
    }
  } catch (err) {
    toast.error(err);
  }
};

// Delete Vehical
export const DeleteRent = async (id) => {
  try {
    let response = await callPrivateApi(`admin/rent/delete/${id}`, "get");
    if (response.success === true) {
      toast.success(response.message);
      return {
        res: response?.data,
      };
    } else {
      toast.error(response.message);
    }
  } catch (err) {
    toast.error(err);
  }
};

// https://dev.thetechub.us/primecar/public/api/v1/admin/vehicle/delete/200
