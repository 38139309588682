import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../confiq/firebase";
import { doc, setDoc, getDoc } from "firebase/firestore";

const BlogArticleFooterSection = () => {
	const [blogId, setBlogId] = useState("");
	const [isBlogArticleIdExistInFirebase, setIsBlogArticleIdExistInFirebase] =
		useState(false);
	const navigate = useNavigate();
	const [likeDislikeData, setLikeDislikeData] = useState({
		likes: 0,
		disLikes: 0,
		ratedBy: "",
	});

	useEffect(() => {
		let pathName = window.location.pathname;
		let splitedPath = pathName.split("/");
		setBlogId(splitedPath[splitedPath.length - 1]);
	}, []);

	useEffect(() => {
		let pathName = window.location.pathname;
		let splitedPath = pathName.split("/");
		let tempBlogId = splitedPath[splitedPath.length - 1];
		setBlogId(tempBlogId);
		async function fetchData() {
			const collectionRef = doc(db, "Blogs", tempBlogId);
			const docSnap = await getDoc(collectionRef);
			if (docSnap.exists()) {
				let data = {
					likes: docSnap.data().likes,
					disLikes: docSnap.data().disLikes,
					ratedBy: docSnap.data().ratedBy,
				};
				setIsBlogArticleIdExistInFirebase(true);
				setLikeDislikeData(data);
			}
		}
		fetchData();
	}, []);

	function generateRandomAlphanumeric(length) {
		const alphanumericCharacters =
			"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		let result = "";
		for (let i = 0; i < length; i++) {
			const randomIndex = Math.floor(
				Math.random() * alphanumericCharacters.length
			);
			result += alphanumericCharacters.charAt(randomIndex);
		}
		return result;
	}

	const handleLikeButton = () => {
		const ratedBy = localStorage.getItem(blogId);
		if (!ratedBy) {
			const randomAlphanumeric = generateRandomAlphanumeric(16);
			let tempObj = {
				likes: likeDislikeData.likes + 1,
				disLikes: likeDislikeData.disLikes,
				ratedBy: randomAlphanumeric,
			};
			setLikeDislikeData(tempObj);
			setDoc(doc(db, "Blogs", blogId), tempObj);
			localStorage.setItem(blogId, randomAlphanumeric);
		} else if (
			!isBlogArticleIdExistInFirebase &&
			likeDislikeData.ratedBy != localStorage.getItem(blogId)
		) {
			const randomAlphanumeric = generateRandomAlphanumeric(16);
			let tempObj = {
				likes: likeDislikeData.likes + 1,
				disLikes: likeDislikeData.disLikes,
				ratedBy: randomAlphanumeric,
			};
			setLikeDislikeData(tempObj);
			setDoc(doc(db, "Blogs", blogId), tempObj);
			localStorage.setItem(blogId, randomAlphanumeric);
		}
	};

	const handleDisLikeButton = () => {
		const ratedBy = localStorage.getItem(blogId);
		if (!ratedBy) {
			const randomAlphanumeric = generateRandomAlphanumeric(16);
			let tempObj = {
				likes: likeDislikeData.likes,
				disLikes: likeDislikeData.disLikes + 1,
				ratedBy: randomAlphanumeric,
			};
			setLikeDislikeData(tempObj);
			setDoc(doc(db, "Blogs", blogId), tempObj);
			localStorage.setItem(blogId, randomAlphanumeric);
		} else if (
			!isBlogArticleIdExistInFirebase &&
			likeDislikeData.ratedBy != localStorage.getItem(blogId)
		) {
			const randomAlphanumeric = generateRandomAlphanumeric(16);
			let tempObj = {
				likes: likeDislikeData.likes + 1,
				disLikes: likeDislikeData.disLikes,
				ratedBy: randomAlphanumeric,
			};
			setLikeDislikeData(tempObj);
			setDoc(doc(db, "Blogs", blogId), tempObj);
			localStorage.setItem(blogId, randomAlphanumeric);
		}
	};

	return (
		<div className="blog-article-footer-section">
			<div className="blog-article-like-dislike-main flex justify-between">
				<div className="blog-article-like-dislike-text">
					<span className="blog-article-like-dislike-heading">
						Cet article vous a-t-il été utile?
					</span>
					<span className="blog-article-like-dislike-sub-heading">
						Utilisateurs l’ayant trouvé utile:{" "}
						{likeDislikeData.likes ? likeDislikeData.likes : 0} sur{" "}
						{likeDislikeData.disLikes + likeDislikeData.likes}
					</span>
				</div>
				<div className="blog-article-like-dislike-buttons-main flex">
					<div
						className="blog-article-like-dislike-button-like"
						onClick={() => {
							handleLikeButton();
						}}
					>
						<svg
							width="16"
							height="15"
							viewBox="0 0 16 15"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M15.6593 6.825C15.6593 6.46696 15.5171 6.12358 15.2639 5.87041C15.0107 5.61723 14.6674 5.475 14.3093 5.475H10.0433L10.6913 2.39025C10.7048 2.32275 10.7116 2.2485 10.7116 2.17425C10.7116 1.8975 10.5968 1.641 10.4146 1.45875L9.69908 0.75L5.25758 5.1915C5.00783 5.44125 4.85933 5.77875 4.85933 6.15V12.9C4.85933 13.258 5.00156 13.6014 5.25473 13.8546C5.50791 14.1078 5.85128 14.25 6.20933 14.25H12.2843C12.8446 14.25 13.3238 13.9125 13.5263 13.4265L15.5648 8.66775C15.6256 8.5125 15.6593 8.3505 15.6593 8.175V6.825ZM0.809326 14.25H3.50933V6.15H0.809326V14.25Z"
								fill="#ACACAC"
							/>
						</svg>
					</div>
					<div
						className="blog-article-like-dislike-button-dislike"
						onClick={() => {
							handleDisLikeButton();
						}}
					>
						<svg
							width="16"
							height="15"
							viewBox="0 0 16 15"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M0.959325 8.175C0.959325 8.53304 1.10156 8.87642 1.35473 9.12959C1.6079 9.38277 1.95128 9.525 2.30932 9.525H6.57533L5.92733 12.6097C5.91383 12.6772 5.90707 12.7515 5.90707 12.8257C5.90707 13.1025 6.02183 13.359 6.20408 13.5412L6.91957 14.25L11.3611 9.8085C11.6108 9.55875 11.7593 9.22125 11.7593 8.85V2.1C11.7593 1.74196 11.6171 1.39858 11.3639 1.14541C11.1107 0.892232 10.7674 0.75 10.4093 0.75H4.33432C3.77407 0.75 3.29482 1.0875 3.09232 1.5735L1.05382 6.33225C0.993074 6.4875 0.959325 6.6495 0.959325 6.825V8.175ZM15.8093 0.75H13.1093V8.85H15.8093V0.75Z"
								fill="#ACACAC"
							/>
						</svg>
					</div>
				</div>
			</div>
			<button
				className="blog-article-footer-section-button"
				onClick={() => {
					navigate("/blog");
				}}
			>
				<span className="blog-article-footer-section-button-text">
					Consulter les autres articles ici
				</span>
			</button>
		</div>
	);
};

export default BlogArticleFooterSection;
