import React, { useState } from 'react'
import rent from '../../../../assests/rent.png'
import ReactPaginate from 'react-paginate';
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md';
import LoaderSpinner from '../../../../util/LoaderSpinner';
import moment from 'moment';
import { GetCustomerRenterbyPage } from '../../../../lib/Api/RentalAppApi';
import {FormatePrice} from '../../../../Partials/FormatePriceSpace' 
const TransactionList = ({ loading, trans, setLoading ,totalPage }) => {


    // const items = [];
    // const [itemOffset, setItemOffset] = useState(0);
    const [itemperPage] = useState(10);

    // const endOffset = itemOffset + itemperPage;
    // const currentItems = Array(vehicalList?.total).slice(itemOffset, endOffset);
    const pageCount = Math.ceil(totalPage / itemperPage);

    const handlePageClick = async (event) => {
        // const newOffset = (event.selected * itemperPage) % Array(vehicalList?.total).length;
        // setItemOffset(newOffset);
        //  let pageNo = event.selected === 0  ? 1  : event.selected
        setLoading(true);
        let { res } = await GetCustomerRenterbyPage(event.selected + 1);
        // let vehicals = Object.assign({}, ...res);
        setLoading(false);
        // setTrans(vehicals?.vehicles);


    };

    return (
        <>
            <div className=' bg-white rounded-md shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] '>
                <div className="overflow-x-auto  mt-6">
                    {
                        trans?.length > 0 ?
                            <>
                                <table className="table-auto w-full ">
                                    {/* Table header */}
                                    <thead className="text-xs font-[500]  bg-[#FFF8EF] border-0">
                                        <tr className='text-[#626973]'>

                                            {/* <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                                                <div className="font-[500]  text-[14px] text-left">ID </div>
                                            </th> */}
                                            <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                                                <div className="font-[500]  text-[14px] text-left">Renter </div>
                                            </th>
                                            <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                                                <div className="font-[500]  text-[14px] text-left">Vehical</div>
                                            </th>
                                            <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                                                <div className="font-[500]  text-[14px] text-left">Date</div>
                                            </th>
                                            <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                                                <div className="font-[500]  text-[14px] text-left">Location</div>
                                            </th>

                                            <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                                                <div className="font-[500]  text-[14px] text-left">Status  </div>
                                            </th>
                                            <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                                                <div className="font-[500]  text-[14px] text-center">Amount  </div>
                                            </th>

                                        </tr>
                                    </thead>
                                    {/* Table body */}
                                    <tbody className="text-sm ">
                                        {
                                            trans?.map((item, i) =>
                                                <tr className=' cursor-pointer  ' key={i}>
                                                    {/* <td className="px-4 first:pl-4 last:pr-4 py-3 whitespace-nowrap pr-5">
                                                        <div className="text-left text-[14px] text-[#898989]">1347</div>
                                                    </td> */}
                                                    <td className="px-4 first:pl-4 flex items-center last:pr-5 py-3 whitespace-nowrap">
                                                        <div className="object-cover w-8 h-8 rounded-full overflow-hidden flex items-center justify-center">
                                                            <img src={item?.rent?.user?.image || rent} alt="rent_image" className='w-auto' />
                                                        </div>
                                                        <div className="text-left text-[14px] text-[#898989] px-1">{item?.rent?.user?.first_name}</div>
                                                    </td>
                                                    <td className="px-4 first:pl-3 last:pr-4 py-3 whitespace-nowrap">
                                                        <div className="text-left text-[14px] text-[#898989]">{item?.rent?.vehicle?.make}</div>
                                                    </td>
                                                    <td className="px-4 first:pl-4 last:pr-5 py-3 whitespace-nowrap">
                                                        <div className="text-left text-[14px] text-[#898989]">{moment(item?.created_at).format('DD-MM-YYYY HH:mm')}</div>
                                                    </td>
                                                    <td className="px-4 first:pl-4 last:pr-5 py-3 whitespace-nowrap">
                                                        <div className="text-left text-[14px] text-[#898989]">Abidjan</div>
                                                    </td>

                                                    <td className="px-4 first:pl-4 last:pr-5 py-3 whitespace-nowrap">
                                                        <div className={`text-center py-[3px] w-fit px-2.5 text-[12px] leading-4 rounded-full`} style={{
                                                            color: `${item?.rent?.status_object?.text_color}`,
                                                            backgroundColor: `${item?.rent?.status_object?.background_color}`,
                                                        }}>
                                                            {item?.rent?.status_object?.text_en}</div>


                                                    </td>
                                                    <td className="px-4 first:pl-4 last:pr-5 py-3 whitespace-nowrap">
                                                        <div className="text-center text-[14px] text-[#898989]"><span className='text-[#FF8C00]'>{FormatePrice(item?.amount)} FCFA </span></div>
                                                    </td>


                                                </tr>
                                            )
                                        }

                                    </tbody>

                                </table>



                            </>
                            :
                            <div className='p-5 '>
                                {loading ? (
                                    <LoaderSpinner type="tableLoad" />
                                ) : (
                                    <h2>Record not found</h2>
                                )}
                            </div>
                    }

                </div>
                {trans?.length > 0 && (
                    <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap justify-between items-center pt-6 ">
                        {/* <div className='flex items-center w-full'>
                            <h2 className='text-[#333333] text-[14px]'>Show</h2>
                            <select className='border focus:outline-none rounded-md px-5 py-2 mx-3'>
                                <option value="6">6</option>
                                <option value="12">12</option>
                                <option value="25">25</option>
                                <option value="30">30</option>
                            </select>
                            <h2 className='text-[#333333] text-[14px]'>entries of </h2>
                        </div> */}
                        <div className="px-8 xxtra-small:px-0 mt-4 mb-1 w-full flex justify-end xxtra-small:justify-start">
                            {/* <div className='flex items-center'>
                            {
                                vehicalList?.links?.map((pag, i) => (

                                    <div key={i} className='border text-center px-2 rounded-md bg-[#F3F3F3]'>
                                        {pag?.label === '&laquo; Previous' && <MdOutlineKeyboardArrowLeft /> && pag.label === "Next &raquo;" ? <MdOutlineKeyboardArrowRight /> : pag?.label}
                                    </div>

                                ))
                            }
                        </div> */}

                            <ReactPaginate
                                breakLabel="..."
                                breakClassName=" bg-[#F3F3F3]"
                                nextLabel={<MdOutlineKeyboardArrowRight />}
                                onPageChange={handlePageClick}
                                activeClassName="bg-[#FF8C00] text-white rounded"
                                nextClassName="px-3  bg-[#F3F3F3] text-[20px] pt-[5px] rounded xxtra-small:mb-4"
                                previousClassName="px-3  bg-[#F3F3F3] text-[20px] pt-[5px] rounded xxtra-small:mb-4"
                                pageRangeDisplayed={5}
                                pageCount={pageCount}
                                previousLabel={<MdOutlineKeyboardArrowLeft />}
                                className=" flex justify-end  xxtra-small:w-full xxtra-small:justify-start xtra-small:justify-start xxtra-small:flex-wrap xtra-small:flex-wrap"
                                pageClassName=" w-[30px] h-[30px]  text-center mx-2 pt-[3px] rounded bg-[#F3F3F3] xxtra-small:mb-4 xtra-small:mb-4"
                                renderOnZeroPageCount={null}
                            />
                        </div>
                    </div>
                )}
            </div>

        </>
    )
}

export default TransactionList