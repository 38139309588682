import React, {  useState } from 'react'
// import renteruser from '../../../assests/renteruser.png'
// import camera from '../../../assests/Camera.png'
import { MdSave } from 'react-icons/md'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import {  useSelector } from 'react-redux';
import LoaderSpinner from '../../../util/LoaderSpinner';
import { EditAdminProfile } from '../../../lib/Api/AdminApi';
import { IoEye, IoEyeOff } from "react-icons/io5";
const schema = yup.object().shape({
    password: yup
        .string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters'),
    confirmPassword: yup
        .string()
        .required('Confirm Password is required')
        .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const EditPassword = () => {
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)


    const userInfo = useSelector((state) => state?.userAuth?.userInfo)

    const { register, handleSubmit, reset, formState: { errors } } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });



    const onSubmit = async (data) => {
        setLoading(true)
        let payload = {
            id: userInfo?.userid,
            password: data?.password
        }
        let { res } = await EditAdminProfile(payload)
        setLoading(false)
        if (res !== undefined) {
            reset({password:"" , confirmPassword:""})
            setShow(false)
            setShowConfirm(false)
            setLoading(false)
        }
        else {
            setLoading(false)

        }
    }




    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className=' '>
                    <div className=' grid  lg:grid-cols-2 gap-10 pt-5'>
                        <div className='flex flex-col '>
                            <label className='text-[#98A2B3] text-[14px] leading-[24px]'>New Password</label>
                            <div className='flex justify-between items-center border px-2 rounded-md'>
                                <input type={show ? 'text' : "password"} placeholder='******'  {...register('password')} className={` cursor-pointer focus:outline-none px-2 h-[40px] w-full rounded-md  ${errors.password ? 'border-red-500' : 'border-gray-300'}`} />
                                {show ? <IoEye onClick={() => setShow(false)} size={20} className='cursor-pointer' /> : <IoEyeOff  onClick={() => setShow(true)} size={20} className='cursor-pointer'/>}
                            </div>

                            {errors.password && (
                                <p className="text-red-500 text-sm text-start pt-1 ">{errors.password.message}</p>
                            )}
                        </div>

                        <div className='flex flex-col '>
                            <label className='text-[#98A2B3] text-[14px] leading-[24px]'>Confirm Password</label>
                            <div className='flex justify-between items-center border px-2 rounded-md'>
                                <input type={showConfirm ? 'text' : "password"} placeholder='******'  {...register('confirmPassword')} className={` cursor-pointer focus:outline-none px-2 h-[40px] w-full rounded-md  ${errors.confirmPassword ? 'border-red-500' : 'border-gray-300'}`} />
                                {showConfirm ? <IoEye onClick={() => setShowConfirm(false)} size={20} className='cursor-pointer'/> : <IoEyeOff  onClick={() => setShowConfirm(true)} size={20} className='cursor-pointer'/>}
                            </div>
                            {errors.confirmPassword && (
                                <p className="text-red-500 text-sm text-start pt-1 ">{errors.confirmPassword.message}</p>
                            )}
                        </div>
                    </div>
                    <div className='flex justify-end'>
                        <button className='my-2 flex items-center text-white  justify-center w-[138px] py-2  rounded-md bg-[#FF8C00] '>
                            {loading ?
                                <LoaderSpinner />
                                :
                                <>
                                    <MdSave className='mx-1 ' />
                                    Save
                                </>
                            }
                        </button>
                    </div>



                </div>
            </form>
        </>
    )
}

export default EditPassword