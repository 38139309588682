import React, { useState , useEffect } from 'react'
import TransactionList from '../TransactionList'
import { useLocation, useParams } from 'react-router-dom'
import { GetVehicalOwnerTrans, GetVehicalRnterTrans } from '../../../../lib/Api/RentalAppApi'

const Transaction = ({userId}) => {

  const [loading, setLoading] = useState(false);
  const [trans, setTrans] = useState([])

  let id = useParams().id
  let name = useParams().name
  let location = useLocation().pathname
  
// //console.log("id0" , id)
  // Get Renter Request
  let fetchRenterTrans = async () => {
    try {
      setLoading(true)
      let { res } = await GetVehicalRnterTrans(id)
      if (res) {
        setLoading(false)
        setTrans(res?.data)
      }
      else {
        setLoading(false)
      }
    }
    catch (err) {
      //console.log(err)
    }
  }
  // Get Owner Request
  let fetchOwnerTrans = async () => {
    try {
      setLoading(true)
      let { res } = await GetVehicalRnterTrans(userId || id )
      if (res) {
        setLoading(false)
        setTrans(res?.data)
      }
      else {
        setLoading(false)
      }
    }
    catch (err) {
      //console.log(err)
    }
  }

  //console.log("daya" , trans)

  useEffect(() => {
    if (location === `/dinss/tech/vehicalRenters/detail/${id}/${name}`) {
      fetchRenterTrans()
    }
    else {
      fetchOwnerTrans()
    }
  }, [id])
  return (
    <>
      <TransactionList trans={trans} loading={loading} />
    </>
  )
}

export default Transaction