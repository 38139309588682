import React from "react";
import Layout from "../../components/layouts/mainLayout/MainLayout";
import heroImage from "../../assests/blog/article-head-section.webp";
import googlePlay from "../../assests/blog/google-play.webp";
import appleStore from "../../assests/blog/apple-store.webp";

const MetaAds = () => {
	return (
		<Layout BannerButtonText="Télécharger">
				<title>
					Primecar
				</title>
			
				<div className="max-w-[1440px] mx-auto">
                <div className="mx-auto my-[50px] meta-ads-main-section">
						<h1 className="text-[22px] text-[#444444] text-center font-['Open_Sans'] meta-ads-heading-section">
                        Faites de chaque kilomètre une expérience unique. Louez 
                            avec Primecar et partez maintenant.
						</h1>
                        <div className="mt-[30px] max-w-[272px] meta-ads-apps-button-section">
                            <h1 className="text-[22px] leading-[24px] text-center text-[#FF8C00] font-['Open_Sans'] font-[700] ">
                            Téléchargez l’application
                            </h1>
                            <div className="mt-4 flex justify-between px-2 ">
							<a href="https://play.google.com/store/apps/details?id=com.dinsstech.primecar">
								<img
									className="w-[124px] h-[36px] rounded mr-2"
									src={googlePlay}
									alt="google-play"
								/>
							</a>
							<a href="https://apps.apple.com/fr/app/primecar/id6446251526">
								<img
									className="w-[124px] h-[36px] rounded"
									src={appleStore}
									alt="apple-store"
								/>
							</a>
						</div>
                        </div>
					</div>
					<img src={heroImage} alt="header-section" />
					</div>

		
					<div className="mx-auto w-[350px] my-[50px] meta-ads-mobile-section">
						<h1 className="font-[700] text-[22px] leading-[32px] text-[#444444] text-center font-['Open_Sans']">
                        <span>
                             Faites de chaque kilomètre une
                            </span><br />
                            <span>
                            expérience unique. Louez 
                            </span><br />
                         <span>
                         avec Primecar et partez 
                         </span><br />
                            maintenant.
						</h1>
                        <div className="mt-[30px] mx-auto max-w-[272px]">
                            <h1 className="text-[22px] leading-[24px] text-center text-[#FF8C00] font-['Open_Sans'] font-[700] ">
                            Téléchargez l’application
                            </h1>
                            <div className="mt-4 flex justify-between px-2">
							<a href="https://play.google.com/store/apps/details?id=com.dinsstech.primecar">
								<img
									className="w-[124px] h-[36px] rounded mr-2"
									src={googlePlay}
									alt="google-play"
								/>
							</a>
							<a href="https://apps.apple.com/fr/app/primecar/id6446251526">
								<img
									className="w-[124px] h-[36px] rounded"
									src={appleStore}
									alt="apple-store"
								/>
							</a>
						</div>
                        </div>
					</div>
		</Layout>
	);
};

export default MetaAds;