import React, { useState } from "react";
import LoaderSpinner from "../../../util/LoaderSpinner";
import moment from "moment/moment";
import { Truncate } from "../../../util/TrucateString";
import { BsThreeDotsVertical } from "react-icons/bs";
import Popup from "../../../util/popup/Popup";
import EditIcon from "../../../assests/EditIcon";
import FeesActions from "./FeesActions";
const PrimeHistory = ({
  services,
  // setVehicalList,
  // setLoading,
  // searchData,
  loading,
  setReload
}) => {

  console.log("services", services)
  // React hooks
  const [show, setShow] = useState(false);
  const [type, setType] = useState("");
  const [singleCoupon, setSingleCoupon] = useState({});

  const openPopup = (e, mode, item) => {
    e.stopPropagation();
    setSingleCoupon(item)
    setShow(true);
    setReload(mode)
    setType(mode);
  };

  //  Handle Close Popup

  const handleClose = () => {
    setShow(false)
    setReload("")
  }



  return (
    <>

      {show && (
        <Popup
          type="Detail"
          modalOpen={show}
          data={<FeesActions handleClose={handleClose} setShow={() => setShow(false)} singleCoupon={singleCoupon} />}
          onClose={() => setShow(false)}
        />
      )}
      <div className='flex md:py-5 py-5 justify-between'>
        <div>
          <h2 className='text-[#FF8C00] font-semibold text-[20px]'>Primecar Commission</h2>
          <p className='pt-4 text-[#898989] text-[14px]'>Here is Primecar Commission stanza</p>
        </div>
      </div>
      <div className="bg-white rounded-md shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)]">
        <div className="overflow-x-auto bg-white rounded-md mt-6 w-full">
          {
            services?.length > 0 ? (
              <>
                <table className="table-auto w-full ">
                  {/* Table header */}
                  <thead className="text-xs font-[500] bg-[#FFF8EF] border-0">
                    <tr className="text-[#626973]">
                      <th className="px-5 py-4 whitespace-nowrap w-px">
                        <div className="font-[500] text-[14px] text-left">ID</div>
                      </th>
                      <th className="px-5 py-4 whitespace-nowrap">
                        <div className="font-[500] text-[14px] text-left">
                          Fees Name
                        </div>
                      </th>
                      <th className="px-5 py-4 whitespace-nowrap">
                        <div className="font-[500] text-[14px] text-left">
                          Rate
                        </div>
                      </th>
                      <th className="px-5 py-4 whitespace-nowrap">
                        <div className="font-[500] text-[14px] text-left">
                          Modification Date
                        </div>
                      </th>

                      <th className="px-5 py-4 whitespace-nowrap">
                        <div className="font-[500] text-[14px] text-center">
                          Note
                        </div>
                      </th>
                      <th className="px-5 py-4 whitespace-nowrap">
                        <div className="font-[500] text-[14px] text-center">
                          Action
                        </div>
                      </th>
                    </tr>
                  </thead>
                  {/* Table body */}
                  <tbody className="text-sm ">
                    {
                      !loading ? (
                        services?.map((item, i) =>
                          <tr className=" cursor-pointer" key={i}>
                            <td className="px-5 py-4 whitespace-nowrap">
                              <div className="text-left text-[14px] text-[#898989]">
                                {i + 1}
                              </div>
                            </td>
                            <td className="px-5 py-4 whitespace-nowrap">
                              <div className="text-left text-[14px] text-[#898989]">
                                {item?.key}
                              </div>
                            </td>
                            <td className="px-5 py-4 whitespace-nowrap">
                              <div className="text-left text-[14px] text-[#898989]">
                                {item?.value * 100}%
                              </div>
                            </td>
                            <td className="px-5 py-4 whitespace-nowrap">
                              <div className="text-left text-[14px] text-[#898989]">
                                {moment(item?.created_at).format('ll HH:mm')}
                              </div>
                            </td>
                            <td className="px-5 py-4 whitespace-nowrap">
                              <div className="text-center text-[14px] text-[#898989]">
                                {Truncate(item?.note, 30)}
                              </div>
                            </td>
                            <td className="px-5 py-4 whitespace-nowrap">
                              <div className="text-left flex justify-center ">
                                {/* <BsThreeDotsVertical className='text-[20px] text-[#898989]' /> */}
                                {/* <GenericDrodown /> */}

                                <div class="group inline-block">
                                  <button class="outline-none focus:outline-none  px-3 py-1 bg-white rounded-sm flex items-center">
                                    <BsThreeDotsVertical className="text-[20px] text-[#898989]" />
                                  </button>
                                  <ul
                                    class="bg-white rounded-md transform scale-0 group-hover:scale-100 absolute p-2 shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] 
transition duration-150 ease-in-out right-7 origin-top lg:w-[12%]  "
                                  >
                                    <li
                                      onClick={(e) => openPopup(e, "Edit", item)}
                                      class="rounded-sm flex items-center px-2 py-2 hover:bg-gray-100"
                                    >
                                      <EditIcon />
                                      <h2 className="ml-2 text-[14px] text-[#444444]">
                                        Edit
                                      </h2>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </td>
                          </tr>
                        )
                      ) : (
                        <div className="pt-4">
                          <LoaderSpinner type="tableLoad" />
                        </div>
                      )
                    }
                  </tbody>
                </table>
              </>
            ) : (
              <div className="p-5 flex items-center px-3 py-3">
                {loading ? (
                  <LoaderSpinner type="tableLoad" />
                ) : (
                  <h2>Record not found</h2>
                )}
              </div>
            )}
        </div>
        {/* {vehicalList?.length > 0 && (
          <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap justify-between items-center pt-6 ">
            <div className="px-8 xxtra-small:px-0 mt-4 mb-1 w-full flex justify-end xxtra-small:justify-start">
              <ReactPaginate
                breakLabel="..."
                breakClassName=" bg-[#F3F3F3]"
                nextLabel={<MdOutlineKeyboardArrowRight />}
                onPageChange={handlePageClick}
                activeClassName="bg-[#FF8C00] text-white rounded"
                nextClassName="px-3  bg-[#F3F3F3] text-[20px] pt-[5px] rounded xxtra-small:mb-4"
                previousClassName="px-3  bg-[#F3F3F3] text-[20px] pt-[5px] rounded xxtra-small:mb-4"
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel={<MdOutlineKeyboardArrowLeft />}
                className=" flex justify-end  xxtra-small:w-full xxtra-small:justify-start xtra-small:justify-start xxtra-small:flex-wrap xtra-small:flex-wrap"
                pageClassName=" w-[30px] h-[30px]  text-center mx-2 pt-[3px] rounded bg-[#F3F3F3] xxtra-small:mb-4 xtra-small:mb-4"
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        )} */}
      </div>
    </>
  );
};

export default PrimeHistory;
