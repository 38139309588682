import React from 'react'
import fixez from '../../../assests/PMNeew/Lassyer_1 1.svg'
import car from '../../../assests/PMNeew/Group.svg'
import mobile from '../../../assests/PMNeew/destination 1.svg'
const Vehicles = () => {
  return (
    <div className='flex flex-col bg-[#FFFBF7] px-5 md:px-8 lg:px-20 xl:px-40 py-10 xl:py-14 gap-6 md:gap-8 lg:gap-12 xl:gap-20 items-center  xl:justify-center '>

      <div className="flex flex-col gap-3 text-center max-w-md md:max-w-none">
        <p className="text-[#444444] font-semibold lg:font-bold text-2xl xl:text-3xl">
          Des véhicules disponibles, où et <span className='text-[#FF8C00]'>quand vous voulez</span>
        </p>
        <p className="text-sm lg:text-base text-[#667085]">
          Fini les déplacements en agence
        </p>
      </div>

      <div className="flex flex-col md:flex-row gap-5 md:gap-8 max-w-xs md:max-w-none xl:max-w-7xl">

        <div className="flex flex-col gap-4 lg:gap-5 text-center items-center p-4 cursor-pointer rounded-md hover:shadow-lg">
          <img src={fixez} alt="fixez_img" className='object-contain w-11 md:w-14 lg:w-16' />
          <p className="text-base lg:text-lg text-[#444444] font-semibold">
            Ouvrez un compte gratuit <br/> pour votre entreprise
          </p>
          <p className="text-[#667085] text-sm">
            Nous vous donnerons un accès unique. Plusieurs personnes peuvent gérer ce même compte pour votre entreprise et faire des réservations
          </p>
        </div>

        <div className="flex flex-col gap-4 lg:gap-5 text-center items-center p-4 cursor-pointer rounded-md hover:shadow-lg">
          <img src={car} alt="car_img" className='object-contain w-11 md:w-14 lg:w-16' />
          <p className="text-base lg:text-lg text-[#444444] font-semibold">
            Réservez rapidement <br /> un véhicule
          </p>
          <p className="text-[#667085] text-sm">
            Des véhicules disponibles selon vos besoins du moment que la location soit pour quelques semaines ou plusieurs mois.
          </p>
        </div>

        <div className="flex flex-col gap-4 lg:gap-5 text-center items-center p-4 cursor-pointer rounded-md hover:shadow-lg">
          <img src={mobile} alt="mobile_img" className='object-contain w-11 md:w-14 lg:w-16' />
          <p className="text-base lg:text-lg text-[#444444] font-semibold">
            Soyez livré à votre <br /> convenance
          </p>
          <p className="text-[#667085] text-sm">
            Que ce soit une voiture ou un utilitaire, avec ou sans chauffeur, votre véhicule vous attendra au point de rendez-vous.
          </p>
        </div>

      </div>

    </div>
  )
}

export default Vehicles