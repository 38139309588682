import React, { useState } from "react";
import { MdClose } from "react-icons/md";
import LoaderSpinner from "../../../util/LoaderSpinner";

const EditData = ({ setShow, callback, loadReason, vehicalStatus }) => {
  const [reason, setReason] = useState("");
  const [lists, setLists] = useState([])
  const HandleChecks = (item) => {
    if (lists?.includes(item?.id)) {
      let l = lists.filter((f) => f?.id !== item?.id)
      setLists(l)
    }
    else {
      setLists((prev) => [...prev, item])
      // setLists((prev) => ([...prev , { item }]))
    }
  }


  const onSubmit = (e) => {
    e.preventDefault();
    if (lists !== null) {
      let arr = []

      for (let index = 0; index < lists.length; index++) {
        const element = lists[index];
        arr.push(element.detail)
      }
      let check = vehicalStatus === "incomplete" ? arr : reason
      callback(check);
    } else {
      // setError("Reason is Required")
    }
  };

  let ReasonData = [{ id: 0, detail: "Détails des caractéristiques de votre véhicule incorrects (étape 1/11)" }, { id: 1, detail: "Année ou type de véhicule incorrecte (étape 1/11)" }, { id: 2, detail: "Description incomplète et trop simpliste (étape 2/11)" }, { id: 3, detail: "Photos du véhicule ne respectant le format recommandé (étape 3/11)" }, { id: 4, detail: "Photos de véhicule floues (étape 3/11)" }, { id: 5, detail: "Revoir l'étape 4/11, équipements et accessoires incorrects" }, { id: 6, detail: "Corriger les infos sur votre assurance (étape 6/11)" }, { id: 6, detail: "Votre assurance doit être valable pour au moins 6 mois (étape 6/11)" }, { id: 7, detail: "Photos documents d’assurance floues (étape 6/11)" }, { id: 8, detail: "Informations personnelles incorrectes (étape 10/11)" }, { id: 9, detail: "Photos pièces d’identité floues (étape 10/11)" }]

  return (
    <>
      <div className="">
        <div className="flex justify-between items-center">
          <h2 className="text-[#444444] font-semibold">Reason</h2>
          <MdClose onClick={() => setShow(false)} className="cursor-pointer" />
        </div>
        {vehicalStatus === "incomplete" ?

          <div className="pt-4">
            {
              ReasonData.map((item, i) => (
                <div className="flex " key={i}>
                  <input id={i} className="h-[20px] w-[20px]" type="checkbox" onChange={() => HandleChecks(item)} />
                  <label htmlFor={i} className="pl-2 cursor-pointer">{item.detail}</label>
                </div>
              ))
            }
          </div>
          :
          <div className="">
            <textarea
              onChange={(e) => setReason(e.target.value)}
              value={reason}
              rows={4}
              placeholder="Type here....."
              className="border py-2  placeholder:text-[14px] mt-5 w-full rounded-md text-[#ACACAC] focus:outline-none px-2 "
            />
          </div>
        }
        <div className="pt-5 flex justify-end">
          <button
            type="submit"
            onClick={(e) => onSubmit(e)}
            className="w-[138px] py-2 rounded-md bg-[#FF8C00]   text-white"
          >
            {loadReason ? <LoaderSpinner /> : "Soumettre"}
          </button>
        </div>
      </div>
    </>
  );
};

export default EditData;
