import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Transition from "../Transition/Transition";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { GetVihicalsByFilter, GetRentsByFilter } from "../../lib/Api/Vehical";
import { GetSellerByFilter } from "../../lib/Api/SellerApi";

function FilterDropdown({
  setVehicalList,
  setLoading,
  // setTypes,
  setTotalPage,
  setSearchData,
  searchData,
  setCurrentPageIndex,
  // offsetPage,
  setOffSetPage,
  setStatusCheck,
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const list = [
    "All",
    "Pending",
    "Approved",
    "Paid",
    "Rejected",
    "Expired",
    "Canceled",
    "Canceled By Owner",
    "Ended",
  ];
  const list2 = [
    "All",
    "Draft",
    "Incomplete",
    "Pending",
    "Approved",
    "Rejected",
    "Inactive",
  ];
  const list3 = [
    "All",
    "Draft",
    "Pending",
    "Approved",
    "Rejected",
    "Idle",
    "Sold",
  ];
  const location = useLocation().pathname;
  let checkList =
    location === "/dinss/tech/vehicalRenters" || location === "/dinss/tech/customerRenter"
      ? list
      :  location === "/dinss/tech/vehicalSaller" ? list3 : list2;
  const [List, setList] = useState([]);

  const trigger = useRef(null);
  const dropdown = useRef(null);
  const [filterList, setFilterList] = useState(
    location === "/dinss/tech/dashboard" ? "Pending" : "Filter"
  );

  let VehicalFilter = async (item) => {
    setFilterList(item);
    setLoading(true);
    let filter = item.toLowerCase();
    setStatusCheck(filter);
    let { res } = await ( location === "/dinss/tech/vehicalSaller" ? GetSellerByFilter(filter, 1, searchData) : GetVihicalsByFilter(filter, 1, searchData));
    let vehicals = Object.assign({}, ...res);
    setLoading(false);
    setCurrentPageIndex(0)
    setVehicalList(vehicals?.vehicles?.data);
    setTotalPage(vehicals?.vehicles?.total);
    setDropdownOpen(false);
  };

  let RentFilter = async (item) => {
    setFilterList(item);
    setLoading(true);
    let filter = "";
    let check = item.toLowerCase()
    if (check === "canceled by owner") {
      filter = "canceled_by_owner"
    }
    else {
      filter = item.toLowerCase()
    }
    setStatusCheck(filter)
    let { res } = await GetRentsByFilter(filter, 1, searchData);
    let vehicals = Object.assign({}, ...res);
    setLoading(false);
    // setOffsetPage(1)
    setCurrentPageIndex(0)
    setVehicalList(vehicals?.rents?.data);
    setTotalPage(vehicals?.rents?.total);
    setDropdownOpen(false);
  };

  const GetFilterVehical = async (item) => {
    if (item === "All") {
      setFilterList(item);
      setSearchData("all");
      setStatusCheck("all");
      // setOffSetPage(1)
      setTimeout(() => {
        setSearchData("");
      }, 1000);
    } else {
      location === "/dinss/tech/dashboard" || location === "/dinss/tech/vehicalOwner" || location === "/dinss/tech/vehicalSaller"
        ? VehicalFilter(item)
        : RentFilter(item);
    }
  };

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  useEffect(() => {
    setList(checkList);
  }, [location]);

  return (
    <div className="relative inline-flex xtra-small:w-full xxtra-small:w-full">
      <button
        ref={trigger}
        className={` flex items-center w-full px-5 py-[10px] justify-between bg-white shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] transition duration-150 rounded-full ${dropdownOpen && "bg-slate-200"
          } ml-4 xxtra-small:ml-0 xtra-small:ml-0`}
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <h2 className="font-medium text-[#444]"> {filterList} </h2>
        <MdOutlineKeyboardArrowDown className="text-[20px] ml-2" />
      </button>

      <Transition
        className={`origin-top-center lg:right-20 md:right-0 xtra-small:right-0  lg:-left-[60px] rounded-md z-10 absolute top-full w-[200px]  bg-white border-0 py-5 px-2 shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] overflow-hidden mt-1`}
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          <ul>
            {List.map((item, i) => {
              if (filterList !== "Pendding")
                return (
                  <li key={i} className={`hover:bg-gray-200 hover:text-white cursor-pointer`}>
                    <div
                      className="text-[#444444] text-[14px] font-medium  flex items-center py-1 px-3"
                      onClick={() => GetFilterVehical(item)}
                    >
                      <span>{item}</span>
                    </div>
                  </li>
                );
            })}
          </ul>
        </div>
      </Transition>
    </div>
  );
}

export default FilterDropdown;
