import React, { useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
// import ReactPaginate from "react-paginate";
// import close from "../../../assests/clos.png";
// import {
//   MdOutlineKeyboardArrowLeft,
//   MdOutlineKeyboardArrowRight,
// } from "react-icons/md";
import Popup from "../../../util/popup/Popup";
// import CancelBooking from "./CancelBooking";
// import EyeIcon from "../../../assests/EyeIcon";
import { useNavigate } from "react-router-dom";
// import { GetBookingMangPageNo } from "../../../lib/Api/Management";
import LoaderSpinner from "../../../util/LoaderSpinner";
import { FormatePrice } from "../../../Partials/FormatePriceSpace";
import UpdateStatusLoader from "../statusLoaderSpinner/StatusLoader";
import { UpdateTransfer } from "../../../lib/Api/Management";
import moment from "moment/moment";
const DataList = ({
  type,
  rents,
  // searchData,
  // setRents,
  loading,
  setSearchData,
  // setLoading,
  // active,
  // totalPage,
  // setCurrentPageIndex,
  // currentPageIndex,
  // setOffsetPage 
}) => {
  const [show, setShow] = useState(false);



  // const endOffset = itemOffset + itemperPage;
  // const currentItems = Array(vehicalList?.total).slice(itemOffset, endOffset);
  // const pageCount = Math.ceil(totalPage / itemperPage);

  // const handlePageClick = async (event) => {
  //   setLoading(true);
  //   let { res } = await GetBookingMangPageNo(event.selected + 1, searchData);
  //   setLoading(false);
  //   setRents(res.data);
  //   setOffsetPage(event.selected + 1)
  //   setCurrentPageIndex(event.selected)
  // };


  const handleButtonClick = (link) => {
    // Create an anchor element
    const anchor = document.createElement('a');
    // Set the href attribute to the file path
    anchor.href = link; // Replace 'path/to/your/file.pdf' with the path to your file
    // Set the target attribute to '_blank' to open in a new tab
    anchor.target = '_blank';
    // Programmatically click the anchor element to open the file in a new tab
    anchor.click();
  };


  const handleUpate = async (item, type) => {
    let payload = {
      "rent_id": item?.id,
      "is_valid": type === "Approve" ? true : false
    }
    try {
      setShow(true)
      setSearchData("loading")
      let res = await UpdateTransfer(payload)
      if (res) {
        setShow(false)
        setSearchData("")
      }

    }
    catch (err) { }
  }

  return (
    <>
      {show && <Popup data={<UpdateStatusLoader />} modalOpen={show} onClose={() => setShow(false)} />}

      <div className="bg-white rounded-md shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)]">
        <div className="overflow-x-auto  mt-6">
          {
            !loading &&
              rents?.length > 0 ?
              <>
                <table className="table-auto w-full ">
                  {/* Table header */}
                  <thead className="text-xs font-semibold bg-[#FFF8EF] border-0">
                    <tr className="text-[#626973]">
                      <th className="px-4 py-4 pr-[25px] whitespace-nowrap w-px">
                        <div className="font-semibold text-[14px] text-left">
                          ID{" "}
                        </div>
                      </th>
                      <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                        <div className="font-semibold text-[14px] text-left">
                          Date/Time
                        </div>
                      </th>
                      <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                        <div className="font-semibold text-[14px] text-left">
                          Vehicle Name
                        </div>
                      </th>
                      <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                        <div className="font-semibold text-[14px] text-left">
                          Vehicle Renter
                        </div>
                      </th>
                      <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                        <div className="font-semibold text-[14px] text-left">
                          Owner Email
                        </div>
                      </th>
                      <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                        <div className="font-semibold text-[14px] text-left">
                          {/* Vehicle Owner */}
                          Full Name
                        </div>
                      </th>
                      <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                        <div className="font-semibold text-[14px] text-left">
                          Total Amount
                        </div>
                      </th>
                      <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                        <div className="font-semibold text-[14px] text-left">
                          Transfer Type
                        </div>
                      </th>
                      <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                        <div className="font-semibold text-[14px] text-left">
                          Proof
                        </div>
                      </th>
                      {type !== "Active" &&
                        <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                          <div className="font-semibold text-[14px] text-left">
                            Status
                          </div>
                        </th>
                      }
                      <th className="px-4 py-4 pr-[25px] whitespace-nowrap">
                        <div className="font-semibold text-[14px] text-center">
                          {type === "Active" ? "Status" : "Action"}
                        </div>
                      </th>
                    </tr>
                  </thead>
                  {/* Table body */}
                  <tbody className="text-sm ">
                    {rents?.map((item, i) => (
                      <tr className=" cursor-pointer  " key={i}>
                        <td className="px-4 py-4 pr-[25px] whitespace-nowrap">
                          <div className="text-left text-[14px] text-[#898989]">
                            {item?.id}
                          </div>
                        </td>
                        <td className="px-4 py-4 pr-[25px] whitespace-nowrap">
                          <div className="text-left text-[14px] text-[#898989]">
                            {moment(item?.created_at).format('DD-MM-YYYY HH:mm')}
                          </div>
                        </td>
                        <td className="px-4 py-4 pr-[25px] whitespace-nowrap">
                          <div className="text-left text-[14px] text-[#898989]">
                            {item?.vehicle?.make}<span className="px-2">{item?.vehicle?.model}</span>
                          </div>
                        </td>
                        <td
                          // onClick={() => {
                          //   return navigate(
                          //     `/dinss/tech/vehicalRenters/detail/${item?.user_id}/all`
                          //   );
                          //   // setRentalUser(item?.user)
                          // }}
                          className="px-2 first:pl-5 flex items-center last:pr-5 py-3 whitespace-nowrap"
                        >
                          <div className="object-cover w-8 h-8 rounded-full overflow-hidden flex items-center justify-center">
                            <img
                              src={item?.user?.image}
                              alt="rent_image"
                              className="w-auto"
                            />
                          </div>
                          <div className="text-left  text-[14px] text-[#898989] px-1">
                            {item?.user?.first_name}
                          </div>
                        </td>

                        <td className="px-4 py-4 pr-[25px] whitespace-nowrap">
                          <div className="text-left text-[14px] text-[#898989]">
                            {item?.vehicle?.user?.email}
                          </div>
                        </td>
                        <td
                          // onClick={() => {
                          //   return navigate(
                          //     `/dinss/tech/vehicalRenters/detail/${item?.user_id}/all`
                          //   );
                          //   // setRentalUser(item?.user)
                          // }}
                          className="px-2 first:pl-5 flex items-center last:pr-5 py-3 whitespace-nowrap"
                        >
                          <div className="object-cover w-8 h-8 rounded-full overflow-hidden flex items-center justify-center">
                            <img
                              src={item?.vehicle?.user?.image}
                              alt="rent_image"
                              className="w-auto"
                            />
                          </div>
                          <div className="text-left  text-[14px] text-[#898989] px-1">
                            {`${item?.vehicle?.user?.first_name} ${item?.vehicle?.user?.last_name}`}
                          </div>
                        </td>

                        <td className="px-4 py-4 pr-[25px] whitespace-nowrap">
                          <div className="text-left text-[14px] text-[#898989]">
                            {FormatePrice(item?.amount)}FCFA
                          </div>
                        </td>
                        <td className="px-4 py-4 pr-[25px] whitespace-nowrap">
                          <div className="text-left text-[14px] text-[#898989]">
                            {item?.money_transfer?.type === "money_transfer" ? "Mobile Transfer" : item?.money_transfer?.type === "onsite" ? "Onsite" : "Bank Transfer"}
                          </div>
                        </td>
                        <td className="px-4 py-4 pr-[25px] whitespace-nowrap">
                          <button disabled={item?.money_transfer?.type === "onsite" ? true : false} className={`border rounded-md bg-[#FF8C00] px-3 py-2 text-white `} onClick={() => handleButtonClick(item?.money_transfer?.proof)}> {"View Proof"} </button>
                        </td>
                        <td className="px-4 py-4 pr-[25px] whitespace-nowrap">
                          <div className="text-left text-[14px]">
                            {item?.money_transfer?.status === 1 ? (
                              <span className="bg-green-100 text-green-500 px-2 py-1 rounded-full">
                                Approved
                              </span>
                            ) : item?.money_transfer?.status === 2 ? (
                              <span className="bg-red-100 text-red-500 px-2 py-1 rounded-full">
                                Declined
                              </span>
                            ) : (
                              <span className="bg-yellow-100 text-yellow-500 px-2 py-1 rounded-full">
                                Pending
                              </span>
                            )}
                          </div>
                        </td>
                        {/* {type === "Active" ? */}
                        {/* <td className="px-4 py-4 pr-[25px] whitespace-nowrap">
                          <div className={item?.money_transfer?.status === 1 ? 'text-green-500 text-left text-[14px]' : 'text-red-500 text-left text-[14px]'}>
                            {item?.money_transfer?.status === 1 ? "Approved" : "Declined"}
                          </div>
                        </td> */}
                        {/* : */}
                        {type !== "Active" &&
                          <td className="px-4 py-4 pr-[25px] whitespace-nowrap">
                            <div className="text-center flex justify-center">
                              <div className="group inline-block">
                                <button className="outline-none focus:outline-none  px-3 py-1 bg-white rounded-sm flex items-center">
                                  <BsThreeDotsVertical className="text-[20px] text-[#898989]" />
                                </button>
                                <ul
                                  className="bg-white shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] border-0 rounded-md p-4 transform scale-0 group-hover:scale-100 absolute 
  transition duration-150 ease-in-out right-7 origin-top xl:w-[15%]  "
                                >

                                  <li
                                    onClick={() => handleUpate(item, "Approve")}
                                    className="rounded-sm actions flex items-center px-2 py-2 mb-2 hover:bg-gray-100"
                                  >
                                    <h2 className="ml-2">Approved</h2>
                                  </li>
                                  <li
                                    onClick={() => handleUpate(item, "Decline")}
                                    className="rounded-sm actions flex items-center px-2 py-2 mb-2 hover:bg-gray-100"
                                  >
                                    <h2 className="ml-2">Declined</h2>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                        }
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
              :
              <div className="p-5 ">
                {loading ? (
                  <LoaderSpinner type="tableLoad" />
                ) : (
                  <h2>Record not found</h2>
                )}
              </div>
          }
        </div>

        {/* {
          rents?.length > 0 ?

            <div className="flex flex-wrap justify-end items-end pt-6 px-5">

              <div className="lg:px-8 my-6 ">
                <ReactPaginate
                  breakLabel="..."
                  breakClassName=" bg-[#F3F3F3]"
                  nextLabel={<MdOutlineKeyboardArrowRight />}
                  onPageChange={handlePageClick}
                  activeClassName="bg-[#FF8C00] text-white rounded"
                  nextClassName="px-3  bg-[#F3F3F3] text-[20px] pt-[5px] rounded"
                  previousClassName="px-3  bg-[#F3F3F3] text-[20px] pt-[5px] rounded"
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel={<MdOutlineKeyboardArrowLeft />}
                  className=" flex justify-end"
                  pageClassName=" w-[30px] h-[30px]  text-center mx-2 pt-[3px] rounded bg-[#F3F3F3] "
                  // renderOnZeroPageCount={null}
                  forcePage={currentPageIndex}
                />
              </div>
            </div>
            : null} */}
      </div>
    </>
  );
};

export default DataList;
