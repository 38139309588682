import React, { useState, useEffect } from 'react'
import CustomerSuppport from '../CustomerSuppport'
import {
  getFirestore,
  collection,
  query,
  getDocs,
  orderBy,
} from "firebase/firestore";
import { useParams } from 'react-router-dom';
import { Firebaseapp } from '../../../../confiq/firebase';
import Popup from '../../../../util/popup/Popup';
import IntitiateChat from './IntitiateChat';
// import { useSelector } from 'react-redux';
const CustomerSupport = ({ allUsers, title, setUpdateMessages }) => {
  const [users, setUsers] = useState([]);
  const [convId, setConvId] = useState();
  const [show, setShow] = useState(false);
  const [updateMessage, setUpdateMessage] = useState("")
  const { id } = useParams();

  let fetch = async () => {
    const db = getFirestore(Firebaseapp);
    const Conv = query(collection(db, `Users/${id}/Conversation`), orderBy("lastMessageTime", "desc"));
    try {
      const querySnapshot = await getDocs(Conv);
      const UsersConv = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      const filterData = UsersConv.filter((f) => f?.otherUserId == "0");
      const arr = allUsers.reduce((result, element) => {
        const foundUser = filterData.find((element2) => element.id == element2.otherUserId);
        if (foundUser) {
          result.push({ ...element, ...foundUser });
        }
        return result;
      }, []);

      setUsers(arr);
    } catch (error) {
      console.error("Error retrieving conversations:", error);
    }

  };

  const handleClose = () => {
    setUpdateMessages("data")
    setShow(false)
    setUpdateMessages("")
    fetch();
  }

  useEffect(() => {
    if (allUsers?.length > 0) {
      fetch();
      
    }
  }, [allUsers, updateMessage === ""]);

  let checkUser = allUsers.find((f) => f.userId === id)





  return (
    <div>
      <div className='flex justify-end items-end pb-3'>
        <button onClick={() => setShow(true)} className='bg-[#FF8C00] text-white px-3 py-2 rounded-md'>Initiate Chat</button>
      </div>
      {show && <Popup type="chat" data={<IntitiateChat close={() => handleClose()} />} modalOpen={show} onClose={() => setShow(false)} />}
      <CustomerSuppport
        users={users}
        convId={convId}
        currentUser={checkUser}
        setConvId={setConvId}
        setUpdateMessage={setUpdateMessage}
        title={title}
      />
    </div>
  )
}

export default CustomerSupport