import React, { useEffect, useState } from "react";
import RenterDetail from "./RenterDetail";
// import Reviews from "./Reviews";
import CarRequest from "./CarRequest";
import ChatWithOwner from "./ChatWithOwner";
import CustomerSupport from "./CustomerSupport";
// import Transaction from "./Transaction";
import { useLocation, useParams } from "react-router-dom";
import CarList from "./CarList";
import CarlistDetail from "../carlistDetail/CarlistDetail";
import { collection, getDocs, getFirestore, query } from "firebase/firestore";
import { Firebaseapp } from "../../../../confiq/firebase";
// import BreadCums from "../../../MainLandingComp/breadCums/BreadCums";
// import { GetVehicalRnterDetail } from "../../../../lib/Api/RentalAppApi";

const RentalIndex = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [carListDetail, setcarListDetail] = useState(false);
  const [user, setUser] = useState({});
  const [allUsers, setAllUsers] = useState([]);
  const [updateMessage, setUpdateMessage] = useState("")

  let location = useLocation().pathname;
  let id = useParams().id;
  let name = useParams().name;

  let list = [
    "Seller Details",
    "Car list",
    "Chat with Buyer",
    "Customer Support",
  ];
  const handlClick = (index) => {
    setActiveIndex(Number(index));
    // carListDetail(false)
  };

  useEffect(() => {
    if (name === "all") {
      setActiveIndex(0);
    } else {
      setActiveIndex(1);
    }
  }, []);

  useEffect(() => {
    let fetch = async () => {
      const db = getFirestore(Firebaseapp);
      const q = query(collection(db, `Users/`));
      const querySnapshots = await getDocs(q);
      const Users = querySnapshots.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAllUsers(Users);
    };
    fetch();
  }, [updateMessage === ""]);


  console.log("activeIndex" , activeIndex)

  return (
    <>
      <h2 className="text-[#444444] font-semibold text-[20px] leading-[30px]">
      Vehicle Seller
      </h2>
      <div className="my-3">
      </div>
        <>
          <select
            className="border-0 shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] rounded-md bg-white px-2 xl:hidden md:hidden w-full py-2 focus:outline-none mt-2"
            onChange={(e) => handlClick(e.target.value)}
          >
            <option value="">Please Select</option>
            {list.map((list, i) => (
              <option value={i} key={i}>
                {list}
              </option>
            ))}
          </select>
          <div className="xl:flex md:flex hidden  items-center border-b-2  mt-3">
            {list.map((list, i) => (
              <h1
                className={`xl:w-[170px] md:w-auto w-[165px] text-center cursor-pointer text-[#ACACAC] px-3 py-2 text-[15px] leading-[21px] ${activeIndex === i &&
                  "border-b-2  text-center  border-[#FF8C00] !text-[#444444] font-semibold"
                  }`}
                onClick={() => {
                  setActiveIndex(i);
                  setcarListDetail(false);
                }}
                key={i}
              >
                {" "}
                {list}
              </h1>
            ))}
          </div>
        </>
      <div className="mt-5">
        {activeIndex === 0 && <RenterDetail userId={user?.id} />}
        {/* {activeIndex === 1 && <Reviews user={user} />} */}
        { }
        {
          (activeIndex === 1 &&
            location === `/dinss/tech/vehicalSaller/detail/${id}/all`) ? (
              <CarList
              carListDetails={carListDetail}
              user={user}
              setcarListDetail={setcarListDetail}
            />
        ) : (activeIndex === 1 &&
          location === `/dinss/tech/vehicalSaller/detail/${id}/detail`) ||
          (activeIndex === 1 &&
            location === `/dinss/tech/vehicalSaller/detail/${id}/carlist`) ? (
          <CarlistDetail setUser={setUser} user={user} VehicalId={id} />
        ) : (
          ""
        )}
        {activeIndex === 2 && <ChatWithOwner allUsers={allUsers} title={"Owner"} />}
        {activeIndex === 3 && <CustomerSupport  setUpdateMessages={setUpdateMessage} allUsers={allUsers} title={"Support"}/>}
        {/* {activeIndex === 5 && <Transaction userId={user?.id} />} */}
      </div>
    </>
  );
};

export default RentalIndex;
