import React, { useEffect, useState } from "react";
import professionaimg from "../../../assests/Group-1261153749-x2.webp";
const Banner = () => {
	const scrollToContact = () =>
		document.querySelector("#prof-form").scrollIntoView();

	useEffect(() => {}, []);

	const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 900);
		};
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<div className="px-5 md:px-20 flex flex-row items-center justify-center">
			<div className="flex flex-col md:flex-row  gap-6 md:gap-12 lg:gap-14 xl:gap-[62.52]  py-10 lg:py-11 items-center justify-center 2xl:w-[1440px] ">
				<div className="lg:w-2/5 w-full h-[400px] md:h-auto md:w-3/6 xl:w-3/6">
					{isMobile ? (
						<img
							src={professionaimg}
							alt="user_img"
							className="w-full h-full object-contain"
						/>
					) : (
						<img
							src={professionaimg}
							alt="user_img"
							className="w-full h-full object-contain"
						/>
					)}
				</div>
				<div className="flex flex-col gap-4 text-center items-center  md:items-start md:text-left justify-center lg:justify-start  w-full md:w-[500px] ">
					<h1 className="font-bold   md:font-semibold text-xl xxtra-small:text-2xl sm:text-3xl md:text-4xl lg:text-[34px] leading-normal">
						<span className="text-[#444444] lg:leading-tight xl:leading-snug">
							Nous simplifions le quotidien
						</span>
						<br className="hidden lg:block" />
						<span className="text-[#FF8C00] lg:leading-tight xl:leading-snug">
							&nbsp;des professionnels
						</span>
					</h1>
					<p className="text-[#667085] text-center md:text-left text-sm">
						Obtenez un véhicule en un clin d’œil, pour vous et vos
						équipes
					</p>
					<button
						onClick={scrollToContact}
						className="px-8 py-2 xl:py-3 text-base bg-[#FF8C00] hover:bg-[#D16A00] text-white w-fit rounded-md mt-3 md:mt-6"
					>
						Ça m’intéresse !
					</button>
				</div>
			</div>
		</div>
	);
};

export default Banner;
