import React from "react";
import android from "../../../assests/svg/googleplay.svg";
import apple from "../../../assests/svg/apple.svg";
// import phone from '../../../assests/svg/mobile.svg'
// import phone1 from "../../../assests/PMNeew/Phone Mockup 7.svg";
// import phone2 from "../../../assests/PMNeew/Phone Mockup 6.svg";
import phone1 from "../../../assests/Phone-Mockup-8.webp";
import phone2 from "../../../assests/Phone-Mockup-9.webp";
import phone3 from "../../../assests/phone3.webp";
// import SocialButton from '../../downLoadBtn/SocialButton'
const Application = () => {
	return (
		<>
			<div className="application h-full ">
				<div className=" lg:py-[40px] py-[40px]  md:px-20 px-5 ">
					<div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap md:items-center 2xl:w-[1440px] justify-between m-auto">
						<div className="w-full  lg:order-0 md:order-0 order-1 xl:max-w-2xl">
							<h2 className="lg:text-[31px] md:leading-[50px] font-sans text-[22px] text-[#444444] font-bold">
								Une{" "}
								<span className="text-[#FF8C00]">
									application
								</span>{" "}
								pour vous
							</h2>
							<p className="text-[#18191F] font-sans font-normal md:leading-[26px] leading-[20px] text-[14px] md:pt-[14px] pt-[24px]">
								Que vous cherchiez une voiture, un camion ou un
								engin lourd, pour votre besoin du moment, nous
								vous donnons accès aux meilleures offres.
								Réservez votre véhicule à partir de notre
								application, c’est simple, rapide et sans
								difficulté
							</p>

							<div className="pt-[48px] lg:pt-[50px] md:pt-[20px] text-[#444444]">
								<h2 className="text-[15px] font-medium font-sans">
									Téléchargez l’application
								</h2>
								<div className="flex pt-[8px] md:pt-[2px] items-center">
									<a href="https://apps.apple.com/fr/app/primecar/id6446251526">
										<img
											src={apple}
											alt="social_login"
											style={{ width: "150px" }}
										/>
									</a>
									<a href="https://play.google.com/store/apps/details?id=com.dinsstech.primecar">
										<img
											src={android}
											alt="social_login"
											style={{ width: "175px" }}
										/>
									</a>
								</div>
							</div>
						</div>

						<div className="grid grid-cols-2  w-full pl-5 md:order-1  pt-5 lg:order-1 order-0 xl:max-w-2xl">
							<div>
								<img
									src={phone1}
									alt="social_login"
									className="object-contain w-full h-full"
								/>
							</div>
							<div className="mt-[2rem]">
								<img
									src={phone3}
									alt="social_login"
									className="object-contain w-full h-full "
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Application;
