import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export const options = {
  responsive: true,
  tooltips: {
    enabled: false,
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
      position: "top",
    },
  },
};



const WalletChart = ({ topWollet }) => {

  let labelArr = []
  let Owners = []
  let  Renters = []

  for (let index = 0; index < topWollet?.length; index++) {
    const element = topWollet[index];
    labelArr.push(element?.label)
    Owners.push(element?.car_owner)
    Renters.push(element?.renter)

  }


  const labels = labelArr

   const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: "Car Owner ",
        data: Owners,
        borderColor: "#FF8C00",
        backgroundColor: "rgba(247, 246, 247, 0.5)",
        borderWidth: 1,
        pointRadius: 0,
      },
      {
        fill: true,
        label: "Renters",
        data: Renters,
        borderColor: "#FFC278",
        backgroundColor: "rgba(247, 241, 236, 0.5)",
        borderWidth: 1,
        pointRadius: 0,
      },
    ],
  };
  return (
    <>
      <div className="h-full ">
        <Line options={options} className="w-full !h-[80%] " data={data} redraw={true} />
      </div>
    </>
  );
};

export default WalletChart;
