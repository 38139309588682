import React from "react";
import DataTable from "../../components/AdminComponents/manageUser/registerUsers/DataTable";

const RegisterUsers = () => {



  return (
    <>
      <div className="shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] bg-white rounded-md p-5 mt-5">
       
        <DataTable />
      </div>
    </>
  );
};

export default RegisterUsers;
