import React from 'react'
import DataTable from './PaymentTables/DataTable'
const PaymentManagement = () => {
 
  return (
    <>
     <DataTable/>
    </>
  )
}

export default PaymentManagement