import React from 'react'
import Chart1 from '../../components/AdminComponents/GlobelReports/ChartsBox/Chart1'
import Chart2 from '../../components/AdminComponents/GlobelReports/ChartsBox/Chart2'
import Chart3 from '../../components/AdminComponents/GlobelReports/ChartsBox/Chart3'
import Chart4 from '../../components/AdminComponents/GlobelReports/ChartsBox/Chart4'
import Chart5 from '../../components/AdminComponents/GlobelReports/ChartsBox/Chart5'
import Chart6 from '../../components/AdminComponents/GlobelReports/ChartsBox/Chart6'
import Chart7 from '../../components/AdminComponents/GlobelReports/ChartsBox/Chart7'
import Chart8 from '../../components/AdminComponents/GlobelReports/ChartsBox/Chart8'
import Chart9 from '../../components/AdminComponents/GlobelReports/ChartsBox/Chart9'
import Chart10 from '../../components/AdminComponents/GlobelReports/ChartsBox/Chart10'
import Chart11 from '../../components/AdminComponents/GlobelReports/ChartsBox/Chart11'
const KpiMatrics = () => {
    const charts = [
        { housr: "Users Clickes on [Réserver]", daily: "Users Clickes on [Réserver]", sideHeading: "Réserver", keys: "reserver" },
        { housr: "Users Clickes on [Faire une Offre]", daily: "Users Clickes on [Faire une Offre]", sideHeading: "Faire une offre", keys:"click_on_offer" },
        { housr: "Users Clickes on [Oui]", daily: "Users Clickes on [Oui]", sideHeading: "Oui", keys: "how_to_yes" },
        { housr: "Users Clickes on [Non]", daily: "Users Clickes on [Non]", sideHeading: "Non", keys: "how_to_no" },
        { housr: "User clickes on [Trouver un véhicule]", daily: "User clickes on [Trouver un véhicule]", sideHeading: "Trouver un véhicule", keys: "filter" },
        { housr: "Renter sends [Offer request]", daily: "Renter sends [Offer request]", sideHeading: "Renter sends [Offer request]", keys: "offer_request_sent" },
        { housr: "Renter sends [Booking request]", daily: "Renter sends [Booking request]", sideHeading: "Renter sends [Booking request]", keys: "rent_request_sent" },
        { housr: "Owner accepts [Offer request]", daily: "Owner accepts [Offer request]", sideHeading: "Owner accepts [Offer request]", keys: "offer_request_accepted" },
        { housr: "Owner accepts [Booking request]", daily: "Owner accepts [Booking request]", sideHeading: "Owner accepts [Booking request]", keys: "rent_request_accepted" },
        { housr: "Users newly registers on Primecar", daily: "Users newly registers on Primecar", sideHeading: "Users newly registers on Primecar", keys: "user_register" },
        { housr: "Users deleting Primecar account", daily: "Users deleting Primecar account", sideHeading: "Users deleting Primecar", keys: "user_deleted" },
    ]

    return (
        <>
            <div className='' >
                <Chart1 heading={charts[0].housr} sideHeading={charts[0]?.sideHeading} keys={charts[0]?.keys} />
                <Chart2 heading={charts[1].housr} sideHeading={charts[1]?.sideHeading} keys={charts[1]?.keys} />
                <Chart3 heading={charts[2].housr} sideHeading={charts[2]?.sideHeading} keys={charts[2]?.keys} />
                <Chart4 heading={charts[3].housr} sideHeading={charts[3]?.sideHeading} keys={charts[3]?.keys} />
                <Chart5 heading={charts[4].housr} sideHeading={charts[4]?.sideHeading} keys={charts[4]?.keys} />
                <Chart6 heading={charts[5].housr} sideHeading={charts[5]?.sideHeading} keys={charts[5]?.keys} />
                <Chart7 heading={charts[6].housr} sideHeading={charts[6]?.sideHeading} keys={charts[6]?.keys} />
                <Chart8 heading={charts[7].housr} sideHeading={charts[7]?.sideHeading} keys={charts[7]?.keys} />
                <Chart9 heading={charts[8].housr} sideHeading={charts[8]?.sideHeading} keys={charts[8]?.keys} />
                <Chart10 heading={charts[9].housr} sideHeading={charts[9]?.sideHeading} keys={charts[9]?.keys} />
                <Chart11 heading={charts[10].housr} sideHeading={charts[10]?.sideHeading} keys={charts[10]?.keys} />
                {/* <KpiChart condition={"Hourly"} 
                /> */}
            </div>

        </>
    )
}

export default KpiMatrics