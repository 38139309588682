import React, { useState, useEffect } from "react";
import android from "../../../assests/Google-Play-Download.webp";
import apple from "../../../assests/App-Store-Download.webp";

import AppLogo from "../../../assests/app-Icon-.webp";

import { Link, useLocation } from "react-router-dom";
import {
	FaFacebookF,
	FaInstagram,
	FaTwitter,
	// FaXTwitter,
} from "react-icons/fa";

import { RiLinkedinFill } from "react-icons/ri";
// import twitter from "../../../assests/x-twitter.svg";
import c_1 from "../../../assests/PMNeew/Visa.svg";
import c_2 from "../../../assests/PMNeew/Mastercard.svg";
import c_3 from "../../../assests/PMNeew/Moov Moeney.svg";
import c_4 from "../../../assests/PMNeew/Orange.svg";
import c_5 from "../../../assests/MTN x2.png";
import c_6 from "../../../assests/PMNeew/WAVE.svg";
// import { useNavigate } from "react-router-dom";

const BlogFooter = () => {
	const [showNotification, setShowNotification] = useState(false);
	// const navigate = useNavigate();
	let currentYear = new Date().getFullYear()

	const handleNotificationClick = () => {
		setShowNotification(true);

		// Hide the notification after 2 seconds
		setTimeout(() => {
			setShowNotification(false);
		}, 2000);
	};
	const location = useLocation().pathname;

	const [windowDimensions, setWindowDimensions] = useState(0);

	useEffect(() => {
		function handleResize() {
			const { innerWidth: width } = window;
			setWindowDimensions(width);
		}

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, [windowDimensions]);

	return (
		<div className="w-full flex justify-center ">
			<div className="w-full">
				<div className="bg-[#EBEBEB] bg-center bg-cover flex justify-start lg:justify-center footerSec w-full">
					<div className="md:px-20 px-5">
						<div className="2xl:w-[1440px]  ">
							<div className=" pt-[40px] pb-5">
								<div className="grid lg:grid-cols-5  items-start justify-start lg:gap-10 gap-[24px]">
									<div className="lg:col-span-2">
										<Link to="/">
											<div className="flex flex-row items-center gap-[4px]">
												<img
													src={AppLogo}
													alt="logo"
													className="object-contain lg:w-[31.87px] lg:h-[31.84px] md:w-[25.89px] md:h-[25.87px] tablet:w-[25.89px] tablet:h-[25.87px]  w-[25.89px] h-[25.87px] cursor-pointer"
												/>
												<p className="font-bold text-[16px] sm:text-[20px]  leading-[24px] text-[#FF8C00]">
													Primecar
												</p>
											</div>
										</Link>
										<div className="pt-[14px]">
											{location === "/" ? (
												<p className="text-[12px] text-[#5A5A5A] font-normal footerFont text-left  leading-[22px]">
													Primecar est la première
													plateforme digitale
													d'autopartage dans la zone
													Afrique francophone. En tant
													qu’acteur de l’économie
													collaborative, notre mission
													est de faciliter l’accès aux
													services de mobilité à tout
													moment et n’importe quand.
													Vous n'avez qu'à réservez
													votre véhicule sur notre
													application mobile, il est
													immédiatement mis à votre
													disposition.
												</p>
											) : (
												<div>
													<p className="text-[14px] text-[#5A5A5A] font-normal ml-[5px] footerFont text-left  leading-[26px]">
														Téléchargez
														l'application
													</p>
													<div className=" pt-[8px] md:pt-[2px] items-center">
														<a href="https://apps.apple.com/fr/app/primecar/id6446251526">
															<img
																src={apple}
																alt="social_login"
																className="socialBtn "
															/>
														</a>
														<a href="https://play.google.com/store/apps/details?id=com.dinsstech.primecar">
															<img
																src={android}
																alt="social_login"
																className="socialBtn"
															/>
														</a>
													</div>
												</div>
											)}
										</div>
									</div>

									<div className=" lg:col-span-3 grid lg:grid-cols-3 grid-cols-2 lg:gap-6 text-[12px]">
										<ul className="flex flex-col ">
											<li className="font-bold h-[34px] text-[#FF8C00] footerFont text-[15px]">
												En savoir plus
											</li>
											{/* <li className='text-[#5A5A5A] text-[12px] text  footerFont'>À propos</li> */}

											<li className=" h-[34px] text-[#5A5A5A] text-[12px] footerFont">
												<Link to="/contact">
													Contactez-nous
												</Link>
											</li>
											<li className="text-[#5A5A5A] h-[34px] text-[12px] text  footerFont">
												<Link to="/blog">Blog</Link>
											</li>
											<li className="text-[#5A5A5A] h-[34px] text-[12px]   footerFont">
												<Link to="/help-center">
													Centre d’aide
												</Link>
											</li>
										</ul>
										<ul className="flex flex-col ">
											<li className="font-bold h-[34px] text-[#FF8C00] text-[15px] footerFont">
												Découvrir
											</li>
											<li className=" footerFont h-[34px] text-[#5A5A5A] text-[12px]">
												{" "}
												<Link to="/deplacement-professionnel">
													{" "}
													Professionnel{" "}
												</Link>
											</li>
											<li className=" footerFont h-[34px] text-[#5A5A5A] text-[12px]">
												<Link to="/louer-mon-vehicule">
													{" "}
													Louer mon véhicule
												</Link>
											</li>
										</ul>

										<div className="mt-[24px] md:mt-0">
											<h2 className="font-bold h-[34px]  text-[#FF8C00] footerFont text-[15px]">
												Paiements sécurisés
											</h2>
											<div className="flex xl:flex-wrap lg:flex-wrap pt-3 gap-[16px] md:gap-[8px]">
												<img
													src={c_1}
													alt="card_1"
													className="object-contain"
												/>
												<img
													src={c_2}
													alt="card_1"
													className="object-contain"
												/>
												<img
													src={c_3}
													alt="card_1"
													className="object-contain"
												/>
												<img
													src={c_4}
													alt="card_1"
													className="object-contain "
												/>
												<img
													src={c_5}
													alt="card_1"
													className="object-contain"
												/>
												<img
													src={c_6}
													alt="card_1"
													className="object-contain"
												/>
											</div>

											<div className="mt-[24px] gap-[10px] flex ">
												<a
													target="_blank"
													href="https://www.facebook.com/primecarciv"
													className="group"
												>
													<div className="rounded-full flex group-hover:border-[#FF8C00] group-hover:border cursor-pointer  group-hover:bg-white justify-center items-center bg-[#FF8C00] w-[40px] h-[40px]">
														<FaFacebookF className="text-white group-hover:text-[#FF8C00] text-[15px]" />
													</div>
												</a>
												<a
													target="_blank"
													href="https://www.linkedin.com/company/primecarciv"
													className="group"
												>
													<div className="rounded-full group-hover:bg-white group-hover:border-[#FF8C00] group-hover:border cursor-pointer  flex justify-center items-center bg-[#FF8C00] w-[40px] h-[40px]">
														<RiLinkedinFill className="text-white group-hover:text-[#FF8C00] text-[15px]" />
													</div>
												</a>
												<a
													target="_blank"
													className="group hidden"
												>
													<div className="rounded-full group-hover:bg-white group-hover:border-[#FF8C00] group-hover:border cursor-pointer flex justify-center items-center bg-[#FF8C00] w-[40px] h-[40px]">
														<FaTwitter className="text-white group-hover:text-[#FF8C00] text-[15px]" />
													</div>
												</a>
												<a
													target="_blank"
													className="group hidden"
												>
													<div className="rounded-full group-hover:bg-white group-hover:border-[#FF8C00] group-hover:border cursor-pointer flex justify-center items-center bg-[#FF8C00] w-[40px] h-[40px]">
														<FaInstagram className="text-white group-hover:text-[#FF8C00] text-[15px]" />
													</div>
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="  py-3  text-[13px]">
								<hr className="border-[#667085] " />
								<div className="flex flex-col-reverse justify-start md:flex-row sm:justify-between footerFont   lg:flex-nowrap md:justify-between md:items-center lg:items-center  md:flex-nowrap lg:justify-between text-[#667085]  items-start">
									<h2 className="pt-[8px]">
										© {currentYear} DINSS LLC, All rights reserved
									</h2>
									<div className="flex flex-row gap-[51px] items-center lg:pt-0 pt-[8px]">
										<h2 className="footerFont">
											<Link to="/gcu">
												Conditions générales
											</Link>
										</h2>
										<h2 className="footerFont  ">
											<Link to="/privacy">
												Confidentialités
											</Link>
										</h2>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BlogFooter;
