import React, { useEffect, useState } from "react";
import RenterDetail from "./RenterDetail";
import Reviews from "./Reviews";
import CarRequest from "./CarRequest";
import ChatWithOwner from "./ChatWithOwner";
import CustomerSupport from "./CustomerSupport";
import Transaction from "./Transaction";
import { useLocation, useParams } from "react-router-dom";
import CarList from "./CarList";
import CarlistDetail from "../carlistDetail/CarlistDetail";
import { collection, getDocs, getFirestore, query } from "firebase/firestore";
import { Firebaseapp } from "../../../../confiq/firebase";
// import BreadCums from "../../../MainLandingComp/breadCums/BreadCums";
// import { GetVehicalRnterDetail } from "../../../../lib/Api/RentalAppApi";

const RentalIndex = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [carListDetail, setcarListDetail] = useState(false);
  const [user, setUser] = useState({});
  const [allUsers, setAllUsers] = useState([]);
  const [updateMessage, setUpdateMessage] = useState("")

  let location = useLocation().pathname;
  let id = useParams().id;
  let name = useParams().name;

  let list = [
    "Renter Detail",
    "Reviews",
    "Car requests",
    "Chat with Owner",
    "Customer Support",
    "Transaction",
  ];
  let listOwner = [
    "Car Owner Detail",
    "Reviews",
    "Car list",
    "Chat with Renter",
    "Customer Support",
    "Transaction",
  ];

  const handlClick = (index) => {
    setActiveIndex(Number(index));
    // carListDetail(false)
  };

  useEffect(() => {
    if (name === "all") {
      setActiveIndex(0);
    } else {
      setActiveIndex(2);
    }
  }, []);

  useEffect(() => {
    let fetch = async () => {
      const db = getFirestore(Firebaseapp);
      const q = query(collection(db, `Users/`));
      const querySnapshots = await getDocs(q);
      const Users = querySnapshots.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAllUsers(Users);
    };
    fetch();
  }, [updateMessage === ""]);

  return (
    <>
      <h2 className="text-[#444444] font-semibold text-[20px] leading-[30px]">
        {location.includes("/dinss/tech/vehicalOwner") || location.includes("/dinss/tech/dashboard")
          ? "Vehicle Owner"
          : "Vehicle Renters"}
      </h2>
      <div className="my-3">
      {/* <BreadCums left={"User Management"} leftLink={"/dinss/tech/vehicalOwner"} right={"Vehicle Owner"} /> */}
      </div>
      {location.includes("/dinss/tech/vehicalOwner") ||
        location.includes("/dinss/tech/dashboard") ||
        location.includes("/dinss/tech/customerOwner") ? (
        <>
          <div
            className="select-wrapper px-4 mx-2 border-0 shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] rounded-md bg-white xl:hidden md:hidden w-full py-2 focus:outline-none mt-2 "
            onChange={(e) => handlClick(e.target.value)}
          >
            <select className=" w-[98%]  focus:outline-none  p-1 ">
              <option value="">Please Select</option>
              {listOwner.map((list, i) => (
                <option value={i} key={i}>
                  {list}
                </option>
              ))}
            </select>
          </div>
          <div className="xl:flex md:flex hidden justify-between items-center border-b-2  mt-3">
            {listOwner.map((list, i) => (
              <h1
                className={`lg:w-[170px] text-center cursor-pointer text-[#ACACAC] px-3 py-2 text-[15px] ${activeIndex === i &&
                  "border-b-2  text-center border-[#FF8C00] !text-[#444444] font-semibold"
                  }`}
                onClick={() => setActiveIndex(i)}
                key={i}
              >
                {" "}
                {list}
              </h1>
            ))}
          </div>
        </>
      ) : (
        <>
          <select
            className="border-0 shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] rounded-md bg-white px-2 xl:hidden md:hidden w-full py-2 focus:outline-none mt-2"
            onChange={(e) => handlClick(e.target.value)}
          >
            <option value="">Please Select</option>
            {list.map((list, i) => (
              <option value={i} key={i}>
                {list}
              </option>
            ))}
          </select>
          <div className="xl:flex md:flex hidden justify-between items-center border-b-2  mt-3">
            {list.map((list, i) => (
              <h1
                className={`xl:w-[170px] md:w-auto w-[165px] text-center cursor-pointer text-[#ACACAC] px-3 py-2 text-[15px] leading-[21px] ${activeIndex === i &&
                  "border-b-2  text-center  border-[#FF8C00] !text-[#444444] font-semibold"
                  }`}
                onClick={() => {
                  setActiveIndex(i);
                  setcarListDetail(false);
                }}
                key={i}
              >
                {" "}
                {list}
              </h1>
            ))}
          </div>
        </>
      )}

      <div className="mt-5">
        {activeIndex === 0 && <RenterDetail userId={user?.id} />}
        {activeIndex === 1 && <Reviews user={user} />}
        { }
        {(activeIndex === 2 && location === `/dinss/tech/vehicalOwner/detail/${id}/all`) ||
          (activeIndex === 2 &&
            location === `/dinss/tech/dashboard/vehical-detail/${id}/all`) ||
          (activeIndex === 2 &&
            location === `/dinss/tech/vehicalOwner/detail/${id}/carlist`) ||
          (activeIndex === 2 &&
            location === `/dinss/tech/dashboard/vehical-detail/${id}/carlist`) ||
          (activeIndex === 2 &&
            location === `/dinss/tech/customerOwner/detail/${id}/carlist`) ||
          (activeIndex === 2 &&
            location === `/dinss/tech/customerOwner/detail/${id}/all`) ? (
          <CarList
            carListDetails={carListDetail}
            user={user}
            setcarListDetail={setcarListDetail}
          />
        ) : (activeIndex === 2 &&
          location === `/dinss/tech/vehicalRenters/detail/${id}/all`) ||
          (activeIndex === 2 &&
            location === `/dinss/tech/customerRenter/detail/${id}/all`) ? (
          <CarRequest setUser={setUser} />
        ) : (activeIndex === 2 &&
          location === `/dinss/tech/vehicalOwner/detail/${id}/detail`) ||
          (activeIndex === 2 &&
            location === `/dinss/tech/dashboard/vehical-detail/${id}/detail`) ? (
          <CarlistDetail setUser={setUser} user={user} VehicalId={id} />
        ) : (
          ""
        )}
        {activeIndex === 3 && <ChatWithOwner allUsers={allUsers} title={"Owner"} />}
        {activeIndex === 4 && <CustomerSupport  setUpdateMessages={setUpdateMessage} allUsers={allUsers} title={"Support"}/>}
        {activeIndex === 5 && <Transaction userId={user?.id} />}
      </div>
    </>
  );
};

export default RentalIndex;
