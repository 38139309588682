import React, { useEffect, useState } from "react";
import Weights from "../../components/AdminComponents/dashboard/Weights";
import DataTable from "../../components/AdminComponents/dashboard/DataTable/DataTable";
import DashboardChart from "../../components/AdminComponents/dashboard/SingleLineChart";
import { GetDashStatis } from "../../lib/Api/Vehical";
import RentalIndex from "../../components/AdminComponents/manageUser/vihicalRenterSteps/RentalIndex";
// import CommonDropdown from "../../components/AdminComponents/commonDropdown";
import { GetGrapState } from "../../lib/Api/AdminApi";
import Popup from "../../util/popup/Popup";
import UpdateStatusLoader from "../../components/AdminComponents/statusLoaderSpinner/StatusLoader";



const Dashboard = () => {
  const [Static, setStatic] = useState({});
  const [detail, setDetail] = useState(false);
  const [isStatus, setIsStatus] = useState(false);
  const [rentalUser, setRentalUser] = useState({});
  const [graphData, setGraphData] = useState([]);
  // const [month, setMonth] = useState("");


  const currentDate = new Date();
  const [defaultMonth, setDefaultMonth] = useState(currentDate.getMonth());

  // Month is 0-based, so add 1
  const currentYear = currentDate.getFullYear();

  const months = [];

  for (let month = 0; month < 12; month++) {
    const monthName = new Date(currentYear, month, 1).toLocaleString('default', { month: 'long' });
    months.push(monthName);
  }



  const handleChangeMonth = async (e) => {
    let val = e.target.value
    setDefaultMonth(val)
    let incVal = Number(val) + 1
    let addZeroWithMonth = incVal <= 9 ? `0${incVal}-${currentYear}` : `${incVal}-${currentYear}`
    let { resGraph } = await GetGrapState(addZeroWithMonth);
    if (resGraph) {
      setGraphData(resGraph);

    }
  }


  useEffect(() => {
    try {
      let fetchStatis = async () => {
        let { res } = await GetDashStatis();
        let Statis = Object.assign({}, ...res);
        setStatic(Statis);
        let incVal = Number(defaultMonth) + 1
        //console.log(defaultMonth)
        let addZeroWithMonth = incVal <= 9 ? `0${incVal}-${currentYear}` : `${incVal}-${currentYear}`
        let { resGraph } = await GetGrapState(addZeroWithMonth);
        if (resGraph) {
          setGraphData(resGraph);
        }
      };
      fetchStatis();
    } catch (err) { }
  }, []);



  return (
    <>
      {isStatus && <Popup data={<UpdateStatusLoader />} modalOpen={isStatus} />}
      {detail === false ? (
        <>
          <h2 className="text-[#444444] font-semibold leading-[30px] text-[20px]">
            Overview
          </h2>
          <div className="grid lg:grid-cols-10 md:grid-cols-10 xxtra-small:grid-cols-1 xtra-small:grid-cols-1 gap-6 mt-[20px]">
            <div className="bg-white shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] border border-gray-100 rounded-md h-full px-3 pt-3 col-span-6 md:col-span-4  ">
              <div className="flex justify-between items-center pb-3">
                <h2 className="text-[#444444] leading-[20.16px] text-[14.4px] font-medium">
                  Earnings
                </h2>
                <select value={defaultMonth} onChange={(e) => handleChangeMonth(e)} className="border rounded-2xl outline-none px-4 py-1">
                  <option>Month</option>
                  {
                    months?.map((item, i) => (
                      <option value={i} key={i}>{item}</option>
                    ))
                  }
                </select>
              </div>
              <DashboardChart graphData={graphData} />
            </div>
            <div className="col-span-6">
              <Weights Static={Static} />
            </div>
          </div>

          <div className="bg-white shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] border-0 border-gray-100 rounded-md h-full p-4 mt-5">
            <DataTable setIsStatus={setIsStatus} setDetail={setDetail} setRentalUser={setRentalUser} />
          </div>

        </>
      ) : (
        <RentalIndex rentalUser={rentalUser} />
      )}
    </>
  );
};

export default Dashboard;
