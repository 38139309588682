import React, { useEffect } from "react";
import { MdClose } from "react-icons/md";
import Left from "./Left";
import { useRef } from "react";
import { FiFilter } from "react-icons/fi";

function Sidebar({
  sidebarOpen,
  setSidebarOpen,
  priceType,
  setPriceType,
  payload,
  setCars,
  setPagination,
  setLoadingCars,
}) {
  const sidebar = useRef(null);

  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  const handleCloseFilterSideBar = () => {
    setSidebarOpen(false);
    document.body.style.overflow = "auto";
  };

  useEffect(() => {
    if (sidebarOpen === true) {
      if (typeof window != "undefined" && window.document) {
        document.body.style.overflow = "hidden";
      }
    } else {
      document.body.style.overflow = "auto";
    }
  }, [sidebarOpen]);

  return (
    <div>
      {/* Sidebar backdrop (mobile only) */}
      <div
        className={`fixed inset-0 bg-slate-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
          sidebarOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        aria-hidden="true"
      ></div>

      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`lg:flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 h-screen   border border-gray-100  overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 lg:w-[15rem] lg:sidebar-expanded:!w-[15rem] 2xl:!w-64 shrink-0 bg-white p-4 transition-all duration-200 ease-in-out ${
          sidebarOpen ? "translate-x-0" : "-translate-x-64"
        }`}
      >
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <FiFilter className="text-[20px] lg:hidden md:hidden text-[#FF8C00]" />
            <h2 className="text-[#FF8C00] font-normal text-[22px]">Filtre</h2>
          </div>
          <div className="" onClick={() => handleCloseFilterSideBar()}>
            <MdClose className="text-[28px] " />
          </div>
        </div>
        <div>
          <Left
            payload={payload}
            setCars={setCars}
            setPagination={setPagination}
            priceType={priceType}
            setPriceType={setPriceType}
            setLoadingCars={setLoadingCars}
          />
        </div>
      </div>
    </div>
  );
}

export default Sidebar;