import React from "react";

function EyeIcon(props) {
  return (
    <svg
      {...props}
      width="16"
      height="10"
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00211 0.231445C4.94433 0.231445 2.17138 1.90438 0.125225 4.62168C-0.0417416 4.8443 -0.0417416 5.15532 0.125225 5.37794C2.17138 8.09851 4.94433 9.77145 8.00211 9.77145C11.0599 9.77145 13.8328 8.09851 15.879 5.38121C16.046 5.15859 16.046 4.84757 15.879 4.62495C13.8328 1.90438 11.0599 0.231445 8.00211 0.231445ZM8.22146 8.36042C6.19167 8.4881 4.51546 6.81516 4.64314 4.7821C4.7479 3.10589 6.10655 1.74724 7.78276 1.64247C9.81255 1.51479 11.4888 3.18773 11.3611 5.22079C11.253 6.89373 9.89439 8.25238 8.22146 8.36042ZM8.11997 6.80861C7.0265 6.87736 6.12292 5.97705 6.19494 4.88359C6.2506 3.98 6.98394 3.24994 7.88753 3.19101C8.98099 3.12226 9.88457 4.02256 9.81255 5.11603C9.75362 6.02289 9.02028 6.75295 8.11997 6.80861Z"
        fill="#98A2B3"
      />
    </svg>
  );
}

export default EyeIcon;
