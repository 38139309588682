import React, { useState } from "react";
import delIcon from "../../../assests/delIcon.png";
import { DelInvitation } from "../../../lib/Api/AdminApi";
import LoaderSpinner from "../../../util/LoaderSpinner";
const DeleteData = ({ handleClose, list, onClose }) => {
  const [loading, setLoading] = useState(false);

  let handleDel = async () => {
    setLoading(true);
    let { res } = await DelInvitation(list?.id);
    if (res) {
      setLoading(false);
      handleClose();
    }
  };

  return (
    <>
      <div className="text-center p-5">
        <div className="flex justify-center items-center">
          <img src={delIcon} alt="delicon" className="object-cover" />
        </div>
        <div className="py-5">
          <h1 className="text-[#292929] text-[20px] font-semibold">
            Do you want to delete this Admin
          </h1>
          <p className="text-[#666666] leading-[20px] text-[16px]">
            Are you sure you want to delete this Admin
          </p>
          <div className="pt-5">
            <button
              onClick={() => onClose()}
              className="w-[138px] py-2 rounded-md bg-transparent border-[#ACACAC] border text-[#ACACAC]"
            >
              Cancel
            </button>
            <button
              onClick={() => handleDel()}
              className="w-[138px] py-2 ml-3 rounded-md bg-[#F04438] text-white"
            >
              {loading ? <LoaderSpinner /> : "Delete"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteData;
