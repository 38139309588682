import React from "react";
// import { MdSearch } from "react-icons/md";
import DataList from "./DataList";
import SearchBar from "../../../GenericComponents/SearchBar";
// import GenericDrodown from '../../util/dropdowns/GenericDropdown'
// import FilterDropdown from '../../util/dropdowns/FilterDropdown'

const DataTable = ({
  setIsEdit,
  setTotalPages,
  title,
  vehicalList,
  totalPages,
  setSearchData,
  searchData,
  offsetPage,
  setOffsetPage,
  setVehicalList,
  loading,
  currentPageIndex,
  setCurrentPageIndex,
  setLoading,
  setTypes,
  setStatusCheck,
  statusCheck,
}) => {
  return (
    <>
      <div className="flex  flex-wrap justify-between items-center">
        <h2 className="text-[20px] font-semibold leading-[20.16px]  text-[#444444]">
          {title}
        </h2>
        <div className="flex lg:py-0 py-2 ">
          {
            title === "Vehicle Fleet Management" ?
              <SearchBar
                offsetPage={offsetPage}
                setSearchData={setSearchData}
                setVehicalList={setVehicalList}
                setLoading={setLoading}
                setTypes={setTypes}
                setCurrentPageIndex={setCurrentPageIndex}
                setTotalPages={setTotalPages}
                setStatusCheck={setStatusCheck}
                statusCheck={statusCheck}
                title=""
              />
              :
              <SearchBar
                offsetPage={offsetPage}
                setSearchData={setSearchData}
                setVehicalList={setVehicalList}
                setLoading={setLoading}
                setTypes={setTypes}
                setTotalPages={setTotalPages}
                setStatusCheck={setStatusCheck}
                setCurrentPageIndex={setCurrentPageIndex}
                statusCheck={statusCheck}
                title=""
              />

          }
        </div>
      </div>
      <div>
        <DataList
          vehicalList={vehicalList}
          setDetail={setIsEdit}
          title={title}
          setSearchData={setSearchData}
          searchData={searchData}
          setVehicalList={setVehicalList}
          loading={loading}
          setOffsetPage={setOffsetPage}
          setLoading={(val) => setLoading(val)}
          setTypes={setTypes}
          totalPages={totalPages}
          // currentPageIndex={currentPageIndex}
          setCurrentPageIndex={setCurrentPageIndex}

        />
      </div>
    </>
  );
};

export default DataTable;
