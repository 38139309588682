import { toast } from "react-toastify"
import { callPrivateApi } from "../Apiendpoint"
// import moment from "moment";


// Create BroadCast
export const CreateBroadCast = async (data) => {
    try {
        let response = await callPrivateApi(`admin/broadcast`, 'post', data)
        if (response.success === true) {
            toast.success(response.message)
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}
// Get BroadCast 
export const GetBroadCast = async () => {
    try {
        let response = await callPrivateApi(`admin/broadcast/list`, 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data)
            return {
                res: obj.messages
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}


// Create BroadCast
export const CreateSendNotification = async (data) => {
    try {
        

        let response = await callPrivateApi(`admin/broadcast/notification`, 'post', data)
        if (response.success === true) {
            toast.success(response.message)
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}


// ------------------- Send Code Api ------------------------//
// Create BroadCast
export const CreateSendCode = async (data) => {
    try {
        let response = await callPrivateApi(`admin/otp_code/store`, 'post', data)
        if (response.success === true) {
            toast.success(response.message)
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}
// Get BroadCast 
export const GetUserLists = async () => {
    try {
        let response = await callPrivateApi(`admin/get_users_list`, 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data)
            return {
                res: obj
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}

// del Broadcast
export const DelBroadCast = async (id) => {
    try {
        let response = await callPrivateApi(`admin/broadcast/delete/${id}`, 'get')
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}
