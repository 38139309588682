import React from 'react'
// import { callApi } from '../../utils/CallApi';
// import { useForm } from "react-hook-form";
// import { toast, Toast container mx-auto xl:px-[110px] } from 'react-toastify';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';



const Popup = ({ modalOpen, onClose, data, type }) => {

    return (
        <>
            <div id="search_page">
                <Modal
                    open={modalOpen}
                    onClose={onClose} >
                    <div className={`bg-white ${type === "Detail" ? 'px-5' : type === "chat" ? 'px-5' : 'md:px-[60px] px-[20px]'} xl:rounded shadow-lg overflow-auto  py-[20px] md:py-[30px] xl:mt-5`}>
                        {/* Modal header */}
                        {data}
                    </div>
                </Modal >
            </div>
        </>
    )
}

export default Popup